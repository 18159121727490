import { Link } from "react-router-dom";
import ChapterDatas from "../../../data/event/chapters.json";
import React from "react";
import { Row, Col } from "react-bootstrap";
import common from "../../../shared/common";
import DownloadIcon from '@mui/icons-material/Download';

const Modules = (props) => {
    const downloadResource = (data, url) => {
        // if (props.subsubcategory.can_user_access || data.allow_free_access) {
            window.open(url);
            // common.downloadResource(url)
        // } else {
        //     alert('You need to subscribe the course first')
        // }
    }
    return (<div className="course-element">
        <Row>
            <Col lg="12" md="12">
                {props.subsubcategory.modules && props.subsubcategory.modules.length > 0 &&
                    props.subsubcategory.modules.map((data, i) => (
                        <div className="event-box" key={i}>
                            <Row>
                                <Col xl="2" lg="2" md="2">
                                    <div className="event-img">
                                        <img
                                            src={
                                                (data.module_image && data.module_image_url) ||
                                                (process.env.PUBLIC_URL +
                                                    `/assets/images/${ChapterDatas[0].eventImg}`)
                                            }
                                            alt=""
                                            className="img-fluid"
                                        />
                                    </div>
                                </Col>
                                <Col xl="10" lg="10" md="10">
                                    <div className="event-content">
                                        <div
                                            className="content-box"
                                            style={{ width: "100%" }}>
                                            <Row>
                                                <Col md="12">
                                                    <div className="event-title">
                                                        <h6 style={{ margin: 0 }}>
                                                            {data.name}
                                                        </h6>
                                                        {data.teacher_technology_kit && (
                                                            <a className="right-btn" onClick={() => downloadResource(data, data.teacher_technology_kit_url)}>
                                                                Download KIT <DownloadIcon />
                                                            </a>
                                                        )}
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    ))}
            </Col>
        </Row>
    </div>
    )
}

export default Modules;