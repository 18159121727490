import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import FooterTwo from "../../components/FooterTwo";
import HeaderTwo from "../../components/HeaderTwo";
import TestimonialSlider from "../../components/TestimonialSlider";
import AppSection from "../../components/AppSection";
import FeatureHeroImage from "../../components/FeatureHeroImage";
import Offerings from "../../components/Offerings";
import ButtonArea from "../../components/ButtonArea";
import ImageContent from "../../components/ImageContent";

const School = (props) => {

    return (
        <div className="main-wrapper" >

            {/* Header */}
            <HeaderTwo {...props} />

            {/* Hero Image */}
            <FeatureHeroImage
                buttonText='CONNECT'
                showPopup={true}
                connect={true}
                bgImage="promotions/politics_schoolreopening.webp"
                sliderTitle="Alyss School Solutions"
                sliderSubtitle="Alyss is your perfect partner for STEM learning. We specialise in Science and Maths content for Grades 9 to 10 and Robotics content for Ages 9+? We believe in empowering teachers with high quality and well researched content that helps them develop scientific temperament in students. We provide complete solution with concept animation videos, theory, teacher technology kits, sample papers, mock tests, online tests, test generation function."

            />

            {/* Offerings */}
            <Offerings />

            {/* Button Area */}
            <ButtonArea
                buttonText='CONNECT'
                showPopup={true}
                connect={true}
            />

            {/* Image content */}
            < ImageContent />

            {/* Button Area */}
            <ButtonArea
                buttonText='CONNECT'
                showPopup={true}
                connect={true}
            />

            {/* Testimonial Slider */}
            < TestimonialSlider />

            {/* App Section */}
            < AppSection />

            {/* Footer */}
            < FooterTwo />

        </div >

    );
};

const mapStateToProps = (state) => ({

});

const mapDispatchToProps = (dispatch) => ({

});

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(School)
);
