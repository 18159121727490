import React, { useEffect, useState } from "react";
import { Col, Dropdown, Row } from "react-bootstrap";
import IntlTelInput from "react-intl-tel-input";
import { messages, webUrl } from "../../../shared/constants.js";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import "react-intl-tel-input/dist/main.css";
import { Autocomplete, Box, TextField } from "@mui/material";

function UserProfile(props) {
  const query = new URLSearchParams(props.location.search);
  const referCode = query.get("refer-code") || "";
  const schoolId = query.get("school") || null;
  const [termsChecked, setTermsChecked] = useState(true);
  const [formData, setFormData] = useState({
    member_type: "",
    full_name: props.user && props.user.name ? props.user.name : "",
    city_id: props.user && props.user.city_id ? props.user.city_id : "",
    category_id: props.user && props.user.category_id ? props.user.category_id : "",
    subcategory_id: props.user && props.user.subcategory_id ? props.user.subcategory_id : "",
    email: props.registeredBy === 2 ? props.email : props.user && props.user.email ? props.user.email : "",
    mobile: props.registeredBy === 1 ? props.mobile : props.user && props.user.mobile ? props.user.mobile : "",
    country_code: props.registeredBy === 1 ? props.country : props.user && props.user.country_code ? props.user.country_code : "+91",
    is_robotics: props.user && props.user.is_robotics ? props.user.is_robotics : props.country === "+91" ? 0 : 1,
    refer_code: referCode,
    school_id: schoolId,
  });
  let subcategoriesData = [];
  let subcategoriesLabel = "Grade";
  if (props.masterData && props.masterData.categories && props.masterData.categories.length > 0 && formData.category_id !== "") {
    let selectedItem = props.masterData.categories.filter((item) => item.id === parseInt(formData.category_id))[0];
    subcategoriesData = selectedItem && selectedItem.subcategories;
    if (selectedItem && selectedItem.is_robotics) {
      subcategoriesLabel = "Hardware";
    } else {
      subcategoriesLabel = "Grade";
    }
  }
  const [selectedCity, setSelectedCity] = useState(null);
  const [selectedCityInput, setSelectedCityInput] = useState("");
  const [subcategories, setSubcategories] = useState(subcategoriesData);
  const [subCategoryLabel, setSubCategoryLabel] = useState(subcategoriesLabel);
  const [memberTypes, setMemberTypes] = useState([
    { id: 1, name: "Student" },
    { id: 2, name: "Teacher" },
  ]);
  const [phoneNumber, setPhoneNumber] = useState(formData.mobile);
  const [countryCode, setCountryCode] = useState(formData.country_code);
  const handlePhoneChange = (status, number, countryObj, finalValue) => {
    setCountryCode("+" + countryObj.dialCode);
    const re = /^[0-9\b]+$/;
    if (number !== "" && re.test(number) && number.length <= 10) {
      setPhoneNumber(number);
      onFieldChange("mobile", number);
    } else if (number === "") {
      setPhoneNumber(number);
      onFieldChange("mobile", number);
    }
  };
  const handleCountryChange = (number, obj, finalValue, status) => {
    setCountryCode("+" + obj.dialCode);
    setPhoneNumber(number);
    onFieldChange("country_code", "+" + obj.dialCode);
    if ("" + obj.dialCode === "91") {
      memberTypes[1].name = "Teacher";
    } else {
      memberTypes[1].name = "Coach";
    }
    setMemberTypes(memberTypes);
  };

  useEffect(() => {
    props.profileMasterData({
      country_code: formData.country_code,
    });
  }, []);

  const onFieldChange = (name, value) => {
    setFormData({
      ...formData,
      [name]: value,
    });

    resetError(name);
  };

  const onCategoryChange = (name, value) => {
    let selectedItem = props.masterData.categories.filter((item) => item.id === parseInt(value))[0];
    setSubcategories(selectedItem && selectedItem.subcategories);
    if (selectedItem && selectedItem.is_robotics) {
      setSubCategoryLabel("Hardware");
    } else {
      setSubCategoryLabel("Grade");
    }

    setFormData({
      ...formData,
      [name]: value,
      subcategory_id: null,
      is_robotics: (selectedItem && selectedItem.is_robotics) || 0,
    });

    resetError(name);
  };

  const onSaveProfileClick = (e) => {
    e.preventDefault();
    if (isValidLoginFormData()) {
      props.updateProfile(formData).then((response) => {
        if (response) {
          props.redirectTo(webUrl.userDashboard);
        }
      });
    }
  };

  const isValidLoginFormData = () => {
    let isValid = true;
    if (!formData || !formData.member_type) {
      setError("member_type", messages.requiredField);
      isValid = false;
    } else {
      resetError("member_type");
    }

    if (!formData || !formData.full_name) {
      setError("full_name", messages.requiredField);
      isValid = false;
    } else if (formData.full_name.length < 3 || formData.full_name.length > 20) {
      setError("full_name", "name should be 3 to 20 characters long");
      isValid = false;
    } else {
      resetError("full_name");
    }
    if (!formData || !formData.mobile) {
      setError("mobile", messages.requiredMobileField);
      isValid = false;
    } else if (isNaN(formData.mobile)) {
      setError("mobile", messages.numberField);
      isValid = false;
    } else if (formData.mobile.length !== 10 && countryCode === "+91") {
      setError("mobile", messages.tenDigitsField);
      isValid = false;
    } else {
      resetError("mobile");
    }

    if (!formData || !formData.email) {
      setError("email", messages.requiredField);
      isValid = false;
    } else if (!isEmail(formData.email)) {
      setError("email", messages.invalidEmailField);
      isValid = false;
    } else {
      resetError("email");
    }

    if (!formData || !formData.password) {
      setError("password", messages.requiredField);
      isValid = false;
    } else {
      resetError("password");
    }

    if (!formData || !formData.confirm_password) {
      setError("confirm_password", messages.requiredField);
      isValid = false;
    } else if (formData.password !== formData.confirm_password) {
      setError("confirm_password", messages.confirmPasswordField);
      isValid = false;
    } else {
      resetError("confirm_password");
    }

    if (!formData || !formData.category_id) {
      setError("category_id", messages.requiredField);
      isValid = false;
    } else {
      resetError("category_id");
    }

    if (!formData || !formData.subcategory_id) {
      setError("subcategory_id", messages.requiredField);
      isValid = false;
    } else {
      resetError("subcategory_id");
    }

    return isValid;
  };

  const isEmail = (email) => {
    return /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(email);
  };

  const setError = (input, message) => {
    let formControl;
    if (input === "mobile") {
      formControl = document.getElementsByName(input)[0].parentElement.parentElement;
      const errorMsg = formControl.querySelector(".login_input-msg");
      formControl.classList.add("error");
      formControl.classList.add("text-left");
      errorMsg.innerText = message;
    } else {
      formControl =
        document.getElementsByName(input)[0] && document.getElementsByName(input)[0].parentElement
          ? document.getElementsByName(input)[0].parentElement
          : null;
      if (formControl) {
        const errorMsg = formControl.querySelector(".login_input-msg");
        formControl.classList.add("error");
        formControl.classList.add("text-left");
        errorMsg.innerText = message;
      }
    }
  };

  const resetError = (input) => {
    let formControl;
    if (input === "mobile") {
      formControl = document.getElementsByName(input)[0].parentElement.parentElement;
      const errorMsg = formControl.querySelector(".login_input-msg");
      formControl.classList.remove("error");
      formControl.classList.remove("text-left");
      errorMsg.innerText = "";
    } else {
      const controlNode = document.getElementsByName(input)[0];
      if (controlNode) {
        formControl = controlNode.parentElement;
        const errorMsg = formControl.querySelector(".login_input-msg");
        formControl.classList.remove("error");
        formControl.classList.remove("text-left");
        errorMsg.innerText = "";
      }
    }
  };

  const pickedRole = (key, data) => {
    if (data) {
      let selectedItem = data.filter((item) => item.id === parseInt(key))[0];
      return (selectedItem && selectedItem.name) || "Select Role";
    }
    return "Select Role";
  };

  const pickedItem = (key, data) => {
    if (data) {
      let selectedItem = data.filter((item) => item.id === parseInt(key))[0];
      return (selectedItem && selectedItem.name) || "";
    }
    return "";
  };

  return (
    <>
      <div className="side-content">
        <h5>Personal info</h5>
        <p>Please fill your information</p>
      </div>
      <div className="side-post">
        {/* Login Area */}
        <section className="login-area">
          <Row>
            <Col md="12">
              <div className="login-box">
                <form id="form_login" className="form" onSubmit={onSaveProfileClick} autoComplete="off">
                  <div className="form-control">
                    <Row>
                      <Col md="12" className="error-icon-info">
                        <label>I am a</label>
                        <Dropdown
                          className="info-filter"
                          name="member_type"
                          id="member_type"
                          onSelect={(e) => {
                            onFieldChange("member_type", e);
                          }}
                        >
                          <Dropdown.Toggle as="a" className="filter-item">
                            <span>{pickedRole(formData.member_type, memberTypes)}</span>
                            <KeyboardArrowDownOutlinedIcon />
                          </Dropdown.Toggle>
                          <Dropdown.Menu as="ul">
                            {memberTypes.map((item) => (
                              <Dropdown.Item as="li" key={item.id} eventKey={item.id}>
                                {" "}
                                {item.name}
                              </Dropdown.Item>
                            ))}
                          </Dropdown.Menu>
                          <span className="login_input-msg"></span>
                        </Dropdown>
                      </Col>
                    </Row>
                  </div>
                  <div className="form-control">
                    <Row>
                      <Col md="12" className="error-icon-info">
                        <label>Full Name</label>
                        <input
                          type="text"
                          placeholder="Full Name"
                          name="full_name"
                          value={formData.full_name}
                          onChange={(e) => {
                            onFieldChange(e.target.name, e.target.value);
                          }}
                          maxLength="20"
                          minLength="3"
                        />
                        <span className="login_input-msg"></span>
                      </Col>
                    </Row>
                  </div>
                  <div className="form-control">
                    <Row>
                      <Col md="12">
                        <IntlTelInput
                          fieldName="mobile"
                          formatOnInit={false}
                          containerClassName="intl-tel-input"
                          inputClassName="phone-number"
                          preferredCountries={["in", "us", "ae"]}
                          value={phoneNumber}
                          onPhoneNumberBlur={handlePhoneChange}
                          onPhoneNumberChange={handlePhoneChange}
                          onSelectFlag={handleCountryChange}
                          separateDialCode
                          disabled={props.registeredBy === 1}
                        />
                        <span className="login_input-msg"></span>
                      </Col>
                    </Row>
                  </div>

                  <div className="form-control">
                    <Row>
                      <Col md="12" className="error-icon-info">
                        <label>Email</label>
                        <input
                          type="text"
                          placeholder="Email"
                          name="email"
                          value={formData.email}
                          onChange={(e) => {
                            onFieldChange(e.target.name, e.target.value);
                          }}
                          readOnly={props.registeredBy === 2}
                        />
                        <span className="login_input-msg"></span>
                      </Col>
                    </Row>
                  </div>
                  <div className="form-control">
                    <Row>
                      <Col md="12" className="error-icon-info">
                        <label>Password</label>
                        <input
                          type="password"
                          placeholder="Password"
                          name="password"
                          value={formData.password}
                          onChange={(e) => {
                            onFieldChange(e.target.name, e.target.value);
                          }}
                        />
                        <span className="login_input-msg"></span>
                      </Col>
                    </Row>
                  </div>
                  <div className="form-control">
                    <Row>
                      <Col md="12" className="error-icon-info">
                        <label>Confirm Password</label>
                        <input
                          type="password"
                          placeholder="Confirm Password"
                          name="confirm_password"
                          value={formData.confirm_password}
                          onChange={(e) => {
                            onFieldChange(e.target.name, e.target.value);
                          }}
                        />
                        <span className="login_input-msg"></span>
                      </Col>
                    </Row>
                  </div>

                  <div className="">
                    <Row>
                      <Col md="12" className="error-icon-info mb-4">
                        {/* <label>Location (Optional)</label> */}
                        <Autocomplete
                          id="city"
                          freeSolo
                          className="form-field"
                          options={props.masterData.cities}
                          value={selectedCity}
                          groupBy={(option) => option.state.name}
                          onChange={(event, newValue) => {
                            setSelectedCity(newValue ? newValue : null);
                            setSelectedCityInput(newValue ? newValue.name : "");
                            setFormData({
                              ...formData,
                              city_id: newValue ? newValue.id : null,
                            });
                          }}
                          inputValue={selectedCityInput}
                          onInputChange={(event, newInputValue) => {
                            setSelectedCityInput(newInputValue);
                          }}
                          clearOnBlur
                          getOptionLabel={(option) => {
                            if (typeof option === "string") {
                              return "";
                            } else {
                              return option.name;
                            }
                          }}
                          renderOption={(props, option) => (
                            <Box component="li" {...props} key={option.id}>
                              {option.name}
                            </Box>
                          )}
                          onKeyDown={(event) => {
                            if (event.key === "Enter") {
                              event.defaultMuiPrevented = true;
                            }
                          }}
                          renderInput={(params) => <TextField {...params} variant="outlined" label="Location (optional)" />}
                        />
                      </Col>
                    </Row>
                  </div>

                  <div className="form-control">
                    <Row>
                      <Col md="12" className="error-icon-info">
                        <label>What are you interested in?</label>
                        <Dropdown
                          className="info-filter"
                          name="category_id"
                          id="category_id"
                          onSelect={(e) => {
                            onCategoryChange("category_id", e);
                          }}
                        >
                          <Dropdown.Toggle as="a" className="filter-item">
                            <span>{pickedItem(formData.category_id, props.masterData.categories)}</span>
                            <KeyboardArrowDownOutlinedIcon />
                          </Dropdown.Toggle>
                          <Dropdown.Menu as="ul">
                            {props.masterData.categories &&
                              props.masterData.categories.map((item) => (
                                <Dropdown.Item as="li" key={item.id} eventKey={item.id}>
                                  {" "}
                                  {item.name}
                                </Dropdown.Item>
                              ))}
                          </Dropdown.Menu>
                          <span className="login_input-msg"></span>
                        </Dropdown>
                      </Col>
                    </Row>
                  </div>
                  {subcategories && subcategories.length > 0 && (
                    <div className="form-control">
                      <Row>
                        <Col md="12" className="error-icon-info">
                          <label>{subCategoryLabel}</label>
                          <Dropdown
                            className="info-filter"
                            name="subcategory_id"
                            id="subcategory_id"
                            onSelect={(e) => {
                              onFieldChange("subcategory_id", e);
                            }}
                          >
                            <Dropdown.Toggle as="a" className="filter-item">
                              <span> {pickedItem(formData.subcategory_id, subcategories)}</span>
                              <KeyboardArrowDownOutlinedIcon />
                            </Dropdown.Toggle>
                            <Dropdown.Menu as="ul">
                              {subcategories &&
                                subcategories.map((item) => (
                                  <Dropdown.Item as="li" key={item.id} eventKey={item.id}>
                                    {" "}
                                    {item.name}
                                  </Dropdown.Item>
                                ))}
                            </Dropdown.Menu>
                            <span className="login_input-msg"></span>
                          </Dropdown>
                        </Col>
                      </Row>
                    </div>
                  )}

                  <div className="form-control">
                    <Row>
                      <Col md="12" className="error-icon-info">
                        <label>Refer Code (Optional)</label>
                        <input
                          type="text"
                          placeholder="Refer Code"
                          name="refer_code"
                          value={formData.refer_code}
                          onChange={(e) => {
                            onFieldChange(e.target.name, e.target.value);
                          }}
                        />
                        <span className="login_input-msg"></span>
                      </Col>
                    </Row>
                  </div>

                  <div className="form-control">
                    <Row>
                      <Col md="12" className="error-icon-info">
                        <div className="accept-login-check col-sm-12">
                          <input
                            type="checkbox"
                            name="terms_check"
                            checked={termsChecked}
                            className="terms_check"
                            onChange={(e) => {
                              setTermsChecked(e.target.checked);
                            }}
                          />
                          By creating account you have agreed to the{" "}
                          <a href={webUrl.terms} target="_blank" rel="noopener noreferrer" style={{ color: "#0000CC" }}>
                            Terms of Use
                          </a>
                          .
                        </div>
                      </Col>
                    </Row>
                  </div>

                  <div className="forget-password">
                    <button id="btn-save-info" type="button" className="btn-save-info" onClick={onSaveProfileClick} disabled={!termsChecked}>
                      Agree & Continue
                    </button>
                  </div>
                </form>
              </div>
            </Col>
          </Row>
        </section>
      </div>
    </>
  );
}

export default UserProfile;
