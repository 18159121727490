import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import FooterTwo from "../../../../components/FooterTwo";
import { Styles } from "../../styles/dashboard.js";
import DashboardMenu from "../DashboardMenu";
import LoaderNew from "../../../../components/LoaderNew";
import Typography from "@mui/material/Typography";
import { DataGrid } from "@mui/x-data-grid";
import PartnerHeader from "../../../../components/PartnerHeader";

const DataList = (props) => {
  // if (props.showLoader.length > 0) {
  //   return <LoaderNew />;
  // }

  return (
    <div className="main-wrapper course-page">
      {/* User Header */}
      <PartnerHeader />

      <Styles>
        {/* Course Grid */}
        <section className="dashboard-grid-area">
          <Container>
            <Row>
              <Col lg="3" md="3" sm="12">
                <DashboardMenu {...props} index={props.index || 0} />
              </Col>
              <Col>
                {props.showLoader && props.showLoader.length > 0 && <LoaderNew />}
                {props.showLoader.length === 0 && (
                  <>
                    <Typography variant="h3" component="div" gutterBottom>
                      {props.headerTitle}
                      {props.button_component && props.button_component}
                    </Typography>
                    {props.subheader_title && (
                      <Typography variant="h4" gutterBottom component="div" className="mt-3">
                        {props.subheader_title}
                      </Typography>
                    )}
                    <div style={{ height: 400, width: "100%" }}>
                      <DataGrid
                        rows={props.dataRows}
                        columns={props.dataCols}
                        pageSize={10}
                        rowsPerPageOptions={[10]}
                        sx={{ fontSize: "15px" }}
                        getRowHeight={({ id, densityFactor }) => {
                          return 100;
                        }}
                      />
                    </div>
                    {props.dialog_component && <div>{props.dialog_component}</div>}
                  </>
                )}
              </Col>
            </Row>
          </Container>
        </section>
      </Styles>

      {/* Footer 2 */}
      <FooterTwo />
    </div>
  );
};

export default DataList;
