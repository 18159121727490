import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { messages } from "../../../shared/constants.js";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";

function ForgotPassword(props) {
  const [formData, setFormData] = useState({});

  const onFieldChange = (name, value) => {
    setFormData({
      ...formData,
      [name]: value,
    });

    resetError(name);
  };

  const onSubmitClick = (e) => {
    e.preventDefault();
    if (isValidFormData()) {
      props.forgotPassword({ email: formData.email }).then((response) => {
        if (response) {
          props.setStep(7);
        }
      });
    }
  };

  const isEmail = (email) => {
    return /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(email);
  };

  const isValidFormData = () => {
    let isValid = true;

    if (!formData || !formData.email) {
      setError("email", messages.requiredField);
      isValid = false;
    } else if (!isEmail(formData.email)) {
      setError("email", messages.invalidEmailField);
      isValid = false;
    } else if (props.email !== formData.email) {
      setError("email", "email doesn't match with provided confirmed one.");
      isValid = false;
    } else {
      resetError("email");
    }

    return isValid;
  };

  const setError = (input, message) => {
    const formControl = document.getElementsByName(input)[0].parentElement;
    const errorMsg = formControl.querySelector(".login_input-msg");
    formControl.classList.add("error");
    formControl.classList.add("text-left");
    errorMsg.innerText = message;
  };

  const resetError = (input) => {
    const formControl = document.getElementsByName(input)[0].parentElement;
    const errorMsg = formControl.querySelector(".login_input-msg");
    formControl.classList.remove("error");
    formControl.classList.remove("text-left");
    errorMsg.innerText = "";
  };

  return (
    <>
      <div className="side-content">
        <h5>Forgot Password</h5>
        <p>Reset password for {props.email}</p>
      </div>
      <div className="side-post">
        {/* Login Area */}
        <section className="login-area">
          <Row>
            <Col md="12">
              <div className="login-box">
                <form id="form_login" className="form" onSubmit={onSubmitClick}>
                  <div className="form-control">
                    <Row>
                      <Col md="12" className="error-icon-info">
                        <label>Confirm Your Email</label>
                        <input
                          type="text"
                          autoFocus
                          placeholder="Email"
                          name="email"
                          value={formData.email}
                          onChange={(e) => {
                            onFieldChange(e.target.name, e.target.value);
                          }}
                        />
                        <span className="login_input-msg"></span>
                      </Col>
                    </Row>
                  </div>
                  <div className="forget-password">
                    <button type="submit" onClick={onSubmitClick}>
                      Send OTP on Email
                    </button>
                  </div>
                </form>
                <div className="save-forget-password d-flex justify-content-between">
                  <div className="email-link forget-password">
                    <Link to="#" onClick={() => props.setStep(3)}>
                      <EditOutlinedIcon />
                      Change Email
                    </Link>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </section>
      </div>
    </>
  );
}

export default ForgotPassword;
