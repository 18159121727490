import styled from "styled-components";
import { colors } from "../common/element/elements";

export const Styles = styled.div`
  .hero-button-area {
    position: relative;

    .button-area {
      button.join-button {
        // transform: translateX(0) translateY(-150%);
        box-sizing: content-box;
        display: block;
        margin: auto;
        min-width: 250px;
        height: 45px;
        ${colors.gredient_orange_bg}
        border-radius : 5px;
        font-size: 19px;
        color: #ffffff;
        text-align: center;
        text-transform: capitalize;
        font-weight: 500;
        position: relative;
        border: none;
        padding: 0 30px;
        box-shadow: 0 12px 25px rgb(0 0 0 / 20%);
        &:hover {
          color: rgb(234 135 51);
          background: white;
        }
      }
    }
  }
`;
