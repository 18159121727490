import { actionTypes } from "../actions/common";

/** Initial state */
const initialState = {
  data: {
    member_audit_event: null,
  },
  error: null,
  showLoader: [],
};

/** Reducers */
export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case actionTypes.SEND_MEMBER_AUDIT_EVENT:
      return {
        ...state,
        data: {
          ...state.data,
          member_audit_event: payload.data.user,
        },
        error: null,
      };

    default:
      return state;
  }
};
