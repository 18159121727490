import React, { useEffect, useState,Suspense } from "react";
import axios from "axios";
import { apiUrl } from "./shared/constants";
//import HomeIN from "./HomeIN";
//import HomeUS from "./HomeUS";
import LoaderImage from "./components/LoaderImage";
import common from "./shared/common";
const HomeIN = React.lazy(() => import('./HomeIN'));
const HomeUS = React.lazy(() => import('./HomeUS'));


const Home = (props) => {
  const [homePage, setHomePage] = useState(common.getFromStorage("homePage") || "");

  useEffect(() => {
    if (homePage === "") {
      axios.get(apiUrl.baseApiUrl + apiUrl.detectCountry).then((response) => {
        setHomePage(response.data.country_iso_code);
        common.setToStorage("homePage", response.data.country_iso_code);
      });
    }
  }, []);
  if (homePage !== "") {
    if (homePage === "IN") {
      return (
        <Suspense fallback={<div>Loading...</div>}>
          <HomeIN {...props} />
        </Suspense>
      );
    }
    return (
      <Suspense fallback={<div>Loading...</div>}>
        <HomeUS {...props} />
      </Suspense>
    );
  }
  return <LoaderImage {...props} />;
};

export default Home;
