import { showLoaderAction, stopLoaderAction } from "../user/action";
import { getTransactionsListData } from "./service";

/**
 * Action types
 */
export const actionTypes = {
  TRANSACTIONS_DATA_REQUEST_SUCCESS: "TRANSACTIONS_DATA_REQUEST_SUCCESS",
};

export const receivedTransactionsListDataAction = (payload) => ({
  type: actionTypes.TRANSACTIONS_DATA_REQUEST_SUCCESS,
  payload,
});

export const transactionsListDataAction = (data) => {

  return (dispatch) => {

    dispatch(showLoaderAction("getTransactionsListData"));

    return getTransactionsListData(data)
      .then((response) => {
        dispatch(stopLoaderAction("getTransactionsListData"));

        if (response) dispatch(receivedTransactionsListDataAction(response));
      })
      .catch((error) => {
        dispatch(stopLoaderAction("getTransactionsListData"));
      });
  };
};