import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { newRequestAction } from "./action";
import UserConnect from "./components/UserConnect";

const Connect = (props) => {
  return <UserConnect {...props} redirectTo={props.history.push} />;
};

const mapStateToProps = (state) => ({
  user: state.user
});

const mapDispatchToProps = (dispatch) => ({
  newRequest: (data) => dispatch(newRequestAction(data))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Connect));
