import { actionTypes } from "./action";

/** Initial state */
const initialState = {
  data: {
    categories: [],
    countries: [],
    country_states: [],
    state_cities: [],
    city_schools: [],
    category_subcategories: [],
    dataLoaded: 0,
  },
  error: null,
};

/** Reducers */
export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case actionTypes.MASTER_DATA_REQUEST_SUCCESS:
      let newData = payload.data;
      if (payload.data.categories) {
        newData.categories = payload.data.categories;
      }
      if (payload.data.countries) {
        newData.countries = payload.data.countries;
      }
      if (payload.data.country_states) {
        newData.country_states = payload.data.country_states;
      }
      if (payload.data.state_cities) {
        newData.state_cities = payload.data.state_cities;
      }
      if (payload.data.city_schools) {
        newData.city_schools = payload.data.city_schools;
      }
      if (payload.data.category_subcategories) {
        newData.category_subcategories = payload.data.category_subcategories;
      }
      if (payload.data.subcategory_subsubcategories) {
        newData.subcategory_subsubcategories = payload.data.subcategory_subsubcategories;
      }
      return {
        ...state,
        data: {
          ...state.data,
          ...newData,
        },
      };
    default:
      return state;
  }
};
