import common from "../../shared/common";
import { setDashboardFormDataAction } from "../dashboard/action";
import {
  userLogin,
  verifyOTP,
  updateProfile,
  getProfileData,
  userLoginWithEmail,
  verifyEmailOTP,
  verifyPassword,
  updatePassword,
  forgotPassword,
  verifyResetPasswordOTP,
} from "./service";

/**
 * Action types
 */
export const actionTypes = {
  VERIFY_OTP_REQUEST_SUCCESS: "VERIFY_OTP_REQUEST_SUCCESS",
  USER_LOGIN_REQUEST_SUCCESS: "USER_LOGIN_REQUEST_SUCCESS",
  USER_LOGIN_EMAIL_REQUEST_SUCCESS: "USER_LOGIN_EMAIL_REQUEST_SUCCESS",
  VERIFY_EMAIL_OTP_REQUEST_SUCCESS: "VERIFY_EMAIL_OTP_REQUEST_SUCCESS",
  VERIFY_RESET_PASSWORD_OTP_REQUEST_SUCCESS: "VERIFY_RESET_PASSWORD_OTP_REQUEST_SUCCESS",
  VERIFY_PASSWORD_REQUEST_SUCCESS: "VERIFY_PASSWORD_REQUEST_SUCCESS",
  UPDATE_PASSWORD_REQUEST_SUCCESS: "UPDATE_PASSWORD_REQUEST_SUCCESS",
  FORGOT_PASSWORD_REQUEST_SUCCESS: "FORGOT_PASSWORD_REQUEST_SUCCESS",
  UPDATE_PROFILE_REQUEST_SUCCESS: "UPDATE_PROFILE_REQUEST_SUCCESS",
  PROFILE_MASTER_DATA_REQUEST_SUCCESS: "PROFILE_MASTER_DATA_REQUEST_SUCCESS",
  SHOW_LOADER: "SHOW_LOADER",
  STOP_LOADER: "STOP_LOADER",
  UNMOUNT_USER: "UNMOUNT_USER",
};

export const showLoaderAction = (payload) => ({
  type: actionTypes.SHOW_LOADER,
  payload,
});

export const stopLoaderAction = (payload) => ({
  type: actionTypes.STOP_LOADER,
  payload,
});

export const unmountUserAction = () => ({
  type: actionTypes.UNMOUNT_USER,
});

export const receivedUserLoginAction = (payload) => ({
  type: actionTypes.USER_LOGIN_REQUEST_SUCCESS,
  payload,
});

export const receivedUserLoginEmailAction = (payload) => ({
  type: actionTypes.USER_LOGIN_EMAIL_REQUEST_SUCCESS,
  payload,
});

export const receivedVerifyEmailOTPAction = (payload) => ({
  type: actionTypes.VERIFY_EMAIL_OTP_REQUEST_SUCCESS,
  payload,
});

export const receivedVerifyPasswordAction = (payload) => ({
  type: actionTypes.VERIFY_PASSWORD_REQUEST_SUCCESS,
  payload,
});

export const receivedForgotPasswordAction = (payload) => ({
  type: actionTypes.FORGOT_PASSWORD_REQUEST_SUCCESS,
  payload,
});

export const receivedVerifyResetPasswordOTPAction = (payload) => ({
  type: actionTypes.VERIFY_RESET_PASSWORD_OTP_REQUEST_SUCCESS,
  payload,
});

export const receivedUpdatePasswordAction = (payload) => ({
  type: actionTypes.UPDATE_PASSWORD_REQUEST_SUCCESS,
  payload,
});

export const userLoginAction = (data) => {
  return (dispatch) => {
    dispatch(showLoaderAction("userLogin"));

    return userLogin(data)
      .then((response) => {
        dispatch(stopLoaderAction("userLogin"));

        if (response && response.access_token) {
          dispatch(receivedUserLoginAction(response));
          localStorage.setItem("token", response.access_token);
          localStorage.setItem("user", JSON.stringify(data));
          return true;
        } else {
          return false;
        }
      })
      .catch((error) => {
        dispatch(stopLoaderAction("userLogin"));
        return false;
      });
  };
};

export const receivedVerifyOTPAction = (payload) => ({
  type: actionTypes.VERIFY_OTP_REQUEST_SUCCESS,
  payload,
});

export const verifyOTPAction = (data) => {
  return (dispatch) => {
    dispatch(showLoaderAction("verifyOTP"));

    return verifyOTP(data)
      .then((response) => {
        dispatch(stopLoaderAction("verifyOTP"));

        if (response) {
          dispatch(receivedVerifyOTPAction(response));
          common.setToStorage("isAuthenticated", true);
          if (response.data.user.is_profile_completed === 1) {
            common.setToStorage("userName", response.data.user && response.data.user.name);
            common.setToStorage("profile", response.data.user && JSON.stringify(response.data.user));
            common.setToStorage("features", response.data.master_features && JSON.stringify(response.data.master_features));
          }
          dispatch(setDashboardFormDataAction({ category_id: response.data.user.category_id, subcategory_id: response.data.user.subcategory_id }));
          return response.data.user;
        } else {
          return false;
        }
      })
      .catch((error) => {
        dispatch(stopLoaderAction("verifyOTP"));
        return false;
      });
  };
};

export const receivedUpdateProfileAction = (payload) => ({
  type: actionTypes.UPDATE_PROFILE_REQUEST_SUCCESS,
  payload,
});

export const updateProfileAction = (data) => {
  return (dispatch) => {
    const updatedData = { ...data, name: data.full_name };
    dispatch(showLoaderAction("updateProfile"));

    return updateProfile(updatedData)
      .then((response) => {
        dispatch(stopLoaderAction("updateProfile"));

        if (response) {
          common.setToStorage("isAuthenticated", true);
          dispatch(receivedUpdateProfileAction(response));
          if (response.data.user.is_profile_completed === 1) {
            common.setToStorage("userName", response.data.user && response.data.user.name);
            common.setToStorage("profile", response.data.user && JSON.stringify(response.data.user));
            common.setToStorage("features", response.data.master_features && JSON.stringify(response.data.master_features));
          }
          dispatch(setDashboardFormDataAction({ category_id: data.category_id, subcategory_id: data.subcategory_id }));
          return true;
        } else {
          return false;
        }
      })
      .catch((error) => {
        dispatch(stopLoaderAction("updateProfile"));
        return false;
      });
  };
};

export const receivedProfileMasterDataAction = (payload) => ({
  type: actionTypes.PROFILE_MASTER_DATA_REQUEST_SUCCESS,
  payload,
});

export const profileMasterDataAction = (data) => {
  return (dispatch) => {
    dispatch(showLoaderAction("getProfileData"));

    return getProfileData(data)
      .then((response) => {
        dispatch(stopLoaderAction("getProfileData"));
        if (response) {
          dispatch(receivedProfileMasterDataAction(response));
          dispatch(setDashboardFormDataAction({ category_id: response.data.user.category_id, subcategory_id: response.data.user.subcategory_id }));
        }
      })
      .catch((error) => {
        dispatch(stopLoaderAction("getProfileData"));
      });
  };
};

export const handleReloadAppAction = () => {
  return (dispatch) => {
    let _user = localStorage.getItem("user");

    if (_user)
      dispatch(
        profileMasterDataAction({
          country_code: JSON.parse(_user).country_code,
        })
      );
  };
};

export const userLoginWithEmailAction = (data) => {
  return (dispatch) => {
    dispatch(showLoaderAction("userLoginWithEmail"));

    return userLoginWithEmail(data)
      .then((response) => {
        dispatch(stopLoaderAction("userLoginWithEmail"));

        if (response && response.access_token) {
          dispatch(receivedUserLoginEmailAction(response));
          localStorage.setItem("token", response.access_token);
          localStorage.setItem("user", JSON.stringify(data));
          return response;
        } else {
          return false;
        }
      })
      .catch((error) => {
        dispatch(stopLoaderAction("userLoginWithEmail"));
        return false;
      });
  };
};

export const verifyEmailOTPAction = (data) => {
  return (dispatch) => {
    dispatch(showLoaderAction("verifyEmailOTP"));

    return verifyEmailOTP(data)
      .then((response) => {
        dispatch(stopLoaderAction("verifyEmailOTP"));

        if (response) {
          dispatch(receivedVerifyEmailOTPAction(response));
          if (response.data.passwordSet === 1) {
            common.setToStorage("isAuthenticated", true);
            if (response.data.user.is_profile_completed === 1) {
              common.setToStorage("userName", response.data.user && response.data.user.name);
              common.setToStorage("profile", response.data.user && JSON.stringify(response.data.user));
              common.setToStorage("features", response.data.master_features && JSON.stringify(response.data.master_features));
            }
            dispatch(setDashboardFormDataAction({ category_id: response.data.user.category_id, subcategory_id: response.data.user.subcategory_id }));
          }
          return response.data;
        } else {
          return false;
        }
      })
      .catch((error) => {
        dispatch(stopLoaderAction("verifyEmailOTP"));
        return false;
      });
  };
};

export const verifyPasswordAction = (data) => {
  return (dispatch) => {
    dispatch(showLoaderAction("verifyPassword"));

    return verifyPassword(data)
      .then((response) => {
        dispatch(stopLoaderAction("verifyPassword"));

        if (response) {
          dispatch(receivedVerifyPasswordAction(response));
          common.setToStorage("isAuthenticated", true);
          if (response.data.user.is_profile_completed === 1) {
            common.setToStorage("userName", response.data.user && response.data.user.name);
            common.setToStorage("profile", response.data.user && JSON.stringify(response.data.user));
            common.setToStorage("features", response.data.master_features && JSON.stringify(response.data.master_features));
          }
          dispatch(setDashboardFormDataAction({ category_id: response.data.user.category_id, subcategory_id: response.data.user.subcategory_id }));
          return response.data;
        } else {
          return false;
        }
      })
      .catch((error) => {
        dispatch(stopLoaderAction("verifyPassword"));
        return false;
      });
  };
};

export const forgotPasswordAction = (data) => {
  return (dispatch) => {
    dispatch(showLoaderAction("forgotPassword"));

    return forgotPassword(data)
      .then((response) => {
        dispatch(stopLoaderAction("forgotPassword"));

        if (response && response.access_token) {
          dispatch(receivedUserLoginEmailAction(response));
          localStorage.setItem("token", response.access_token);
          return response;
        } else {
          return false;
        }
      })
      .catch((error) => {
        dispatch(stopLoaderAction("forgotPassword"));
        return false;
      });
  };
};

export const verifyResetPasswordOTPAction = (data) => {
  return (dispatch) => {
    dispatch(showLoaderAction("verifyResetPasswordOTP"));

    return verifyResetPasswordOTP(data)
      .then((response) => {
        dispatch(stopLoaderAction("verifyResetPasswordOTP"));

        if (response) {
          dispatch(receivedVerifyResetPasswordOTPAction(response));
          return response.data;
        } else {
          return false;
        }
      })
      .catch((error) => {
        dispatch(stopLoaderAction("verifyResetPasswordOTP"));
        return false;
      });
  };
};

export const updatePasswordAction = (data) => {
  return (dispatch) => {
    dispatch(showLoaderAction("updatePassword"));

    return updatePassword(data)
      .then((response) => {
        dispatch(stopLoaderAction("updatePassword"));

        if (response) {
          dispatch(receivedUpdatePasswordAction(response));
          return response.data;
        } else {
          return false;
        }
      })
      .catch((error) => {
        dispatch(stopLoaderAction("updatePassword"));
        return false;
      });
  };
};
