import React, { Component, useState } from "react";
import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import { Styles } from "./styles/tabBox.js";
import ArrowCircleRightOutlinedIcon from "@mui/icons-material/ArrowCircleRightOutlined";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";

const TabBox = (props) => {
  const [title, setTitle] = useState("Who We Are?");

  const handleSelect = (e) => {
    switch (e) {
      case "who":
        setTitle("Who We Are?");
        break;
      case "mission":
        setTitle("Our Mission");
        break;
      case "vision":
        setTitle("Our Vision");
        break;
      case "learning":
        setTitle("Affordable Learning");
        break;
      case "teachers":
        setTitle("Empowers Teachers");
        break;
      case "scientists":
        setTitle("Future Scientists");
        break;
      default:
        setTitle("Why Alyss");
        break;
    }
  };

  return (
    <Styles>
      {/* Tab Box Area */}
      <section className="tab-section">
        <Container>
          <Tab.Container defaultActiveKey="who" onSelect={handleSelect}>
            <Row>
              <Col lg="12" style={{ marginBottom: "42px" }}>
                <h2 className="tab-title text-center">{title}</h2>
              </Col>
              <Col lg="3" md="4">
                <Nav className="flex-column">
                  <Nav.Item>
                    <Nav.Link eventKey="who">
                      <ArrowCircleRightOutlinedIcon /> Who We Are?
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="why">
                      <ArrowCircleRightOutlinedIcon /> Why Alyss
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="mission">
                      <ArrowCircleRightOutlinedIcon /> Our Mission
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="vision">
                      <ArrowCircleRightOutlinedIcon /> Our Vision
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
              </Col>
              <Col lg="9" md="8">
                <Tab.Content>
                  <Tab.Pane eventKey="who">
                    <p className="tab-desc">
                      Alyss (Animation Lab for Young Science Students) is a pioneering introduction to a new era in Science/Maths/Robotics Learning.
                    </p>
                    <p className="tab-desc">
                      We bring explanations of Science/Maths concepts using 3D HD animations to the schools and homes. The content is specially
                      designed to enhance the scientific temperament of students while improving their grades in school (Board Examinations) and
                      performance in competitive examinations (Medical and Engineering Entrance Tests).
                    </p>
                    <p className="tab-desc">
                      Founded by an IIT alumnus with a strong passion for Science and Maths, Alyss is a team of highly qualified content and animation
                      experts sharing a zeal for Science.
                    </p>
                  </Tab.Pane>
                  <Tab.Pane eventKey="why">
                    <p className="tab-desc">
                      We focus on inquiry-based learning and our high quality content uses storytelling techniques and Q and A, real life examples
                      that helps the students absorb the concepts deeply and help them think further and beyond.
                    </p>
                    <p className="tab-desc">
                      "If you can't explain it simply, you don't understand it well enough", we follow this principle in designing the content for
                      classrooms ensuring that explanations are lucid and engaging enough for students to grasp and understand. With the
                      fast-diminishing attention span of these complex concepts in 3D HD animations recreates excitement and improves students
                      understanding.
                    </p>
                    <p className="tab-desc">
                      Alyss Robotics program is Alyss's signature program that helps build life skills in young students. We simplify robotics
                      concepts through animations, introduce kids to programming and train them on robot missions. We are your school's and child's
                      perfect partner in STEM learning.
                    </p>
                    <p className="tab-desc">
                      The content from Alyss serves as a springboard to introduce science, Maths and robotics concepts in the classroom. It is a
                      powerful aid for teachers and helps enhance the curiosity and scientific temperament of students.
                    </p>
                    <p className="tab-desc">Our Alyss dashboard is a smart solution that makes it easy for teachers to:</p>
                    <ul className="list-unstyled check-list">
                      <li>
                        <CheckCircleOutlineOutlinedIcon />
                        Manage lessons
                      </li>
                      <li>
                        <CheckCircleOutlineOutlinedIcon />
                        Track student progress
                      </li>
                      <li>
                        <CheckCircleOutlineOutlinedIcon />
                        Generate online tests
                      </li>
                      <li>
                        <CheckCircleOutlineOutlinedIcon />
                        Evaluate performance
                      </li>
                    </ul>
                  </Tab.Pane>
                  <Tab.Pane eventKey="mission">
                    <p className="tab-desc">
                      To transform our education from rote learning to experiential and STEM learning through digitized Science, Maths and Robotics
                      solutions.
                    </p>
                  </Tab.Pane>
                  <Tab.Pane eventKey="vision">
                    <p className="tab-desc">
                      We want to reach remotest places in India and abroad with our STEM pedagogy to make Science and Maths exciting, accessible and
                      affordable for all children.
                    </p>
                  </Tab.Pane>
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>
        </Container>
      </section>
    </Styles>
  );
};

export default TabBox;
