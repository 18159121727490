import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import common from './common';
import { webUrl } from './constants';

export const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route { ...rest } render={ (props) => (
       common.getFromStorage('isAuthenticated') === 'true'
            ? <Component { ...props } />
            : <Redirect to={ webUrl.home } />
    ) } />
)