import React from "react";
import { Row, Col } from "react-bootstrap";
import common from "../../../shared/common";
import DownloadIcon from '@mui/icons-material/Download';
import { Button } from "@mui/material";

function NcertBook(props) {
  const downloadResource = (data, url) => {
    // if (props.chapter.can_user_access || data.allow_free_access) {
    window.open(url);
    // common.downloadResource(url);
    // } else {
    //   alert("You need to subscribe the course first");
    // }
  };
  return (
    <>
      <Row className='margin-bottom-15'>
        <Col md="12">
          <h5 className='grid-header ml-0'>NCERT Books</h5>
        </Col>
      </Row>
      <Row>
        <Col md="12">
          <div className="course-desc">
            {props.chapter.ncert_books.map((data, i) => (
              <div className="event-box" key={i}>
                <Row>
                  <Col xl="12" lg="12" md="12">
                    <div className="event-content">
                      <div
                        className="content-box"
                        style={{
                          width: "100%",
                          position: "relative",
                          left: "0",
                          top: 0,
                        }}
                      >
                        <Row>
                          <Col md="9">
                            <div className="event-title">
                              <h6 style={{ marginTop: 10 }}>
                                {data.document_name}
                              </h6>
                            </div>
                          </Col>
                          <Col md="3" className="text-center">
                            <div
                              className="join-btn"
                              style={{ display: "flex", justifyContent: "right" }}
                            ><Button
                              onClick={() => {
                                downloadResource(data, data.document_file_url);
                              }}
                            >
                                Download <DownloadIcon />
                              </Button>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            ))}
          </div>
        </Col>
      </Row>
    </>
  );
}

export default NcertBook;
