import React, { useEffect, useState } from "react";

import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import FooterTwo from "../../components/FooterTwo";
import UserHeader from "../../components/UserHeader";
import { Container, Row, Col } from "react-bootstrap";
import { Styles } from "../dashboard/styles/dashboard.js";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Collapse,
  Divider,
  Grid,
  IconButton,
  Link,
  Paper,
  Stack,
  Table,
  Typography,
} from "@mui/material";
import { ArrowLeft, Home, Replay, Share } from "@mui/icons-material";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import CloseIcon from "@mui/icons-material/Close";
import { orderDetailsAction } from "./action";
import { webUrl } from "../../shared/constants";
import common from "../../shared/common";

function OrderDetails(props) {
  const {
    match: { params },
  } = props;
  const [quizDataLoaded, setQuizDataLoaded] = useState(false);
  const [open, setOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [severity, setSeverity] = useState("");

  const [orderData, setOrderData] = useState({
    id: 5,
    member_id: 2,
    address_id: 1,
    checked_out_at: "2021-12-31T19:38:04.000Z",
    completed_at: "2021-12-31T19:38:55.000Z",
    cancelled_at: null,
    order_status: "completed",
    payment_status: "paid",
    paid_at: "2021-12-31T19:38:55.000Z",
    order_code: "84362335",
    total_amount: "700.00",
    payable_amount: "700.00",
    discount_amount: "0.00",
    discount_percent: "0.00",
    coupon_code: null,
    currency: "INR",
    coupon_id: null,
    items_count: 1,
    is_read: 0,
    memberId: 2,
    items: [
      {
        id: 5,
        order_id: 5,
        subsubcategory_id: 1,
        subscription_id: 2,
        display_name: "Silver Jumbo Pack",
        duration_in_days: 90,
        duration_text: "3 Months",
        price: "1000",
        discounted_price: "700",
        currency: "INR",
        is_valid: 1,
        valid_upto: "2022-03-31T18:38:55.000Z",
        orderId: 5,
        subsubcategoryId: 1,
        subscriptionId: 2,
        subsubcategory: {
          subsubcategory_image_url: "",
          id: 1,
          name: "Mathematics",
          description: "",
          status: 1,
          subcategory_id: 3,
          student_package_id: 1,
          teacher_package_id: 1,
          subsubcategory_image: "uploads/subsubcategories/images/1638288885196.png",
          subcategoryId: 3,
        },
        subscription: {
          id: 2,
          name: "Student Silver",
          display_name: "Silver Jumbo Pack",
          duration_in_days: 90,
          duration_text: "3 Months",
          price_in_inr: "1000",
          discounted_price_in_inr: "700",
          price_in_usd: "15",
          discounted_price_in_usd: "12",
          status: 1,
          package_id: 1,
          PackageId: 1,
          packageId: 1,
          package: { id: 1, name: "Student Package", display_name: "Student Package", description: "undefined", display_image: "", status: 1 },
        },
      },
    ],
    transactions: [
      {
        id: 1,
        order_id: 5,
        payment_gateway_id: 1,
        payment_gateway_response:
          '{"BANKNAME":"HDFC Bank","BANKTXNID":"777001882251345","CURRENCY":"INR","GATEWAYNAME":"HDFC","MID":"MNepyK66855273340853","ORDERID":"84362335","PAYMENTMODE":"CC","RESPCODE":"01","RESPMSG":"Txn Success","STATUS":"TXN_SUCCESS","TXNAMOUNT":"700.00","TXNDATE":"2022-01-01 01:08:07.0","TXNID":"20220101111212800110168785903326903"}',
        bank_name: "HDFC Bank",
        bank_transaction_id: "777001882251345",
        currency: "INR",
        gateway_name: "HDFC",
        payment_mode: "Credit Card",
        response_code: "01",
        response_message: "Txn Success",
        status: "TXN_SUCCESS",
        amount: "700.00",
        transaction_at: "2022-01-01T06:08:07.000Z",
        transaction_id: "20220101111212800110168785903326903",
        orderId: 5,
      },
    ],
  });

  useEffect(() => {
    props.orderDetails([params.type]); // To do: params.type should be default one
    setQuizDataLoaded(false);
  }, []);
  if (!quizDataLoaded) {
    if (props.order) {
      setOrderData(props.order);
      if (+props.order.is_read === 0) {
        setOpen(true);
      }
      if (props.order.payment_status === "paid") {
        setAlertMessage("Thank you! Payment for your order is successful");
        setSeverity("success");
      }
      setQuizDataLoaded(true);
    }
  }

  return (
    <div className="main-wrapper course-details-page">
      {/* Header */}
      <UserHeader />
      <Styles>
        {/* Course Grid */}
        <section className="dashboard-grid-area">
          <Container>
            <Row>
              <Col>
                <Row>
                  <Col lg="9" md="12" sm="12" style={{ margin: "0 auto" }}>
                    <Row>
                      <Col lg="12">
                        <Box sx={{ width: "100%" }}>
                          <Collapse in={open}>
                            <Alert
                              severity={severity}
                              action={
                                <IconButton
                                  aria-label="close"
                                  color="inherit"
                                  size="small"
                                  onClick={() => {
                                    setOpen(false);
                                  }}
                                >
                                  <CloseIcon fontSize="inherit" />
                                </IconButton>
                              }
                              sx={{ mb: 2, fontSize: "15px", fontFamily: "Manrope" }}
                            >
                              {alertMessage}
                            </Alert>
                          </Collapse>
                        </Box>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="12">
                        <h3 style={{ padding: "10px", marginBottom: "20px" }}>Order #: {orderData.order_code}</h3>
                      </Col>
                    </Row>
                    <Card sx={{ minWidth: 275 }}>
                      <CardContent>
                        <Grid container spacing={2}>
                          <Grid item sm={12}>
                            <Paper elevation={1} square sx={{ padding: "10px" }}>
                              <div className="product-list table-responsive">
                                <Table className="table">
                                  <tbody>
                                    {orderData && (
                                      <>
                                        <tr>
                                          <td style={{ border: "none", padding: "10px" }}>
                                            <Typography className="table-text">
                                              <strong>Order Date:</strong> {common.getISTFormattedDate(orderData.checked_out_at)}
                                            </Typography>
                                          </td>
                                          <td style={{ border: "none", padding: "10px" }}>
                                            <Typography className="table-text">
                                              <strong>Payment Status:</strong> {common.capitalizeFirstLetter(orderData.payment_status)}
                                            </Typography>
                                          </td>
                                        </tr>
                                        {orderData.transactions && orderData.payment_status === "paid" && orderData.transactions.length > 0 && (
                                          <>
                                            <tr>
                                              <td style={{ border: "none", padding: "10px" }}>
                                                <Typography className="table-text">
                                                  <strong>Payment Date:</strong>{" "}
                                                  {common.getISTFormattedDate(orderData.transactions[0].transaction_at)}
                                                </Typography>
                                              </td>
                                              <td style={{ border: "none", padding: "10px" }}>
                                                <Typography className="table-text">
                                                  <strong>Payment Mode:</strong>{" "}
                                                  {common.capitalizeFirstLetter(orderData.transactions[0].payment_mode)}
                                                </Typography>
                                              </td>
                                            </tr>
                                            <tr>
                                              <td style={{ border: "none", padding: "10px" }}>
                                                <Typography className="table-text">
                                                  <strong>Bank:</strong> {orderData.transactions[0].bank_name}
                                                </Typography>
                                              </td>
                                              <td style={{ border: "none", padding: "10px" }}>
                                                <Typography className="table-text">
                                                  <strong>Bank Transaction ID:</strong> {orderData.transactions[0].bank_transaction_id}
                                                </Typography>
                                              </td>
                                            </tr>
                                          </>
                                        )}
                                      </>
                                    )}
                                  </tbody>
                                </Table>
                              </div>
                            </Paper>
                          </Grid>
                          <Grid item sm={9}>
                            <Paper elevation={1} square sx={{ padding: "10px" }}>
                              <div className="product-list table-responsive">
                                <Table className="table">
                                  <thead>
                                    <tr>
                                      <th className="product-thumbnail"></th>
                                      <th className="product-name">Course</th>
                                      <th className="product-price">Price</th>
                                      <th className="product-subtotal">Package Subscription</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {orderData &&
                                      orderData.items &&
                                      orderData.items.map((orderItem) => {
                                        return (
                                          <tr key={orderItem.id}>
                                            <td className="product-thumbnail">
                                              <Link
                                                onClick={(e) => {
                                                  e.preventDefault();
                                                  props.history.push(webUrl.subjectDetails + "/" + orderItem.subsubcategory.id);
                                                }}
                                              >
                                                <img
                                                  src={
                                                    orderItem.subsubcategory.subsubcategory_image_url
                                                      ? orderItem.subsubcategory.subsubcategory_image_url
                                                      : process.env.PUBLIC_URL + `/assets/images/product-01.jpg`
                                                  }
                                                  alt=""
                                                />
                                              </Link>
                                            </td>
                                            <td className="product-title">
                                              <Link
                                                onClick={(e) => {
                                                  e.preventDefault();
                                                  props.history.push(webUrl.subjectDetails + "/" + orderItem.subsubcategory.id);
                                                }}
                                              >
                                                {orderItem.subsubcategory.name}
                                              </Link>
                                            </td>
                                            <td className="product-price">
                                              <span className="amount">
                                                {orderData.currency === "INR" ? <CurrencyRupeeIcon /> : <AttachMoneyIcon />}
                                                {orderData.currency === "INR"
                                                  ? orderItem.subscription.discounted_price_in_inr
                                                  : orderItem.subscription.discounted_price_in_usd}
                                              </span>
                                            </td>
                                            <td className="product-subtotal">
                                              <span className="subtotal">
                                                {orderItem.subscription.name} - {orderItem.subscription.duration_text}
                                              </span>
                                            </td>
                                          </tr>
                                        );
                                      })}
                                  </tbody>
                                </Table>
                              </div>
                            </Paper>
                          </Grid>
                          <Grid item sm={3}>
                            <Paper elevation={1} square sx={{ padding: "10px" }}>
                              <div className="cart-summary">
                                <div className="cs-title text-center">
                                  <h5>Order Summary</h5>
                                </div>
                                <div className="cs-content">
                                  <ul className="list-unstyled">
                                    <li>
                                      Sub Total{" "}
                                      <span>
                                        {orderData.currency === "INR" ? <CurrencyRupeeIcon /> : <AttachMoneyIcon />}
                                        {orderData.payable_amount}
                                      </span>
                                    </li>
                                    {/* <li>
                                                Discount (10%){" "}
                                                <span>$14.70</span>
                                            </li> */}
                                  </ul>
                                  <p className="cart-total">
                                    Grand Total{" "}
                                    <span>
                                      {orderData.currency === "INR" ? <CurrencyRupeeIcon /> : <AttachMoneyIcon />}
                                      {orderData.total_amount}
                                    </span>
                                  </p>
                                </div>
                              </div>
                            </Paper>
                          </Grid>
                        </Grid>
                      </CardContent>
                      <Divider />
                      <CardActions>
                        <Box sx={{ flexGrow: 1 }}>
                          <Grid container>
                            <Grid item xs={6} sx={{ textAlign: "center" }}>
                              <Stack spacing={2} direction={"row"}>
                                <Button
                                  variant={"contained"}
                                  startIcon={<ArrowLeft />}
                                  className={"retry_button"}
                                  style={{ margin: "10px" }}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    props.history.push(webUrl.userTransactions);
                                  }}
                                >
                                  My Orders
                                </Button>
                                <Button
                                  variant={"contained"}
                                  startIcon={<Home />}
                                  className={"dashboard_button"}
                                  style={{ margin: "10px" }}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    props.history.push(webUrl.userDashboard);
                                  }}
                                >
                                  Dashboard
                                </Button>
                              </Stack>
                            </Grid>
                          </Grid>
                        </Box>
                      </CardActions>
                    </Card>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </section>
      </Styles>

      {/* Footer 2 */}
      <FooterTwo />
    </div>
  );
}

const mapStateToProps = (state) => ({
  order: state.order.data.order,
});

const mapDispatchToProps = (dispatch) => ({
  orderDetails: (data) => dispatch(orderDetailsAction(data)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(OrderDetails));
