import styled from "styled-components";
import { colors } from "../../../components/common/element/elements.js";

export const Styles = styled.div`

    .model-message-intro {
        position: fixed;
        top: 0;
        left: 0;
        height: 100vh;
        width: 100%;
        background: rgba(0, 0, 0, 0.9);
        z-index: 9999;
    }   

    .close-message-intro {
        position: absolute;
        top: 15px;
        right: 22px;
        width: 25px;
        cursor: pointer;

        img{
            height: 30px;
        }
    }

    .model-message-intro .pop-vid-container {
        max-width: 800px;
        max-height: 500px;
        width: 100%;
        height: 100%;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }

    .embed-responsive {
        position: relative;
        display: block;
        width: 100%;
        padding: 0;
        overflow: hidden;
    }

    .embed-responsive .embed-responsive-item,.embed-responsive iframe, .embed-responsive video {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: 0;
    }

    .event-faq-area {        
        .event-area {
            
            .sec-title {
                h4 {
                    color         : ${colors.black1};
                    font-weight   : 600;
                    text-transform: uppercase;
                    margin-bottom : 37px;

                    span {
                        color: ${colors.green};
                    }

                    @media(max-width: 575px) {
                        margin-bottom: 15px;
                        font-size: 20px;
                    }
                }
            }

            .event-box {
                margin-bottom: 35px;

                .event-date {
                    width : 70px;
                    height: 70px;
                    border-radius : 50%;
                    color       : #fff;
                    margin-top  : 8px;
                    margin-right: 30px;
                    position    : relative;

                    &::before {
                        content   : "";
                        position  : absolute;
                        width     : 70px;
                        height    : 70px;
                        background: ${colors.green};
                        top       : 0;
                        left      : 0;
                        border-radius : 50% 3px 50% 50%;
                        transform: rotate(45deg);
                        z-index  : -1;
                    }

                    p {
                        font-size  : 17px;
                        max-width  : 50px;
                        margin     : auto;
                        line-height: 20px;
                        padding-top: 13px;

                        @media(max-width: 991px) {
                            font-size: 15px;
                            max-width: 56px;
                            padding-left: 14px;
                        }
                    }
                }

                .event-details {
                    margin-top: 5px;

                    h6 {
                        a {
                            color        : ${colors.black1};
                            display      : inline-block;
                            font-weight  : 600;
                            margin-bottom: 10px;

                            &:hover {
                                color: ${colors.green};
                            }
                        }
                    }

                    ul {
                        margin-bottom: 5px;

                        li {
                            font-size  : 14px;
                            color      : ${colors.text3};
                            font-weight: 500;

                            svg {
                                font-size   : 15px;
                                margin-right: 3px;
                                color       : ${colors.green};
                            }
                        }
                    }

                    p {
                        color      : ${colors.text3};
                        line-height: 22px;
                    }
                }

                &:last-child {
                    margin-bottom : 0;
                }

                @media(max-width: 991px) {
                    margin-bottom: 17px;
                }
            }

            @media(max-width: 767px) {
                margin-bottom: 30px;
            }

            @media(max-width: 575px) {
                margin-bottom: 10px;
            }
        }

        .faq-area {
            .sec-title {
                h4 {
                    color         : ${colors.black1};
                    font-weight   : 600;
                    text-transform: uppercase;
                    margin-bottom : 45px;

                    span {
                        color: ${colors.green};
                    }

                    @media(max-width: 575px) {
                        margin-bottom: 15px;
                        font-size: 20px;
                    }
                }
            }

            .faq-box {
                .faq-item {
                    margin-bottom: 22px;

                    button.accordion-button {
                        border       : none;
                        background   : transparent;
                        margin-bottom: 15px;
                        display      : block;
                        width        : 100%;
                        padding      : 0;
                        text-align   : left;
                        position     : relative;

                        div.accordion-icon {
                            background  : ${colors.green};
                            height      : 18px;
                            text-align  : center;
                            float       : left;
                            margin-right: 12px;
                            position    : relative;

                            svg {
                                font-size   : 20px;
                                color       : #ffffff;
                                width       : 32px;
                                line-height : 18px;
                                padding-left: 2px
                            }

                            &::before {
                                content            : "";
                                position           : absolute;
                                border-width       : 8px 16px;
                                border-style       : solid;
                                border-top-color   : transparent;
                                border-right-color : transparent;
                                border-bottom-color: ${colors.green};
                                border-left-color  : transparent;
                                top                : -16px;
                                left               : 0;
                                z-index            : 1;
                            }

                            &::after {
                                content            : "";
                                position           : absolute;
                                border-width       : 8px 16px;
                                border-style       : solid;
                                border-top-color   : ${colors.green};
                                border-right-color : transparent;
                                border-bottom-color: transparent;
                                border-left-color  : transparent;
                                bottom             : -16px;
                                left               : 0;
                                z-index            : 1;
                            }
                        }

                        p {
                            font-size  : 16px;
                            color      : ${colors.black1};
                            font-weight: 500;
                            line-height: 18px;
                        }
                    }

                    .accordion-content {
                        max-height: 0;
                        overflow  : hidden;
                        transition: max-height 0.2s ease-in-out;

                        p {
                            font-size   : 14px;
                            color       : ${colors.text3};
                            line-height : 28px;
                            padding-left: 45px;
                        }
                    }

                    .margin-bottom-15{                        
                        margin-bottom: 15px;
                    }
                    .accordion-content.show {
                        max-height: 100%;

                        .course-items {

                            .course-item {
                                border: 1px solid ${colors.border1};
                                border-radius : 5px;
                                transition : all 0.2s ease;
                                overflow : hidden;
                                margin-bottom: 30px;
                
                                .course-image {
                                    width              : 100%;
                                    padding-top: 56.25%;
                                    background-size    : cover;
                                    background-position: center;
                                    background-repeat  : no-repeat;
                                    border-radius : 5px 5px 0 0;
                                    position: relative;
                
                                    .author-img {
                                        position: absolute;
                                        left    : 20px;
                                        bottom  : 20px;
                
                                        img {
                                            max-width: 40px;
                                            border-radius : 50%;
                                            margin-right: 5px;
                                        }
                
                                        .title {
                                            background: #ffffff;
                                            padding   : 3px 8px;
                                            border-radius : 5px;
                
                                            p {
                                                font-size    : 12px;
                                                color        : ${colors.black1};
                                                font-weight  : 500;
                                                margin-bottom: -4px;
                                            }
                
                                            span {
                                                font-size  : 11px;
                                                color      : ${colors.text3};
                                                font-weight: 500;
                                            }
                                        }
                
                                    }
                
                                    .course-price {
                                        p {
                                            font-size  : 16px;
                                            color      : #ffffff;
                                            background : ${colors.bg1};
                                            position   : absolute;
                                            right      : 20px;
                                            bottom     : 20px;
                                            padding    : 8px 10px;
                                            font-weight: 500;
                                            border-radius : 5px;
                                        }
                                        button.play-button {
                                            position  : absolute;
                                            z-index   : 10;
                                            top       : 50%;
                                            left      : 50%;
                                            transform : translateX(-50%) translateY(-50%);
                                            box-sizing: content-box;
                                            display   : block;
                                            width     : 32px;
                                            height    : 44px;
                                            border-radius : 50%;
                        
                                            svg {
                                                position   : relative;
                                                font-size  : 40px;
                                                color      : ${colors.bg1};
                                                z-index    : 11;
                                                padding-top: 2px;
                                                margin-left: -2px;
                                            }
                        
                                            &::before {
                                                content   : "";
                                                position  : absolute;
                                                z-index   : 0;
                                                left      : 50%;
                                                top       : 50%;
                                                transform : translateX(-50%) translateY(-50%);
                                                display   : block;
                                                width     : 50px;
                                                height    : 50px;
                                                background: #ffffff;
                                                border-radius : 50%;
                                                animation: pulse-border 1500ms ease-out infinite;
                                            }
                        
                                            &:after {
                                                content   : "";
                                                position  : absolute;
                                                z-index   : 1;
                                                left      : 50%;
                                                top       : 50%;
                                                transform : translateX(-50%) translateY(-50%);
                                                display   : block;
                                                width     : 50px;
                                                height    : 50px;
                                                background: #ffffff;
                                                border-radius : 50%;
                                                transition : all 200ms;
                                            }
                        
                                            &:hover {
                                                svg {
                                                    color: ${colors.green};
                                                }
                                            }
                        
                                            @keyframes pulse-border {
                                                0% {
                                                    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
                                                    opacity  : 1;
                                                }
                        
                                                100% {
                                                    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
                                                    opacity  : 0;
                                                }
                                            }
                                        }
                                    }
                
                                    @media(max-width: 767px) {
                                        height: 185px;
                                    }
                                }
                
                                .course-content {
                                    background: #fff;
                                    padding   : 10px 15px;
                
                                    h6.heading {
                                        overflow-wrap: break-word;
                                        a {
                                            color        : ${colors.black1};
                                            font-weight  : 600;
                                            display      : inline-block;
                
                                            &:hover {
                                                color: ${colors.green};
                                            }
                                        }
                                    }
                
                                    p.desc {
                                        font-size     : 14px;
                                        color         : ${colors.text3};
                                        line-height   : 25px;
                                        border-bottom : 1px solid ${colors.border1};
                                        padding-bottom: 10px;
                                        margin-bottom : 12px;
                                    }
                
                                    .course-face {
                
                                        .duration,
                                        .student {
                                            p {
                                                font-size: 13px;
                                                color    : ${colors.text3};
                
                                                svg {
                                                    font-size     : 16px;
                                                    color         : ${colors.green};
                                                    vertical-align: text-bottom;
                                                    margin-right  : 3px;
                                                }
                                            }
                                        }
                
                                        .rating {
                                            ul {
                                                li {
                                                    margin-right: 0;
                
                                                    svg {
                                                        font-size: 14px;
                                                        color    : ${colors.yellow};
                                                    }
                
                                                    &:last-child {
                                                        font-size: 13px;
                                                        color    : ${colors.text3};
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                
                                &:hover {
                                    box-shadow: 0 12px 25px rgba(0, 0, 0, 0.07);
                                }
                            }
                        }
                        
                        .event-box {
                            margin-bottom: 30px;
                            a {
                              color: ${colors.black1};
                              font-weight: 600;
                              display: inline-block;
                              margin-bottom: 12px;
                        
                              &:hover {
                                color: ${colors.green};
                              }
                            }
                            .event-img {
                              a {
                                img {
                                  border-radius: 5px;
                                }
                              }
                        
                              @media (max-width: 991px) {
                                display: none;
                              }
                            }
                            .event-content {
                              position: relative;
                              .content-box {
                                box-shadow: 0 0px 20px rgba(0, 0, 0, 0.08);
                                padding: 20px;
                                background: #ffffff;
                                border-radius: 5px;
                                position: absolute;
                                top: 22px;
                                left: -9%;
                                z-index: 1;
                                .event-title {
                                  h6 {
                                    margin-bottom: 10px;
                                    a {
                                      color: ${colors.black1};
                                      font-weight: 600;
                        
                                      &:hover {
                                        color: ${colors.green};
                                      }
                                    }
                                  }
                                }
                        
                                .event-time-location {
                                  margin-bottom: 10px;
                                  ul {
                                    li {
                                      font-size: 13px;
                                      color: ${colors.text3};
                                      svg {
                                        font-size: 18px;
                                        color: ${colors.green};
                                        vertical-align: top;
                                      }
                                      &:first-child {
                                        margin-right: 20px;
                                      }
                                    }
                                  }
                                }
                        
                                .event-desc {
                                  p {
                                    font-size: 13px;
                                    color: ${colors.text2};
                                    line-height: 20px;
                                  }
                        
                                  @media (max-width: 767px) {
                                    margin-bottom: 10px;
                                  }
                                }
                        
                                .event-date {
                                  position: relative;
                                  margin-bottom: 20px;
                                  padding-top: 6px;
                                  &:before {
                                    position: absolute;
                                    content: "";
                                    background: ${colors.border1};
                                    width: 1px;
                                    height: 100px;
                                    top: 0;
                                    left: -30px;
                        
                                    @media (max-width: 767px) {
                                      content: none;
                                    }
                                  }
                                  p {
                                    font-size: 20px;
                                    color: ${colors.green};
                                    font-weight: 500;
                                    text-transform: uppercase;
                        
                                    @media (max-width: 767px) {
                                      float: left;
                                      font-size: 18px;
                                      margin-right: 20px;
                                    }
                                  }
                        
                                  @media (max-width: 767px) {
                                    margin-bottom: 0;
                                    padding-top: 0;
                                  }
                                }
                        
                                .join-btn {
                                  button {
                                    cursor: pointer !important;
                                    font-size: 12px;
                                    color: ${colors.black2};
                                    border: 1px solid ${colors.border3};
                                    font-weight: 500;
                                    text-transform: uppercase;
                                    padding: 9px 12px 7px;
                                    border-radius: 5px;
                                    &:hover {
                                      color: #ffffff;
                                      background: ${colors.gr_bg};
                                      border-color: ${colors.green};
                                    }
                        
                                    @media (max-width: 767px) {
                                      float: left;
                                      padding: 5px 10px 3px;
                                    }
                                  }
                                }
                        
                                @media (max-width: 991px) {
                                  position: unset;
                                }
                              }
                            }
                    }

                    &:last-child {
                        margin-bottom : 0;
                    }
                }
            }
        }

        @media(max-width: 767px) {
            padding: 30px 0;
        }
    }
`;