import { actionTypes } from "./action";

/** Initial state */
const initialState = {
  data: {
    generatedTests: []
  },
  error: null
};

/** Reducers */
export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {

    case actionTypes.GENERATED_TESTS_LIST_REQUEST_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          generatedTests: payload.data.generated_tests
        }
      };
    default:
      return state;
  }
};
