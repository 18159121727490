import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Container, Row, Col } from "react-bootstrap";
import FooterTwo from "../../../../components/FooterTwo";
import { Styles } from "../../styles/dashboard.js";
import DashboardMenu from "../DashboardMenu";
import {
  assignPackageToMemberAction,
  getAuthMasterDataAction,
  getMasterDataAction,
  getMemberPackagesDataAction,
  getPartnerProfileDataAction,
} from "../../action";
import LoaderNew from "../../../../components/LoaderNew";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { DataGrid } from "@mui/x-data-grid";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import Autocomplete from "@mui/material/Autocomplete";
import PartnerHeader from "../../../../components/PartnerHeader";
import { webUrl } from "../../../../shared/constants";
import common from "../../../../shared/common";

const StudentPackage = (props) => {
  const {
    match: { params },
  } = props;
  const [open, setOpen] = useState(false);
  // Form Fields
  let addresses = [
    {
      id: 0,
      name: "New Address",
      email: "",
      address: "",
      city: "",
      state: "",
      postal_code: "",
    },
  ];

  if (props.student && props.student.addresses && props.student.addresses.length > 0) {
    addresses = [...addresses, ...props.student.addresses];
  }

  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedCategoryInput, setSelectedCategoryInput] = useState("");
  const [selectedSubcategory, setSelectedSubcategory] = useState(null);
  const [selectedSubcategoryInput, setSelectedSubcategoryInput] = useState("");
  const [selectedSubsubcategory, setSelectedSubsubcategory] = useState(null);
  const [selectedSubsubcategoryInput, setSelectedSubsubcategoryInput] = useState("");
  const [selectedPackage, setSelectedPackage] = useState(null);
  const [selectedPackageMRP, setSelectedPackageMRP] = useState(null);
  const [selectedPackageInput, setSelectedPackageInput] = useState("");
  const [sellingPrice, setSellingPrice] = useState("");
  const [sellingPriceError, setSellingPriceError] = useState("");
  // const [discount, setDiscount] = useState("");
  // const [discountError, setDiscountError] = useState("");
  const [selectedPackageCost, setSelectedPackageCost] = useState(null);
  // const [discountValue, setDiscountValue] = useState(null);
  const [afterWalletBalance, setAfterWalletBalance] = useState(null);
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [selectedAddressInput, setSelectedAddressInput] = useState("");
  const [name, setName] = useState("");
  const [nameError, setNameError] = useState("");
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [address, setAddress] = useState("");
  const [addressError, setAddressError] = useState("");
  const [city, setCity] = useState("");
  const [cityError, setCityError] = useState("");
  const [state, setState] = useState("");
  const [stateError, setStateError] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [postalCodeError, setPostalCodeError] = useState("");
  const [error, setError] = useState(false);

  useEffect(() => {
    props
      .getStudentPackagesData([params.type || 0])
      .then(props.getPartnerProfile())
      .catch((error) => {});
  }, [params.type]);

  useEffect(() => {
    setTimeout(() => {
      handleForm();
    }, 500);
  }, [error]);

  const resetForm = () => {
    setSelectedCategory(null);
    setSelectedCategoryInput("");
    setSelectedSubcategory(null);
    setSelectedSubcategoryInput("");
    setSelectedSubsubcategory(null);
    setSelectedSubsubcategoryInput("");
    setSelectedPackage(null);
    setSelectedPackageInput("");
    setSellingPrice("");
    setSellingPriceError("");
    // setDiscount("");
    // setDiscountError("");
    setSelectedPackageCost(null);
    // setDiscountValue(null);
    setAfterWalletBalance(null);
    setName("");
    setEmail("");
    setAddress("");
    setCity("");
    setState("");
    setPostalCode("");
    setNameError("");
    setEmailError("");
    setAddressError("");
    setCityError("");
    setStateError("");
    setPostalCodeError("");
    setSelectedAddress(null);
    setSelectedAddressInput("");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!handleForm()) {
      const studentId = props.student && props.student.id;
      const studentPackageData = {
        category_id: selectedCategory,
        subcategory_id: selectedSubcategory,
        subsubcategory_id: selectedSubsubcategory,
        subscription_id: selectedPackage,
        selling_price: sellingPrice,
        // discount_percent: discount,
        member_id: studentId,
        address_id: selectedAddress,
        name: name,
        email: email,
        address: address,
        state: state,
        city: city,
        postal_code: postalCode,
      };
      props
        .assignPackageToStudent(studentPackageData)
        .then((response) => {
          resetForm();
          setOpen(false);
          props.getStudentPackagesData([params.type || 0]).then(props.getPartnerProfile());
        })
        .catch((error) => {});
    }
  };

  const handleForm = (key = "", value = "") => {
    const selectedCategoryValue = key === "selectedCategory" ? value : selectedCategory;
    const selectedSubcategoryValue = key === "selectedSubcategory" ? value : selectedSubcategory;
    const selectedSubsubcategoryValue = key === "selectedSubsubcategory" ? value : selectedSubsubcategory;
    const selectedPackageValue = key === "selectedPackage" ? value : selectedPackage;
    const sellingPriceValue = key === "sellingPrice" ? value : sellingPrice;
    const selectedAddressValue = key === "selectedAddress" ? value : selectedAddress;
    const nameValue = key === "name" ? value : name;
    const emailValue = key === "email" ? value : email;
    const addressValue = key === "address" ? value : address;
    const stateValue = key === "state" ? value : state;
    const cityValue = key === "city" ? value : city;
    const postalCodeValue = key === "postalCode" ? value : postalCode;
    let errorState = false;

    if (
      selectedCategoryValue === null ||
      selectedSubcategoryValue === null ||
      selectedSubsubcategoryValue === null ||
      selectedPackageValue === null
    ) {
      errorState = true;
    }

    if (sellingPriceValue === "") {
      setNameError(`Please enter selling price`);
      errorState = true;
    } else if (sellingPriceValue < 0) {
      setSellingPriceError(`Selling Price cannot be less than zero`);
      errorState = true;
    } else if (+sellingPriceValue > 0 && +sellingPriceValue > +selectedPackageMRP) {
      setSellingPriceError(`Selling Price cannot be greater than ${selectedPackageMRP}`);
      errorState = true;
    } else {
      setSellingPriceError("");
    }

    // if (discount !== "") {
    //   if (discount < 0) {
    //     setDiscountError(`Discount percent cannot be less than zero`);
    //     errorState = true;
    //   } else if (props.profile && props.profile.partner && discount > props.profile.partner.partner_commission) {
    //     setDiscountError(`Discount percent cannot be greater than ${props.profile.partner.partner_commission}`);
    //     errorState = true;
    //   } else {
    //     setDiscountError("");
    //   }
    // }
    if (selectedAddressValue === null) {
      errorState = true;
    } else if (selectedAddressValue === 0) {
      if (nameValue === "") {
        setNameError(`Please enter Billing Name`);
        errorState = true;
      } else {
        setNameError(``);
      }
      if (emailValue === "") {
        setEmailError(`Please enter Billing Email`);
        errorState = true;
      } else {
        setEmailError(``);
      }
      if (addressValue === "") {
        setAddressError(`Please enter Billing Address`);
        errorState = true;
      } else {
        setAddressError(``);
      }
      if (stateValue === "") {
        setStateError(`Please enter Billing State`);
        errorState = true;
      } else {
        setStateError(``);
      }
      if (cityValue === "") {
        setCityError(`Please enter Billing City`);
        errorState = true;
      } else {
        setCityError(``);
      }
      if (postalCodeValue === "") {
        setPostalCodeError(`Please enter Billing Postal Code`);
        errorState = true;
      } else {
        setPostalCodeError(``);
      }
    }
    setError(errorState);
    return errorState;
  };

  const handleClickOpen = () => {
    const dataPayload = { modules: ["categories"] };
    if (props.student && props.student.id) {
      setName(props.student.name ? props.student.name : "");
      setEmail(props.student.email ? props.student.email : "");
      setCity(props.student.city && props.student.city.name ? props.student.city.name : "");
      setState(props.student.city && props.student.city.state && props.student.city.state.name ? props.student.city.state.name : "");
    }
    props.getMasterData(dataPayload).then(setOpen(true));
  };

  const handleClose = () => {
    resetForm();
    setOpen(false);
  };
  const handleSellingPriceChange = (e) => {
    setSellingPrice(e.target.value);
    handleForm("sellingPrice", e.target.value);
  };

  // const handleDiscountChange = (e) => {
  //   setDiscount(e.target.value);
  //   if (selectedPackageCost && discount) {
  //     const discountNewValue = (+e.target.value * +selectedPackageCost) / 100;
  //     setDiscountValue(Math.round(discountNewValue));

  //     if (props.profile && props.profile.partner && props.profile.partner.wallet_amount) {
  //       setAfterWalletBalance(+props.profile.partner.wallet_amount - (+selectedPackageCost - discountNewValue));
  //     }
  //   }
  //   handleForm();
  // };

  const handleNameChange = (e) => {
    setName(e.target.value);
    handleForm("name", e.target.value);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    handleForm("email", e.target.value);
  };

  const handleAddressChange = (e) => {
    setAddress(e.target.value);
    handleForm("address", e.target.value);
  };

  const handleStateChange = (e) => {
    setState(e.target.value);
    handleForm("state", e.target.value);
  };

  const handleCityChange = (e) => {
    setCity(e.target.value);
    handleForm("city", e.target.value);
  };

  const handlePostalCodeChange = (e) => {
    setPostalCode(e.target.value);
    handleForm("postalCode", e.target.value);
  };

  const columns = React.useMemo(
    () => [
      {
        field: "packageName",
        headerName: "Package",
        flex: 1,
        renderCell: (params) => (
          <div>
            <Typography sx={{ whiteSpace: "break-spaces" }}>{`${params.row.display_name || ""} - ${params.row.duration_text || ""}`}</Typography>
          </div>
        ),
      },
      {
        field: "valid_upto",
        headerName: "Valid Upto",
        flex: 1,
        renderCell: (params) => (
          <div>
            <Typography sx={{ whiteSpace: "break-spaces" }}>{`${common.getISTFormattedDate(params.row.valid_upto) || ""}`}</Typography>
          </div>
        ),
      },
      {
        field: "currencyPrice",
        headerName: "Price",
        flex: 1,
        renderCell: (params) => (
          <div>
            <Typography sx={{ whiteSpace: "break-spaces" }}>{`${params.row.currency || ""} ${params.row.discounted_price || ""}`}</Typography>
          </div>
        ),
      },
      {
        field: "subject",
        headerName: "Subject",
        flex: 1,
        renderCell: (params) => (
          <div>
            <Typography sx={{ whiteSpace: "break-spaces" }}>{`${params.row.subsubcategory.subcategory.name || ""} / ${
              params.row.subsubcategory.name || ""
            }`}</Typography>
          </div>
        ),
      },
      {
        field: "order_code",
        headerName: "Order Code",
        flex: 1,
        renderCell: (params) => (
          <div>
            <Typography sx={{ whiteSpace: "break-spaces" }}>{`${params.row.order.order_code || ""} / ${
              common.getISTFormattedDate(params.row.order.completed_at) || ""
            }`}</Typography>
          </div>
        ),
      },
    ],
    []
  );

  return (
    <div className="main-wrapper course-page">
      {/* User Header */}
      <PartnerHeader />

      <Styles>
        {/* Course Grid */}
        <section className="dashboard-grid-area">
          <Container>
            <Row>
              <Col lg="3" md="3" sm="12">
                <DashboardMenu {...props} index={props.index || 1} />
              </Col>
              <Col>
                {props.showLoader && props.showLoader.length > 0 && <LoaderNew />}
                {props.showLoader.length === 0 && (
                  <>
                    <Breadcrumbs aria-label="breadcrumb">
                      <Link
                        underline="hover"
                        color="inherit"
                        onClick={(e) => {
                          e.preventDefault();
                          props.history.push(webUrl.partnerDashboard);
                        }}
                      >
                        Home
                      </Link>
                      <Link
                        underline="hover"
                        color="inherit"
                        onClick={(e) => {
                          e.preventDefault();
                          props.history.push(webUrl.studentsList);
                        }}
                      >
                        Manage Students
                      </Link>
                      <Typography color="text.primary">Student's Subscriptions</Typography>
                    </Breadcrumbs>
                    <Typography variant="h5" component="div" gutterBottom sx={{ display: "inline-block", width: "100%" }}>
                      {props.student && props.student.name}'s Subscriptions List
                      <Button variant="outlined" onClick={handleClickOpen} className={"mt-3 mb-3 pull-right"}>
                        Add New Subscription
                      </Button>
                    </Typography>
                    <div style={{ height: 400, width: "100%" }}>
                      <div style={{ display: "flex", height: "100%" }}>
                        <div style={{ flexGrow: 1 }}>
                          <DataGrid
                            rows={props.packages}
                            columns={columns}
                            pageSize={10}
                            rowsPerPageOptions={[10]}
                            getRowHeight={({ id, densityFactor }) => {
                              return 100;
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div>
                      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="lg">
                        <DialogTitle>
                          Add New Subscription to Student - {props.student && props.student.name} -{" "}
                          {props.student && props.student.subcategory && props.student.subcategory.name}
                        </DialogTitle>
                        <DialogContent>
                          <Typography variant="h3" gutterBottom component="div" className="mt-3">
                            Wallet Balance: {props.profile && props.profile.partner && props.profile.partner.wallet_amount}
                          </Typography>
                          <Box component="form" noValidate autoComplete="off">
                            <Grid container spacing={2}>
                              <Grid item xs={12} sm={6} md={4}>
                                <Autocomplete
                                  id="category"
                                  options={props.categories}
                                  value={selectedCategory}
                                  inputValue={selectedCategoryInput}
                                  onChange={(event, newValue) => {
                                    setSelectedCategory(newValue ? newValue.id : null);
                                    setSelectedCategoryInput(newValue ? newValue.name : "");
                                    setSelectedSubcategory(null);
                                    setSelectedSubcategoryInput("");
                                    setSelectedSubsubcategory(null);
                                    setSelectedSubsubcategoryInput("");
                                    setSelectedPackage(null);
                                    setSelectedPackageCost(null);
                                    setSelectedPackageMRP(null);
                                    setSelectedPackageInput("");
                                    if (newValue && newValue.id) {
                                      props.getMasterData({ modules: ["category_subcategories"], category_id: newValue.id });
                                    }
                                  }}
                                  renderOption={(props, option) => (
                                    <Box component="li" {...props}>
                                      {option.name}
                                    </Box>
                                  )}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      variant="filled"
                                      required
                                      label="Select Category"
                                      inputProps={{
                                        ...params.inputProps,
                                        autoComplete: "", // disable autocomplete and autofill
                                      }}
                                    />
                                  )}
                                />
                              </Grid>
                              <Grid item xs={12} sm={6} md={4}>
                                <Autocomplete
                                  id="subcategory"
                                  options={props.subcategories}
                                  value={selectedSubcategory}
                                  inputValue={selectedSubcategoryInput}
                                  onChange={(event, newValue) => {
                                    setSelectedSubcategory(newValue ? newValue.id : null);
                                    setSelectedSubcategoryInput(newValue ? newValue.name : "");
                                    setSelectedSubsubcategory(null);
                                    setSelectedSubsubcategoryInput("");
                                    setSelectedPackage(null);
                                    setSelectedPackageCost(null);
                                    setSelectedPackageMRP(null);
                                    setSelectedPackageInput("");
                                    if (newValue && newValue.id) {
                                      props.getMasterData({ modules: ["subcategory_subsubcategories"], subcategory_id: newValue.id });
                                    }
                                  }}
                                  renderOption={(props, option) => (
                                    <Box component="li" {...props}>
                                      {option.name}
                                    </Box>
                                  )}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      variant="filled"
                                      required
                                      label="Select Subcategory"
                                      inputProps={{
                                        ...params.inputProps,
                                        autoComplete: "", // disable autocomplete and autofill
                                      }}
                                    />
                                  )}
                                />
                              </Grid>
                              <Grid item xs={12} sm={6} md={4}>
                                <Autocomplete
                                  id="subsubcategory"
                                  options={props.subsubcategories}
                                  value={selectedSubsubcategory}
                                  inputValue={selectedSubsubcategoryInput}
                                  onChange={(event, newValue) => {
                                    setSelectedSubsubcategory(newValue ? newValue.id : null);
                                    setSelectedSubsubcategoryInput(newValue ? newValue.name : "");
                                    setSelectedPackage(null);
                                    setSelectedPackageCost(null);
                                    setSelectedPackageMRP(null);
                                    setSelectedPackageInput("");
                                    if (newValue && newValue.id) {
                                      props.getAuthMasterData({ modules: ["subsubcategory_subscriptions"], subsubcategory_id: newValue.id });
                                    }
                                  }}
                                  renderOption={(props, option) => (
                                    <Box component="li" {...props}>
                                      {option.name}
                                    </Box>
                                  )}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      variant="filled"
                                      required
                                      label="Select Subsubcategory"
                                      inputProps={{
                                        ...params.inputProps,
                                        autoComplete: "", // disable autocomplete and autofill
                                      }}
                                    />
                                  )}
                                />
                              </Grid>
                              <Grid item xs={12} sm={6} md={4}>
                                <Autocomplete
                                  id="package"
                                  options={props.subscriptions}
                                  value={selectedPackage}
                                  inputValue={selectedPackageInput}
                                  onChange={(event, newValue) => {
                                    setSelectedPackage(newValue ? newValue.id : null);
                                    setSelectedPackageCost(newValue ? newValue.subscription_price : null);
                                    setSelectedPackageMRP(newValue ? +newValue.discounted_price_in_inr : null);
                                    setSelectedPackageInput(
                                      newValue ? newValue.display_name + " - (" + newValue.duration_text + ") - " + newValue.subscription_price : ""
                                    );
                                    let discountNewValue = 0;
                                    // if (newValue && newValue.discounted_price_in_inr && discount) {
                                    //   discountNewValue = (+discount * +newValue.discounted_price_in_inr) / 100;
                                    //   setDiscountValue(Math.round(discountNewValue));
                                    // }
                                    if (
                                      props.profile &&
                                      props.profile.partner &&
                                      props.profile.partner.wallet_amount &&
                                      newValue &&
                                      newValue.discounted_price_in_inr
                                    ) {
                                      setAfterWalletBalance(+props.profile.partner.wallet_amount - (+newValue.subscription_price - discountNewValue));
                                    }
                                  }}
                                  renderOption={(props, option) => (
                                    <Box component="li" {...props}>
                                      {option.display_name} - ({option.duration_text}) - <s>{option.discounted_price_in_inr}</s>&nbsp;
                                      {option.subscription_price}
                                    </Box>
                                  )}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      variant="filled"
                                      label="Select Package"
                                      required
                                      inputProps={{
                                        ...params.inputProps,
                                        autoComplete: "", // disable autocomplete and autofill
                                      }}
                                    />
                                  )}
                                />
                              </Grid>
                              <Grid item xs={12} sm={6} md={4}>
                                <TextField
                                  label="Selling Price"
                                  fullWidth
                                  required
                                  placeholder="Enter Selling Price"
                                  variant="filled"
                                  type="number"
                                  onChange={handleSellingPriceChange}
                                  value={sellingPrice}
                                  error={sellingPriceError !== ""}
                                  helperText={sellingPriceError}
                                />
                              </Grid>
                              {/* <Grid item xs={12} sm={6} md={4}>
                                <TextField
                                  label="Discount Percent"
                                  fullWidth
                                  placeholder="Enter Discount Percent"
                                  variant="filled"
                                  type="number"
                                  onChange={handleDiscountChange}
                                  value={discount}
                                  error={discountError !== ""}
                                  helperText={discountError}
                                />
                              </Grid> */}
                              <Grid item xs={12} sm={6} md={4}>
                                <Autocomplete
                                  id="address"
                                  options={addresses}
                                  value={selectedAddress}
                                  inputValue={selectedAddressInput}
                                  onChange={(event, newValue) => {
                                    setSelectedAddress(newValue ? newValue.id : null);
                                    if (newValue && newValue.id === 0) {
                                      setSelectedAddressInput(newValue ? newValue.name : "");
                                    } else if (newValue && newValue.id !== 0) {
                                      setSelectedAddressInput(
                                        newValue
                                          ? `${newValue.name}, ${newValue.address}, ${newValue.city}, ${newValue.state} - ${newValue.postal_code}`
                                          : ""
                                      );
                                    } else {
                                      setSelectedAddressInput("");
                                    }
                                    handleForm("selectedAddress", newValue ? newValue.id : null);
                                  }}
                                  renderOption={(props, option) => (
                                    <Box component="li" {...props}>
                                      {option.id === 0
                                        ? option.name
                                        : `${option.name}, ${option.address}, ${option.city}, ${option.state} - ${option.postal_code}`}
                                    </Box>
                                  )}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      variant="filled"
                                      required
                                      label="Select Billing Address"
                                      inputProps={{
                                        ...params.inputProps,
                                        autoComplete: "", // disable autocomplete and autofill
                                      }}
                                    />
                                  )}
                                />
                              </Grid>
                              {selectedAddress === 0 && (
                                <>
                                  <Grid item xs={12} sm={6} md={4}>
                                    <TextField
                                      label="Name"
                                      fullWidth
                                      required
                                      placeholder="Enter Name"
                                      variant="filled"
                                      onChange={handleNameChange}
                                      value={name}
                                      error={nameError !== ""}
                                      helperText={nameError}
                                    />
                                  </Grid>
                                  <Grid item xs={12} sm={6} md={4}>
                                    <TextField
                                      label="Email"
                                      type="email"
                                      fullWidth
                                      required
                                      placeholder="Enter Email"
                                      variant="filled"
                                      onChange={handleEmailChange}
                                      value={email}
                                      error={emailError !== ""}
                                      helperText={emailError}
                                    />
                                  </Grid>
                                  <Grid item xs={12} sm={6} md={4}>
                                    <TextField
                                      label="Address"
                                      fullWidth
                                      required
                                      placeholder="Enter Address"
                                      variant="filled"
                                      onChange={handleAddressChange}
                                      value={address}
                                      error={addressError !== ""}
                                      helperText={addressError}
                                    />
                                  </Grid>
                                  <Grid item xs={12} sm={6} md={4}>
                                    <TextField
                                      label="City"
                                      fullWidth
                                      required
                                      placeholder="Enter City"
                                      variant="filled"
                                      onChange={handleCityChange}
                                      value={city}
                                      error={cityError !== ""}
                                      helperText={cityError}
                                    />
                                  </Grid>
                                  <Grid item xs={12} sm={6} md={4}>
                                    <TextField
                                      label="State"
                                      fullWidth
                                      required
                                      placeholder="Enter State"
                                      variant="filled"
                                      onChange={handleStateChange}
                                      value={state}
                                      error={stateError !== ""}
                                      helperText={stateError}
                                    />
                                  </Grid>
                                  <Grid item xs={12} sm={6} md={4}>
                                    <TextField
                                      label="Postal Code"
                                      fullWidth
                                      required
                                      placeholder="Enter Postal Code"
                                      variant="filled"
                                      onChange={handlePostalCodeChange}
                                      value={postalCode}
                                      error={postalCodeError !== ""}
                                      helperText={postalCodeError}
                                    />
                                  </Grid>
                                </>
                              )}
                            </Grid>
                            {selectedPackageCost && (
                              <div className={"mt-3"}>
                                <Typography variant="h5" gutterBottom component="div">
                                  Selected Package Cost: {selectedPackageCost}
                                </Typography>
                                {/* {discountValue && discountValue !== 0 && (
                                  <>
                                    <Typography variant="h5" gutterBottom component="div">
                                      Discount: {discountValue}
                                    </Typography>
                                    <Typography variant="h5" gutterBottom component="div">
                                      Cost After Discount: {+selectedPackageCost - discountValue}
                                    </Typography>
                                  </>
                                )} */}
                                <Typography variant="h5" gutterBottom component="div">
                                  Wallet Balance After Purchase: {afterWalletBalance}
                                </Typography>
                              </div>
                            )}
                          </Box>
                        </DialogContent>
                        <DialogActions>
                          <Button onClick={handleClose} size="large">
                            Cancel
                          </Button>
                          <Button
                            size="large"
                            onClick={handleSubmit}
                            disabled={
                              (props.showSubLoader && props.showSubLoader.length > 0) ||
                              (afterWalletBalance != null && afterWalletBalance < 0) ||
                              error
                            }
                          >
                            Assign Package to Student
                          </Button>
                        </DialogActions>
                      </Dialog>
                    </div>
                  </>
                )}
              </Col>
            </Row>
          </Container>
        </section>
      </Styles>

      {/* Footer 2 */}
      <FooterTwo />
    </div>
  );
};

const mapStateToProps = (state) => ({
  categories: state.partnerDashboard.data.categories,
  subcategories: state.partnerDashboard.data.category_subcategories,
  subsubcategories: state.partnerDashboard.data.subcategory_subsubcategories,
  subscriptions: state.partnerDashboard.data.student_subscriptions,
  packages: state.partnerDashboard.data.packages,
  profile: state.partnerDashboard.data.profile,
  student: state.partnerDashboard.data.member,
  showLoader: state.user.showLoader,
  showSubLoader: state.partnerDashboard.showSubLoader,
});

const mapDispatchToProps = (dispatch) => ({
  getMasterData: (data) => dispatch(getMasterDataAction(data)),
  getAuthMasterData: (data) => dispatch(getAuthMasterDataAction(data)),
  getPartnerProfile: () => dispatch(getPartnerProfileDataAction()),
  assignPackageToStudent: (data) => dispatch(assignPackageToMemberAction(data)),
  getStudentPackagesData: (data) => dispatch(getMemberPackagesDataAction(data)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(StudentPackage));
