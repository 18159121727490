import React, { useEffect, useState } from "react";

import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import FooterTwo from "../../components/FooterTwo";
import UserHeader from "../../components/UserHeader";
import { Container, Row, Col } from "react-bootstrap";
import { Styles } from "../dashboard/styles/dashboard.js";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CircularProgress,
  Divider,
  Grid,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { BackspaceOutlined, Home, Replay, Share, TimerOutlined } from "@mui/icons-material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import LooksOneOutlinedIcon from "@mui/icons-material/LooksOneOutlined";
import LooksTwoOutlinedIcon from "@mui/icons-material/LooksTwoOutlined";
import Looks3OutlinedIcon from "@mui/icons-material/Looks3Outlined";
import Looks4OutlinedIcon from "@mui/icons-material/Looks4Outlined";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import { moduleQuizReportAction } from "./action";
import { webUrl } from "../../shared/constants";
import MathJax from "mathjax3-react";
import common from "../../shared/common";
import SampleHeader from "../../components/SampleHeader";
import LoaderImage from "../../components/LoaderImage";

const secondsToHMS = (numberOfSeconds) => {
  let timeInHMS = {
    hours: String(Math.floor((numberOfSeconds / (60 * 60)) % 24)).padStart(2, "0"),
    minutes: String(Math.floor((numberOfSeconds / 60) % 60)).padStart(2, "0"),
    seconds: String(Math.floor(numberOfSeconds % 60)).padStart(2, "0"),
  };
  return timeInHMS["hours"] + ":" + timeInHMS["minutes"] + ":" + timeInHMS["seconds"];
};
function CircularProgressWithLabel(props) {
  return (
    <Box sx={{ position: "relative", display: "inline-flex" }}>
      <CircularProgress
        variant="determinate"
        {...props}
        size={"150px"}
        thickness={2.5}
        sx={{
          color: "#524d4d",
        }}
      />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography variant="caption" component="div" color="text.secondary" sx={{ fontSize: "40px" }}>
          {`${Math.round(props.value)}%`}
        </Typography>
      </Box>
    </Box>
  );
}

function ModuleQuizReport(props) {
  const {
    match: { params },
  } = props;
  const [quizDataLoaded, setQuizDataLoaded] = useState(false);

  const [module_quiz_data, setModuleQuizData] = useState({ module_quiz: { name: null } });
  useEffect(() => {
    props.moduleQuizReport([params.type]).then(() => {
      setQuizDataLoaded(true);
    }); // To do: params.type should be default one
  }, []);
  useEffect(() => {
    if (quizDataLoaded) {
      if (props.quiz_report) {
        setModuleQuizData(props.quiz_report);
      }
    }
  }, [quizDataLoaded]);

  return (
    <div className="main-wrapper course-details-page">
      {/* Header */}
      {common.getFromStorage("isAuthenticated") === "true" && common.getFromStorage("userName") !== "" ? <UserHeader /> : <SampleHeader />}
      <Styles>
        {/* Course Grid */}
        <section className="dashboard-grid-area">
          <Container>
            <Row>
              <Col>
                {props.showLoader && props.showLoader.length > 0 && <LoaderImage />}
                {props.showLoader.length === 0 && (
                  <>
                    <Row>
                      <Col lg="9" md="12" sm="12" style={{ margin: "0 auto" }}>
                        <Row>
                          <Col lg="12">
                            <h3 style={{ padding: "10px", marginBottom: "20px" }}>Quiz Summary: {module_quiz_data.module_quiz.name}</h3>
                          </Col>
                        </Row>
                        <Card sx={{ minWidth: 275 }}>
                          <CardContent>
                            <Grid container spacing={2}>
                              <Grid item sm={9}>
                                <Paper elevation={0}>
                                  <Accordion expanded>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                                      <Typography variant="h4">Summary</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                      <Typography variant="h6">
                                        Time Taken{" "}
                                        <span className="pull-right summary-value">
                                          <TimerOutlined /> {secondsToHMS(module_quiz_data.total_seconds)}
                                        </span>
                                      </Typography>
                                      <Divider sx={{ margin: "10px 0" }} />
                                      <Typography variant="h6">
                                        Total Questions <span className="pull-right summary-value">{module_quiz_data.total_questions}</span>
                                      </Typography>
                                      <Divider sx={{ margin: "10px 0" }} />
                                      <Typography variant="h6">
                                        Attempt Questions{" "}
                                        <span className="pull-right summary-value">
                                          {+module_quiz_data.total_questions - +module_quiz_data.total_unattempted}
                                        </span>
                                      </Typography>
                                      <Divider sx={{ margin: "10px 0" }} />
                                      <Typography variant="h6">
                                        Correct Answers <span className="pull-right summary-value">{+module_quiz_data.total_correct}</span>
                                      </Typography>
                                      <Divider sx={{ margin: "10px 0" }} />
                                      <Typography variant="h6">
                                        Highest Score{" "}
                                        <span className="pull-right summary-value">
                                          {module_quiz_data.maxScore ? parseFloat(module_quiz_data.maxScore).toFixed(2) + "%" : "100%"}
                                        </span>
                                      </Typography>
                                      <Divider sx={{ margin: "10px 0" }} />
                                      <Typography variant="h6">
                                        Percentile{" "}
                                        <span className="pull-right summary-value">
                                          {module_quiz_data.percentile ? parseFloat(module_quiz_data.percentile).toFixed(2) + "%" : "100%"}
                                        </span>
                                      </Typography>
                                    </AccordionDetails>
                                  </Accordion>
                                </Paper>
                              </Grid>
                              <Grid item sm={3}>
                                <Paper elevation={1} square sx={{ padding: "10px" }}>
                                  <div className="total_score">Total Score</div>
                                  <div className="progress_bar">
                                    <CircularProgressWithLabel value={(module_quiz_data.total_correct * 100) / module_quiz_data.total_questions} />
                                  </div>
                                </Paper>
                              </Grid>
                            </Grid>
                          </CardContent>
                          <Divider />
                          <CardActions>
                            <Box sx={{ flexGrow: 1 }}>
                              <Grid container>
                                <Grid item xs={3} sx={{ textAlign: "center" }}>
                                  <Button
                                    variant={"contained"}
                                    endIcon={<ArrowLeftIcon />}
                                    className={"back_button"}
                                    style={{ margin: "10px" }}
                                    onClick={(e) => {
                                      e.preventDefault();
                                      props.history.push(webUrl.moduleDetails + "/" + module_quiz_data.module_quiz.module_id);
                                    }}
                                  >
                                    Back to Module
                                  </Button>
                                </Grid>
                                <Grid item xs={3} sx={{ textAlign: "center" }}>
                                  <Button
                                    variant={"contained"}
                                    endIcon={<Replay />}
                                    className={"retry_button"}
                                    style={{ margin: "10px" }}
                                    onClick={(e) => {
                                      e.preventDefault();
                                      if (window.confirm("Are you sure to restart the quiz?")) {
                                        props.history.push(webUrl.startModuleQuiz + "/" + module_quiz_data.module_quiz.id);
                                      }
                                    }}
                                  >
                                    Retry Quiz
                                  </Button>
                                </Grid>
                                <Grid item xs={3} sx={{ textAlign: "center" }}>
                                  {common.getFromStorage("isAuthenticated") === "true" && common.getFromStorage("userName") !== "" ? (
                                    <Button
                                      variant={"contained"}
                                      endIcon={<Home />}
                                      className={"dashboard_button"}
                                      style={{ margin: "10px" }}
                                      onClick={(e) => {
                                        e.preventDefault();
                                        props.history.push(webUrl.userDashboard);
                                      }}
                                    >
                                      Dashboard
                                    </Button>
                                  ) : (
                                    <Button
                                      variant={"contained"}
                                      startIcon={<BackspaceOutlined />}
                                      className={"dashboard_button"}
                                      style={{ margin: "10px" }}
                                      onClick={(e) => {
                                        e.preventDefault();
                                        const sampleModule = common.getFromStorage("sampleModule");
                                        props.history.push(sampleModule || webUrl.sampleModule);
                                      }}
                                    >
                                      Back to {common.getFromStorage("sampleModuleTitle") || `Light Sensor`} Module
                                    </Button>
                                  )}
                                </Grid>
                                <Grid item xs={3} sx={{ textAlign: "center" }}>
                                  <Button variant="contained" endIcon={<Share />} className={"share_button"} style={{ margin: "10px" }}>
                                    Share Result
                                  </Button>
                                </Grid>
                              </Grid>
                            </Box>
                          </CardActions>
                        </Card>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="9" md="12" style={{ margin: "20px auto" }}>
                        <Accordion className={"accordion_purple"}>
                          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography variant="h4">Evaluate Your Answers</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            {module_quiz_data.responses &&
                              module_quiz_data.responses.map((question, i) => {
                                let userResponse = question.status !== 1 ? question.member_response : null;
                                if (question.status === 1) {
                                  return "";
                                }
                                let correctAnswer = question.correct_option;
                                return (
                                  <div
                                    style={{
                                      marginTop: "10px",
                                    }}
                                    key={question.id}
                                  >
                                    <Card sx={{ minWidth: 275 }}>
                                      <CardContent>
                                        <Row>
                                          <Col lg="12" md="12" sm="12">
                                            <Typography variant="h5" gutterBottom className="quiz" component="div">
                                              Question {i + 1}
                                            </Typography>
                                          </Col>
                                        </Row>
                                        <Row>
                                          <Col lg="12" md="12" sm="12">
                                            <Typography variant="h2" className="quiz" gutterBottom component="div">
                                              <MathJax.Provider>
                                                <MathJax.Html html={question.module_quiz_question.question_text} />
                                              </MathJax.Provider>
                                            </Typography>
                                          </Col>
                                        </Row>
                                        <Row>
                                          <Col lg="12" md="12" sm="12">
                                            <Stack sx={{ width: "100%" }} spacing={2}>
                                              <Alert
                                                icon={
                                                  correctAnswer === 1 ? (
                                                    <CheckCircleOutlineIcon />
                                                  ) : userResponse === 1 ? (
                                                    <CancelOutlinedIcon />
                                                  ) : (
                                                    <LooksOneOutlinedIcon />
                                                  )
                                                }
                                                severity={correctAnswer === 1 ? "success" : userResponse === 1 ? "error" : "info"}
                                              >
                                                <Typography variant="h2" gutterBottom component="div" sx={{ marginBottom: 0 }}>
                                                  <MathJax.Provider>
                                                    <MathJax.Html html={question.module_quiz_question.option1} />
                                                  </MathJax.Provider>
                                                </Typography>
                                              </Alert>
                                              <Alert
                                                icon={
                                                  correctAnswer === 2 ? (
                                                    <CheckCircleOutlineIcon />
                                                  ) : userResponse === 2 ? (
                                                    <CancelOutlinedIcon />
                                                  ) : (
                                                    <LooksTwoOutlinedIcon />
                                                  )
                                                }
                                                severity={correctAnswer === 2 ? "success" : userResponse === 2 ? "error" : "info"}
                                              >
                                                <Typography variant="h2" gutterBottom component="div" sx={{ marginBottom: 0 }}>
                                                  <MathJax.Provider>
                                                    <MathJax.Html html={question.module_quiz_question.option2} />
                                                  </MathJax.Provider>
                                                </Typography>
                                              </Alert>
                                              <Alert
                                                icon={
                                                  correctAnswer === 3 ? (
                                                    <CheckCircleOutlineIcon />
                                                  ) : userResponse === 3 ? (
                                                    <CancelOutlinedIcon />
                                                  ) : (
                                                    <Looks3OutlinedIcon />
                                                  )
                                                }
                                                severity={correctAnswer === 3 ? "success" : userResponse === 3 ? "error" : "info"}
                                              >
                                                <Typography variant="h2" gutterBottom component="div" sx={{ marginBottom: 0 }}>
                                                  <MathJax.Provider>
                                                    <MathJax.Html html={question.module_quiz_question.option3} />
                                                  </MathJax.Provider>
                                                </Typography>
                                              </Alert>
                                              <Alert
                                                icon={
                                                  correctAnswer === 4 ? (
                                                    <CheckCircleOutlineIcon />
                                                  ) : userResponse === 4 ? (
                                                    <CancelOutlinedIcon />
                                                  ) : (
                                                    <Looks4OutlinedIcon />
                                                  )
                                                }
                                                severity={correctAnswer === 4 ? "success" : userResponse === 4 ? "error" : "info"}
                                              >
                                                <Typography variant="h2" gutterBottom component="div" sx={{ marginBottom: 0 }}>
                                                  <MathJax.Provider>
                                                    <MathJax.Html html={question.module_quiz_question.option4} />
                                                  </MathJax.Provider>
                                                </Typography>
                                              </Alert>
                                            </Stack>
                                          </Col>
                                        </Row>
                                      </CardContent>
                                    </Card>
                                  </div>
                                );
                              })}
                          </AccordionDetails>
                        </Accordion>
                      </Col>
                    </Row>
                  </>
                )}
              </Col>
            </Row>
          </Container>
        </section>
      </Styles>

      {/* Footer 2 */}
      <FooterTwo />
    </div>
  );
}

const mapStateToProps = (state) => ({
  quiz_report: state.moduleQuizReport.data.quiz_report,
  showLoader: state.user.showLoader,
});

const mapDispatchToProps = (dispatch) => ({
  moduleQuizReport: (data) => dispatch(moduleQuizReportAction(data)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ModuleQuizReport));
