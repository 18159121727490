import { showLoaderAction, stopLoaderAction } from "../user/action";
import { moduleQuizReport } from "./service";

/**
 * Action types
 */
export const actionTypes = {
  MODULE_QUIZ_REPORT_REQUEST_SUCCESS: "MODULE_QUIZ_REPORT_REQUEST_SUCCESS"
};

export const receivedModuleQuizReportAction = (payload) => ({
  type: actionTypes.MODULE_QUIZ_REPORT_REQUEST_SUCCESS,
  payload,
});

export const moduleQuizReportAction = (data) => {
  return (dispatch) => {
    dispatch(showLoaderAction("moduleQuizReport"));

    return moduleQuizReport(data)
      .then((response) => {
        dispatch(stopLoaderAction("moduleQuizReport"));
        dispatch(receivedModuleQuizReportAction(response));
      })
      .catch((error) => {
        dispatch(stopLoaderAction("moduleQuizReport"));
      });
  };
};