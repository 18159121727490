import common from "../../shared/common";
import { showLoaderAction, stopLoaderAction } from "../user/action";
import { getDashboardData } from "./service";

/**
 * Action types
 */
export const actionTypes = {
  DASHBOARD_DATA_REQUEST_SUCCESS: "DASHBOARD_DATA_REQUEST_SUCCESS",
  SET_FORM_DATA: "SET_FORM_DATA",
};

export const receivedDashboardDataAction = (payload) => ({
  type: actionTypes.DASHBOARD_DATA_REQUEST_SUCCESS,
  payload,
});

export const dashboardDataAction = (data) => {
  return (dispatch) => {
    dispatch(showLoaderAction("getDashboardData"));

    return getDashboardData(data)
      .then((response) => {
        dispatch(stopLoaderAction("getDashboardData"));

        if (response) {
          common.setToStorage("profile", response.data.user && JSON.stringify(response.data.user));
          common.setToStorage("categories", response.data.categories && JSON.stringify(response.data.categories));
          dispatch(receivedDashboardDataAction(response));
        }
      })
      .catch((error) => {
        dispatch(stopLoaderAction("getDashboardData"));
      });
  };
};

export const setDashboardFormDataAction = (payload) => ({
  type: actionTypes.SET_FORM_DATA,
  payload,
});
