import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { messages, webUrl } from "../../../shared/constants.js";

function VerifyResetPasswordOTP(props) {
  const [formData, setFormData] = useState({});

  const onFieldChange = (name, value) => {
    setFormData({
      ...formData,
      [name]: value,
    });

    resetError(name);
  };

  const onOTPNextClick = (e) => {
    e.preventDefault();
    if (isValidOTPFormData()) {
      props.verifyResetPasswordOTP({ otp: formData.otp }).then((response) => {
        if (response) {
          props.setStep(8);
        }
      });
    }
  };

  const isValidOTPFormData = () => {
    let isValid = true;

    if (!formData || !formData.otp) {
      setError("otp", messages.requiredField);
      isValid = false;
    } else if (isNaN(formData.otp)) {
      setError("otp", messages.numberField);
      isValid = false;
    } else {
      resetError("otp");
    }

    return isValid;
  };

  const setError = (input, message) => {
    const formControl = document.getElementsByName(input)[0].parentElement;
    const errorMsg = formControl.querySelector(".login_input-msg");
    formControl.classList.add("error");
    formControl.classList.add("text-left");
    errorMsg.innerText = message;
  };

  const resetError = (input) => {
    const formControl = document.getElementsByName(input)[0].parentElement;
    const errorMsg = formControl.querySelector(".login_input-msg");
    formControl.classList.remove("error");
    formControl.classList.remove("text-left");
    errorMsg.innerText = "";
  };

  return (
    <>
      <div className="side-content">
        <h5>Reset Password OTP</h5>
        <p>We have sent an OTP to {props.email} to reset your password</p>
      </div>
      <div className="side-post">
        {/* Login Area */}
        <section className="login-area">
          <Row>
            <Col md="12">
              <div className="login-box">
                <form id="form_login" className="form" onSubmit={onOTPNextClick}>
                  <div className="form-control">
                    <Row>
                      <Col md="12">
                        <input
                          type="text"
                          autoFocus
                          placeholder="OTP"
                          id="otp"
                          name="otp"
                          value={formData.otp}
                          maxLength={6}
                          onChange={(e) => {
                            onFieldChange(e.target.name, e.target.value);
                          }}
                        />
                        <span className="login_input-msg"></span>
                      </Col>
                    </Row>
                  </div>
                  <div className="forget-password">
                    <button type="submit" onClick={onOTPNextClick}>
                      Next
                    </button>
                  </div>
                </form>
              </div>
            </Col>
          </Row>
        </section>
      </div>
    </>
  );
}

export default VerifyResetPasswordOTP;
