import styled from "styled-components";
import { colors } from "../common/element/elements.js";

export const Styles = styled.div`
  .contact-page {
    .contact-area {
      padding: 68px 0 0;
      margin: 30px 0;
      h6 {
        font-size: 24px;
        color: ${colors.black1};
        font-weight: 600;
        padding-bottom: 10px;
        margin-top: 20px;
        margin-bottom: 20px;
        position: relative;
        &:before {
          position: absolute;
          content: "";
          background: ${colors.green};
          width: 50px;
          height: 2px;
          bottom: 0;
          left: 0;
        }

        @media (max-width: 575px) {
          font-size: 20px;
        }
      }

      i {
        font-size: 32px;
        color: ${colors.green};
        width: 65px;
        height: 65px;
        border: 1px solid ${colors.border3};
        text-align: center;
        border-radius: 50%;
        padding-top: 15px;
        margin-top: 5px;
        margin-right: 20px;

        @media (max-width: 991px) {
          font-size: 26px;
          width: 50px;
          height: 50px;
          padding-top: 10px;
          margin-right: 12px;
        }
      }
      h5 {
        color: ${colors.black2};
        font-weight: 600;
        margin-bottom: 8px;

        @media (max-width: 991px) {
          font-size: 16px;
        }
      }
      p {
        font-size: 17px;
        margin-bottom: 10px;
        color: ${colors.text3};

        @media (max-width: 991px) {
          font-size: 15px;
        }
      }

      @media (max-width: 767px) {
        margin-bottom: 20px;
      }
      ul li {
          font-size: 17px;
          color: ${colors.text3};
      }

      a{
        font-size: 17px;
      }
    }
  }
`;
