import styled from "styled-components";
import { colors } from "../common/element/elements.js";

export const Styles = styled.div`
  @font-face {
    src: url("/assets/fonts/FreeSans.ttf") format("truetype"); /* Safari, Android, iOS */
  }
  @font-face {
    src: url("/assets/fonts/FreeSansBold.ttf") format("truetype"); /* Safari, Android, iOS */
  }

  html {
    body {
    }
  }
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }
  .btn_white {
    width: 200px;
    ${colors.gredient_orange_bg}
    font-size: 24px;
    letter-spacing: 2px;
    line-height: 42px;
    color: #ffffff;
    font-weight: 600;
    border: 0;
    cursor: pointer;
    &:hover {
      background: white;
      color: rgb(234 135 51);
    }
  }
  .btn_primary {
    background-color: #ed833b;
    padding: 10px 50px;
    color: #fff;

    border: 0;
    border-radius: 6px;
    cursor: pointer;
  }
  .h-100 {
    height: 100% !important;
  }

  .d_flex {
    display: flex;
    .col_two {
      width: 50%;
    }
    .col_four {
      width: 25%;
    }
    .col_three {
      width: 33.333%;
    }
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
  }
  p {
    margin: 0;
  }

  .main_title {
    font-size: 25px;
    letter-spacing: 2px;

    color: #000000;
    font-weight: 400;

    text-align: center;
    margin-top: 30px;
    span {
      font-size: 40px;
    }
    span.orange_text {
      color: #f97654;
    }
    .blue {
      color: #35bef4;
    }
  }
  .learning_app {
    margin-top: 30px;
    margin-bottom: 100px;
  }
  section {
    .sec_title {
      font-size: 22px;
      letter-spacing: 2px;
      line-height: 50px;
      color: #f97654;
      font-weight: 400;

      text-align: center;
      margin-bottom: 100px;
    }
    .bg_card {
      border-radius: 10px;
      .col_two.left {
        display: flex;
        align-items: flex-end;
        justify-content: center;
        flex-direction: column;
        width: 60%;
        position: relative;
        &::before {
          content: "";
          position: absolute;
          background-image: url("/assets/images/home/learning_layer.webp");
          background-repeat: no-repeat;
          background-size: contain;
          height: 500px;
          width: 65%;
          left: -30px;
        }
        h4 {
          font-size: 31px;
          letter-spacing: 2px;
          line-height: 22px;
          color: #f19ba4;
          font-weight: 600;
        }
        p {
          font-size: 13px;
          letter-spacing: 1px;
          line-height: 20px;
          color: #ffffff;
          font-weight: 250;
          max-width: 35%;
          margin-top: 20px;
          text-align: right;
        }
      }
    }
    .blue {
      background-color: #5076c6;
    }
    .col_two.right {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-end;
      padding-right: 40px;
      width: 40%;
    }
    .board_card {
      width: 395px;
      min-height: 180px;
      border-radius: 10px;
      background-color: #ffffff;
      border: 2px solid #5076c6;
      padding: 10px;
      .left_col {
        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-direction: column;
        text-align: left;
      }
      .class_text {
        font-size: 17px;
        letter-spacing: 1px;

        color: #000000;
        font-weight: 400;
      }
      h6 {
        font-size: 17px;
        letter-spacing: 1px;

        color: #000000;
        font-weight: 600;

        margin-bottom: 10px;
      }
      .desc_text {
        font-size: 11px;
        letter-spacing: 1px;

        color: #000000;
        font-weight: 400;
      }
    }
    .top {
      margin-top: -50px;
    }
    .bottom {
      margin-bottom: -50px;
    }

    .yellow {
      background-color: #fac832;
    }
  }
  section.robotic {
    margin-top: 280px;
    .bg_card {
      padding: 15px;
    }

    .robotic_card {
      border-radius: 10px;
      background-color: #ffffff;
      border: 2px solid #fa9e2d;
      padding: 15px;
      margin-top: -90px;
      h5 {
        font-size: 24px;
        color: #fa9e2d;
        font-weight: 800;
        margin-bottom: 20px;
      }
      ul {
        text-indent: -30px;
        margin-left: 25px;
        li {
          list-style: none;
          font-size: 16px;
          svg {
            margin-right: 5px;
          }
        }
      }
      p {
        font-size: 18px;
        letter-spacing: 1px;
        line-height: 29px;
        color: #000000;
        font-weight: 400;
      }
    }
    h2 {
      font-size: 25px;
      letter-spacing: 2px;
      line-height: 43px;
      color: #870bae;
      font-weight: 600;
      margin-top: 20px;
      width: 100%;
      text-align: center;
    }

    .course_card {
      border-radius: 11px;
      background-color: #ffffff;
      border: 2px solid #fa9e2d;

      .course_layer {
        position: relative;
        &:before {
          content: "";
          display: block;
          background-image: url(/assets/images/home/course_layer.webp);
          background-position: center;
          background-size: contain;
          height: 211px;
          background-repeat: no-repeat;
          @media (max-width: 767px) {
            width: 100%;
          }
        }
      }
      .right_col {
        padding: 25px 0;
        h3 {
          font-size: 22px;
          line-height: 32px;
          color: #fa9e2d;
          font-weight: 800;
          text-align: center;
          margin-top: 3px;
          padding: 0 10px;
        }
      }
    }
    .right_four {
      display: flex;
      align-items: flex-end;
      margin-bottom: -90px;
      margin-left: 30px;
      flex: 1;
      position: relative;
      @media (max-width: 767px) {
        margin-bottom: -30px;
      }
      &::before {
        content: " ";
        position: absolute;
        right: -7px;
        background-image: url("/assets/images/home/robotic_layer.webp");
        background-repeat: no-repeat;
        height: 567px;
        width: 658px;
        bottom: 75px;
      }
    }
  }
  section.teacher {
    margin-top: 140px;
    position: relative;
    &::before {
      content: " ";
      position: absolute;
      left: 0;
      background-image: url("/assets/images/home/teacher_layer.webp");
      background-size: contain;
      background-repeat: no-repeat;
      height: 600px;
      width: 40%;
      top: -130px;
    }
    .green {
      background-color: #2fa8b0;
    }
    h2 {
      font-size: 25px;
      letter-spacing: 2px;
      line-height: 43px;
      color: #ffffff;
      font-weight: 600;
      text-align: center;
      padding-top: 20px;
      width: 60%;
      margin: 0 auto 0 35%;
    }
    .col_two.col_right {
      display: flex;
      justify-content: flex-end;
    }
    .tech_card {
      width: 329px;
      height: 240px;
      border-radius: 11px;
      background-color: #ffffff;
      border: 2px solid #2fa8b0;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      padding: 15px;
      margin-top: 290px;
      position: relative;
      left: 200px;
      display: none;
      h5 {
        font-size: 21px;
        letter-spacing: 2px;
        line-height: 32px;
        color: #000000;
        font-weight: 600;

        margin-bottom: 15px;
      }
      p {
        font-size: 16px;
        letter-spacing: 1px;
        line-height: 30px;
        color: #000000;
        font-weight: 400;
      }
    }
    .teacher_card {
      min-width: 481px;
      width: 50%;
      border-radius: 11px;
      background-color: #ffffff;
      border: 2px solid #2fa8b0;
      padding: 15px;
      margin-right: -20px;
      margin-top: 20px;
      margin-bottom: -20px;

      h5 {
        font-size: 21px;
        letter-spacing: 2px;
        line-height: 32px;
        color: #2fa8b0;
        font-weight: 800;

        text-align: center;
        margin-bottom: 15px;
      }
      ul {
        text-indent: -30px;
        margin-left: 25px;
        li {
          list-style: none;
          font-size: 17px;
          svg {
            margin-right: 5px;
          }
        }
      }
      p {
        font-size: 16px;
        letter-spacing: 1px;
        line-height: 30px;
        color: #000000;
        font-weight: 400;
      }
    }
  }
  .trial {
    margin-top: 190px;
    margin-bottom: 150px;
    .sky_blue {
      background-image: url("/assets/images/home/background_blue.webp");
      padding: 45px;
      background-repeat: no-repeat;
      background-size: cover;
    }
    .left_col {
      display: flex;
      align-items: center;
    }
    .content_box {
      h5 {
        font-size: 22px;
        letter-spacing: 2px;
        line-height: 50px;
        color: #ffffff;
        font-weight: 600;
      }
      h2 {
        font-size: 38px;
        letter-spacing: 3px;
        line-height: 50px;
        color: #ffffff;
        font-weight: 600;
      }
      p {
        font-size: 13px;
        letter-spacing: 1px;
        line-height: 50px;
        color: #ffffff;
        font-weight: 400;
      }
    }
    .right_col {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      @media (max-width: 767px) {
        justify-content: flex-start;
      }
    }
    .r_content_box {
      @media (max-width: 767px) {
        width: 70%;
      }
      h5 {
        font-size: 28px;
        letter-spacing: 2px;
        line-height: 50px;
        color: #ffffff;
        font-weight: 600;
      }
      p {
        font-size: 13px;
        letter-spacing: 1px;
        line-height: 21px;
        color: #ffffff;
        font-weight: 400;
      }
    }
    .img_col {
      position: relative;
      &::before {
        content: "";
        position: absolute;
        left: 0;
        background-image: url("/assets/images/home/trial_layer.webp");
        background-size: contain;
        background-repeat: no-repeat;
        height: 500px;
        width: 70%;
        top: -170px;
      }
    }
  }

  @media (min-width: 1200px) {
    .main_container {
      width: 1140px;
      margin: 0 auto;
    }
  }

  .title {
    font-size: 35px;
    line-height: 67px;
    color: #f97654;
    font-weight: 600;
    text-align: center;
    margin-bottom: 10px;
    text-transform: uppercase;
  }

  .about-us {
    margin-bottom: 45px;
    .about-content {
      margin-top: 40px;
      h4.about-title {
        font-size: 36px;
        margin-bottom: 30px;
      }
      .about-para {
        font-size: 22px;
        word-spacing: 2px;
        letter-spacing: 1px;
      }
    }
  }
  .experiential_learning {
    padding: 60px 0;
    .right_col {
      display: flex;
      align-items: center;
      justify-content: center;
      .content {
        h5 {
          font-size: 35px;
          line-height: 67px;
          color: #000000;
          font-weight: 600;

          margin-bottom: 25px;
        }
        p {
          color: #9c9996;
        }
        ul {
          display: flex;
          justify-content: space-around;
          margin-top: 20px;
          li {
            span {
              font-size: 30px;
            }
            text-align: center;
            p {
              font-size: 12px;
            }
            .first {
              color: #ff6f6f;
            }
            .middle {
              color: #84479c;
            }
            .last {
              color: #2c97ea;
            }
          }
        }
      }
    }
  }
  .button_box {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
  }

  .climb {
    margin-top: 100px;
    span {
      color: ${colors.orange};
      font-size: 36px;
      @media (max-width: 767px) {
        font-size: 25px;
      }
    }
    h2 {
      font-size: 33px;
      line-height: 47px;
      text-align: center;
      font-weight: 600;
      margin-top: 30px;
      margin-bottom: 36px;
      @media (max-width: 767px) {
        font-size: 32px;
        color: black !important;
      }
    }
    .bg_card {
      padding: 15px;
      position: relative;
      height: auto;
      display: flex;
      justify-content: center;
      .bg-climb_img {
        .desktop_climb {
          display: block;
          @media (max-width: 767px) {
            display: none;
          }
        }
        .mobile_climb {
          display: none;
          @media (max-width: 767px) {
            display: block;
            margin-bottom: 20px;
          }
        }
      }
      .climb_join {
        position: absolute;
        bottom: 65px;
        right: 30px;
        @media (max-width: 767px) {
          bottom: 20px;
          right: auto;
        }
        .join_btn {
          background: white;
          color: #eb6808;
          border: solid 1px #eb6808;
          padding: 5px 30px;
          border-radius: 5px;
          text-transform: capitalize;
          font-size: 24px;
          font-weight: 600;
          min-width: 250px;

          &:hover {
            background: #eb6808;
            color: white;
            border: solid 1px white;
          }
          @media (max-width: 767px) {
            font-size: 16px;
            right: auto;
            bottom: 33px;
          }
        }
      }

      .first {
        position: absolute;
        bottom: 16%;
        left: 33%;
        h5 {
          color: #fff;
          font-size: 30px;
        }
        p {
          color: #fff;
        }
      }
    }
  }
  .orange {
    background-color: #ffa544;
  }

  .have_fun {
    margin-top: 100px;
    .bg_card {
      background-color: #fff8e7;
      padding: 20px;
      height: 100%;
      .head {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin-bottom: 50px;
        h2 {
          color: #f6b400;
          font-size: 60px;
          line-height: 40px;
          text-transform: uppercase;
        }
        p {
          color: #f6b400;
          font-size: 32px;

          text-transform: uppercase;

          .line {
            display: flex;
            height: 8px;
            span {
              width: 33.333%;
            }
            .start {
              background-color: #fbcd15;
            }
            .middle {
              background-color: #47c2f4;
            }
            .end {
              background-color: #fb267c;
            }
          }
        }
      }
      .d_flex {
        position: relative;
        &:after {
          content: "";
          position: absolute;
          width: 70%;
          height: 1px;
          background-color: #d2c3ac;
          top: 33%;
          left: 105px;
        }
      }
      .col_three {
        position: relative;
        text-align: center;
        &:last-child {
          h5 {
            &:after {
              top: 25%;
            }
          }
        }
        img {
          position: relative;
          z-index: 1;
        }

        .circle_title {
          color: #987f61;
          font-size: 25px;
          line-height: 40px;
          text-transform: uppercase;

          margin-bottom: 15px;
        }
        h5 {
          color: #987f61;
          font-size: 25px;
          line-height: 40px;

          margin-bottom: 0;
          padding-top: 65px;
          position: relative;
        }
        p {
          color: #987f61;
        }
      }
    }
  }

  .school_solution {
    padding: 100px 0;
    .bg_card {
      background-color: #301d4a;
      height: 600px;
      padding: 0;
      display: flex;
      justify-content: center;

      .img_bg {
        background-image: url("/assets/images/robotics/school_solutions.webp");
        background-repeat: no-repeat;
        height: 100%;
        width: 100%;
        background-position: center;
        background-size: contain;
      }
    }
  }

  .robotics_bottom {
    background-image: url(/assets/images/home/Bottom_robotics.webp);
    background-repeat: no-repeat;
    background-size: contain;
    height: 0;
    padding-top: 66.64%;
    margin-left: 50px;
    .d_flex {
      position: absolute;
      margin-top: -50%;
      margin-left: 20px;
      color: white;
      h2 {
        margin-top: 50px;
        margin-bottom: 50px;
        @media (max-width: 767px) {
          margin: auto;
        }
      }
      h5 {
        color: #61ff61;
        margin: 40px 0;
        @media (max-width: 767px) {
          font-size: 14px;
          margin: 20px 0;
        }
      }
      .join_btn {
        background: white;
        color: #eb6808;
        border: solid 1px #eb6808;
        padding: 5px 30px;
        border-radius: 5px;
        text-transform: capitalize;
        font-size: 24px;
        font-weight: 600;
        min-width: 250px;

        &:hover {
          background: #eb6808;
          color: white;
          border: solid 1px white;
        }
        @media (max-width: 767px) {
          font-size: 16px;
        }
      }
      @media (min-width: 1240px) {
        margin-top: -600px;
      }
      @media (max-width: 767px) {
        width: 50%;
        h2 {
          font-size: 21px;
        }
      }
    }
    @media (max-width: 767px) {
      margin-left: 0;
    }
  }

  @media (max-width: 767px) {
    .main_container {
      padding: 0 10px;
    }
    .d_flex {
      flex-wrap: wrap;
      .col_two {
        width: 100%;
      }
    }
    section .bg_card .col_two.left::before {
      background-size: contain;
      height: 320px;
      width: 320px;
      left: -15px;

      top: -78px;
    }
    section .bg_card .col_two.left h4 {
      font-size: 33px;
      line-height: 35px;
      width: 50%;
    }
    section .bg_card .col_two.left p {
      max-width: 70%;
      font-size: 16px;
      text-align: left;
      padding-top: 90px;
    }
    section .col_two.right {
      padding-right: 0;
      width: 100%;
      justify-content: center;
      align-items: center;
    }
    section .bg_card .col_two.left {
      width: 100%;
    }
    section.robotic .right_four::before {
      height: 313px;
      width: 362px;
      bottom: 133px;
      background-size: contain;
      display: none;
    }
    .trial .img_col::before {
      width: 50%;
      top: -250px;
      left: 60%;
    }
    section.teacher .teacher_card {
      width: 100%;
      margin-top: 35px;
      min-width: 100%;
    }
    section.teacher .tech_card {
      left: -16px;
      width: 100%;
    }
    section.teacher::before {
      width: 50%;
      top: -20px;
      max-height: 230px;
    }
    section.teacher h2 {
      line-height: 34px;
      width: 67%;
      margin: 0 auto 0 35%;
    }
    .main_title {
      font-size: 16px;
      span {
        font-size: 20px;
      }
    }
    .learning_app {
      margin-top: 50px;
    }
    section .bg_card {
      padding: 25px;
      height: 100%;
    }
    section .board_card {
      width: 100%;
      margin-bottom: 30px;
      text-align: center;
    }
    section .board_card.top {
      margin-top: 20px;
    }
    section .board_card {
      position: relative;
      .d_flex .col_two {
        display: flex;
        justify-content: flex-end;
      }
    }
    section .board_card img {
      margin-left: 0;
    }
    section.robotic {
      margin-top: 125px;
    }
    section.robotic .robotic_card {
      margin-left: -7px;
    }
    .d_flex .col_three {
      width: 100%;
    }
    .trial {
      margin-top: 25px;
    }
    .trial .content_box h2 {
      font-size: 22px;
      letter-spacing: 3px;
      line-height: 25px;
    }
    .trial .r_content_box h5 {
      font-size: 22px;
      letter-spacing: 2px;
      line-height: 30px;
      color: #ffffff;
      font-weight: 600;

      margin-top: 20px;
    }
    section.teacher {
      margin-top: 70px;
    }
    .img_left {
      width: 100%;
    }
    .title {
      font-size: 25px;
      line-height: 30px;
      margin-top: 40px;
    }
    .content {
      padding: 15px;
    }
    .climb .bg_card {
      padding: 30px 15px;
      position: relative;
    }
    .climb h2 {
      font-size: 25px;
      line-height: 35px;
      color: #ffffff;
      font-weight: 600;
    }
    .climb .bg_card:before {
      content: " ";
      position: absolute;
      right: 0;

      height: 350px;
      width: 389px;
      background-repeat: no-repeat;
      background-size: contain;
      bottom: -95px;
    }
    .have_fun .bg_card .col_three h5:after {
      display: none;
    }
    .have_fun .bg_card .col_three:after {
      display: none;
    }
    .have_fun .bg_card .d_flex:after {
      display: none;
    }
    .school_solution .bg_card {
      height: 400px;
    }
    .have_fun {
      margin-top: 0px;
    }
    .school_solution {
      padding: 30px 0;
    }
    .banner {
      height: calc(100vh - 238px);
      .btn_primary {
        padding: 7px 30px;
      }
    }
  }
  .banner_wrapper {
    .robotics_banner {
      background-image: url("/assets/images/banners/robotics_banner_bg.webp");
      height: 0;
      padding-top: 47.14%;
      background-repeat: no-repeat;
      background-size: contain;
      position: relative;
      &::before {
        content: "";
        position: absolute;
        background-image: url(/assets/images/banners/banner_light.webp);
        background-repeat: no-repeat;
        background-size: contain;
        padding-top: 56.14%;
        width: 80%;
        top: 0;
        left: 10%;
      }
      .robotics_board {
        position: absolute;
        top: 10%;
        background-image: url(/assets/images/banners/blackboard.webp);
        width: 40%;
        height: 45%;
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        left: 35%;
        .board_content {
          padding: 6% 10%;
          color: white;
          text-align: center;
          text-transform: uppercase;
          h2,
          h3 {
            font-size: 2.25vw;
          }
          .board-points {
            text-align: left;
            padding: 0.5vw;
            li {
              font-size: 1vw;
              margin: 0.5vw 0;
              svg {
                font-size: 1.2vw;
                vertical-align: sub;
                margin-right: 1vw;
              }
            }
          }
          .board_button {
            text-align: center;
            .btn_connect {
              background-color: #ed833b;
              padding: 0.4vw 2vw;
              color: #fff;
              border: 0;
              border-radius: 1vw;
              cursor: pointer;
              line-height: 1vw;
              font-size: 1vw;
              margin: 0.5vw;
              text-transform: uppercase;
              &:hover {
                color: #ed833b;
                background-color: #fff;
              }
            }
          }
        }
      }
      &::after {
        content: "";
        position: absolute;
        background-image: url(/assets/images/banners/banner_characters.webp);
        background-repeat: no-repeat;
        background-size: contain;
        padding-top: 47.14%;
        bottom: 0;
        width: 100%;
      }
    }
    .board_button {
      position: absolute;
      margin-top: -20%;
      left: 45%;
      .btn_connect {
        background-color: yellow;
        padding: 1vw 3vw;
        color: black;
        border: 0;
        cursor: pointer;
        line-height: 1vw;
        font-size: 1.4vw;
        border-radius: 0.3vw;
        font-weight: 600;
        margin: 1vw;
        &:hover {
          color: yellow;
          background-color: black;
        }
      }
    }
  }
  .button-area {
    margin-top: 130px;
    @media (max-width: 767px) {
      margin-top: 50px;
    }
    .join-button {
      ${colors.gredient_orange_bg}
      box-sizing: content-box;
      display: block;
      margin: auto;
      min-width: 250px;
      height: 45px;
      border-radius: 5px;
      font-size: 19px;
      color: #ffffff;
      text-align: center;
      font-weight: 500;
      position: relative;
      border: none;
      box-shadow: 0 12px 25px rgb(0 0 0 / 20%);
      &:hover {
        color: rgb(234 135 51);
        background: white;
      }
    }
  }
`;
