import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import SquareIcon from "@mui/icons-material/Square";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { Timer } from "@mui/icons-material";
import Alert from "@mui/material/Alert";

import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import FooterTwo from "../../components/FooterTwo";
import UserHeader from "../../components/UserHeader";
import { Container, Row, Col, Form } from "react-bootstrap";
import { Styles } from "../dashboard/styles/dashboard.js";
import { startModuleQuizAction, submitModuleQuizAction } from "./action";
import { AlertTitle, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid } from "@mui/material";
import { webUrl } from "../../shared/constants";
import MathJax from "mathjax3-react";
import common from "../../shared/common";
import SampleHeader from "../../components/SampleHeader";
import LoaderImage from "../../components/LoaderImage";

const calculateTimeLeft = (startTime, quizTime) => {
  const difference = +new Date(startTime.getTime() + quizTime * 60000) - +new Date();
  let timeLeft = {};

  if (difference >= 0) {
    timeLeft = {
      // days: Math.floor(difference / (1000 * 60 * 60 * 24)),
      hours: String(Math.floor((difference / (1000 * 60 * 60)) % 24)).padStart(2, "0"),
      minutes: String(Math.floor((difference / 1000 / 60) % 60)).padStart(2, "0"),
      seconds: String(Math.floor((difference / 1000) % 60)).padStart(2, "0"),
    };
  }

  return timeLeft;
};

function ModuleQuiz(props) {
  const {
    match: { params },
  } = props;
  // const [progress, setProgress] = useState(0);
  const [open, setOpen] = useState(false);
  const [selectedQuestion, setSelectedQuestion] = useState(1);
  const [skipQuestionsIndex, setSkipQuestionIndex] = useState([]);
  const [attemptQuestionsIndex, setAttemptQuestionIndex] = useState([]);
  const [userResponses, setUserResponses] = useState([]);

  const [startTime, setStartTime] = useState(new Date());
  const [quizTime, setQuizTime] = useState(0);
  const [quizDataLoaded, setQuizDataLoaded] = useState(false);
  const [disablePrevious, setDisablePrevious] = useState(false);
  const [disableNext, setDisableNext] = useState(false);
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft(startTime, quizTime));

  const [module_quiz_temp, setModuleQuizTemp] = useState({
    moduleQuiz: {
      id: 0,
      name: "",
      quiz_time: 0,
      random_questions: 0,
      module_id: 0,
      sort_order: 0,
      allow_free_access: 1,
      status: 1,
      moduleId: 0,
      questions: [],
      can_user_access: 1,
      is_package_expired: 0,
      message: "Data found",
    },
    memberModuleQuiz: {
      id: 0,
      member_id: 0,
      module_quiz_id: 0,
      total_questions: 0,
      quiz_code: "",
      quiz_started_at: "",
      status: 1,
      memberId: 0,
      moduleQuizId: 0,
    },
  });

  useEffect(() => {
    props.startModuleQuiz({ quiz_id: params.type }).then(() => {
      setQuizDataLoaded(true);
    }); // To do: params.type should be default one
  }, [params.type]);

  const selectedQuestionActions = (selectedId) => {
    setDisablePrevious(false);
    setDisableNext(false);
    switch (+selectedId) {
      case 0:
        setDisablePrevious(true);
        break;
      case module_quiz_temp.moduleQuiz.questions.length - 1:
        setDisableNext(true);
        break;
      default:
        break;
    }
  };
  useEffect(() => {
    if (quizDataLoaded) {
      if (props.module_quiz) {
        setModuleQuizTemp(props.module_quiz);
        setQuizTime(props.module_quiz.moduleQuiz.quiz_time);
        setStartTime(new Date(props.module_quiz.memberModuleQuiz.quiz_started_at));
        selectedQuestionActions(0);
      }
    }
  }, [quizDataLoaded]);

  const handleSelectedQuestion = (e) => {
    setSelectedQuestion(+e.target.value + 1);
    selectedQuestionActions(e.target.value);
  };

  const handleSkipQuestion = () => {
    if (skipQuestionsIndex.indexOf(selectedQuestion) === -1) {
      setSkipQuestionIndex([...skipQuestionsIndex, selectedQuestion]);
      if (attemptQuestionsIndex.indexOf(selectedQuestion) !== -1) {
        let newAttemptedQuestionIndex = [...attemptQuestionsIndex];
        newAttemptedQuestionIndex.splice(attemptQuestionsIndex.indexOf(selectedQuestion), 1);
        setAttemptQuestionIndex(newAttemptedQuestionIndex);
        const question_id = module_quiz_temp.moduleQuiz.questions[selectedQuestion - 1].id;
        const checkResponses = userResponses.filter((questionObj) => +questionObj.question_id !== +question_id);
        setUserResponses(checkResponses);
      }
    }
    if (selectedQuestion === module_quiz_temp.moduleQuiz.questions.length) {
      setSelectedQuestion(1);
      selectedQuestionActions(0);
    } else {
      setSelectedQuestion(selectedQuestion + 1);
      selectedQuestionActions(selectedQuestion);
    }
  };

  const handlePreviousQuestion = () => {
    if (selectedQuestion === 2) {
      setSelectedQuestion(1);
      selectedQuestionActions(0);
    } else {
      selectedQuestionActions(selectedQuestion - 2);
      setSelectedQuestion(selectedQuestion - 1);
    }
  };

  const handleNextQuestion = () => {
    if (selectedQuestion === module_quiz_temp.moduleQuiz.questions.length) {
      setSelectedQuestion(1);
      selectedQuestionActions(0);
    } else {
      setSelectedQuestion(selectedQuestion + 1);
      selectedQuestionActions(selectedQuestion);
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const submitModuleQuiz = () => {
    const difference = +new Date() - +new Date(startTime.getTime());
    let response_time = Math.round(difference / 1000);
    if (difference > +quizTime * 60000) {
      response_time = +quizTime * 60;
    }
    const formSubmitData = {
      member_module_quiz_id: module_quiz_temp.memberModuleQuiz.id,
      quiz_code: module_quiz_temp.memberModuleQuiz.quiz_code,
      member_responses: [...userResponses],
      response_time,
    };
    props.submitModuleQuiz(formSubmitData).then((response) => {
      handleClose();
      props.history.replace(webUrl.moduleQuizReport + "/" + formSubmitData.member_module_quiz_id);
    });
  };

  const handleSubmitQuiz = (e) => {
    e.preventDefault();
    submitModuleQuiz();
  };

  const handleQuizOptionChange = (e) => {
    let question_id = e.target.name.replace("radio-buttons-group-", "");
    let member_response = e.target.value;
    let checkResponses = userResponses.filter((questionObj) => +questionObj.question_id === +question_id);
    if (attemptQuestionsIndex.indexOf(selectedQuestion) === -1) {
      setAttemptQuestionIndex([...attemptQuestionsIndex, selectedQuestion]);
      if (skipQuestionsIndex.indexOf(selectedQuestion) !== -1) {
        let newSkipQuestionIndex = [...skipQuestionsIndex];
        newSkipQuestionIndex.splice(skipQuestionsIndex.indexOf(selectedQuestion), 1);
        setSkipQuestionIndex(newSkipQuestionIndex);
      }
    }
    if (checkResponses.length === 0) {
      setUserResponses([...userResponses, { question_id: +question_id, member_response: +member_response }]);
    } else {
      let newResponse = userResponses.map((questionObj) => {
        if (+questionObj.question_id === +question_id) {
          return { ...questionObj, member_response: +member_response };
        }
        return questionObj;
      });
      setUserResponses(newResponse);
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft(startTime, quizTime));
    }, 1000);

    return () => clearTimeout(timer);
  });

  const timerComponents = [];
  if (timeLeft && Object.keys(timeLeft).length > 0) {
    timerComponents.push(timeLeft["hours"] + ":" + timeLeft["minutes"] + ":" + timeLeft["seconds"]);
  }

  return (
    <div className="main-wrapper course-details-page">
      {/* Header */}
      {common.getFromStorage("isAuthenticated") === "true" && common.getFromStorage("userName") !== "" ? <UserHeader /> : <SampleHeader />}

      <Styles>
        {/* Course Grid */}
        <section className="dashboard-grid-area">
          <Container>
            {props.showLoader && props.showLoader.length > 0 && <LoaderImage />}
            {props.showLoader.length === 0 && (
              <>
                <Row>
                  <Col lg="12">
                    <h3 style={{ padding: "10px" }}>Module Quiz: {module_quiz_temp.moduleQuiz.name}</h3>
                  </Col>
                </Row>
                <Row>
                  <Col
                    lg="3"
                    md="3"
                    sm="12"
                    style={{
                      padding: "10px",
                      border: "1px solid #ccc",
                      borderRadius: "5px",
                      marginTop: "10px",
                    }}
                  >
                    <div
                      style={{
                        padding: "10px",
                        marginTop: "10px",
                        textAlign: "center",
                      }}
                    >
                      <Typography variant="h3" gutterBottom component="div">
                        <Timer
                          style={{
                            fontSize: "34px",
                            marginRight: "7px",
                            marginTop: "-7px",
                          }}
                        />
                        {timerComponents.length ? timerComponents : ""}
                      </Typography>
                    </div>
                    <Typography variant="h5" gutterBottom component="div" style={{ textAlign: "center" }}>
                      Total Questions: {module_quiz_temp.moduleQuiz.questions.length}
                    </Typography>
                    <Grid
                      container
                      sx={{
                        color: "text.primary",
                        padding: "0 20px",
                        marginBottom: "10px",
                      }}
                    >
                      <Grid item xs={1} direction="row">
                        <SquareIcon sx={{ color: "#1565c0" }} />
                      </Grid>
                      <Grid item xs={5} direction="row">
                        <Typography sx={{ padding: "1px 5px" }}> Attempted</Typography>
                      </Grid>
                      <Grid item xs={1} direction="row">
                        <SquareIcon sx={{ color: "yellow" }} />
                      </Grid>
                      <Grid item xs={5} direction="row">
                        <Typography sx={{ padding: "1px 5px" }}> Skipped</Typography>
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      spacing={2}
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                      style={{
                        marginTop: "10px",
                        padding: "0px 20px 16px",
                      }}
                    >
                      {module_quiz_temp.moduleQuiz.questions &&
                        module_quiz_temp.moduleQuiz.questions.map((question, i) => {
                          let selectedVariant = selectedQuestion === i + 1 ? "contained" : "outlined";
                          let selectedColor = "white";
                          let fontColor = "#1565c0";
                          if (skipQuestionsIndex.indexOf(i + 1) !== -1) {
                            selectedColor = "yellow";
                            fontColor = "black";
                          } else if (attemptQuestionsIndex.indexOf(i + 1) !== -1) {
                            selectedColor = "#1565c0";
                            fontColor = "white";
                          } else if (selectedVariant === "contained") {
                            selectedColor = "white";
                            fontColor = "#1565c0";
                          }
                          return (
                            <Grid item xs={4} alignItems={"center"} justifyContent="center">
                              <Button
                                variant={selectedVariant}
                                key={question.id}
                                fullWidth
                                onClick={(e) => handleSelectedQuestion(e)}
                                value={i}
                                style={{
                                  backgroundColor: selectedColor,
                                  color: fontColor,
                                }}
                              >
                                {i + 1}
                              </Button>
                            </Grid>
                          );
                        })}
                    </Grid>
                    <Alert
                      severity="warning"
                      style={{
                        marginTop: "10px",
                      }}
                    >
                      <AlertTitle>Disclaimer</AlertTitle>
                      Please do not <strong>Close</strong> or <strong>Refresh</strong> this page, otherwise you cannot attempt this quiz again with
                      this <strong>ID</strong>
                    </Alert>
                  </Col>
                  <Col>
                    <Row>
                      <Col lg="12" md="12" sm="12">
                        <Form onSubmit={handleClickOpen}>
                          {module_quiz_temp.moduleQuiz.questions &&
                            module_quiz_temp.moduleQuiz.questions.map((question, i) => {
                              let selectedVariant = selectedQuestion === i + 1 ? "block" : "none";
                              const checkResponses = userResponses.filter((questionObj) => +questionObj.question_id === +question.id);
                              let selectedOptionValue = checkResponses.length > 0 ? checkResponses[0].member_response : "";
                              return (
                                <div
                                  style={{
                                    display: `${selectedVariant}`,
                                    padding: "20px 20px 5px",
                                    border: "1px solid #ccc",
                                    borderRadius: "5px",
                                    marginTop: "10px",
                                  }}
                                  key={question.id}
                                >
                                  <Row>
                                    <Col lg="12" md="12" sm="12">
                                      <Typography variant="h5" gutterBottom className="quiz" component="div">
                                        Question {i + 1}
                                      </Typography>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col lg="12" md="12" sm="12">
                                      <FormControl component="fieldset" key={question.id}>
                                        <FormLabel component="legend">
                                          <Typography variant="h2" className="quiz" gutterBottom component="div">
                                            <MathJax.Provider>
                                              <MathJax.Html html={question.question_text} />
                                            </MathJax.Provider>
                                          </Typography>
                                        </FormLabel>
                                        <RadioGroup
                                          name={`radio-buttons-group-${question.id}`}
                                          key={question.id}
                                          value={selectedOptionValue}
                                          onChange={handleQuizOptionChange}
                                        >
                                          <FormControlLabel
                                            value={1}
                                            className="quiz"
                                            control={<Radio />}
                                            label={
                                              <Typography variant="h2" gutterBottom component="div">
                                                <MathJax.Provider>
                                                  <MathJax.Html html={question.option1} />
                                                </MathJax.Provider>
                                              </Typography>
                                            }
                                          />
                                          <FormControlLabel
                                            value={2}
                                            className="quiz"
                                            control={<Radio />}
                                            label={
                                              <Typography variant="h2" gutterBottom component="div">
                                                <MathJax.Provider>
                                                  <MathJax.Html html={question.option2} />
                                                </MathJax.Provider>
                                              </Typography>
                                            }
                                          />
                                          <FormControlLabel
                                            value={3}
                                            className="quiz"
                                            control={<Radio />}
                                            label={
                                              <Typography variant="h2" gutterBottom component="div">
                                                <MathJax.Provider>
                                                  <MathJax.Html html={question.option3} />
                                                </MathJax.Provider>
                                              </Typography>
                                            }
                                          />
                                          <FormControlLabel
                                            value={4}
                                            className="quiz"
                                            control={<Radio />}
                                            label={
                                              <Typography variant="h2" gutterBottom component="div">
                                                <MathJax.Provider>
                                                  <MathJax.Html html={question.option4} />
                                                </MathJax.Provider>
                                              </Typography>
                                            }
                                          />
                                        </RadioGroup>
                                      </FormControl>
                                    </Col>
                                  </Row>
                                </div>
                              );
                            })}
                        </Form>
                        <Box
                          sx={{ flexGrow: 1 }}
                          style={{
                            padding: "10px",
                            border: "1px solid #ccc",
                            borderRadius: "5px",
                            marginTop: "10px",
                          }}
                        >
                          <Grid container>
                            <Grid item xs={4}>
                              <Button
                                variant={"contained"}
                                onClick={handleSkipQuestion}
                                style={{
                                  margin: "10px",
                                  backgroundColor: "yellow",
                                  color: "black",
                                }}
                              >
                                Skip
                              </Button>
                            </Grid>
                            <Grid item xs={8}>
                              <Button variant={"contained"} onClick={handleClickOpen} style={{ margin: "10px", float: "right" }}>
                                Submit
                              </Button>
                              <Button
                                variant={"contained"}
                                disabled={disableNext}
                                onClick={handleNextQuestion}
                                style={{ margin: "10px", float: "right" }}
                              >
                                Next
                              </Button>
                              <Button
                                variant={"contained"}
                                disabled={disablePrevious}
                                onClick={handlePreviousQuestion}
                                style={{ margin: "10px", float: "right" }}
                              >
                                Previous
                              </Button>
                            </Grid>
                          </Grid>
                        </Box>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </>
            )}
          </Container>
        </section>
      </Styles>

      {/* Footer 2 */}
      <FooterTwo />
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Submit Quiz</DialogTitle>
        <DialogContent>
          <DialogContentText>Are you sure you want to submit the quiz?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button variant="contained" onClick={handleSubmitQuiz}>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

const mapStateToProps = (state) => ({
  module_quiz: state.module_quiz.data.module_quiz,
  submit_quiz: state.module_quiz.data.submit_quiz,
  showLoader: state.user.showLoader,
});

const mapDispatchToProps = (dispatch) => ({
  startModuleQuiz: (data) => dispatch(startModuleQuizAction(data)),
  submitModuleQuiz: (data) => dispatch(submitModuleQuizAction(data)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ModuleQuiz));
