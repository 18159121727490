import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import FooterTwo from "../../components/FooterTwo";
import VideoSlider from "../../components/VideoSlider";
import ButtonEnRoll from "../../components/ButtonEnRoll";
import common from "../../shared/common";
import { webUrl } from "../../shared/constants";
import { UsCoursePlansDataAction } from "./action";

const CoursesList = (props) => {
    const [anchorState, setAnchorState] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const openVideoModal = (data) => {
      setIsOpen(true);
    };
    const onClickJoinNow = () => {
      if (common.getFromStorage("userName")) props.history.push(webUrl.userDashboard);
    };
    const onOpenDrawer = (event) => {
      if (event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
        return;
      }
      setAnchorState(true);
    };
    const onCloseDrawer = (event) => {
      if (event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
        return;
      }
      setAnchorState(false);
    };
   
    useEffect(() => {
        props.coursePlansData([]);
      }, []);

    return (
        <>
            <section className="popularCourses">
                <div className="container">
                    <div className="coursesBlk">
                        {props && props.courses && props.courses.length > 0 && props.courses.map((course, index) => {
                            return ( 
                                <div className="cBox" key={index} style={{cursor:"pointer"}} onClick={() => {
                                    props.history.push(process.env.PUBLIC_URL + webUrl.courseDetailsUs+"/"+course.id);
                                  }}>
                                    <img src={course.image_url} />
                                    <span>${course.amount}</span>
                                    <h2>{course.title}</h2>
                                    <ul>
                                        <li>{course.age_group}</li>
                                        <li>{course.module}</li>
                                        <li>{course.video_time}</li>
                                    </ul>
                                    <p><i className="fa fa-star" aria-hidden="true"></i> {course.reviews}</p>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </section>
            
            <section className="testimonialsBox">
                <h3>Testimonials</h3>
                <h4>What Parents and Kids Say About<br />Our Robotic Courses</h4>
                <p>Pulvinar nunc eget tincidunt id urna diam scelerisque cursus.<br />Quis nunc pharetra arcu lectus nisl. Tortor vulputate sed maecenas rhoncusvivera purus.</p>

                <div className="container">
                    <div className="cBox">
                        <span><i className="fa fa-star" aria-hidden="true"></i> 4.8</span>
                        <i className="fa fa-quote-left" aria-hidden="true"></i>
                        <h2>Hands-On Learning</h2>
                        <p>Sed ut elit mattis turpis sit tincidunt amet diam ultrices. venenatis tincidunt augue pellentesque ultrices ut id. in</p>
                        <div className="lmgT"><img src={`${process.env.PUBLIC_URL}/assets/images/usImages/tm.png`} /></div><p>Emily  Johnson <b>Parent</b></p>
                    </div>

                    <div className="cBox">
                        <span><i className="fa fa-star" aria-hidden="true"></i> 4.8</span>
                        <i className="fa fa-quote-left" aria-hidden="true"></i>

                        <h2>Creative Exploration</h2>
                        <p>Sed ut elit mattis turpis sit tincidunt amet diam ultrices. venenatis tincidunt augue pellentesque ultrices ut id. in</p>
                        <div className="lmgT"><img src={`${process.env.PUBLIC_URL}/assets/images/usImages/tm.png`} /></div><p>Emily  Johnson <b>Parent</b></p>
                    </div>

                    <div className="cBox">
                        <span><i className="fa fa-star" aria-hidden="true"></i> 4.8</span>
                        <i className="fa fa-quote-left" aria-hidden="true"></i>

                        <h2>Expert Instructors</h2>
                        <p>Sed ut elit mattis turpis sit tincidunt amet diam ultrices. venenatis tincidunt augue pellentesque ultrices ut id. in</p>
                        <div className="lmgT"><img src={`${process.env.PUBLIC_URL}/assets/images/usImages/tm.png`} /></div><p>Emily  Johnson <b>Parent</b></p>
                    </div>

                    <div className="cBox">
                        <span><i className="fa fa-star" aria-hidden="true"></i> 4.8</span>
                        <i className="fa fa-quote-left" aria-hidden="true"></i>

                        <h2>Confidence Boost</h2>
                        <p>Sed ut elit mattis turpis sit tincidunt amet diam ultrices. venenatis tincidunt augue pellentesque ultrices ut id. in</p>
                        <div className="lmgT"><img src={`${process.env.PUBLIC_URL}/assets/images/usImages/tm.png`} /></div><p>Emily  Johnson <b>Parent</b></p>
                    </div>

                    <div className="cBox">
                        <span><i className="fa fa-star" aria-hidden="true"></i> 4.8</span>
                        <i className="fa fa-quote-left" aria-hidden="true"></i>

                        <h2>Liflong Learning</h2>
                        <p>Sed ut elit mattis turpis sit tincidunt amet diam ultrices. venenatis tincidunt augue pellentesque ultrices ut id. in</p>
                        <div className="lmgT"><img src={`${process.env.PUBLIC_URL}/assets/images/usImages/tm.png`} /></div><p>Emily  Johnson <b>Parent</b></p>
                    </div>

                    <div className="cBox">
                        <span><i className="fa fa-star" aria-hidden="true"></i> 4.8</span>
                        <i className="fa fa-quote-left" aria-hidden="true"></i>

                        <h2>Collaboration</h2>
                        <p>Sed ut elit mattis turpis sit tincidunt amet diam ultrices. venenatis tincidunt augue pellentesque ultrices ut id. in</p>
                        <div className="lmgT"><img src={`${process.env.PUBLIC_URL}/assets/images/usImages/tm.png`} /></div><p>Emily  Johnson <b>Parent</b></p>
                    </div>
                </div>
            </section>

            {/* <section className="howWorks">
                <div className="container">
                    <h2>How It Works</h2>

                    <div className="textBox">
                        <div className="aTx">Pulvinar nunc eget tincidunt id urna diam scelerisque cursus. Quis nunc pharetra arcu lectus nisl. Risus hendreritconsequat aliquet erat ullamcorper diam. Tortor vulputate sed maecenas rhoncusvivera purus.</div>
                        
                        <ButtonEnRoll buttonText="Get Started"clsName="button-enroll" onHeroButtonClick={onClickJoinNow} showPopup={!!!common.getFromStorage("userName")} />
                           
                    </div>

                    <ul>
                        <li><strong>Choose Your Course</strong><p>Quis nunc pharetra arcu lectus nisl. venenatis tincidunt augue pellentesque ultrices ut id.</p></li>
                        <li><strong>Choose Your Course</strong><p>Quis nunc pharetra arcu lectus nisl. venenatis tincidunt augue pellentesque ultrices ut id.</p></li>
                        <li><strong>Choose Your Course</strong><p>Quis nunc pharetra arcu lectus nisl. venenatis tincidunt augue pellentesque ultrices ut id.</p></li>
                        <li><strong>Choose Your Course</strong><p>Quis nunc pharetra arcu lectus nisl. venenatis tincidunt augue pellentesque ultrices ut id.</p></li>
                    </ul>

                </div>
            </section> */}





            <section className="learningMethods">
                <div className="container">
                    <div className="lmLeft">
                        <h2>Learning Methods</h2>
                        <h3>Access to Learn Anytime, Anywhere</h3>
                        <p>Tellus tristique habitant non orci ante ipsum quis.<br />Donec etiam hac in ultrices sed dictumst</p>
                        <ul>
                            <li>World className</li>
                            <li>Easy Learning</li>
                            <li>Flexible</li>
                            <li>Affordable</li>
                        </ul>
                        <ButtonEnRoll buttonText="Enroll Now!" onHeroButtonClick={onClickJoinNow} showPopup={!!!common.getFromStorage("userName")} />
                    </div>
                    <div className="lmRgt"><img src={`${process.env.PUBLIC_URL}/assets/images/usImages/12.png`}/></div>
                </div>

            </section>

        </>
    );
};

const mapStateToProps = (state) => ({
    courses: state.usCoursesList.data.courses || [],
    showLoader: state.user.showLoader,
});

const mapDispatchToProps = (dispatch) => ({
    coursePlansData: (data) => dispatch(UsCoursePlansDataAction(data)),
    // addToCart: (data) => dispatch(addToCartAction(data)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CoursesList));

