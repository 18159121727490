import styled from "styled-components";
import { colors, fonts } from "../common/element/elements.js";

export const Styles = styled.div`
  .top-bar2 {
    height: 40px;
    border-bottom: 1px solid ${colors.border1};

    .bar-left {
      margin-top: 8px;

      ul {
        li {
          font-size: 14px;
          color: ${colors.text2};
          margin-right: 20px;
          display: inline-block;

          svg {
            font-size: 18px;
            color: ${colors.green};
            vertical-align: text-bottom;
            margin-right: 5px;

            @media (max-width: 1199px) {
              margin-right: 2px;
            }
          }

          @media (max-width: 1199px) {
            margin-right: 8px;
          }

          @media (max-width: 991px) {
            font-size: 13px;
          }

          a {
            color: ${colors.text2};

            &:hover {
              color: ${colors.green};
            }
          }
        }
        @media (max-width: 991px) {
          text-align: center;
        }
      }
    }

    .bar-right {
      margin-top: 6px;

      ul.bar-lang {
        margin-right: 30px;
        position: relative;
        margin-top: 3px;

        &::before {
          position: absolute;
          content: "";
          background: ${colors.border1};
          width: 1px;
          height: 20px;
          top: 0;
          right: -15px;
        }

        li {
          .dropdown {
            button.dropdown-toggle {
              font-size: 13px;
              color: ${colors.text2};
              background: transparent;
              border: none;
              padding: 0;
              box-shadow: none;

              img {
                margin-right: 5px;
                max-width: 21px;
              }

              svg {
                font-size: 12px;
                margin-left: 3px;
              }

              &::after {
                display: none;
              }

              &:hover {
                cursor: pointer;
                color: ${colors.green};
              }
            }

            ul.dropdown-menu {
              padding: 0;
              margin: 0;
              border: none;
              background-color: #ffffff;
              border-radius: 5px;
              top: 50% !important;
              left: -16px !important;
              box-shadow: 0 0 2px rgba(0, 0, 0, 0.2);

              li {
                display: block;
                border-bottom: 1px solid ${colors.border1};
                padding: 7px 15px;
                color: ${colors.text3};
                cursor: pointer;

                img {
                  max-width: 21px;
                  margin-right: 5px;
                }

                &:hover {
                  color: ${colors.black1};
                  font-weight: 500;
                  background-color: transparent;
                }

                &:last-child {
                  border-bottom: none;
                }
              }
            }
          }
        }
      }

      ul.bar-social {
        margin-right: 30px;
        position: relative;

        &::before {
          position: absolute;
          content: "";
          background: ${colors.border1};
          width: 1px;
          height: 20px;
          top: 3px;
          right: -15px;
        }

        li {
          a {
            font-size: 13px;
            color: ${colors.green};
            background: ${colors.border1};
            display: inline-block;
            width: 25px;
            height: 25px;
            text-align: center;
            padding-top: 3px;
            border-radius: 5px;

            &:hover {
              background: ${colors.green};
              color: #ffffff;
            }
          }

          &:last-child {
            a {
              margin-right: 0;
            }
          }
        }

        @media (min-width: 768px) and (max-width: 991px) {
          display: none;
        }
      }

      ul.sidebar-button {
        li.side-box {
          a.nav-link {
            font-size: 20px;
            padding: 0;
            line-height: 28px;
            svg {
              color: ${colors.green};
            }
          }
        }
      }
      @media (max-width: 767px) {
        justify-content: center !important;
        margin-bottom: 6px;
        border-top: 1px solid #ccc;
        padding-top: 6px;
      }
    }

    @media (max-width: 767px) {
      height: auto;
    }
  }

  .logo-area2 {
    height: 82px;
    padding-top: 20px;
    box-shadow: 0 3px 8px -6px #222222;
    @media (max-width: 767px) {
      display: none;
    }
    .logo {
      text-align: center;
      width: 100%;
      a {
        font-size: 29px;
        font-weight: 500;
        font-family: ${fonts.manrope};
        color: ${colors.green};
        img {
          max-height: 50px;
          max-width: 100%;
        }
      }
    }

    .la-bars {
      color: ${colors.green};
    }

    ul.bar-user {
      li {
        padding: 2px 15px;
        margin: 0;
        display: inline-block;

        a {
          font-size: 13px;
          color: ${colors.text2};

          svg {
            font-size: 20px;
            color: ${colors.green};
            vertical-align: bottom;
            margin-right: 5px;
          }

          &:hover {
            color: ${colors.green};
          }
        }

        @media (max-width: 991px) {
          border-right: none;
          padding-right: 0;
        }
      }
    }

    div.menu-box {
      ul.nav.menu-nav {
        li.nav-item {
          position: relative;
          padding-right: 10px;

          a.nav-link {
            font-size: 14px;
            color: ${colors.black1};
            text-transform: capitalize;
            font-weight: 550;
            padding: 10px 10px 20px;

            svg {
              font-size: 12px;
            }

            &:after {
              content: none;
            }

            &:hover {
              color: ${colors.green};
            }

            @media (max-width: 1199px) {
              padding: 10px 2px 20px;
              letter-spacing: 0;
              font-size: 13px;
            }
          }

          ul.dropdown {
            position: absolute;
            left: 0;
            top: 100%;
            min-width: 190px;
            background: #fff;
            border: 1px solid ${colors.border1};
            text-align: left;
            padding: 0;
            border-radius: 5px;
            transition: 0.2s ease;
            opacity: 0;
            transform: scaleY(0);
            visibility: hidden;
            z-index: 999;
            transform-origin: center top 0;

            li.nav-item {
              position: relative;

              a.nav-link {
                font-size: 13px;
                color: ${colors.text1};
                padding: 10px 20px;
                text-transform: capitalize;
                font-weight: 400;
                margin-right: 0;
                border-bottom: 1px solid ${colors.border1};

                &:hover {
                  color: ${colors.green};

                  svg {
                    color: #fff;
                  }
                }

                svg {
                  float: right;
                  margin-top: 4px;
                }
              }

              &:last-child {
                margin-left: 0;

                a.nav-link {
                  border-bottom: none;
                }
              }

              ul.dropdown2 {
                position: absolute;
                top: 0;
                left: 100%;
                min-width: 180px;
                background: #fff;
                border: 1px solid ${colors.border1};
                transition: 0.2s ease;
                opacity: 0;
                transform: scaleY(0);
                visibility: hidden;
                z-index: 999;
                transform-origin: center top 0;
              }

              &:hover {
                ul.dropdown2 {
                  opacity: 1;
                  transform: scaleY(1);
                  visibility: visible;
                }
              }
            }
          }

          &:hover {
            ul.dropdown {
              opacity: 1;
              transform: scaleY(1);
              visibility: visible;
            }
          }
        }
      }

      .search-box {
        margin-left: 18px;
        margin-top: 11px;

        a {
          padding: 0;

          svg {
            font-size: 18px;
            color: ${colors.green};
          }
        }

        i.close-btn {
          position: absolute;
          top: 25px;
          right: -12px;
          font-size: 26px;
          color: ${colors.green};
          cursor: pointer;
        }

        @media (max-width: 1199px) {
          margin-left: 5px;
        }
      }

      .apply-btn {
        margin-left: 100px;
        margin-top: -1px;

        a {
          font-size: 13px;
          color: #ffffff;
          background: ${colors.gr_bg};
          display: inline-block;
          height: 40px;
          text-align: center;
          text-transform: uppercase;
          font-weight: 500;
          padding: 11px;
          border-radius: 0 5px 5px 0;
          position: relative;

          svg {
            position: absolute;
            font-size: 20px;
            left: -40px;
            top: 0;
            padding: 10px;
            width: 40px;
            height: 100%;
            border-radius: 5px 0 0 5px;
            background-color: ${colors.green};
          }

          &:hover {
            background: ${colors.gr_bg2};
          }
        }

        @media (max-width: 991px) {
          display: none;
        }
      }
    }

    @media (max-width: 767px) {
      .col-sm-0 {
        display: none;
      }
      .bar-right {
        justify-content: center !important;
      }
    }
  }
  .cart-count {
    border: 1px solid ${colors.green};
    border-radius: 50%;
    position: absolute;
    font-size: 11px;
    width: 17px;
    margin-left: 1px;
    margin-top: 5px;
    height: 17px;
    text-align: center;
  }
`;
