import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Autocomplete, Box, Button, Grid, TextField, Typography } from "@mui/material";
import common from "../../../shared/common";
import { getMasterDataAction, updateMemberProfileAction } from "../action";
import { webUrl } from "../../../shared/constants";

const EditProfile = (props) => {
  const [open, setOpen] = useState(false);
  const userProfile = JSON.parse(common.getFromStorage("profile"));
  let fieldName = props.field;
  const [name, setName] = useState(userProfile.name);
  const [nameError, setNameError] = useState("");
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedCountryInput, setSelectedCountryInput] = useState("");
  const [countryError, setCountryError] = useState("");
  const [selectedState, setSelectedState] = useState(null);
  const [selectedStateInput, setSelectedStateInput] = useState("");
  const [stateError, setStateError] = useState("");
  const [selectedCity, setSelectedCity] = useState(null);
  const [selectedCityInput, setSelectedCityInput] = useState("");
  const [cityError, setCityError] = useState("");
  const [selectedSchool, setSelectedSchool] = useState(null);
  const [selectedSchoolInput, setSelectedSchoolInput] = useState("");
  const [schoolError, setSchoolError] = useState("");
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedCategoryInput, setSelectedCategoryInput] = useState("");
  const [categoryError, setCategoryError] = useState("");
  const [selectedSubcategory, setSelectedSubcategory] = useState(null);
  const [selectedSubcategoryInput, setSelectedSubcategoryInput] = useState("");
  const [subcategoryError, setSubcategoryError] = useState("");

  const handleNameChange = (e) => {
    e.preventDefault();
    setNameError("");
    setName(e.target.value);
    if (e.target.value === "") {
      setNameError("Name cannot be empty");
    }
  };
  let modules = ["categories", "countries"];
  let dataPayload = { modules };
  if (userProfile.subcategory && fieldName === "category") {
    modules.push("category_subcategories");
    dataPayload = { modules, category_id: userProfile.subcategory.category_id };
  }
  if (userProfile.city && fieldName === "city") {
    modules.push("country_states");
    modules.push("state_cities");
    modules.push("city_schools");
    dataPayload = { modules, country_id: userProfile.city.state.country_id, state_id: userProfile.city.state.id, city_id: userProfile.city.id };
  }
  useEffect(() => {
    if (fieldName) {
      if (fieldName === "city" || fieldName === "category") {
        props.getMasterData(dataPayload).then(() => {
          setSelectedCategory(userProfile.subcategory ? userProfile.subcategory.category_id : null);
          setSelectedCategoryInput(userProfile.subcategory ? userProfile.subcategory.category.name : "");
          setSelectedSubcategory(userProfile.subcategory ? userProfile.subcategory.id : null);
          setSelectedSubcategoryInput(userProfile.subcategory ? userProfile.subcategory.name : "");
          setSelectedCountry(userProfile.city ? userProfile.city.state.country_id : null);
          setSelectedCountryInput(userProfile.city ? userProfile.city.state.country.name : "");
          setSelectedState(userProfile.city ? userProfile.city.state.id : null);
          setSelectedStateInput(userProfile.city ? userProfile.city.state.name : "");
          setSelectedCity(userProfile.city ? userProfile.city.id : null);
          setSelectedCityInput(userProfile.city ? userProfile.city.name : "");
          setSelectedSchool(userProfile.member_school ? userProfile.member_school.id : null);
          setSelectedSchoolInput(userProfile.member_school ? userProfile.member_school.name : "");
          setOpen(true);
        });
      } else {
        setOpen(true);
      }
    }
  }, [fieldName]);

  const handleClose = () => {
    setOpen(false);
    props.setField(null);
  };

  const handleSave = () => {
    let profileData = {};
    if (fieldName === "name") {
      profileData = { name: name };
    }
    if (fieldName === "city") {
      profileData = { city_id: selectedCity, school_id: selectedSchool };
    }
    if (fieldName === "category") {
      profileData = { subcategory_id: selectedSubcategory };
    }
    props.updateMemberProfile(profileData).then(() => {
      setOpen(false);
      props.setField(null);
      props.history.replace(webUrl.profile);
    });
  };

  return (
    <div>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
        <DialogTitle sx={{ fontSize: "30px" }}>
          {userProfile &&
            ((+userProfile.member_type === 1 && "Student") ||
              (+userProfile.member_type === 2 && "Teacher") ||
              (+userProfile.member_type === 3 && "School"))}{" "}
          Profile
        </DialogTitle>
        <DialogContent>
          <Box
            component="form"
            sx={{
              "& .MuiTextField-root": { m: 1 },
            }}
            noValidate
            autoComplete="off"
          >
            <Grid container spacing={2}>
              {fieldName === "name" && (
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Name"
                    fullWidth
                    autoFocus
                    placeholder="Enter full name"
                    variant="outlined"
                    inputProps={{ maxLength: 30 }}
                    required
                    onChange={handleNameChange}
                    value={name}
                    error={nameError !== ""}
                    helperText={nameError}
                  />
                </Grid>
              )}
              {fieldName === "city" && (
                <>
                  <Grid item xs={12} sm={6} md={4}>
                    <Autocomplete
                      id="country"
                      freeSolo
                      options={props.countries}
                      value={selectedCountry}
                      inputValue={selectedCountryInput}
                      onChange={(event, newValue) => {
                        setSelectedCountry(newValue ? newValue.id : null);
                        setSelectedCountryInput(newValue ? newValue.name : "");
                        setSelectedState(null);
                        setSelectedStateInput("");
                        setSelectedCity(null);
                        setSelectedCityInput("");
                        setSelectedSchool(null);
                        setSelectedSchoolInput("");
                        if (newValue && newValue.id) {
                          props.getMasterData({ modules: ["country_states"], country_id: newValue.id });
                        }
                      }}
                      onInputChange={(event, newInputValue) => {
                        setSelectedCountryInput(newInputValue);
                      }}
                      getOptionLabel={(option) => {
                        if (typeof option === "string") {
                          return "";
                        } else if (!isNaN(option)) {
                          return props.countries.filter((country) => country.id === option)[0].name;
                        } else {
                          return option.name;
                        }
                      }}
                      clearOnBlur
                      renderOption={(props, option) => (
                        <Box component="li" {...props} key={"" + option.id}>
                          {option.name}
                        </Box>
                      )}
                      onKeyDown={(event) => {
                        if (event.key === "Enter") {
                          event.defaultMuiPrevented = true;
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="filled"
                          label="Select Country"
                          required
                          error={countryError !== ""}
                          helperText={countryError}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <Autocomplete
                      id="state"
                      freeSolo
                      options={props.states}
                      value={selectedState}
                      inputValue={selectedStateInput}
                      onChange={(event, newValue) => {
                        setSelectedState(newValue ? newValue.id : null);
                        setSelectedStateInput(newValue ? newValue.name : "");
                        setSelectedCity(null);
                        setSelectedCityInput("");
                        setSelectedSchool(null);
                        setSelectedSchoolInput("");
                        if (newValue && newValue.id) {
                          props.getMasterData({ modules: ["state_cities"], state_id: newValue.id });
                        }
                      }}
                      onInputChange={(event, newInputValue) => {
                        setSelectedStateInput(newInputValue);
                      }}
                      getOptionLabel={(option) => {
                        if (typeof option === "string") {
                          return "";
                        } else if (!isNaN(option)) {
                          return props.states.filter((state) => state.id === option)[0].name;
                        } else {
                          return option.name;
                        }
                      }}
                      clearOnBlur
                      renderOption={(props, option) => (
                        <Box component="li" {...props} key={"" + option.id}>
                          {option.name}
                        </Box>
                      )}
                      onKeyDown={(event) => {
                        if (event.key === "Enter") {
                          event.defaultMuiPrevented = true;
                        }
                      }}
                      renderInput={(params) => (
                        <TextField {...params} variant="filled" label="Select State" required error={stateError !== ""} helperText={stateError} />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <Autocomplete
                      id="city"
                      freeSolo
                      options={props.cities}
                      value={selectedCity}
                      inputValue={selectedCityInput}
                      onChange={(event, newValue) => {
                        setSelectedCity(newValue ? newValue.id : null);
                        setSelectedCityInput(newValue ? newValue.name : "");
                        setSelectedSchool(null);
                        setSelectedSchoolInput("");
                        if (newValue && newValue.id) {
                          props.getMasterData({ modules: ["city_schools"], city_id: newValue.id });
                        }
                      }}
                      onInputChange={(event, newInputValue) => {
                        setSelectedCityInput(newInputValue);
                      }}
                      getOptionLabel={(option) => {
                        if (typeof option === "string") {
                          return "";
                        } else if (!isNaN(option)) {
                          return props.cities.filter((city) => city.id === option)[0].name;
                        } else {
                          return option.name;
                        }
                      }}
                      clearOnBlur
                      renderOption={(props, option) => (
                        <Box component="li" {...props} key={"" + option.id}>
                          {option.name}
                        </Box>
                      )}
                      onKeyDown={(event) => {
                        if (event.key === "Enter") {
                          event.defaultMuiPrevented = true;
                        }
                      }}
                      renderInput={(params) => (
                        <TextField {...params} variant="filled" label="Select City" required error={cityError !== ""} helperText={cityError} />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <Autocomplete
                      id="school"
                      freeSolo
                      options={props.schools}
                      value={selectedSchool}
                      inputValue={selectedSchoolInput}
                      onChange={(event, newValue) => {
                        setSelectedSchool(newValue ? newValue.id : null);
                        setSelectedSchoolInput(newValue ? newValue.name : "");
                      }}
                      onInputChange={(event, newInputValue) => {
                        setSelectedSchoolInput(newInputValue);
                      }}
                      getOptionLabel={(option) => {
                        if (typeof option === "string") {
                          return "";
                        } else if (!isNaN(option)) {
                          return props.schools.filter((school) => school.id === option)[0].name;
                        } else {
                          return option.name;
                        }
                      }}
                      clearOnBlur
                      renderOption={(props, option) => (
                        <Box component="li" {...props} key={"" + option.id}>
                          {option.name}
                        </Box>
                      )}
                      onKeyDown={(event) => {
                        if (event.key === "Enter") {
                          event.defaultMuiPrevented = true;
                        }
                      }}
                      renderInput={(params) => (
                        <TextField {...params} variant="filled" label="Select School" required error={schoolError !== ""} helperText={schoolError} />
                      )}
                    />
                  </Grid>
                </>
              )}
              {fieldName === "category" && (
                <>
                  <Grid item xs={12} sm={6} md={4}>
                    <Autocomplete
                      id="category"
                      freeSolo
                      options={props.categories}
                      value={selectedCategory}
                      inputValue={selectedCategoryInput}
                      onChange={(event, newValue) => {
                        setSelectedCategory(newValue ? newValue.id : null);
                        setSelectedCategoryInput(newValue ? newValue.name : "");
                        setSelectedSubcategory(null);
                        setSelectedSubcategoryInput("");
                        if (newValue && newValue.id) {
                          props.getMasterData({ modules: ["category_subcategories"], category_id: newValue.id });
                        }
                      }}
                      onInputChange={(event, newInputValue) => {
                        setSelectedCategoryInput(newInputValue);
                      }}
                      getOptionLabel={(option) => {
                        if (typeof option === "string") {
                          return "";
                        } else if (!isNaN(option)) {
                          return props.categories && props.categories.length > 0
                            ? props.categories.filter((category) => category.id === option)[0].name
                            : "";
                        } else {
                          return option.name;
                        }
                      }}
                      clearOnBlur
                      renderOption={(props, option) => (
                        <Box component="li" {...props} key={"" + option.id}>
                          {option.name}
                        </Box>
                      )}
                      onKeyDown={(event) => {
                        if (event.key === "Enter") {
                          event.defaultMuiPrevented = true;
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="filled"
                          label="Select Category"
                          required
                          error={categoryError !== ""}
                          helperText={categoryError}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <Autocomplete
                      id="subcategory"
                      freeSolo
                      options={props.subcategories}
                      value={selectedSubcategory}
                      inputValue={selectedSubcategoryInput}
                      onChange={(event, newValue) => {
                        setSelectedSubcategory(newValue ? newValue.id : null);
                        setSelectedSubcategoryInput(newValue ? newValue.name : "");
                      }}
                      onInputChange={(event, newInputValue) => {
                        setSelectedSubcategoryInput(newInputValue);
                      }}
                      getOptionLabel={(option) => {
                        if (typeof option === "string") {
                          return "";
                        } else if (!isNaN(option)) {
                          return props.subcategories && props.subcategories.length > 0
                            ? props.subcategories.filter((subcategory) => subcategory.id === option)[0].name
                            : "";
                        } else {
                          return option.name;
                        }
                      }}
                      clearOnBlur
                      renderOption={(props, option) => (
                        <Box component="li" {...props} key={"" + option.id}>
                          {option.name}
                        </Box>
                      )}
                      onKeyDown={(event) => {
                        if (event.key === "Enter") {
                          event.defaultMuiPrevented = true;
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="filled"
                          label="Select Subcategory"
                          required
                          error={subcategoryError !== ""}
                          helperText={subcategoryError}
                        />
                      )}
                    />
                  </Grid>
                </>
              )}
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions className="justify-content-left">
          <Button onClick={handleClose} variant="outlined" size="large">
            Cancel
          </Button>
          <Button
            onClick={handleSave}
            className="orange-bg"
            variant="contained"
            size="large"
            disabled={
              (fieldName === "name" && nameError !== "") ||
              (fieldName === "city" && selectedCity === null) ||
              (fieldName === "category" && selectedSubcategory === null)
            }
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const mapStateToProps = (state) => ({
  categories: state.profile.data.categories,
  subcategories: state.profile.data.category_subcategories,
  countries: state.profile.data.countries,
  states: state.profile.data.country_states,
  cities: state.profile.data.state_cities,
  schools: state.profile.data.city_schools,
  member: state.profile.data.member_profile,
  error: state.profile.error,
  showLoader: state.user.showLoader,
  showSubLoader: state.profile.showSubLoader,
});

const mapDispatchToProps = (dispatch) => ({
  getMasterData: (data) => dispatch(getMasterDataAction(data)),
  updateMemberProfile: (data) => dispatch(updateMemberProfileAction(data)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EditProfile));
