import { showLoaderAction, stopLoaderAction } from "../user/action";
import { getRecordedSessionsData, getValidatedRecordedSessionData } from "./service";

/**
 * Action types
 */
export const actionTypes = {
  RECORDED_SESSIONS_DATA_REQUEST_SUCCESS: "RECORDED_SESSIONS_DATA_REQUEST_SUCCESS",
  RECORDED_SESSION_CHECK_DATA_REQUEST_SUCCESS: "RECORDED_SESSION_CHECK_DATA_REQUEST_SUCCESS",
  SET_FORM_DATA: "SET_FORM_DATA",
};

export const receivedRecordedSessionsDataAction = (payload) => ({
  type: actionTypes.RECORDED_SESSIONS_DATA_REQUEST_SUCCESS,
  payload,
});

export const recordedSessionsDataAction = (data) => {
  return (dispatch) => {
    dispatch(showLoaderAction("getRecordedSessionsData"));

    return getRecordedSessionsData(data)
      .then((response) => {
        dispatch(stopLoaderAction("getRecordedSessionsData"));

        if (response) dispatch(receivedRecordedSessionsDataAction(response));
      })
      .catch((error) => {
        dispatch(stopLoaderAction("getRecordedSessionsData"));
      });
  };
};

export const setRecordedSessionsFormDataAction = (payload) => ({
  type: actionTypes.SET_FORM_DATA,
  payload,
});

export const receivedRecordedSessionCheckDataAction = (payload) => ({
  type: actionTypes.RECORDED_SESSION_CHECK_DATA_REQUEST_SUCCESS,
  payload,
});

export const recordedSessionCheckDataAction = (data) => {
  return (dispatch) => {
    // dispatch(showLoaderAction("getRecordedSessionCheckData"));

    return getValidatedRecordedSessionData(data)
      .then((response) => {
        
        dispatch(stopLoaderAction("getRecordedSessionCheckData"));
        
        dispatch(receivedRecordedSessionCheckDataAction(response));

        return response;
      })
      .catch((error) => {
        dispatch(stopLoaderAction("getRecordedSessionCheckData"));
      });
  };
};
