import styled from "styled-components";
import { colors } from "../common/element/elements.js";

export const Styles = styled.div`
  .about-us {
    padding: 60px 0 50px;

    .about-bottom {
      @media (max-width: 1199px) {
        flex-direction: column-reverse;
      }
    }
    .about-image {
      position: relative;

      img.main-img {
        width: 100%;
        border-radius: 5px;
        margin-top: 50px;

        &:hover {
          transform: scale(1.02);
          transition: 0.7s;
        }
        @media (max-width: 767px) {
          display: none;
        }
      }

      img.pattern-img {
        position: absolute;
        bottom: -94px;
        left: 0;

        @media (max-width: 1199px) {
          max-width: 100%;
        }

        @media (max-width: 767px) {
          display: none;
        }
      }

      .video-player {
        position: absolute;
        bottom: -83px;
        right: 0;
        width: 150px;
        height: 170px;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        border-radius: 5px;

        &::before {
          position: absolute;
          content: "";
          background-color: rgba(0, 0, 0, 0.2);
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          border-radius: 5px;
        }

        button.play-button {
          position: absolute;
          z-index: 10;
          top: 50%;
          left: 50%;
          transform: translateX(-50%) translateY(-50%);
          box-sizing: content-box;
          display: block;
          width: 32px;
          height: 44px;
          border-radius: 50%;

          svg,
          i {
            position: relative;
            font-size: 40px;
            color: ${colors.bg1};
            z-index: 11;
            padding-top: 2px;
            margin-left: -2px;
          }

          &::before {
            content: "";
            position: absolute;
            z-index: 0;
            left: 50%;
            top: 50%;
            transform: translateX(-50%) translateY(-50%);
            display: block;
            width: 50px;
            height: 50px;
            background: #ffffff;
            border-radius: 50%;
            animation: pulse-border 1500ms ease-out infinite;
          }

          &:after {
            content: "";
            position: absolute;
            z-index: 1;
            left: 50%;
            top: 50%;
            transform: translateX(-50%) translateY(-50%);
            display: block;
            width: 50px;
            height: 50px;
            background: #ffffff;
            border-radius: 50%;
            transition: all 200ms;
          }

          &:hover {
            svg,
            i {
              color: ${colors.green};
            }
          }

          @keyframes pulse-border {
            0% {
              transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
              opacity: 1;
            }

            100% {
              transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
              opacity: 0;
            }
          }
        }

        @media (max-width: 991px) {
          bottom: -70%;
        }

        @media (max-width: 767px) {
          position: unset;
          width: 100%;
          max-height: 250px;
          margin-bottom: 30px;
        }
      }
    }

    .about-content {
      h4.about-title {
        color: ${colors.black1};
        line-height: 35px;
        font-size: 29px;
        font-weight: 800;
        margin-bottom: 25px;

        @media (max-width: 575px) {
          margin-bottom: 15px;
          font-size: 20px;
        }
      }

      .about-para {
        font-size: 16px;
        color: ${colors.black};
        opacity: 0.7;
        line-height: 28px;
        margin-bottom: 40px;
        font-weight: 400;
        ul {
          padding: 0 20px;
          li {
            font-size: 16px;
          }
        }
        p {
          font-size: 16px;
          color: ${colors.black};
          opacity: 0.7;
          line-height: 28px;
          margin-bottom: 20px;
          font-weight: 400;
        }

        span {
          margin-top: 20px;
        }

        @media (max-width: 575px) {
          margin-bottom: 20px;
        }
      }

      .counter-box {
        h3 {
          margin-bottom: 10px;

          span {
            font-weight: 600;
          }

          svg,
          i {
            font-size: 20px;
            vertical-align: middle;
          }
        }

        p {
          font-size: 14px;
          color: ${colors.text3};
          font-weight: 500;
        }

        @media (max-width: 575px) {
          display: none;
        }
      }

      .counter-box.box1 {
        h3 {
          color: ${colors.red};
        }
      }

      .counter-box.box2 {
        h3 {
          color: ${colors.purple};
        }
      }

      .counter-box.box3 {
        h3 {
          color: ${colors.blue};
        }
      }

      a.readmore-btn {
        font-size: 16px;
        font-weight: 400;
        line-height: 18px;
        color: #fff;
        display: inline-block;
        width: 145px;
        height: 40px;
        text-align: center;
        padding: 11px;
        border-radius: 5px;

        &:hover {
          transform: scale(1.1);
          transition: 0.7s;
        }

        @media (max-width: 575px) {
          margin-top: 0;
        }
      }
    }

    .about1 {
      float: right;
      @media (max-width: 767px) {
        float: none;
      }
    }
    .about-content {
      button.pull-right {
        float: right;
        @media (max-width: 767px) {
          float: none;
          margin: 0 auto;
          display: block;
        }
      }
    }
    @media (max-width: 767px) {
      padding: 30px 0 40px;
    }
  }
  .about_science {
    ${colors.gredient_blue_text}
    .first-letter {
      font-size: 40px;
      line-height: 49px;
      @media (max-width: 767px) {
        font-size: 25px;
        line-height: 40px;
      }
    }
  }
  .about_math {
    ${colors.gredient_red_text}
    .first-letter {
      font-size: 40px;
      line-height: 49px;
      @media (max-width: 767px) {
        font-size: 25px;
        line-height: 40px;
      }
    }
  }
  .red_btn {
    background: ${colors.gradient_red_color} !important;
  }
  .green_btn {
    background: ${colors.gradient_green_color} !important;
  }
  .orange-bg {
    ${colors.gredient_orange_bg}
    color: white;
    svg {
      color: #fff;
    }
    &:hover {
      color: #000;
      svg {
        color: #000;
      }
    }
  }
  .about_robotics {
    ${colors.gredient_green_text}
    .first-letter {
      font-size: 40px;
      line-height: 49px;
      @media (max-width: 767px) {
        font-size: 25px;
        line-height: 40px;
      }
    }
  }
  .teacher {
    ${colors.gredient_green_text}
    font-size: 30px;
    font-weight: 800;
  }
  .empowering {
    ${colors.gredient_black_text}
    font-size: 30px;
  }
  .description {
    font-size: 16px;
    line-height: 28px;
  }
  .stem_teaching {
    ${colors.gredient_violet_text}
    font-weight: 800;
  }
  .connect-button {
    height: 45px;
    font-size: 24px;
    text-transform: capitalize;
    min-width: 250px;
    padding: 0 30px;
    box-shadow: 0 12px 25px rgb(0 0 0 / 20%);
    &:hover {
      color: rgb(234 135 51);
      background: white;
    }
  }
`;
