import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { getModuleHardwareBuildingAction } from "./action";
import { Styles } from "./styles";
import LoaderNew from "../../components/LoaderNew";

const ModuleHardwareBuilding = (props) => {
  const {
    match: { params },
  } = props;

  useEffect(() => {
    props.getModuleHardwareBuildingData([params.id, params.vid]); // To do: params.type should be default one
  }, []);

  if (props.showLoader && props.showLoader.length > 0) {
    return <LoaderNew />;
  }

  return (
    props.showLoader.length === 0 &&
    props.video && (
      <Styles>
        <div className="embed-responsive embed-responsive-16by9" id="main_video">
          <iframe id="class-video" title="video" className="embed-responsive-item" src={props.video.full_video_url} allowFullScreen />
        </div>
      </Styles>
    )
  );
};

const mapStateToProps = (state) => ({
  video: state.embed.data.video || "",
  showLoader: state.user.showLoader,
});

const mapDispatchToProps = (dispatch) => ({
  getModuleHardwareBuildingData: (data) => dispatch(getModuleHardwareBuildingAction(data)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ModuleHardwareBuilding));
