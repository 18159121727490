import React, { Component } from "react";
import { Container, Row, Col, Dropdown } from "react-bootstrap";
import StickyMenu from "./common/StickyMenu";
import MobileMenu from "./common/MobileMenu";
import { Styles } from "./styles/headerTwo.js";
import User from "../pages/user";
import common from "../shared/common";
import { webUrl } from "../shared/constants";
import Connect from "../pages/connect/index.js";
import PhoneOutlinedIcon from "@mui/icons-material/PhoneOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import AssignmentOutlinedIcon from "@mui/icons-material/AssignmentOutlined";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import InstagramIcon from "@mui/icons-material/Instagram";
import AccountCircleRoundedIcon from "@mui/icons-material/AccountCircleRounded";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import SampleStickyMenu from "./common/SampleStickyMenu";
import SampleMobileMenu from "./common/SampleMobileMenu";
import { Link } from "@mui/material";

class SampleHeader extends Component {
  render(props) {
    return (
      <Styles>
        {/* Topbar 2 */}
        <section className="top-bar2">
          <Container>
            <Row>
              <Col lg="7" md="9">
                <div className="bar-left">
                  <ul className="list-unstyled list-inline">
                    <li className="list-inline-item">
                      <PhoneOutlinedIcon />
                      (931) 009 1906
                    </li>
                    <li className="list-inline-item">
                      <a href="mailto:info@ealyss.com">
                        <EmailOutlinedIcon />
                        info@ealyss.com
                      </a>
                    </li>
                    <li className="list-inline-item">
                      <Link href="#" underline="none">
                        <AssignmentOutlinedIcon />
                        Request a call
                      </Link>
                    </li>
                  </ul>
                </div>
              </Col>
              <Col lg="5" md="3">
                <div className="bar-right d-flex justify-content-end">
                  <ul className="list-unstyled list-inline bar-login">
                    <li className="list-inline-item">
                      <Link href={"#"} underline="none">
                        <PersonOutlineOutlinedIcon />
                        {"Login / Signup"}
                      </Link>
                    </li>
                  </ul>
                </div>
              </Col>
            </Row>
          </Container>
        </section>

        {/* Logo Area 2 */}
        <section className="logo-area2">
          <Container>
            <Row>
              <Col md="2">
                <div className="logo">
                  <Link href="#" underline="none">
                    <img src={process.env.PUBLIC_URL + "/assets/images/logo2.webp"} alt="" />
                  </Link>
                </div>
              </Col>
              <Col md="10">
                <div className="menu-box d-flex justify-content-end">
                  <ul className="nav menu-nav">
                    <li className="nav-item dropdown active">
                      <Link className="nav-link dropdown-toggle" href="#" underline="none" data-toggle="dropdown">
                        Study Material
                      </Link>
                    </li>
                    <li className="nav-item dropdown">
                      <Link className="nav-link dropdown-toggle" href="#" underline="none" data-toggle="dropdown">
                        Robotics
                      </Link>
                    </li>
                    {/* <li className="nav-item dropdown">
                      <Link className="nav-link dropdown-toggle" href="#" underline="none" data-toggle="dropdown">
                        Live Classes
                      </Link>
                    </li> */}
                    <li className="nav-item dropdown">
                      <Link className="nav-link dropdown-toggle" href="#" underline="none" data-toggle="dropdown">
                        Teacher Solutions
                      </Link>
                    </li>
                  </ul>
                  <div className="apply-btn">
                    <Link href="#" underline="none">
                      <AssignmentOutlinedIcon />
                      Become a partner
                    </Link>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </section>

        {/* Sticky Menu */}
        <SampleStickyMenu />

        {/* Mobile Menu */}
        <SampleMobileMenu />
      </Styles>
    );
  }
}

export default SampleHeader;
