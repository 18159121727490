import styled from "styled-components";
import { colors } from "../common/element/elements.js";

export const Styles = styled.div`
  @font-face {
    src: url("/assets/fonts/FreeSans.ttf") format("truetype"); /* Safari, Android, iOS */
  }
  @font-face {
    src: url("/assets/fonts/FreeSansBold.ttf") format("truetype"); /* Safari, Android, iOS */
  }

  html {
    body {
    }
  }
  .header {
    background-image: linear-gradient(to bottom, #0d63d9 0%, #0d63d9 60%, #004fc1 60%, #004fc1 100%);
    padding: 10px 15px 20px 15px;
    min-height: 300px;
    position: relative;
    @media (max-width: 600px) {
      background: #0d63d9;
    }
  }
  .fixed-plus {
    position: absolute;
    left: 30%;
    width: 11vw;
    top: 4vw;
  }

  .header-content {
    padding: 10px;
    color: white;
    align-items: end;
    .tp-9 {
      padding-top: 7vw;
    }

    h2 {
      text-transform: uppercase;
      font-size: 2.3vw;
      letter-spacing: 1px;
      font-weight: 900;
      @media (max-width: 600px) {
        font-size: 26px;
      }
    }
    ul {
      margin-top: 1.5vw;
      margin-bottom: 2vw;
      text-transform: uppercase;
      li {
        font-size: 1vw;
        @media (max-width: 600px) {
          font-size: 14px;
        }
      }
    }
    .header-bottom-first {
      padding: 5vw 0 0.3vw 0;
      text-align: right;
      text-transform: uppercase;
      .orange-text {
        color: #ff7261;
      }
      h3 {
        padding: 10px 20px;
        margin-bottom: 2vw;
        font-size: 2.2vw;
        font-weight: 900;
        letter-spacing: 2px;
        @media (max-width: 600px) {
          font-size: 25px;
        }
      }
      h5 {
        font-size: 1.3vw;
        letter-spacing: 3px;
        text-transform: capitalize;
        font-weight: 300;
        @media (max-width: 600px) {
          font-size: 14px;
        }
      }
    }
    .robo-with_students {
      background-image: url(/assets/images/us/kids-working-with-teacher.webp);
      padding-top: 120%;
      background-size: cover;
      background-position: bottom;
      @media (max-width: 600px) {
        display: none;
      }
    }
    .robo-only {
      background-image: url(/assets/images/us/robots-right.webp);
      padding-top: 70%;
      background-size: cover;
      background-position: 60%;
      position: relative;

      #robo-overlay {
        height: 12vw;
        background: #000;
        opacity: 0.4;
        z-index: 130;
      }
    }
  }

  .why-robots {
    border: 2px solid white;
    display: flex;
    padding: 2vw 0.5vw 2vw 1.5vw;
    margin-top: 3vw;
    align-items: center;
    cursor: pointer;
    text-decoration: none;

    a {
      text-decoration: none;
    }

    span {
      color: white;
      font-size: 1.8vw;
      text-transform: uppercase;
      font-weight: 900;
      letter-spacing: 1px;
      @media (max-width: 600px) {
        font-size: 24px;
      }
    }

    img {
      max-width: 60px;
      width: 5vw;
      margin-right: 5px;
      @media (max-width: 600px) {
        width: 60px;
      }
    }
  }

  .logo {
    float: left;
    width: 50%;
    img {
      max-width: 250px;
      width: 33%;
      @media (max-width: 600px) {
        width: 125px;
      }
    }
  }
  .right-side {
    float: right;
    width: 50%;
    text-align: right;
    padding-right: 15px;
  }
  .orange-bg {
    ${colors.gredient_orange_bg}
    padding: 0px 15px;
    svg {
      color: #fff;
    }
    a {
      color: #fff;
    }
    &:hover {
      color: #000;
      svg {
        color: #000;
      }
      a {
        color: #000;
      }
    }
  }
  .menu img {
    max-width: 50px;
    padding: 14px;
    margin-left: 30px;
    cursor: pointer;
  }
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  .experiential-learning {
    align-items: center;
    padding: 6vw 0px;
    .learning-text {
      padding: 3vw;
      @media (min-width: 1200px) {
        padding: 20px;
      }
      h2 {
        font-size: 3.4vw;
        font-weight: 800;
        color: #0b0653;
        margin-bottom: 3vw;
        @media (min-width: 1200px) {
          font-size: 45px;
        }
      }

      p {
        color: #91abf4;
        font-size: 16px;
        margin-bottom: 2.5vw;
      }
      button {
        color: white;
        background: #4b51ed;
        float: right;
        text-transform: capitalize;
        font-weight: 300;
      }
    }
    .check-in-block {
      margin-top: 5vw;
      background: #4b51ee;
      border-radius: 5px 0 0 5px;
      padding: 10px 3vw;
      color: white;
      @media (max-width: 600px) {
        margin-top: 42px;
        text-align: center;
      }
    }
  }

  .checkin-form {
    padding: 10px 0;
    justify-content: start;
    align-items: center;
    display: flex;
    @media (max-width: 600px) {
      justify-content: center;
    }

    button {
      line-height: 35px;
      min-width: 90px;
    }
  }

  .email-input {
    color: white;
    background: white;
    border-radius: 5px;
    margin-right: 15px;

    label.MuiInputLabel-shrink {
      color: white;
      background: #4b51ee;
      padding: 1px 10px;
      border-radius: 5px;
    }
  }

  .robotics-expert {
    h3 {
      padding: 0 15vw;
      font-size: 2.3vw;
      font-weight: 900;
      text-transform: uppercase;
      color: #1a1a1a;
      line-height: 3.7vw;
      @media (min-width: 1200px) {
        font-size: 28px;
        line-height: 34px;
        padding: 0 170px;
      }
    }
    img {
      padding: 2vw 3vw;
      @media (min-width: 1200px) {
        padding: 0 30px;
      }
    }
    .img-caption {
      text-align: center;
      font-size: 1.9vw;
      font-weight: 600;
      @media (min-width: 1200px) {
        font-size: 19px;
      }
      @media (max-width: 600px) {
        font-size: 19px;
      }
    }
  }
  .robotics-coach {
    padding: 6vw 0;
    display: flex;
    align-items: center;

    h4 {
      font-weight: 800;
      font-size: 2vw;
      @media (min-width: 1200px) {
        font-size: 28px;
      }
      @media (max-width: 600px) {
        font-size: 28px;
      }
    }

    p {
      font-size: 1.5vw;
      margin-bottom: 1vw;
      margin-top: 1vw;
      @media (min-width: 1200px) {
        font-size: 16px;
      }
      @media (max-width: 600px) {
        font-size: 16px;
      }
      a {
        color: #ff8ebb;
        text-decoration: none;
        cursor: pointer;
        font-size: 1.5vw;
        @media (min-width: 1200px) {
          font-size: 16px;
        }
        @media (max-width: 600px) {
          font-size: 16px;
        }
      }
    }

    .pink-bg {
      background-color: #ff4098;
      border: none;
      box-shadow: none;
      border-radius: 20px;
    }
  }
  .btn_white {
    width: 200px;
    ${colors.gredient_orange_bg}
    font-size: 24px;
    letter-spacing: 2px;
    line-height: 42px;
    color: #ffffff;
    font-weight: 600;
    border: 0;
    cursor: pointer;
    &:hover {
      background: white;
      color: rgb(234 135 51);
    }
  }
  .btn_primary {
    background-color: #ed833b;
    padding: 10px 50px;
    color: #fff;

    border: 0;
    border-radius: 6px;
    cursor: pointer;
  }
  .h-100 {
    height: 100% !important;
  }

  .d_flex {
    display: flex;
    .col_two {
      width: 50%;
    }
    .col_four {
      width: 25%;
    }
    .col_three {
      width: 33.333%;
    }
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
  }
  p {
    margin: 0;
  }

  .main_title {
    font-size: 25px;
    letter-spacing: 2px;

    color: #000000;
    font-weight: 400;

    text-align: center;
    margin-top: 30px;
    span {
      font-size: 40px;
    }
    span.orange_text {
      color: #f97654;
    }
    .blue {
      color: #35bef4;
    }
  }
  .learning_app {
    margin-top: 30px;
    margin-bottom: 100px;
  }
  section {
    .sec_title {
      font-size: 22px;
      letter-spacing: 2px;
      line-height: 50px;
      color: #f97654;
      font-weight: 400;

      text-align: center;
      margin-bottom: 100px;
    }
    .bg_card {
      border-radius: 10px;
      .col_two.left {
        display: flex;
        align-items: flex-end;
        justify-content: center;
        flex-direction: column;
        width: 60%;
        position: relative;
        &::before {
          content: "";
          position: absolute;
          background-image: url("/assets/images/home/learning_layer.webp");
          background-repeat: no-repeat;
          background-size: contain;
          height: 500px;
          width: 65%;
          left: -30px;
        }
        h4 {
          font-size: 31px;
          letter-spacing: 2px;
          line-height: 22px;
          color: #f19ba4;
          font-weight: 600;
        }
        p {
          font-size: 13px;
          letter-spacing: 1px;
          line-height: 20px;
          color: #ffffff;
          font-weight: 250;
          max-width: 35%;
          margin-top: 20px;
          text-align: right;
        }
      }
    }
    .blue {
      background-color: #5076c6;
    }
    .col_two.right {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-end;
      padding-right: 40px;
      width: 40%;
    }
    .board_card {
      width: 395px;
      min-height: 180px;
      border-radius: 10px;
      background-color: #ffffff;
      border: 2px solid #5076c6;
      padding: 10px;
      .left_col {
        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-direction: column;
        text-align: left;
      }
      .class_text {
        font-size: 17px;
        letter-spacing: 1px;

        color: #000000;
        font-weight: 400;
      }
      h6 {
        font-size: 17px;
        letter-spacing: 1px;

        color: #000000;
        font-weight: 600;

        margin-bottom: 10px;
      }
      .desc_text {
        font-size: 11px;
        letter-spacing: 1px;

        color: #000000;
        font-weight: 400;
      }
    }
    .top {
      margin-top: -50px;
    }
    .bottom {
      margin-bottom: -50px;
    }

    .yellow {
      background-color: #fac832;
    }
  }
  section.robotic {
    margin-top: 280px;
    .bg_card {
      padding: 15px;
    }

    .robotic_card {
      border-radius: 10px;
      background-color: #ffffff;
      border: 2px solid #fa9e2d;
      padding: 15px;
      margin-top: -90px;
      h5 {
        font-size: 24px;
        color: #fa9e2d;
        font-weight: 800;
        margin-bottom: 20px;
      }
      ul {
        text-indent: -30px;
        margin-left: 25px;
        li {
          list-style: none;
          font-size: 16px;
          svg {
            margin-right: 5px;
          }
        }
      }
      p {
        font-size: 18px;
        letter-spacing: 1px;
        line-height: 29px;
        color: #000000;
        font-weight: 400;
      }
    }
    h2 {
      font-size: 25px;
      letter-spacing: 2px;
      line-height: 43px;
      color: #870bae;
      font-weight: 600;
      margin-top: 20px;
      width: 100%;
      text-align: center;
    }

    .course_card {
      border-radius: 11px;
      background-color: #ffffff;
      border: 2px solid #fa9e2d;

      .course_layer {
        position: relative;
        &:before {
          content: "";
          display: block;
          background-image: url(/assets/images/home/course_layer.webp);
          background-position: center;
          background-size: contain;
          height: 211px;
          background-repeat: no-repeat;
          @media (max-width: 767px) {
            width: 100%;
          }
        }
      }
      .right_col {
        padding: 25px 0;
        h3 {
          font-size: 22px;
          line-height: 32px;
          color: #fa9e2d;
          font-weight: 800;
          text-align: center;
          margin-top: 3px;
          padding: 0 10px;
        }
      }
    }
    .right_four {
      display: flex;
      align-items: flex-end;
      margin-bottom: -90px;
      margin-left: 30px;
      flex: 1;
      position: relative;
      @media (max-width: 767px) {
        margin-bottom: -30px;
      }
      &::before {
        content: " ";
        position: absolute;
        right: -7px;
        background-image: url("/assets/images/home/robotic_layer.webp");
        background-repeat: no-repeat;
        height: 567px;
        width: 658px;
        bottom: 75px;
      }
    }
  }
  section.teacher {
    margin-top: 140px;
    position: relative;
    &::before {
      content: " ";
      position: absolute;
      left: 0;
      background-image: url("/assets/images/home/teacher_layer.webp");
      background-size: contain;
      background-repeat: no-repeat;
      height: 600px;
      width: 40%;
      top: -130px;
    }
    .green {
      background-color: #2fa8b0;
    }
    h2 {
      font-size: 25px;
      letter-spacing: 2px;
      line-height: 43px;
      color: #ffffff;
      font-weight: 600;
      text-align: center;
      padding-top: 20px;
      width: 60%;
      margin: 0 auto 0 35%;
    }
    .col_two.col_right {
      display: flex;
      justify-content: flex-end;
    }
    .tech_card {
      width: 329px;
      height: 240px;
      border-radius: 11px;
      background-color: #ffffff;
      border: 2px solid #2fa8b0;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      padding: 15px;
      margin-top: 290px;
      position: relative;
      left: 200px;
      display: none;
      h5 {
        font-size: 21px;
        letter-spacing: 2px;
        line-height: 32px;
        color: #000000;
        font-weight: 600;

        margin-bottom: 15px;
      }
      p {
        font-size: 16px;
        letter-spacing: 1px;
        line-height: 30px;
        color: #000000;
        font-weight: 400;
      }
    }
    .teacher_card {
      min-width: 481px;
      width: 50%;
      border-radius: 11px;
      background-color: #ffffff;
      border: 2px solid #2fa8b0;
      padding: 15px;
      margin-right: -20px;
      margin-top: 20px;
      margin-bottom: -20px;

      h5 {
        font-size: 21px;
        letter-spacing: 2px;
        line-height: 32px;
        color: #2fa8b0;
        font-weight: 800;

        text-align: center;
        margin-bottom: 15px;
      }
      ul {
        text-indent: -30px;
        margin-left: 25px;
        li {
          list-style: none;
          font-size: 17px;
          svg {
            margin-right: 5px;
          }
        }
      }
      p {
        font-size: 16px;
        letter-spacing: 1px;
        line-height: 30px;
        color: #000000;
        font-weight: 400;
      }
    }
  }
  .trial {
    margin-top: 190px;
    margin-bottom: 150px;
    .sky_blue {
      background-image: url("/assets/images/home/background_blue.webp");
      padding: 45px;
      background-repeat: no-repeat;
      background-size: cover;
    }
    .left_col {
      display: flex;
      align-items: center;
    }
    .content_box {
      h5 {
        font-size: 22px;
        letter-spacing: 2px;
        line-height: 50px;
        color: #ffffff;
        font-weight: 600;
      }
      h2 {
        font-size: 38px;
        letter-spacing: 3px;
        line-height: 50px;
        color: #ffffff;
        font-weight: 600;
      }
      p {
        font-size: 13px;
        letter-spacing: 1px;
        line-height: 50px;
        color: #ffffff;
        font-weight: 400;
      }
    }
    .right_col {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      @media (max-width: 767px) {
        justify-content: flex-start;
      }
    }
    .r_content_box {
      @media (max-width: 767px) {
        width: 70%;
      }
      h5 {
        font-size: 28px;
        letter-spacing: 2px;
        line-height: 50px;
        color: #ffffff;
        font-weight: 600;
      }
      p {
        font-size: 13px;
        letter-spacing: 1px;
        line-height: 21px;
        color: #ffffff;
        font-weight: 400;
      }
    }
    .img_col {
      position: relative;
      &::before {
        content: "";
        position: absolute;
        left: 0;
        background-image: url("/assets/images/home/trial_layer.webp");
        background-size: contain;
        background-repeat: no-repeat;
        height: 500px;
        width: 70%;
        top: -170px;
      }
    }
  }

  @media (min-width: 1200px) {
    .main_container {
      width: 1140px;
      margin: 0 auto;
    }
  }

  .title {
    font-size: 35px;
    line-height: 67px;
    color: #f97654;
    font-weight: 600;
    text-align: center;
    margin-bottom: 10px;
    text-transform: uppercase;
  }

  .about-us {
    margin-bottom: 45px;
    .about-content {
      margin-top: 40px;
      h4.about-title {
        font-size: 36px;
        margin-bottom: 30px;
      }
      .about-para {
        font-size: 22px;
        word-spacing: 2px;
        letter-spacing: 1px;
      }
    }
  }
  .experiential_learning {
    padding: 60px 0;
    .right_col {
      display: flex;
      align-items: center;
      justify-content: center;
      .content {
        h5 {
          font-size: 35px;
          line-height: 67px;
          color: #000000;
          font-weight: 600;

          margin-bottom: 25px;
        }
        p {
          color: #9c9996;
        }
        ul {
          display: flex;
          justify-content: space-around;
          margin-top: 20px;
          li {
            span {
              font-size: 30px;
            }
            text-align: center;
            p {
              font-size: 12px;
            }
            .first {
              color: #ff6f6f;
            }
            .middle {
              color: #84479c;
            }
            .last {
              color: #2c97ea;
            }
          }
        }
      }
    }
  }
  .button_box {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
  }

  .climb {
    margin-top: 100px;
    span {
      color: ${colors.orange};
      font-size: 36px;
      @media (max-width: 767px) {
        font-size: 25px;
      }
    }
    h2 {
      font-size: 33px;
      line-height: 47px;
      text-align: center;
      font-weight: 600;
      margin-top: 30px;
      margin-bottom: 36px;
      @media (max-width: 767px) {
        font-size: 32px;
        color: black !important;
      }
    }
    .bg_card {
      padding: 15px;
      position: relative;
      height: auto;
      display: flex;
      justify-content: center;
      .bg-climb_img {
        .desktop_climb {
          display: block;
          @media (max-width: 767px) {
            display: none;
          }
        }
        .mobile_climb {
          display: none;
          @media (max-width: 767px) {
            display: block;
            margin-bottom: 20px;
          }
        }
      }
      .climb_join {
        position: absolute;
        bottom: 65px;
        right: 30px;
        @media (max-width: 767px) {
          bottom: 20px;
          right: auto;
        }
        .join_btn {
          background: white;
          color: #eb6808;
          border: solid 1px #eb6808;
          padding: 5px 30px;
          border-radius: 5px;
          text-transform: capitalize;
          font-size: 24px;
          font-weight: 600;
          min-width: 250px;

          &:hover {
            background: #eb6808;
            color: white;
            border: solid 1px white;
          }
          @media (max-width: 767px) {
            font-size: 16px;
            right: auto;
            bottom: 33px;
          }
        }
      }

      .first {
        position: absolute;
        bottom: 16%;
        left: 33%;
        h5 {
          color: #fff;
          font-size: 30px;
        }
        p {
          color: #fff;
        }
      }
    }
  }
  .orange {
    background-color: #ffa544;
  }

  .have_fun {
    margin-top: 100px;
    margin-bottom: 50px;
    .bg_card {
      background-image: linear-gradient(90deg, #0072ca 0.02%, #0072ca 30.84%, #30d5fc 88.7%, #30d5fc 100.02%);
      padding: 20px;
      height: 100%;
      .head {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin-bottom: 50px;
        h2 {
          color: #ffff06;
          font-size: 4vw;
          font-weight: 900;
          line-height: 4vw;
          text-transform: uppercase;
          @media (min-width: 1200px) {
            font-size: 45px;
          }
          @media (max-width: 600px) {
            font-size: 30px;
          }
        }
        p {
          color: #ffff06;
          font-size: 2.2vw;
          line-height: 2.2vw;
          font-weight: 900;
          text-transform: uppercase;
          @media (min-width: 1200px) {
            font-size: 24px;
          }
          @media (max-width: 600px) {
            font-size: 16px;
            line-height: 20px;
          }

          .line {
            display: flex;
            height: 0.4vw;
            span {
              width: 33.333%;
            }
            .start {
              background-color: #fbcd15;
            }
            .middle {
              background-color: #47c2f4;
            }
            .end {
              background-color: #fb267c;
            }
          }
        }
      }
      .d_flex {
        position: relative;
        &:after {
          content: "";
          position: absolute;
          width: 70%;
          height: 1px;
          background-color: #d2c3ac;
          top: 33%;
          left: 105px;
          display: none;
        }
      }
      .col_three {
        position: relative;
        text-align: center;
        &:last-child {
          h5 {
            &:after {
              top: 25%;
            }
          }
        }
        img {
          position: relative;
          z-index: 1;
          width: 15vw;
          @media (max-width: 600px) {
            width: 160px;
          }
        }

        .circle_title {
          color: #ffffff;
          font-size: 2vw;
          font-weight: 900;
          line-height: 40px;
          text-transform: uppercase;
          margin-bottom: 15px;
          @media (min-width: 1200px) {
            font-size: 24px;
          }
          @media (max-width: 600px) {
            font-size: 24px;
          }
        }
        h5 {
          color: #ffffff;
          font-size: 1.7vw;
          font-weight: 900;
          line-height: 2vw;
          margin-bottom: 0;
          padding-top: 1vw;
          position: relative;
          @media (min-width: 1200px) {
            font-size: 19px;
            width: 200px;
            margin: 0 auto;
          }
          @media (max-width: 600px) {
            line-height: 22px;
            font-size: 21px;
            margin-bottom: 3px;
          }
        }
        p {
          color: #ffffff;
          @media (max-width: 600px) {
            margin-bottom: 20px;
          }
        }
      }
    }
  }

  .school_solution {
    padding: 100px 0;
    .bg_card {
      background-color: #301d4a;
      height: 600px;
      padding: 0;
      display: flex;
      justify-content: center;

      .img_bg {
        background-image: url("/assets/images/robotics/school_solutions.webp");
        background-repeat: no-repeat;
        height: 100%;
        width: 100%;
        background-position: center;
        background-size: contain;
      }
    }
  }

  .robotics_bottom {
    background-image: url(/assets/images/home/Bottom_robotics.webp);
    background-repeat: no-repeat;
    background-size: contain;
    height: 0;
    padding-top: 66.64%;
    margin-left: 50px;
    .d_flex {
      position: absolute;
      margin-top: -50%;
      margin-left: 20px;
      color: white;
      h2 {
        margin-top: 50px;
        margin-bottom: 50px;
        @media (max-width: 767px) {
          margin: auto;
        }
      }
      h5 {
        color: #61ff61;
        margin: 40px 0;
        @media (max-width: 767px) {
          font-size: 14px;
          margin: 20px 0;
        }
      }
      .join_btn {
        background: white;
        color: #eb6808;
        border: solid 1px #eb6808;
        padding: 5px 30px;
        border-radius: 5px;
        text-transform: capitalize;
        font-size: 24px;
        font-weight: 600;
        min-width: 250px;

        &:hover {
          background: #eb6808;
          color: white;
          border: solid 1px white;
        }
        @media (max-width: 767px) {
          font-size: 16px;
        }
      }
      @media (min-width: 1240px) {
        margin-top: -600px;
      }
      @media (max-width: 767px) {
        width: 50%;
        h2 {
          font-size: 21px;
        }
      }
    }
    @media (max-width: 767px) {
      margin-left: 0;
    }
  }

  @media (max-width: 767px) {
    .main_container {
      padding: 0 10px;
    }
    .d_flex {
      flex-wrap: wrap;
      .col_two {
        width: 100%;
      }
    }
    section .bg_card .col_two.left::before {
      background-size: contain;
      height: 320px;
      width: 320px;
      left: -15px;

      top: -78px;
    }
    section .bg_card .col_two.left h4 {
      font-size: 33px;
      line-height: 35px;
      width: 50%;
    }
    section .bg_card .col_two.left p {
      max-width: 70%;
      font-size: 16px;
      text-align: left;
      padding-top: 90px;
    }
    section .col_two.right {
      padding-right: 0;
      width: 100%;
      justify-content: center;
      align-items: center;
    }
    section .bg_card .col_two.left {
      width: 100%;
    }
    section.robotic .right_four::before {
      height: 313px;
      width: 362px;
      bottom: 133px;
      background-size: contain;
      display: none;
    }
    .trial .img_col::before {
      width: 50%;
      top: -250px;
      left: 60%;
    }
    section.teacher .teacher_card {
      width: 100%;
      margin-top: 35px;
      min-width: 100%;
    }
    section.teacher .tech_card {
      left: -16px;
      width: 100%;
    }
    section.teacher::before {
      width: 50%;
      top: -20px;
      max-height: 230px;
    }
    section.teacher h2 {
      line-height: 34px;
      width: 67%;
      margin: 0 auto 0 35%;
    }
    .main_title {
      font-size: 16px;
      span {
        font-size: 20px;
      }
    }
    .learning_app {
      margin-top: 50px;
    }
    section .bg_card {
      padding: 25px;
      height: 100%;
    }
    section .board_card {
      width: 100%;
      margin-bottom: 30px;
      text-align: center;
    }
    section .board_card.top {
      margin-top: 20px;
    }
    section .board_card {
      position: relative;
      .d_flex .col_two {
        display: flex;
        justify-content: flex-end;
      }
    }
    section .board_card img {
      margin-left: 0;
    }
    section.robotic {
      margin-top: 125px;
    }
    section.robotic .robotic_card {
      margin-left: -7px;
    }
    .d_flex .col_three {
      width: 100%;
    }
    .trial {
      margin-top: 25px;
    }
    .trial .content_box h2 {
      font-size: 22px;
      letter-spacing: 3px;
      line-height: 25px;
    }
    .trial .r_content_box h5 {
      font-size: 22px;
      letter-spacing: 2px;
      line-height: 30px;
      color: #ffffff;
      font-weight: 600;

      margin-top: 20px;
    }
    section.teacher {
      margin-top: 70px;
    }
    .img_left {
      width: 100%;
    }
    .title {
      font-size: 25px;
      line-height: 30px;
      margin-top: 40px;
    }
    .content {
      padding: 15px;
    }
    .climb .bg_card {
      padding: 30px 15px;
      position: relative;
    }
    .climb h2 {
      font-size: 25px;
      line-height: 35px;
      color: #ffffff;
      font-weight: 600;
    }
    .climb .bg_card:before {
      content: " ";
      position: absolute;
      right: 0;

      height: 350px;
      width: 389px;
      background-repeat: no-repeat;
      background-size: contain;
      bottom: -95px;
    }
    .have_fun .bg_card .col_three h5:after {
      display: none;
    }
    .have_fun .bg_card .col_three:after {
      display: none;
    }
    .have_fun .bg_card .d_flex:after {
      display: none;
    }
    .school_solution .bg_card {
      height: 400px;
    }
    .have_fun {
      margin-top: 0px;
    }
    .school_solution {
      padding: 30px 0;
    }
    .banner {
      height: calc(100vh - 238px);
      .btn_primary {
        padding: 7px 30px;
      }
    }
  }
  .banner_wrapper {
    .robotics_banner {
      background-image: url("/assets/images/banners/robotics_banner_bg.webp");
      height: 0;
      padding-top: 47.14%;
      background-repeat: no-repeat;
      background-size: contain;
      position: relative;
      &::before {
        content: "";
        position: absolute;
        background-image: url(/assets/images/banners/banner_light.webp);
        background-repeat: no-repeat;
        background-size: contain;
        padding-top: 56.14%;
        width: 80%;
        top: 0;
        left: 10%;
      }
      .robotics_board {
        position: absolute;
        top: 10%;
        background-image: url(/assets/images/banners/blackboard.webp);
        width: 40%;
        height: 45%;
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        left: 35%;
        .board_content {
          padding: 6% 10%;
          color: white;
          text-align: center;
          text-transform: uppercase;
          h2,
          h3 {
            font-size: 2.25vw;
          }
          .board-points {
            text-align: left;
            padding: 0.5vw;
            li {
              font-size: 1vw;
              margin: 0.5vw 0;
              svg {
                font-size: 1.2vw;
                vertical-align: sub;
                margin-right: 1vw;
              }
            }
          }
          .board_button {
            text-align: center;
            .btn_connect {
              background-color: #ed833b;
              padding: 0.4vw 2vw;
              color: #fff;
              border: 0;
              border-radius: 1vw;
              cursor: pointer;
              line-height: 1vw;
              font-size: 1vw;
              margin: 0.5vw;
              text-transform: uppercase;
              &:hover {
                color: #ed833b;
                background-color: #fff;
              }
            }
          }
        }
      }
      &::after {
        content: "";
        position: absolute;
        background-image: url(/assets/images/banners/banner_characters.webp);
        background-repeat: no-repeat;
        background-size: contain;
        padding-top: 47.14%;
        bottom: 0;
        width: 100%;
      }
    }
    .board_button {
      position: absolute;
      margin-top: -20%;
      left: 45%;
      .btn_connect {
        background-color: yellow;
        padding: 1vw 3vw;
        color: black;
        border: 0;
        cursor: pointer;
        line-height: 1vw;
        font-size: 1.4vw;
        border-radius: 0.3vw;
        font-weight: 600;
        margin: 1vw;
        &:hover {
          color: yellow;
          background-color: black;
        }
      }
    }
  }
  .button-area {
    margin-top: 130px;
    @media (max-width: 767px) {
      margin-top: 50px;
    }
    .join-button {
      ${colors.gredient_orange_bg}
      box-sizing: content-box;
      display: block;
      margin: auto;
      min-width: 250px;
      height: 45px;
      border-radius: 5px;
      font-size: 19px;
      color: #ffffff;
      text-align: center;
      font-weight: 500;
      position: relative;
      border: none;
      box-shadow: 0 12px 25px rgb(0 0 0 / 20%);
      &:hover {
        color: rgb(234 135 51);
        background: white;
      }
    }
  }
  .bar-login {
    right: 10vw;
    position: absolute;
    top: 20px;
    @media (max-width: 1200px) {
      right: 15vw;
    }
    @media (max-width: 600px) {
      top: 60px;
    }
    a {
      color: white;
      cursor: pointer;
      font-size: 16px;
      svg {
        margin-right: 5px;
      }
    }
  }
  .header-courcelist {
    background: url(/assets/images/usImages/innerbg.jpg) repeat-x; padding: 100px 0; margin-top: 75px;
    padding: 10px 15px 20px 15px;
    min-height: 300px;
    position: relative;
    @media (max-width: 600px) {
      background: #0d63d9;
    }
  }
  body { font-family: inherit; margin:0; padding:0; color:#666}

  .container { width: 80%; margin: 0 auto;}
.object { margin: 0; padding: 0; z-index: 9; position: relative; margin-top: 50px;}

.aboutRobooookido { margin: 50px 0 0 0; width: 100%;} 
.aboutRobooookido object{ font-family: 'Arial'; font-size: 20px; color: #ffa7d6; font-weight: 600;}
.aboutRobooookido h1 { font-family: 'Arial'; font-size: 50px; font-weight: 700; line-height: 1.5em; margin: 0; border-bottom: solid 1px #ddd; padding: 25px 0 65px 0; color: #111;}
.aboutRobooookido a{ font-family: 'Arial'; background: #0086ff; display: inline-block; padding: 15px 20px; margin: 35px 0; border-radius: 30px; font-size: 18px; color: #fff; text-decoration: none; }
.aboutRobooookido a:hover{background: #333; }
.aboutRobooookido  ul { border-bottom: solid 1px #ddd; padding: 50px 0; display:flex; flex-wrap:wrap; justify-content:space-between; align-items:top;}
.aboutRobooookido  ul li {width: 30%; position: relative;}
.aboutRobooookido  ul li i{ position: absolute; top: 0; background: #a3a4f8; color: #fff; font-size: 20px; width: 60px; line-height: 60px; border-radius: 60px; text-align: center;  }
.aboutRobooookido  ul li h2{font-family: 'Arial'; font-size: 25px; color: #333; padding: 0 0 10px 90px;}
.aboutRobooookido  ul li p{font-family: 'Arial'; line-height: 1.5em; color: #666; padding-left:90px; margin: 0;}
.aboutRobooookido .textBox { display:flex; flex-wrap:wrap; justify-content:space-between; align-items:center; margin: 30px 0;}
.aboutRobooookido .textBox .aTag { width: 20%;}
.aboutRobooookido .textBox .aTx { width: 70%; font-size: 16px; line-height: 1.5em;}
.aboutRobooookido .iBx { overflow: hidden;}
.aboutRobooookido .iBx img {width: 100%;}

.menubar i { color: #000;}
.menubar {background: #0086ff;}

.whyChoose { background: url(/assets/images/usImages//bg.jpg) repeat-x; padding: 100px 0; margin-top: 75px;}
.whyChoose .container {display:flex; flex-wrap:wrap; justify-content:space-between;}
.whyChoose .wcLeft{ width: 30%; overflow: hidden;}
.whyChoose .wcLeft img{ width: 100%;}
.whyChoose .wcRight { width: 60%;}
.whyChoose .wcRight strong {color:#0659ba;}
.whyChoose .wcRight h2 { font-size: 50px; color: #333; padding: 20px 0; font-weight: 800;}
.whyChoose .wcRight p{ font-size: 25px; color: #333; padding: 0; font-weight: 400; line-height: 1.5em; margin: 5px 0 20px 0;}
.whyChoose .wcRight ul{ border-bottom: solid 2px #333; padding-bottom: 40px;}
.whyChoose .wcRight ul li{position: relative; padding: 12px 0; color: #222; font-size: 25px; font-weight: 600; padding-left: 50px;}
.whyChoose .wcRight ul li:before{ content: "\f061"; font-family: 'FontAwesome'; color: #fff; font-size: 10px; position: absolute; left: 0; top: 10px; background: #a3a4f8; border-radius: 100%; width: 35px; line-height: 35px; text-align: center;}
.whyChoose .wcRight b{ display: block; padding: 40px 0 0 0; font-size: 20px; color: #333; font-weight: 500;}
.whyChoose .wcRight button { cursor: pointer; font-family: 'Arial'; background: #0086ff; display: inline-block; padding: 15px 40px; margin: 20px 0; border-radius: 30px; border: 0; font-size: 18px; color: #fff; text-decoration: none; }
.whyChoose .wcRight button:hover {background: #333; }

.popularCourses { padding: 50px 0;}
.popularCourses h2 { font-size: 50px; color: #333; padding: 10px 0; font-weight: 700;}
.popularCourses p{ font-size: 24px; color: #333; padding: 0; font-weight: 400; width: 60%; display: inline-block;}
.popularCourses .textBox { display:flex; flex-wrap:wrap; justify-content:space-between; align-items:center; margin: 0;}
.popularCourses .textBox .aTag { width: 21%;}
.popularCourses .textBox .aTag a{ cursor: pointer; font-family: 'Arial'; background: #0086ff; display: inline-block; padding: 15px 20px; margin: 35px 0; border-radius: 30px; border: 0; font-size: 18px; color: #fff; text-decoration: none; vertical-align: top;}
.popularCourses .textBox .aTag a:hover{background: #333; }
.popularCourses .textBox .aTx { width: 70%; font-size: 20px; line-height: 1.5em;}

.coursesBlk {display:flex; flex-wrap:wrap; justify-content:space-between;}
.coursesBlk .cBox{width: 32%; overflow: hidden; border: solid 1px #ddd; border-radius: 25px; padding: 20px; margin: 15px 0; position: relative;}
.coursesBlk .cBox img{ width: 100%;}
.coursesBlk .cBox span{ position: absolute; top: 35px; right: 35px; z-index: 9; background: #a1a2f7; border-radius: 5px; color: #fff; text-align: center; font-size: 15	px; padding: 10px 20px; font-weight: 500; font-family: 'Roboto';}
.coursesBlk .cBox h2 { font-size: 25px; color: #333; padding: 20px 0 0 0; font-weight: 700;}
.coursesBlk .cBox ul {padding: 10px 0;}
.coursesBlk .cBox ul li{ position: relative; font-size: 14px; color: #666; display: inline-block; font-weight: 500; padding-left: 15px; margin-right: 10px;}
.coursesBlk .cBox ul li:before{ content: "\f111"; font-family: 'FontAwesome'; color: #666; font-size: 8px; position: absolute; left: 0; top: 3px; }
.coursesBlk .cBox p {font-size: 16px; font-weight: 400;}
.coursesBlk .cBox p i{color: #ffc107;}

.ourPrograms { padding: 20px 0;}
.ourPrograms h2{ font-family: 'Arial'; font-size: 20px; color: #ffa7d6; font-weight: 600; text-align: center;}
.ourPrograms h3 { font-family: 'Arial'; font-size: 50px; font-weight: 700; margin: 0; padding: 20px 0; color: #111; text-align: center;}
.ourPrograms h4 {font-size: 20px; font-weight: 500; line-height: 1.5em; text-align: center;}
.ourPrograms .opSection {display:flex; flex-wrap:wrap; justify-content:space-between; margin: 40px 0;}
.ourPrograms .opSection .cBox{ width: 27%; text-align: left; position: relative; padding-left: 80px; margin: 25px 0;}
.ourPrograms .opSection .cBox i{ background: #a3a4f8; color: #fff; width: 50px; line-height: 50px; text-align: center; font-size: 20px; position: absolute; left: 0; top: -10px; border-radius: 50px;}
.ourPrograms .opSection .cBox object{font-size: 25px; font-weight: 700; color: #333; }
.ourPrograms .opSection .cBox p{font-size: 16px; line-height: 1.5em;}
.ourPrograms .opSection .cBox a {cursor: pointer; background: none; font-family: 'Arial'; border: 0; font-weight: 500; font-size: 16px; color: #0086ff; text-decoration: none; position: relative; border-bottom: solid 2px #2196f3; padding-bottom: 15px;}
.ourPrograms .opSection .cBox a:after { content: "\f178"; font-family: 'FontAwesome'; color: #2196f3; font-size: 16px; position: absolute; right: -30px; }
.ourPrograms .opSection .cBox a:hover {color: #333; } 

.learningMethods { background: url(/assets/images/usImages/bg1.jpg) repeat-x; padding: 100px 0; }
.learningMethods .container {display:flex; flex-wrap:wrap; justify-content:space-between; align-items: center;}
.learningMethods .lmLeft{ width: 50%;}

.learningMethods .lmLeft h2 { color: #fff; text-transform: uppercase; font-size: 18px;}
.learningMethods .lmLeft h3 { font-size: 58px; color: #fff; padding: 20px 0; font-weight: 700;}
.learningMethods .lmLeft p{ font-size: 20px; color: #fff; padding: 0; font-weight: 500; line-height: 1.5em;}
.learningMethods .lmLeft ul{ display:flex; flex-wrap:wrap; justify-content:space-between; align-items: top;}
.learningMethods .lmLeft ul li{position: relative; padding: 15px 0; color: #fff; font-size: 15px; font-weight: 600; padding-left: 45px; width: 45%;}
.learningMethods .lmLeft ul li:before{ content: "\f061"; font-family: 'FontAwesome'; color: #a2a3ff; font-size: 12px; position: absolute; left: 0; top: 8px; background: #fff; width: 35px; line-height: 35px; text-align: center; border-radius: 35px;}
.learningMethods .lmLeft button { cursor: pointer; font-family: 'Arial'; background: #0086ff; display: inline-block; padding: 20px 40px; margin: 35px 0; border-radius: 30px; border: 0; font-size: 18px; color: #fff; text-decoration: none; }
.learningMethods .lmLeft button:hover {background: #333; }
.learningMethods .lmRgt{ width: 50%; overflow: hidden;}
.learningMethods .lmRgt img {width: 100%;}


.testimonialsBox { padding: 50px 0;}
.testimonialsBox h3{ font-family: 'Arial'; font-size: 20px; color: #ffa7d6; font-weight: 600; text-align: center; text-transform: uppercase;}
.testimonialsBox h4 { font-family: 'Arial'; font-size: 50px; font-weight: 700; color: #111; text-align: center; padding: 10px 0;}
.testimonialsBox p{text-align: center; font-size: 20px; font-weight: 400; line-height: 1.5em; padding-bottom: 15px;}
.testimonialsBox .container {display:flex; flex-wrap:wrap; justify-content:space-between; align-items: top;}
.testimonialsBox .cBox{width: 32%; border: solid 1px #ddd; border-radius: 25px; padding: 30px; text-align:left; margin-bottom: 30px;}
.testimonialsBox .cBox span{font-size: 16px; font-weight: 500; color: #fff; display: inline-block; padding: 4px 15px; line-height: 25px; background: #a3a4f8; border-radius: 35px;}
.testimonialsBox .cBox span i{color: #ffc107;}
.testimonialsBox .cBox i.fa.fa-quote-left {color: #a5a4f8; float: right; font-size: 30px;}
.testimonialsBox .cBox h2{padding-top: 25px}
.testimonialsBox .cBox p{font-size: 18px; font-weight: 400; color: #666; text-align: left; margin: 10px 0 10px 0;}
.testimonialsBox .cBox .lmgT{overflow: hidden; width: 50px; height: 50px;}
.testimonialsBox .cBox .lmgT img{ width: 100%;}


.contactUs{ padding: 50px 0;}
.contactUs .container {display:flex; flex-wrap:wrap; justify-content:space-between;}
.contactUs .lmLeft { width: 33%; overflow: hidden;}
.contactUs .lmLeft img {width: 100%;}
.contactUs .csRgt { width: 63%;}
.contactUs .csRgt h2{ font-family: 'Arial'; font-size: 18px; color: #ffa7d6; font-weight: 600;}
.contactUs .csRgt h3{ font-family: 'Arial'; font-size: 48px; font-weight: 800; color: #111; padding: 30px 0; line-height: 1.5em;}
.contactUs .csRgt p{font-size: 20px; font-weight: 500; line-height: 1.5em; padding: 0; margin: 0 0 30px 0;}
.contactUs .csRgt button { cursor: pointer; font-family: 'Arial'; background: #0086ff; display: inline-block; padding: 15px 40px; border-radius: 30px; border: 0; font-size: 18px; color: #fff; text-decoration: none; }
.contactUs .csRgt button:hover {background: #333; }
.contactUs .csRgt span {padding-left: 15px; display: inline-block;}
.contactUs .csRgt span b {color: #a3a4f8;}


.cBottom { padding: 50px 0;}
.cBottom .container {display:flex; flex-wrap:wrap; justify-content:space-between; align-items: center;}
.cBottom .cbBox {width: 30%;}
.cBottom .cbBox h2{color: #333; font-size: 35px; font-weight: 700;}
.cBottom .cbBox p{font-size: 18px; font-weight: 400; line-height: 1.5em;}
.cBottom .cbBox h3 {margin: 10px 0; font-weight: 500; font-size: 18px;}
.cBottom .cbBox h3 i { background: #a3a4f8; color: #fff; width: 40px; line-height: 40px; text-align: center; font-size:18px; border-radius: 50px; margin-right: 10px;}

.howWorks { padding: 20px 0;}
.howWorks h2 { font-size: 50px; color: #333; padding: 10px 0; font-weight: 700;}
.howWorks ul{display:flex; flex-wrap:wrap; justify-content:space-between; vertical-align: top; margin: 25px 0;}
.howWorks ul li{width: 24%;}
.howWorks ul li strong {font-size: 30px; color: #333; padding: 5px 0; font-weight: 700;}
.howWorks ul li p{ width:100%; font-size: 18px; line-height: 1.5em; color: #333; padding: 0; font-weight: 400;}
.howWorks .textBox { display:flex; flex-wrap:wrap; justify-content:space-between; align-items:center; margin: 0;}
.howWorks .textBox .aTag { width: 20%;}
.howWorks .textBox .aTag a{ cursor: pointer; font-family: 'Arial'; background: #0086ff; display: inline-block; padding: 15px 40px; margin: 35px 0; border-radius: 30px; border: 0; font-size: 18px; color: #fff; text-decoration: none; vertical-align: top;}
.howWorks .textBox .aTag a:hover{background: #333; }
.howWorks .textBox .aTx { width: 70%; font-size: 20px; line-height: 1.5em;}


.object { margin: 0; padding: 0; z-index: 9; position: relative; background: #fff;}
.navobject .container {display:flex; flex-wrap:wrap; justify-content: space-between; align-items:center; }
.navobject .menu ul li { display: inline-block;}
.menu ul li a{ display: inline-block; color: #333; font-family: 'Arial'; font-size: 20px; font-weight: 500; padding: 0 15px;}
.menu ul li a:hover{ color: #6083fb; font-weight: 500;}
.menu ul li.current-menu-item a{ color: #8cc63e; font-weight: 500; background: #fff; border-radius: 35px; line-height: 2em;}
.menu ul li:last-child span {display: inline-block; vertical-align: middle; font-size: 12px;}
.menu ul li:last-child span b{display: block; font-size: 20px; font-weight: 600;}
.menu ul li:last-child i{ font-size: 26px; font-weight: 500; color: #fff; display: inline-block; width: 50px; line-height: 50px; background: #5d85fb; border-radius: 50px; text-align: center; margin-right: 10px;}
.menubar {display: none; cursor: pointer; margin-top: 5px; padding: 10px 20px 8px 20px; border-radius: 2px; z-index: 9; position:relative;}
.menubar i { color: #fff; font-size: 25px;}
.menubar.active{color: #e72838;}
.logoBlk{ width: 300px; padding: 15px 0;}
.logoBlk::before{ content: ''; position: absolute; top: -100px; left: 0; z-index: 1; width: 145px; height: 100px;}
.logoBlk img { width: 100%;}
.object.fixobject { position: fixed; top: 0; margin: 0; width: 100%; z-index: 9999; box-shadow: 0 0 5px #000; background: #fff; -webkit-animation:fadeInDown 0.4s; animation:fadeInDown 0.4s;}

.innerBanner { position: relative; max-height: 600px; overflow: hidden;}
.innerBanner img { width: 100%;}
.innerBanner object{ position: absolute; bottom: 0; top:0; left:0; right: 0; width: 600px; height: 125px; margin: auto; font-size: 50px; color: #fff; text-align: center; font-weight: 800;}
.innerBanner object strong { display: block; padding-top: 10px; font-size: 18px; color: #fff; font-weight: 400;}

.disSection { padding: 0;}
.disSection .dsPanel{display:flex; flex-wrap:wrap; justify-content:space-between; align-items: top; margin-top: 80px;}
.disSection .disLeft{width: 72%;}
.disSection .disRight{width: 25%;}
.disSection .disLeft .iBox {overflow: hidden; border-radius: 30px;}
.disSection .disLeft .iBox img{width: 100%;}
.disSection .disLeft strong {font-size: 16px; color: #ccc; padding-top: 30px; display: block;} 
.disSection .disLeft object {font-size: 35px; font-weight: 700; line-height: 1.5em;}
.disSection .disLeft p{font-size: 18px; font-weight: 500; line-height: 1.5em; color: #333;} 
.disSection .disLeft .learn{ border-radius: 30px; border: solid 1px #ddd; padding: 20px;}
.disSection .disLeft .learn h4{font-size: 30px; font-weight: 700; line-height: 1.5em;}
.disSection .disLeft .learn h5{font-size: 18px; font-weight: 500; line-height: 1.5em; color: #333;}
.disSection .disLeft .learn ul {display:flex; flex-wrap:wrap; justify-content:space-between; align-items: top; margin:15px 0;}
.disSection .disLeft .learn ul li {position: relative; width: 48%; padding: 5px 0 5px 25px;}
.disSection .disLeft .learn ul li:before{content: "\f00c"; font-family: 'FontAwesome'; color: #1fa67a; font-size: 16px; position: absolute; left: 0; top: 4px;}


.disSection .disRight h2{ font-size: 35px; font-weight: 800; padding-bottom: 15px;} 
.disSection .disRight ul li{ color: #fff; display: inline-block; width: 100px; height: 90px; background: #a1a2f7; text-align: center; align-items: center; vertical-align: middle; padding: 25px 0; border-radius: 10px; margin-right: 15px;}
.disSection .disRight ul li b{ font-size: 25px; display: block;}
.disSection .disRight ol{list-style: none; padding:0; margin:25px 0;}
.disSection .disRight ol li{display: block; line-height: 3em; font-size: 16px; font-weight: 400; border-bottom: solid 1px #ddd; padding-bottom: 10px;}
.disSection .disRight button { cursor: pointer; font-family: 'Arial'; background: #0086ff; display: inline-block; padding: 15px 50px; margin: 0 0 35px 0; border-radius: 30px; border: 0; font-size: 18px; color: #fff; text-decoration: none; vertical-align: top;}
.disSection .disRight button:hover {background: #333; }


.accordionBlk {padding: 50px 0 70px 0; margin: 0;}
.accordionBlk object { display: block; font-size: 16px; font-weight: 600; color: #666; text-transform: uppercase; letter-spacing: 10px; border-bottom: solid 1px #ddd; padding: 10px; text-align: center;}
.accordionBlk h2{ color: #333; line-height: 1.5em; padding: 10px; margin: 0; font-weight: 400; font-size: 17px; text-align: center; padding-bottom: 30px;}
.accordion a { position: relative; display: -webkit-box; display: -webkit-flex; display: -ms-flexbox; display: flex; -webkit-box-orient: vertical; -webkit-box-direction: normal; -webkit-flex-direction: column; -ms-flex-direction: column; flex-direction: column; width: 100%; padding: 10px; margin: 5px 0; color: #444; font-size: 1.15rem;
  font-weight: 400; border: 1px solid #e5e5e5; background: #e5e5e5;}
.accordion a:hover, .accordion a:hover::after { cursor: pointer; color: #00a2ff;}
.accordion a.active { color: #fff; border-bottom: 1px solid #00a2ff; background: #00a2ff;}
.accordion a::after { content: "\f067"; font-family: 'FontAwesome'; position: absolute; float: right; right: 1rem; font-size: 1rem; color: #333; padding: 5px 0;}
.accordion a.active::after { content: "\f068"; font-family: 'FontAwesome'; color: #444;}
.accordion .content { opacity: 0; padding: 0 1rem; max-height: 0; border: 1px solid #00a2ff; overflow: hidden; clear: both; -webkit-transition: all 0.2s ease 0.15s; -o-transition: all 0.2s ease 0.15s; transition: all 0.2s ease 0.15s; font-size: 16px;}
.accordion .content p { font-size: 1rem; font-weight: 300;}
.accordion .content.active { opacity: 1; padding: 1rem; max-height: 100%; -webkit-transition: all 0.35s ease 0.15s; -o-transition: all 0.35s ease 0.15s; transition: all 0.35s ease 0.15s;}
.accordion .content p i { color: #00a2ff; padding-right: 10px; font-size: 12px;}

.header.fixheader { position: fixed; top: 0; margin: 0; width: 100%; z-index: 9999; box-shadow: 0 0 5px #000; background: #191919; -webkit-animation:fadeInDown 0.4s; animation:fadeInDown 0.4s;}

.disRight.fixdisRight { position: fixed; top: 0; right: 0; margin: 0; width: 24%; z-index: 9999; box-shadow: 0 0 5px #000; background: #fff; -webkit-animation:fadeInDown 0.4s; animation:fadeInDown 0.4s; padding: 10px 20px;}

@media(max-width:1366px){
	.logoBlk { width: 225px; padding: 15px 0 15px 0;}
	.whyChoose .wcRight { width: 54%;}
	.whyChoose .wcLeft { width: 40%;}
	.disSection .disRight ul li {width: 120px; height: 75px; padding: 15px 0; margin-right: 10px;}
	.disSection .disRight h2 { font-size: 35px; font-weight: 700; padding-bottom: 15px;}
}


@media(max-width:1024px){
	
	.container { width: 90%; margin: 0 auto;}
	.innerBanner object { font-size: 50px;}
	.menu ul li a { font-size: 16px; font-weight: 400; padding: 0 5px;}
	.logoBlk { width: 225px; padding: 15px 0;}
	.object .topobject ul li a { font-size: 14px;}
	.innerBanner object {width: center; vertical-align: middle;}
    .aboutRobooookido h1 {font-size: 55px; padding: 25px 0 55px 0;}
	.aboutRobooookido ul li { width: 30%;}
	.aboutRobooookido ul li h2 { font-size: 20px;}
	.aboutRobooookido .textBox p { vertical-align: middle;}
	.whyChoose .wcRight h2 { font-size: 50px;}
	.whyChoose .wcRight button {  margin: 10px 0 35px 0;}
	.ourPrograms .opSection .cBox { width: 30%;}
	.learningMethods .lmLeft h3 { font-size: 50px; padding: 10px 0;}
	.cBottom .cbBox h2 { font-size: 30px;}
	
	.howWorks ul li strong { font-size: 20px;}
	.howWorks ul li p {font-size: 15px;}
	
	.howWorks { padding: 0;}
	.howWorks ul { margin: 10px 0;}
	.howWorks p { font-size: 20px; width: 75%;}
	.howWorks h2 { font-size: 50px;}
	
	.disSection .disLeft { width: 65%;}
	.disSection .disRight { width: 30%;}
	.disSection .disRight ul li { width: 115px; height: 70px; padding: 15px 0;}
}

@media(max-width:991px){
	.container { width: 90%; margin: 0 auto;}
	
	.menubar { display: block;}
	.menu ul {  display: none; width: 50%; position: absolute; top: 56px; right: 0; background: #7a99f6; padding: 0; z-index: 99;}
	.menu ul li{ display: block; width: 100%;}
	.menu ul li a { padding: 20px; color: #fff; border-bottom: solid 1px #ccc; display: block;}
	.menu ul li:last-child {background: #ff7c74; padding: 10px 20px;}
	
	.object .topobject ul li a { font-size: 14px;}
    .aboutRobooookido h1 {font-size: 40px; padding: 25px 0 55px 0;}
	.aboutRobooookido ul li { width: 30%;}
	.aboutRobooookido ul li h2 { font-size: 20px;}
	.aboutRobooookido .textBox p { vertical-align: middle;}
	.whyChoose { padding: 60px 0; margin-top: 50px;}
	.whyChoose .wcRight h2 { font-size: 40px;}
	.whyChoose .wcRight button {  margin: 10px 0 35px 0;}
	.ourPrograms .opSection .cBox { width: 30%;}
	.popularCourses { padding: 30px 0;}
	.popularCourses .textBox .aTag { width: 23%;}
	.popularCourses .textBox .aTag a{ padding: 15px 20px;}
	.learningMethods .lmLeft h3 { font-size: 40px; padding: 10px 0;}
	.cBottom .cbBox h2 { font-size: 30px;}	
	.howWorks ul li strong {font-size: 20px;}
	.howWorks p { font-size: 18px; width: 75%;}
	.howWorks h2 { font-size: 40px;}
	.howWorks ul li p { font-size: 14px;}
	.testimonialsBox { padding: 30px 0;}
	.testimonialsBox .cBox p { font-size: 18px;}
	.testimonialsBox h4 { font-size: 40px;}
	
	.learningMethods { padding: 70px 0 40px 0; margin-top: 50px;}
	.testimonialsBox p { font-size: 18px;}
	.popularCourses button { padding: 15px 30px; margin: 15px 0;}
	.innerBanner object {height: 90px;}
	}
	
@media(max-width:767px){
	
	.logoBlk { padding: 25px; width: 200px;}
	.logoBlk::before { display: none;}
	.object .topobject .container {justify-content: center;}
	
	.object .topobject ul li a { font-size: 14px;}
    .aboutRobooookido h1 {font-size: 40px; padding: 20px 0 40px 0;}
	.aboutRobooookido ul li { width: 30%;}
	.aboutRobooookido ul li h2 { font-size: 20px;}
	.aboutRobooookido .textBox p { vertical-align: middle;}
	.whyChoose .wcRight h2 br{display: none;}
	.whyChoose .wcRight h2 { font-size: 40px;}
	.whyChoose .wcRight button {  margin: 10px 0 35px 0;}
	.whyChoose .wcRight p { font-size: 17px;}
	.ourPrograms .opSection .cBox { width: 46%;}
	.ourPrograms h4 br {display: none;}
	.ourPrograms h3 { font-size: 45px;}
	.learningMethods { padding: 50px 0;}
	.learningMethods .lmLeft h3 { font-size: 35px; padding: 10px 0;}
	.learningMethods .lmLeft p br{display: none;}
	.learningMethods .lmLeft p {font-size: 16px;}
	.cBottom .cbBox h2 { font-size: 30px;}
	.popularCourses { padding: 20px 0;}
	.popularCourses h2 {font-size: 45px;}
	.popularCourses p { font-size: 17px;}
	.whyChoose .wcRight p { font-size: 17px;}
	
	.testimonialsBox h4 { font-size: 40px;}	
    .testimonialsBox .cBox { width: 48%; padding: 15px; margin-bottom: 25px;}	
	
	.contactUs .csRgt h3 br, .contactUs .csRgt p br{display: none; }
	.cBottom .cbBox { width: 100%; margin-top: 35px;}
	.cBottom { padding: 0;}
	
	.howWorks p { font-size: 16px; width: 75%;}
	.howWorks h2 { font-size: 35px;}
	
	.coursesBlk .cBox { width: 48%;}
	
	.howWorks ul li { width: 48%;}
	.howWorks p {width: 70%;}
	.innerBanner object {font-size: 50px; height: 90px;}
	
	.disSection .disLeft { width: 100%;}
	.disSection .disRight { width: 100%;}
	.disSection .disRight ul li { width: 115px; height: 80px; padding: 20px 0;}
	.disSection .disLeft .learn ul li { width: 100%; padding: 5px 0 5px 25px;}
	.popularCourses button { padding: 15px 30px; margin: 15px 0;}
	
}
	
@media(max-width:575px)	{
	.container { padding: 0 10px;}
	.menu ul { width: 100%;}
	.logoBlk { padding: 10px 0; width: 150px;}
	.object .topobject ul li a{ font-size: 10px;}
	.object .topobject ul li i { font-size: 10px;}
	.object .topobject ul.login { width: auto; padding-left: 10px;}
	.aboutRobooookido { text-align: center;}
	.aboutRobooookido h1 br{display: none}	
	.aboutRobooookido ul li {width: 100%; left: 0; text-align: left; margin-bottom: 25px;}
    .aboutRobooookido .textBox p { text-align: center; display: block; width: 100%; padding: 0 0 20px 0;}
	.aboutRobooookido a { margin: 35px 0 10px 0;}
	
	.whyChoose .wcLeft { width: 100%;}
	.whyChoose .wcRight { width: 100%; padding-top: 35px;}
	.whyChoose { padding: 50px 0 30px 0;}
	.whyChoose .wcRight h2 { font-size: 32px;}
	.whyChoose .wcRight ul li {font-size: 20px;}
	.ourPrograms h3 br{display: none;}
	.ourPrograms h3 {font-size: 32px;}
	.popularCourses p { font-size: 17px; width: 100%;}
	.popularCourses h2 { font-size: 32px;}
	.popularCourses .textBox .aTx { width: 100%;}
	.popularCourses .textBox .aTag { width: 100%;}
	.coursesBlk .cBox { width: 100%; padding: 15px; margin: 10px 0;}
	.ourPrograms .opSection .cBox { width: 100%;}
	
	
	.learningMethods { margin-top: 0;}
	.learningMethods .lmLeft { width: 100%;}
	.learningMethods .lmRgt { width: 100%;}
	.testimonialsBox p br, .testimonialsBox h4 br{display: none;}
	.testimonialsBox p { font-size: 16px; line-height: 1.5em; padding: 0 20px;}
	.testimonialsBox .cBox { width: 100%;}	
	.testimonialsBox h4 { font-size: 32px;}
	
	.contactUs { padding: 0;}
	.contactUs .lmLeft { width: 100%;}
	.contactUs .csRgt { width: 100%; padding-top: 40px;}
    .contactUs .csRgt h3 { font-size: 40px;}
	.contactUs .csRgt p {padding: 5px 0;}
	
	.innerBanner object { font-size: 40px; width: 100%; height: 80px;}	
	.howWorks p { width: 100%;}
	.howWorks button { margin: 10px 0;}
	.howWorks .textBox .aTx { width: 100%;}
	.howWorks .textBox .aTag { width: 100%;}
	.disRight.fixdisRight { width: 100%;}
}

@media(max-width:320px)	{

	
	}
.button-enroll{
  cursor: pointer; font-family: 'Arial'; background: #0086ff; display: inline-block; padding: 15px 50px; margin: 0 0 35px 0; border-radius: 30px; border: 0; font-size: 18px; color: #fff; text-decoration: none; vertical-align: top;
}
.stickyRight {
    position: sticky;
    top: 5rem;
    right: 0;
    z-index: 2;
    height: calc(100vh - 7rem);
    overflow-y: auto;
}
`;
