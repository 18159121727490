import React, { useEffect } from "react";
import { Card, CardContent, Typography, Button, Paper, Grid, ButtonBase } from "@mui/material";

import { styled } from "@mui/material/styles";
import { Styles } from "./styles/styles";
import { messages, webUrl } from "../../shared/constants";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { checkOlympiadQuizAction } from "./action";
import LoaderNew from "../../components/LoaderNew";

const Img = styled("img")({
  margin: "auto",
  display: "block",
  maxWidth: "100%",
  maxHeight: "100%",
});

function OlympiadWelcome(props) {
  useEffect(() => {
    if (props.quiz_data) {
      if (props.quiz_data) {
        props.history.push(webUrl.startOlympiadQuiz + "/" + props.quiz_data.data.quiz_id);
      }
    }
  }, [props.quiz_data, props.history]);

  function startOlympiad(e) {
    e.preventDefault();
    if (navigator.onLine) {
      props.checkOlympiadQuiz({});
    } else {
      alert(messages.noInternetConnection);
    }
  }
  if (props.showLoader && props.showLoader.length > 0) {
    return <LoaderNew />;
  }
  return (
    <Styles>
      <div className="makeStyles-root-2 makeStyles-root-3 makeStyles-root-1 container w-full flex">
        <div className="flex flex-auto min-w-0">
          <div className="flex flex-col flex-auto min-h-screen min-w-0 relative z-10">
            <div className="flex flex-col flex-auto min-h-0 relative z-10">
              <Grid container spacing={2}>
                <Grid item xs={12} md={8}>
                  <ButtonBase
                    sx={{
                      width: "100%",
                      height: "auto",
                      margin: 0,
                    }}
                  >
                    <Img alt="complex" src="/assets/images/olympiad/welcome_page_creative.webp" style={{ margin: 0, width: "100%" }} />
                  </ButtonBase>
                </Grid>
                <Grid item xs={12} md={4} sm container>
                  <Grid item xs container direction="column" spacing={2} style={{ padding: "20px" }}>
                    <Grid item xs>
                      <Typography gutterBottom variant="h4" component="div"></Typography>
                      <Typography variant="h5" gutterBottom>
                        Total Marks: 60 <span style={{ float: "right" }}>Time: 90 minutes</span>
                      </Typography>
                      <Typography variant="h5"></Typography>
                      <Typography
                        variant="h6"
                        sx={{
                          background: "#1b2330",
                          color: "white",
                          textAlign: "center",
                          marginTop: "10px",
                        }}
                      >
                        Exam Instructions
                      </Typography>
                      <Typography>
                        <ol>
                          <li
                            style={{
                              fontSize: "16px",
                              margin: "10px 15px",
                            }}
                          >
                            This test will have 3 sections:
                            <br />
                            <strong>Section - 1</strong> : Logical Reasoning (10 questions, 1 mark each)
                            <br />
                            <strong>Section - 2</strong> : Science Questions (35 questions, 1 mark each)
                            <br />
                            <strong>Section - 3</strong> : Achievers Section (5 questions, 3 marks each)
                          </li>
                          <li
                            style={{
                              fontSize: "16px",
                              margin: "10px 15px",
                            }}
                          >
                            This test is based on MCQ pattern.There are 4 options out of which only one is correct.
                          </li>
                          <li
                            style={{
                              fontSize: "16px",
                              margin: "10px 15px",
                            }}
                          >
                            There is no negative marking.
                          </li>
                          <li
                            style={{
                              fontSize: "16px",
                              margin: "10px 15px",
                            }}
                          >
                            Read the instructions carefully to answer the questions.
                          </li>
                        </ol>
                      </Typography>
                      <Button
                        type="button"
                        variant="contained"
                        color="primary"
                        className="MuiButton-root-197 MuiButton-containedPrimary-206 w-full mx-auto mt-16"
                        aria-label="LOG IN"
                        value="legacy"
                        onClick={startOlympiad}
                        style={{ marginTop: "20px", marginBottom: "20px" }}
                      >
                        Start Olympiad
                      </Button>
                      <Typography
                        variant="h6"
                        sx={{
                          color: "red",
                          textAlign: "center",
                          marginTop: "10px",
                        }}
                      >
                        Note: Olympiad shall be started at 13-Feb-2022 03:00 PM.
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} md={12} style={{ paddingTop: 0 }}></Grid>
              </Grid>
            </div>
          </div>
        </div>
      </div>
    </Styles>
  );
}

const mapStateToProps = (state) => ({
  quiz_data: state.olympiad.data.quiz_data,
  showLoader: state.user.showLoader,
});

const mapDispatchToProps = (dispatch) => ({
  checkOlympiadQuiz: (data) => dispatch(checkOlympiadQuizAction(data)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(OlympiadWelcome));
