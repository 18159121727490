import { showLoaderAction, stopLoaderAction } from "../user/action";
import { submitContactForm } from "./service";

/**
 * Action types
 */
export const actionTypes = {
  CONTACT_SUBMIT_REQUEST_SUCCESS: "CONTACT_SUBMIT_REQUEST_SUCCESS",
};

export const receivedSubmitContactFormAction = (payload) => ({
  type: actionTypes.CONTACT_SUBMIT_REQUEST_SUCCESS,
  payload,
});

export const submitContactFormAction = (data) => {
  return (dispatch) => {
    dispatch(showLoaderAction("submitContactForm"));

    return submitContactForm(data)
      .then((response) => {
        dispatch(stopLoaderAction("submitContactForm"));
        dispatch(receivedSubmitContactFormAction(response));
        return response;
      })
      .catch((error) => {
        dispatch(stopLoaderAction("submitContactForm"));
        throw new Error(error.message);
      });
  };
};
