import { showLoaderAction, stopLoaderAction } from "../user/action";
import { startChapterQuiz, submitChapterQuiz } from "./service";

/**
 * Action types
 */
export const actionTypes = {
  START_CHAPTER_QUIZ_REQUEST_SUCCESS: "START_CHAPTER_QUIZ_REQUEST_SUCCESS",
  SUBMIT_CHAPTER_QUIZ_REQUEST_SUCCESS: "SUBMIT_CHAPTER_QUIZ_REQUEST_SUCCESS"
};

export const receivedStartChapterQuizAction = (payload) => ({
  type: actionTypes.START_CHAPTER_QUIZ_REQUEST_SUCCESS,
  payload,
});

export const startChapterQuizAction = (data) => {
  return (dispatch) => {
    dispatch(showLoaderAction("startChapterQuiz"));

    return startChapterQuiz(data)
      .then((response) => {

        dispatch(stopLoaderAction("startChapterQuiz"));
        dispatch(receivedStartChapterQuizAction(response));
      })
      .catch((error) => {
        dispatch(stopLoaderAction("startChapterQuiz"));
      });
  };
};

export const receivedSubmitChapterQuizAction = (payload) => ({
  type: actionTypes.SUBMIT_CHAPTER_QUIZ_REQUEST_SUCCESS,
  payload,
});

export const submitChapterQuizAction = (data) => {
  return (dispatch) => {
    dispatch(showLoaderAction("submitChapterQuiz"));

    return submitChapterQuiz(data)
      .then((response) => {

        dispatch(stopLoaderAction("submitChapterQuiz"));
        dispatch(receivedSubmitChapterQuizAction(response));
      })
      .catch((error) => {
        dispatch(stopLoaderAction("submitChapterQuiz"));
      });
  };
};