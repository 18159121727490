import styled from "styled-components";
import { colors } from "../common/element/elements.js";

export const Styles = styled.div`
  ion-spinner {
    color: ${colors.green} !important;
  }
  .loading-image {
    width: 100%;
    height: 100vh;
    align-content: center;
    justify-content: center;
    display: inline-grid;

    img {
      max-width: 200px;
    }
  }
`;
