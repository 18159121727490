import React, { Component } from "react";
import Datas from "../data/service/three-section.json";
import { Container, Row, Col } from "react-bootstrap";
import { Styles } from "./styles/threeSections";

class ThreeSection extends Component {
  render() {
    return (
      <Styles>
        {/* Service Box */}
        <section className="service-area">
          <Container>
            <Row className="pt-5">
              <Col md="12">
                <div className="sec-title text-center">
                  <h4>{Datas.secTitle}</h4>
                </div>
              </Col>
              {Datas.dataList.map((data, i) => (
                <Col md="4" key={i}>
                  <div className="service-box" style={{ backgroundColor: data.bgColor }}>
                    <Row className="box-header d-flex justify-content-center">
                      <div className="box-icon">
                        <i className={data.boxIcon}></i>
                      </div>
                      <div className="box-title">
                        <h6>{data.title}</h6>
                      </div>
                    </Row>
                    <div className="box-desc-wrapper">
                      <Row>
                        <div className="box-des d-flex">
                          <div className="des-icon">
                            <i className="las la-check-circle"></i>
                          </div>
                          <div className="des-title">
                            <p>{data.subTitle}</p>
                          </div>
                        </div>
                      </Row>
                      {data.subTitle2 && (
                        <Row>
                          <div className="box-des d-flex">
                            <div className="des-icon">
                              <i className="las la-check-circle"></i>
                            </div>
                            <div className="des-title">
                              <p>{data.subTitle2}</p>
                            </div>
                          </div>
                        </Row>
                      )}
                      {data.subTitle3 && (
                        <Row>
                          <div className="box-des d-flex">
                            <div className="des-icon">
                              <i className="las la-check-circle"></i>
                            </div>
                            <div className="des-title">
                              <p>{data.subTitle3}</p>
                            </div>
                          </div>
                        </Row>
                      )}
                    </div>
                  </div>
                </Col>
              ))}
            </Row>
          </Container>
        </section>
      </Styles>
    );
  }
}

export default ThreeSection;
