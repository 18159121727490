import styled from "styled-components";
import { colors } from "../common/element/elements.js";

export const Styles = styled.div`
  @font-face {
    src: url("/assets/fonts/FreeSans.ttf") format("truetype"); /* Safari, Android, iOS */
  }
  @font-face {
    src: url("/assets/fonts/FreeSansBold.ttf") format("truetype"); /* Safari, Android, iOS */
  }

  html {
    body {
    }
  }
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }
  .btn_white {
    width: 200px;
    ${colors.gredient_orange_bg}
    font-size: 24px;
    letter-spacing: 2px;
    line-height: 42px;
    color: #ffffff;
    font-weight: 600;
    border: 0;
    cursor: pointer;
    &:hover {
      background: white;
      color: rgb(234 135 51);
    }
  }
  .h-100 {
    height: 100% !important;
  }

  .d_flex {
    display: flex;
    .col_two {
      width: 50%;
    }
    .col_four {
      width: 25%;
    }
    .col_three {
      width: 33.333%;
    }
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
  }
  p {
    margin: 0;
  }

  section {
    .bg_card {
      border-radius: 10px;
      .col_two.left {
        display: flex;
        align-items: flex-end;
        justify-content: center;
        flex-direction: column;
        width: 60%;
        position: relative;
        &::before {
          content: "";
          position: absolute;
          background-image: url("/assets/images/home/learning_layer.webp");
          background-repeat: no-repeat;
          background-size: contain;
          height: 500px;
          width: 65%;
          left: -30px;
        }
        h4 {
          font-size: 31px;
          letter-spacing: 2px;
          line-height: 22px;
          color: #f19ba4;
          font-weight: 600;
        }
        p {
          font-size: 13px;
          letter-spacing: 1px;
          line-height: 20px;
          color: #ffffff;
          font-weight: 250;
          max-width: 35%;
          margin-top: 20px;
          text-align: right;
        }
      }
    }
    .col_two.right {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-end;
      padding-right: 40px;
      width: 40%;
    }
    .top {
      margin-top: -50px;
    }
    .bottom {
      margin-bottom: -50px;
    }

    .yellow {
      background-color: #fac832;
    }
  }

  .trial {
    margin-top: 190px;
    margin-bottom: 150px;
    .sky_blue {
      background-image: url("/assets/images/home/background_blue.webp");
      padding: 45px;
      background-repeat: no-repeat;
      background-size: cover;
    }
    .left_col {
      display: flex;
      align-items: center;
    }
    .content_box {
      h5 {
        font-size: 22px;
        letter-spacing: 2px;
        line-height: 50px;
        color: #ffffff;
        font-weight: 600;
      }
      h2 {
        font-size: 38px;
        letter-spacing: 3px;
        line-height: 50px;
        color: #ffffff;
        font-weight: 600;
      }
      p {
        font-size: 13px;
        letter-spacing: 1px;
        line-height: 50px;
        color: #ffffff;
        font-weight: 400;
      }
    }
    .right_col {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      @media (max-width: 767px) {
        justify-content: flex-start;
      }
    }
    .r_content_box {
      @media (max-width: 767px) {
        width: 70%;
      }
      h5 {
        font-size: 28px;
        letter-spacing: 2px;
        line-height: 50px;
        color: #ffffff;
        font-weight: 600;
      }
      p {
        font-size: 13px;
        letter-spacing: 1px;
        line-height: 21px;
        color: #ffffff;
        font-weight: 400;
      }
    }
    .img_col {
      position: relative;
      &::before {
        content: "";
        position: absolute;
        left: 0;
        background-image: url("/assets/images/home/trial_layer.webp");
        background-size: contain;
        background-repeat: no-repeat;
        height: 500px;
        width: 70%;
        top: -170px;
      }
    }
  }

  @media (max-width: 767px) {
    .d_flex {
      flex-wrap: wrap;
      .col_two {
        width: 100%;
      }
    }
    section .bg_card .col_two.left::before {
      background-size: contain;
      height: 320px;
      width: 320px;
      left: -15px;

      top: -78px;
    }
    section .bg_card .col_two.left h4 {
      font-size: 33px;
      line-height: 35px;
      width: 50%;
    }
    section .bg_card .col_two.left p {
      max-width: 70%;
      font-size: 16px;
      text-align: left;
      padding-top: 90px;
    }
    section .col_two.right {
      padding-right: 0;
      width: 100%;
      justify-content: center;
      align-items: center;
    }
    section .bg_card .col_two.left {
      width: 100%;
    }
    .trial .img_col::before {
      width: 50%;
      top: -250px;
      left: 60%;
    }

    section .bg_card {
      padding: 25px;
      height: 100%;
    }
    .d_flex .col_three {
      width: 100%;
    }
    .trial {
      margin-top: 25px;
    }
    .trial .content_box h2 {
      font-size: 22px;
      letter-spacing: 3px;
      line-height: 25px;
    }
    .trial .r_content_box h5 {
      font-size: 22px;
      letter-spacing: 2px;
      line-height: 30px;
      color: #ffffff;
      font-weight: 600;

      margin-top: 20px;
    }
  }
`;
