import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import FooterTwo from "../../components/FooterTwo";
import UserHeader from "../../components/UserHeader";
import { Container, Row, Col } from "react-bootstrap";
import SubjectPane from "./components/SubjectPane";
import DashboardMenu from "../dashboard/components/DashboardMenu";
import { Styles } from "../dashboard/styles/dashboard.js";
import { subjectDetailsAction } from "./action";
import LoaderNew from "../../components/LoaderNew";
import USCourseMenu from "../dashboard/components/USCourseMenu";
import USDashboard from "./components/USDashboard";

function Subject(props) {
  const {
    match: { params },
  } = props;
  let subjectName = props?.subsubcategory?.name || "";
  let isUSVersion =
    props?.subsubcategory?.subcategory?.category?.is_us_version || 0;

  return (
    <div className="main-wrapper course-details-page">
      {/* User Header */}
      <UserHeader {...props} />

      <Styles>
        {/* Course Grid */}
        <section className="dashboard-grid-area">
          <Container>
            <Row>
              <Col lg="3" md="3" sm="12">
                {isUSVersion == 1 ? (
                  <USCourseMenu {...props} />
                ) : (
                  <DashboardMenu />
                )}

                {props.subsubcategory.can_user_access === 1
                  ? ""
                  : props.showLoader.length === 0 && (
                      <div
                        className="course-details-area"
                        style={{ padding: "10px 0" }}
                      >
                        <div className="single-details-sidbar">
                          <div
                            className="course-details-feature"
                            style={{ padding: 0 }}
                          >
                            <Col sm="12">
                              <button
                                type="button"
                                onClick={() =>
                                  props.history.push(
                                    process.env.PUBLIC_URL +
                                      "/package/" +
                                      params.type
                                  )
                                }
                                className="enroll-btn"
                                style={{ margin: "20px 0" }}
                              >
                                Buy Now
                              </button>
                            </Col>
                          </div>
                        </div>
                      </div>
                    )}
              </Col>
              {props.showLoader && props.showLoader.length > 0 && <LoaderNew />}
              {isUSVersion == 1 ? (
                <>
                  <Col>
                    {props.showLoader.length === 0 && (
                      <Row className="usBannerBG">
                        <Col lg="6" md="6" sm="12">
                          <h5
                            style={{
                              marginTop: "10px",
                              textTransform: "uppercase",
                            }}
                          >
                            GET STARTED WITH{" "}
                            <span className="text-white">ALYSS</span>{" "}
                            SPIKE&trade; {subjectName}
                          </h5>
                        </Col>
                        <Col lg="6" md="6" sm="12">
                          <div className="us-module-hardware">
                            <img
                              src={
                                process.env.PUBLIC_URL +
                                (props.subsubcategory.subcategory.name ===
                                  "Spike Prime")
                                  ? `/assets/images/module/spike_prime_biker.webp`
                                  : `/assets/images/module/ev3_hardware.webp`
                              }
                              alt=""
                              className="img-fluid"
                            />
                          </div>
                        </Col>
                      </Row>
                    )}

                    <Row>
                      <Col className="usDashboard p-0 mt-5">
                        <USDashboard {...props} />
                      </Col>
                    </Row>
                  </Col>
                </>
              ) : (
                <>
                  <Col>
                    <Row>
                      <Col lg="12" md="12" sm="12">
                        {props.showLoader.length === 0 && (
                          <h5 style={{ marginTop: "10px" }}>{subjectName}</h5>
                        )}
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="12" md="12" sm="12">
                        <SubjectPane {...props} />
                      </Col>
                    </Row>
                  </Col>
                </>
              )}
            </Row>
          </Container>
        </section>
      </Styles>

      {/* Footer 2 */}
      <FooterTwo />
    </div>
  );
}

const mapStateToProps = (state) => ({
  subsubcategory: state.subject.data.subsubcategory,
  showLoader: state.user.showLoader,
});

const mapDispatchToProps = (dispatch) => ({
  subjectDetails: (data) => dispatch(subjectDetailsAction(data)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Subject)
);
