import React, { useState, useEffect } from "react";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Container, Row, Col, Dropdown } from "react-bootstrap";
import FooterTwo from "../../components/FooterTwo";
import TestimonialSlider from "../../components/TestimonialSlider";
import AppSection from "../../components/AppSection";
import FeatureHeroImage from "../../components/FeatureHeroImage";
import Offerings from "../../components/Offerings";
import ModalVideo from "react-modal-video";
import ThreeSection from "../../components/ThreeSection";
import VideoSlider from "../../components/VideoSlider";
import ButtonArea from "../../components/ButtonArea";
import { webUrl } from "../../shared/constants";
import { Styles as StylesAbout } from "../../components/styles/aboutUs.js";
import common from "../../shared/common";
import HeaderPromo from "../../components/HeaderPromo";
import { Button } from "@mui/material";
import User from "../user";
import { sendAuditEventAction } from "../../components/actions/common";

const Teacher = (props) => {
  const idPref = Math.random();
  const [isOpen, setIsOpen] = useState(false);

  const openModal = () => {
    setIsOpen(true);
  };

  const onClickJoinNow = () => {
    if (common.getFromStorage("userName")) props.history.push(webUrl.userDashboard);
  };

  useEffect(() => {
    if (common.getFromStorage("userName")) props.sendAuditEvent({ event_id: "teacher_solution", source: "web" });
  }, []);

  return (
    <div className="main-wrapper">
      {/* Header */}
      <HeaderPromo {...props} onButtonClick={onClickJoinNow} showPopup={!!!common.getFromStorage("userName")} />

      {/* Three Section */}
      <ThreeSection />
      {/* Button Area */}
      <ButtonArea
        buttonText="Join Now"
        onHeroButtonClick={onClickJoinNow}
        showPopup={!!!common.getFromStorage("userName")}
        bgColor="rgb(246,249,255)"
      />

      <StylesAbout>
        {/* About Us */}
        <section className="about-us">
          <Container>
            <Row>
              <Col md="6">
                <div className="about-image">
                  <img src={process.env.PUBLIC_URL + `/assets/images/about/Teacher.webp`} className="main-img" alt="" />
                  <img src={process.env.PUBLIC_URL + "/assets/images/about/pattern.webp"} className="pattern-img" alt="" />
                  {/* <div className="video-player" style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/vd-bg.webp)` }}>
                    <ModalVideo channel="youtube" isOpen={isOpen} videoId="uXFUl0KcIkA" onClose={() => setIsOpen(false)} />
                    <button onClick={openModal} className="play-button">
                      <i className="las la-play"></i>
                    </button>
                  </div> */}
                </div>
              </Col>
              <Col md="6">
                <div className="about-content">
                  <h4 className="about-title">ALYSS Content Edge</h4>
                  <p className="about-para">
                    We believe that the purpose of education is not about flooding the student's mind with information from the syllabus but about
                    awakening a genuine interest and questioning spirit in the minds of the students that forms the basis of scientific learning. This
                    is what our content and animation experts work ceaselessly on, in an effort to nurture the spirit of scientific curiosity in
                    school students... to enable them to become future scientists!
                  </p>
                  <div className="about-para">
                    <ul>
                      <li>To empower teachers</li>
                      <li>To make science and maths learning a simple and pleasant exercise</li>
                      <li>To enhance scientific curiosity among students</li>
                      <li>To inspire future scientists and mathematicians</li>
                    </ul>
                  </div>
                  <Button
                    variant="contained"
                    size="large"
                    style={{ float: "right" }}
                    className={"mt-3 mb-3 orange-bg pull-right connect-button"}
                    onClick={onClickJoinNow}
                    id={"hero-btn" + idPref}
                  >
                    Connect
                  </Button>
                  <User openCommand={true} commandCtrlId={"hero-btn" + idPref} />
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </StylesAbout>

      {/* Testimonial Slider */}
      <TestimonialSlider />

      {/* Video Slider */}
      <VideoSlider />
      {/* Button Area */}
      <ButtonArea buttonText="Join Now" onHeroButtonClick={onClickJoinNow} showPopup={!!!common.getFromStorage("userName")} />

      {/* Footer */}
      <FooterTwo />
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  sendAuditEvent: (data) => dispatch(sendAuditEventAction(data)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Teacher));
