import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";

import { messages } from "../../../shared/constants.js";

function EmailFactor(props) {
  const query = new URLSearchParams(props.location.search);
  const referCode = query.get("refer-code") || "";
  const schoolId = query.get("school") || null;
  const [formData, setFormData] = useState({
    email: props.email,
    refer_code: referCode,
    school_id: schoolId,
  });

  const onFieldChange = (name, value) => {
    setFormData({
      ...formData,
      [name]: value,
    });

    resetError(name);
  };

  const onLoginNextClick = (e) => {
    e.preventDefault();
    if (isValidLoginFormData()) {
      props.setEmail(formData.email);
      props.userLoginWithEmail(formData).then((response) => {
        if (response && response.existingUser === 1) props.setStep(4);
        else if (response && response.existingUser === 0) props.setStep(5);
      });
    }
  };

  const onLoginWithPhone = (e) => {
    e.preventDefault();
    props.setStep(0);
  };

  const isValidLoginFormData = () => {
    let isValid = true;

    if (!formData || !formData.email) {
      setError("email", messages.requiredField);
      isValid = false;
    } else if (!isEmail(formData.email)) {
      setError("email", messages.invalidEmailField);
      isValid = false;
    } else {
      resetError("email");
    }

    return isValid;
  };

  const isEmail = (email) => {
    return /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(email);
  };

  const setError = (input, message) => {
    const formControl = document.getElementsByName(input)[0].parentElement;
    const errorMsg = formControl.querySelector(".login_input-msg");
    formControl.classList.add("error");
    formControl.classList.add("text-left");
    errorMsg.innerText = message;
  };

  const resetError = (input) => {
    const controlNode = document.getElementsByName(input)[0];

    if (controlNode) {
      const formControl = controlNode.parentElement;
      const errorMsg = formControl.querySelector(".login_input-msg");
      formControl.classList.remove("error");
      formControl.classList.remove("text-left");
      errorMsg.innerText = "";
    }
  };

  return (
    <>
      <div className="side-content">
        <h5>Let's get started</h5>
        <p>Enter your email address to sign up / sign in to your Alyss account</p>
      </div>
      <div className="side-post">
        {/* Login Area */}
        <section className="login-area">
          <Row>
            <Col md="12">
              <div className="login-box">
                <form id="form_login" className="form" onSubmit={onLoginNextClick}>
                  <div className="form-control">
                    <Row>
                      <Col md="12" className="error-icon-info">
                        <label>Email</label>
                        <input
                          type="text"
                          autoFocus
                          placeholder="Email"
                          name="email"
                          value={formData.email}
                          onChange={(e) => {
                            onFieldChange(e.target.name, e.target.value);
                          }}
                        />
                        <span className="login_input-msg"></span>
                      </Col>
                    </Row>
                  </div>
                  <button type="button" onClick={onLoginNextClick}>
                    Next
                  </button>
                </form>
                <div className="separator">Or</div>
                <div className="email-link">
                  <a href="#" onClick={onLoginWithPhone}>
                    Login/Signup with Phone Number
                  </a>
                </div>
              </div>
            </Col>
          </Row>
        </section>
      </div>
    </>
  );
}

export default EmailFactor;
