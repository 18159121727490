import React, { useState } from "react";
import { Row, Col, Form, Dropdown } from "react-bootstrap";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

const RecordedSessionsFilter = (props) => {
  const getSubcategoriesByCategory = (value) => {
    let selectedItem = props.categories.filter((item) => parseInt(item.id) === parseInt(value))[0];

    return selectedItem && selectedItem.subcategories;
  };

  const getSubsubcategoriesBySubcategory = (value, category_id = props.formData.category_id) => {
    const subcategories = getSubcategoriesByCategory(category_id);
    let selectedItem = subcategories && subcategories.filter((item) => parseInt(item.id) === parseInt(value))[0];

    return selectedItem && selectedItem.subsubcategories;
  };

  const pickedItem = (key, data) => {
    if (data) {
      let selectedItem = data.filter((item) => parseInt(item.id) === parseInt(key))[0];
      return (selectedItem && selectedItem.name) || "";
    }
    return "";
  };

  const onSubsubcategoryChange = (name, value) => {
    props.setFormData({
      [name]: value,
    });
  };

  const onSubcategoryChange = (name, value) => {
    let _subSubCategoryId =
      (getSubsubcategoriesBySubcategory(value) && getSubsubcategoriesBySubcategory(value)[0] && getSubsubcategoriesBySubcategory(value)[0].id) ||
      null;

    props.setFormData({
      [name]: value,
      subsubcategory_id: _subSubCategoryId,
    });
  };

  const onCategoryChange = (name, value) => {
    let _subCategoryId =
      (getSubcategoriesByCategory(value) && getSubcategoriesByCategory(value)[0] && getSubcategoriesByCategory(value)[0].id) || null;

    let _subSubCategoryId = null;
    if (_subCategoryId) {
      _subSubCategoryId =
        (getSubsubcategoriesBySubcategory(_subCategoryId, value) &&
          getSubsubcategoriesBySubcategory(_subCategoryId, value)[0] &&
          getSubsubcategoriesBySubcategory(_subCategoryId, value)[0].id) ||
        null;
    }

    props.setFormData({
      [name]: value,
      subcategory_id: _subCategoryId,
      subsubcategory_id: _subSubCategoryId,
    });
  };

  return (
    <section className="dashboard-filter">
      <Row>
        <Col md={{ span: 4 }}>
          <Form.Group controlId="formBasicSelect">
            <Dropdown
              className="info-filter"
              name="category_id"
              id="category_id"
              onSelect={(e) => {
                onCategoryChange("category_id", e);
              }}
            >
              <Dropdown.Toggle as="a" className="filter-item">
                <span>{pickedItem(props.formData.category_id, props.categories)}</span>
                <KeyboardArrowDownIcon />
              </Dropdown.Toggle>
              <Dropdown.Menu as="ul">
                {props.categories &&
                  props.categories.map((item) => (
                    <Dropdown.Item as="li" key={item.id} eventKey={item.id}>
                      {item.name}
                    </Dropdown.Item>
                  ))}
              </Dropdown.Menu>
              <span className="login_input-msg"></span>
            </Dropdown>
          </Form.Group>
        </Col>
        <Col md={{ span: 4 }}>
          <Form.Group controlId="formBasicSelect">
            {getSubcategoriesByCategory(props.formData.category_id) && getSubcategoriesByCategory(props.formData.category_id).length > 0 && (
              <Dropdown
                className="info-filter"
                name="subcategory_id"
                id="subcategory_id"
                onSelect={(e) => {
                  onSubcategoryChange("subcategory_id", e);
                }}
              >
                <Dropdown.Toggle as="a" className="filter-item">
                  <span>{pickedItem(props.formData.subcategory_id, getSubcategoriesByCategory(props.formData.category_id))}</span>
                  <KeyboardArrowDownIcon />
                </Dropdown.Toggle>
                <Dropdown.Menu as="ul">
                  {getSubcategoriesByCategory(props.formData.category_id) &&
                    getSubcategoriesByCategory(props.formData.category_id).map((item) => (
                      <Dropdown.Item as="li" key={item.id} eventKey={item.id}>
                        {item.name}
                      </Dropdown.Item>
                    ))}
                </Dropdown.Menu>
                <span className="login_input-msg"></span>
              </Dropdown>
            )}
          </Form.Group>
        </Col>
        <Col md={{ span: 4 }}>
          <Form.Group controlId="formBasicSelect">
            {getSubsubcategoriesBySubcategory(props.formData.subcategory_id) &&
              getSubsubcategoriesBySubcategory(props.formData.subcategory_id).length > 0 && (
                <Dropdown
                  className="info-filter"
                  name="subsubcategory_id"
                  id="subsubcategory_id"
                  onSelect={(e) => {
                    onSubsubcategoryChange("subsubcategory_id", e);
                  }}
                >
                  <Dropdown.Toggle as="a" className="filter-item">
                    <span>{pickedItem(props.formData.subsubcategory_id, getSubsubcategoriesBySubcategory(props.formData.subcategory_id))}</span>
                    <KeyboardArrowDownIcon />
                  </Dropdown.Toggle>
                  <Dropdown.Menu as="ul">
                    {getSubsubcategoriesBySubcategory(props.formData.subcategory_id) &&
                      getSubsubcategoriesBySubcategory(props.formData.subcategory_id).map((item) => (
                        <Dropdown.Item as="li" key={item.id} eventKey={item.id}>
                          {item.name}
                        </Dropdown.Item>
                      ))}
                  </Dropdown.Menu>
                  <span className="login_input-msg"></span>
                </Dropdown>
              )}
          </Form.Group>
        </Col>
      </Row>
    </section>
  );
};

export default RecordedSessionsFilter;
