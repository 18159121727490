import { doPatchRequest, doPostRequest } from "./../../shared/api/base-api";
import { apiUrl } from "./../../shared/constants";

//master data api
export function getMasterData(data) {
  return doPostRequest(apiUrl.masterData, data);
}

//Update profile api
export function updateMemberProfile(data) {
  return doPatchRequest(apiUrl.updateMemberProfile, data);
}
