import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Styles } from "../styles/userLogin.js";
import PhoneFactor from "./PhoneFactor.js";
import VerifyOTP from "./VerifyOTP.js";
import CloseIcon from "@mui/icons-material/Close";

function PartnerLogin(props) {
  const [step, setStep] = useState(0);
  const [mobile, setMobile] = useState("");
  const [country, setCountry] = useState("");

  useEffect(() => {
    const sidebarBtn = props.openCommand ? document.getElementById(props.commandCtrlId) : document.getElementById("partner-login");

    if (sidebarBtn) {
      const sidebarOverlay = document.getElementById("sidebar-overlay");
      const sidebarBody = document.getElementById("partner-body");
      const sidebarExit = document.getElementById("close-partner");

      sidebarBtn.addEventListener("click", function (e) {
        e.preventDefault();
        sidebarOverlay.classList.add("visible");
        sidebarBody.classList.add("opened");
        resetForm();
      });

      sidebarOverlay.addEventListener("click", function (e) {
        e.preventDefault();
        sidebarOverlay.classList.remove("visible");
        sidebarBody.classList.remove("opened");
        resetForm();
      });

      sidebarExit.addEventListener("click", function (e) {
        e.preventDefault();
        sidebarOverlay.classList.remove("visible");
        sidebarBody.classList.remove("opened");
        // resetForm();
      });
    }
  });

  const resetForm = () => {
    setStep(0);
    // resetError("mobile");
    // resetError("country_code");
    // document.getElementById("mobile").value = "";
  };

  return (
    <Styles>
      {/* Sidebar */}
      {props.openCommand ? (
        ""
      ) : (
        <a href={"#!"} id="partner-login">
          <i className="las la-angle-right"></i>
          {props && props.customText}
        </a>
      )}

      <div className="sidebar" id="partner-body">
        <div className="side-logo d-flex justify-content-between">
          <div>
            <Link to={process.env.PUBLIC_URL + "/"}>
              <img src={process.env.PUBLIC_URL + "/assets/images/logo2.webp"} alt="" />
            </Link>
          </div>
          <div>
            <a href={process.env.PUBLIC_URL + "/"} id="close-partner">
              <CloseIcon />
            </a>
          </div>
        </div>

        {step === 0 && (
          <PhoneFactor
            {...props}
            setStep={(key) => setStep(step + key)}
            setMobile={(number) => setMobile(number)}
            setCountry={(country) => setCountry(country)}
          />
        )}
        {step === 1 && <VerifyOTP {...props} setStep={(key) => setStep(step + key)} mobile={mobile} country={country} />}
      </div>

      <div className="sidebar-overlay" id="sidebar-overlay"></div>
    </Styles>
  );
}

export default PartnerLogin;
