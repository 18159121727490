import { showLoaderAction, stopLoaderAction } from "../user/action";
import { getUsCoursePlansData,getUsCourseDetailPlansData } from "./service";

/**
 * Action types
 */
export const actionTypes = {
  COURSE_PLANS_DATA_REQUEST_SUCCESS: "COURSE_PLANS_DATA_REQUEST_SUCCESS",
  COURSE_PLAN_DETAIL_DATA_REQUEST_SUCCESS: "COURSE_PLAN_DETAIL_DATA_REQUEST_SUCCESS",
};

export const receivedUsCoursePlansDataAction = (payload) => ({
  type: actionTypes.COURSE_PLANS_DATA_REQUEST_SUCCESS,
  payload,
});
export const receivedUsCourseDetailPlansDataAction = (payload) => ({
  type: actionTypes.COURSE_PLAN_DETAIL_DATA_REQUEST_SUCCESS,
  payload,
});

export const UsCoursePlansDataAction = (data) => {
  return (dispatch) => {
    dispatch(showLoaderAction("getUsCoursePlansData"));

    return getUsCoursePlansData(data)
      .then((response) => {
        dispatch(stopLoaderAction("getUsCoursePlansData"));

        if (response) {
          dispatch(receivedUsCoursePlansDataAction(response));
        }
      })
      .catch((error) => {
        dispatch(stopLoaderAction("getUsCoursePlansData"));
      });
  };
};
export const UsCourseDetailPlansDataAction = (data) => {
  return (dispatch) => {
    dispatch(showLoaderAction("getCourseDetailPlansDataAction"));

    return getUsCourseDetailPlansData(data)
      .then((response) => {
        dispatch(stopLoaderAction("getCourseDetailPlansDataAction"));

        if (response) {
          dispatch(receivedUsCourseDetailPlansDataAction(response));
        }
      })
      .catch((error) => {
        dispatch(stopLoaderAction("getCourseDetailPlansDataAction"));
      });
  };
};
