import React, { useEffect } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Provider } from "react-redux";
//import ReactGA from "react-ga";
import ReactGA from "react-ga4";
import ReactPixel from "react-facebook-pixel";
import TagManager from "react-gtm-module";

import ScrollToTop from "./helper/ScrollToTop";
import { GlobalStyle } from "./components/common/styles/global.js";
import Home from "./Home";
import HomeUS from "./HomeUS";
import HomeIN from "./HomeIN";
import About from "./pages/about/About";
import Contact from "./pages/contact/Contact";
import Faq from "./pages/faq/Faq";
import PageNotFound from "./pages/404/PageNotFound";
import BlogClassic from "./pages/blog/BlogClassic";
import BlogDetails from "./pages/blog/BlogDetails";
import Cart from "./pages/cart";
import Subject from "./pages/subject";
import Chapter from "./pages/chapter";
import ChapterQuiz from "./pages/chapter_quiz";
import ChapterQuizReport from "./pages/chapter_quiz_report";
import ModuleQuiz from "./pages/module_quiz";
import ModuleQuizReport from "./pages/module_quiz_report";
import Module from "./pages/module";
import Dashboard from "./pages/dashboard";
import Package from "./pages/package";
import store from "./shared/store";
import { PrivateRoute } from "./shared/privateRoute";
// import Loader from "./components/Loader";
import { apiUrl, config, webUrl } from "./shared/constants";
import Subscriptions from "./pages/subscriptions";
import Notifications from "./pages/notifications";
import Transactions from "./pages/transactions";
import Profile from "./pages/profile";
import StudyMaterial from "./pages/study_material";
import Robotic from "./pages/robotic";
import TeacherTechnologyKit from "./pages/teacher_technology_kit";
import TTKDetails from "./pages/ttk_details";
import GeneratedTestsList from "./pages/generated_tests_list";
import NewTest from "./pages/generate_test";
import Teacher from "./pages/teacher";
import TeacherCampaign from "./pages/teacher_campaign";
import School from "./pages/school";
import Partner from "./pages/partner";
import LiveClass from "./pages/live_classes";
import RecordedSession from "./pages/recorded_sessions";
import OrderDetail from "./pages/order_details";
import ModuleSample from "./pages/module/ModuleSample";
import OlympiadLogin from "./pages/olympiad/OlympiadLogin";
import OlympiadWelcome from "./pages/olympiad/OlympiadWelcome";
import OlympiadThankyou from "./pages/olympiad/OlympiadThankyou";
import paymentSuccess from "./pages/payment/paymentSuccess";
import paymentFailure from "./pages/payment/paymentFailure";
import Olympiad from "./pages/olympiad/index";
import PartnerDashboard from "./pages/partner_dashboard";
import StudentList from "./pages/partner_dashboard/components/students/StudentList";
import StudentPackage from "./pages/partner_dashboard/components/students/StudentPackage";
import TeacherList from "./pages/partner_dashboard/components/teachers/TeacherList";
import TeacherPackage from "./pages/partner_dashboard/components/teachers/TeacherPackage";
import SchoolList from "./pages/partner_dashboard/components/schools/SchoolList";
import Wallet from "./pages/partner_dashboard/components/list/Wallet";
// import Coupon from "./pages/partner_dashboard/components/list/Coupon";
// import Commission from "./pages/partner_dashboard/components/list/Commission";
import PromotionalMaterial from "./pages/partner_dashboard/components/list/PromotionalMaterial";
import PartnerNotification from "./pages/partner_dashboard/components/list/PartnerNotification";
import PartnerEnquiry from "./pages/partner_dashboard/components/PartnerEnquiry";
import ReferCode from "./pages/partner_dashboard/components/refer_code/ReferCode";
import ThankYou from "./pages/teacher_campaign/ThankYou";
import Terms from "./components/Terms";
import Privacy from "./components/Privacy";
import Offerings from "./pages/offerings/index";
import ChapterTheory from "./pages/embed/ChapterTheory";
import ChapterExercise from "./pages/embed/ChapterExercise";
import ChapterVideo from "./pages/embed/ChapterVideo";
import ModuleTheory from "./pages/embed/ModuleTheory";
import ModuleConcept from "./pages/embed/ModuleConcept";
import ModuleProgramming from "./pages/embed/ModuleProgramming";
import ModuleHardwareBuilding from "./pages/embed/ModuleHardwareBuilding";
import ModuleSoftwareBuilding from "./pages/embed/ModuleSoftwareBuilding";
import ModuleCoreFundamentals from "./pages/embed/ModuleCoreFundamentals";
import ModuleSoftwareSetup from "./pages/embed/ModuleSoftwareSetup";
import EmbedVideo from "./pages/embed/EmbedVideo";
import PartnerLoginAdmin from "./pages/partner_dashboard/components/PartnerLoginAdmin";
import ExamPreparatory from "./pages/exam_preparatory/index";
import Internship from "./pages/internship/index";
import courseListUs from "./pages/ushomepage/Courses";
import CourseDetails from "./pages/ushomepage/CourseDetails";
import Introduction from "./pages/subject/components/Introduction";
import Units from "./pages/subject/components/Units";
import Missions from "./pages/subject/components/Missions";
import Certificate from "./pages/subject/components/Certificate";

function App() {
  //Google Tag Manager
  const tagManagerArgs = {
    gtmId: config.GTM_ID,
  };

  TagManager.initialize(tagManagerArgs);
  if (process.env.NODE_ENV === "production") {
    ReactGA.initialize(config.GA_MEASUREMENT_ID);
    typeof ReactGA.pageview === "function" &&
      ReactGA.pageview(window.location.pathname + window.location.search);

    const advancedMatching = {};
    const options = {
      autoConfig: true,
      debug: false,
    };
    ReactPixel.init(config.FACEBOOK_PIXEL_ID, advancedMatching, options);
    ReactPixel.pageView(window.location.pathname + window.location.search);
  }

  return (
    <Provider store={store}>
      <Router>
        <GlobalStyle />
        <ScrollToTop />
        <Switch>
          <Route exact path={webUrl.home} component={Home} />
          <Route exact path={webUrl.homeUS} component={HomeUS} />
          <Route exact path={webUrl.homeIN} component={HomeIN} />
          <Route path={webUrl.about} component={About} />
          <Route path={webUrl.terms} component={Privacy} />
          <Route path={webUrl.privacyPolicy} component={Terms} />
          <Route path={webUrl.contact} component={Contact} />
          <Route path={webUrl.faq} component={Faq} />
          <Route path={webUrl.blog} component={BlogClassic} />
          <Route path={webUrl.blogDetails} component={BlogDetails} />
          <Route
            path={webUrl.studyMaterial}
            component={StudyMaterial}
            exact={true}
          />
          <Route path={webUrl.teacher} component={Teacher} exact={true} />
          <Route
            path={webUrl.examPreparatory}
            component={ExamPreparatory}
            exact={true}
          />
          <Route path={webUrl.internship} component={Internship} exact={true} />
          <Route
            path={webUrl.offerings + "/:selected"}
            component={Offerings}
            exact={true}
          />
          <Route
            path={webUrl.teacherCampaign}
            component={TeacherCampaign}
            exact={true}
          />
          <Route
            path={webUrl.teacherCampaignSubmit}
            component={ThankYou}
            exact={true}
          />
          <Route path={webUrl.partner} component={Partner} exact={true} />
          <Route path={webUrl.school} component={School} exact={true} />
          <Route path={webUrl.robotic} component={Robotic} exact={true} />
          <Route
            path={webUrl.sampleModule}
            component={ModuleSample}
            exact={true}
          />
          <Route
            path={webUrl.sampleModule2}
            component={ModuleSample}
            exact={true}
          />
          <Route
            path={`${webUrl.embedChapterTheory + "/:id"}`}
            component={ChapterTheory}
            exact={true}
          />
          <Route
            path={`${webUrl.embedChapterExercise + "/:id"}`}
            component={ChapterExercise}
            exact={true}
          />
          <Route
            path={`${webUrl.embedChapterVideo + "/:id/:vid"}`}
            component={ChapterVideo}
            exact={true}
          />
          <Route
            path={`${webUrl.embedModuleTheory + "/:id"}`}
            component={ModuleTheory}
            exact={true}
          />
          <Route
            path={`${webUrl.embedModuleConcept + "/:id/:vid"}`}
            component={ModuleConcept}
            exact={true}
          />
          <Route
            path={`${webUrl.embedModuleProgramming + "/:id/:vid"}`}
            component={ModuleProgramming}
            exact={true}
          />
          <Route
            path={`${webUrl.embedModuleHardwareBuilding + "/:id/:vid"}`}
            component={ModuleHardwareBuilding}
            exact={true}
          />
          <Route
            path={`${webUrl.embedModuleSoftwareBuilding + "/:id/:vid"}`}
            component={ModuleSoftwareBuilding}
            exact={true}
          />
          <Route
            path={`${webUrl.embedModuleCoreFundamentals + "/:id"}`}
            component={ModuleCoreFundamentals}
            exact={true}
          />
          <Route
            path={`${webUrl.embedModuleSoftwareSetup + "/:id"}`}
            component={ModuleSoftwareSetup}
            exact={true}
          />
          <Route
            path={`${webUrl.embedVideo}`}
            component={EmbedVideo}
            exact={true}
          />
          <Route
            path={`${webUrl.courseListUs}`}
            component={courseListUs}
            exact={true}
          />
          <Route
            path={`${webUrl.courseDetailsUs + "/:id"}`}
            component={CourseDetails}
            exact={true}
          />

          {/* Student / Teacher Routes */}
          <PrivateRoute
            path={webUrl.userDashboard}
            component={Dashboard}
            exact={true}
          />
          <PrivateRoute
            path={`${webUrl.subjectDetails + "/:type"}`}
            component={Subject}
            exact={true}
          />
          <PrivateRoute
            path={`${
              webUrl.subjectDetails + "/:type" + webUrl.userUSIntroduction
            }`}
            component={Introduction}
            exact={true}
          />
          <PrivateRoute
            path={`${webUrl.subjectDetails + "/:type" + webUrl.userUSUnits}`}
            component={Units}
            exact={true}
          />
          <PrivateRoute
            path={`${
              webUrl.subjectDetails + "/:type" + webUrl.userUSAdvanceMission
            }`}
            component={Missions}
            exact={true}
          />
          <PrivateRoute
            path={`${
              webUrl.subjectDetails + "/:type" + webUrl.userUSCeritificate
            }`}
            component={Certificate}
            exact={true}
          />
          <PrivateRoute
            path={`${webUrl.chapterDetails + "/:type"}`}
            component={Chapter}
            exact={true}
          />
          <PrivateRoute
            path={`${webUrl.startChapterQuiz + "/:type"}`}
            component={ChapterQuiz}
            exact={true}
          />
          <PrivateRoute
            path={`${webUrl.chapterQuizReport + "/:type"}`}
            component={ChapterQuizReport}
            exact={true}
          />
          <PrivateRoute
            path={`${webUrl.moduleDetails + "/:type"}`}
            component={Module}
            exact={true}
          />
          <PrivateRoute
            path={`${webUrl.startModuleQuiz + "/:type"}`}
            component={ModuleQuiz}
            exact={true}
          />
          <PrivateRoute
            path={`${webUrl.moduleQuizReport + "/:type"}`}
            component={ModuleQuizReport}
            exact={true}
          />
          <PrivateRoute
            path={`${webUrl.packageList + "/:type"}`}
            component={Package}
            exact={true}
          />
          <PrivateRoute
            path={`${webUrl.cartList}`}
            component={Cart}
            exact={true}
          />
          <PrivateRoute
            path={`${webUrl.orderDetails + "/:type"}`}
            component={OrderDetail}
            exact={true}
          />
          <PrivateRoute
            path={webUrl.userSubscriptions}
            component={Subscriptions}
            exact={true}
          />
          <PrivateRoute
            path={webUrl.userTransactions}
            component={Transactions}
            exact={true}
          />
          <PrivateRoute
            path={webUrl.userNotifications}
            component={Notifications}
            exact={true}
          />
          <PrivateRoute
            path={webUrl.userProfile}
            component={Profile}
            exact={true}
          />
          <PrivateRoute
            path={webUrl.liveClasses}
            component={LiveClass}
            exact={true}
          />
          <PrivateRoute
            path={webUrl.recordedSessions}
            component={RecordedSession}
            exact={true}
          />
          <PrivateRoute
            path={webUrl.teacherTechnologyKit}
            component={TeacherTechnologyKit}
            exact={true}
          />
          <PrivateRoute
            path={`${webUrl.teacherTechnologyKitDetails + "/:type"}`}
            component={TTKDetails}
            exact={true}
          />
          <PrivateRoute
            path={webUrl.testGeneration}
            component={GeneratedTestsList}
            exact={true}
          />
          <PrivateRoute
            path={webUrl.generateNewTest}
            component={NewTest}
            exact={true}
          />

          {/* Olympiad Routes */}
          <Route
            path={webUrl.olympiadLogin}
            component={OlympiadLogin}
            exact={true}
          />
          <Route
            path={webUrl.olympiadWelcome}
            component={OlympiadWelcome}
            exact={true}
          />
          <Route
            path={`${webUrl.startOlympiadQuiz + "/:type"}`}
            component={Olympiad}
            exact={true}
          />
          <Route
            path={webUrl.olympiadThankyou}
            component={OlympiadThankyou}
            exact={true}
          />
          <Route
            path={webUrl.paymentSuccess}
            component={paymentSuccess}
            exact={true}
          />
          <Route
            path={webUrl.paymentFailure}
            component={paymentFailure}
            exact={true}
          />

          {/* Partner Dashboard Routes */}
          <Route
            path={`${webUrl.partnerLoginAdmin + "/:hash"}`}
            component={PartnerLoginAdmin}
            exact={true}
          />
          <PrivateRoute
            path={webUrl.partnerDashboard}
            component={PartnerDashboard}
            exact={true}
          />
          <PrivateRoute
            path={webUrl.studentsList}
            component={StudentList}
            exact={true}
          />
          <PrivateRoute
            path={`${webUrl.studentPackages + "/:type"}`}
            component={StudentPackage}
            exact={true}
          />
          <PrivateRoute
            path={webUrl.teachersList}
            component={TeacherList}
            exact={true}
          />
          <PrivateRoute
            path={`${webUrl.teacherPackages + "/:type"}`}
            component={TeacherPackage}
            exact={true}
          />
          <PrivateRoute
            path={webUrl.schoolsList}
            component={SchoolList}
            exact={true}
          />
          <PrivateRoute
            path={webUrl.partnerWallet}
            component={Wallet}
            exact={true}
          />
          {/* <PrivateRoute path={webUrl.partnerCoupons} component={Coupon} exact={true} /> */}
          {/* <PrivateRoute path={webUrl.partnerCommissions} component={Commission} exact={true} /> */}
          <PrivateRoute
            path={webUrl.promotionalMaterials}
            component={PromotionalMaterial}
            exact={true}
          />
          <PrivateRoute
            path={webUrl.referCode}
            component={ReferCode}
            exact={true}
          />
          <PrivateRoute
            path={webUrl.partnerNotifications}
            component={PartnerNotification}
            exact={true}
          />
          <PrivateRoute
            path={webUrl.partnerEnquiry}
            component={PartnerEnquiry}
            exact={true}
          />

          <Route path="*" component={PageNotFound} />
        </Switch>
        {/* <Loader /> */}
      </Router>
    </Provider>
  );
}

export default App;
