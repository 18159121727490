import React from "react";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import { Container, Row, Col } from "react-bootstrap";
import MobileMenu from "./common/MobileMenu";
import { Styles } from "./styles/userHeader";

import common from "../shared/common";
import { webUrl } from "../shared/constants";
import Connect from "../pages/connect/index.js";
import PhoneOutlinedIcon from "@mui/icons-material/PhoneOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import AssignmentOutlinedIcon from "@mui/icons-material/AssignmentOutlined";

const PartnerHeader = (props) => {
  const partner = JSON.parse(common.getFromStorage("partner"));

  const partnerLogo =
    partner.partner && partner.partner.profile_photo_url !== ""
      ? partner.partner.profile_photo_url
      : process.env.PUBLIC_URL + "/assets/images/logo2.webp";
  return (
    <Styles>
      {/* Topbar 2 */}
      <section className="top-bar2">
        <Container>
          <Row>
            <Col lg="7" md="9">
              <div className="bar-left">
                <ul className="list-unstyled list-inline">
                  <li className="list-inline-item">
                    <PhoneOutlinedIcon />
                    (931) 009 1906
                  </li>
                  <li className="list-inline-item">
                    <a href="mailto:info@ealyss.com">
                      <EmailOutlinedIcon />
                      info@ealyss.com
                    </a>
                  </li>
                  <li className="list-inline-item">
                    <Link id={"req-btn"} to={process.env.PUBLIC_URL + "#"}>
                      <AssignmentOutlinedIcon />
                      Request a call
                    </Link>
                    <Connect openCommand={true} commandCtrlId={"req-btn"} />
                  </li>
                </ul>
              </div>
            </Col>
            <Col lg="5" md="3">
              <div className="bar-right d-flex justify-content-end"></div>
            </Col>
          </Row>
        </Container>
      </section>

      {/* Logo Area 2 */}
      <section className="logo-area2">
        <Container>
          <Row>
            <Col md="3" sm="0">
              <Row style={{ justifyContent: "center" }}>
                <div className="logo">
                  <Link to={webUrl.partnerDashboard} style={{ textAlign: "center" }}>
                    <img src={partnerLogo} alt="" style={{ maxHeight: "56px" }} />
                  </Link>
                </div>
              </Row>
            </Col>

            <Col md="6">
              {/* <div className="bar-right d-flex justify-content-end">
                <ul className="list-unstyled list-inline bar-user">
                  <li className="list-inline-item">
                    <a target="_blank" rel="noopener noreferrer" href="https://play.google.com/store/apps/details?id=com.onlinestudy.alyssmission">
                      <InstallMobileOutlinedIcon />
                      Download App
                    </a>
                  </li>
                </ul>
              </div> */}
            </Col>
            <Col md="3">
              <Row style={{ justifyContent: "end" }}>
                <div className="logo">
                  <Link to={webUrl.home}>
                    <img src={process.env.PUBLIC_URL + "/assets/images/logo2.webp"} alt="" />
                  </Link>
                </div>
              </Row>
            </Col>
          </Row>
        </Container>
      </section>

      {/* Sticky Menu */}
      {/* <StickyMenu /> */}

      {/* Mobile Menu */}
      <MobileMenu />
    </Styles>
  );
};

const mapStateToProps = (state) => ({
  cartCount: state.cart.data.cart.items_count || 0,
});

const mapDispatchToProps = (dispatch) => ({});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PartnerHeader));
