import React, { Component } from "react";
import Datas from "../data/about-us/about-us.json";
import { Container, Row, Col } from "react-bootstrap";
import CountUp from "react-countup";
import { Styles } from "./styles/imageContent.js";

class ImageContent extends Component {
  render() {
    return (
      <Styles>
        {/* About Us */}
        <section className="about-us">
          <Container>
            <Row>
              <Col md="6">
                <div className="about-image">
                  <img src={process.env.PUBLIC_URL + `/assets/images/${Datas[0].mainImage}`} className="main-img" alt="" />
                  <img src={process.env.PUBLIC_URL + "/assets/images/about/pattern.webp"} className="pattern-img" alt="" />
                </div>
              </Col>
              <Col md="6">
                <div className="about-content">
                  <h4 className="about-title">{Datas[0].title}</h4>
                  <p className="about-para">
                    {Datas[0].desc1}
                    <span>{Datas[0].desc2}</span>
                  </p>
                  <Row>
                    <Col sm="4">
                      <div className="counter-box box1 text-center">
                        <h3>
                          <CountUp end={970} duration={5} delay={1.5} />
                          <i className="las la-plus"></i>
                        </h3>
                        <p>Happy Students</p>
                      </div>
                    </Col>
                    <Col sm="4">
                      <div className="counter-box box2 text-center">
                        <h3>
                          <CountUp end={130} duration={5} delay={1.5} />
                          <i className="las la-plus"></i>
                        </h3>
                        <p>Teachers</p>
                      </div>
                    </Col>
                    <Col sm="4">
                      <div className="counter-box box3 text-center">
                        <h3>
                          <CountUp end={340} duration={5} delay={1.5} />
                          <i className="las la-plus"></i>
                        </h3>
                        <p>Courses</p>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </Styles>
    );
  }
}

export default ImageContent;
