import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import FooterTwo from "../../components/FooterTwo";
import { webUrl } from "../../shared/constants";
import { Styles } from "./styles";
import Typography from "@mui/material/Typography";
import ModalVideo from "react-modal-video";
import common from "../../shared/common";
import HeaderTwo from "../../components/HeaderTwo";
import VideoSlider from "../../components/VideoSlider";
import ButtonArea from "../../components/ButtonArea";
import CustomerSlider from "../../components/CustomerSlider";
import { Grid } from "@mui/material";
import User from "../user";
import CountUp from "react-countup";
import ExpertTeacherSlider from "../../components/ExpertTeacherSlider";
import InternshipForm from "../../components/InternshipForm";

const Internship = (props) => {
  const idPref = Math.random();
  const idPref2 = Math.random();
  const idPref3 = Math.random();
  const onClickJoinNow = () => {
    if (!!common.getFromStorage("userName")) {
      props.history.push(webUrl.userDashboard);
    }
  };

  const [selectedVideoId, setSelectedVideoId] = useState("394367036");

  const [isOpen, setIsOpen] = useState(false);

  const openVideoModal = (data) => {
    setSelectedVideoId(data);
    setIsOpen(true);
  };

  return (
    <Styles>
      <div className="main-wrapper">
        {/* Header */}
        <HeaderTwo {...props} />

        <div className="bg-banner">
            <div className="overlay-bg">
              <div className="left-text">Discover the endless possibilities of robotics - start learning today!</div>
              <div className="right-text">
                <div className="para-left">Learn Robotics with us and discover the limitless potential of this crucial and rapidly growing field</div>
                <div className="para-right">Robotics is an increasingly important fields that offers diverse career opportunities across industries.</div>
              </div>
              <button className="btn btn-primary join-now-banner" id={"hero-btn" + idPref} onClick={onClickJoinNow}>Join Now</button>
              <User openCommand={true} commandCtrlId={"hero-btn" + idPref}  />
            </div>
        </div>
        <div className="counters-block">
          <ul>
            <li>
              <div className="counter-box box1 text-center">
                  <h3>
                    <CountUp end={135} duration={3} delay={1.5} />
                    <i className="las la-plus"></i>
                  </h3>
                  <p>Exclusive Professors</p>
              </div>
            </li>
            <li>
              <div className="counter-box box1 text-center">
                  <h3>
                    <CountUp end={10357} duration={3} delay={1.5} />
                    <i className="las la-plus"></i>
                  </h3>
                  <p>Study Hours</p>
              </div>
            </li>
            <li>
              <div className="counter-box box1 text-center">
                  <h3>
                    <CountUp end={1257} duration={3} delay={1.5} />
                    <i className="las la-plus"></i>
                  </h3>
                  <p>Supported Materials</p>
              </div>
            </li>
            <li>
              <div className="counter-box box1 text-center">
                  <h3>
                    <CountUp end={4865} duration={3} delay={1.5} />
                    <i className="las la-plus"></i>
                  </h3>
                  <p>Active Students</p>
              </div>
            </li>
            <li>
              <div className="counter-box box1 text-center">
                  <h3>
                    <CountUp end={3273} duration={3} delay={1.5} />
                    <i className="las la-plus"></i>
                  </h3>
                  <p>Certificates</p>
              </div>
            </li>
          </ul>
        </div>
        

        <div className="container mid-section py-5 text-center">
          <h3 className="big-title py-3">How Alyss will Assist you</h3>
          <p className="text-large">
            Our Company provides the tools, resources and expertise you need to build a successful career in the dynamic and exciting field of robotics
          </p>
        </div>
        <div className="container mid-section py-5">
          <Grid container spacing={2} className="robotics-expert">
            <Grid sm={6} className="px-5 py-3">
              <img src={process.env.PUBLIC_URL + `/assets/images/exam_preparatory/collage-city-committed-education.webp`} alt="Explanation" />
              <button
                onClick={(e) => {
                  e.preventDefault();
                  openVideoModal("784759492");
                }}
                className="play-button"
              >
                <i className="fas fa-play"></i>
              </button>
            </Grid>
            <Grid sm={6} className="px-5 py-3">
              <Typography variant="h3" className="py-2">
              STEM ROBOTICS COURSE OVERVIEW
              </Typography>
              <Typography variant="p" className="py-5 text-large">
                <ul>
                  <li>Introduction to EV3 and Spike Prime Robotics Kit</li>
                  <li>Hands-on experience </li>
                  <li>Build a robot using our RoboSimulator</li>
                  <li>STEM led Curriculum</li>
                  <li>Make your robot smarter using different sensors</li>
                  <li>Intuitive Native Software: GUI Based and MicroPython</li>
                  <li>Solving Simple and Complex Missions</li>
                  <li>Learn Artificial Intelligence and Machine Learning</li>
                  <li>Build Competitive Robots from scratch!</li>
                  <li>Exciting Projects</li>
                </ul>
              </Typography>
              <p>
                <button className="btn btn-primary btn-round btn-round2 my-5" id={"hero-btn" + idPref2} onClick={onClickJoinNow}>
                  Learn More
                </button>
              </p>
              <User openCommand={true} commandCtrlId={"hero-btn" + idPref2}  />
            </Grid>
          </Grid>
        </div>
        <div className="container mid-section py-5">
          <Grid container spacing={2} className="robotics-expert">
            <Grid sm={6} className="px-5 py-3">
              <Typography variant="h3" className="py-5">
              INTERNSHIP AT ALYSS/PARTNERED ORGANIZATIONS
              </Typography>
              <p className="my-2">
              Practical learning: Robotics internship at Alyss provides students and graduates with the opportunity to gain practical, hands-on experience in the field of robotics. By working on real robotics projects, interns can develop valuable skills and knowledge that will make them more competitive in the job market and more effective in their future robotics careers.</p>
              <p className="my-2">Industry connections: Internship at alyss provide students and graduates with the opportunity to network with professionals in the robotics industry, which can give them an edge in their future robotics careers.</p>
              <p className="my-2">Innovation and growth: Robotics is a rapidly evolving field, and providing internships can help drive innovation and growth in the industry Alyss can help ensure that the industry continues to grow and thrive.
              </p>
              <p>
                <button className="btn btn-primary btn-round btn-round2 my-5" id={"hero-btn" + idPref3} onClick={onClickJoinNow}>
                  Learn More
                </button>
              </p>
              <User openCommand={true} commandCtrlId={"hero-btn" + idPref3}  />
            </Grid>
            <Grid sm={6} className="px-5 py-3">
              <img src={process.env.PUBLIC_URL + `/assets/images/exam_preparatory/students_interns.webp`} alt="Explanation" />
              <button
                onClick={(e) => {
                  e.preventDefault();
                  openVideoModal("716694942");
                }}
                className="play-button play-button2"
              >
                <i className="fas fa-play"></i>
              </button>
            </Grid>
          </Grid>
        </div>

        {/* Testimonial Slider */}
        <ExpertTeacherSlider />

        <InternshipForm />

        <CustomerSlider />

        <VideoSlider />

        {/* Button Area */}
        <ButtonArea buttonText="Join Now" onHeroButtonClick={onClickJoinNow} showPopup={!!!common.getFromStorage("userName")} />

        {/* Footer */}
        <FooterTwo />
      </div>
      <ModalVideo channel="vimeo" isOpen={isOpen} videoId={selectedVideoId} onClose={() => setIsOpen(false)} />
    </Styles>
  );
};

const mapStateToProps = (state) => ({
});

const mapDispatchToProps = (dispatch) => ({

});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Internship));
