import { actionTypes } from "./action";

/** Initial state */
const initialState = {
  data: {
    masterData: {},
    categories: [],
    learnings: [],
    recommended: [],
    user: {},
    recordedSessionData: {},
  },
  formData: {},
  error: null,
};

/** Reducers */
export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case actionTypes.RECORDED_SESSIONS_DATA_REQUEST_SUCCESS:
      let newformData = state.formData;
      const categories = payload.data.categories;
      const category_id = (categories.length && categories[0] && categories[0].id) || null;
      if (!state.formData.category_id && category_id) {
        const subcategories = categories[0].subcategories;
        const subcategory_id = (subcategories.length && subcategories[0] && subcategories[0].id) || null;
        if (subcategory_id) {
          const subsubcategories = subcategories[0].subsubcategories;
          const subsubcategory_id = (subsubcategories.length && subsubcategories[0] && subsubcategories[0].id) || null;
          newformData = { subsubcategory_id, subcategory_id, category_id };
        } else {
          newformData = { subcategory_id, category_id };
        }
      }
      return {
        ...state,
        data: {
          ...state.data,
          categories: payload.data.categories,
          recordedSessions: payload.data.recordedSessions,
          user: payload.data.user,
        },
        formData: {
          ...newformData,
        },
      };
    case actionTypes.RECORDED_SESSION_CHECK_DATA_REQUEST_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          recordedSessionData: payload.data.live_class,
        }
      };

    case actionTypes.SET_FORM_DATA:
      return {
        ...state,
        formData: {
          ...state.formData,
          ...payload,
        },
      };

    default:
      return state;
  }
};
