import { showLoaderAction, stopLoaderAction } from "../user/action";
import { newRequest } from "./service";

/**
 * Action types
 */
export const actionTypes = {
};

export const newRequestAction = (data) => {
  return (dispatch) => {

    dispatch(showLoaderAction("newRequest"));

    return newRequest(data)
      .then((response) => {

        dispatch(stopLoaderAction("newRequest"));

        if (response && response.access_token) {
          localStorage.setItem("token_connect", response.access_token);
          return true;
        } else {
          return false;
        }
      })
      .catch((error) => {

        dispatch(stopLoaderAction("newRequest"));
        return false;
      });
  };
};