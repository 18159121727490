import { doPostRequest } from "../../shared/api/base-api";
import { apiUrl } from "../../shared/constants";

// Chapter Quiz Start Api
export function startChapterQuiz(data) {
   return doPostRequest(apiUrl.startChapterQuiz, data);
}

// Chapter Quiz Submit Api
export function submitChapterQuiz(data) {
   return doPostRequest(apiUrl.submitChapterQuiz, data);
}