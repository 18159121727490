import React, { Fragment } from "react";
import { Col, Row } from "react-bootstrap";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import common from "../../../shared/common";
import { webUrl } from "../../../shared/constants";
import { Button, Card, CardContent, Grid, Table, Typography } from "@mui/material";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import { Styles } from "../styles/transactions";

const ItemGrid = (props) => {
  return (
    <Styles>
      <Fragment>
        {/* Course Item */}
        <Row>
          <Col>
            <h5 className="grid-header ml-0">My Transactions</h5>
          </Col>
        </Row>

        <Row>
          <Col lg="12" md="12">
            <Grid spacing={2}>
              {(props.data &&
                props.data.length > 0 &&
                props.data.map((data, i) => (
                  <Card key={i} sx={{ marginBottom: "10px" }}>
                    <CardContent>
                      <Table className="table">
                        <tbody>
                          <tr>
                            <td>
                              <Typography className="table-text">
                                <strong>Order #: </strong>
                                {data.order_code}
                              </Typography>
                            </td>
                            <td>
                              <Typography className="table-text">
                                <strong>Order Date: </strong>
                                {common.getISTFormattedDate(data.checked_out_at)}
                              </Typography>
                            </td>
                            <td rowSpan={2} align="right">
                              <Button
                                className="view-order-details"
                                startIcon={<RemoveRedEyeOutlinedIcon />}
                                variant="contained"
                                onClick={(e) => {
                                  e.preventDefault();
                                  props.redirectTo(webUrl.orderDetails + "/" + data.order_code);
                                }}
                              >
                                View
                              </Button>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <Typography className="table-text">
                                <strong>Order Total: </strong>
                                {data.currency === "INR" ? <CurrencyRupeeIcon /> : <AttachMoneyIcon />}
                                {data.payable_amount}
                              </Typography>
                            </td>
                            <td>
                              <Typography className="table-text capitalize">
                                <strong>Order Status: </strong>
                                {data.order_status}
                              </Typography>
                            </td>
                          </tr>
                          {data.transactions && data.transactions.length > 0 && data.payment_status === "paid" && (
                            <tr>
                              <td>
                                <Typography className="table-text capitalize">
                                  <strong>Payment Status: </strong>
                                  {data.payment_status}
                                </Typography>
                              </td>
                              <td>
                                <Typography className="table-text">
                                  <strong>Payment Mode: </strong>
                                  {data.transactions[0].payment_mode}
                                </Typography>
                              </td>
                              <td></td>
                            </tr>
                          )}
                        </tbody>
                      </Table>
                    </CardContent>
                  </Card>
                ))) || <p className="not-found">No Transaction found</p>}
            </Grid>
          </Col>
        </Row>
      </Fragment>
    </Styles>
  );
};

export default ItemGrid;
