import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Row, Col, Container } from "react-bootstrap";
import Link from "@mui/material/Link";
// import { Styles } from "../styles/subject.js";
import { Styles } from "../../dashboard/styles/dashboard.js";
import UserHeader from "../../../components/UserHeader.js";
import USCourseMenu from "../../dashboard/components/USCourseMenu.js";
import LoaderNew from "../../../components/LoaderNew.js";
import FooterTwo from "../../../components/FooterTwo.js";
import { subjectDetailsAction } from "../action.js";
import { webUrl } from "../../../shared/constants.js";
// import USDashboard from "./USDashboard.js";

function Introduction(props) {
  const {
    match: { params },
  } = props;
  const [snackPack, setSnackPack] = useState([]);
  const [open, setOpen] = useState(false);
  const [messageInfo, setMessageInfo] = useState(undefined);

  useEffect(() => {
    if (snackPack.length && !messageInfo) {
      // Set a new snack when we don't have an active one
      setMessageInfo({ ...snackPack[0] });
      setSnackPack((prev) => prev.slice(1));
      setOpen(true);
    } else if (snackPack.length && messageInfo && open) {
      // Close an active snack when a new one is added
      setOpen(false);
    }
  }, [snackPack, messageInfo, open]);

  const handleSnackbar = (message) => {
    return setSnackPack((prev) => [
      ...prev,
      { message, key: new Date().getTime() },
    ]);
  };
  let subjectName = props?.subsubcategory?.name || "";
  let isUSVersion =
    props?.subsubcategory?.subcategory?.category?.is_us_version || 0;
  useEffect(() => {
    props.subjectDetails([params.type]); // To do: params.type should be default one
  }, []);
  let modules = [];
  const images = [
    "/assets/images/module/us-page-sections/introduction/intro1.webp",
    "/assets/images/module/us-page-sections/introduction/intro2.webp",
    "/assets/images/module/us-page-sections/introduction/intro3.webp",
  ];
  if (props.subsubcategory.modules.length > 0) {
    for (let i = 0; i < 3; i++) {
      modules.push(props.subsubcategory.modules[i]);
    }
  }
  return (
    <div className="main-wrapper course-details-page">
      {/* User Header */}
      <UserHeader {...props} />

      <Styles>
        {/* Course Grid */}
        <section className="dashboard-grid-area">
          <Container>
            <Row>
              <Col lg="3" md="3" sm="12">
                {isUSVersion == 1 ? (
                  <USCourseMenu {...props} index="1" />
                ) : (
                  <></>
                )}

                {props.subsubcategory.can_user_access === 1
                  ? ""
                  : props.showLoader.length === 0 && (
                      <div
                        className="course-details-area"
                        style={{ padding: "10px 0" }}
                      >
                        <div className="single-details-sidbar">
                          <div
                            className="course-details-feature"
                            style={{ padding: 0 }}
                          >
                            <Col sm="12">
                              <button
                                type="button"
                                onClick={() =>
                                  props.history.push(
                                    process.env.PUBLIC_URL +
                                      "/package/" +
                                      params.type
                                  )
                                }
                                className="enroll-btn"
                                style={{ margin: "20px 0" }}
                              >
                                Buy Now
                              </button>
                            </Col>
                          </div>
                        </div>
                      </div>
                    )}
              </Col>
              {props.showLoader && props.showLoader.length > 0 && <LoaderNew />}
              {props.showLoader.length === 0 && isUSVersion == 1 ? (
                <>
                  <Col className="usSectionHeading">
                    <Row>
                      <Col lg="12" md="12" sm="12">
                        {props.showLoader.length === 0 && (
                          <h5>Introduction and Building Instructions</h5>
                        )}
                      </Col>
                    </Row>
                    <Row>
                      <Col className="usDashboard p-0 mt-1">
                        <section className="course-details-area">
                          <Row className="course-details-area">
                            {modules.length > 0 &&
                              modules.map((data, i) => (
                                <Col lg="6" md="6" sm="12" key={i}>
                                  <Link
                                    underline="none"
                                    href="#"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      if (
                                        props.can_user_access === 0 &&
                                        data.allow_free_access === 0
                                      ) {
                                        handleSnackbar(
                                          "Please subscribe first to get access to this module"
                                        );
                                      } else {
                                        props.history.push(
                                          process.env.PUBLIC_URL +
                                            "/module-details/" +
                                            data.id
                                        );
                                      }
                                    }}
                                  >
                                    <div className="course-item no-transition">
                                      <div
                                        className="course-image"
                                        style={{
                                          backgroundImage: `url(${images[i]})`,
                                        }}
                                      >
                                        <div className="course-price"></div>
                                      </div>
                                      <div
                                        className="course-content"
                                        style={{ paddingTop: 0 }}
                                      >
                                        <h6 className="section-heading">
                                          <span className="text-yellow">
                                            {i + 1}
                                          </span>{" "}
                                          {data.display_name}
                                        </h6>
                                      </div>
                                    </div>
                                  </Link>
                                </Col>
                              ))}
                          </Row>
                        </section>
                      </Col>
                    </Row>
                  </Col>
                </>
              ) : (
                <></>
              )}
            </Row>
          </Container>
        </section>
      </Styles>

      {/* Footer 2 */}
      <FooterTwo />
    </div>
  );
}

const mapStateToProps = (state) => ({
  subsubcategory: state.subject.data.subsubcategory,
  showLoader: state.user.showLoader,
});

const mapDispatchToProps = (dispatch) => ({
  subjectDetails: (data) => dispatch(subjectDetailsAction(data)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Introduction)
);
