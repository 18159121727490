import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Container, Row, Col } from "react-bootstrap";
import ItemGrid from "./components/ItemsGrid";
import FooterTwo from "../../components/FooterTwo";
import { Styles } from "../dashboard/styles/dashboard.js";
import UserHeader from "../../components/UserHeader";
import DashboardMenu from "../dashboard/components/DashboardMenu";
import { notificationsListDataAction } from "./action";
import LoaderNew from "../../components/LoaderNew";

const Notifications = (props) => {
    useEffect(() => {
        props.notificationData();
    }, []);

    return (
      <div className="main-wrapper course-page">
        {/* User Header */}
        <UserHeader index={5} />

        <Styles>
          {/* Course Grid */}
          <section className="dashboard-grid-area">
            <Container>
              <Row>
                <Col lg="3" md="3" sm="12">
                  <DashboardMenu index={5} />
                </Col>
                <Col>
                  {props.showLoader && props.showLoader.length > 0 && <LoaderNew />}
                  {props.showLoader.length === 0 && (
                    <Row>
                      <Col lg="12" md="12" sm="12">
                        <div className="course-items">
                          <ItemGrid data={props.notifications} />
                        </div>
                      </Col>
                    </Row>
                  )}
                </Col>
              </Row>
            </Container>
          </section>
        </Styles>

        {/* Footer 2 */}
        <FooterTwo />
      </div>
    );
};

const mapStateToProps = (state) => ({
    notifications: state.notification.data.notifications,
    showLoader: state.user.showLoader,
});

const mapDispatchToProps = (dispatch) => ({
    notificationData: (data) => dispatch(notificationsListDataAction(data)),
});

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(Notifications)
);
