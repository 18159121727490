import { actionTypes } from './action';

/** Initial state */
const initialState = {
    data: {
    }
};

/** Reducers */
export default (state = initialState, action) => {

    const { type, payload } = action;

    switch (type) {
        case actionTypes.START_MODULE_QUIZ_REQUEST_SUCCESS:
            return {
                ...state,
                data: {
                    ...state.data,
                    module_quiz: payload.data
                }
            };
        case actionTypes.SUBMIT_MODULE_QUIZ_REQUEST_SUCCESS:
            return {
                ...state,
                data: {
                    ...state.data,
                    submit_quiz: payload.data
                }
            };

        default:
            return state;
    }
};
