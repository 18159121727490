import { doGetRequest } from "./../../shared/api/base-api";
import { apiUrl } from "./../../shared/constants";
import common from "./../../shared/common";

//exam Course Plans data api
export function getUsCoursePlansData(data) {
  return doGetRequest(common.stringformat(apiUrl.usCourseList, data));
}
export function getUsCourseDetailPlansData(data) {
  return doGetRequest(common.stringformat(apiUrl.usCourseDetail, data));
}