import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Container, Row, Col } from "react-bootstrap";
import ProfileDetail from "./components/ProfileDetail";
import FooterTwo from "../../components/FooterTwo";
import { Styles } from "../dashboard/styles/dashboard.js";
import UserHeader from "../../components/UserHeader";
import DashboardMenu from "../dashboard/components/DashboardMenu";
import EditProfile from "./components/EditProfile";

const UserProfile = (props) => {
  const [field, setField] = useState(null);
  const editField = (fieldName) => {
    setField(fieldName);
  };

  return (
    <div className="main-wrapper course-page">
      {/* User Header */}
      <UserHeader index={6} />

      <Styles>
        {/* Course Grid */}
        <section className="dashboard-grid-area">
          <Container>
            <Row>
              <Col lg="3" md="3" sm="12">
                <DashboardMenu index={6} />
              </Col>
              <Col>
                <Row>
                  <Col lg="12" md="12" sm="12">
                    <div className="course-items">
                      <ProfileDetail {...props} editField={editField} />
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </section>
        <EditProfile field={field} {...props} setField={setField} />
      </Styles>

      {/* Footer 2 */}
      <FooterTwo />
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.dashboard.data.user,
  categories: state.dashboard.data.categories,
});

const mapDispatchToProps = (dispatch) => ({});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UserProfile));
