import React, { Component } from "react";
import { Container, Row, Col } from "react-bootstrap";
import User from "../pages/user/index.js";
import { Styles } from "./styles/buttonArea.js";
import Connect from "../pages/connect/index.js";

class
  ButtonEnRoll extends Component {
  render() {
    const idPref = Math.random();
    const bgColor = this.props.bgColor ? this.props.bgColor : "#fff";
    const clsname = this.props.clsName ? this.props.clsName :null
    return (
      <Styles>
       
            <button id={"hero-btn" + idPref} className={clsname}  onClick={this.props.onHeroButtonClick}>
              <span> {this.props.buttonText}</span>
            </button>
            {this.props.showPopup ? (
              this.props.connect ? (
                <Connect openCommand={true} commandCtrlId={"hero-btn" + idPref} />
              ) : (
                <User openCommand={true} commandCtrlId={"hero-btn" + idPref} />
              )
            ) : (
              ""
            )}
        
       
      </Styles>
    );
  }
}

export default ButtonEnRoll;
