import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Row, Col } from "react-bootstrap";
import Swiper from "react-id-swiper";
import FooterTwo from "../../components/FooterTwo";
import { webUrl } from "../../shared/constants";
import { Styles } from "./styles";
import Typography from "@mui/material/Typography";
import ModalVideo from "react-modal-video";
import common from "../../shared/common";
import HeaderTwo from "../../components/HeaderTwo";
import VideoSlider from "../../components/VideoSlider";
import ButtonArea from "../../components/ButtonArea";
import CustomerSlider from "../../components/CustomerSlider";
import CheckIcon from "@mui/icons-material/Check";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import { Grid } from "@mui/material";
import { examCoursePlansDataAction } from "./action";
import { addToCartAction } from "../package/action";
import User from "../user";

const ExamPreparatory = (props) => {
  const idPref = Math.random();
  const idPref2 = Math.random();
  const idPref3 = Math.random();
  const onClickJoinNow = () => {
    if (!!common.getFromStorage("userName")) {
      props.history.push(webUrl.userDashboard);
    }
  };
  useEffect(() => {
    props.coursePlansData([]);
  }, []);
  const _packages = props.examCoursePlans;
  const isInr = true;

  const [selectedVideoId, setSelectedVideoId] = useState("394367036");

  const [isOpen, setIsOpen] = useState(false);
  const settings = {
    slidesPerView: 3,
    loop: true,
    speed: 1000,
    spaceBetween: 30,
    watchSlidesVisibility: false,
    pagination: {
      el: ".slider-dot.text-center",
      clickable: true,
    },
    breakpoints: {
      0: {
        slidesPerView: 1,
      },
      576: {
        slidesPerView: 1,
      },
      768: {
        slidesPerView: 2,
      },
      992: {
        slidesPerView: 3,
      },
    },
  };

  const openVideoModal = (data) => {
    setSelectedVideoId(data);
    setIsOpen(true);
  };

  const onClickAddToCart = (e, selectedIndex) => {
    e.preventDefault();
    const subscriptionId = _packages[selectedIndex].subscription_id;
    const subsubcategoryId = _packages[selectedIndex].subsubcategory_id;
    if (!!common.getFromStorage("userName")) {
      props
        .addToCart({
          subscription_id: subscriptionId,
          subsubcategory_id: +subsubcategoryId,
        })
        .then((response) => {
          if (response) {
            props.history.push(webUrl.cartList);
          }
        });
    } else {
      const sidebarOverlay = document.getElementById("sidebar-overlay");
      const sidebarBody = document.getElementById("sidebar-body");
      common.setToStorage("redirectURL", webUrl.packageList + "/" + subsubcategoryId);

      sidebarOverlay.classList.add("visible");
      sidebarBody.classList.add("opened");
    }
  };

  return (
    <Styles>
      <div className="main-wrapper">
        {/* Header */}
        <HeaderTwo {...props} step={0} />

        <div className="bg-gray">
          <div className="left-section">
            <h4>
              Say Goodbye to Sample Papers, Download your own Personalized Papers by{" "}
              <span className="orange-color">"Alyss' AI Powered Test Maker Tool"</span>
            </h4>

            <h2>Test Maker Tool</h2>
            <p>
              THE <span className="orange-color">BEST GUIDELINE</span> FOR YOUR KIDS
            </p>
            <p>
              <button className="btn btn-primary btn-round" id={"hero-btn" + idPref} onClick={onClickJoinNow}>
                Get Started
              </button>
            </p>
            <User openCommand={true} commandCtrlId={"hero-btn" + idPref} step={0} />
          </div>
          <div className="student">
            <button
              onClick={(e) => {
                e.preventDefault();
                openVideoModal("716694942");
              }}
              className="play-button banner-play-button"
            >
              <i className="fas fa-play"></i>
            </button>
          </div>
        </div>
        <div className="container mid-section py-5 text-center">
          <h3 className="big-title py-3">Course Plans</h3>
          <div className="filter-items pt-5">
            <Swiper {...settings}>
              {_packages &&
                _packages.length > 0 &&
                _packages.map((value, i) => (
                  <div key={i}>
                    <div className="course-item">
                      <div className={"course-content"}>
                        <h6 className="heading my-3">{value.display_name}</h6>
                        <h5 className="sub-heading my-3"> {value.subject_name}</h5>
                        <ul className="list-unstyled check-list">
                          {value.features &&
                            value.features.length > 0 &&
                            value.features.map((feature, j) => (
                              <li key={j}>
                                <CheckIcon /> &nbsp; {feature.feature_text}
                              </li>
                            ))}
                        </ul>
                        <div className={"package-price "}>
                          <div className="rating">
                            {isInr ? (
                              <p>
                                <span style={{ textDecoration: "line-through", color: "#fd6464" }}>
                                  <CurrencyRupeeIcon />
                                  {value.price_in_inr}
                                </span>
                                &nbsp;&nbsp;
                                <CurrencyRupeeIcon />
                                {value.discounted_price_in_inr}
                              </p>
                            ) : (
                              <p>
                                <span style={{ textDecoration: "line-through", color: "#fd6464" }}>
                                  <AttachMoneyIcon />
                                  {value.price_in_usd}
                                </span>
                                &nbsp;&nbsp;
                                <AttachMoneyIcon />
                                {value.discounted_price_in_usd}
                              </p>
                            )}
                          </div>
                          <button
                            className="btn btn-primary btn-round buy-course my-5"
                            onClick={(e) => onClickAddToCart(e, i)}
                            id={"hero-btn-" + i + "" + value.subscription_id}
                          >
                            Buy Course
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </Swiper>
            <User step={0} />
          </div>
        </div>

        <div className="container mid-section py-5 text-center">
          <h3 className="big-title py-3">How Can Alyss Help You?</h3>
          <p className="text-large">
            By using Alyss videos, theory, MCQs, practice tests and AI based test generator, you will gain confidence in the subject and excel in the
            exams
          </p>
        </div>
        <div className="container mid-section py-5">
          <Grid container spacing={2} className="robotics-expert">
            <Grid sm={6} className="px-5 py-3">
              <img src={process.env.PUBLIC_URL + `/assets/images/exam_preparatory/ram_sir.webp`} alt="Explanation" />
              <button
                onClick={(e) => {
                  e.preventDefault();
                  openVideoModal("784759492");
                }}
                className="play-button"
              >
                <i className="fas fa-play"></i>
              </button>
            </Grid>
            <Grid sm={6} className="px-5 py-3">
              <Typography variant="h3" className="py-5">
                Prevalent Problem Faced by Students
              </Typography>
              <Typography variant="p" className="py-5 text-large">
                Alyss clears the conceptuality of the students in the domain of Science and Mathematics, many students face problems while visualizing
                the concepts and the theorems, but with our innovative digitized content, visualization won't be a problem anymore.
              </Typography>
              <p>
                <button className="btn btn-primary btn-round btn-round2 my-5" id={"hero-btn" + idPref2} onClick={onClickJoinNow}>
                  Learn More
                </button>
              </p>
              <User openCommand={true} commandCtrlId={"hero-btn" + idPref2} step={0} />
            </Grid>
          </Grid>
        </div>
        <div className="container mid-section py-5">
          <Grid container spacing={2} className="robotics-expert">
            <Grid sm={6} className="px-5 py-3">
              <Typography variant="h3" className="py-5">
                Provide The Right Solution
              </Typography>
              <Typography variant="p" className="py-5 text-large">
                A one-stop solution for students to generate test papers hassle free, Select the number of questions according to your need from the
                huge question bank we have developed by experts having over than 20+ years of experience in teaching. Personalised test just a click
                away now.
              </Typography>
              <p>
                <button className="btn btn-primary btn-round btn-round2 my-5" id={"hero-btn" + idPref3} onClick={onClickJoinNow}>
                  Learn More
                </button>
              </p>
              <User openCommand={true} commandCtrlId={"hero-btn" + idPref3} step={0} />
            </Grid>
            <Grid sm={6} className="px-5 py-3">
              <img src={process.env.PUBLIC_URL + `/assets/images/exam_preparatory/girl_student.webp`} alt="Explanation" />
              <button
                onClick={(e) => {
                  e.preventDefault();
                  openVideoModal("716694942");
                }}
                className="play-button play-button2"
              >
                <i className="fas fa-play"></i>
              </button>
            </Grid>
          </Grid>
        </div>

        <CustomerSlider />

        <VideoSlider />

        {/* Button Area */}
        <ButtonArea buttonText="Join Now" onHeroButtonClick={onClickJoinNow} showPopup={!!!common.getFromStorage("userName")} />

        {/* Footer */}
        <FooterTwo />
      </div>
      <ModalVideo channel="vimeo" isOpen={isOpen} videoId={selectedVideoId} onClose={() => setIsOpen(false)} />
    </Styles>
  );
};

const mapStateToProps = (state) => ({
  examCoursePlans: state.examCoursePlans.data.course_plans,
  showLoader: state.user.showLoader,
});

const mapDispatchToProps = (dispatch) => ({
  coursePlansData: (data) => dispatch(examCoursePlansDataAction(data)),
  addToCart: (data) => dispatch(addToCartAction(data)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ExamPreparatory));
