import { showLoaderAction, stopLoaderAction } from "../user/action";
import { getNotificationsListData } from "./service";

/**
 * Action types
 */
export const actionTypes = {
  NOTIFICATIONS_DATA_REQUEST_SUCCESS: "NOTIFICATIONS_DATA_REQUEST_SUCCESS",
};

export const receivedNotificationsListDataAction = (payload) => ({
  type: actionTypes.NOTIFICATIONS_DATA_REQUEST_SUCCESS,
  payload,
});

export const notificationsListDataAction = (data) => {

  return (dispatch) => {

    dispatch(showLoaderAction("getNotificationsListData"));

    return getNotificationsListData(data)
      .then((response) => {
        dispatch(stopLoaderAction("getNotificationsListData"));

        if (response) dispatch(receivedNotificationsListDataAction(response));
      })
      .catch((error) => {
        dispatch(stopLoaderAction("getNotificationsListData"));
      });
  };
};