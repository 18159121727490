import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Styles } from "./styles";

const EmbedVideo = (props) => {
  const query = new URLSearchParams(props.location.search);
  const videoURL = query.get("url") || "";

  return (
    videoURL !== "" && (
      <Styles>
        <div className="embed-responsive embed-responsive-16by9" id="main_video">
          <iframe id="class-video" title="video" className="embed-responsive-item" src={videoURL} allowFullScreen />
        </div>
      </Styles>
    )
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EmbedVideo));
