import styled from "styled-components";
import { colors } from "../common/element/elements.js";

export const Styles = styled.div`
    .intership-form-bg{
        background-image: url(/assets/images/internship_form/registration_form_bg.webp);
        min-height: 457px;
        background-repeat: no-repeat;
        background-position: center;
        padding: 34px 67px 0 36px;
        color: white;
        @media (max-width: 575px) {
            background-size: contain;
            background-color: #9d16fa;
        }
    }

    .top-title{
        font-weight: 800;
        font-size: 32px;
        width: 85%;
        margin: 30px auto;
        @media (max-width: 575px) {
            font-size: 20px;
        }
    }
    .expert-talk{
        color: yellow;
        font-weight: 600;
        font-size: 32px;
        @media (max-width: 575px) {
            font-size: 25px;
        }
    }

    .connect-now {
        font-size: 30px;
        background: white;
        color: black;
        border: 1px solid white;
        margin-top: 70px;
        border-radius: 50px;
        padding: 5px 50px;
        @media (max-width: 575px) {
            font-size: 20px;
        }
    }
    

    @media(max-width: 767px) {
        padding: 30px 0;
    }
    
`;