import styled from "styled-components";
import { colors } from "../common/element/elements.js";

export const Styles = styled.div`
        .loading-spinner
        {
            background: url('/assets/images/icons/loading-loader.gif') center center no-repeat;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background-size: 300px;
            z-index: 10;
            opacity: 0.9;
        }
`;