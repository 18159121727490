import React, { useState } from "react";
import { Styles } from "./styles/homeContent";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { webUrl } from "../shared/constants";
import common from "../shared/common";
import User from "../pages/user";
import FreeTrial from "./FreeTrial";

const HomeContent = (props) => {
  const idPref = Math.random();

  const onClickJoinNow = () => {
    if (common.getFromStorage("userName")) {
      props.history.push(webUrl.userDashboard);
    }
  };
  return (
    <Styles>
      <div className="main_container">
        <h2 className="main_title">
          Quality <span className="orange_text">Science, Maths</span> and <span className="orange_text">Robotics</span> content with{" "}
          <span className="blue">Alyss</span>
        </h2>

        <section className="learning_app">
          <h5 className="sec_title">The Learning App by ALYSS </h5>
          <div className="bg_card blue">
            <div className="d_flex h-100">
              <div className="col_two left">
                <h4>India’s Best Learning App</h4>
                <p>Alyss 3 As for Holistic Learning</p>
              </div>
              <div className="col_two right">
                <div className="board_card top">
                  <div className="d_flex h-100">
                    <div className="col_two left_col">
                      <p className="class_text"></p>
                      <h6>Our Intelligent Method Solves Every Student's Problems</h6>
                      <p className="desc_text">ALYSS works with children in grades 9 & 10 to help them acquire a mindset of lifelong learning.</p>
                    </div>
                    <div className="col">
                      <img src={process.env.PUBLIC_URL + `/assets/images/home/board_1.webp`} alt="" />
                    </div>
                  </div>
                </div>
                <div className="board_card bottom">
                  <div className="d_flex h-100">
                    <div className="col_two left_col">
                      <p className="class_text"></p>
                      <h6>ALYSS' Award-Winning Curriculum is Highly Visual and Logical</h6>
                      <p className="desc_text">We teach Mathematics and Science as life-skill, not just as school subjects.</p>
                    </div>
                    <div className="col">
                      <img src={process.env.PUBLIC_URL + `/assets/images/home/board_2.webp`} alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="button-area">
            <button
              className="join-button my-5"
              onClick={(e) => {
                e.preventDefault();
                props.history.push(webUrl.studyMaterial);
              }}
            >
              Explore More
            </button>
          </div>
        </section>
        <section className="robotic">
          <div className="bg_card yellow">
            <div className="d_flex">
              <div className="col_three">
                <div className="robotic_card">
                  <h5>Alyss Robotics Program</h5>
                  <ul>
                    <li>
                      <ChevronRightIcon />
                      3D HD Animations for Robotics Concepts
                    </li>
                    <li>
                      <ChevronRightIcon />
                      Learn from Expert Coaches
                    </li>
                    <li>
                      <ChevronRightIcon />
                      Hands on Robotics Training
                    </li>
                    <li>
                      <ChevronRightIcon />
                      Experiential Learning
                    </li>
                    <li>
                      <ChevronRightIcon />
                      Cognitive Development
                    </li>
                    <li>
                      <ChevronRightIcon />
                      Ace Robotics Competitions
                    </li>
                    <li>
                      <ChevronRightIcon />
                      Earn Certificates as your Child climbs the Course Ladder
                    </li>
                  </ul>
                </div>
                <h2>Your Child’s Perfect Partner in STEM Learning</h2>
              </div>
              <div className="col_four right_four">
                <div className="course_card">
                  <div className="d_flex h-100">
                    <div className="col_two course_layer"></div>
                    <div className="col_two right_col">
                      <h3>Robotics U.S. Based Certification Course</h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="button-area">
            <button
              className="join-button my-5"
              onClick={(e) => {
                e.preventDefault();
                props.history.push(webUrl.robotic);
              }}
            >
              Explore More
            </button>
          </div>
        </section>
        <section className="teacher">
          <div className="bg_card green">
            <h2>Your Partner in STEM Subjects in Schools, Tuition Centers, Online and Home Tuitions</h2>
            <div className="d_flex ">
              <div className="col_two"></div>
              <div className="col_two">
                <div className="tech_card">
                  <h5>Empowering Teacher</h5>

                  <p>Lesson plans</p>
                  <p>Video Links </p>
                  <p>References Copy</p>
                </div>
              </div>
              <div className="col_two col_right">
                <div className="teacher_card">
                  <h5>Empowering Teacher</h5>
                  <ul>
                    <li>
                      <ChevronRightIcon />
                      Content Library
                    </li>
                    <li>
                      <ChevronRightIcon />
                      AI Powered Test Generation
                    </li>
                    <li>
                      <ChevronRightIcon />
                      Teacher Technology Kit
                    </li>
                    <li>
                      <ChevronRightIcon />
                      Dashboard
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="button-area">
            <button
              className="join-button my-5"
              onClick={(e) => {
                e.preventDefault();
                props.history.push(webUrl.teacher);
              }}
            >
              Explore More
            </button>
          </div>
        </section>
        <FreeTrial></FreeTrial>
      </div>
      <User openCommand={true} commandCtrlId={"hero-btn" + idPref} />
    </Styles>
  );
};

export default HomeContent;
