import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import FooterTwo from "../../components/FooterTwo";
import VideoSlider from "../../components/VideoSlider";
import ButtonEnRoll from "../../components/ButtonEnRoll";
import common from "../../shared/common";
import { webUrl } from "../../shared/constants";
import { Styles } from "../../components/styles/homeUS";
import { Button, Container, Grid, Link, Paper, TextField } from "@mui/material";
import { Dropdown } from "react-bootstrap";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import FreeTrial from "../../components/FreeTrial";
import User from "../user";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import DashboardIcon from "@mui/icons-material/Dashboard";
import PrecisionManufacturingIcon from "@mui/icons-material/PrecisionManufacturing";
import SupervisedUserCircleIcon from "@mui/icons-material/SupervisedUserCircle";
import AccountCircleRoundedIcon from "@mui/icons-material/AccountCircleRounded";
import ModalVideo from "react-modal-video";
import { UsCourseDetailPlansDataAction } from "./action";
// import '../../../public/assets/css/font-awesome-us.min.css';

const CourceDetailContent = (props) => {
  const [anchorState, setAnchorState] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const openVideoModal = (data) => {
    setIsOpen(true);
  };
  const onClickJoinNow = () => {
    if (common.getFromStorage("userName")) props.history.push(webUrl.userDashboard);
  };
  const onOpenDrawer = (event) => {
    if (event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
      return;
    }
    setAnchorState(true);
  };
  const onCloseDrawer = (event) => {
    if (event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
      return;
    }
    setAnchorState(false);
  };
 
  const list = () => (
    <Box role="presentation" onClick={onCloseDrawer} onKeyDown={onCloseDrawer}>
      <List>
        <ListItem>
          <ListItemButton
            onClick={() => {
              props.history.push(process.env.PUBLIC_URL + webUrl.studyMaterial);
            }}
          >
            <ListItemIcon>
              <MenuBookIcon />
            </ListItemIcon>
            <ListItemText primary="Study Material" />
          </ListItemButton>
        </ListItem>
        <ListItem>
          <ListItemButton
            onClick={() => {
              props.history.push(process.env.PUBLIC_URL + webUrl.robotic);
            }}
          >
            <ListItemIcon>
              <PrecisionManufacturingIcon />
            </ListItemIcon>
            <ListItemText primary="Robotics" />
          </ListItemButton>
        </ListItem>
        <ListItem>
          <ListItemButton
            onClick={() => {
              props.history.push(process.env.PUBLIC_URL + webUrl.teacher);
            }}
          >
            <ListItemIcon>
              <SupervisedUserCircleIcon />
            </ListItemIcon>
            <ListItemText primary="Teacher Solutions" />
          </ListItemButton>
        </ListItem>
      </List>
    </Box>
  );
//   useEffect(() => {
//     if (common.getFromStorage("userName")) props.sendAuditEvent({ event_id: "home", source: "web" });
//   }, []);

//   const profile = common.getFromStorage("profile");
//   let dashboardUrl = webUrl.userDashboard;
//   if (profile) {
//     const profileData = JSON.parse(profile);
//     if (profileData.member_type === 4) {
//       dashboardUrl = webUrl.partnerDashboard;
//     }
//   }
//   useEffect(() => {
//     const script = document.createElement('script');    
//     script.src = 'assets/css/custom.js';
//     document.head.appendChild(script);
//     return () => {
//       document.head.removeChild(script);
//     };
//   }, []);


  const AccordionItem = ({ title, content }) => {
	const [isOpen, setIsOpen] = useState(false);
  
	const toggleAccordion = () => {
	  setIsOpen(!isOpen);
	};
  
	return (
	  <div className="accordion-item">
		<a onClick={toggleAccordion}>{title}</a>
	        {isOpen && <div style={{opacity:'1',maxHeight: '200px'}} className="content">{content}</div>}

	  </div>
	);
  };
  return (<>
 
<section className="disSection">
	<div className="container">
		<div className="dsPanel">
		
			<div className="disLeft">
				<div className="iBox"><img src={`${process.env.PUBLIC_URL}/assets/images/usImages/simg.jpg`} /></div>
				<strong>Tuto : Shophi Patel</strong>
				<header>Discription</header>
				<p>Dive into the exciting world of robotics with our 'Foundation of Robotics' course using Spike Prime. Designed for beginners and aspiring young engineers, this course provides a solid introduction to robotics, combining hands-on experience with engaging 3D HD video animations. Students will embark on an interactive journey, learning the fundamentals of robotics, programming, and problem-solving while exploring the incredible potential of Spike Prime.</p>
				<p>With a STEM-focused approach and a wealth of additional resources, this course sets the stage for lifelong learning and innovation in the field of robotics.</p>
			<div className="learn">
				<h4>What You Will Learn</h4>
				<h5>By the end of this course, you will have acquired advanced knowledge and skills in robotics, including:</h5>
				<ul>
					<li>Spike Prime Mastery: Gain an in-depth understanding of Spike Prime's capabilities and functionality.</li>
					<li>Advanced Concepts: Explore high-level topics such as PID Controller concepts, Artificial Intelligence, and Machine Learning in robotics.</li>
					<li>AIML Projects: Develop AI and Machine Learning projects that reflect real-world applications.</li>
					<li>Competitive Robotics: Learn how competitions are held in today’s world and how they are categorized.</li>
					<li>Programming Expertise: Master advanced programming techniques to control robots with precision.</li>
					<li>3D HD Video Animations: Dive into immersive 3D HD video animations that make advanced concepts accessible and enjoyable.</li>
					<li>Assessments and Feedback: Continuously assess your skills, receive valuable feedback, and refine your abilities.</li>
					<li>Additional Resources: Access a wealth of supplementary materials, including build instruction manuals and programming videos.</li>
					<li>STEM Focus: Enhance your STEM-focused learning experience and prepare for the future of technology and innovation.</li>
				</ul>
			</div>
			
			
					  <div className="accordionBlk">
						  <div className="accordion">
							  <AccordionItem
								  title="What sets us apart?"
								  content="Our captivating 3D HD animated concept videos breathe life into complex robotics concepts, making learning engaging and enjoyable. But that's just the beginning. Our dedication to STEM-focused education, hands-on projects, assessments, and a wealth of supplementary resources means that students don't just learn about robotics – they actively engage with it. We emphasize problem-solving, critical thinking, and creativity, preparing learners for the challenges of the future while nurturing a deep passion for innovation. Join us and discover the difference for yourself."
							  />
							  <AccordionItem
								  title="Who can apply for this Course?"
								  content="Our course using Spike Prime is designed for young minds eager to embark on an exciting journey into the world of robotics. It's perfect for beginners and aspiring engineers, who are curious about technology, coding, and problem-solving. Whether you're a student, a parent looking to enrich your child's education, or an educator seeking a dynamic teaching resource, our course is accessible to all with an interest in robotics. No prior experience is necessary – just bring your curiosity and enthusiasm for learning!"
							  />
							  <AccordionItem
								  title="How Can I Apply for the Product?"
								  content="In the Same Page When You Go on The Top There is an APPLY NOW Option. Here is the Direct Link."
							  />
							  <AccordionItem
								  title="What is the Process of Sanction?"
								  content={
									  <div>
										  <p><i className="fa fa-circle" aria-hidden="true"></i> Apply on the Website</p>
										  <p><i className="fa fa-circle" aria-hidden="true"></i> Submit all the Documents</p>
										  <p><i className="fa fa-circle" aria-hidden="true"></i> Pay Documentation Fees</p>
										  <p><i className="fa fa-circle" aria-hidden="true"></i> Get a Sanctioned Amount</p>
										  <p><i className="fa fa-circle" aria-hidden="true"></i> Pay Processing Fees</p>
										  <p><i className="fa fa-circle" aria-hidden="true"></i> Loan is Sanctioned</p>
									  </div>
								  }
							  />
							  <AccordionItem
								  title="What Documents are Required?"
								  content={
									  <div>
										  <p><i className="fa fa-circle" aria-hidden="true"></i> KYC Documents of Owner and Co-borrower.</p>
										  <p><i className="fa fa-circle" aria-hidden="true"></i> Current Account Documents</p>
										  <p><i className="fa fa-circle" aria-hidden="true"></i> KYC Documents of Guarantor</p>
										  <p><i className="fa fa-circle" aria-hidden="true"></i> Borrower's Financials</p>
										  <p><i className="fa fa-circle" aria-hidden="true"></i> Invoices and Contract Copy from the Client</p>
									  </div>
								  }
							  />
						  </div>
					  </div>


			
			</div>
			
           { props && props.coursesDetail ? 
			<div className={`disRight stickyRight`}>
			<h2>{props.coursesDetail.amount} {props.coursesDetail.currency}</h2>
				<ul>
					<li><i className="fa fa-calendar" aria-hidden="true"></i> Modul <b>{props.coursesDetail.module}</b></li>
					<li><i className="fa fa-user-o" aria-hidden="true"></i> Modul <b>{props.coursesDetail.module}</b></li>
				</ul>
				<ol>
					<li>Student : <b>{props.coursesDetail.students_enrolled}</b></li>
					<li>Language : <b>{props.coursesDetail.course_language}</b></li>
					<li>Subtitles: <b>{props.coursesDetail.course_subtitles}</b></li>
					<li>Additional <b>Resources : 12 Files</b></li>
					<li>Duaration : <b>{props.coursesDetail.video_time}</b></li>
				</ol>
				<ButtonEnRoll buttonText="Enroll Now!" onHeroButtonClick={onClickJoinNow} showPopup={!!!common.getFromStorage("userName")} />
				
				<h4>ASSIGMENT</h4>
				<p>Pulvinar nunc eget tincidunt id urna diam scelerisque cursus. Quis nunc pharetra arcu lectus nisl. Risus hendreritconsequat aliquet erat ullamcorper diam. Tortor vulputate sed maecenas rhoncusvivera purus.</p>
				<h4>ASSIGMENT</h4>
				<p>Pulvinar nunc eget tincidunt id urna diam scelerisque cursus. Quis nunc pharetra arcu lectus nisl. Risus hendreritconsequat aliquet erat ullamcorper diam. Tortor vulputate sed maecenas rhoncusvivera purus.</p>
			</div> : null }
		</div> 
	</div>
</section>


<section className="innerBanner">
		<img src={`${process.env.PUBLIC_URL}/assets/images/usImages/build.jpg`} />
		<div className="container"> <header></header></div>
</section>

<section className="popularCourses">
	<div className="container">
	<h2>Trending Courses</h2>
		<div className="textBox">
            <div className="aTx">Empower your child's future with our cutting-edge robotics courses, designed to spark their curiosity while gaining essential skills for tomorrow.</div>
            <div className="aTag"><a href="#"  onClick={() => {
            props.history.push(process.env.PUBLIC_URL + webUrl.courseListUs);
          }}> Explore All Courses</a></div>        
        </div>

		<div className="coursesBlk">
				  {props && props.courses && props.courses.length > 0 && props.courses.map((course, index) => {
					
					  return (
						props && props.coursesDetail.id === course.id ? null :
						  <div className="cBox" key={index} style={{ cursor: "pointer" }} onClick={() => {
							  props.history.push(process.env.PUBLIC_URL + webUrl.courseDetailsUs + "/" + course.id);
						  }}>
							  <img src={course.image_url} />
							  <span>${course.amount}</span>
							  <h2>{course.title}</h2>
							  <ul>
								  <li>{course.age_group}</li>
								  <li>{course.module}</li>
								  <li>{course.video_time}</li>
							  </ul>
							  <p><i className="fa fa-star" aria-hidden="true"></i> {course.reviews}</p>
						  </div>
					  )
				  })}
				
		</div>
	</div>
</section>
	  {/* <section className="howWorks">
		  <div className="container">
			  <h2>How It Works</h2>

			  <div className="textBox">
				  <div className="aTx">Pulvinar nunc eget tincidunt id urna diam scelerisque cursus. Quis nunc pharetra arcu lectus nisl. Risus hendreritconsequat aliquet erat ullamcorper diam. Tortor vulputate sed maecenas rhoncusvivera purus.</div>
				  <ButtonEnRoll clsName="button-enroll" buttonText="Enroll Now!" onHeroButtonClick={onClickJoinNow} showPopup={!!!common.getFromStorage("userName")} />
				 
			  </div>

			  <ul>
				  <li><strong>Choose Your Course</strong><p>Quis nunc pharetra arcu lectus nisl. venenatis tincidunt augue pellentesque ultrices ut id.</p></li>
				  <li><strong>Choose Your Course</strong><p>Quis nunc pharetra arcu lectus nisl. venenatis tincidunt augue pellentesque ultrices ut id.</p></li>
				  <li><strong>Choose Your Course</strong><p>Quis nunc pharetra arcu lectus nisl. venenatis tincidunt augue pellentesque ultrices ut id.</p></li>
				  <li><strong>Choose Your Course</strong><p>Quis nunc pharetra arcu lectus nisl. venenatis tincidunt augue pellentesque ultrices ut id.</p></li>
			  </ul>

		  </div>
	  </section> */}
	  
<section className="learningMethods">
	<div className="container">
		<div className="lmLeft">
			<h2>Learning Methods</h2>
			<h3>Access to Learn Anytime, Anywhere</h3>
			<p>Empower Your Learning Journey with Our Innovative Methods</p>
			<ul>
				<li>Interactive Online Labs</li>
				<li>Expert Guidance</li>
				<li>Real-World Applications</li>
				<li>Adaptive Learning</li>
				<li>Community Support</li>
				<li>Continuous Progress Tracking</li>
			</ul>
			<ButtonEnRoll buttonText="Enroll Now!" onHeroButtonClick={onClickJoinNow} showPopup={!!!common.getFromStorage("userName")} />
		</div>	
		<div className="lmRgt"><img src={`${process.env.PUBLIC_URL}/assets/images/usImages/12.png`} /></div>	
		
</div>
</section>

</>);
};



  
export default CourceDetailContent;
