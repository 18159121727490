import React, { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
// import { Link } from "react-router-dom";
import { Styles } from "../styles/subject.js";
import Modules from "./Modules.js";
import common from "../../../shared/common.js";
// import { Link } from "@mui/material";
import { Link } from "react-router-dom";
import { webUrl } from "../../../shared/constants.js";

const USDashboard = (props) => {
  const {
    match: { params },
  } = props;

  useEffect(() => {
    props.subjectDetails([params.type]); // To do: params.type should be default one
  }, []);

  return (
    props.showLoader.length === 0 && (
      <section className="course-details-area">
        <Row className="course-details-area">
          <Col lg="6" md="6" sm="12">
            <Link
              to={
                webUrl.subjectDetails +
                "/" +
                props.subsubcategory.id +
                webUrl.userUSIntroduction
              }
            >
              <div className="course-content">
                <h6 className="us-heading">Introduction</h6>
              </div>
              <div className="course-item no-transition">
                <div
                  className="course-image"
                  style={{
                    backgroundImage: `url(/assets/images/module/us-page-sections/section-intro-bg.webp)`,
                  }}
                >
                  <div className="course-price"></div>
                </div>
              </div>
            </Link>
          </Col>
          <Col lg="6" md="6" sm="12">
            <Link
              to={
                webUrl.subjectDetails +
                "/" +
                props.subsubcategory.id +
                webUrl.userUSUnits
              }
            >
              <div className="course-content">
                <h6 className="us-heading">Units Plan</h6>
              </div>
              <div className="course-item no-transition">
                <div
                  className="course-image"
                  style={{
                    backgroundImage: `url(/assets/images/module/us-page-sections/section-units-bg.webp)`,
                  }}
                >
                  <div className="course-price"></div>
                </div>
              </div>
            </Link>
          </Col>
          <Col lg="6" md="6" sm="12">
            <Link
              to={
                webUrl.subjectDetails +
                "/" +
                props.subsubcategory.id +
                webUrl.userUSAdvanceMission
              }
            >
              <div className="course-content">
                <h6 className="us-heading">Advance Mission</h6>
              </div>
              <div className="course-item no-transition">
                <div
                  className="course-image"
                  style={{
                    backgroundImage: `url(/assets/images/module/us-page-sections/section-missions-bg.webp)`,
                  }}
                >
                  <div className="course-price"></div>
                </div>
              </div>
            </Link>
          </Col>
          <Col lg="6" md="6" sm="12">
            <Link
              to={
                webUrl.subjectDetails +
                "/" +
                props.subsubcategory.id +
                webUrl.userUSCeritificate
              }
            >
              <div className="course-content">
                <h6 className="us-heading">Certificate</h6>
              </div>
              <div className="course-item no-transition">
                <div
                  className="course-image"
                  style={{
                    backgroundImage: `url(/assets/images/module/us-page-sections/sections-certificate-bg.webp)`,
                  }}
                >
                  <div className="course-price"></div>
                </div>
              </div>
            </Link>
          </Col>
        </Row>
      </section>
    )
  );
};

export default USDashboard;
