import { showLoaderAction, stopLoaderAction } from "../user/action";
import { addToCart, packageList } from "./service";

/**
 * Action types
 */
export const actionTypes = {
    PACKAGE_LIST_REQUEST_SUCCESS: "PACKAGE_LIST_REQUEST_SUCCESS",
    ADD_TO_CART_REQUEST_SUCCESS: "ADD_TO_CART_REQUEST_SUCCESS",
};

export const receivedPackageListAction = (payload) => ({
    type: actionTypes.PACKAGE_LIST_REQUEST_SUCCESS,
    payload,
});

export const receivedAddToCartAction = (payload) => ({
    type: actionTypes.ADD_TO_CART_REQUEST_SUCCESS,
    payload,
});

export const packageListAction = (data) => {
    return (dispatch) => {
        dispatch(showLoaderAction("packageList"));

        return packageList(data)
            .then((response) => {
                dispatch(stopLoaderAction("packageList"));
                dispatch(receivedPackageListAction(response));
            })
            .catch((error) => {
                dispatch(stopLoaderAction("packageList"));
                alert(error.message);
            });
    };
};

export const addToCartAction = (data) => {
    return (dispatch) => {
        dispatch(showLoaderAction("addToCart"));

        return addToCart(data)
            .then((response) => {
                dispatch(stopLoaderAction("addToCart"));
                dispatch(receivedAddToCartAction(response));
                return response;
            })
            .catch((error) => {
                dispatch(stopLoaderAction("addToCart"));
                alert(error.message);
            });
    };
};
