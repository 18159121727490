/**
 * Method to format string
 * @param stringToFormat - string to format
 * @param values - values to be replace
 * @returns formatted string
 */
const stringformat = function (stringToFormat, values) {
  for (let i = 0; i < values.length; i++) {
    stringToFormat = stringToFormat.replace("{" + i + "}", values[i]);
  }

  return stringToFormat;
};

/**
 * Method to get values, stored in storage
 * @param key - key associated to value
 * @returns value stored in storage
 */
const getFromStorage = function (key) {
  return localStorage.getItem(key);
};

/**
 * Method to set values in storage
 * @param key - key associated to value
 * @param value - value associated to key
 */
const setToStorage = function (key, value) {
  localStorage.setItem(key, value);
};

/**
 * Method to download a file
 * @param {*} blob
 * @param {*} filename
 */
const forceDownload = (blob, filename) => {
  const a = document.createElement("a");
  a.href = blob;
  a.setAttribute("download", filename);
  document.body.appendChild(a);
  a.click();
  a.remove();
};

/**
 * Method to download resource
 * @param {*} data
 * @param {*} url
 * @param {*} filename
 */
const downloadResource = (url, filename = null) => {
  if (!url) return;

  if (!filename) filename = url.split("\\").pop().split("/").pop();

  fetch(url, {
    headers: new Headers({
      Origin: window.location.origin,
    }),
    mode: "no-cors",
  })
    .then((response) => response.blob())
    .then((blob) => {
      let blobUrl = window.URL.createObjectURL(blob);
      forceDownload(blobUrl, filename);
    })
    .catch((e) => console.error(e));
};

const getFormattedDate = (dateObj) => {
  const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

  const month = months[dateObj.getMonth()];

  return dateObj.getDate() + " " + month + " " + dateObj.getFullYear();
};

const getFormattedTime = (dateObj) => {
  var hours = dateObj.getHours();
  var minutes = dateObj.getMinutes();
  var ampm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? "0" + minutes : minutes;
  var strTime = hours + ":" + minutes + " " + ampm;
  return strTime;
};

const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

const getISTFormattedDate = (dateObj) => {
  const options = {
    date: "long",
    month: "long",
    day: "numeric",
    year: "numeric",
    timeZone: "Asia/Kolkata",
  };
  return new Date(dateObj).toLocaleString("en-US", options);
};

const getISTFormattedTime = (dateObj) => {
  const options = {
    time: "long",
    hour: "numeric",
    minute: "numeric",
    timeZone: "Asia/Kolkata",
  };
  return new Date(dateObj).toLocaleString("en-US", options);
};

const getISTFormattedDateTime = (dateObj) => {
  const options = {
    date: "long",
    month: "long",
    day: "numeric",
    year: "numeric",
    time: "long",
    hour: "numeric",
    minute: "numeric",
    timeZone: "Asia/Kolkata",
  };
  return new Date(dateObj).toLocaleString("en-US", options);
};

export default {
  stringformat,
  getFromStorage,
  setToStorage,
  downloadResource,
  getFormattedDate,
  getFormattedTime,
  capitalizeFirstLetter,
  getISTFormattedDate,
  getISTFormattedTime,
  getISTFormattedDateTime,
};
