import { showLoaderAction, stopLoaderAction } from "../user/action";
import { getExamCoursePlansData } from "./service";

/**
 * Action types
 */
export const actionTypes = {
  EXAM_COURSE_PLANS_DATA_REQUEST_SUCCESS: "EXAM_COURSE_PLANS_DATA_REQUEST_SUCCESS",
};

export const receivedExamCoursePlansDataAction = (payload) => ({
  type: actionTypes.EXAM_COURSE_PLANS_DATA_REQUEST_SUCCESS,
  payload,
});

export const examCoursePlansDataAction = (data) => {
  return (dispatch) => {
    dispatch(showLoaderAction("getExamCoursePlans"));

    return getExamCoursePlansData(data)
      .then((response) => {
        dispatch(stopLoaderAction("getExamCoursePlans"));

        if (response) {
          dispatch(receivedExamCoursePlansDataAction(response));
        }
      })
      .catch((error) => {
        dispatch(stopLoaderAction("getExamCoursePlans"));
      });
  };
};
