import React, { useEffect, useState } from "react";
import ChapterDatas from "../../../data/event/chapters.json";
import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Styles } from "../styles/subject.js";
import Chapters from "./Chapters";
import Modules from "./Modules";

const SubjectPane = (props) => {
  const {
    match: { params },
  } = props;

  useEffect(() => {
    props.subjectDetails([params.type]); // To do: params.type should be default one
  }, []);
  let userCanAccess = props.subsubcategory.can_user_access;
  let userClasses = 'lg="12" md="12" sm="12"';
  if (userCanAccess === 0) {
    userClasses = 'lg="9" md="8" sm="12"';
  }

  return (
    <Styles>
      {/* Course Details */}
      <section className="course-details-area">
        <Container>
          <Row>
            <Col {...userClasses}>
              <div className="course-details-top">
                <div className="course-tab-list">
                  {props.subsubcategory.is_robotics === 1 && <Modules {...props} />}
                  {props.subsubcategory.is_robotics === 0 && (
                    <Tab.Container defaultActiveKey="chapters">
                      <Tab.Content>
                        <Tab.Pane eventKey="chapters" className="curriculum-tab">
                          <Chapters {...props} />
                        </Tab.Pane>
                      </Tab.Content>
                    </Tab.Container>
                  )}
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </Styles>
  );
};

export default SubjectPane;
