import { showLoaderAction, stopLoaderAction } from "../user/action";
import { getSubscriptionsListData } from "./service";

/**
 * Action types
 */
export const actionTypes = {
  SUBSCRIPTIONS_DATA_REQUEST_SUCCESS: "SUBSCRIPTIONS_DATA_REQUEST_SUCCESS",
};

export const receivedSubscriptionsListDataAction = (payload) => ({
  type: actionTypes.SUBSCRIPTIONS_DATA_REQUEST_SUCCESS,
  payload,
});

export const subscriptionsListDataAction = (data) => {

  return (dispatch) => {

    dispatch(showLoaderAction("getSubscriptionsListData"));

    return getSubscriptionsListData(data)
      .then((response) => {
        dispatch(stopLoaderAction("getSubscriptionsListData"));

        if (response) dispatch(receivedSubscriptionsListDataAction(response));
      })
      .catch((error) => {
        dispatch(stopLoaderAction("getSubscriptionsListData"));
      });
  };
};