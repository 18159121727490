import React, { Component, Fragment, useEffect, useState } from "react";
import Datas from "../../../data/course/item.json";
import { Link } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import { webUrl } from "../../../shared/constants";
import { IconButton, Snackbar } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const CourseItemGrid = (props) => {
  const [snackPack, setSnackPack] = useState([]);
  const [open, setOpen] = useState(false);
  const [messageInfo, setMessageInfo] = useState(undefined);

  useEffect(() => {
    if (snackPack.length && !messageInfo) {
      // Set a new snack when we don't have an active one
      setMessageInfo({ ...snackPack[0] });
      setSnackPack((prev) => prev.slice(1));
      setOpen(true);
    } else if (snackPack.length && messageInfo && open) {
      // Close an active snack when a new one is added
      setOpen(false);
    }
  }, [snackPack, messageInfo, open]);

  const handleSnackbar = (message) => {
    return setSnackPack((prev) => [...prev, { message, key: new Date().getTime() }]);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const handleExited = () => {
    setMessageInfo(undefined);
  };
  const checkData = (data, type = "subject") => {
    if (data.id === 13) {
      return handleSnackbar("Subscribe beginner package first to start the advanced package.");
    } else {
      if (type === "subject") {
        return props.history.push(process.env.PUBLIC_URL + webUrl.subjectDetails + "/" + data.id);
      } else {
        return props.history.push(process.env.PUBLIC_URL + "package/" + data.id);
      }
    }
  };
  const handleSubjectClick = (data) => {
    return checkData(data, "subject");
  };
  const handlePackageClick = (data) => {
    return checkData(data, "package");
  };
  return (
    <Fragment>
      {/* Course Item */}
      <Row>
        <Col>
          <h5 className="grid-header ml-0">{props.headerText}</h5>
        </Col>
      </Row>

      <Row>
        {(props.data &&
          props.data.length > 0 &&
          props.data.map((data, i) => (
            <Col lg="4" md="6" key={i}>
              <div className="course-item">
                <Link
                  onClick={(e) => {
                    e.preventDefault();
                    return handleSubjectClick(data);
                  }}
                >
                  <div
                    className="course-image"
                    style={{
                      backgroundImage: `url(${
                        (data.subsubcategory_image_url && data.subsubcategory_image_url.replace(/\\/g, "/")) ||
                        process.env.PUBLIC_URL + "/assets/images/" + Datas[0].imgUrl
                      })`,
                    }}
                  ></div>
                </Link>
                {props.canBuy && (
                  <div className="course-price">
                    <Link
                      onClick={(e) => {
                        e.preventDefault();
                        return handlePackageClick(data);
                      }}
                    >
                      <p>Buy Now</p>
                    </Link>
                  </div>
                )}
                <div className="course-content">
                  <h6 className="heading">
                    <Link
                      onClick={(e) => {
                        e.preventDefault();
                        return handleSubjectClick(data);
                      }}
                    >
                      {data.name}
                    </Link>
                  </h6>
                </div>
              </div>
            </Col>
          ))) || (
          <Col lg="4" md="6">
            <p className="not-found">{props.notFoundText}</p>
          </Col>
        )}
      </Row>
      <Snackbar
        key={messageInfo ? messageInfo.key : undefined}
        open={open}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        autoHideDuration={6000}
        onClose={handleClose}
        TransitionProps={{ onExited: handleExited }}
        message={messageInfo ? messageInfo.message : undefined}
        action={
          <React.Fragment>
            <IconButton aria-label="close" color="inherit" sx={{ p: 0.5 }} onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </React.Fragment>
        }
      />
    </Fragment>
  );
};

export default CourseItemGrid;
