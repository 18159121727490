import styled from "styled-components";
import { colors } from "../../../components/common/element/elements.js";

export const Styles = styled.div`

    /* dashboard Grid */
    .dashboard-grid-area {
        padding: 20px 0;
        background-color: #afb9c5;
        .not-found{
            padding-bottom: 40px;
        }
        h5 {
            color: ${colors.black2};
            font-weight: 600;
            padding-bottom: 10px;
            margin-bottom: 20px;
            position: relative;
            margin-left: 20px;
            text-transform: capitalize;
            &:before {
                position: absolute;
                content: "";
                background: ${colors.green};
                width: 50px;
                height: 2px;
                bottom: 0;
                left: 0;
            }

            @media(max-width: 575px) {
                font-size : 17px;
            }                            
        }
        .course-items {
            .course-item {
                border: 1px solid ${colors.border1};
                border-radius : 5px;
                transition : all 0.2s ease;
                overflow : hidden;
                margin-bottom: 30px;
                box-shadow: rgb(0 0 0 / 15%) 0px 0px 20px;

                &:hover{
                    transform: scale(1.02);
                    transition: all 0.7s ease;
                }

                .course-image {
                    width              : 100%;
                    padding-top: 56.25%;
                    background-size    : cover;
                    background-position: center;
                    background-repeat  : no-repeat;
                    border-radius : 5px 5px 0 0;
                    position: relative;

                    .author-img {
                        position: absolute;
                        left    : 20px;
                        bottom  : 20px;

                        img {
                            max-width: 40px;
                            border-radius : 50%;
                            margin-right: 5px;
                        }

                        .title {
                            background: #ffffff;
                            padding   : 3px 8px;
                            border-radius : 5px;

                            p {
                                font-size    : 12px;
                                color        : ${colors.black1};
                                font-weight  : 500;
                                margin-bottom: -4px;
                            }

                            span {
                                font-size  : 11px;
                                color      : ${colors.text3};
                                font-weight: 500;
                            }
                        }

                    }

                    .course-price {
                        p {
                            font-size  : 16px;
                            color      : #ffffff;
                            background : ${colors.bg1};
                            position   : absolute;
                            right      : 20px;
                            bottom     : 20px;
                            padding    : 8px 10px;
                            font-weight: 500;
                            border-radius : 5px;
                        }
                    }

                    @media(max-width: 767px) {
                        height: 185px;
                    }
                }

                .course-content {
                    background: #fff;
                    padding   : 10px 15px;

                    h6.heading {
                        a {
                            color        : ${colors.black1};
                            font-weight  : 600;
                            display      : inline-block;

                            &:hover {
                                color: ${colors.green};
                            }
                        }
                    }

                    p.desc {
                        font-size     : 14px;
                        color         : ${colors.text3};
                        line-height   : 25px;
                        border-bottom : 1px solid ${colors.border1};
                        padding-bottom: 5px;
                        margin-bottom : 5px;
                    }

                    .course-face {

                        .duration,
                        .student {
                            p {
                                font-size: 13px;
                                color    : ${colors.text3};

                                i {
                                    font-size     : 16px;
                                    color         : ${colors.green};
                                    vertical-align: text-bottom;
                                    margin-right  : 3px;
                                }
                            }
                        }

                        .rating {
                            ul {
                                li {
                                    margin-right: 0;

                                    i {
                                        font-size: 14px;
                                        color    : ${colors.yellow};
                                    }

                                    &:last-child {
                                        font-size: 13px;
                                        color    : ${colors.text3};
                                    }
                                }
                            }
                        }
                    }
                }

                &:hover {
                    box-shadow: 0 12px 25px rgba(0, 0, 0, 0.07);
                }
            }
        }

        .dashboard-filter{
            border-radius: 5px;
            -webkit-transition: all 0.2s ease;
            transition: all 0.2s ease;
            padding-top: 10px;

            label{
                font-weight :500;
            }

            .info-filter{
                cursor: pointer;
                margin-bottom: 22px !important;
                box-shadow: rgb(0 0 0 / 15%) 0px 0px 20px;
                a.dropdown-toggle::after{
                    content:none;
                }

                .filter-item{
                    width: 100%;
                    height: 47px;
                    background-color: #ffffff;
                    font-size: 14px;
                    padding: 15px 20px;
                    color: #182B49;
                    border: 1px solid #dddddd;
                    border-radius: 5px;
                    width: 100%;
                    margin: 0 auto;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    span{
                        display: inline-block;
                        flex: auto;
                        position: inherit;
                        color: #182B49;
                        visibility: inherit;
                        font-size: 14px;
                        font-weight: inherit;
                    }  
                    
                    i:before{
                        color: ${colors.green};
                        font-size: 22px;
                    }
                }

                ul{
                    width: auto;
                    min-width: auto;
                }
                
                  width: 100%;
                    height: 35px;
                    background-color: #ffffff;
                    font-size: 14px;
                    color: #182B49;
                    border: 1px solid #dddddd;
                    border-radius: 5px;
                    width: 100%;
                    margin: 0 auto;
                    display: -webkit-box;
                    display: -webkit-flex;
                    display: -ms-flexbox;
                    display: flex;
                    -webkit-box-pack: center;
                    -webkit-justify-content: center;
                    -ms-flex-pack: center;
                    justify-content: center;
                    -webkit-align-items: center;
                    -webkit-box-align: center;
                    -ms-flex-align: center;
                    align-items: center;

                    :focus{
                        box-shadow: 0 0 0 0;
                        border: 1px solid #dddddd;
                    }
            }
        }

        .grid-header{
            color: #182B49;
            font-weight: 600;
            padding-top: 10px;
            padding-bottom: 10px;
            margin-bottom: 20px;
            position: relative;
            &:before {
                position : absolute;
                content : "";
                background : ${colors.green};
                width : 50px;
                height: 2px;
                bottom : 0;
                left : 0;
            }
        }

        @media(max-width: 767px) {
            padding: 40px 0 30px;
        }
    }

    /* Course List */
    .course-list-area {
        padding: 70px 0;
        .course-items2 {
            .course-item {
                border-radius : 5px;
                transition : all 0.2s ease;
                overflow : hidden;
                box-shadow: 0 8px 20px 5px rgba(0,0,0,0.07);
                margin-bottom: 30px;
                box-shadow: rgb(0 0 0 / 15%) 0px 0px 20px;

                .course-image-box {
                    .course-image {
                        width              : 100%;
                        padding-top: 56.25%;
                        background-size    : cover;
                        background-position: center;
                        background-repeat  : no-repeat;
                        border-radius : 5px 5px 0 0;
                        position: relative;

                        .author-img {
                            position: absolute;
                            left    : 20px;
                            top  : 20px;

                            img {
                                max-width: 40px;
                                border-radius : 50%;
                                margin-right: 5px;
                            }

                            .title {
                                background: #ffffff;
                                padding   : 3px 8px;
                                border-radius : 5px;

                                p {
                                    font-size    : 12px;
                                    color        : ${colors.black1};
                                    font-weight  : 500;
                                    margin-bottom: -4px;
                                }

                                span {
                                    font-size  : 11px;
                                    color      : ${colors.text3};
                                    font-weight: 500;
                                }
                            }

                            @media(max-width: 991px) {
                                top  : unset;
                                bottom : 20px;
                            }
                        }

                        .course-price {
                            p {
                                font-size  : 16px;
                                color      : #ffffff;
                                background : ${colors.bg1};
                                position   : absolute;
                                right      : 20px;
                                top     : 20px;
                                padding    : 8px 10px;
                                font-weight: 500;
                                border-radius : 5px;

                                @media(max-width: 991px) {
                                    top  : unset;
                                    bottom : 20px;
                                }
                            }

                            a {
                                font-size  : 16px;
                                color      : #ffffff;
                                background : ${colors.bg1};
                                position   : absolute;
                                right      : 20px;
                                top     : 20px;
                                padding    : 8px 10px;
                                font-weight: 500;
                                border-radius : 5px;

                                @media(max-width: 991px) {
                                    top  : unset;
                                    bottom : 20px;
                                }
                            }
                        }

                        @media(max-width: 991px) {
                            width: 100%;
                        }
                    }
                }

                .course-content {
                    background: #fff;
                    padding   : 10px 15px;

                    h6.heading {
                        padding-bottom: 12px;
                        margin-bottom: 20px;
                        position: relative;
                        &:before {
                            position: absolute;
                            content: "";
                            background: ${colors.green};
                            width: 50px;
                            height: 2px;
                            bottom: 0;
                            left: 0;
                        }

                        a {
                            color        : ${colors.black1};
                            font-weight  : 600;
                            display      : inline-block;

                            &:hover {
                                color: ${colors.green};
                            }
                        }
                    }

                    .rating {
                        margin-bottom: 6px;
                        ul {
                            li {
                                margin-right: 0;

                                i {
                                    font-size: 14px;
                                    color    : ${colors.yellow};
                                }

                                &:last-child {
                                    font-size: 13px;
                                    color    : ${colors.text3};
                                }
                            }
                        }
                    }

                    p.desc {
                        font-size     : 14px;
                        color         : ${colors.text3};
                        line-height   : 25px;
                        margin-bottom : 15px;
                    }

                    a.details-btn {
                        font-size : 13px;
                        color : ${colors.green};
                        border : 1px solid ${colors.border3};
                        padding: 7px 15px;
                        border-radius: 5px;
                        font-weight: 500;
                        &:hover {
                            color : #ffffff;
                            background : ${colors.gr_bg};
                            border-color : ${colors.green};
                        }
                    }
                }

                &:hover {
                    box-shadow: 0 12px 25px rgba(0, 0, 0, 0.1);
                }

                @media(max-width: 991px) {
                    display : block !important;
                }
            }
        }

        @media(max-width: 767px) {
            padding: 40px 0 30px;
        }
    }

    /* Course Details */
    .course-details-area {
        padding : 10px 0 20px;
        .course-details-top {
            .heading {
                h4 {
                    color : ${colors.black1};
                    font-weight: 600;
                    line-height: 35px;
                    margin-bottom: 25px;

                    @media(max-width: 575px) {
                        font-size : 20px;
                    }
                }
            }
            .course-top-overview {
                margin-bottom : 25px;
                .overviews {
                    .author {
                        margin-right : 20px;
                        padding-right : 20px;
                        margin-top: -3px;
                        border-right : 1px solid ${colors.border3};
                        img {
                            float : left;
                            max-width: 50px;
                            border-radius: 50%;
                            margin-right: 10px;
                        }
                        .author-name {
                            float : left;
                            margin-top: 3px;
                            h6 {
                                color : ${colors.black2};
                                font-weight: 600;
                                text-transform: uppercase;
                                margin-bottom: 5px;

                                @media(max-width: 767px) {
                                    font-size : 14px;
                                }
                            }
                            p {
                                font-size : 15px;
                                color : ${colors.text3};
                                font-weight: 500;

                                @media(max-width: 767px) {
                                    font-size : 14px;
                                }
                            }
                        }
                    }

                    .category {
                        margin-right : 20px;
                        padding-right : 20px;
                        border-right : 1px solid ${colors.border3};
                        h6 {
                            color : ${colors.black2};
                            font-weight: 600;
                            text-transform: uppercase;
                            margin-bottom: 5px;

                            @media(max-width: 767px) {
                                font-size : 14px;
                            }
                        }
                        p {
                            font-size : 15px;
                            color : ${colors.text3};
                            font-weight: 500;

                            @media(max-width: 767px) {
                                font-size : 14px;
                            }
                        }
                    }

                    .rating {
                        margin-right : 20px;
                        padding-right : 20px;
                        border-right : 1px solid ${colors.border3};
                        h6 {
                            color : ${colors.black2};
                            font-weight: 600;
                            text-transform: uppercase;
                            margin-bottom: 5px;

                            @media(max-width: 767px) {
                                font-size : 14px;
                            }
                        }
                        ul {
                            li {
                                margin-right: 1px;
                                i {
                                    font-size: 16px;
                                    color: ${colors.yellow};
                                }
                                &:last-child {
                                    font-size : 15px;
                                    color : ${colors.text3};
                                    font-weight: 500;
                                    margin-left: 5px;

                                    @media(max-width: 767px) {
                                        font-size : 14px;
                                    }
                                }
                            }
                        }
                    }

                    .price {
                        h6 {
                            color : ${colors.black2};
                            font-weight: 600;
                            text-transform: uppercase;
                            margin-bottom: 5px;

                            @media(max-width: 767px) {
                                font-size : 14px;
                            }
                        }
                        p {
                            font-size : 15px;
                            color : ${colors.text3};
                            font-weight: 500;

                            @media(max-width: 767px) {
                                font-size : 14px;
                            }
                        }
                    }
                }

                @media(max-width: 480px) {
                    display : none;
                }
            }
            .course-details-banner {
                margin-bottom: 40px;
                img {
                    border-radius : 5px;
                }
            }
            .course-tab-list {
                .nav {
                    display: inline-block;
                    border-radius: 5px;
                    box-shadow: 0 8px 25px rgba(0,0,0,0.1);
                    margin-bottom: 35px;
                    .nav-item {
                        display: inline-block;
                        a.nav-link {
                            font-size: 14px;
                            color: ${colors.black2};
                            font-weight: 500;
                            text-transform : uppercase;
                            padding: 12px 30px 10px;
                            border-radius: 5px;

                            @media(max-width: 991px) {
                                padding: 12px 16px 9px;
                            }
                        }
                        a.nav-link.active {
                            background : ${colors.gr_bg};
                            color : #ffffff;
                        }
                    }
                }
                .tab-content {
                    .overview-tab {
                        h5 {
                            color: ${colors.black2};
                            font-weight: 600;
                            padding-bottom: 10px;
                            margin-bottom: 20px;
                            position: relative;
                            &:before {
                                position: absolute;
                                content: "";
                                background: ${colors.green};
                                width: 50px;
                                height: 2px;
                                bottom: 0;
                                left: 0;
                            }

                            @media(max-width: 575px) {
                                font-size : 17px;
                            }                            
                        }
                        p {
                            font-size: 15px;
                            color: ${colors.text2};
                            line-height: 25px;

                            @media(max-width: 575px) {
                                font-size : 14px;
                            }
                        }

                        .course-desc {
                            margin-bottom: 40px;
                        }

                        .course-feature {
                            margin-bottom: 40px;
                            ul {
                                margin-top: 20px;
                                li {
                                    font-size : 14px;
                                    color: ${colors.text3};
                                    line-height : 25px;
                                    margin-bottom : 10px;
                                    i {
                                        font-size : 20px;
                                        color: ${colors.green};
                                        float: left;
                                        height: 40px;
                                        line-height: 27px;
                                        margin-right: 10px;
                                    }
                                    &:last-child {
                                        margin-bottom: 0;
                                    }
                                }
                            }
                        }
                        .course-learn {
                            margin-bottom: 40px;
                            ul {
                                margin-top: 20px;
                                li {
                                    font-size: 14px;
                                    color: ${colors.text3};
                                    line-height: 25px;
                                    margin-bottom: 15px;
                                    i {
                                        float: left;
                                        color: ${colors.green};
                                        border: 1px solid ${colors.border3};
                                        width: 35px;
                                        height: 35px;
                                        border-radius: 50%;
                                        text-align: center;
                                        padding-top: 9px;
                                        margin-top: 8px;
                                        margin-right: 15px;
                                    }
                                    &:last-child {
                                        margin-bottom: 0;
                                    }
                                }
                            }
                        }
                    }
                    .curriculum-tab {
                        .course-curriculum {
                            margin-bottom: 40px;
                            h5 {
                                color: ${colors.black2};
                                font-weight: 600;
                                padding-bottom: 10px;
                                margin-bottom: 20px;
                                position: relative;
                                &:before {
                                    position: absolute;
                                    content: "";
                                    background: ${colors.green};
                                    width: 50px;
                                    height: 2px;
                                    bottom: 0;
                                    left: 0;
                                }

                                @media(max-width: 575px) {
                                    font-size : 17px;
                                }  
                            }
                            p {
                                font-size: 15px;
                                color: ${colors.text2};
                                line-height: 25px;

                                @media(max-width: 575px) {
                                    font-size : 14px;
                                }
                            }
                        }
                        .course-element {
                            h5 {
                                color: ${colors.black2};
                                font-weight: 600;
                                padding-bottom: 10px;
                                margin-bottom: 20px;
                                position: relative;
                                &:before {
                                    position: absolute;
                                    content: "";
                                    background: ${colors.green};
                                    width: 50px;
                                    height: 2px;
                                    bottom: 0;
                                    left: 0;
                                }

                                @media(max-width: 575px) {
                                    font-size : 17px;
                                }
                            }
                            .course-item {
                                margin-bottom: 10px;
                                button.course-button {
                                    border: none;
                                    background: transparent;
                                    margin-bottom: 15px;
                                    display: block;
                                    width: 100%;
                                    text-align : left;
                                    padding: 0;
                                    font-size : 18px;
                                    color: ${colors.black2};
                                    font-weight: 500;
                                    span {
                                        float: right;
                                        font-size: 14px;
                                        color: ${colors.text3};
                                        font-weight: 400;
                                    }
                                }

                                .course-content {
                                    max-height: 0;
                                    overflow  : hidden;
                                    transition: max-height 0.2s ease-in-out;
                                    ul {
                                        li {
                                            border-bottom : 1px solid ${colors.border3};
                                            margin-left: 25px;
                                            padding: 12px 0;
                                            span.play-icon {
                                                font-size : 14px;
                                                color: ${colors.text3};
                                                margin-right: 20px;
                                                i {
                                                    color: ${colors.green};
                                                    border: 1px solid ${colors.green};
                                                    font-size: 22px;
                                                    width: 30px;
                                                    height: 30px;
                                                    border-radius: 50%;
                                                    padding-left: 3px;
                                                    text-align: center;
                                                    line-height: 29px;
                                                    vertical-align: middle;
                                                    margin-right: 10px;
                                                }
                                            }
                                            span.lecture-title {
                                                font-size : 15px;
                                                color: ${colors.black2};

                                                @media(max-width: 575px) {
                                                    font-size : 14px;
                                                }
                                            }
                                            span.lecture-duration {
                                                float: right;
                                                font-size: 14px;
                                                color: ${colors.text3};
                                                line-height: 28px;
                                                font-weight: 400;
                                            }
                                        }
                                    }
                                }

                                .course-content.show {
                                    max-height: 100%;
                                    margin-bottom: 40px;
                                }

                                &:last-child {
                                    margin-bottom: 40px;
                                }
                            }
                        }
                    }

                    .instructor-tab {
                        h5 {
                            color: ${colors.black2};
                            font-weight: 600;
                            padding-bottom: 10px;
                            margin-bottom: 35px;
                            position: relative;
                            &:before {
                                position: absolute;
                                content: "";
                                background: ${colors.green};
                                width: 50px;
                                height: 2px;
                                bottom: 0;
                                left: 0;
                            }

                            @media(max-width: 575px) {
                                font-size : 17px;
                            }
                        }

                        .instructor-item {
                            margin-bottom: 30px;

                            .instructor-img {
                                img {
                                    border-radius : 5px;
                                }
                            }

                            .instructor-content {
                                position: relative;

                                .instructor-box {
                                    box-shadow: 0 0px 20px rgba(0, 0, 0, 0.08);
                                    padding   : 25px;
                                    background: #ffffff;
                                    border-radius : 5px;
                                    position: absolute;
                                    top     : 32px;
                                    left    : -11%;
                                    z-index : 1;

                                    .top-content {
                                        margin-bottom: 20px;

                                        .instructor-name {
                                            h6 {
                                                color      : ${colors.black2};
                                                font-weight: 600;
                                                text-transform: uppercase;
                                                margin-bottom: 12px;

                                                @media(max-width: 575px) {
                                                    font-size : 14px;
                                                }
                                            }
                                            p {
                                                font-size  : 14px;
                                                color      : ${colors.text3};
                                                font-weight: 500;
                                            }
                                        }
                                        .instructor-social {
                                            margin-top: 25px;
                                            ul.social {
                                                li {
                                                    a {
                                                        text-align: center;
                                                        position  : relative;

                                                        &:before {
                                                            content           : "";
                                                            position          : absolute;
                                                            border-width      : 8px 14px;
                                                            border-style      : solid;
                                                            border-top-color  : transparent;
                                                            border-right-color: transparent;
                                                            border-left-color : transparent;
                                                            top               : -16px;
                                                            left              : 0;
                                                            z-index           : 1;
                                                            transition : all 0.2s ease;
                                                        }

                                                        &:after {
                                                            content            : "";
                                                            position           : absolute;
                                                            border-width       : 8px 14px;
                                                            border-style       : solid;
                                                            border-right-color : transparent;
                                                            border-bottom-color: transparent;
                                                            border-left-color  : transparent;
                                                            bottom             : -16px;
                                                            left               : 0;
                                                            z-index            : 1;
                                                            transition : all 0.2s ease;
                                                        }

                                                        i {
                                                            font-size: 13px;
                                                            color    : #ffffff;
                                                            width    : 28px;
                                                        }

                                                        &:hover {
                                                            background-color: ${colors.green} !important;

                                                            &:before {
                                                                border-bottom-color: ${colors.green} !important;
                                                            }

                                                            &:after {
                                                                border-top-color: ${colors.green} !important;
                                                            }
                                                        }
                                                    }

                                                    &:nth-child(1) {
                                                        a {
                                                            background-color: #4267B2;

                                                            &:before {
                                                                border-bottom-color: #4267B2;
                                                            }

                                                            &:after {
                                                                border-top-color: #4267B2;
                                                            }
                                                        }
                                                    }

                                                    &:nth-child(2) {
                                                        a {
                                                            background-color: #1DA1F2;

                                                            &:before {
                                                                border-bottom-color: #1DA1F2;
                                                            }

                                                            &:after {
                                                                border-top-color: #1DA1F2;
                                                            }
                                                        }
                                                    }

                                                    &:nth-child(3) {
                                                        a {
                                                            background-color: #2867B2;

                                                            &:before {
                                                                border-bottom-color: #2867B2;
                                                            }

                                                            &:after {
                                                                border-top-color: #2867B2;
                                                            }
                                                        }
                                                    }

                                                    &:nth-child(4) {
                                                        a {
                                                            background-color: #DD1343;

                                                            &:before {
                                                                border-bottom-color: #DD1343;
                                                            }

                                                            &:after {
                                                                border-top-color: #DD1343;
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }

                                    .instructor-desk {
                                        p {
                                            font-size : 15px;
                                            color      : ${colors.text2};
                                            line-height: 25px;

                                            @media(max-width: 575px) {
                                                font-size : 14px;
                                            }
                                        }
                                    }
                                }
                            }

                            &:last-child {
                                margin-bottom: 0;
                            }
                        }
                    }

                    .review-tab {
                        .review-comments {
                            margin-bottom: 40px;
                            h5 {
                                color: ${colors.black2};
                                font-weight: 600;
                                padding-bottom: 10px;
                                margin-bottom: 35px;
                                position: relative;
                                &:before {
                                    position: absolute;
                                    content: "";
                                    background: ${colors.green};
                                    width: 50px;
                                    height: 2px;
                                    bottom: 0;
                                    left: 0;
                                }

                                @media(max-width: 575px) {
                                    font-size : 17px;
                                }
                            }
                            .comment-box {
                                border-bottom: 1px solid ${colors.border1};
                                padding-bottom: 20px;
                                margin-bottom: 25px;
                                .comment-image {
                                    img {
                                        max-width : 100px;
                                        border-radius : 5px;
                                        margin-right : 20px;
                                    }
                                }
                                .comment-content {
                                    .content-title {
                                        .comment-writer {
                                            h6 {
                                                color: ${colors.black2};
                                                font-weight: 600;
                                                margin-bottom : 10px;

                                                @media(max-width: 575px) {
                                                    font-size : 14px;
                                                }
                                            }
                                            p {
                                                font-size : 12px;
                                                color: ${colors.text3};
                                                margin-bottom: 5px;
                                            }
                                            ul {
                                                margin-bottom: 8px;
                                                li {
                                                    margin-right: 1px;
                                                    i {
                                                        font-size: 16px;
                                                        color: ${colors.yellow};
                                                    }
                                                    &:last-child {
                                                        font-size: 13px;
                                                        color: ${colors.text2};
                                                        margin-left: 5px;
                                                    }
                                                }
                                            }
                                        }
                                        .reply-btn {
                                            button {
                                                font-size : 14px;
                                                color: ${colors.green};
                                                background : transparent;
                                                border : 1px solid ${colors.border3};
                                                font-weight: 500;
                                                border-radius: 25px;
                                                padding: 4px 12px 3px;
                                                margin-top : 10px;
                                                i {
                                                    font-size: 17px;
                                                    vertical-align: text-top;
                                                }
                                                &:hover {
                                                    color : #ffffff;
                                                    background : ${colors.gr_bg};
                                                    border-color : ${colors.green};
                                                }
                                            }
                                        }
                                    }
                                    .comment-desc {
                                        p {
                                            font-size: 14px;
                                            color: ${colors.text3};
                                            line-height: 25px;
                                        }
                                    }
                                }
                                &:last-child {
                                    border-bottom : none;
                                    padding-bottom : 0;
                                    margin-bottom : 0;
                                }
                            }
                        }

                        .review-form {
                            h5 {
                                color: ${colors.black2};
                                font-weight: 600;
                                padding-bottom: 10px;
                                margin-bottom: 25px;
                                position: relative;
                                &:before {
                                    position: absolute;
                                    content: "";
                                    background: ${colors.green};
                                    width: 50px;
                                    height: 2px;
                                    bottom: 0;
                                    left: 0;
                                }

                                @media(max-width: 575px) {
                                    font-size : 17px;
                                }
                            }
                        }
                    }
                }
            }

            @media(max-width: 767px) {
                margin-bottom: 40px;
            }
        }

        .single-details-sidbar {
            .course-details-feature {
                box-shadow: 0 8px 25px rgba(0,0,0,0.1);
                border-radius: 5px;
                padding: 15px 20px 20px;
                h5.title {
                    color: ${colors.black1};
                    font-weight: 600;
                    padding-bottom: 10px;
                    margin-bottom: 20px;
                    position: relative;
                    &:before {
                        position: absolute;
                        content: "";
                        background: ${colors.green};
                        width: 50px;
                        height: 2px;
                        bottom: 0;
                        left: 0;
                    }

                    @media(max-width: 575px) {
                        font-size : 17px;
                    }
                }

                ul.feature-list {
                    margin-bottom: 20px;
                    li {
                        border-top: 1px dashed ${colors.border3};
                        padding: 12px 0;
                        font-size : 14px;
                        color : ${colors.black2};
                        font-weight: 500;
                        i {
                            font-size : 20px;
                            color: ${colors.green};
                            vertical-align: top;
                            margin-right : 2px;
                        }
                        span {
                            float : right;
                            font-size: 13px;
                            color: ${colors.text3};
                            font-weight: 400;
                            line-height: 20px;
                        }
                        &:first-child {
                            border-top : none;
                            padding-top : 0;
                        }
                        &:last-child {
                            padding-bottom : 0;
                        }
                    }
                }

                button.enroll-btn {
                    font-size: 16px;
                    color: #fff;
                    background: ${colors.gr_bg};
                    display: inline-block;
                    width: 100%;
                    height: 40px;
                    font-weight : 500;
                    border : none;
                    padding: 9px;
                    border-radius: 5px;
                    &:hover {
                        background: ${colors.gr_bg2};
                    }

                    @media(max-width: 575px) {
                        font-size : 14px;
                    }
                }

                .course-share {
                    margin-top: 30px;
                    text-align: center;
                    
                    ul.social {
                        margin-top: 30px;
                        li {
                            a {
                                text-align: center;
                                position  : relative;
                                height    : 18px;
                                display   : inline-block;

                                &:before {
                                    content           : "";
                                    position          : absolute;
                                    border-width      : 9px 17px;
                                    border-style      : solid;
                                    border-top-color  : transparent;
                                    border-right-color: transparent;
                                    border-left-color : transparent;
                                    top               : -18px;
                                    left              : 0;
                                    z-index           : 1;
                                    transition : all 0.2s ease;
                                }

                                &:after {
                                    content            : "";
                                    position           : absolute;
                                    border-width       : 9px 17px;
                                    border-style       : solid;
                                    border-right-color : transparent;
                                    border-bottom-color: transparent;
                                    border-left-color  : transparent;
                                    bottom             : -18px;
                                    left               : 0;
                                    z-index            : 1;
                                    transition : all 0.2s ease;
                                }

                                i {
                                    font-size: 14px;
                                    color    : #ffffff;
                                    width    : 34px;
                                }

                                &:hover {
                                    background-color: ${colors.green} !important;

                                    &:before {
                                        border-bottom-color: ${colors.green} !important;
                                    }

                                    &:after {
                                        border-top-color: ${colors.green} !important;
                                    }
                                }
                            }

                            &:nth-child(1) {
                                a {
                                    background-color: #4267B2;

                                    &:before {
                                        border-bottom-color: #4267B2;
                                    }

                                    &:after {
                                        border-top-color: #4267B2;
                                    }
                                }
                            }

                            &:nth-child(2) {
                                a {
                                    background-color: #1DA1F2;

                                    &:before {
                                        border-bottom-color: #1DA1F2;
                                    }

                                    &:after {
                                        border-top-color: #1DA1F2;
                                    }
                                }
                            }

                            &:nth-child(3) {
                                a {
                                    background-color: #2867B2;

                                    &:before {
                                        border-bottom-color: #2867B2;
                                    }

                                    &:after {
                                        border-top-color: #2867B2;
                                    }
                                }
                            }

                            &:nth-child(4) {
                                a {
                                    background-color: #DD1343;

                                    &:before {
                                        border-bottom-color: #DD1343;
                                    }

                                    &:after {
                                        border-top-color: #DD1343;
                                    }
                                }
                            }

                            &:nth-child(5) {
                                a {
                                    background-color: #ea4c89;

                                    &:before {
                                        border-bottom-color: #ea4c89;
                                    }

                                    &:after {
                                        border-top-color: #ea4c89;
                                    }
                                }
                            }
                        }
                    }
                }

                @media(max-width: 1199px) {
                    padding: 12px 15px 15px;
                }
            }
        }

        @media(max-width: 767px) {
            padding: 20px 0 5px;
        }
    }
    .teacher-kit {
       .apply-btn {
            font-size     : 13px;
            color         : #ffffff;
            background    : ${colors.gr_bg};
            display       : inline-block;
            height        : 40px;
            text-align    : center;
            text-transform: uppercase;
            font-weight   : 500;
            padding       : 11px;
            border-radius : 5px;
            border: none;
            position: relative;
            float: right;

            i {
                position : absolute;
                font-size: 20px;
                left     : -40px;
                top      : 0;
                padding  : 10px;
                width    : 40px;
                height   : 100%;
                border-radius : 5px 0 0 5px;
                background-color: ${colors.green};
            }

            &:hover {
                background: ${colors.gr_bg2};
            }
        }

        @media(max-width: 991px) {
            display : none;
        }
    }

    .login-box {
        max-width : 500px;
        margin: auto;
        border-radius: 5px;
        .login-title {
            h3 {
                color : ${colors.black2};
                text-transform: uppercase;
                font-weight: 600;
                padding-bottom: 10px;
                margin-bottom: 20px;
                position: relative;
                &:before {
                    position: absolute;
                    content: "";
                    background: ${colors.green};
                    width: 50px;
                    height: 2px;
                    bottom: 0;
                    left: 50%;
                    margin-left: -25px;
                }

                @media(max-width: 575px) {
                    font-size : 20px;
                }
            }
        }

        form.form {
            
            .btn-save-info{
                font-size: 13px;
                color: #ffffff;
                background: ${colors.gr_bg};
                display: inline-block;
                height: 40px;
                text-align: center;
                text-transform: uppercase;
                font-weight: 500;
                padding: 11px;
                border-radius: 5px 5px;
                position: relative;
                width: 100%;
            }

            .form-control {
                padding      : 0;
                width        : auto;
                height       : auto;
                background   : transparent;
                border       : none;
                margin-bottom: 28px;
                position     : relative;

                .pad-0{
                    padding:0;
                }
                label {
                    font-size : 15px;
                    color : ${colors.text1};
                    font-weight : 500;

                    @media(max-width: 575px) {
                        font-size : 14px;
                    }
                }

                input {
                    width           : 100%;
                    height          : 48px;
                    background-color: #ffffff;
                    font-size       : 14px;
                    padding         : 15px 20px;
                    color           : ${colors.black1};
                    border          : 1px solid ${colors.border3};
                    border-radius : 5px;

                    &::placeholder {
                        font-size : 14px;
                        font-style: italic;
                        color     : ${colors.text3};

                        @media(max-width: 575px) {
                            font-size : 13px;
                        }
                    }

                    &:focus {
                        border-color : ${colors.green};
                    }

                    @media(max-width: 575px) {
                        height : 40px;
                    }
                }

                span {
                    color      : ${colors.red};
                    font-weight: 300;
                    position   : absolute;
                    bottom     : -20px;
                    left       : 0;
                    visibility : hidden;
                }

                .country-filter{
                    width: 100%;
                    height: 48px;
                    background-color: #ffffff;
                    font-size: 14px;
                    padding: 15px 0px;
                    color: #182B49;
                    border-radius: 5px;

                    a.dropdown-toggle::after{
                        content:none;
                    }
                }  
                
                .info-filter{
                    cursor: pointer;
                    a.dropdown-toggle::after{
                        content:none;
                    }

                    .filter-item{
                        width: 100%;
                        height: 48px;
                        background-color: #ffffff;
                        font-size: 14px;
                        padding: 15px 20px;
                        color: #182B49;
                        border: 1px solid #dddddd;
                        border-radius: 5px;
                        width: 100%;
                        margin: 0 auto;
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        span{
                            display: inline-block;
                            flex: auto;
                            position: inherit;
                            color: #182B49;
                            visibility: inherit;
                            font-size: 14px;
                            font-weight: inherit;
                        }                                    
                    }

                    ul{
                        width: auto;
                        min-width: auto;
                        max-height: 200px;
                        overflow: auto;
                    }
                }

                .error {
                    input {
                        border: 2px solid ${colors.red};
                    }

                    a {
                        border: 2px solid ${colors.red} !important;
                    }

                    &::before {
                        position   : absolute;
                        content    : "\f06a";
                        font-family: "Line Awesome Free";
                        font-size  : 24px;
                        color      : ${colors.red};
                        font-weight: 900;
                        top        : 43px;
                        right      : 10px;
                    }
                }

                .error {
                    span {
                        visibility: visible !important;
                        font-weight:400;
                    }

                    .pad-left-15{
                        padding-left: 15px;
                    }
                }

                .error-icon-info {
                    &::before {
                        top: 72px;
                        right: 15px;
                    }
                }
            }

            .form-control.success {
                input {
                    border: 2px solid ${colors.green};
                }

                &::before {
                    position   : absolute;
                    content    : "\f058";
                    font-family: "Line Awesome Free";
                    font-size  : 24px;
                    color      : ${colors.green};
                    font-weight: 900;
                    top        : 34px;
                    right      : 10px;
                }
            }
            


            button {
                .css1r4vtzz{
                    width: 100% !important;
                    margin-bottom: 25px;
                    border: 1px solid #ccc;
                    border-radius: 5px;
                    box-shadow: none;
                    padding: 15px;
                }
                .btn-save-info{
                    font-size  : 13px;
                    color      : #fff;
                    background : ${colors.gr_bg};
                    width      : 100%;
                    height     : 40px;
                    font-weight: 500;
                    border     : none;
                    border-radius : 5px;
                    text-transform: uppercase;
                    margin-bottom : 20px;

                    &:hover {
                        background: ${colors.gr_bg2};

                        i {
                            color: #ffffff;
                        }
                    }

                    @media(max-width: 575px) {
                        font-size : 15px;
                        height     : 40px;
                    }
                }
                
            }

            .save-forget-password {
                margin-bottom: 15px;
                .save-passowrd {
                    label {
                        font-size: 14px;
                        color: ${colors.text3};
                        display: block;
                        font-weight : 500;
                        margin-bottom : 0;
                        cursor: pointer;

                        input[type=checkbox] {
                            border : 2px solid ${colors.border3};
                            appearance: none;
                            width: 18px;
                            height: 18px;
                            cursor: pointer;
                            margin-right: 6px;
                            position: relative;
                            top: 4px;

                            &:focus {
                                outline: none;
                            }

                            &:checked {
                                background-color: ${colors.green};
                                background: ${colors.green} url("data:image/gif;base64,R0lGODlhCwAKAIABAP////3cnSH5BAEKAAEALAAAAAALAAoAAAIUjH+AC73WHIsw0UCjglraO20PNhYAOw==") 2px 2px no-repeat;
                                border-color : ${colors.green};
                            }
                        }
                    }
                }
                .forget-password {
                    margin-top: 3px;
                    a {
                        font-size : 14px;
                        color : ${colors.green};
                        font-weight : 500;
                        &:hover {
                            text-decoration : underline;
                        }
                    }
                }
            }

            .not_account-btn {
                border-bottom: 1px solid ${colors.border1};
                margin-bottom: 20px;
                padding-bottom: 20px;
                p {
                    font-size : 14px;
                    color     : ${colors.text3};
                    a {
                        font-size : 14px;
                        color : ${colors.green};
                        font-weight : 500;
                        &:hover {
                            text-decoration : underline;
                        }
                    }
                }
            }

            .social-login {
                p {
                    font-size : 14px;
                    color     : ${colors.text3};
                    margin-bottom : 15px;
                }
                ul {
                    li {
                        a {
                            font-size: 14px;
                            color: #ffffff;
                            display: inline-block;
                            width: 110px;
                            height: 40px;
                            border-radius: 30px;
                            padding-top: 10px;
                            i {
                                margin-right : 3px;
                            }
                            &:hover {
                                background: ${colors.green} !important;
                            }import { colors } from './../../../components/common/element/elements';

                        }
                        &:nth-child(1) {
                            a {
                                background: #DB4437;
                            }
                        }
                        &:nth-child(2) {
                            a {
                                background: #4267B2;
                            }
                        }
                        &:nth-child(3) {
                            a {
                                background: #1DA1F2;
                            }
                        }
                    }
                }
            }
        }
    }

    .multi-select{
        button{
            width: 100%;
            height: 47px;
            background-color: #ffffff;
            font-size: 14px;
            padding: 15px 20px;
            color: #182B49;
            border: 1px solid #dddddd;
            border-radius: 5px;
            width: 100%;
            margin: 0 auto;
            display: flex;
            justify-content: center;
            align-items: center;

            svg{
                display: none;
            }
        }

        button + div{
            width: 100%;
        }
    }

    .quiz {

        p, div {
            font-size: 16px;
            color: ${colors.black1};
            font-weight: 300;
        }
    }
    
    .MuiAccordionSummary-content {
        justify-content: center;
    }

    .total_score{
        font-size: 22px;
        text-align: center;
        padding: 10px 0;
    }
    .progress_bar{
        padding: 10px;
    }
    .pull-right{
        float: right;   
    }
    .summary-value {
        font-weight: 300;
        font-size: 20px;
        line-height: 28px;
    }
    .retry_button {
        ${colors.gredient_blue_bg}
    }
    .share_button {
        ${colors.gredient_red_bg}
    }
    .dashboard_button {
        ${colors.gredient_green_bg}
    }
    .accordion_purple{
        ${colors.gredient_blue_bg}
    }

    table.table {
        margin-bottom: 0;
        thead {
            tr {
                th {
                    border: none;
                    padding: 20px 10px;
                    font-size: 15px;
                    color: ${colors.black1};
                }
            }
        }
        tbody {
            tr {
                td {
                    border: none;
                    border-top: 1px solid ${colors.border3};
                    padding: 25px 10px;
                    color: ${colors.text2};
                    vertical-align: middle;
                }
                td.product-thumbnail {
                    img {
                        width: 75px;
                        height: auto;
                        border-radius: 5px;
                    }
                }
                td.product-title {
                    a {
                        font-size : 14px;
                        color: ${colors.black1};
                        font-weight : 500;
                        &:hover {
                            color: ${colors.green};
                        }
                    }
                }
                td.product-price {
                    span {
                        font-size : 15px;
                        color: ${colors.black1};
                    }
                }
                td.product-quantity {
                    input.form-control {
                        padding: 10px;
                        height: 38px;
                        width: 58px;
                        font-size: 14px;
                        border-color: ${colors.border1};
                        color: ${colors.text2};
                        border-radius: 5px;
                        &:focus {
                            box-shadow : none;
                            border-color: ${colors.green};
                        }
                    }
                }
                td.product-subtotal {
                    span {
                        font-size : 14px;
                        color: ${colors.black1};
                    }
                }
            }
        }
    }

    .cart-summary {
        .cs-title {
            h5 {
                color: ${colors.black1};
                font-weight: 600;
                padding-bottom : 10px;
                margin-bottom: 20px;
                position: relative;
                margin-left: 0;
                margin-top: 18px;
                font-size: 16px;
                &:before {
                    position: absolute;
                    content: "";
                    background: ${colors.green};
                    width: 50px;
                    height: 2px;
                    bottom: 0;
                    left: 42%;
                }
            }
        }

        .cs-content {
            ul {
                border-bottom: 1px solid ${colors.text2};
                padding-bottom: 10px;
                margin-bottom: 10px;
                li {
                    font-size: 14px;
                    color: ${colors.black2};
                    font-weight: 500;
                    margin-bottom: 10px;
                    span {
                        float : right;
                        font-weight: 400;
                    }
                    &:nth-child(4) {
                        color: ${colors.red};
                    }
                    &:last-child {
                        margin-bottom : 0;
                    }
                }
            }
            p.cart-total {
                font-size: 15px;
                color: ${colors.black1};
                font-weight: 500;
                margin-bottom: 32px;
                span {
                    float : right;
                }

                @media(max-width: 991px) {
                    margin-bottom: 18px;
                }
            }
            button.checkout-btn {
                font-size: 16px;
                color: #ffffff;
                background: ${colors.gr_bg};
                display: inline-block;
                width: 100%;
                height: 45px;
                border: none;
                border-radius: 5px;
                &:hover {
                    background: ${colors.gr_bg2};
                }
            }
        }
    }

    .table-text{
        font-size: 14px;
        color: ${colors.black2};
    }
    .olympiad-quiz img{
        max-width: 100%;
    }
    .refer-code {
        text-align: center;   
        background: white;
        border-radius: 5px;
        box-shadow: 0 12px 25px rgb(0 0 0 / 20%)   
    }
    .orange-bg {
        ${colors.gredient_orange_bg}
    }
    .MuiDataGrid-root{
        background: white;
        box-shadow: 0 12px 25px rgb(0,0,0,0.20);
    }
    .MuiButton-root{
        ${colors.gredient_orange_bg}
        color: white;
        box-shadow: 0 12px 25px rgb(0 0 0 / 20%);
        border: none;
    }
    form.MuiBox-root {
        background: white;
        padding: 10px;
        box-shadow: 0 12px 25px rgb(0 0 0 / 20%);
        border-radius: 5px;
        .MuiTextField-root{
            margin: 0;
        }
    }
`;
