import { showLoaderAction, stopLoaderAction } from "../user/action";
import { orderDetails } from "./service";

/**
 * Action types
 */
export const actionTypes = {
  ORDER_DETAILS_REQUEST_SUCCESS: "ORDER_DETAILS_REQUEST_SUCCESS"
};

export const receivedOrderDetailsAction = (payload) => ({
  type: actionTypes.ORDER_DETAILS_REQUEST_SUCCESS,
  payload,
});

export const orderDetailsAction = (data) => {
  return (dispatch) => {
    dispatch(showLoaderAction("orderDetails"));

    return orderDetails(data)
      .then((response) => {
        dispatch(stopLoaderAction("orderDetails"));
        dispatch(receivedOrderDetailsAction(response));
      })
      .catch((error) => {
        dispatch(stopLoaderAction("orderDetails"));
      });
  };
};