import { showLoaderAction, stopLoaderAction } from "../user/action";
import { getChapterTheory, getChapterExercises, getChapterVideo, getModuleTheory, getModuleConcept, getModuleProgramming, getModuleHardwareBuilding, getModuleSoftwareBuilding, getModuleCoreFundamentals, getModuleSoftwareSetup } from "./service";
/**
 * Action types
 */
export const actionTypes = {
  CHAPTER_THEORY_DATA_REQUEST_SUCCESS: "CHAPTER_THEORY_DATA_REQUEST_SUCCESS",
  CHAPTER_EXERCISES_DATA_REQUEST_SUCCESS: "CHAPTER_EXERCISES_DATA_REQUEST_SUCCESS",
  CHAPTER_VIDEO_DATA_REQUEST_SUCCESS: "CHAPTER_VIDEO_DATA_REQUEST_SUCCESS",
  MODULE_THEORY_DATA_REQUEST_SUCCESS: "MODULE_THEORY_DATA_REQUEST_SUCCESS",
  MODULE_CONCEPT_DATA_REQUEST_SUCCESS: "MODULE_CONCEPT_DATA_REQUEST_SUCCESS",
  MODULE_PROGRAMMING_DATA_REQUEST_SUCCESS: "MODULE_PROGRAMMING_DATA_REQUEST_SUCCESS",
  MODULE_HARDWARE_BUILDING_DATA_REQUEST_SUCCESS: "MODULE_HARDWARE_BUILDING_DATA_REQUEST_SUCCESS",
  MODULE_SOFTWARE_BUILDING_DATA_REQUEST_SUCCESS: "MODULE_SOFTWARE_BUILDING_DATA_REQUEST_SUCCESS",
  MODULE_CORE_FUNDAMENTALS_DATA_REQUEST_SUCCESS: "MODULE_CORE_FUNDAMENTALS_DATA_REQUEST_SUCCESS",
  MODULE_SOFTWARE_SETUP_DATA_REQUEST_SUCCESS: "MODULE_SOFTWARE_SETUP_DATA_REQUEST_SUCCESS",
};

export const receivedChapterTheoryDataAction = (payload) => ({
  type: actionTypes.CHAPTER_THEORY_DATA_REQUEST_SUCCESS,
  payload,
});

export const getChapterTheoryAction = (data) => {
  return (dispatch) => {
    dispatch(showLoaderAction("getChapterTheoryData"));

    return getChapterTheory(data)
      .then((response) => {
        dispatch(stopLoaderAction("getChapterTheoryData"));

        if (response) dispatch(receivedChapterTheoryDataAction(response));
      })
      .catch((error) => {
        dispatch(stopLoaderAction("getChapterTheoryData"));
      });
  };
};

export const receivedChapterExercisesDataAction = (payload) => ({
  type: actionTypes.CHAPTER_EXERCISES_DATA_REQUEST_SUCCESS,
  payload,
});

export const getChapterExercisesAction = (data) => {
  return (dispatch) => {
    dispatch(showLoaderAction("getChapterExercisesData"));

    return getChapterExercises(data)
      .then((response) => {
        dispatch(stopLoaderAction("getChapterExercisesData"));

        if (response) dispatch(receivedChapterExercisesDataAction(response));
      })
      .catch((error) => {
        dispatch(stopLoaderAction("getChapterExercisesData"));
      });
  };
};

export const receivedChapterVideoDataAction = (payload) => ({
  type: actionTypes.CHAPTER_VIDEO_DATA_REQUEST_SUCCESS,
  payload,
});

export const getChapterVideoAction = (data) => {
  return (dispatch) => {
    dispatch(showLoaderAction("getChapterVideoData"));

    return getChapterVideo(data)
      .then((response) => {
        dispatch(stopLoaderAction("getChapterVideoData"));

        if (response) dispatch(receivedChapterVideoDataAction(response));
      })
      .catch((error) => {
        dispatch(stopLoaderAction("getChapterVideoData"));
      });
  };
};

export const receivedModuleTheoryDataAction = (payload) => ({
  type: actionTypes.MODULE_THEORY_DATA_REQUEST_SUCCESS,
  payload,
});

export const getModuleTheoryAction = (data) => {
  return (dispatch) => {
    dispatch(showLoaderAction("getModuleTheoryData"));

    return getModuleTheory(data)
      .then((response) => {
        dispatch(stopLoaderAction("getModuleTheoryData"));

        if (response) dispatch(receivedModuleTheoryDataAction(response));
      })
      .catch((error) => {
        dispatch(stopLoaderAction("getModuleTheoryData"));
      });
  };
};

export const receivedModuleConceptDataAction = (payload) => ({
  type: actionTypes.MODULE_CONCEPT_DATA_REQUEST_SUCCESS,
  payload,
});

export const getModuleConceptAction = (data) => {
  return (dispatch) => {
    dispatch(showLoaderAction("getModuleConceptData"));

    return getModuleConcept(data)
      .then((response) => {
        dispatch(stopLoaderAction("getModuleConceptData"));

        if (response) dispatch(receivedModuleConceptDataAction(response));
      })
      .catch((error) => {
        dispatch(stopLoaderAction("getModuleConceptData"));
      });
  };
};

export const receivedModuleProgrammingDataAction = (payload) => ({
  type: actionTypes.MODULE_PROGRAMMING_DATA_REQUEST_SUCCESS,
  payload,
});

export const getModuleProgrammingAction = (data) => {
  return (dispatch) => {
    dispatch(showLoaderAction("getModuleProgrammingData"));

    return getModuleProgramming(data)
      .then((response) => {
        dispatch(stopLoaderAction("getModuleProgrammingData"));

        if (response) dispatch(receivedModuleProgrammingDataAction(response));
      })
      .catch((error) => {
        dispatch(stopLoaderAction("getModuleProgrammingData"));
      });
  };
};

export const receivedModuleHardwareBuildingDataAction = (payload) => ({
  type: actionTypes.MODULE_HARDWARE_BUILDING_DATA_REQUEST_SUCCESS,
  payload,
});

export const getModuleHardwareBuildingAction = (data) => {
  return (dispatch) => {
    dispatch(showLoaderAction("getModuleHardwareBuildingData"));

    return getModuleHardwareBuilding(data)
      .then((response) => {
        dispatch(stopLoaderAction("getModuleHardwareBuildingData"));

        if (response) dispatch(receivedModuleHardwareBuildingDataAction(response));
      })
      .catch((error) => {
        dispatch(stopLoaderAction("getModuleHardwareBuildingData"));
      });
  };
};

export const receivedModuleSoftwareBuildingDataAction = (payload) => ({
  type: actionTypes.MODULE_SOFTWARE_BUILDING_DATA_REQUEST_SUCCESS,
  payload,
});

export const getModuleSoftwareBuildingAction = (data) => {
  return (dispatch) => {
    dispatch(showLoaderAction("getModuleSoftwareBuildingData"));

    return getModuleSoftwareBuilding(data)
      .then((response) => {
        dispatch(stopLoaderAction("getModuleSoftwareBuildingData"));

        if (response) dispatch(receivedModuleSoftwareBuildingDataAction(response));
      })
      .catch((error) => {
        dispatch(stopLoaderAction("getModuleSoftwareBuildingData"));
      });
  };
};

export const receivedModuleCoreFundamentalsDataAction = (payload) => ({
  type: actionTypes.MODULE_CORE_FUNDAMENTALS_DATA_REQUEST_SUCCESS,
  payload,
});

export const getModuleCoreFundamentalsAction = (data) => {
  return (dispatch) => {
    dispatch(showLoaderAction("getModuleCoreFundamentalsData"));

    return getModuleCoreFundamentals(data)
      .then((response) => {
        dispatch(stopLoaderAction("getModuleCoreFundamentalsData"));

        if (response) dispatch(receivedModuleCoreFundamentalsDataAction(response));
      })
      .catch((error) => {
        dispatch(stopLoaderAction("getModuleCoreFundamentalsData"));
      });
  };
};

export const receivedModuleSoftwareSetupDataAction = (payload) => ({
  type: actionTypes.MODULE_SOFTWARE_SETUP_DATA_REQUEST_SUCCESS,
  payload,
});

export const getModuleSoftwareSetupAction = (data) => {
  return (dispatch) => {
    dispatch(showLoaderAction("getModuleSoftwareSetupData"));

    return getModuleSoftwareSetup(data)
      .then((response) => {
        dispatch(stopLoaderAction("getModuleSoftwareSetupData"));

        if (response) dispatch(receivedModuleSoftwareSetupDataAction(response));
      })
      .catch((error) => {
        dispatch(stopLoaderAction("getModuleSoftwareSetupData"));
      });
  };
};
