import React from "react";
import { Button } from "@mui/material";
import { Col, Container, Row } from "react-bootstrap";
import { Styles } from "./styles";

const ThankYou = (props) => {
  return (
    <Styles>
      <div className="study_material-bg">
        <section className="logo-area2">
          <Container>
            <Row>
              <Col md="12">
                <div className="logo">
                  <img src={process.env.PUBLIC_URL + "/assets/images/logo2.webp"} alt="" style={{ maxWidth: "200px" }} />
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        <div className="price-filter">
          <header className="site-header" id="header">
            <h1 className="site-header__title" data-lead-id="site-header-title">
              THANK YOU!
            </h1>
          </header>
          <div className="main-content">
            <i className="fa fa-check main-content__checkmark" id="checkmark" style={{ color: "white" }}></i>
            <p className="main-content__body" data-lead-id="main-content-body">
              Thanks for filling that out. It means a lot to us, just like you do! We really appreciate you giving us a moment of your time today. We
              will connect you soon.
            </p>
            <Button
              variant="contained"
              size="large"
              style={{}}
              className={"mt-5 mb-3 orange-bg"}
              onClick={(e) => {
                e.preventDefault();
                window.location.href = "https://ealyss.com";
              }}
            >
              Go To Home
            </Button>
          </div>
        </div>
      </div>
    </Styles>
  );
};

export default ThankYou;
