import styled from "styled-components";
import { colors, fonts } from "../../components/common/element/elements.js";

export const Styles = styled.div`
  .orange-bg {
    ${colors.gredient_orange_bg}
    svg {
      color: #fff;
    }
  }
  .video-wrapper {
    position: relative;
    max-height: 600px;
    overflow: hidden;
    @media (max-width: 1366px) {
      max-height: 450px;
    }
    @media (max-width: 767px) {
      height: 350px;
    }
    video {
      @media (max-width: 767px) {
        object-fit: cover;
        height: 100%;
      }
    }
  }
  .video-overlay {
    background: rgb(0 0 0 / 85%);
    height: 100%;
    position: absolute;
    width: 100%;
    top: 0;
    display: flex;
    justify-content: center;
    align-content: center;
    color: white;
    flex-direction: column;
    text-align: center;
  }
  .banner-title {
    font-size: 36px;
    font-weight: 600;
    text-transform: uppercase;
  }
  .banner-subtitle {
    font-size: 26px;
    font-weight: 500;
    margin: 10px 0;
  }
  .banner-button {
    max-width: 200px;
    text-align: center;
    margin: 0 auto;
    font-size: 18px;
  }
`;
