import { doGetRequest, doPostFileRequest, doPostRequest } from "../../shared/api/base-api";
import { apiUrl } from "../../shared/constants";
import common from "../../shared/common";

//master data api
export function getMasterData(data) {
  return doPostRequest(apiUrl.masterData, data);
}

//master data api
export function getAuthMasterData(data) {
  return doPostRequest(apiUrl.authMasterData, data);
}

//student data api
export function getStudentsData() {
  return doGetRequest(apiUrl.partnerStudents);
}

//add new student api
export function addNewStudent(data) {
  return doPostRequest(apiUrl.partnerAddStudent, data);
}

export function getMemberPackagesData(data) {
  return doGetRequest(common.stringformat(apiUrl.memberPackages, data));
}

//Teacher data api
export function getTeachersData() {
  return doGetRequest(apiUrl.partnerTeachers);
}

//add new teacher api
export function addNewTeacher(data) {
  return doPostRequest(apiUrl.partnerAddTeacher, data);
}

//School data api
export function getSchoolsData() {
  return doGetRequest(apiUrl.partnerSchools);
}

//add new school api
export function addNewSchool(data) {
  return doPostRequest(apiUrl.partnerAddSchool, data);
}

//Partner profile api
export function getPartnerProfileData() {
  return doGetRequest(apiUrl.partnerProfile);
}

//assign package to member api
export function assignPackageToMember(data) {
  return doPostRequest(apiUrl.partnerAssignMemberPackage, data);
}

//partner wallet data api
export function getPartnerWalletData() {
  return doGetRequest(apiUrl.partnerWallet);
}

//partner commission data api
export function getPartnerCommissionData() {
  return doGetRequest(apiUrl.partnerCommissions);
}

//promotional materials data api
export function getPromotionalMaterialsData() {
  return doGetRequest(apiUrl.promotionalMaterials);
}

//wallet withdrawl request api
export function submitWalletWithdrawlRequest(data) {
  return doPostRequest(apiUrl.walletWithdrawlRequest, data);
}

//commission payout request api
export function submitCommissionPayoutRequest(data) {
  return doPostRequest(apiUrl.commissionPayoutRequest, data);
}

//Notification List data api
export function getNotificationsListData() {
  return doGetRequest(apiUrl.notificationsList);
}

//new enquiry request api
export function submitPartnerEnquiry(data) {
  return doPostRequest(apiUrl.addEnquiryRequest, data);
}

//new paytm wallet recharge  request api
export function submitWalletPaytmRechargeRequest(data) {
  return doPostRequest(apiUrl.walletPaytmrechargeRequest, data);
}

//wallet Recharge request api
export function submitWalletRechargeRequest(data, files) {
  return doPostFileRequest(
    apiUrl.walletRechargeRequest,
    data,
    {
      method: "post",
      headers: {
        "Content-Type": "multipart/form-data",
      },
      withCredentials: true,
    },
    files
  );
}

//coupon data api
export function getCouponsData() {
  return doGetRequest(apiUrl.partnerCoupons);
}

//add new coupon api
export function addNewCoupon(data) {
  return doPostRequest(apiUrl.partnerAddCoupon, data);
}

//partner dashboard data api
export function getPartnerDashboardData() {
  return doGetRequest(apiUrl.partnerDashboard);
}

//get member profile api
export function getMemberProfileData(data) {
  return doPostRequest(apiUrl.memberProfile, data);
}

//admin partner Login api
export function partnerAdminLogin(data) {
  return doPostRequest(apiUrl.partnerAdminLogin, data);
}