import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col, Dropdown } from "react-bootstrap";
import MobileMenu from "./common/MobileMenu";
import { Styles } from "./styles/headerPromo.js";
import User from "../pages/user";
import common from "../shared/common";
import { webUrl } from "../shared/constants";
import Connect from "../pages/connect/index.js";
import PhoneOutlinedIcon from "@mui/icons-material/PhoneOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import AssignmentOutlinedIcon from "@mui/icons-material/AssignmentOutlined";
import DashboardIcon from "@mui/icons-material/Dashboard";
import AccountCircleRoundedIcon from "@mui/icons-material/AccountCircleRounded";
import { Button, List, ListItem, ListItemIcon, ListItemText, Typography } from "@mui/material";
import { CheckCircleOutlineOutlined } from "@mui/icons-material";

class HeaderPromo extends Component {
  render() {
    const idPref = Math.random();
    const profile = common.getFromStorage("profile");
    let dashboardUrl = webUrl.userDashboard;
    if (profile) {
      const profileData = JSON.parse(profile);
      if (profileData.member_type === 4) {
        dashboardUrl = webUrl.partnerDashboard;
      }
    }
    return (
      <Styles>
        <div className="promo-bg">
          {/* Topbar 2 */}
          <section className="top-bar2">
            <Container>
              <Row>
                <Col lg="7" md="7">
                  <div className="bar-left">
                    <ul className="list-unstyled list-inline">
                      <li className="list-inline-item">
                        <a href="tel:(931) 009 1906">
                          <PhoneOutlinedIcon />
                          (931) 009 1906
                        </a>
                      </li>
                      <li className="list-inline-item">
                        <a href="mailto:info@ealyss.com">
                          <EmailOutlinedIcon />
                          info@ealyss.com
                        </a>
                      </li>
                      <li className="list-inline-item">
                        <a href="#!" id={"req-btn"}>
                          <AssignmentOutlinedIcon />
                          Request a call
                        </a>
                        <Connect openCommand={true} commandCtrlId={"req-btn"} />
                      </li>
                    </ul>
                  </div>
                </Col>
                <Col lg="5" md="5">
                  <div className="bar-right d-flex justify-content-end">
                    <ul className="list-unstyled list-inline bar-login">
                      {common.getFromStorage("userName") ? (
                        <>
                          <li className="list-inline-item">
                            <Link to={dashboardUrl}>
                              <DashboardIcon />
                              Dashboard
                            </Link>
                          </li>
                          <li className="list-inline-item">
                            <Dropdown
                              className="info-filter"
                              name="country_code"
                              id="country_code"
                              onSelect={(e) => {
                                if (e === webUrl.home) localStorage.clear();
                                this.props.history.push(e);
                              }}
                            >
                              <Dropdown.Toggle as="a" className="filter-item">
                                <Link to={"#"}>
                                  <AccountCircleRoundedIcon />
                                  {common.getFromStorage("userName")}
                                </Link>
                              </Dropdown.Toggle>
                              <Dropdown.Menu as="ul">
                                <Dropdown.Item eventKey={webUrl.home} as="li">
                                  Logout
                                </Dropdown.Item>
                              </Dropdown.Menu>
                              <span className="login_input-msg"></span>
                            </Dropdown>
                          </li>
                        </>
                      ) : (
                        <>
                          <li className="list-inline-item">
                            <User customText="Login / Signup" />
                          </li>
                        </>
                      )}
                    </ul>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>

          {/* Logo Area 2 */}
          <section className="logo-area2">
            <Container>
              <Row>
                <Col md="2">
                  <div className="logo">
                    <Link to={process.env.PUBLIC_URL + "/"}>
                      <img src={process.env.PUBLIC_URL + "/assets/images/logo2.webp"} alt="" />
                    </Link>
                  </div>
                </Col>
                <Col md="10">
                  <div className="menu-box d-flex justify-content-center">
                    <ul className="nav menu-nav">
                      <li className="nav-item dropdown active">
                        <Link className="nav-link dropdown-toggle" to={process.env.PUBLIC_URL + webUrl.studyMaterial} data-toggle="dropdown">
                          Study Material
                        </Link>
                      </li>
                      <li className="nav-item dropdown">
                        <Link className="nav-link dropdown-toggle" to={process.env.PUBLIC_URL + webUrl.robotic} data-toggle="dropdown">
                          Robotics
                        </Link>
                      </li>
                      <li className="nav-item dropdown">
                        <Link className="nav-link dropdown-toggle" to={process.env.PUBLIC_URL + webUrl.teacher} data-toggle="dropdown">
                          Teacher Solutions
                        </Link>
                      </li>
                      <li className="nav-item dropdown">
                        <Link className="nav-link dropdown-toggle" to={process.env.PUBLIC_URL + webUrl.examPreparatory} data-toggle="dropdown">
                        Exam Preparatory
                        </Link>
                      </li>
                    </ul>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
          {/* Mobile Menu */}
          <MobileMenu />
          <section className="slider-content">
            <Container>
              <Row className="align-items-center reverse-flex">
                <Col lg="4" md="5" sm="12" style={{ color: "white" }}>
                  <div className="mt-5" style={{ display: "inline-block" }}>
                    <h2>Your Partner in STEM Subjects</h2>
                    <List dense>
                      <ListItem>
                        <ListItemIcon sx={{ justifyContent: "center" }}>
                          <CheckCircleOutlineOutlined sx={{ height: "20px", width: "20px" }} />
                        </ListItemIcon>
                        <ListItemText
                          disableTypography
                          primary={
                            <Typography type="body2" style={{ fontSize: "20px", fontWeight: "400" }}>
                              In School
                            </Typography>
                          }
                        />
                      </ListItem>
                      <ListItem>
                        <ListItemIcon sx={{ justifyContent: "center" }}>
                          <CheckCircleOutlineOutlined sx={{ height: "20px", width: "20px" }} />
                        </ListItemIcon>
                        <ListItemText
                          disableTypography
                          primary={
                            <Typography type="body2" style={{ fontSize: "20px", fontWeight: "400" }}>
                              Tuition Centres
                            </Typography>
                          }
                        />
                      </ListItem>
                      <ListItem>
                        <ListItemIcon sx={{ justifyContent: "center" }}>
                          <CheckCircleOutlineOutlined sx={{ height: "20px", width: "20px" }} />
                        </ListItemIcon>
                        <ListItemText
                          disableTypography
                          primary={
                            <Typography type="body2" style={{ fontSize: "20px", fontWeight: "400" }}>
                              Online and Home Tuitions
                            </Typography>
                          }
                        />
                      </ListItem>
                    </List>
                    <div className="text-center">
                      <Button
                        variant="contained"
                        size="large"
                        className={"mt-3 mb-3 blue-bg"}
                        onClick={this.props.onButtonClick}
                        id={"hero-btn" + idPref}
                      >
                        Connect
                      </Button>
                      <User openCommand={true} commandCtrlId={"hero-btn" + idPref} />
                    </div>
                  </div>
                </Col>
                <Col lg="8" md="7" sm="12">
                  <img src={process.env.PUBLIC_URL + "/assets/images/banners/Element_3.webp"} alt="" className="img-fluid teacher-banner" />
                </Col>
              </Row>
            </Container>
          </section>
        </div>
      </Styles>
    );
  }
}

export default HeaderPromo;
