import { showLoaderAction, stopLoaderAction } from "../user/action";
import { moduleDetails, getSampleToken } from "./service";

/**
 * Action types
 */
export const actionTypes = {
  MODULE_DETAILS_REQUEST_SUCCESS: "MODULE_DETAILS_REQUEST_SUCCESS",
  SAMPLE_TOKEN_REQUEST_SUCCESS: "SAMPLE_TOKEN_REQUEST_SUCCESS"
};

export const receivedModuleDetailsAction = (payload) => ({
  type: actionTypes.MODULE_DETAILS_REQUEST_SUCCESS,
  payload,
});

export const moduleDetailsAction = (data) => {
  return (dispatch) => {
    dispatch(showLoaderAction("moduleDetails"));

    return moduleDetails(data)
      .then((response) => {

        dispatch(stopLoaderAction("moduleDetails"));
        dispatch(receivedModuleDetailsAction(response));
      })
      .catch((error) => {
        dispatch(stopLoaderAction("moduleDetails"));
      });
  };
};

export const receivedSampleTokenAction = (payload) => ({
  type: actionTypes.SAMPLE_TOKEN_REQUEST_SUCCESS,
  payload,
});

export const sampleModuleDetailsAction = (data) => {
  return (dispatch) => {
    dispatch(showLoaderAction("moduleDetails"));
    return getSampleToken().then((response) => {
      dispatch(receivedSampleTokenAction(response));
      localStorage.setItem("token", response.access_token);
      localStorage.setItem("isAuthenticated", "true");
      localStorage.setItem("user", "");
      localStorage.setItem("partner", "");
      localStorage.setItem("userName", "");
      localStorage.setItem("profile", "");
      return moduleDetails(data)
        .then((response) => {
          dispatch(stopLoaderAction("moduleDetails"));
          dispatch(receivedModuleDetailsAction(response));
        })
        .catch((error) => {
          dispatch(stopLoaderAction("moduleDetails"));
        });
    })
    
    

    
  };
};