import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Container, Row, Col } from "react-bootstrap";
import CourseItemGrid from "./components/CourseItemsGrid";
import FooterTwo from "../../components/FooterTwo";
import { Styles } from "./styles/dashboard.js";
import UserHeader from "../../components/UserHeader";
import DashboardMenu from "../dashboard/components/DashboardMenu";
import { recordedSessionCheckDataAction, recordedSessionsDataAction, setRecordedSessionsFormDataAction } from "./action";
import RecordedSessionsFilter from "./components/RecordedSessionsFilter";
import { handleReloadAppAction } from "../user/action";
import LoaderNew from "../../components/LoaderNew";

import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import ModalVideo from "react-modal-video";

const RecordedSession = (props) => {
  const [snackPack, setSnackPack] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedVideoId, setSelectedVideoId] = useState("");
  const [open, setOpen] = useState(false);
  const [messageInfo, setMessageInfo] = useState(undefined);
  useEffect(() => {
    if (snackPack.length && !messageInfo) {
      // Set a new snack when we don't have an active one
      setMessageInfo({ ...snackPack[0] });
      setSnackPack((prev) => prev.slice(1));
      setOpen(true);
    } else if (snackPack.length && messageInfo && open) {
      // Close an active snack when a new one is added
      setOpen(false);
    }
  }, [snackPack, messageInfo, open]);

  const handleSnackbar = (message) => {
    return setSnackPack((prev) => [...prev, { message, key: new Date().getTime() }]);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };
  const handleExited = () => {
    setMessageInfo(undefined);
  };

  const openModal = (data) => {
    setSelectedVideoId(data.videoId);
    setIsOpen(true);
  };

  useEffect(() => {
    if (!props.formData.category_id) {
      props.recordedSessionsData([]);
    } else if (props.formData.subcategory_id && props.formData.subsubcategory_id) {
      props.recordedSessionsData([props.formData.subcategory_id, props.formData.subsubcategory_id]);
    } else if (props.formData.subcategory_id) {
      props.recordedSessionsData([props.formData.subcategory_id]);
    }
  }, [props.formData.subcategory_id, props.formData.subsubcategory_id]);

  return (
    <div className="main-wrapper course-page">
      {/* User Header */}
      <UserHeader index={8} />

      <Styles>
        {/* Course Grid */}
        <section className="dashboard-grid-area">
          <Container>
            <Row>
              <Col lg="3" md="3" sm="12">
                <DashboardMenu {...props} index={8} />
              </Col>
              <Col>
                {props.showLoader && props.showLoader.length > 0 && <LoaderNew />}
                {props.showLoader.length === 0 && (
                  <>
                    <Row>
                      <Col>
                        <h5 className="grid-header">Recorded Sessions</h5>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="12" md="12" sm="12">
                        <RecordedSessionsFilter {...props} />
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="12" md="12" sm="12">
                        <div className="course-items">
                          <CourseItemGrid
                            {...props}
                            data={props.recordedSessions}
                            headerText="Recorded Sessions"
                            notFoundText="No recorded session found"
                            handleSnackbar={handleSnackbar}
                            openModal={openModal}
                          />
                        </div>
                        <Snackbar
                          key={messageInfo ? messageInfo.key : undefined}
                          open={open}
                          anchorOrigin={{ vertical: "top", horizontal: "center" }}
                          autoHideDuration={6000}
                          onClose={handleClose}
                          TransitionProps={{ onExited: handleExited }}
                          message={messageInfo ? messageInfo.message : undefined}
                          action={
                            <React.Fragment>
                              <IconButton aria-label="close" color="inherit" sx={{ p: 0.5 }} onClick={handleClose}>
                                <CloseIcon />
                              </IconButton>
                            </React.Fragment>
                          }
                        />
                        <ModalVideo channel="vimeo" isOpen={isOpen} videoId={selectedVideoId} onClose={() => setIsOpen(false)} />
                      </Col>
                    </Row>
                  </>
                )}
              </Col>
            </Row>
          </Container>
        </section>
      </Styles>

      {/* Footer 2 */}
      <FooterTwo />
    </div>
  );
};

const mapStateToProps = (state) => ({
  categories: state.recordedSession.data.categories,
  recordedSessions: state.recordedSession.data.recordedSessions,
  recordedSessionData: state.recordedSession.data.recordedSessionData,
  formData: state.recordedSession.formData,
  showLoader: state.user.showLoader,
});

const mapDispatchToProps = (dispatch) => ({
  recordedSessionsData: (data) => dispatch(recordedSessionsDataAction(data)),
  recordedSessionCheckData: (data) => dispatch(recordedSessionCheckDataAction(data)),
  setFormData: (data) => dispatch(setRecordedSessionsFormDataAction(data)),
  handleReloadApp: () => dispatch(handleReloadAppAction()),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RecordedSession));
