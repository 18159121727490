import styled from "styled-components";
import { colors } from "../../../components/common/element/elements.js";

export const Styles2 = styled.div`
  /* Course Grid */
  .course-grid-area {
    padding: 70px 0;
    .course-items {
      .course-item {
        border: 1px solid ${colors.border1};
        border-radius: 5px;
        transition: all 0.2s ease;
        overflow: hidden;
        margin-bottom: 30px;

        .course-image {
          width: 100%;
          padding-top: 56.25%;
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
          border-radius: 5px 5px 0 0;
          position: relative;

          .author-img {
            position: absolute;
            left: 20px;
            bottom: 20px;

            img {
              max-width: 40px;
              border-radius: 50%;
              margin-right: 5px;
            }

            .title {
              background: #ffffff;
              padding: 3px 8px;
              border-radius: 5px;

              p {
                font-size: 12px;
                color: ${colors.black1};
                font-weight: 500;
                margin-bottom: -4px;
              }

              span {
                font-size: 11px;
                color: ${colors.text3};
                font-weight: 500;
              }
            }
          }

          .course-price {
            p {
              font-size: 16px;
              color: #ffffff;
              background: ${colors.bg1};
              position: absolute;
              right: 20px;
              bottom: 20px;
              padding: 8px 10px;
              font-weight: 500;
              border-radius: 5px;
            }
          }

          @media (max-width: 767px) {
            height: 185px;
          }
        }

        .course-content {
          background: #fff;
          padding: 10px 15px;

          p.desc {
            font-size: 14px;
            color: ${colors.text3};
            line-height: 25px;
            border-bottom: 1px solid ${colors.border1};
            padding-bottom: 10px;
            margin-bottom: 12px;
          }

          .course-face {
            .duration,
            .student {
              p {
                font-size: 13px;
                color: ${colors.text3};

                i {
                  font-size: 16px;
                  color: ${colors.green};
                  vertical-align: text-bottom;
                  margin-right: 3px;
                }
              }
            }

            .rating {
              ul {
                li {
                  margin-right: 0;

                  i {
                    font-size: 14px;
                    color: ${colors.yellow};
                  }

                  &:last-child {
                    font-size: 13px;
                    color: ${colors.text3};
                  }
                }
              }
            }
          }
        }

        &:hover {
          box-shadow: 0 12px 25px rgba(0, 0, 0, 0.07);
        }
      }
    }

    @media (max-width: 767px) {
      padding: 40px 0 30px;
    }
  }

  /* Course List */
  .course-list-area {
    padding: 70px 0;
    .course-items2 {
      .course-item {
        border-radius: 5px;
        transition: all 0.2s ease;
        overflow: hidden;
        box-shadow: 0 8px 20px 5px rgba(0, 0, 0, 0.07);
        margin-bottom: 30px;

        .course-image-box {
          .course-image {
            width: 290px;
            padding-top: 56.25%;
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            border-radius: 5px 5px 0 0;
            position: relative;

            .author-img {
              position: absolute;
              left: 20px;
              top: 20px;

              img {
                max-width: 40px;
                border-radius: 50%;
                margin-right: 5px;
              }

              .title {
                background: #ffffff;
                padding: 3px 8px;
                border-radius: 5px;

                p {
                  font-size: 12px;
                  color: ${colors.black1};
                  font-weight: 500;
                  margin-bottom: -4px;
                }

                span {
                  font-size: 11px;
                  color: ${colors.text3};
                  font-weight: 500;
                }
              }

              @media (max-width: 991px) {
                top: unset;
                bottom: 20px;
              }
            }

            .course-price {
              p {
                font-size: 16px;
                color: #ffffff;
                background: ${colors.bg1};
                position: absolute;
                right: 20px;
                top: 20px;
                padding: 8px 10px;
                font-weight: 500;
                border-radius: 5px;

                @media (max-width: 991px) {
                  top: unset;
                  bottom: 20px;
                }
              }
            }

            @media (max-width: 991px) {
              width: 100%;
            }
          }
        }

        .course-content {
          background: #fff;
          padding: 10px 15px;

          h6.heading {
            padding-bottom: 12px;
            margin-bottom: 20px;
            position: relative;
            &:before {
              position: absolute;
              content: "";
              background: ${colors.green};
              width: 50px;
              height: 2px;
              bottom: 0;
              left: 0;
            }

            a {
              color: ${colors.black1};
              font-weight: 600;
              display: inline-block;

              &:hover {
                color: ${colors.green};
              }
            }
          }

          .rating {
            margin-bottom: 6px;
            ul {
              li {
                margin-right: 0;

                i {
                  font-size: 14px;
                  color: ${colors.yellow};
                }

                &:last-child {
                  font-size: 13px;
                  color: ${colors.text3};
                }
              }
            }
          }

          p.desc {
            font-size: 14px;
            color: ${colors.text3};
            line-height: 25px;
            margin-bottom: 15px;
          }

          a.details-btn {
            font-size: 13px;
            color: ${colors.green};
            border: 1px solid ${colors.border3};
            padding: 7px 15px;
            border-radius: 5px;
            font-weight: 500;
            &:hover {
              color: #ffffff;
              background: ${colors.gr_bg};
              border-color: ${colors.green};
            }
          }
        }

        &:hover {
          box-shadow: 0 12px 25px rgba(0, 0, 0, 0.1);
        }

        @media (max-width: 991px) {
          display: block !important;
        }
      }
    }

    @media (max-width: 767px) {
      padding: 40px 0 30px;
    }
  }

  .course-theory{
    p {
      font-size: 16px;
      line-height: 24px;
      margin: 10px 0;
    }
    img {
      max-width: 100%;
    }
  }

  .ncert-exercises{
    p {
      font-size: 16px;
      line-height: 24px;
      margin: 10px 0;
    }
    h6 {
        margin-top: 25px;
        font-size: 18px;
    }
    img {
      max-width: 100%;
    }
  }

  /* Course Details */
  .course-details-area {
    padding: 70px 0 78px;
    .course-details-top {
      .heading {
        h4 {
          color: ${colors.black1};
          font-weight: 600;
          line-height: 35px;
          margin-bottom: 25px;

          @media (max-width: 575px) {
            font-size: 20px;
          }
        }
      }
      .course-top-overview {
        margin-bottom: 25px;
        .overviews {
          .author {
            margin-right: 20px;
            padding-right: 20px;
            margin-top: -3px;
            border-right: 1px solid ${colors.border3};
            img {
              float: left;
              max-width: 50px;
              border-radius: 50%;
              margin-right: 10px;
            }
            .author-name {
              float: left;
              margin-top: 3px;
              h6 {
                color: ${colors.black2};
                font-weight: 600;
                text-transform: uppercase;
                margin-bottom: 5px;

                @media (max-width: 767px) {
                  font-size: 14px;
                }
              }
              p {
                font-size: 15px;
                color: ${colors.text3};
                font-weight: 500;

                @media (max-width: 767px) {
                  font-size: 14px;
                }
              }
            }
          }

          .category {
            margin-right: 20px;
            padding-right: 20px;
            border-right: 1px solid ${colors.border3};
            h6 {
              color: ${colors.black2};
              font-weight: 600;
              text-transform: uppercase;
              margin-bottom: 5px;
              margin-top: 3px;

              @media (max-width: 767px) {
                font-size: 14px;
              }
            }
            p {
              font-size: 15px;
              color: ${colors.text3};
              font-weight: 500;

              @media (max-width: 767px) {
                font-size: 14px;
              }
            }
          }

          .rating {
            margin-right: 20px;
            padding-right: 20px;
            border-right: 1px solid ${colors.border3};
            h6 {
              color: ${colors.black2};
              font-weight: 600;
              text-transform: uppercase;
              margin-bottom: 5px;

              @media (max-width: 767px) {
                font-size: 14px;
              }
            }
            ul {
              li {
                margin-right: 1px;
                i {
                  font-size: 16px;
                  color: ${colors.yellow};
                }
                &:last-child {
                  font-size: 15px;
                  color: ${colors.text3};
                  font-weight: 500;
                  margin-left: 5px;

                  @media (max-width: 767px) {
                    font-size: 14px;
                  }
                }
              }
            }
          }

          .price {
            h6 {
              color: ${colors.black2};
              font-weight: 600;
              text-transform: uppercase;
              margin-bottom: 5px;

              @media (max-width: 767px) {
                font-size: 14px;
              }
            }
            p {
              font-size: 15px;
              color: ${colors.text3};
              font-weight: 500;

              @media (max-width: 767px) {
                font-size: 14px;
              }
            }
          }
        }

        @media (max-width: 480px) {
          display: none;
        }
      }
      .course-details-banner {
        margin-bottom: 40px;
        img {
          border-radius: 5px;
        }
      }
      .course-tab-list {
        .nav {
          display: inline-block;
          border-radius: 5px;
          box-shadow: 0 8px 25px rgba(0, 0, 0, 0.1);
          margin-bottom: 35px;
          .nav-item {
            display: inline-block;
            a.nav-link {
              font-size: 14px;
              color: ${colors.black2};
              font-weight: 500;
              text-transform: uppercase;
              padding: 12px 30px 10px;
              border-radius: 5px;

              @media (max-width: 991px) {
                padding: 12px 16px 9px;
              }
            }
            a.nav-link.active {
              background: ${colors.gr_bg};
              color: #ffffff;
            }
          }
        }
        .tab-content {
          .overview-tab {
            h5 {
              color: ${colors.black2};
              font-weight: 600;
              padding-bottom: 10px;
              margin-bottom: 20px;
              position: relative;
              &:before {
                position: absolute;
                content: "";
                background: ${colors.green};
                width: 50px;
                height: 2px;
                bottom: 0;
                left: 0;
              }

              @media (max-width: 575px) {
                font-size: 17px;
              }
            }
            p {
              font-size: 15px;
              color: ${colors.text2};
              line-height: 25px;

              @media (max-width: 575px) {
                font-size: 14px;
              }
            }

            .course-desc {
              margin-bottom: 40px;
            }

            .course-feature {
              margin-bottom: 40px;
              ul {
                margin-top: 20px;
                li {
                  font-size: 14px;
                  color: ${colors.text3};
                  line-height: 25px;
                  margin-bottom: 10px;
                  i {
                    font-size: 20px;
                    color: ${colors.green};
                    float: left;
                    height: 40px;
                    line-height: 27px;
                    margin-right: 10px;
                  }
                  &:last-child {
                    margin-bottom: 0;
                  }
                }
              }
            }
            .course-learn {
              margin-bottom: 40px;
              ul {
                margin-top: 20px;
                li {
                  font-size: 14px;
                  color: ${colors.text3};
                  line-height: 25px;
                  margin-bottom: 15px;
                  i {
                    float: left;
                    color: ${colors.green};
                    border: 1px solid ${colors.border3};
                    width: 35px;
                    height: 35px;
                    border-radius: 50%;
                    text-align: center;
                    padding-top: 9px;
                    margin-top: 8px;
                    margin-right: 15px;
                  }
                  &:last-child {
                    margin-bottom: 0;
                  }
                }
              }
            }
          }
          .curriculum-tab {
            .course-curriculum {
              margin-bottom: 40px;
              h5 {
                color: ${colors.black2};
                font-weight: 600;
                padding-bottom: 10px;
                margin-bottom: 20px;
                position: relative;
                &:before {
                  position: absolute;
                  content: "";
                  background: ${colors.green};
                  width: 50px;
                  height: 2px;
                  bottom: 0;
                  left: 0;
                }

                @media (max-width: 575px) {
                  font-size: 17px;
                }
              }
              p {
                font-size: 15px;
                color: ${colors.text2};
                line-height: 25px;

                @media (max-width: 575px) {
                  font-size: 14px;
                }
              }
            }
            .course-element {
              h5 {
                color: ${colors.black2};
                font-weight: 600;
                padding-bottom: 10px;
                margin-bottom: 20px;
                position: relative;
                &:before {
                  position: absolute;
                  content: "";
                  background: ${colors.green};
                  width: 50px;
                  height: 2px;
                  bottom: 0;
                  left: 0;
                }

                @media (max-width: 575px) {
                  font-size: 17px;
                }
              }
              .course-item {
                margin-bottom: 10px;
                button.course-button {
                  border: none;
                  background: transparent;
                  margin-bottom: 15px;
                  display: block;
                  width: 100%;
                  text-align: left;
                  padding: 0;
                  font-size: 18px;
                  color: ${colors.black2};
                  font-weight: 500;
                  span {
                    float: right;
                    font-size: 14px;
                    color: ${colors.text3};
                    font-weight: 400;
                  }
                }

                .course-content {
                  max-height: 0;
                  overflow: hidden;
                  transition: max-height 0.2s ease-in-out;
                  ul {
                    li {
                      border-bottom: 1px solid ${colors.border3};
                      margin-left: 25px;
                      padding: 12px 0;
                      span.play-icon {
                        font-size: 14px;
                        color: ${colors.text3};
                        margin-right: 20px;
                        i {
                          color: ${colors.green};
                          border: 1px solid ${colors.green};
                          font-size: 22px;
                          width: 30px;
                          height: 30px;
                          border-radius: 50%;
                          padding-left: 3px;
                          text-align: center;
                          line-height: 29px;
                          vertical-align: middle;
                          margin-right: 10px;
                        }
                      }
                      span.lecture-title {
                        font-size: 15px;
                        color: ${colors.black2};

                        @media (max-width: 575px) {
                          font-size: 14px;
                        }
                      }
                      span.lecture-duration {
                        float: right;
                        font-size: 14px;
                        color: ${colors.text3};
                        line-height: 28px;
                        font-weight: 400;
                      }
                    }
                  }
                }

                .course-content.show {
                  max-height: 100%;
                  margin-bottom: 40px;
                }

                &:last-child {
                  margin-bottom: 40px;
                }
              }
            }
          }

          .instructor-tab {
            h5 {
              color: ${colors.black2};
              font-weight: 600;
              padding-bottom: 10px;
              margin-bottom: 35px;
              position: relative;
              &:before {
                position: absolute;
                content: "";
                background: ${colors.green};
                width: 50px;
                height: 2px;
                bottom: 0;
                left: 0;
              }

              @media (max-width: 575px) {
                font-size: 17px;
              }
            }

            .instructor-item {
              margin-bottom: 30px;

              .instructor-img {
                img {
                  border-radius: 5px;
                }
              }

              .instructor-content {
                position: relative;

                .instructor-box {
                  box-shadow: 0 0px 20px rgba(0, 0, 0, 0.08);
                  padding: 25px;
                  background: #ffffff;
                  border-radius: 5px;
                  position: absolute;
                  top: 32px;
                  left: -11%;
                  z-index: 1;

                  .top-content {
                    margin-bottom: 20px;

                    .instructor-name {
                      h6 {
                        color: ${colors.black2};
                        font-weight: 600;
                        text-transform: uppercase;
                        margin-bottom: 12px;

                        @media (max-width: 575px) {
                          font-size: 14px;
                        }
                      }
                      p {
                        font-size: 14px;
                        color: ${colors.text3};
                        font-weight: 500;
                      }
                    }
                    .instructor-social {
                      margin-top: 25px;
                      ul.social {
                        li {
                          a {
                            text-align: center;
                            position: relative;

                            &:before {
                              content: "";
                              position: absolute;
                              border-width: 8px 14px;
                              border-style: solid;
                              border-top-color: transparent;
                              border-right-color: transparent;
                              border-left-color: transparent;
                              top: -16px;
                              left: 0;
                              z-index: 1;
                              transition: all 0.2s ease;
                            }

                            &:after {
                              content: "";
                              position: absolute;
                              border-width: 8px 14px;
                              border-style: solid;
                              border-right-color: transparent;
                              border-bottom-color: transparent;
                              border-left-color: transparent;
                              bottom: -16px;
                              left: 0;
                              z-index: 1;
                              transition: all 0.2s ease;
                            }

                            i {
                              font-size: 13px;
                              color: #ffffff;
                              width: 28px;
                            }

                            &:hover {
                              background-color: ${colors.green} !important;

                              &:before {
                                border-bottom-color: ${colors.green} !important;
                              }

                              &:after {
                                border-top-color: ${colors.green} !important;
                              }
                            }
                          }

                          &:nth-child(1) {
                            a {
                              background-color: #4267b2;

                              &:before {
                                border-bottom-color: #4267b2;
                              }

                              &:after {
                                border-top-color: #4267b2;
                              }
                            }
                          }

                          &:nth-child(2) {
                            a {
                              background-color: #1da1f2;

                              &:before {
                                border-bottom-color: #1da1f2;
                              }

                              &:after {
                                border-top-color: #1da1f2;
                              }
                            }
                          }

                          &:nth-child(3) {
                            a {
                              background-color: #2867b2;

                              &:before {
                                border-bottom-color: #2867b2;
                              }

                              &:after {
                                border-top-color: #2867b2;
                              }
                            }
                          }

                          &:nth-child(4) {
                            a {
                              background-color: #dd1343;

                              &:before {
                                border-bottom-color: #dd1343;
                              }

                              &:after {
                                border-top-color: #dd1343;
                              }
                            }
                          }
                        }
                      }
                    }
                  }

                  .instructor-desk {
                    p {
                      font-size: 15px;
                      color: ${colors.text2};
                      line-height: 25px;

                      @media (max-width: 575px) {
                        font-size: 14px;
                      }
                    }
                  }
                }
              }

              &:last-child {
                margin-bottom: 0;
              }
            }
          }

          .review-tab {
            .review-comments {
              margin-bottom: 40px;
              h5 {
                color: ${colors.black2};
                font-weight: 600;
                padding-bottom: 10px;
                margin-bottom: 35px;
                position: relative;
                &:before {
                  position: absolute;
                  content: "";
                  background: ${colors.green};
                  width: 50px;
                  height: 2px;
                  bottom: 0;
                  left: 0;
                }

                @media (max-width: 575px) {
                  font-size: 17px;
                }
              }
              .comment-box {
                border-bottom: 1px solid ${colors.border1};
                padding-bottom: 20px;
                margin-bottom: 25px;
                .comment-image {
                  img {
                    max-width: 100px;
                    border-radius: 5px;
                    margin-right: 20px;
                  }
                }
                .comment-content {
                  .content-title {
                    .comment-writer {
                      h6 {
                        color: ${colors.black2};
                        font-weight: 600;
                        margin-bottom: 10px;

                        @media (max-width: 575px) {
                          font-size: 14px;
                        }
                      }
                      p {
                        font-size: 12px;
                        color: ${colors.text3};
                        margin-bottom: 5px;
                      }
                      ul {
                        margin-bottom: 8px;
                        li {
                          margin-right: 1px;
                          i {
                            font-size: 16px;
                            color: ${colors.yellow};
                          }
                          &:last-child {
                            font-size: 13px;
                            color: ${colors.text2};
                            margin-left: 5px;
                          }
                        }
                      }
                    }
                    .reply-btn {
                      button {
                        font-size: 14px;
                        color: ${colors.green};
                        background: transparent;
                        border: 1px solid ${colors.border3};
                        font-weight: 500;
                        border-radius: 25px;
                        padding: 4px 12px 3px;
                        margin-top: 10px;
                        i {
                          font-size: 17px;
                          vertical-align: text-top;
                        }
                        &:hover {
                          color: #ffffff;
                          background: ${colors.gr_bg};
                          border-color: ${colors.green};
                        }
                      }
                    }
                  }
                  .comment-desc {
                    p {
                      font-size: 14px;
                      color: ${colors.text3};
                      line-height: 25px;
                    }
                  }
                }
                &:last-child {
                  border-bottom: none;
                  padding-bottom: 0;
                  margin-bottom: 0;
                }
              }
            }

            .review-form {
              h5 {
                color: ${colors.black2};
                font-weight: 600;
                padding-bottom: 10px;
                margin-bottom: 25px;
                position: relative;
                &:before {
                  position: absolute;
                  content: "";
                  background: ${colors.green};
                  width: 50px;
                  height: 2px;
                  bottom: 0;
                  left: 0;
                }

                @media (max-width: 575px) {
                  font-size: 17px;
                }
              }
            }
          }
        }
      }

      @media (max-width: 767px) {
        margin-bottom: 40px;
      }
    }

    .single-details-sidbar {
      .course-details-feature {
        box-shadow: 0 8px 25px rgba(0, 0, 0, 0.1);
        border-radius: 5px;
        padding: 15px 20px 20px;
        h5.title {
          color: ${colors.black1};
          font-weight: 600;
          padding-bottom: 10px;
          margin-bottom: 20px;
          position: relative;
          &:before {
            position: absolute;
            content: "";
            background: ${colors.green};
            width: 50px;
            height: 2px;
            bottom: 0;
            left: 0;
          }

          @media (max-width: 575px) {
            font-size: 17px;
          }
        }

        ul.feature-list {
          margin-bottom: 20px;
          li {
            border-top: 1px dashed ${colors.border3};
            padding: 12px 0;
            font-size: 14px;
            color: ${colors.black2};
            font-weight: 500;
            i {
              font-size: 20px;
              color: ${colors.green};
              vertical-align: top;
              margin-right: 2px;
            }
            span {
              float: right;
              font-size: 13px;
              color: ${colors.text3};
              font-weight: 400;
              line-height: 20px;
            }
            &:first-child {
              border-top: none;
              padding-top: 0;
            }
            &:last-child {
              padding-bottom: 0;
            }
          }
        }

        button.enroll-btn {
          font-size: 16px;
          color: #fff;
          ${colors.gredient_blue_bg}
          display: inline-block;
          width: 100%;
          height: 40px;
          font-weight: 500;
          border: none;
          padding: 9px;
          border-radius: 5px;
          &:hover {
            transition: 0.5s;
            tranform: scale(1.02);
          }

          @media (max-width: 575px) {
            font-size: 14px;
          }
        }

        a.enroll-btn {
          font-size: 15px;
          color: #fff;
          background: ${colors.gr_bg};
          display: inline-block;
          width: 200px;
          height: 48px;
          text-transform: uppercase;
          font-weight: 500;
          text-align: center;
          padding: 14px;
          border-radius: 5px;
          margin-top: 20px;

          @media (max-width: 575px) {
            font-size: 14px;
          }
        }

        .course-share {
          margin-top: 30px;
          text-align: center;

          ul.social {
            margin-top: 30px;
            li {
              a {
                text-align: center;
                position: relative;
                height: 18px;
                display: inline-block;

                &:before {
                  content: "";
                  position: absolute;
                  border-width: 9px 17px;
                  border-style: solid;
                  border-top-color: transparent;
                  border-right-color: transparent;
                  border-left-color: transparent;
                  top: -18px;
                  left: 0;
                  z-index: 1;
                  transition: all 0.2s ease;
                }

                &:after {
                  content: "";
                  position: absolute;
                  border-width: 9px 17px;
                  border-style: solid;
                  border-right-color: transparent;
                  border-bottom-color: transparent;
                  border-left-color: transparent;
                  bottom: -18px;
                  left: 0;
                  z-index: 1;
                  transition: all 0.2s ease;
                }

                i {
                  font-size: 14px;
                  color: #ffffff;
                  width: 34px;
                }

                &:hover {
                  background-color: ${colors.green} !important;

                  &:before {
                    border-bottom-color: ${colors.green} !important;
                  }

                  &:after {
                    border-top-color: ${colors.green} !important;
                  }
                }
              }

              &:nth-child(1) {
                a {
                  background-color: #4267b2;

                  &:before {
                    border-bottom-color: #4267b2;
                  }

                  &:after {
                    border-top-color: #4267b2;
                  }
                }
              }

              &:nth-child(2) {
                a {
                  background-color: #1da1f2;

                  &:before {
                    border-bottom-color: #1da1f2;
                  }

                  &:after {
                    border-top-color: #1da1f2;
                  }
                }
              }

              &:nth-child(3) {
                a {
                  background-color: #2867b2;

                  &:before {
                    border-bottom-color: #2867b2;
                  }

                  &:after {
                    border-top-color: #2867b2;
                  }
                }
              }

              &:nth-child(4) {
                a {
                  background-color: #dd1343;

                  &:before {
                    border-bottom-color: #dd1343;
                  }

                  &:after {
                    border-top-color: #dd1343;
                  }
                }
              }

              &:nth-child(5) {
                a {
                  background-color: #ea4c89;

                  &:before {
                    border-bottom-color: #ea4c89;
                  }

                  &:after {
                    border-top-color: #ea4c89;
                  }
                }
              }
            }
          }
        }

        @media (max-width: 1199px) {
          padding: 12px 15px 15px;
        }
      }
    }

    @media (max-width: 767px) {
      padding: 20px 0 5px;
    }
  }

  .event-box {
    margin-bottom: 30px;
    &:hover{
        transform: scale(1.02);
        transition: all 0.7s ease;
    }
    a {
      color: ${colors.black1};
      font-weight: 600;
      display: inline-block;
      margin-bottom: 12px;

      &:hover {
        color: ${colors.green};
      }
    }
    .event-img {
      a {
        img {
          border-radius: 5px;
        }
      }

      @media (max-width: 991px) {
        display: none;
      }
    }
    .event-content {
      position: relative;
      .content-box {
        box-shadow: 0 0px 20px rgba(0, 0, 0, 0.08);
        padding: 20px;
        background: #ffffff;
        border-radius: 5px;
        position: absolute;
        top: 22px;
        left: -9%;
        z-index: 1;
        .event-title {
          h6 {
            margin-bottom: 10px;
            a {
              color: ${colors.black1};
              font-weight: 600;

              &:hover {
                color: ${colors.green};
              }
            }
          }
        }

        .event-time-location {
          margin-bottom: 10px;
          ul {
            li {
              font-size: 13px;
              color: ${colors.text3};
              i {
                font-size: 18px;
                color: ${colors.green};
                vertical-align: top;
              }
              &:first-child {
                margin-right: 20px;
              }
            }
          }
        }

        .event-desc {
          p {
            font-size: 13px;
            color: ${colors.text2};
            line-height: 20px;
          }

          @media (max-width: 767px) {
            margin-bottom: 10px;
          }
        }

        .event-date {
          position: relative;
          margin-bottom: 20px;
          padding-top: 6px;
          &:before {
            position: absolute;
            content: "";
            background: ${colors.border1};
            width: 1px;
            height: 100px;
            top: 0;
            left: -30px;

            @media (max-width: 767px) {
              content: none;
            }
          }
          p {
            font-size: 20px;
            color: ${colors.green};
            font-weight: 500;
            text-transform: uppercase;

            @media (max-width: 767px) {
              float: left;
              font-size: 18px;
              margin-right: 20px;
            }
          }

          @media (max-width: 767px) {
            margin-bottom: 0;
            padding-top: 0;
          }
        }

        .join-btn {
          a, button {
            cursor: pointer !important;
            font-size: 12px;
            color: ${colors.black2};
            border: 1px solid ${colors.border3};
            font-weight: 500;
            text-transform: uppercase;
            padding: 9px 12px 7px;
            border-radius: 5px;
            &:hover {
              color: #ffffff;
              background: ${colors.gr_bg};
              border-color: ${colors.green};
            }

            @media (max-width: 767px) {
              float: left;
              padding: 5px 10px 3px;
            }
          }
        }

        @media (max-width: 991px) {
          position: unset;
        }
      }
    }
  }
  .model-message-intro {
        position: fixed;
        top: 0;
        left: 0;
        height: 100vh;
        width: 100%;
        background: rgba(0, 0, 0, 0.9);
        z-index: 9999;
        
    }
    .no-transition{
        &:hover {
          transform: none !important;
          transition: none !important;
        }
    }
    .close-message-intro {
        position: absolute;
        top: 15px;
        right: 22px;
        width: 25px;
        cursor: pointer;

        img{
            height: 30px;
        }
    }

    .model-message-intro .pop-vid-container {
        max-width: 800px;
        max-height: 500px;
        width: 100%;
        height: 100%;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }

    .embed-responsive {
        position: relative;
        display: block;
        width: 100%;
        padding: 0;
        overflow: hidden;
    }

    .embed-responsive .embed-responsive-item,.embed-responsive iframe, .embed-responsive video {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: 0;
    }



        .faq-area {
            .sec-title {
                h4 {
                    color         : ${colors.black1};
                    font-weight   : 600;
                    text-transform: uppercase;
                    margin-bottom : 45px;

                    span {
                        color: ${colors.green};
                    }

                    @media(max-width: 575px) {
                        margin-bottom: 15px;
                        font-size: 20px;
                    }
                }
            }

            .faq-box {
                .faq-item {
                    margin-bottom: 22px;

                    button.accordion-button {
                        border       : none;
                        background   : transparent;
                        margin-bottom: 15px;
                        display      : block;
                        width        : 100%;
                        padding      : 0;
                        text-align   : left;
                        position     : relative;

                        div.accordion-icon {
                            background  : ${colors.green};
                            height      : 18px;
                            text-align  : center;
                            float       : left;
                            margin-right: 12px;
                            position    : relative;

                            i {
                                font-size   : 20px;
                                color       : #ffffff;
                                width       : 32px;
                                line-height : 18px;
                                padding-left: 2px
                            }

                            &::before {
                                content            : "";
                                position           : absolute;
                                border-width       : 8px 16px;
                                border-style       : solid;
                                border-top-color   : transparent;
                                border-right-color : transparent;
                                border-bottom-color: ${colors.green};
                                border-left-color  : transparent;
                                top                : -16px;
                                left               : 0;
                                z-index            : 1;
                            }

                            &::after {
                                content            : "";
                                position           : absolute;
                                border-width       : 8px 16px;
                                border-style       : solid;
                                border-top-color   : ${colors.green};
                                border-right-color : transparent;
                                border-bottom-color: transparent;
                                border-left-color  : transparent;
                                bottom             : -16px;
                                left               : 0;
                                z-index            : 1;
                            }
                        }

                        p {
                            font-size  : 16px;
                            color      : ${colors.black1};
                            font-weight: 500;
                            line-height: 18px;
                        }
                    }

                    .accordion-content {
                        max-height: 0;
                        overflow  : hidden;
                        transition: max-height 0.2s ease-in-out;

                        p {
                            font-size   : 14px;
                            color       : ${colors.text3};
                            line-height : 28px;
                            padding-left: 45px;
                        }
                    }

                    .margin-bottom-15{                        
                        margin-bottom: 15px;
                    }
                    .accordion-content.show {
                        max-height: 100%;

                        .course-items {

                            .course-item {
                                border: 1px solid ${colors.border1};
                                border-radius : 5px;
                                transition : all 0.2s ease;
                                overflow : hidden;
                                margin-bottom: 30px;
                
                                .course-image {
                                    width              : 100%;
                                    padding-top        : 56.25%;
                                    background-size    : cover;
                                    background-position: center;
                                    background-repeat  : no-repeat;
                                    border-radius : 5px 5px 0 0;
                                    position: relative;
                
                                    .author-img {
                                        position: absolute;
                                        left    : 20px;
                                        bottom  : 20px;
                
                                        img {
                                            max-width: 40px;
                                            border-radius : 50%;
                                            margin-right: 5px;
                                        }
                
                                        .title {
                                            background: #ffffff;
                                            padding   : 3px 8px;
                                            border-radius : 5px;
                
                                            p {
                                                font-size    : 12px;
                                                color        : ${colors.black1};
                                                font-weight  : 500;
                                                margin-bottom: -4px;
                                            }
                
                                            span {
                                                font-size  : 11px;
                                                color      : ${colors.text3};
                                                font-weight: 500;
                                            }
                                        }
                
                                    }
                
                                    .course-price {
                                        p {
                                            font-size  : 16px;
                                            color      : #ffffff;
                                            background : ${colors.bg1};
                                            position   : absolute;
                                            right      : 20px;
                                            bottom     : 20px;
                                            padding    : 8px 10px;
                                            font-weight: 500;
                                            border-radius : 5px;
                                        }
                                        button.play-button {
                                            position  : absolute;
                                            z-index   : 10;
                                            top       : 50%;
                                            left      : 50%;
                                            transform : translateX(-50%) translateY(-50%);
                                            box-sizing: content-box;
                                            display   : block;
                                            width     : 32px;
                                            height    : 44px;
                                            border-radius : 50%;
                        
                                            svg {
                                                position   : relative;
                                                font-size  : 40px;
                                                color      : ${colors.bg1};
                                                z-index    : 11;
                                                padding-top: 2px;
                                                margin-left: -2px;
                                            }
                        
                                            &::before {
                                                content   : "";
                                                position  : absolute;
                                                z-index   : 0;
                                                left      : 50%;
                                                top       : 50%;
                                                transform : translateX(-50%) translateY(-50%);
                                                display   : block;
                                                width     : 50px;
                                                height    : 50px;
                                                background: #ffffff;
                                                border-radius : 50%;
                                                animation: pulse-border 1500ms ease-out infinite;
                                            }
                        
                                            &:after {
                                                content   : "";
                                                position  : absolute;
                                                z-index   : 1;
                                                left      : 50%;
                                                top       : 50%;
                                                transform : translateX(-50%) translateY(-50%);
                                                display   : block;
                                                width     : 50px;
                                                height    : 50px;
                                                background: #ffffff;
                                                border-radius : 50%;
                                                transition : all 200ms;
                                                border: 1px solid #cccccccc
                                            }
                        
                                            &:hover {
                                                svg {
                                                    color: ${colors.green};
                                                }
                                            }
                        
                                            @keyframes pulse-border {
                                                0% {
                                                    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
                                                    opacity  : 1;
                                                }
                        
                                                100% {
                                                    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
                                                    opacity  : 0;
                                                }
                                            }
                                        }
                                    }
                
                                    @media(max-width: 767px) {
                                        height: 185px;
                                    }
                                }
                
                                .course-content {
                                    background: #fff;
                                    padding: 10px 15px;
                
                                    h6.heading {
                                        a {
                                            color        : ${colors.black1};
                                            font-weight  : 600;
                                            display      : inline-block;
                
                                            &:hover {
                                                color: ${colors.green};
                                            }
                                        }
                                    }
                
                                    p.desc {
                                        font-size     : 14px;
                                        color         : ${colors.text3};
                                        line-height   : 25px;
                                        border-bottom : 1px solid ${colors.border1};
                                        padding-bottom: 10px;
                                        margin-bottom : 12px;
                                    }
                
                                    .course-face {
                
                                        .duration,
                                        .student {
                                            p {
                                                font-size: 13px;
                                                color    : ${colors.text3};
                
                                                i {
                                                    font-size     : 16px;
                                                    color         : ${colors.green};
                                                    vertical-align: text-bottom;
                                                    margin-right  : 3px;
                                                }
                                            }
                                        }
                
                                        .rating {
                                            ul {
                                                li {
                                                    margin-right: 0;
                
                                                    i {
                                                        font-size: 14px;
                                                        color    : ${colors.yellow};
                                                    }
                
                                                    &:last-child {
                                                        font-size: 13px;
                                                        color    : ${colors.text3};
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                
                                &:hover {
                                    box-shadow: 0 12px 25px rgba(0, 0, 0, 0.07);
                                }
                            }
                        }
                        
                        .event-box {
                            margin-bottom: 30px;
                            a {
                              color: ${colors.black1};
                              font-weight: 600;
                              display: inline-block;
                              margin-bottom: 12px;
                        
                              &:hover {
                                color: ${colors.green};
                              }
                            }
                            .event-img {
                              a {
                                img {
                                  border-radius: 5px;
                                }
                              }
                        
                              @media (max-width: 991px) {
                                display: none;
                              }
                            }
                            .event-content {
                              position: relative;
                              .content-box {
                                box-shadow: 0 0px 20px rgba(0, 0, 0, 0.08);
                                padding: 20px;
                                background: #ffffff;
                                border-radius: 5px;
                                position: absolute;
                                top: 22px;
                                left: -9%;
                                z-index: 1;
                                .event-title {
                                  h6 {
                                    margin-bottom: 10px;
                                    a {
                                      color: ${colors.black1};
                                      font-weight: 600;
                        
                                      &:hover {
                                        color: ${colors.green};
                                      }
                                    }
                                  }
                                }
                        
                                .event-time-location {
                                  margin-bottom: 10px;
                                  ul {
                                    li {
                                      font-size: 13px;
                                      color: ${colors.text3};
                                      i {
                                        font-size: 18px;
                                        color: ${colors.green};
                                        vertical-align: top;
                                      }
                                      &:first-child {
                                        margin-right: 20px;
                                      }
                                    }
                                  }
                                }
                        
                                .event-desc {
                                  p {
                                    font-size: 13px;
                                    color: ${colors.text2};
                                    line-height: 20px;
                                  }
                        
                                  @media (max-width: 767px) {
                                    margin-bottom: 10px;
                                  }
                                }
                        
                                .event-date {
                                  position: relative;
                                  margin-bottom: 20px;
                                  padding-top: 6px;
                                  &:before {
                                    position: absolute;
                                    content: "";
                                    background: ${colors.border1};
                                    width: 1px;
                                    height: 100px;
                                    top: 0;
                                    left: -30px;
                        
                                    @media (max-width: 767px) {
                                      content: none;
                                    }
                                  }
                                  p {
                                    font-size: 20px;
                                    color: ${colors.green};
                                    font-weight: 500;
                                    text-transform: uppercase;
                        
                                    @media (max-width: 767px) {
                                      float: left;
                                      font-size: 18px;
                                      margin-right: 20px;
                                    }
                                  }
                        
                                  @media (max-width: 767px) {
                                    margin-bottom: 0;
                                    padding-top: 0;
                                  }
                                }
                        
                                .join-btn {
                                  a, button {
                                    cursor: pointer !important;
                                    font-size: 12px;
                                    color: ${colors.black2};
                                    border: 1px solid ${colors.border3};
                                    font-weight: 500;
                                    text-transform: uppercase;
                                    padding: 9px 12px 7px;
                                    border-radius: 5px;
                                    &:hover {
                                      color: #ffffff;
                                      background: ${colors.gr_bg};
                                      border-color: ${colors.green};
                                    }
                        
                                    @media (max-width: 767px) {
                                      float: left;
                                      padding: 5px 10px 3px;
                                    }
                                  }
                                }
                        
                                @media (max-width: 991px) {
                                  position: unset;
                                }
                              }
                            }
                    }

                    &:last-child {
                        margin-bottom : 0;
                    }
                }
            }
        }
         

`;
