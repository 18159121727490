import React, { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import FooterTwo from "../../components/FooterTwo";
import UserHeader from "../../components/UserHeader";
import { Container, Row, Col } from "react-bootstrap";
import { Styles } from "../dashboard/styles/dashboard.js";
import { Styles2 } from "./styles/module.js";
import { moduleDetailsAction } from "./action";
import ModuleMenu from "./components/ModuleMenu";
import { Box, Breadcrumbs, Drawer, IconButton, Link, Typography } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import Concept from "./components/Concept";
import HardwareBuilding from "./components/HardwareBuilding";
import Programming from "./components/Programming";
import SoftwareBuilding from "./components/SoftwareBuilding";
import Theory from "./components/Theory";
import Tests from "./components/Tests";
import Mission from "./components/Mission";
import Ppt from "./components/Ppt";
import { webUrl } from "../../shared/constants";
import LoaderImage from "../../components/LoaderImage";
import CoreFundamentals from "./components/CoreFundamentals";
import SoftwareSetup from "./components/SoftwareSetup";
import Certification from "./components/Certification";

const drawerWidth = 240;

function Module(props) {
  const {
    match: { params },
    window,
  } = props;

  const [selectedPage, setSelectedPage] = useState(0);
  const [mobileOpen, setMobileOpen] = useState(false);
  const isMobile = useMediaQuery({ query: `(max-width: 600px)` });

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const changeMenuHandler = (value) => {
    setSelectedPage(value);
    if (isMobile) {
      handleDrawerToggle();
    }
  };

  useEffect(() => {
    props.moduleDetails([params.type || 1]); // To do: params.type should be default one
  }, []);

  const drawer = (
    <div>
      <ModuleMenu {...props} onChange={changeMenuHandler} />
    </div>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <div className="main-wrapper course-details-page">
      {/* Header */}
      <UserHeader />
      <Styles2>
        <Styles>
          {/* Course Grid */}
          <section className="dashboard-grid-area">
            <Container>
              <Row>
                <Col lg="3" md="3" sm="12">
                  <Box component="nav" sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }} aria-label="mailbox folders">
                    {isMobile === true ? (
                      <Drawer
                        container={container}
                        variant="temporary"
                        open={mobileOpen}
                        onClose={handleDrawerToggle}
                        ModalProps={{
                          keepMounted: true, // Better open performance on mobile.
                        }}
                        sx={{
                          display: { xs: "block", sm: "none" },
                          "& .MuiDrawer-paper": {
                            boxSizing: "border-box",
                            width: drawerWidth,
                            position: "relative",
                            borderRight: "none",
                            zIndex: "0",
                          },
                        }}
                      >
                        {drawer}
                      </Drawer>
                    ) : (
                      <Drawer
                        variant="permanent"
                        sx={{
                          display: { xs: "none", sm: "block" },
                          "& .MuiDrawer-paper": {
                            boxSizing: "border-box",
                            width: drawerWidth,
                            position: "relative",
                            borderRight: "none",
                            zIndex: "0",
                          },
                        }}
                        open
                      >
                        {drawer}
                      </Drawer>
                    )}
                  </Box>
                </Col>
                <Col lg="8">
                  <Row>
                    <Col lg="12" md="12" sm="12">
                      <Box component="main" sx={{ flexGrow: 1, p: 0 }}>
                        <div className="main-wrapper course-details-page">
                          <Styles>
                            <section>
                              <Row>
                                <Col lg="12" md="12" sm="12">
                                  <IconButton
                                    color="inherit"
                                    aria-label="open drawer"
                                    edge="start"
                                    onClick={handleDrawerToggle}
                                    sx={{ mr: 2, display: { sm: "none" } }}
                                  >
                                    <MenuIcon />
                                  </IconButton>
                                </Col>
                                <Col lg="12" md="12" sm="12">
                                  <div className="course-details-top">
                                    <div className="course-tab-list">
                                      {props.showLoader && props.showLoader.length > 0 && <LoaderImage />}
                                      {props.showLoader.length === 0 && (
                                        <>
                                          <Breadcrumbs aria-label="breadcrumb">
                                            <Link
                                              underline="none"
                                              color="inherit"
                                              sx={{ cursor: "pointer" }}
                                              onClick={(e) => {
                                                e.preventDefault();
                                                props.history.push(webUrl.userDashboard);
                                              }}
                                            >
                                              Dashboard
                                            </Link>
                                            <Link
                                              underline="none"
                                              color="inherit"
                                              sx={{ cursor: "pointer" }}
                                              onClick={(e) => {
                                                e.preventDefault();
                                                props.history.push(webUrl.subjectDetails + "/" + props.module.subsubcategory_id);
                                              }}
                                            >
                                              {props.module &&
                                                props.module.subsubcategory &&
                                                props.module.subsubcategory.subcategory &&
                                                `${props.module.subsubcategory.subcategory.name} - ${props.module.subsubcategory.name}`}
                                            </Link>
                                            <Typography color="text.primary">{props.module.name}</Typography>
                                          </Breadcrumbs>
                                          <div className="course-desc">
                                            {selectedPage === 0 && <Concept {...props} />}
                                            {selectedPage === 1 && <HardwareBuilding {...props} />}
                                            {selectedPage === 2 && <Programming {...props} />}
                                            {selectedPage === 3 && <SoftwareBuilding {...props} />}
                                            {selectedPage === 4 && <Theory {...props} />}
                                            {selectedPage === 5 && <CoreFundamentals {...props} />}
                                            {selectedPage === 6 && <SoftwareSetup {...props} />}
                                            {selectedPage === 7 && <Ppt {...props} />}
                                            {selectedPage === 8 && <Tests {...props} redirectTo={props.history.push} />}
                                            {selectedPage === 9 && <Mission {...props} />}
                                            {selectedPage === 10 && <Certification {...props} />}
                                          </div>
                                        </>
                                      )}
                                    </div>
                                  </div>
                                </Col>
                              </Row>
                            </section>
                          </Styles>
                        </div>
                      </Box>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Container>
          </section>
        </Styles>
      </Styles2>

      {/* Footer 2 */}
      <FooterTwo />
    </div>
  );
}

Module.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

const mapStateToProps = (state) => ({
  module: state.module.data.module,
  showLoader: state.user.showLoader,
});

const mapDispatchToProps = (dispatch) => ({
  moduleDetails: (data) => dispatch(moduleDetailsAction(data)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Module));
