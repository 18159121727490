import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { BreadcrumbBox } from "../../components/common/Breadcrumb";
import FooterTwo from "../../components/FooterTwo";
import { Styles } from "./styles/package.js";
import UserHeader from "../../components/UserHeader";
import PackageFilter from "./components/PackageFilter";
import { addToCartAction, packageListAction } from "./action";

const Package = (props) => {
  const {
    match: { params },
  } = props;

  useEffect(() => {
    props.packageList([params.type || 1]); // To do: params.type should be default one
  }, []);

  return (
    <Styles>
      {/* Main Wrapper */}
      <div className="main-wrapper cart-page">
        {/* Header 2 */}
        <UserHeader />

        {/* Breadcroumb */}
        <BreadcrumbBox title="Package" />

        {/* Course Filter */}

        <PackageFilter {...props} redirectTo={props.history.push} />

        {/* Footer 2 */}
        <FooterTwo />
      </div>
    </Styles>
  );
};

const mapStateToProps = (state) => ({
  cart: state.cart.data.cart,
  subsubcategory: state.package.data.subsubcategory,
  user: state.dashboard.data.user,
  showLoader: state.user.showLoader,
});

const mapDispatchToProps = (dispatch) => ({
  packageList: (data) => dispatch(packageListAction(data)),
  addToCart: (data) => dispatch(addToCartAction(data)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Package));
