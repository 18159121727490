import { actionTypes } from "./action";

/** Initial state */
const initialState = {
  data: {
    embed: null
  },
  error: null,
};

/** Reducers */
export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case actionTypes.CHAPTER_THEORY_DATA_REQUEST_SUCCESS:
    case actionTypes.CHAPTER_EXERCISES_DATA_REQUEST_SUCCESS:
    case actionTypes.CHAPTER_VIDEO_DATA_REQUEST_SUCCESS:
    case actionTypes.MODULE_THEORY_DATA_REQUEST_SUCCESS:
    case actionTypes.MODULE_CONCEPT_DATA_REQUEST_SUCCESS:
    case actionTypes.MODULE_PROGRAMMING_DATA_REQUEST_SUCCESS:
    case actionTypes.MODULE_HARDWARE_BUILDING_DATA_REQUEST_SUCCESS:
    case actionTypes.MODULE_SOFTWARE_BUILDING_DATA_REQUEST_SUCCESS:
    case actionTypes.MODULE_CORE_FUNDAMENTALS_DATA_REQUEST_SUCCESS:
    case actionTypes.MODULE_SOFTWARE_SETUP_DATA_REQUEST_SUCCESS:
      return {
        ...state,
        data: payload.data,
      };

    default:
      return state;
  }
};
