import React, { Component, Fragment, useState } from "react";
import { Col, Row } from "react-bootstrap";
import DownloadIcon from "@mui/icons-material/Download";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import { Button, Card, CardContent } from "@mui/material";
import MathJax from "mathjax3-react";

const VideoTextAttachment = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [videoURL, setVideoURL] = useState("");

  const closeModal = () => {
    setIsOpen(false);
  };

  const playVideo = (videoURL) => {
    setVideoURL(videoURL);
    setIsOpen(true);
  };

  const resizeIframe = () => {
    let item = document.getElementById("frameVideo");
    if (item) item.style.height = item.contentWindow.document.documentElement.scrollHeight + "px";
  };

  const downloadResource = (data, url) => {
    // if (this.props.canUserAccess || data.allow_free_access) {
    window.open(url);
    // common.downloadResource(url)
    // } else {
    //     alert('You need to subscribe the course first')
    // }
  };

  return (
    <Fragment>
      <Row className="margin-bottom-15">
        <Col md="12">
          <h5 className="grid-header ml-0">{props.headerText}</h5>
        </Col>
      </Row>

      <Row>
        {props &&
          props.data.map(
            (data) =>
              (data.type === 1 && (
                <Col lg="4" md="6" key={data.id}>
                  <div className="course-item no-transition">
                    <div
                      className="course-image"
                      style={{ backgroundImage: `url('${data.video.video_image ? data.video.video_image_url : "/assets/images/course-1.jpg"}')` }}
                    >
                      <div className="course-price">
                        <button
                          onClick={(e) => {
                            e.preventDefault();
                            playVideo(data.video.full_video_url);
                          }}
                          className="play-button"
                        >
                          <PlayArrowIcon />
                        </button>
                      </div>
                    </div>
                    <div className="course-content">
                      <h6 className="heading">{data.name}</h6>
                    </div>
                  </div>
                </Col>
              )) ||
              (data.type === 2 && (
                <>
                  <Col lg="4" md="6" key={data.id}>
                    <div className="course-item no-transition">
                      <div
                        className="course-image"
                        style={{
                          backgroundImage: `url('${
                            data.attachment_thumb_file !== "" ? data.attachment_thumb_file_url.replace(/\\/g, "/") : "/assets/images/course-1.jpg"
                          }')`,
                        }}
                      >
                        <div className="course-price">
                          <button
                            onClick={() => {
                              downloadResource(data, data.attachment_file_url);
                            }}
                            className="play-button"
                          >
                            <DownloadIcon />
                          </button>
                        </div>
                      </div>
                      <div className="course-content">
                        <h6 className="heading">{data.name}</h6>
                      </div>
                      <div className="course-content text-center join-btn" style={{ borderTop: "1px solid #ddd" }}>
                        <Button
                          onClick={() => {
                            downloadResource(data, data.attachment_file_url);
                          }}
                        >
                          Download <DownloadIcon />
                        </Button>
                      </div>
                    </div>
                  </Col>
                </>
              )) ||
              (data.type === 3 && (
                <Col lg="12" md="12" key={data.id}>
                  <Card sx={{ width: "100%", marginBottom: "10px" }}>
                    <CardContent>
                      <div className="course-desc">
                        <MathJax.Provider>
                          <MathJax.Html html={data.description} />
                        </MathJax.Provider>
                      </div>
                    </CardContent>
                  </Card>
                </Col>
              ))
          )}
      </Row>
      {isOpen && (
        <div className="model-message-intro">
          <a href="#" className="close-message-intro" onClick={closeModal}>
            <img src={`${process.env.PUBLIC_URL}/assets/images/sample/close-icon.webp`} alt="" />
          </a>
          <div className="pop-vid-container">
            <div className="embed-responsive">
              <iframe
                title="Video Iframe"
                id="frameVideo"
                onload={resizeIframe(this)}
                className="embed-responsive-item"
                src={videoURL}
                allowFullScreen
              ></iframe>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default VideoTextAttachment;
