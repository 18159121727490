import styled from "styled-components";
import { colors } from "../element/elements.js";

export const Styles = styled.div`
    a.nav-sidebar {
        padding: 18px 0 0 10px;

        i {
            font-size: 20px;
            color    : ${colors.border1};
        }
    }
    
    .sidebar {  
        background-color: #ffffff;
        padding         : 40px;
        height          : 100%;
        width           : 400px;
        position        : fixed;
        top             : 0;
        right           : 0;
        overflow-y      : auto;
        z-index         : 9999;
        transition : all 400ms cubic-bezier(0.785, 0.135, 0.15, 0.86);

        .side-logo {
            margin-bottom: 40px;

            a {
                i {
                    font-size: 18px;
                    color    : ${colors.green};
                    border   : 1px solid ${colors.border1};
                    padding  : 6px;
                    border-radius : 50%;

                    &:hover {
                        background  : ${colors.green};
                        color       : #ffffff;
                        border-color: ${colors.green};
                    }
                }
            }
        }

        .side-content {
            h5 {
                color         : ${colors.green};
                text-transform: uppercase;
                font-weight   : 500;
                margin-bottom : 18px;
                position      : relative;
            }

            p {
                font-size    : 15px;
                color        : ${colors.text2};
                line-height  : 25px;
                margin-bottom: 30px;
            }
        }

        .side-post {
            margin-bottom: 40px;

            h5 {
                color         : ${colors.green};
                text-transform: uppercase;
                font-weight   : 500;
                margin-bottom : 25px;
                position      : relative;

                &::before {
                    position  : absolute;
                    content   : "";
                    background: ${colors.border1};
                    width     : 50%;
                    height    : 1px;
                    top       : 11px;
                    right     : 0;
                }
            }

            .post-box {
                margin-bottom: 15px;

                .post-img {
                    img {
                        max-width: 90px;
                        border-radius : 5px;
                        margin-right: 15px;
                    }
                }

                .post-title {
                    p {
                        font-size    : 13px;
                        color        : ${colors.black2};
                        font-weight  : 500;
                        margin-bottom: 6px;
                    }

                    span {
                        font-size: 12px;
                        color    : ${colors.text3};
                    }
                }
            }
            .login-area {
                .login-box {
                    max-width : 500px;
                    margin: auto;
                    border-radius: 5px;
                    .login-title {
                        h3 {
                            color : ${colors.black2};
                            text-transform: uppercase;
                            font-weight: 600;
                            padding-bottom: 10px;
                            margin-bottom: 20px;
                            position: relative;
                            &:before {
                                position: absolute;
                                content: "";
                                background: ${colors.green};
                                width: 50px;
                                height: 2px;
                                bottom: 0;
                                left: 50%;
                                margin-left: -25px;
                            }
    
                            @media(max-width: 575px) {
                                font-size : 20px;
                            }
                        }
                    }
    
                    form.form {
                        
                        .btn-save-info{
                            font-size: 13px;
                            color: #ffffff;
                            background: ${colors.gr_bg2};
                            display: inline-block;
                            height: 40px;
                            text-align: center;
                            text-transform: uppercase;
                            font-weight: 500;
                            padding: 11px;
                            border-radius: 5px 5px;
                            position: relative;
                            width: 100%;
                        }

                        .form-control {
                            padding      : 0;
                            width        : auto;
                            height       : auto;
                            background   : transparent;
                            border       : none;
                            margin-bottom: 28px;
                            position     : relative;
    
                            .pad-0{
                                padding:0;
                            }
                            label {
                                font-size : 15px;
                                color : ${colors.text1};
                                font-weight : 500;
    
                                @media(max-width: 575px) {
                                    font-size : 14px;
                                }
                            }
    
                            input {
                                width           : 100%;
                                height          : 48px;
                                background-color: #ffffff;
                                font-size       : 14px;
                                padding         : 15px 20px;
                                color           : ${colors.black1};
                                border          : 1px solid ${colors.border3};
                                border-radius : 5px;
    
                                &::placeholder {
                                    font-size : 14px;
                                    font-style: italic;
                                    color     : ${colors.text3};
    
                                    @media(max-width: 575px) {
                                        font-size : 13px;
                                    }
                                }
    
                                &:focus {
                                    border-color : ${colors.green};
                                }
    
                                @media(max-width: 575px) {
                                    height : 40px;
                                }
                            }
    
                            span {
                                color      : ${colors.red};
                                font-weight: 300;
                                position   : absolute;
                                bottom     : -20px;
                                left       : 0;
                                visibility : hidden;
                            }

                            .country-filter{
                                width: 100%;
                                height: 48px;
                                background-color: #ffffff;
                                font-size: 14px;
                                padding: 15px 0px;
                                color: #182B49;
                                border-radius: 5px;

                                a.dropdown-toggle::after{
                                    content:none;
                                }
                            }  
                            
                            .info-filter{
                               cursor: pointer;
                                a.dropdown-toggle::after{
                                    content:none;
                                }

                                .filter-item{
                                    width: 100%;
                                    height: 48px;
                                    background-color: #ffffff;
                                    font-size: 14px;
                                    padding: 15px 20px;
                                    color: #182B49;
                                    border: 1px solid #dddddd;
                                    border-radius: 5px;
                                    width: 100%;
                                    margin: 0 auto;
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;

                                    span{
                                        display: inline-block;
                                        flex: auto;
                                        position: inherit;
                                        color: #182B49;
                                        visibility: inherit;
                                        font-size: 14px;
                                        font-weight: inherit;
                                    }                                    
                                }

                                ul{
                                    width: auto;
                                    min-width: auto;
                                }
                            }
                        }
    
                        .form-control.success {
                            input {
                                border: 2px solid ${colors.green};
                            }
    
                            &::before {
                                position   : absolute;
                                content    : "\f058";
                                font-family: "Line Awesome Free";
                                font-size  : 24px;
                                color      : ${colors.green};
                                font-weight: 900;
                                top        : 34px;
                                right      : 10px;
                            }
                        }
    
                        .form-control.error {
                            input {
                                border: 2px solid ${colors.red};
                            }
    
                            &::before {
                                position   : absolute;
                                content    : "\f06a";
                                font-family: "Line Awesome Free";
                                font-size  : 24px;
                                color      : ${colors.red};
                                font-weight: 900;
                                top        : 34px;
                                right      : 10px;
                            }
                        }
    
                        .form-control.error {
                            span {
                                visibility: visible;
                            }
                        }
    
                        button {
                            font-size  : 13px;
                            color      : #fff;
                            background : ${colors.gr_bg};
                            width      : 100%;
                            height     : 40px;
                            font-weight: 500;
                            border     : none;
                            border-radius : 5px;
                            text-transform: uppercase;
                            margin-bottom : 20px;
    
                            &:hover {
                                background: ${colors.gr_bg2};
    
                                i {
                                    color: #ffffff;
                                }
                            }
    
                            @media(max-width: 575px) {
                                font-size : 15px;
                                height     : 40px;
                            }
                        }
    
                        .save-forget-password {
                            margin-bottom: 15px;
                            .save-passowrd {
                                label {
                                    font-size: 14px;
                                    color: ${colors.text3};
                                    display: block;
                                    font-weight : 500;
                                    margin-bottom : 0;
                                    cursor: pointer;
    
                                    input[type=checkbox] {
                                        border : 2px solid ${colors.border3};
                                        appearance: none;
                                        width: 18px;
                                        height: 18px;
                                        cursor: pointer;
                                        margin-right: 6px;
                                        position: relative;
                                        top: 4px;
    
                                        &:focus {
                                            outline: none;
                                        }
    
                                        &:checked {
                                            background-color: ${colors.green};
                                            background: ${colors.green} url("data:image/gif;base64,R0lGODlhCwAKAIABAP////3cnSH5BAEKAAEALAAAAAALAAoAAAIUjH+AC73WHIsw0UCjglraO20PNhYAOw==") 2px 2px no-repeat;
                                            border-color : ${colors.green};
                                        }
                                    }
                                }
                            }
                            .forget-password {
                                margin-top: 3px;
                                a {
                                    font-size : 14px;
                                    color : ${colors.green};
                                    font-weight : 500;
                                    &:hover {
                                        text-decoration : underline;
                                    }
                                }
                            }
                        }
    
                        .not_account-btn {
                            border-bottom: 1px solid ${colors.border1};
                            margin-bottom: 20px;
                            padding-bottom: 20px;
                            p {
                                font-size : 14px;
                                color     : ${colors.text3};
                                a {
                                    font-size : 14px;
                                    color : ${colors.green};
                                    font-weight : 500;
                                    &:hover {
                                        text-decoration : underline;
                                    }
                                }
                            }
                        }
    
                        .social-login {
                            p {
                                font-size : 14px;
                                color     : ${colors.text3};
                                margin-bottom : 15px;
                            }
                            ul {
                                li {
                                    a {
                                        font-size: 14px;
                                        color: #ffffff;
                                        display: inline-block;
                                        width: 110px;
                                        height: 40px;
                                        border-radius: 30px;
                                        padding-top: 10px;
                                        i {
                                            margin-right : 3px;
                                        }
                                        &:hover {
                                            background: ${colors.green} !important;
                                        }import { colors } from './../../../components/common/element/elements';
    
                                    }
                                    &:nth-child(1) {
                                        a {
                                            background: #DB4437;
                                        }
                                    }
                                    &:nth-child(2) {
                                        a {
                                            background: #4267B2;
                                        }
                                    }
                                    &:nth-child(3) {
                                        a {
                                            background: #1DA1F2;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
    
                @media(max-width: 767px) {
                    padding: 30px 0;
                }
            }
        }

        .side-gallery {
            margin-bottom: 32px;

            h5 {
                color         : ${colors.green};
                text-transform: uppercase;
                font-weight   : 500;
                margin-bottom : 25px;
                position      : relative;

                &::before {
                    position  : absolute;
                    content   : "";
                    background: ${colors.border1};
                    width     : 65%;
                    height    : 1px;
                    top       : 11px;
                    right     : 0;
                }
            }

            img {
                max-width: 96px;
                border-radius : 5px;
                margin-right : 5px;
                margin-bottom: 6px;
            }
        }

        .side-contact {
            margin-bottom: 20px;

            h5 {
                color         : ${colors.green};
                text-transform: uppercase;
                font-weight   : 500;
                margin-bottom : 15px;
                position      : relative;
            }

            ul {
                li {
                    font-size    : 14px;
                    color        : ${colors.text2};
                    margin-bottom: 12px;

                    i {
                        font-size     : 18px;
                        color         : ${colors.green};
                        margin-top    : 1px;
                        margin-right  : 6px;
                        vertical-align: text-bottom;
                        float         : left;
                        height        : 30px;
                    }
                }
            }
        }

        .side-social {
            ul {
                li {
                    a {
                        font-size  : 13px;
                        color      : ${colors.green};
                        display    : inline-block;
                        border     : 1px solid ${colors.border1};
                        width      : 30px;
                        height     : 30px;
                        text-align : center;
                        padding-top: 5px;
                        border-radius : 50%;

                        &:hover {
                            background  : ${colors.green};
                            border-color: ${colors.green};
                            color       : #fff;
                        }
                    }
                }
            }
        }
    }

    .sidebar.opened {
        right: 0 !important;
    }

    .sidebar-overlay {
        position        : fixed;
        left            : 0;
        top             : 0;
        height          : 100%;
        width           : 100%;
        display         : block;
        background-color: rgba(0, 0, 0, 0.8);
        z-index         : 1111;
        visibility      : hidden;
        opacity         : 0;
        transition      : 0.3s ease;
    }

    .sidebar-overlay.visible {
        visibility: visible;
        opacity   : 1;
    }
`;
