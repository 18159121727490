import { actionTypes } from './action';

/** Initial state */
const initialState = {
    data: {
        chapter: {
            videos: [],
            interactivities: [],
            quizzes: [],
            ncert_books: [],
            descriptive_practice_tests: [],
            ncert_exercises: []
        }
    }
};

/** Reducers */
export default (state = initialState, action) => {

    const { type, payload } = action;

    switch (type) {
        case actionTypes.CHAPTER_DETAILS_REQUEST_SUCCESS:
            return {
                ...state,
                data: {
                    ...state.data,
                    chapter: payload.data.chapter
                }
            };

        default:
            return state;
    }
};
