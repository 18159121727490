import React, { Fragment, useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Container, Row, Col } from "react-bootstrap";
import FooterTwo from "../../components/FooterTwo";
import { Styles } from "../dashboard/styles/dashboard.js";
import UserHeader from "../../components/UserHeader";
import DashboardMenu from "../dashboard/components/DashboardMenu";
import { generateTestAction, subscriptionsListDataAction } from "./action";
import { subjectDetailsAction } from "../subject/action";
import { webUrl } from "../../shared/constants.js";
import ReactMultiSelectCheckboxes from "react-multiselect-checkboxes";
import LoaderNew from "../../components/LoaderNew";
import { Autocomplete, Box, Button,  Grid, MenuItem,  TextField } from "@mui/material";

const NewTest = (props) => {
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [maximumMarks, setMaximumMarks] = useState(0);
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [selectedCourseInput, setSelectedCourseInput] = useState("");
  const [testTitle, setTestTitle] = useState("");
  const [testTitleError, setTestTitleError] = useState("");
  const [selectedSets, setSelectedSets] = useState("");
  const [selectedSetsError, setSelectedSetsError] = useState("");
  const [oneMarkMCQ, setOneMarkMCQ] = useState("");
  const [oneMarkMCQError, setOneMarkMCQError] = useState("");
  const [oneMarkSubjective, setOneMarkSubjective] = useState("");
  const [oneMarkSubjectiveError, setOneMarkSubjectiveError] = useState("");
  const [twoMarks, setTwoMarks] = useState("");
  const [twoMarksError, setTwoMarksError] = useState("");
  const [threeMarks, setThreeMarks] = useState("");
  const [threeMarksError, setThreeMarksError] = useState("");
  const [fiveMarks, setFiveMarks] = useState("");
  const [fiveMarksError, setFiveMarksError] = useState("");

  const handleForm = (key = "", value = "") => {
    const testTitleValue = key === "testTitle" ? value : testTitle;
    const selectedSetsValue = key === "selectedSets" ? value : selectedSets;
    const oneMarkMCQValue = key === "oneMarkMCQ" ? value : oneMarkMCQ;
    const oneMarkSubjectiveValue = key === "oneMarkSubjective" ? value : oneMarkSubjective;
    const twoMarksValue = key === "twoMarks" ? value : twoMarks;
    const threeMarksValue = key === "threeMarks" ? value : threeMarks;
    const fiveMarksValue = key === "fiveMarks" ? value : fiveMarks;
    let error = false;
    if (testTitleValue === "") {
      setTestTitleError("please enter test paper title");
      error = true;
    } else {
      setTestTitleError("");
    }
    if (selectedSetsValue === "") {
      setSelectedSetsError("please select number of sets");
      error = true;
    } else {
      setSelectedSetsError("");
    }
    if (oneMarkMCQValue === "") {
      setOneMarkMCQError("please enter one mark mcq questions count");
      error = true;
    } else {
      setOneMarkMCQError("");
    }
    if (oneMarkSubjectiveValue === "") {
      setOneMarkSubjectiveError("please enter one mark subjective questions count");
      error = true;
    } else {
      setOneMarkSubjectiveError("");
    }
    if (twoMarksValue === "") {
      setTwoMarksError("please enter two marks questions count");
      error = true;
    } else {
      setTwoMarksError("");
    }
    if (threeMarksValue === "") {
      setThreeMarksError("please enter three marks questions count");
      error = true;
    } else {
      setThreeMarksError("");
    }
    if (fiveMarksValue === "") {
      setFiveMarksError("please enter five marks questions count");
      error = true;
    } else {
      setFiveMarksError("");
    }
    if (!selectedOptions || selectedOptions.length === 0) {
      error = true;
    }
    return error;
  };
  const handleTitleChange = (e) => {
    setTestTitle(e.target.value);
    handleForm("testTitle", e.target.value);
  };

  const handleSetsChange = (e) => {
    setSelectedSets(e.target.value);
    handleForm("selectedSets", e.target.value);
  };
  const handleOneMarkMCQChange = (e) => {
    setOneMarkMCQ(e.target.value);
    handleForm("oneMarkMCQ", e.target.value);
    handleTotalMarks("oneMarkMCQ", e.target.value);
  };
  const handleOneMarkSubjectiveChange = (e) => {
    setOneMarkSubjective(e.target.value);
    handleForm("oneMarkSubjective", e.target.value);
    handleTotalMarks("oneMarkSubjective", e.target.value);
  };
  const handleTwoMarksChange = (e) => {
    setTwoMarks(e.target.value);
    handleForm("twoMarks", e.target.value);
    handleTotalMarks("twoMarks", e.target.value);
  };
  const handleThreeMarksChange = (e) => {
    setThreeMarks(e.target.value);
    handleForm("threeMarks", e.target.value);
    handleTotalMarks("threeMarks", e.target.value);
  };
  const handleFiveMarksChange = (e) => {
    setFiveMarks(e.target.value);
    handleForm("fiveMarks", e.target.value);
    handleTotalMarks("fiveMarks", e.target.value);
  };

  const numberOfSets = [
    { id: 1, name: "1" },
    { id: 2, name: "2" },
    { id: 3, name: "3" },
    { id: 4, name: "4" },
    { id: 5, name: "5" },
  ];
  const [chapters, setChapters] = useState([{ value: "*", label: "All" }]);
  const [chapterLabel, setChapterLabel] = useState("Chapter");

  useEffect(() => {
    props.subscriptionData();
    if (props.subsubcategory) {
      if (props.subsubcategory.is_robotics === 1) {
        setChapters([
          { value: "*", label: "All" },
          ...props.subsubcategory.modules.map((item) => {
            return { value: item.id, label: item.name };
          }),
        ]);
        setChapterLabel("Module");
      } else {
        setChapters([
          { value: "*", label: "All" },
          ...props.subsubcategory.chapters.map((item) => {
            return { value: item.id, label: item.name };
          }),
        ]);
        setChapterLabel("Chapter");
      }
    }
  }, [props.subsubcategory]);

  const onGenerateTestClick = (e) => {
    e.preventDefault();
    if (!handleForm()) {
      const selected_chapters_modules_ids = selectedOptions.map((chapter) => {
        return chapter.value;
      });
      const testData = {
        test_name: testTitle,
        one_mark_mcq_count: oneMarkMCQ,
        one_mark_count: oneMarkSubjective,
        two_marks_count: twoMarks,
        three_marks_count: threeMarks,
        five_marks_count: fiveMarks,
        sets_count: selectedSets,
        total_marks: maximumMarks,
        subsubcategory_id: selectedCourse,
        selected_chapters_modules_ids: selected_chapters_modules_ids,
      };
      props
        .generateTest(testData)
        .then((response) => {
          props.history.push(webUrl.testGeneration);
        })
        .catch((error) => {
          // console.log(error);
        });
    }
  };

  const handleTotalMarks = (key = "", value = "") => {
    const oneMarkMCQValue = key === "oneMarkMCQ" ? value : oneMarkMCQ;
    const oneMarkSubjectiveValue = key === "oneMarkSubjective" ? value : oneMarkSubjective;
    const twoMarksValue = key === "twoMarks" ? value : twoMarks;
    const threeMarksValue = key === "threeMarks" ? value : threeMarks;
    const fiveMarksValue = key === "fiveMarks" ? value : fiveMarks;
    let test_total_marks = 0;
    let test_1_mcq_marks = 0;
    let test_1_marks = 0;
    let test_2_marks = 0;
    let test_3_marks = 0;
    let test_5_marks = 0;

    if (oneMarkMCQValue !== "") {
      test_1_mcq_marks = +oneMarkMCQValue;
    }
    if (oneMarkSubjectiveValue !== "") {
      test_1_marks = +oneMarkSubjectiveValue;
    }
    if (twoMarksValue !== "") {
      test_2_marks = +twoMarksValue;
    }
    if (threeMarksValue !== "") {
      test_3_marks = +threeMarksValue;
    }
    if (fiveMarksValue !== "") {
      test_5_marks = +fiveMarksValue;
    }
    test_total_marks = test_1_mcq_marks + test_1_marks + 2 * test_2_marks + 3 * test_3_marks + 5 * test_5_marks;

    setMaximumMarks(test_total_marks);
  };

  const handleChapterChange = (value, event) => {
    if (event.action === "select-option" && event.option.value === "*") {
      setSelectedOptions(chapters);
    } else if (event.action === "deselect-option" && event.option.value === "*") {
      setSelectedOptions([]);
    } else if (event.action === "deselect-option") {
      setSelectedOptions(value.filter((o) => o.value !== "*"));
    } else if (value.length === chapters.length - 1) {
      setSelectedOptions(chapters);
    } else {
      setSelectedOptions(value);
    }
  };

  function getDropdownButtonLabel({ placeholderButtonLabel, value }) {
    let chapterLabel = placeholderButtonLabel + "s";
    if (value && value.some((o) => o.value === "*")) {
      return `All ${chapterLabel} Selected`;
    } else if (value.length === 0) {
      return `Select ` + placeholderButtonLabel;
    } else {
      if (value.length === 1) {
        chapterLabel = placeholderButtonLabel;
      }
      return `${value.length} ${chapterLabel} Selected`;
    }
  }

  return (
    <div className="main-wrapper course-page">
      {/* User Header */}
      <UserHeader index={3} />

      <Styles>
        {/* Course Grid */}
        <section className="dashboard-grid-area">
          <Container>
            <Row>
              <Col lg="3" md="3" sm="12">
                <DashboardMenu index={3} />
              </Col>
              <Col>
                {props.showLoader && props.showLoader.length > 0 && <LoaderNew />}
                {props.showLoader.length === 0 && (
                  <Row>
                    <Col lg="12" md="12" sm="12">
                      <div className="course-items">
                        <Fragment>
                          {/* Course Item */}
                          <Row>
                            <Col>
                              <h5 className="grid-header ml-0">Generate New Test</h5>
                            </Col>
                          </Row>

                          <Row>
                            <Col>
                              <Box
                                component="form"
                                sx={{
                                  "& .MuiTextField-root": { m: 1 },
                                  minHeight: "320px",
                                  margin: "20px 0",
                                }}
                                noValidate
                                autoComplete="off"
                              >
                                <Grid container spacing={2}>
                                  <Grid item xs={12} sm={6} md={6}>
                                    <Autocomplete
                                      id="course_id"
                                      freeSolo
                                      options={props.subscriptions}
                                      value={selectedCourse}
                                      inputValue={selectedCourseInput}
                                      onChange={(event, newValue) => {
                                        setSelectedCourse(newValue ? newValue.subsubcategory_id : null);
                                        setSelectedCourseInput(
                                          newValue
                                            ? `${newValue.subsubcategory.subcategory.category.name} / ${newValue.subsubcategory.subcategory.name} / ${newValue.subsubcategory.name}`
                                            : ""
                                        );
                                        if (newValue && newValue.subsubcategory.id) {
                                          props.subjectDetails([newValue.subsubcategory.id]);
                                        }
                                        if (newValue == null) {
                                          setSelectedOptions([]);
                                        }
                                      }}
                                      renderOption={(props, option) => (
                                        <Box component="li" {...props}>
                                          {option.subsubcategory.subcategory.category.name}
                                          {" / "}
                                          {option.subsubcategory.subcategory.name}
                                          {" / "}
                                          {option.subsubcategory.name}
                                        </Box>
                                      )}
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          label="Select Subscribed Course"
                                          inputProps={{
                                            ...params.inputProps,
                                            autoComplete: "", // disable autocomplete and autofill
                                          }}
                                        />
                                      )}
                                    />
                                  </Grid>
                                  <Grid item xs={12} sm={6} md={6}>
                                    <TextField
                                      label="Test Paper Title"
                                      fullWidth
                                      placeholder="Enter Test Paper Title"
                                      required
                                      id="test_title"
                                      onChange={handleTitleChange}
                                      value={testTitle}
                                      error={testTitleError !== ""}
                                      helperText={testTitleError}
                                    />
                                  </Grid>
                                  <Grid item xs={12} sm={6} md={6}>
                                    <TextField
                                      id="number_of_sets"
                                      select
                                      fullWidth
                                      label="Select Number of Sets Required"
                                      value={selectedSets}
                                      onChange={handleSetsChange}
                                      helperText={selectedSetsError}
                                      error={selectedSetsError !== ""}
                                    >
                                      {numberOfSets &&
                                        numberOfSets.map((item) => (
                                          <MenuItem value={item.id} key={item.id}>
                                            {item.name}
                                          </MenuItem>
                                        ))}
                                    </TextField>
                                  </Grid>
                                  <Grid item xs={12} sm={6} md={6}>
                                    <div
                                      style={{
                                        marginLeft: "10px",
                                        width: "100%",
                                      }}
                                    >
                                      <label
                                        style={{
                                          fontSize: "11px",
                                          color: "#555555",
                                          fontWeight: "500",
                                        }}
                                      >
                                        Select {chapterLabel}s
                                      </label>
                                      <div
                                        className="multi-select"
                                        data-toggle="popover"
                                        data-trigger="focus"
                                        data-content={`Please select ${chapterLabel}`}
                                        style={{
                                          marginBottom: "20px",
                                          width: "100%",
                                        }}
                                      >
                                        <ReactMultiSelectCheckboxes
                                          options={[...chapters]}
                                          placeholderButtonLabel={chapterLabel}
                                          getDropdownButtonLabel={getDropdownButtonLabel}
                                          value={selectedOptions}
                                          onChange={handleChapterChange}
                                          setState={setSelectedOptions}
                                        />
                                      </div>
                                      <span className="login_input-msg"></span>
                                    </div>
                                  </Grid>
                                  <Grid item xs={12} sm={6} md={6}>
                                    <TextField
                                      label="1 Mark MCQ Questions Count"
                                      fullWidth
                                      placeholder="Enter 1 Mark MCQ Questions Count"
                                      required
                                      inputProps={{
                                        max: 50,
                                        min: 0,
                                      }}
                                      type="number"
                                      onChange={handleOneMarkMCQChange}
                                      value={oneMarkMCQ}
                                      error={oneMarkMCQError !== ""}
                                      helperText={oneMarkMCQError}
                                    />
                                  </Grid>
                                  <Grid item xs={12} sm={6} md={6}>
                                    <TextField
                                      label="1 Mark Subjective Questions Count"
                                      fullWidth
                                      placeholder="Enter 1 Mark Subjective Questions Count"
                                      required
                                      inputProps={{
                                        max: 50,
                                        min: 0,
                                      }}
                                      type="number"
                                      onChange={handleOneMarkSubjectiveChange}
                                      value={oneMarkSubjective}
                                      error={oneMarkSubjectiveError !== ""}
                                      helperText={oneMarkSubjectiveError}
                                    />
                                  </Grid>
                                  <Grid item xs={12} sm={6} md={6}>
                                    <TextField
                                      label="2 Marks Questions Count"
                                      fullWidth
                                      placeholder="Enter 2 Marks Questions Count"
                                      required
                                      inputProps={{
                                        max: 50,
                                        min: 0,
                                      }}
                                      type="number"
                                      onChange={handleTwoMarksChange}
                                      value={twoMarks}
                                      error={twoMarksError !== ""}
                                      helperText={twoMarksError}
                                    />
                                  </Grid>
                                  <Grid item xs={12} sm={6} md={6}>
                                    <TextField
                                      label="3 Marks Questions Count"
                                      fullWidth
                                      placeholder="Enter 3 Marks Questions Count"
                                      required
                                      inputProps={{
                                        max: 50,
                                        min: 0,
                                      }}
                                      type="number"
                                      onChange={handleThreeMarksChange}
                                      value={threeMarks}
                                      error={threeMarksError !== ""}
                                      helperText={threeMarksError}
                                    />
                                  </Grid>
                                  <Grid item xs={12} sm={6} md={6}>
                                    <TextField
                                      label="5 Marks Questions Count"
                                      fullWidth
                                      placeholder="Enter 5 Marks Questions Count"
                                      required
                                      inputProps={{
                                        max: 50,
                                        min: 0,
                                      }}
                                      type="number"
                                      onChange={handleFiveMarksChange}
                                      value={fiveMarks}
                                      error={fiveMarksError !== ""}
                                      helperText={fiveMarksError}
                                    />
                                  </Grid>
                                  <Grid
                                    item
                                    xs={12}
                                    sm={6}
                                    md={6}
                                    sx={{ justifyContent: "center", display: "flex", flexDirection: "column", textAlign: "center" }}
                                  >
                                    <label>Total Marks: {maximumMarks}</label>
                                  </Grid>
                                  <Grid item xs={12} className="text-center">
                                    <Button
                                      variant="contained"
                                      size="large"
                                      onClick={onGenerateTestClick}
                                      className={"orange-bg"}
                                      disabled={props.showSubLoader && props.showSubLoader.length > 0}
                                    >
                                      Generate Test
                                    </Button>
                                  </Grid>
                                </Grid>
                              </Box>
                            </Col>
                          </Row>
                        </Fragment>
                      </div>
                    </Col>
                  </Row>
                )}
              </Col>
            </Row>
          </Container>
        </section>
      </Styles>

      {/* Footer 2 */}
      <FooterTwo />
    </div>
  );
};

const mapStateToProps = (state) => ({
  subscriptions: state.subscription.data.subscriptions,
  subsubcategory: state.subject.data.subsubcategory,
  showLoader: state.user.showLoader,
});

const mapDispatchToProps = (dispatch) => ({
  subscriptionData: (data) => dispatch(subscriptionsListDataAction(data)),
  subjectDetails: (data) => dispatch(subjectDetailsAction(data)),
  generateTest: (data) => dispatch(generateTestAction(data)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NewTest));
