import styled from "styled-components";
import { colors, fonts } from "../common/element/elements.js";

export const Styles = styled.div`
  .carousel-content {
    flex: 1;
  }

  .partner-program {
    ${colors.gredient_black_text}
  }

  .study_material_heading {
    ${colors.gredient_orange_text}
  }
  .black {
    color: black !important;
    @media (max-width: 767px) {
      color: #fff !important;
    }
  }
  .white {
    color: white !important;
  }

  .home-carousel-item {
    min-height: 450px;
    display: flex;
    align-items: center;
    color: #fff;
    font-family: ${fonts.nunito};
    @media (min-width: 1920px) {
      min-height: 600px;
    }

    .study-material {
      background-image: url("/assets/images/banners/study_material_banner.webp");
      @media (max-width: 767px) {
        background-image: url("/assets/images/banners/mobile_study_material_banner.webp");
      }
    }
    .stem-robotics {
      color: #fff;
      background-image: url("/assets/images/banners/robotics_banner.webp");
      @media (max-width: 767px) {
        color: #fff;
        background-image: url("/assets/images/banners/mobile_robotics_banner.webp");
      }
    }
    .partner-program-banner {
      background-image: url("/assets/images/banners/partner_banner.webp");
      @media (max-width: 767px) {
        background-image: url("/assets/images/banners/mobile_partner_banner.webp");
      }
    }
    .bg-overlay {
      background-color: rgba(20, 30, 58, 0.85);
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
    }

    h2,
    p,
    img {
      position: relative;
    }
    .mover-image {
      animation: mover 1s infinite alternate;
    }
    .home-img {
      text-align: center;
      img {
        max-height: 380px;
        position: relative;
        z-index: 1;
      }
    }
    .img-fluid {
      max-width: 100%;
      height: auto;
    }
  }
`;
