import { doPostRequest } from "../../shared/api/base-api";
import { apiUrl } from "../../shared/constants";

//master data api
export function getMasterData(data) {
  return doPostRequest(apiUrl.masterData, data);
}

//send campaign otp api
export function sendCampaignOTP(data) {
  return doPostRequest(apiUrl.sendCampaignOTP, data);
}

//send campaign otp api
export function verifyCampaignOTP(data) {
  return doPostRequest(apiUrl.verifyCampaignOTP, data);
}

