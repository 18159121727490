import styled from "styled-components";
import { colors } from "../common/element/elements.js";

export const Styles = styled.div`
  .service-area {
    background: rgb(246, 249, 255);

    .section {
      padding: 70px 0px;
      font-size: 16px;
    }

    .sec-title {
      h4 {
        color: ${colors.black1};
        line-height: 35px;
        font-weight: 600;
        margin: auto;
        margin-bottom: 40px;

        @media (max-width: 575px) {
          margin-bottom: 15px;
          font-size: 20px;
        }
      }
    }

    .service-box {
      padding: 35px 20px 20px;
      box-shadow: 0 12px 25px rgba(0, 0, 0, 0.07);
      border-radius: 5px;
      margin-bottom: 30px;
      background-color: #fff;
      min-height: 290px;
      .box-desc-wrapper {
        max-width: 300px;
        .box-des {
          padding: 6px 15px 6px 20px;

          .des-icon {
            height: 25px;
            text-align: center;
            position: relative;

            i {
              font-size: 25px;
              color: ${colors.white};
              width: 40px;
              display: inline-block;
              position: relative;
              z-index: 1;

              @media (max-width: 991px) {
                font-size: 24px;
                width: 45px;
              }

              @media (max-width: 767px) {
                font-size: 34px;
                width: 60px;
              }
            }
          }

          .des-title {
            min-height: 45px;
            h4 {
              color: ${colors.black1};
              text-transform: uppercase;
              font-weight: 600;
              margin-bottom: 6px;
              font-size: 18px !important;
            }

            p {
              font-size: 16px;
              color: ${colors.white};

              @media (max-width: 991px) {
                font-size: 13px;
                letter-spacing: 0;
              }

              @media (max-width: 767px) {
                font-size: 14px;
                letter-spacing: 0.3px;
              }
            }
          }
        }
      }

      .box-icon {
        background: ${colors.white};
        height: 36px;
        text-align: center;
        margin-right: 20px;
        position: relative;
        margin-left: 20px;

        i {
          font-size: 34px;
          color: #000;
          width: 60px;
          display: inline-block;
          position: relative;
          z-index: 1;
          margin-top: -8px;

          @media (max-width: 991px) {
            font-size: 24px;
            width: 45px;
          }

          @media (max-width: 767px) {
            font-size: 34px;
            width: 60px;
          }
        }

        &::before {
          content: "";
          position: absolute;
          border-width: 15px 30px;
          border-style: solid;
          border-top-color: transparent;
          border-right-color: transparent;
          border-bottom-color: ${colors.white};
          border-left-color: transparent;
          top: -30px;
          left: 0;
          z-index: 1;

          @media (max-width: 991px) {
            border-width: 12px 23px;
            top: -24px;
          }

          @media (max-width: 767px) {
            border-width: 15px 30px;
            top: -30px;
          }
        }

        &:after {
          content: "";
          position: absolute;
          border-width: 15px 30px;
          border-style: solid;
          border-top-color: ${colors.white};
          border-right-color: transparent;
          border-bottom-color: transparent;
          border-left-color: transparent;
          bottom: -30px;
          left: 0;
          z-index: 1;

          @media (max-width: 991px) {
            border-width: 12px 23px;
            bottom: -24px;
          }

          @media (max-width: 767px) {
            border-width: 15px 30px;
            bottom: -30px;
          }
        }

        @media (max-width: 991px) {
          height: 22px;
          margin-right: 10px;
        }

        @media (max-width: 767px) {
          height: 36px;
          margin-right: 20px;
        }
      }

      .box-title {
        margin-top: 30px;
        margin-bottom: 15px;
        padding: 0 10px;
        width: 100%;
        h6 {
          color: ${colors.black};
          text-transform: uppercase;
          font-weight: 600;
          margin-top: 6px;
          margin-bottom: 6px;
          font-size: 20px;
          text-align: center;

          @media (max-width: 991px) {
            letter-spacing: 0;
          }

          @media (max-width: 767px) {
            letter-spacing: 0.3px;
          }
        }

        p {
          font-size: 14px;
          color: ${colors.text3};

          @media (max-width: 991px) {
            font-size: 13px;
            letter-spacing: 0;
          }

          @media (max-width: 767px) {
            font-size: 14px;
            letter-spacing: 0.3px;
          }
        }
      }

      @media (max-width: 991px) {
        padding: 25px 10px 8px;
      }

      @media (max-width: 767px) {
        padding: 35px 20px;
        margin-bottom: 25px;
      }
    }

    @media (max-width: 767px) {
      padding: 30px 0 15px;
    }
  }

  .teacher {
    ${colors.gredient_green_text}
    font-size: 30px;
    font-weight: 800;
  }
  .empowering {
    ${colors.gredient_black_text}
    font-size: 30px;
  }
  .description {
    font-size: 16px;
    line-height: 28px;
  }
  .stem_teaching {
    ${colors.gredient_violet_text}
    font-weight: 800;
  }
`;
