import React from "react";
import MathJax from "mathjax3-react";

const Overview = (props) => {
    return (
        <div className="course-desc">
            <MathJax.Provider>
                <MathJax.Html html={props.subsubcategory.description} />
            </MathJax.Provider>
        </div>
    )
}

export default Overview;