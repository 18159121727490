import Link from "@mui/material/Link";
import ChapterDatas from "../../../data/event/chapters.json";
import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import common from "../../../shared/common";
import Snackbar from "@mui/material/Snackbar";
import DownloadIcon from "@mui/icons-material/Download";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import CheckIcon from "@mui/icons-material/Check";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { Button, Fab } from "@mui/material";

const Chapters = (props) => {
  const {
    match: { params },
  } = props;
  const [snackPack, setSnackPack] = useState([]);
  const [open, setOpen] = useState(false);
  const [messageInfo, setMessageInfo] = useState(undefined);
  useEffect(() => {
    if (snackPack.length && !messageInfo) {
      // Set a new snack when we don't have an active one
      setMessageInfo({ ...snackPack[0] });
      setSnackPack((prev) => prev.slice(1));
      setOpen(true);
    } else if (snackPack.length && messageInfo && open) {
      // Close an active snack when a new one is added
      setOpen(false);
    }
  }, [snackPack, messageInfo, open]);

  const handleSnackbar = (message) => {
    return setSnackPack((prev) => [...prev, { message, key: new Date().getTime() }]);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };
  const handleExited = () => {
    setMessageInfo(undefined);
  };

  const downloadResource = (data, url) => {
    // if (props.subsubcategory.can_user_access || data.allow_free_access) {
    window.open(url);
    // common.downloadResource(url)
    // } else {
    //     alert('You need to subscribe the course first')
    // }
  };
  return (
    <div className="course-element">
      {props.subsubcategory.can_user_access === 0 && (
        <Row className="mb-3">
          <Col lg="12" md="12">
            <Fab
              variant="extended"
              className="pull-right fab-top-right orange-bg"
              onClick={() => props.history.push(process.env.PUBLIC_URL + "/package/" + params.type)}
            >
              <CheckIcon sx={{ mr: 1 }} />
              Buy Now
            </Fab>
          </Col>
        </Row>
      )}
      <Row>
        <Col lg="12" md="12">
          {props.subsubcategory.chapters &&
            props.subsubcategory.chapters.length > 0 &&
            props.subsubcategory.chapters.map((data, i) => (
              <div className="event-box" key={i}>
                <Row>
                  <Col xl="2" lg="2" md="2">
                    <div className="event-img">
                      <img
                        src={
                          (data.chapter_image && data.chapter_image_url.replace(/\\/g, "/")) ||
                          process.env.PUBLIC_URL + `/assets/images/${ChapterDatas[0].eventImg}`
                        }
                        alt=""
                        className="img-fluid"
                      />
                    </div>
                  </Col>
                  <Col xl="10" lg="10" md="10" className="subject-list-item">
                    <div className="event-content">
                      <div className="content-box" style={{ width: "100%" }}>
                        <Row>
                          <Col md="12">
                            <div className="event-title">
                              <h6 style={{ margin: 0 }}>{data.name}</h6>
                              {props.subsubcategory.can_user_access === 0 ? (
                                data.allow_free_access === 0 ? (
                                  <Link
                                    onClick={(e) => {
                                      e.preventDefault();
                                      handleSnackbar("Please subscribe first to get access to this chapter's technology kit");
                                    }}
                                  >
                                    <LockOutlinedIcon />
                                  </Link>
                                ) : (
                                  data.teacher_technology_kit && (
                                    <Button
                                      size="large"
                                      variant="contained"
                                      className="right-btn orange-bg"
                                      onClick={() => downloadResource(data, data.teacher_technology_kit_url)}
                                    >
                                      Free Download KIT <DownloadIcon />
                                    </Button>
                                  )
                                )
                              ) : (
                                data.teacher_technology_kit && (
                                  <Button
                                    size="large"
                                    variant="contained"
                                    className="right-btn orange-bg"
                                    onClick={() => downloadResource(data, data.teacher_technology_kit_url)}
                                  >
                                    Download KIT <DownloadIcon />
                                  </Button>
                                )
                              )}
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            ))}
        </Col>
      </Row>
      <Snackbar
        key={messageInfo ? messageInfo.key : undefined}
        open={open}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        autoHideDuration={6000}
        onClose={handleClose}
        TransitionProps={{ onExited: handleExited }}
        message={messageInfo ? messageInfo.message : undefined}
        action={
          <React.Fragment>
            <IconButton aria-label="close" color="inherit" sx={{ p: 0.5 }} onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </React.Fragment>
        }
      />
    </div>
  );
};

export default Chapters;
