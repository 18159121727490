import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import FooterTwo from "../../components/FooterTwo";
import HeaderTwo from "../../components/HeaderTwo";
import VideoSlider from "../../components/VideoSlider";
import { Styles as RoboticsStyles } from "./styles.js";
import RoboticsContent from "../../components/RoboticsContent";
import ButtonArea from "../../components/ButtonArea";
import common from "../../shared/common";
import { webUrl } from "../../shared/constants";
import { sendAuditEventAction } from "../../components/actions/common";

const Robotic = (props) => {
  const onClickJoinNow = () => {
    if (common.getFromStorage("userName")) this.props.history.push(webUrl.userDashboard);
  };
  
  useEffect(() => {
    if (common.getFromStorage("userName")) props.sendAuditEvent({ event_id: "robotics", source: "web" });
  }, []);
  return (
    <RoboticsStyles>
      <div className="main-wrapper">
        {/* Header */}
        <HeaderTwo {...props} />

        <RoboticsContent {...props} />

        {/* Hero Image */}
        {/* <div className="video-wrapper">
          <video autoPlay muted loop style={{ width: "100%" }}>
            <source
              src={`https://player.vimeo.com/progressive_redirect/playback/700788057/rendition/1080p?loc=external&signature=b7d13d068210f3c7f91888e8645e9bb77854af1be30110a88551ab155d93cdb0`}
              type="video/mp4"
            />
          </video> */}
        {/* <iframe title="Test" class="embed-responsive-item" src="https://player.vimeo.com/video/700788057" allowfullscreen></iframe> */}
        {/* <iframe
            title="vimeo-player"
            src={`https://player.vimeo.com/video/700788057?background=1`}
            className="embed-responsive-item"
            width="100%"
            height="600"
            frameborder="0"
            allowFullScreen
            allow="autoplay"
          ></iframe> */}
        {/* <div className="video-overlay">
            <h1 className="banner-title">FUN with STEM - Alyss Robotics program</h1>
            <h2 className="banner-subtitle">Alyss - Your Child's partner in STEM learning</h2>
            <Button
              id={"hero-btn" + idPref}
              variant="contained"
              size="large"
              className={"mt-3 mb-3 orange-bg banner-button"}
              onClick={onClickJoinNow}
            >
              Enrol Now
            </Button>
            <User openCommand={true} commandCtrlId={"hero-btn" + idPref} />
          </div>
        </div> */}
        {/* Offerings */}
        {/* <Offerings /> */}

        {/* Button Area */}
        {/* <ButtonArea buttonText="JOIN NOW" onHeroButtonClick={onClickJoinNow} showPopup={!!!common.getFromStorage("userName")} /> */}
        {/* Three Section */}
        {/* <ThreeSection /> */}

        {/* Video Slider */}
        <VideoSlider />
        {/* Button Area */}
        <ButtonArea buttonText="Join Now" onHeroButtonClick={onClickJoinNow} showPopup={!!!common.getFromStorage("userName")} />

        {/* Button Area */}
        {/* <ButtonArea buttonText="JOIN NOW" onHeroButtonClick={onClickJoinNow} showPopup={!!!common.getFromStorage("userName")} /> */}
        {/* Testimonial Slider */}
        {/* <TestimonialSlider /> */}

        {/* App Section */}
        {/* <AppSection /> */}

        {/* Footer */}
        <FooterTwo />
      </div>
    </RoboticsStyles>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  sendAuditEvent: (data) => dispatch(sendAuditEventAction(data)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Robotic));
