import React, { Component } from 'react';
import Datas from '../data/expert_teachers/expert_teachers_slider.json';
import { Container, Row, Col } from 'react-bootstrap';
import Swiper from 'react-id-swiper';
import { Styles } from "./styles/expertTeacherSlider.js";

class ExpertTeacherSlider extends Component {
    render() {
        const settings = {
            slidesPerView: 1,
            loop: true,
            speed: 1000,
            autoplay: {
                delay: 3000,
                disableOnInteraction: false
            },
            spaceBetween: 30,
            watchSlidesVisibility: true,
            pagination: {
                el: '.slider-dot.text-center',
                clickable: true
            },
        };

        return (
            <Styles>
                {/* Expert Teacher Slider */}
                <section className="expert-teacher-area">
                    <Container>
                        <Row>
                            <Col md="12">
                                <div className="sec-title text-center">
                                    <h4>MEET SOME OF OUR EXPERT TEACHERS</h4>
                                </div>
                            </Col>
                            <Col md="12" className="expert-teacher-slider">
                                
                                <Swiper {...settings}>
                                    {
                                        Datas.dataList.map((data, i) => (
                                            <div className="slider-item expert-item" key={i}>
                                                <div className="slider-image-block">
                                                    <img src={process.env.PUBLIC_URL + `/assets/images/expert_teachers/${data.authorImg}`} className="slider-image" alt="" />
                                                </div>
                                                <div className="slider-content">
                                                <div className="slider-text">
                                                <div
                                                    dangerouslySetInnerHTML={{
                                                    __html: `${data.expertTeacherDesc}`,
                                                    }}
                                                ></div>
                                                </div>
                                                <div className="writer">
                                                    <h6>{data.authorName}</h6>
                                                    <p>{data.authorTitle}</p>
                                                </div>
                                                </div>
                                            </div>
                                        ))
                                    }
                                </Swiper>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </Styles>
        )
    }
}

export default ExpertTeacherSlider
