import { actionTypes } from "./action";

/** Initial state */
const initialState = {
  data: {
    contact: {},
  },
};

/** Reducers */
export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case actionTypes.CONTACT_SUBMIT_REQUEST_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          contact: payload.data,
        },
      };

    default:
      return state;
  }
};
