import styled from "styled-components";
import { colors, fonts } from "../../components/common/element/elements.js";

export const Styles = styled.div`
  .orange-bg {
    ${colors.gredient_orange_bg}
    svg {
      color: #fff;
    }
  }
  .p-absolute {
    position: absolute;
    width: 100%;
  }

  .p-student {
    height: 20vw;
    left: auto;
    right: 12%;
    margin-top: 100px;
    @media (min-width: 1366px) {
      height: 19vw;
      left: 60vw;
      margin-top: 100px;
    }
    @media (min-width: 901px) and (max-width: 1365px) {
      height: 22vw;
      right: 4vw;
      margin-top: 100px;
    }
    @media (min-width: 768px) and (max-width: 900px) {
      height: 33vw;
      right: -18%;
      margin-top: 100px;
    }
    @media (max-width: 767px) {
      height: 40vw;
      right: -27%;
      margin-top: 150px;
    }
  }

  .study_material-bg {
    background-image: linear-gradient(to right, #00b0eb, white);
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat;
  }

  .features-section {
    background: #ebecff;

    .sec-title {
      color: ${colors.orange};
      font-size: 20px;
      h4 {
        margin-bottom: 20px;
        font-size: 30px;
        font-weight: 800;
      }
    }
    .feature-1 {
      .feature-icon,
      .feature-title {
        color: ${colors.dark_purple};
      }
    }
    .feature-2 {
      .feature-icon,
      .feature-title {
        color: ${colors.orange};
      }
    }
    .feature-3 {
      .feature-icon,
      .feature-title {
        color: ${colors.green2};
      }
    }
    .feature-4 {
      .feature-icon,
      .feature-title {
        color: ${colors.yellow};
      }
    }
    .feature-5 {
      .feature-icon,
      .feature-title {
        color: ${colors.red};
      }
    }
    .feature-block {
      max-width: 295px;
      margin: 25px auto;
      background: white;
      border-radius: 5px;
      padding: 13px;
      box-shadow: 0 12px 25px rgb(0 0 0 / 20%);
      .feature-icon {
        text-align: center;
        svg {
          font-size: 38px;
        }
      }

      .feature-title {
        text-align: center;
        font-weight: 900;
        font-size: 24px;
      }

      .feature-excerpt {
        font-size: 16px;
        margin-top: 9px;
        margin-left: 27px;
        ul {
          text-indent: -30px;
          li {
            list-style: none;
            font-size: 16px;
            svg {
              margin-right: 10px;
            }
          }
        }
      }
    }
  }
  .explore-section {
    margin: 60px 20px 90px 0 !important;
    background-color: #fa9e2d;
    min-height: 300px;
    border-radius: 0 10px 10px 0;
    color: white;

    .section-title {
      margin-top: 25px;
      font-size: 36px;
      text-transform: uppercase;
      line-height: 39px;
      font-weight: 700;
      max-width: 300px;
      margin-left: 35px;
    }
    .section-subtitle {
      font-size: 18px;
      max-width: 315px;
      margin-left: 35px;
      margin-top: 10px;
      font-weight: 500;
    }
    .explore-button {
      margin-top: 30px;
      button {
        background: white;
        color: #df6c0a;
        margin-left: 35px;
        font-size: 18px;
        text-transform: capitalize;
        &:hover {
          color: white;
          background: #df6c0a;
        }
      }
    }
    .explore-box-top {
      padding-top: 15px;
      background-color: #fff;
      border: 1px solid #fa9e2d;
      border-radius: 16px;
      width: 100%;
      padding-bottom: 15px;
      margin-top: -60px;

      @media (max-width: 767px) {
        margin: auto;
      }
    }
    .explore-box-bottom {
      padding-top: 15px;
      background-color: #fff;
      border: 1px solid #fa9e2d;
      border-radius: 16px;
      width: 100%;
      padding-bottom: 15px;
      margin-bottom: -60px;
      margin-top: 20px;
      @media (max-width: 767px) {
        margin: 20px auto;
      }
    }
    .explore-box-right {
      padding-top: 15px;
      background-color: #fff;
      border: 1px solid #fa9e2d;
      border-radius: 16px;
      width: 100%;
      padding-bottom: 15px;
      margin-top: 11%;
      margin-left: 30px;

      @media (max-width: 767px) {
        margin: 0 auto 20px;
      }
    }
    .box-right {
      text-align: right;
      padding: 5px;
    }
    .box-left {
      text-align: left;
      padding: 5px;
      color: black;
      padding-left: 15px;
      .box-title {
        font-weight: 800;
        font-size: 25px;
      }
      .box-points {
        ul {
          text-indent: -20px;
          li {
            color: orange;
            font-size: 14px;
            font-weight: 600;
            list-style: none;
            margin-left: 15px;
            svg {
              color: #fa9e2d;
              font-size: 17px;
            }
          }
        }
      }
    }
  }
  .slider-title {
    color: ${colors.black};
    font-weight: 800;
    text-transform: uppercase;
    font-size: 3vw;
  }
  .slider-subtitle {
    font-weight: 600;
    color: ${colors.black};
    text-transform: uppercase;
    font-size: 1.2vw;
    font-style: italic;
  }
  .slider-content-wrapper {
    position: relative;
    height: 33.7vw;
    @media (max-width: 1366px) {
      height: 33.55vw;
    }
    @media (max-width: 768px) {
      height: 33.25vw;
    }
    @media (max-width: 576px) {
      height: 32.8vw;
    }
  }
  .slider-content-text {
    position: absolute;
    bottom: 0;
    margin-left: -2vw;
    display: inline-block;
    @media (max-width: 1620px) {
      padding-left: 5vw;
    }
    @media (max-width: 1410px) {
      padding-left: 10vw;
    }
    @media (max-width: 576px) {
      padding-left: 15vw;
    }
    button {
      padding: 0.5vw 1vw;
      font-size: 1.7vw;
      min-width: 20vw;
      height: 3.5vw;
      text-transform: capitalize;
      margin: 1vw 0 !important;
      &:hover {
        color: #ea8733;
        background: #fff;
      }
    }
  }
  .top-left-in {
    font-size: 21px;
    line-height: 22px;
    font-family: "Manrope";

    &:before {
      content: " ";
      position: absolute;
      width: 0;
      height: 0;
      left: 15px;
      top: -21px;
      border: 11px solid;
      border-color: transparent transparent #ec8339 #fd735c;
      display: none;
    }
  }
  .slider-content {
    // min-height: 551px;
    // @media (max-width: 1919px) {
    //   min-height: 450px;
    // }
    .reverse-flex {
      @media (max-width: 767px) {
        flex-direction: column-reverse;
        padding: 30px;
      }
    }
  }
  ul.bar-social {
    margin-right: 30px;
    position: relative;

    li {
      a {
        font-size: 13px;
        color: ${colors.white};
        background: ${colors.orange};
        display: inline-block;
        width: 25px;
        height: 25px;
        text-align: center;
        padding-top: 3px;
        border-radius: 50%;

        &:hover {
          background: ${colors.white};
          color: #000;
        }
      }

      &:last-child {
        a {
          margin-right: 0;
        }
      }
    }
  }
  .bottom-block {
    font-size: 20px;
    font-weight: 500;
    color: #000;
    text-transform: uppercase;
    text-align: center;
    .highlight-bg {
      color: rgb(234 135 51);
    }
  }
  .about-us {
    .about-content {
      h4.about-title {
        color: ${colors.black1};
        line-height: 35px;
        font-size: 30px;
        font-weight: 800;
        margin-bottom: 25px;

        @media (max-width: 575px) {
          margin-bottom: 15px;
          font-size: 20px;
        }
      }

      .counter-box {
        h3 {
          margin-bottom: 10px;

          span {
            font-weight: 600;
          }

          svg,
          i {
            font-size: 20px;
            vertical-align: middle;
          }
        }

        p {
          font-size: 14px;
          color: ${colors.text3};
          font-weight: 500;
        }
      }

      .counter-box.box1 {
        h3 {
          color: ${colors.red};
        }
      }

      .counter-box.box2 {
        h3 {
          color: ${colors.purple};
        }
      }

      .counter-box.box3 {
        h3 {
          color: ${colors.blue};
        }
      }

      .about-para {
        font-size: 15px;
        color: ${colors.text3};
        line-height: 28px;
        margin-bottom: 30px;

        @media (max-width: 991px) {
          font-size: 14px;
        }
      }

      .cta-box {
        margin-bottom: 15px;

        .cta-icon {
          margin-right: 15px;

          i {
            font-size: 20px;
            color: ${colors.green};
            width: 35px;
            height: 35px;
            border: 1px solid ${colors.green};
            border-radius: 50%;
            padding-top: 7px;
          }
        }

        .cta-content {
          h6 {
            color: ${colors.black1};
            margin-top: 5px;
            margin-bottom: 5px;
          }

          p {
            font-size: 14px;
            color: ${colors.text3};
            line-height: 25px;

            @media (max-width: 991px) {
              font-size: 13px;
            }
          }
        }
      }
    }

    .about-image {
      position: relative;
      height: 100%;

      img.main-img {
        width: 100%;
        border-radius: 5px;
        margin-top: 50px;

        &:hover {
          transform: scale(1.02);
          transition: 0.7s;
        }
        @media (max-width: 767px) {
          display: none;
        }
      }

      img.pattern-img {
        @media (max-width: 1199px) {
          max-width: 100%;
        }

        @media (max-width: 767px) {
          display: none;
        }
      }

      p.exp-box {
        font-size: 14px;
        color: #ffffff;
        background: ${colors.gr_bg};
        position: absolute;
        bottom: 15%;
        right: 20%;
        margin-bottom: -30px;
        margin-right: -20px;
        width: 120px;
        height: 120px;
        z-index: 1;
        border-radius: 50%;
        text-align: center;
        font-weight: 500;
        padding-top: 24px;

        span {
          display: block;
          font-size: 30px;
          font-weight: 800;
          margin-bottom: -5px;

          i {
            font-size: 15px;
            vertical-align: middle;
          }
        }

        @media (max-width: 991px) {
          bottom: 48%;
          right: 11%;
        }
      }
    }

    @media (max-width: 767px) {
      padding: 30px 0 20px;
    }
  }
`;
