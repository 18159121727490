import styled from "styled-components";
import { colors, fonts } from "../../../components/common/element/elements.js";

export const Styles = styled.div`
    table.table{
        tbody{
            tr{
                td{
                    border: none;
                    padding: 3px 10px;
                }
            }
        }
    }

    .table-text{
        font-size: 14px;
        color: ${colors.black2};
        font-family: ${fonts.manrope};

        svg{
            font-size: 14px;
        }
    }

    .capitalize{
        text-transform: capitalize;
    }
    .view-order-details{
        ${colors.gredient_orange_bg}
    }
`;