import { actionTypes } from "./action";

/** Initial state */
const initialState = {
  data: {
    courses: [],
    courses_detail: [],
  },
  error: null,
};

/** Reducers */
export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case actionTypes.COURSE_PLANS_DATA_REQUEST_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          courses: payload.data.courses,
        },
      };
      case actionTypes.COURSE_PLAN_DETAIL_DATA_REQUEST_SUCCESS:
        return {
          ...state,
          data: {
            ...state.data,
            courses_detail: payload.data.course,
          },
        };
    default:
      return state;
  }
};
