import fetch from "isomorphic-fetch";
import FormData from "form-data";
import { apiUrl, messages } from "./../constants";

/**
  Set default fields to make api call.
*/
const defaultOptions = {
  method: "get",
  headers: {
    "Content-Type": "application/json",
  },
  withCredentials: true,
};

/**
  Used for making request to the api.
*/
export function makeRequest(uri, options = defaultOptions) {
  if (!navigator.onLine) {
    alert(messages.noInternetConnection);
    return () => {
      throw new Error(messages.noInternetConnection);
    };
  }
  if (uri !== apiUrl.userLogin) {
    let _token = null;

    if (uri === apiUrl.verifyRequestOtp) _token = localStorage.getItem("token_connect");
    else _token = localStorage.getItem("token");

    options.headers["Authorization"] = "Bearer " + _token;
  }

  options = JSON.parse(JSON.stringify(options));
  uri = `${apiUrl.baseApiUrl}${uri}`;

  return fetch(uri, options).then(function (response) {
    if (response.status !== 200) {
      return response.json().then((responseBody) => {
        alert(responseBody.message);
        throw new Error(responseBody.message);
      });
    }
    if (response.status >= 400) {
      //If api call has unauthorised response then redirect to login page.
      // if (response.status === 401) {
      //   localStorage.clear();
      //   window.location.href = "/";
      //   return;
      // }
      //will be throwing some betting structured error messages.
      throw new Error(response.status);
    }
    //returns a json request.
    return response.json();
  });
}

/**
  Used for making form request to the api.
*/
export function makeFormRequest(uri, options = defaultOptions) {
  if (!navigator.onLine) {
    alert(messages.noInternetConnection);
    return () => {
      throw new Error(messages.noInternetConnection);
    };
  }
  if (uri !== apiUrl.userLogin) {
    let _token = null;

    if (uri === apiUrl.verifyRequestOtp) _token = localStorage.getItem("token_connect");
    else _token = localStorage.getItem("token");

    options.headers["Authorization"] = "Bearer " + _token;
  }

  //   options = JSON.parse(JSON.stringify(options));
  uri = `${apiUrl.baseApiUrl}${uri}`;

  return fetch(uri, options).then(function (response) {
    if (response.status !== 200) {
      return response.json().then((responseBody) => {
        alert(responseBody.message);
        throw new Error(responseBody.message);
      });
    }
    if (response.status >= 400) {
      //If api call has unauthorised response then redirect to login page.
      // if (response.status === 401) {
      //   localStorage.clear();
      //   window.location.href = "/";
      //   return;
      // }
      //will be throwing some betting structured error messages.
      throw new Error(response.status);
    }
    //returns a json request.
    return response.json();
  });
}

/**
  Used for making get request to the api.
*/
export function doGetRequest(uri, data = null, options = defaultOptions) {
  options.body = data !== null ? JSON.stringify(data) : data;
  options.method = "get";
  return makeRequest(uri, options);
}

/**
 Used for making post request to the api.
*/
export function doPostRequest(uri, data, options = defaultOptions) {
  options.body = JSON.stringify(data);
  options.method = "post";
  return makeRequest(uri, options);
}

/**
 Used for making post request to the api.
*/
export function doPostFileRequest(uri, data, options = defaultOptions, files) {
  let formData = new FormData();
  formData.append("proof", files[0]);
  formData.append("source", data.source);
  formData.append("amount", data.amount);
  formData.append("transaction_date", data.transaction_date);
  formData.append("reference_no", data.reference_no);

  // !! must ,delete header content type is header is  neccessary , the fetch will help you decide the content type is multipart/form-data and add suitable boundary because your body contains file object.
  if (options.headers["Content-Type"] === "multipart/form-data") {
    delete options.headers["Content-Type"];
    delete options.headers["content-type"];
  }
  options = {
    ...options,
    body: formData,
  };
  return makeFormRequest(uri, options);
}

/**
 *
Used for making delete request to the api
 */
export function doDeleteRequest(uri, data, options = defaultOptions) {
  options.body = JSON.stringify(data);
  options.method = "delete";
  return makeRequest(uri, options);
}

/**
 Used for making put request to the api.
*/
export function doPutRequest(uri, data, options = defaultOptions) {
  options.method = "put";
  options.body = JSON.stringify(data);
  return makeRequest(uri, options);
}

/**
 Used for making patch request to the api.
*/
export function doPatchRequest(uri, data, options = defaultOptions) {
  options.body = JSON.stringify(data);
  options.method = "PATCH";
  return makeRequest(uri, options);
}
