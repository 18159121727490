import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import FooterTwo from "../../components/FooterTwo";
import VideoSlider from "../../components/VideoSlider";
import ButtonArea from "../../components/ButtonArea";
import common from "../../shared/common";
import { webUrl } from "../../shared/constants";
import { Styles } from "../../components/styles/homeUS";
import { Button, Container, Grid, Link, Paper, TextField } from "@mui/material";
import { Dropdown } from "react-bootstrap";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import FreeTrial from "../../components/FreeTrial";
import User from "../user";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import DashboardIcon from "@mui/icons-material/Dashboard";
import PrecisionManufacturingIcon from "@mui/icons-material/PrecisionManufacturing";
import SupervisedUserCircleIcon from "@mui/icons-material/SupervisedUserCircle";
import AccountCircleRoundedIcon from "@mui/icons-material/AccountCircleRounded";
import ModalVideo from "react-modal-video";
import { sendAuditEventAction } from "../../components/actions/common";
import CourceList from "./CoursesList"
const Courses = (props) => {
  const [anchorState, setAnchorState] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const openVideoModal = (data) => {
    setIsOpen(true);
  };
  const onClickJoinNow = () => {
    if (common.getFromStorage("userName")) props.history.push(webUrl.userDashboard);
  };
  const onOpenDrawer = (event) => {
    if (event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
      return;
    }
    setAnchorState(true);
  };
  const onCloseDrawer = (event) => {
    if (event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
      return;
    }
    setAnchorState(false);
  };
  const list = () => (
    <Box role="presentation" onClick={onCloseDrawer} onKeyDown={onCloseDrawer}>
      <List>
        <ListItem>
          <ListItemButton
            onClick={() => {
              props.history.push(process.env.PUBLIC_URL + webUrl.studyMaterial);
            }}
          >
            <ListItemIcon>
              <MenuBookIcon />
            </ListItemIcon>
            <ListItemText primary="Study Material" />
          </ListItemButton>
        </ListItem>
        <ListItem>
          <ListItemButton
            onClick={() => {
              props.history.push(process.env.PUBLIC_URL + webUrl.robotic);
            }}
          >
            <ListItemIcon>
              <PrecisionManufacturingIcon />
            </ListItemIcon>
            <ListItemText primary="Robotics" />
          </ListItemButton>
        </ListItem>
        <ListItem>
          <ListItemButton
            onClick={() => {
              props.history.push(process.env.PUBLIC_URL + webUrl.teacher);
            }}
          >
            <ListItemIcon>
              <SupervisedUserCircleIcon />
            </ListItemIcon>
            <ListItemText primary="Teacher Solutions" />
          </ListItemButton>
        </ListItem>
      </List>
    </Box>
  );
  useEffect(() => {
    if (common.getFromStorage("userName")) props.sendAuditEvent({ event_id: "home", source: "web" });
  }, []);

  const profile = common.getFromStorage("profile");
  let dashboardUrl = webUrl.userDashboard;
  if (profile) {
    const profileData = JSON.parse(profile);
    if (profileData.member_type === 4) {
      dashboardUrl = webUrl.partnerDashboard;
    }
  }
  return (
    <Styles>
      {/* <div className="header-courcelist">
        <img src={process.env.PUBLIC_URL + `/assets/images/us/plus.webp`} alt="Plus Signs" className="fixed-plus" />
        <Container maxWidth="100%">
          <div className="logo">
            <Link href={webUrl.homeUS}>
              <img src={process.env.PUBLIC_URL + `/assets/images/us/logo.webp`} alt="ALYSS Logo" />
            </Link>
          </div>
          <div className="right-side">
            <ul className="list-unstyled list-inline bar-login">
              {common.getFromStorage("userName") ? (
                <>
                  <li className="list-inline-item">
                    <Link to={dashboardUrl}>
                      <DashboardIcon />
                      Dashboard
                    </Link>
                  </li>
                  <li className="list-inline-item">
                    <Dropdown
                      className="info-filter"
                      name="country_code"
                      id="country_code"
                      onSelect={(e) => {
                        if (e === webUrl.home) localStorage.clear();
                        this.props.history.push(e);
                      }}
                    >
                      <Dropdown.Toggle as="a" className="filter-item">
                        <Link to={"#"}>
                          <AccountCircleRoundedIcon />
                          {common.getFromStorage("userName")}
                        </Link>
                      </Dropdown.Toggle>
                      <Dropdown.Menu as="ul">
                        <Dropdown.Item eventKey={webUrl.home} as="li">
                          Logout
                        </Dropdown.Item>
                      </Dropdown.Menu>
                      <span className="login_input-msg"></span>
                    </Dropdown>
                  </li>
                </>
              ) : (
                <>
                  <li className="list-inline-item">
                    <Button className="orange-bg" variant="contained" size="large">
                      <User customText="Login / Signup" />
                    </Button>
                  </li>
                </>
              )}
            </ul>

            <Link className="menu" onClick={onOpenDrawer}>
              <img src={process.env.PUBLIC_URL + `/assets/images/us/menu.webp`} alt="Menu" />
            </Link>
          </div>
          <Grid container spacing={2} className="header-content">
            <Grid xs={12} sm={5} className="p-3">
              <h2 className="tp-9">Fun With STEM</h2>
              <h2>ALYSS Robotics Program</h2>
              <ul>
                <li>3D HD Animations for Robotics Concepts</li>
                <li>Learn from Expert Coaches</li>
                <li>Experiential Learning</li>
                <li>Cognitive Development</li>
                <li>Earn Certificates as you climb the course ladder</li>
              </ul>
              <div className="header-bottom-first">
                <h3>
                  Your Child's Partner in <span className="orange-text">STEM Learning</span>
                </h3>
                <h5>Register now for a free demo</h5>
              </div>
            </Grid>
            <Grid xs={12} md={3} sm={3} className="p-3">
              <div>
                <div className="robo-with_students"></div>
              </div>
              <Link
                className="why-robots"
                onClick={() => {
                  openVideoModal();
                }}
              >
                <img src={process.env.PUBLIC_URL + `/assets/images/us/video-icon.webp`} alt="Menu" />
                <span>Why Robot</span>
              </Link>
            </Grid>
            <Grid xs={12} md={4} sm={4} className="p-3">
              <div>
                <div className="robo-only">
                  <div id="robo-overlay"></div>
                </div>
              </div>
            </Grid>
          </Grid>
        </Container>
      </div> */}

      <Container maxWidth="100%">
      
        <section className="innerBanner">
          <img src={`${process.env.PUBLIC_URL}/assets/images/usImages/innerbg.jpg`} />
          <div className="container"> <object>Course Details<strong>Online</strong></object></div>
        </section>
      </Container>
      <div className="main-wrapper">
        <Container>
        </Container>
         <CourceList {...props} />
        <FooterTwo />
      </div>
      <Drawer anchor="right" open={anchorState} onClose={onCloseDrawer}>
        {list()}
      </Drawer>
      <ModalVideo channel="vimeo" isOpen={isOpen} videoId="709618465" onClose={() => setIsOpen(false)} />
    </Styles>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  sendAuditEvent: (data) => dispatch(sendAuditEventAction(data)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Courses));
