import { showLoaderAction, stopLoaderAction } from "../user/action";
import { cartList, removeFromCart, getAddressesList, checkout, savePaymentDetails, paypalReturn, paypalCancel, applyCoupon, removeCoupon,razorpaycheckout } from "./service";

/**
 * Action types
 */
export const actionTypes = {
  CART_LIST_REQUEST_SUCCESS: "CART_LIST_REQUEST_SUCCESS",
  REMOVE_FROM_REQUEST_SUCCESS: "REMOVE_FROM_REQUEST_SUCCESS",
  APPLY_COUPON_REQUEST_SUCCESS: "APPLY_COUPON_REQUEST_SUCCESS",
  REMOVE_COUPON_REQUEST_SUCCESS: "REMOVE_COUPON_REQUEST_SUCCESS",
  ADDRESSES_LIST_REQUEST_SUCCESS: "ADDRESSES_LIST_REQUEST_SUCCESS",
  CHECKOUT_REQUEST_SUCCESS: "CHECKOUT_REQUEST_SUCCESS",
  PAYPAL_RETURN_REQUEST_SUCCESS: "PAYPAL_RETURN_REQUEST_SUCCESS",
  PAYPAL_CANCEL_REQUEST_SUCCESS: "PAYPAL_CANCEL_REQUEST_SUCCESS",
  SAVE_PAYMENT_DETAILS_REQUEST_SUCCESS: "SAVE_PAYMENT_DETAILS_REQUEST_SUCCESS",
};

export const receivedCartListAction = (payload) => ({
  type: actionTypes.CART_LIST_REQUEST_SUCCESS,
  payload,
});

export const receivedRemoveFromCartAction = (payload) => ({
  type: actionTypes.REMOVE_FROM_REQUEST_SUCCESS,
  payload,
});

export const receivedAddressListAction = (payload) => ({
  type: actionTypes.ADDRESSES_LIST_REQUEST_SUCCESS,
  payload,
});

export const receivedCheckoutAction = (payload) => ({
  type: actionTypes.CHECKOUT_REQUEST_SUCCESS,
  payload,
});

export const receivedSavePaymentDetailsAction = (payload) => ({
  type: actionTypes.SAVE_PAYMENT_DETAILS_REQUEST_SUCCESS,
  payload,
});

export const receivedApplyCouponAction = (payload) => ({
  type: actionTypes.APPLY_COUPON_REQUEST_SUCCESS,
  payload,
});

export const receivedRemoveCouponAction = (payload) => ({
  type: actionTypes.REMOVE_COUPON_REQUEST_SUCCESS,
  payload,
});

export const cartListAction = () => {
  return (dispatch) => {
    dispatch(showLoaderAction("cartList"));

    return cartList()
      .then((response) => {
        dispatch(stopLoaderAction("cartList"));
        dispatch(receivedCartListAction(response));
        return response;
      })
      .catch((error) => {
        dispatch(stopLoaderAction("cartList"));
        alert(error.message);
      });
  };
};

export const removeFromCartAction = (data) => {
  return (dispatch) => {
    dispatch(showLoaderAction("removeFromCart"));

    return removeFromCart(data)
      .then((response) => {
        dispatch(stopLoaderAction("removeFromCart"));
        dispatch(receivedRemoveFromCartAction(response));
        return response;
      })
      .catch((error) => {
        dispatch(stopLoaderAction("removeFromCart"));
        alert(error.message);
      });
  };
};

export const addressListAction = () => {
  return (dispatch) => {
    dispatch(showLoaderAction("addressList"));

    return getAddressesList()
      .then((response) => {
        dispatch(stopLoaderAction("addressList"));
        dispatch(receivedAddressListAction(response));
        return response;
      })
      .catch((error) => {
        dispatch(stopLoaderAction("addressList"));
        alert(error.message);
      });
  };
};

export const checkoutAction = (data) => {
  return (dispatch) => {
    dispatch(showLoaderAction("checkout"));

    return checkout(data)
      .then((response) => {
        dispatch(stopLoaderAction("checkout"));
        dispatch(receivedCheckoutAction(response));
        return response;
      })
      .catch((error) => {
        dispatch(stopLoaderAction("checkout"));
        alert(error.message);
      });
  };
};
export const razorpaycheckoutAction = (data) => {
  return (dispatch) => {
    dispatch(showLoaderAction("checkout"));

    return razorpaycheckout(data)
      .then((response) => {
        dispatch(stopLoaderAction("checkout"));
        dispatch(receivedCheckoutAction(response));
        return response;
      })
      .catch((error) => {
        dispatch(stopLoaderAction("checkout"));
        alert(error.message);
      });
  };
};

export const savePaymentDetailsAction = (data) => {
  return (dispatch) => {
    dispatch(showLoaderAction("savePaymentDetails"));

    return savePaymentDetails(data)
      .then((response) => {
        dispatch(stopLoaderAction("savePaymentDetails"));
        dispatch(receivedSavePaymentDetailsAction(response));
        return response;
      })
      .catch((error) => {
        dispatch(stopLoaderAction("savePaymentDetails"));
        alert(error.message);
      });
  };
};

export const receivedPaypalReturnAction = (payload) => ({
  type: actionTypes.PAYPAL_RETURN_REQUEST_SUCCESS,
  payload,
});

export const paypalReturnAction = (data) => {
  return (dispatch) => {
    dispatch(showLoaderAction("paypalReturn"));

    return paypalReturn(data)
      .then((response) => {
        dispatch(stopLoaderAction("paypalReturn"));
        dispatch(receivedPaypalReturnAction(response));
        return response;
      })
      .catch((error) => {
        dispatch(stopLoaderAction("paypalReturn"));
        alert(error.message);
      });
  };
};

export const receivedPaypalCancelAction = (payload) => ({
  type: actionTypes.PAYPAL_CANCEL_REQUEST_SUCCESS,
  payload,
});

export const paypalCancelAction = (data) => {
  return (dispatch) => {
    dispatch(showLoaderAction("paypalCancel"));

    return paypalCancel(data)
      .then((response) => {
        dispatch(stopLoaderAction("paypalCancel"));
        dispatch(receivedPaypalCancelAction(response));
        return response;
      })
      .catch((error) => {
        dispatch(stopLoaderAction("paypalCancel"));
        alert(error.message);
      });
  };
};

export const applyCouponAction = (data) => {
  return (dispatch) => {
    dispatch(showLoaderAction("applyCoupon"));

    return applyCoupon(data)
      .then((response) => {
        dispatch(stopLoaderAction("applyCoupon"));
        dispatch(receivedApplyCouponAction(response));
        return response;
      })
      .catch((error) => {
        dispatch(stopLoaderAction("applyCoupon"));
        alert(error.message);
      });
  };
};

export const removeCouponAction = () => {
  return (dispatch) => {
    dispatch(showLoaderAction("removeCoupon"));

    return removeCoupon()
      .then((response) => {
        dispatch(stopLoaderAction("removeCoupon"));
        dispatch(receivedRemoveCouponAction(response));
        return response;
      })
      .catch((error) => {
        dispatch(stopLoaderAction("removeCoupon"));
        alert(error.message);
      });
  };
};
