import React, { useState } from "react";
import { Col, Dropdown, Row } from "react-bootstrap";
import IntlTelInput from "react-intl-tel-input";

import { messages } from "../../../shared/constants.js";
import "react-intl-tel-input/dist/main.css";

function PhoneFactor(props) {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [countryCode, setCountryCode] = useState("+91");
  const handlePhoneChange = (status, number, countryObj, finalValue) => {
    setCountryCode("+" + countryObj.dialCode);
    setPhoneNumber(number);
    onFieldChange("mobile2", number);
  };
  const [loginFormData, setLoginFormData] = useState({
    country_code: countryCode,
  });

  const handleCountryChange = (number, obj, finalValue, status) => {
    setCountryCode("+" + obj.dialCode);
    setPhoneNumber(number);
    onFieldChange("country_code", "+" + obj.dialCode);
  };

  const onFieldChange = (name, value) => {
    setLoginFormData({
      ...loginFormData,
      [name]: value,
    });

    resetError(name);
  };

  const onLoginNextClick = (e) => {
    e.preventDefault();
    if (isValidLoginFormData()) {
      const inputData = {
        mobile: loginFormData.mobile2,
        country_code: loginFormData.country_code,
      };
      props.setMobile(loginFormData.mobile2);
      props.setCountry(loginFormData.country_code);
      props.partnerLogin(inputData).then((response) => {
        if (response) props.setStep(1);
      });
    }
  };

  const isValidLoginFormData = () => {
    let isValid = true;

    if (!loginFormData || !loginFormData.mobile2) {
      setError("mobile2", messages.requiredMobileField);
      isValid = false;
    } else if (isNaN(loginFormData.mobile2)) {
      setError("mobile2", messages.numberField);
      isValid = false;
    } else if (loginFormData.mobile2.length !== 10 && countryCode === "+91") {
      setError("mobile2", messages.tenDigitsField);
      isValid = false;
    } else {
      resetError("mobile2");
    }

    return isValid;
  };

  const setError = (input, message) => {
    let formControl;
    if (input === "mobile2") {
      formControl = document.getElementsByName(input)[0].parentElement.parentElement;
      const errorMsg = formControl.querySelector(".login_input-msg");
      formControl.classList.add("error");
      formControl.classList.add("text-left");
      errorMsg.innerText = message;
    }
  };

  const resetError = (input) => {
    let formControl;
    if (input === "mobile2") {
      formControl = document.getElementsByName(input)[0].parentElement.parentElement;
      const errorMsg = formControl.querySelector(".login_input-msg");
      formControl.classList.remove("error");
      formControl.classList.remove("text-left");
      errorMsg.innerText = "";
    }
  };

  return (
    <>
      <div className="side-content">
        <h5>Let's get started</h5>
        <p>Only Alyss Registered Partners shall login from here</p>
      </div>
      <div className="side-post">
        {/* Login Area */}
        <section className="login-area">
          <Row>
            <Col md="12">
              <div className="login-box">
                <form id="form_login" className="form" onSubmit={onLoginNextClick}>
                  <div className="form-control">
                    <Row>
                      <Col md="12">
                        <IntlTelInput
                          fieldName="mobile2"
                          containerClassName="intl-tel-input"
                          inputClassName="phone-number"
                          preferredCountries={["in", "us", "ae"]}
                          onPhoneNumberBlur={handlePhoneChange}
                          onPhoneNumberChange={handlePhoneChange}
                          onSelectFlag={handleCountryChange}
                          separateDialCode
                        />
                        <span className="login_input-msg"></span>
                      </Col>
                    </Row>
                  </div>
                  <button type="button" onClick={onLoginNextClick}>
                    Next
                  </button>
                </form>
              </div>
            </Col>
          </Row>
        </section>
      </div>
    </>
  );
}

export default PhoneFactor;
