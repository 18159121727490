import styled from "styled-components";
import { colors, fonts } from "../../components/common/element/elements.js";

export const Styles = styled.div`
  .bg-gray {
    background-color: ${colors.border1};
    font-family: ${fonts.manrope};
  }
  .MuiTab-root {
    font-size: 26px;
    text-transform: capitalize;
    border: 3px solid #555;
    margin: 0 5px;
    padding: 5px 50px !important;
    color: #555;
  }
  .Mui-selected {
    color: white !important;
    background: #ffc801;
    border: 3px solid #ffc801;
    font-size: 26px;
    font-weight: 400;
    text-transform: capitalize;
    padding: 10px 50px;
  }
  .MuiTabs-indicator {
    display: none;
  }
  .offering-title {
    color: #0fa9a9;
    font-size: 44px;
    font-weight: 600;
    padding-bottom: 12px;

    &::after {
      content: "";
      border-bottom: 3px solid;
      width: 250px;
      position: absolute;
      top: 65px;
      text-align: center;
      margin-left: -300px;
    }
  }
  .offering-subtitle {
    color: #0fa9a9;
    font-size: 26px;
    text-align: center;
    margin: 15px 0 30px 0;
  }
  .subject-section {
    border-top: 8px solid white;
  }
  .subject-title {
    color: #0fa9a9;
    padding-top: 20px;
    font-size: 36px;
    font-weight: 600;
    padding-bottom: 10px;
  }
  .subject-subtitle {
    text-align: center;
    font-size: 23px;
    color: #0fa9a9;
    margin-bottom: 20px;
  }
  .feature-box {
    background: white;
    margin: 40px 0 30px 0;
    color: #0fa9a9;
    padding: 5px;
    border-bottom: 8px solid;
    min-height: 320px;

    .feature-image {
      text-align: center;
      img {
        margin-top: -40px;
        margin-bottom: 15px;
        height: 70px;
      }
    }

    .feature-title {
      font-size: 22px;
      font-weight: 600;
      text-align: center;
      margin-bottom: 10px;
    }
    .features-list {
      padding-left: 25px;
      li {
        font-size: 18px;
      }
    }
  }
`;
