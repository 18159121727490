import { doGetRequest } from "../../shared/api/base-api";
import { apiUrl } from "../../shared/constants";
import common from "../../shared/common";

//Live Classes data api
export function getLiveClassesData(data) {
  return doGetRequest(common.stringformat(apiUrl.liveClasses, data));
}

//Live Classes validation data api
export function getValidatedLiveClassData(data) {
  return doGetRequest(common.stringformat(apiUrl.liveClassCheck, data));
}

//Live Classes pariticipation data api
export function confirmParticipationLiveClassData(data) {
  return doGetRequest(common.stringformat(apiUrl.liveClassParticipation, data));
}
