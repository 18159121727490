import React, { useEffect, useRef, useState } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Container, Row, Col } from "react-bootstrap";
import FooterTwo from "../../components/FooterTwo";
import { Styles } from "./styles/dashboard.js";
import DashboardMenu, { DashboardMenuDrawer, DashboardMenuToggleButton } from "./components/DashboardMenu";
import { dashboardDataAction, getPartnerDashboardDataAction, setDashboardFormDataAction } from "./action";
import { handleReloadAppAction } from "../user/action";
import LoaderNew from "../../components/LoaderNew";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import { Chart as ChartJS, ArcElement, Tooltip, Legend, CategoryScale, LinearScale, PointElement, LineElement, Title } from "chart.js";
import { Doughnut, Line } from "react-chartjs-2";
import PartnerHeader from "../../components/PartnerHeader";

const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
    },
    title: {
      display: false,
      text: "",
    },
  },
};

ChartJS.register(ArcElement, Tooltip, Legend, CategoryScale, LinearScale, PointElement, LineElement, Title);

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const PartnerDashboard = (props) => {
  useEffect(() => {
    props.getPartnerDashboard([]);
  }, []);

  return (
    <div className="main-wrapper course-page">
      {/* User Header */}
      <PartnerHeader />

      <Styles>
        {/* Course Grid */}
        <section className="dashboard-grid-area">
          <Container>
            <Row>
              <Col lg="3" md="3" sm="12">
                <DashboardMenu {...props} />
              </Col>
              <Col>
                {/* {props.showLoader && props.showLoader.length > 0 && <LoaderNew />} */}
                {props.showLoader.length === 0 && (
                  <>
                    <Row>
                      {/* <DashboardMenuToggleButton  {...props} /> */}
                      <Col lg="12" md="12" sm="12">
                        {/* <DashboardFilter {...props} /> */}
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="12" md="12" sm="12">
                        <div className="course-items">
                          {/* <CourseItemGrid
                            data={props.learnings}
                            loader={props.showLoader}
                            headerText="My Learnings"
                            notFoundText="Buy now from recommended section."
                          /> */}
                          <Typography variant="h3" component="div" gutterBottom>
                            Dashboard
                          </Typography>
                          <Box sx={{ flexGrow: 1 }}>
                            <Grid container spacing={2}>
                              <Grid item md={4} sm={12}>
                                <Item>{props.dashboard && props.dashboard.dougnut_data && <Doughnut data={props.dashboard.dougnut_data} />}</Item>
                              </Grid>
                              <Grid item md={8} sm={12}>
                                <Item>
                                  {props.dashboard && props.dashboard.line_chart_data && (
                                    <Line options={options} data={props.dashboard.line_chart_data} />
                                  )}
                                </Item>
                              </Grid>
                              <Grid item xs={6} sm={6} md={4}>
                                <Item
                                  sx={{
                                    backgroundImage: "linear-gradient(90deg, #0b2b4e 0%, #274865 100%)",
                                    boxShadow: "0 12px 25px rgb(0 0 0 / 20%)",
                                    color: "#fff",
                                  }}
                                >
                                  <CardContent>
                                    <Typography variant="h5" component="div" gutterBottom>
                                      Students
                                    </Typography>
                                    <Typography variant="h5" component="div">
                                      {props.dashboard ? props.dashboard.students_count : 0}
                                    </Typography>
                                  </CardContent>
                                </Item>
                              </Grid>
                              <Grid item xs={6} sm={6} md={4}>
                                <Item
                                  sx={{
                                    backgroundImage: "linear-gradient(90deg, #0b2b4e 0%, #274865 100%)",
                                    boxShadow: "0 12px 25px rgb(0 0 0 / 20%)",
                                    color: "#fff",
                                  }}
                                >
                                  <CardContent>
                                    <Typography variant="h5" component="div" gutterBottom>
                                      Teachers
                                    </Typography>
                                    <Typography variant="h5" component="div">
                                      {props.dashboard ? props.dashboard.teachers_count : 0}
                                    </Typography>
                                  </CardContent>
                                </Item>
                              </Grid>
                              <Grid item xs={6} sm={6} md={4}>
                                <Item
                                  sx={{
                                    backgroundImage: "linear-gradient(90deg, #0b2b4e 0%, #274865 100%)",
                                    boxShadow: "0 12px 25px rgb(0 0 0 / 20%)",
                                    color: "#fff",
                                  }}
                                >
                                  <CardContent>
                                    <Typography variant="h5" component="div" gutterBottom>
                                      Schools
                                    </Typography>
                                    <Typography variant="h5" component="div">
                                      {props.dashboard ? props.dashboard.schools_count : 0}
                                    </Typography>
                                  </CardContent>
                                </Item>
                              </Grid>
                              <Grid item xs={6} sm={6} md={6}>
                                <Item
                                  sx={{
                                    backgroundImage: "linear-gradient(90deg, #0b2b4e 0%, #274865 100%)",
                                    boxShadow: "0 12px 25px rgb(0 0 0 / 20%)",
                                    color: "#fff",
                                  }}
                                >
                                  <CardContent>
                                    <Typography variant="h5" component="div" gutterBottom>
                                      Wallet balance
                                    </Typography>
                                    <Typography variant="h5" component="div">
                                      {props.dashboard ? props.dashboard.wallet_balance : "INR 0"}
                                    </Typography>
                                  </CardContent>
                                </Item>
                              </Grid>
                              {/* <Grid item xs={6} sm={6} md={4}>
                                <Item
                                  sx={{
                                    backgroundImage: "linear-gradient(90deg, #0b2b4e 0%, #274865 100%)",
                                    boxShadow: "0 12px 25px rgb(0 0 0 / 20%)",
                                    color: "#fff",
                                  }}
                                >
                                  <CardContent>
                                    <Typography variant="h5" component="div" gutterBottom>
                                      Commission balance
                                    </Typography>
                                    <Typography variant="h5" component="div">
                                      {props.dashboard ? props.dashboard.commission : "INR 0"}
                                    </Typography>
                                  </CardContent>
                                </Item>
                              </Grid> */}
                              <Grid item xs={6} sm={6} md={6}>
                                <Item
                                  sx={{
                                    backgroundImage: "linear-gradient(90deg, #0b2b4e 0%, #274865 100%)",
                                    boxShadow: "0 12px 25px rgb(0 0 0 / 20%)",
                                    color: "#fff",
                                  }}
                                >
                                  <CardContent>
                                    <Typography variant="h5" component="div" gutterBottom>
                                      Last Payout
                                    </Typography>
                                    <Typography variant="h5" component="div">
                                      {props.dashboard ? props.dashboard.last_payout : "INR 0"}
                                    </Typography>
                                  </CardContent>
                                </Item>
                              </Grid>
                            </Grid>
                          </Box>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="12" md="12" sm="12">
                        <div className="course-items">
                          {/* <CourseItemGrid
                            data={props.recommended}
                            loader={props.showLoader}
                            canBuy={true}
                            headerText="Recommended"
                            notFoundText="No data found."
                          /> */}
                        </div>
                      </Col>
                    </Row>
                  </>
                )}
              </Col>
            </Row>
          </Container>
        </section>
      </Styles>

      {/* Footer 2 */}
      <FooterTwo />
    </div>
  );
};

const mapStateToProps = (state) => ({
  dashboard: state.partnerDashboard.data.dashboard,
  showLoader: state.user.showLoader,
});

const mapDispatchToProps = (dispatch) => ({
  getPartnerDashboard: () => dispatch(getPartnerDashboardDataAction()),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PartnerDashboard));
