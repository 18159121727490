import { showLoaderAction, stopLoaderAction } from "../user/action";
import { getGeneratedTestsListData } from "./service";

/**
 * Action types
 */
export const actionTypes = {
  GENERATED_TESTS_LIST_REQUEST_SUCCESS: "GENERATED_TESTS_LIST_REQUEST_SUCCESS",
};

export const receivedGeneratedTestsListAction = (payload) => ({
  type: actionTypes.GENERATED_TESTS_LIST_REQUEST_SUCCESS,
  payload,
});

export const generatedTestsListDataAction = (data) => {

  return (dispatch) => {

    dispatch(showLoaderAction("getGeneratedTestsListData"));

    return getGeneratedTestsListData(data)
      .then((response) => {
        dispatch(stopLoaderAction("getGeneratedTestsListData"));

        if (response) dispatch(receivedGeneratedTestsListAction(response));
      })
      .catch((error) => {
        dispatch(stopLoaderAction("getGeneratedTestsListData"));
      });
  };
};
