import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Styles } from "./styles/headerCampaign";
import { webUrl } from "../shared/constants";
import { Autocomplete, Box, Button, Grid, TextField } from "@mui/material";
import IntlTelInput from "react-intl-tel-input";
import "react-intl-tel-input/dist/main.css";

const HeaderCampaign = (props) => {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [countryCode, setCountryCode] = useState("+91");
  const [mobileError, setMobileError] = useState("");
  const [errorDisplay, setErrorDisplay] = useState("none");
  const [errorClass, setErrorClass] = useState("");
  const [name, setName] = useState("");
  const [nameError, setNameError] = useState("");
  const [otp, setOTP] = useState("");
  const [otpError, setOTPError] = useState("");
  const [selectedCity, setSelectedCity] = useState(null);
  const [selectedCityInput, setSelectedCityInput] = useState("");

  useEffect(() => {
    props.getMasterData({ modules: ["cities"] });
  }, []);

  const handleForm = (key = "", value = "") => {
    const phoneNumberValue = key === "phoneNumber" ? value : phoneNumber;
    const nameValue = key === "name" ? value : name;
    let error = false;
    if (nameValue === "") {
      setNameError("please enter name");
      error = true;
    } else {
      setNameError("");
    }
    if (countryCode === "" || phoneNumberValue === "") {
      setMobileError("please enter mobile number");
      setErrorClass("error");
      setErrorDisplay("block");
      error = true;
    } else if (phoneNumberValue.length !== 10) {
      setMobileError("please enter 10 digits mobile number");
      setErrorClass("error");
      setErrorDisplay("block");
      error = true;
    } else {
      setMobileError("");
      setErrorClass("");
      setErrorDisplay("none");
    }
    return error;
  };
  const handleFormOTP = (key = "", value = "") => {
    const otpValue = key === "otp" ? value : otp;
    let error = false;
    if (otpValue === "") {
      setOTPError("please enter OTP");
      error = true;
    } else if (otpValue.length !== 6) {
      setOTPError("please enter 6 digit OTP");
      error = true;
    } else {
      setOTPError("");
    }
    return error;
  };

  const handleNameChange = (e) => {
    setName(e.target.value);
    handleForm("name", e.target.value);
  };

  const handleOTPChange = (e) => {
    if (!isNaN(e.target.value)) {
      setOTP(e.target.value);
      handleFormOTP("otp", e.target.value);
    }
  };

  const handlePhoneChange = (status, number, countryObj, finalValue) => {
    setCountryCode("+" + countryObj.dialCode);
    const re = /^[0-9\b]+$/;
    if (number !== "" && re.test(number) && number.length <= 10) {
      setPhoneNumber(number);
      handleForm("phoneNumber", number);
    } else if (number === "") {
      setPhoneNumber(number);
      handleForm("phoneNumber", number);
    }
  };

  const handleCountryChange = (number, obj, finalValue, status) => {
    setCountryCode("+" + obj.dialCode);
    setPhoneNumber(number);
    handleForm();
  };

  const resetForm = () => {
    setCountryCode("+91");
    setPhoneNumber("");
    setName("");
    setOTP("");
    setNameError("");
    setOTPError("");
    setMobileError("");
    setErrorDisplay("none");
    setErrorClass("");
    setSelectedCity(null);
    setSelectedCityInput("");
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!handleForm()) {
      const campaign_url = window.location.href;
      const formData = {
        name: name,
        mobile: phoneNumber,
        country_code: countryCode,
        city_id: selectedCity ? selectedCity.id : null,
        campaign_url,
      };
      props
        .sendCampaignOTP(formData)
        .then((response) => {
          resetForm();
        })
        .catch((error) => {});
    }
  };

  const handleOTPSubmit = (e) => {
    e.preventDefault();
    if (!handleFormOTP()) {
      const formData = {
        otp: otp,
        token: props.token,
      };
      props
        .verifyCampaignOTP(formData)
        .then((response) => {
          resetForm();
          props.history.replace(webUrl.teacherCampaignSubmit);
        })
        .catch((error) => {
          setOTP("");
        });
    }
  };
  return (
    <Styles>
      <div className="promo-bg">
        {/* Logo Area 2 */}
        <section className="logo-area2">
          <Container>
            <Row>
              <Col md="2" sm="12">
                <div className="logo">
                  <img src={process.env.PUBLIC_URL + "/assets/images/logo2.webp"} alt="" style={{ maxWidth: "200px" }} />
                </div>
              </Col>
              <Col md="10" sm="12"></Col>
            </Row>
          </Container>
        </section>
        <section className="slider-content">
          <Container>
            <Row className="align-items-center">
              <Col lg="8" md="7" sm="12">
                <img src={process.env.PUBLIC_URL + "/assets/images/banners/Element_3.webp"} alt="" className="img-fluid teacher-banner" />
              </Col>
              <Col lg={{ span: 3, offset: 1 }} md="5" sm="12" style={{ color: "white" }}>
                <div className="my-5">
                  <h5>Let's Connect</h5>
                  <Box
                    component="form"
                    noValidate
                    autoComplete="off"
                    onSubmit={(e) => {
                      e.preventDefault();
                      if (props.token === "") {
                        handleSubmit(e);
                      } else {
                        handleOTPSubmit(e);
                      }
                    }}
                  >
                    <Grid container spacing={1}>
                      {props.token === "" ? (
                        <>
                          <Grid item xs={12} sm={12} md={12}>
                            <TextField
                              label="Name"
                              id="name"
                              className="form-field"
                              fullWidth
                              placeholder="Enter full name"
                              variant="filled"
                              inputProps={{
                                maxLength: 20,
                              }}
                              required
                              onChange={handleNameChange}
                              value={name}
                              error={nameError !== ""}
                              helperText={nameError}
                            />
                          </Grid>
                          <Grid item xs={12} sm={12} md={12}>
                            <div className={`mobile-field ${errorClass}`}>
                              <IntlTelInput
                                fieldName="mobile"
                                containerClassName="intl-tel-input"
                                inputClassName="phone-number"
                                defaultCountry="in"
                                value={phoneNumber}
                                allowDropdown={false}
                                preferredCountries={["in", "us", "ae"]}
                                onPhoneNumberBlur={handlePhoneChange}
                                onPhoneNumberChange={handlePhoneChange}
                                onSelectFlag={handleCountryChange}
                                separateDialCode
                              />
                            </div>
                            <span className="mobile-error" style={{ display: errorDisplay }}>
                              {mobileError}
                            </span>
                          </Grid>
                          <Grid item xs={12} sm={12} md={12}>
                            <Autocomplete
                              id="city"
                              freeSolo
                              className="form-field"
                              options={props.cities}
                              value={selectedCity}
                              groupBy={(option) => option.state.name}
                              onChange={(event, newValue) => {
                                setSelectedCity(newValue ? newValue : null);
                                setSelectedCityInput(newValue ? newValue.name : "");
                              }}
                              inputValue={selectedCityInput}
                              onInputChange={(event, newInputValue) => {
                                setSelectedCityInput(newInputValue);
                              }}
                              clearOnBlur
                              getOptionLabel={(option) => {
                                if (typeof option === "string") {
                                  return "";
                                } else {
                                  return option.name;
                                }
                              }}
                              renderOption={(props, option) => (
                                <Box component="li" {...props} key={option.id}>
                                  {option.name}
                                </Box>
                              )}
                              onKeyDown={(event) => {
                                if (event.key === "Enter") {
                                  event.defaultMuiPrevented = true;
                                }
                              }}
                              renderInput={(params) => <TextField {...params} variant="filled" label="Select City" />}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6} md={12}>
                            <Button onClick={handleSubmit} className="orange-bg" variant="contained" size="large" fullWidth>
                              Join Now
                            </Button>
                          </Grid>
                        </>
                      ) : (
                        <>
                          <Grid item xs={12} sm={12} md={12}>
                            <TextField
                              label="OTP"
                              id="otp"
                              fullWidth
                              placeholder="Enter OTP"
                              className="form-field"
                              variant="filled"
                              required
                              inputProps={{
                                maxLength: 6,
                              }}
                              onChange={handleOTPChange}
                              value={otp}
                              error={otpError !== ""}
                              helperText={otpError}
                            />
                          </Grid>

                          <Grid item xs={12} sm={6} md={12}>
                            <Button onClick={handleOTPSubmit} className="orange-bg" variant="contained" fullWidth size="large">
                              Verify OTP
                            </Button>
                          </Grid>
                        </>
                      )}
                    </Grid>
                  </Box>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </div>
    </Styles>
  );
};

export default HeaderCampaign;
