import { createStore, combineReducers, applyMiddleware, compose } from "redux";
import thunkMiddleware from "redux-thunk";
import userReducer from "../pages/user/reducer";
import partnerReducer from "../pages/partner_login/reducer";
import dashboardReducer from "../pages/dashboard/reducer";
import packageReducer from "../pages/package/reducer";
import subjectReducer from "../pages/subject/reducer";
import chapterReducer from "../pages/chapter/reducer";
import moduleReducer from "../pages/module/reducer";
import cartReducer from "../pages/cart/reducer";
import subscriptionReducer from "../pages/subscriptions/reducer";
import notificationReducer from "../pages/notifications/reducer";
import transactionReducer from "../pages/transactions/reducer";
import liveClassesReducer from "../pages/live_classes/reducer";
import recordedSessionsReducer from "../pages/recorded_sessions/reducer";
import generateNewTestReducer from "../pages/generate_test/reducer";
import generatedTestReducer from "../pages/generated_tests_list/reducer";
import subsubcategoriesReducer from "../pages/teacher_technology_kit/reducer";
import chapterQuizReducer from "../pages/chapter_quiz/reducer";
import chapterQuizReportReducer from "../pages/chapter_quiz_report/reducer";
import moduleQuizReducer from "../pages/module_quiz/reducer";
import moduleQuizReportReducer from "../pages/module_quiz_report/reducer";
import orderDetailsReducer from "../pages/order_details/reducer";
import olympiadQuizReducer from "../pages/olympiad/reducer";
import partnerDashboardReducer from "../pages/partner_dashboard/reducer";
import teacherCampaignReducer from "../pages/teacher_campaign/reducer";
import footerReducer from "../components/reducers/footer";
import contactReducer from "../pages/contact/reducer";
import embedReducer from "../pages/embed/reducer";
import profileReducer from "../pages/profile/reducer";
import commonReducer from "../components/reducers/common";
import examCoursePlansReducer from "../pages/exam_preparatory/reducer";
import usCoursesListReducer from "../pages/ushomepage/reducer";
import usCoursesDetailReducer from "../pages/ushomepage/reducer";
/**
 * Root reducer
 * - Combine all reducers used in application
 */
const rootReducer = combineReducers({
  user: userReducer,
  dashboard: dashboardReducer,
  package: packageReducer,
  subject: subjectReducer,
  cart: cartReducer,
  chapter: chapterReducer,
  module: moduleReducer,
  subscription: subscriptionReducer,
  notification: notificationReducer,
  transaction: transactionReducer,
  liveClass: liveClassesReducer,
  recordedSession: recordedSessionsReducer,
  generateNewTest: generateNewTestReducer,
  generatedTest: generatedTestReducer,
  subsubcategories: subsubcategoriesReducer,
  chapter_quiz: chapterQuizReducer,
  chapterQuizReport: chapterQuizReportReducer,
  module_quiz: moduleQuizReducer,
  moduleQuizReport: moduleQuizReportReducer,
  order: orderDetailsReducer,
  olympiad: olympiadQuizReducer,
  partner: partnerReducer,
  partnerDashboard: partnerDashboardReducer,
  footer: footerReducer,
  teacherCampaign: teacherCampaignReducer,
  contact: contactReducer,
  embed: embedReducer,
  profile: profileReducer,
  common: commonReducer,
  examCoursePlans: examCoursePlansReducer,
  usCoursesList:usCoursesListReducer,
  usCoursesDetail:usCoursesDetailReducer,
});

/**
 * Global declaration for devtools
 */
declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}
/**
 * Compose Enhancers
 */
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

/**
 * Store
 * - Create store with root reducer and middleware
 */
const store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunkMiddleware)));

export default store;
