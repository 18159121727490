import React, { Component } from "react";
import Datas from "../data/about-us/about-us.json";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import { Styles } from "./styles/aboutUs.js";
import { webUrl } from "../shared/constants";

class AboutUs extends Component {
  constructor() {
    super();
    this.state = {
      isOpen: false,
    };
    this.openModal = this.openModal.bind(this);
  }

  openModal() {
    this.setState({ isOpen: true });
  }

  render() {
    return (
      <Styles>
        {/* About Us */}
        <section className="about-us">
          <Container>
            <Row>
              <Col md="6">
                <div className="about-image">
                  <img
                    src={process.env.PUBLIC_URL + `/assets/images/about/student-graduation-cap-using-computer-desk.webp`}
                    className="main-img"
                    alt=""
                    style={{ margin: 0 }}
                  />
                </div>
              </Col>
              <Col md="6">
                <div className="about-content">
                  <h4 className="about-title" dangerouslySetInnerHTML={{ __html: Datas[0].title }}></h4>
                  <p className="about-para">
                    {Datas[0].desc1}
                    <span>{Datas[0].desc2}</span>
                  </p>
                  <Link className="readmore-btn orange-bg about1" to={process.env.PUBLIC_URL + webUrl.studyMaterial}>
                    Know More
                  </Link>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        <section className="about-us">
          <Container>
            <Row className="about-bottom">
              <Col md="6">
                <div className="about-content">
                  <h4 className="about-title" dangerouslySetInnerHTML={{ __html: Datas[1].title }}></h4>
                  <p className="about-para" dangerouslySetInnerHTML={{ __html: `${Datas[1].desc1} ${Datas[1].desc2}` }}></p>
                  <Link className="readmore-btn orange-bg" to={process.env.PUBLIC_URL + webUrl.robotic}>
                    Know More
                  </Link>
                </div>
              </Col>
              <Col md="6">
                <div className="about-image">
                  <img
                    src={process.env.PUBLIC_URL + `/assets/images/about/stem-education-logo-with-children.png`}
                    className="main-img"
                    alt=""
                    style={{ marginTop: "50px" }}
                  />
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        <section className="about-us">
          <Container>
            <Row>
              <Col md="6">
                <div className="about-image">
                  <img src={process.env.PUBLIC_URL + `/assets/images/about/Teacher_about.webp`} className="main-img" alt="" style={{ margin: 0 }} />
                </div>
              </Col>
              <Col md="6">
                <div className="about-content">
                  <h4 className="about-title" dangerouslySetInnerHTML={{ __html: Datas[2].title }}></h4>
                  <p className="about-para" dangerouslySetInnerHTML={{ __html: Datas[2].desc1 }} />
                  <Link className="readmore-btn orange-bg about1" to={process.env.PUBLIC_URL + webUrl.teacher}>
                    Know More
                  </Link>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </Styles>
    );
  }
}

export default AboutUs;
