import React, { Fragment } from "react";
import Datas from "../../../data/course/item.json";
import { Link } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import { webUrl } from "../../../shared/constants";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import common from "../../../shared/common";

const ItemGrid = (props) => {
  return (
    <Fragment>
      {/* Course Item */}
      <Row>
        <Col>
          <h5 className="grid-header ml-0">My Subscriptions</h5>
        </Col>
      </Row>

      <Row>
        {(props.data &&
          props.data.length > 0 &&
          props.data.map((data, i) => (
            <Col lg="4" md="6" key={i}>
              <div className="course-item">
                <Link to={webUrl.subjectDetails + "/" + data.subsubcategory.id}>
                  <div
                    className="course-image"
                    style={{
                      backgroundImage: `url(${
                        data.subsubcategory.subsubcategory_image_url || process.env.PUBLIC_URL + "/assets/images/" + Datas[0].imgUrl
                      })`,
                    }}
                  >
                    {data.is_valid === 0 && (
                      <div className="course-price">
                        <Link to={process.env.PUBLIC_URL + "package/" + data.subsubcategory.id}>
                          <p>Renew Now</p>
                        </Link>
                      </div>
                    )}
                  </div>
                </Link>
                <div className="course-content">
                  <h6 className="heading">
                    <Link to={webUrl.subjectDetails + "/" + data.subsubcategory.id}>{data.subsubcategory.name}</Link>
                  </h6>
                  <p className="desc">
                    {data.subsubcategory.subcategory.name} - {data.subsubcategory.subcategory.category.name}
                  </p>
                  <p>Valid Upto: {common.getISTFormattedDate(data.valid_upto)}</p>
                  <p>
                    Subscription Price: {data.currency === "INR" ? <CurrencyRupeeIcon /> : <AttachMoneyIcon />} {data.discounted_price}
                  </p>
                  <p>Subscription: {data.duration_text}</p>
                </div>
              </div>
            </Col>
          ))) || (
          <Col lg="4" md="6">
            <p className="not-found">No Subscriptions found</p>
          </Col>
        )}
      </Row>
    </Fragment>
  );
};

export default ItemGrid;
