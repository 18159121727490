import React from "react";
import PartnerLogin from "./components/PartnerLogin";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { profileMasterDataAction, updateProfileAction, partnerLoginAction, verifyOTPAction } from "./action";

const Partner = (props) => {
  return <PartnerLogin {...props} redirectTo={props.history.push} />;
};

const mapStateToProps = (state) => ({
  partner: state.partner,
  masterData: state.partner.data.masterData.data || {},
});

const mapDispatchToProps = (dispatch) => ({
  partnerLogin: (partnerData) => dispatch(partnerLoginAction(partnerData)),
  verifyOTP: (otpData) => dispatch(verifyOTPAction(otpData)),
  updateProfile: (profileData) => dispatch(updateProfileAction(profileData)),
  profileMasterData: (data) => dispatch(profileMasterDataAction(data)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Partner));
