import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import common from "../../../shared/common.js";
import { Styles } from "../styles/concept.js";
import VideoTextAttachment from "./VideoTextAttachment";

function Mission(props) {
  const userProfile = JSON.parse(common.getFromStorage("profile"));
  const member_type = userProfile ? userProfile.member_type : 1;
  const missionsData = props.module.missions.filter((mission) => mission.member_type === +member_type);
  return (
    <Styles>
      {/* Faq & Event */}
      <section className="event-faq-area">
        <Row>
          <Col md="12">
            <div className="course-desc">
              <div className="faq-area">
                <div className="faq-box">
                  <div className="faq-item">
                    <div className="accordion-content show">
                      <div className="course-items">
                        <VideoTextAttachment data={missionsData} headerText="Missions" canUserAccess={props.module.can_user_access} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </section>
    </Styles>
  );
}

export default Mission;
