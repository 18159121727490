import React, { Component } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { webUrl } from "../shared/constants.js";
import { Styles } from "./styles/iconBox.js";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import PrecisionManufacturingIcon from "@mui/icons-material/PrecisionManufacturing";
import LiveTvIcon from "@mui/icons-material/LiveTv";

class IconBox extends Component {
  render() {
    return (
      <Styles>
        {/* Icon Box */}
        <section className="icon-box-area">
          <Container>
            <Row>
              <Col md="4">
                <div
                  className="full-icon-box"
                  onClick={() => {
                    this.props.history.push(webUrl.studyMaterial);
                  }}
                >
                  <div className="icon-box d-flex">
                    <div className="box-icon box1">
                      <MenuBookIcon />
                    </div>
                    <div className="box-title">
                      <h6>Study Material</h6>
                      <p>Absorb. Apply. Assess.</p>
                    </div>
                  </div>
                </div>
              </Col>
              <Col md="4">
                <div
                  className="full-icon-box"
                  onClick={() => {
                    this.props.history.push(webUrl.robotic);
                  }}
                >
                  <div className="icon-box d-flex">
                    <div className="box-icon box2">
                      <PrecisionManufacturingIcon />
                    </div>
                    <div className="box-title">
                      <h6>Robotics</h6>
                      <p>Absorb. Program. Challenge.</p>
                    </div>
                  </div>
                </div>
              </Col>
              <Col md="4">
                <div
                  className="full-icon-box"
                  onClick={() => {
                    this.props.history.push(webUrl.teacher);
                  }}
                >
                  <div className="icon-box d-flex">
                    <div className="box-icon box3">
                      <LiveTvIcon />
                    </div>
                    <div className="box-title">
                      <h6>Teacher Solutions</h6>
                      <p>Learn. Ask. Solve.</p>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </Styles>
    );
  }
}

export default IconBox;
