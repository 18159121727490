import React, { useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Styles } from "./styles/stickyMenu.js";
import { Link } from "@mui/material";

function SampleStickyMenu() {
  useEffect(() => {
    window.addEventListener("scroll", () => {
      const stickyMenu = document.querySelector(".sticky-menu");
      if (stickyMenu) {
        if (window.scrollY > 160) {
          stickyMenu.classList.add("sticky");
        } else {
          stickyMenu.classList.remove("sticky");
        }
      }
    });
  });

  return (
    <Styles>
      {/* Sticky Menu */}
      <section className="sticky-menu">
        <Container>
          <Row>
            <Col md="2">
              <div className="logo">
                <Link href="#" underline="none">
                  <img src={process.env.PUBLIC_URL + "/assets/images/logo2.webp"} alt="" />
                </Link>
              </div>
            </Col>
            <Col md="10">
              <div className="menu-box d-flex justify-content-end">
                <ul className="nav menu-nav">
                  <li className="nav-item dropdown active">
                    <Link underline="none" className="nav-link dropdown-toggle" href="#" data-toggle="dropdown">
                      Study Material
                    </Link>
                  </li>
                  <li className="nav-item dropdown">
                    <Link underline="none" className="nav-link dropdown-toggle" href="#" data-toggle="dropdown">
                      Robotics
                    </Link>
                  </li>
                  {/* <li className="nav-item dropdown">
                                        <Link underline="none" className="nav-link dropdown-toggle" href="#" data-toggle="dropdown">Live Classes</Link>
                                    </li> */}
                  <li className="nav-item dropdown">
                    <Link underline="none" className="nav-link dropdown-toggle" href="#" data-toggle="dropdown">
                      Teacher Solutions
                    </Link>
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </Styles>
  );
}

export default SampleStickyMenu;
