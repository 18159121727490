import React, { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import FooterTwo from "../../components/FooterTwo";
import UserHeader from "../../components/UserHeader";
import { Container, Row, Col } from "react-bootstrap";
import { Styles } from "../dashboard/styles/dashboard.js";
import { Styles2 } from "./styles/chapter.js";
import { chapterDetailsAction } from "./action";
import ChapterMenu from "./components/ChapterMenu";
import Tests from "./components/Tests";
import SubjectVideos from "./components/SubjectVideos";
import Theory from "./components/Theory";
import NcertBook from "./components/NcertBook";
import { Box, Breadcrumbs, Drawer, IconButton, Link, Typography } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import PracticeTest from "./components/PracticeTest";
import NcertExercise from "./components/NcertExercise";
import LoaderNew from "../../components/LoaderNew";
import { webUrl } from "../../shared/constants";

const drawerWidth = 240;

function Chapter(props) {
  const {
    match: { params },
    window,
  } = props;

  const [selectedPage, setSelectedPage] = useState(0);
  const [mobileOpen, setMobileOpen] = useState(false);
  const isMobile = useMediaQuery({ query: `(max-width: 600px)` });

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const changeMenuHandler = (value) => {
    setSelectedPage(value);
    if (isMobile) {
      handleDrawerToggle();
    }
  };

  useEffect(() => {
    props.chapterDetails([params.type || 1]); // To do: params.type should be default one
  }, [params.type]);

  const drawer = (
    <div>
      <ChapterMenu {...props} onChange={changeMenuHandler} />
    </div>
  );

  const container = window !== undefined ? () => window().document.body : undefined;
  if (props.showLoader && props.showLoader.length > 0) {
    return <LoaderNew />;
  }

  return (
    <div className="main-wrapper course-details-page">
      {/* Header */}
      <UserHeader />
      <Styles2>
        <Styles>
          {/* Course Grid */}
          <section className="dashboard-grid-area">
            <Container>
              <Row>
                <Col lg="3" md="3" sm="12">
                  <Box component="nav" sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }} aria-label="mailbox folders">
                    {isMobile === true ? (
                      <Drawer
                        container={container}
                        variant="temporary"
                        open={mobileOpen}
                        onClose={handleDrawerToggle}
                        ModalProps={{
                          keepMounted: true, // Better open performance on mobile.
                        }}
                        sx={{
                          display: { xs: "block", sm: "none" },
                          "& .MuiDrawer-paper": {
                            boxSizing: "border-box",
                            width: drawerWidth,
                            position: "relative",
                            borderRight: "none",
                            zIndex: "0",
                          },
                        }}
                      >
                        {drawer}
                      </Drawer>
                    ) : (
                      <Drawer
                        variant="permanent"
                        sx={{
                          display: { xs: "none", sm: "block" },
                          "& .MuiDrawer-paper": {
                            boxSizing: "border-box",
                            width: drawerWidth,
                            position: "relative",
                            borderRight: "none",
                            zIndex: "0",
                          },
                        }}
                        open
                      >
                        {drawer}
                      </Drawer>
                    )}
                  </Box>
                </Col>
                <Col>
                  <Row>
                    <Col lg="12" md="12" sm="12">
                      <Box component="main" sx={{ flexGrow: 1, p: 0 }}>
                        <div className="main-wrapper course-details-page">
                          <Styles>
                            <section>
                              <Container>
                                <Row>
                                  <Col lg="12" md="12" sm="12">
                                    <IconButton
                                      color="inherit"
                                      aria-label="open drawer"
                                      edge="start"
                                      onClick={handleDrawerToggle}
                                      sx={{ mr: 2, display: { sm: "none" } }}
                                    >
                                      <MenuIcon />
                                    </IconButton>
                                  </Col>
                                  <Col lg="12" md="12" sm="12">
                                    <div className="course-details-top">
                                      <div className="course-tab-list">
                                        <Breadcrumbs aria-label="breadcrumb">
                                          <Link
                                            underline="none"
                                            color="inherit"
                                            sx={{ cursor: "pointer" }}
                                            onClick={(e) => {
                                              e.preventDefault();
                                              props.history.push(webUrl.userDashboard);
                                            }}
                                          >
                                            Dashboard
                                          </Link>
                                          <Link
                                            underline="none"
                                            color="inherit"
                                            sx={{ cursor: "pointer" }}
                                            onClick={(e) => {
                                              e.preventDefault();
                                              props.history.push(webUrl.subjectDetails + "/" + props.chapter.subsubcategory_id);
                                            }}
                                          >
                                            {props.chapter &&
                                              props.chapter.subsubcategory &&
                                              props.chapter.subsubcategory.subcategory &&
                                              `${props.chapter.subsubcategory.subcategory.name} - ${props.chapter.subsubcategory.name}`}
                                          </Link>
                                          <Typography color="text.primary">{props.chapter.name}</Typography>
                                        </Breadcrumbs>
                                        <div className="course-desc">
                                          {selectedPage === 0 && (
                                            <div className="course-desc">
                                              <div className="faq-area">
                                                <div className="faq-box">
                                                  <div className="faq-item">
                                                    <div className="accordion-content show">
                                                      <div className="course-items">
                                                        <SubjectVideos data={props.chapter.videos} itemKey="video" headerText="Videos" />
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          )}
                                          {selectedPage === 1 && <Theory {...props} />}
                                          {selectedPage === 2 && <NcertBook {...props} />}
                                          {selectedPage === 3 && <PracticeTest {...props} />}
                                          {selectedPage === 4 && (
                                            <div className="course-desc">
                                              <div className="faq-area">
                                                <div className="faq-box">
                                                  <div className="faq-item">
                                                    <div className="accordion-content show">
                                                      <div className="course-items">
                                                        <SubjectVideos
                                                          data={props.chapter.interactivities}
                                                          itemKey="interactivity"
                                                          headerText="Interactivities"
                                                        />
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          )}
                                          {selectedPage === 5 && <NcertExercise {...props} />}
                                          {selectedPage === 6 && <Tests {...props} redirectTo={props.history.push} />}
                                        </div>
                                      </div>
                                    </div>
                                  </Col>
                                </Row>
                              </Container>
                            </section>
                          </Styles>
                        </div>
                      </Box>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Container>
          </section>
        </Styles>
      </Styles2>

      {/* Footer 2 */}
      <FooterTwo />
    </div>
  );
}

Chapter.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

const mapStateToProps = (state) => ({
  chapter: state.chapter.data.chapter,
  showLoader: state.user.showLoader,
});

const mapDispatchToProps = (dispatch) => ({
  chapterDetails: (data) => dispatch(chapterDetailsAction(data)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Chapter));
