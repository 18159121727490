import React from 'react';
import { IonLoading } from '@ionic/react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Styles } from './styles/loader.js';

const Loader = (props) => {

    return (
        <Styles>
            <IonLoading
                isOpen={props.showLoader && props.showLoader.length > 0}
                message={'Please wait...'}
                duration={10000}
            />
        </Styles>
    )
}

const mapStateToProps = state => ({
    showLoader: state.user.showLoader
});

export default withRouter(connect(mapStateToProps, {})(Loader));