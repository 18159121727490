import React, { useEffect, useState } from "react";
import Datas from "../data/footer/footer2.json";
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Container, Row, Col } from "react-bootstrap";
import BackToTop from "./common/BackToTop";
import { Styles } from "./styles/footerTwo.js";
import Partner from "../pages/partner_login";
import { Alert, Link as Link2 } from "@mui/material";
import { submitNewsletterAction } from "./actions/footer";
import { webUrl } from "../shared/constants";

function FooterTwo(props) {
  const [successMessage, setSuccessMessage] = useState(false);
  useEffect(() => {
    const form = document.getElementById("form4");
    const email = document.getElementById("email4");
    if (form) {
      form.addEventListener("submit", formSubmit);
      function formSubmit(e) {
        e.preventDefault();
        let valid = false;
        const emailValue = email.value.trim();

        if (emailValue === "") {
          setError(email, "Email can't be blank");
        } else if (!isEmail(emailValue)) {
          setError(email, "Not a valid email");
        } else {
          setSuccess(email);
          valid = true;
        }
        if (valid) {
          props.submitNewsletter({ email: emailValue });
          setSuccessMessage(true);
          setTimeout(() => {
            resetForm(email);
            setSuccessMessage(false);
          }, 5000);
        }
      }
      function resetForm(input) {
        const formControl = input.parentElement;
        input.value = "";
        const errorMsg = formControl.querySelector(".input-msg4");
        formControl.className = "form-control";
        errorMsg.innerText = "";
      }

      function setError(input, message) {
        const formControl = input.parentElement;
        const errorMsg = formControl.querySelector(".input-msg4");
        formControl.className = "form-control error";
        errorMsg.innerText = message;
      }

      function setSuccess(input) {
        const formControl = input.parentElement;
        formControl.className = "form-control success";
      }

      function isEmail(email) {
        return /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(email);
      }
    }
  }, []);

  return (
    <Styles>
      {/* Footer Two */}
      <footer className="footer2" style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/${Datas.backgroundImage})` }}>
        <Container>
          <Row>
            <Col md="3">
              <div className="footer-logo-info">
                <img src={process.env.PUBLIC_URL + "/assets/images/logo2.webp"} alt="" className="img-fluid" />
                <p>Alyss - Your Child’s partner in STEM learning, fall in love with Science, Maths and Robotics.</p>
                <ul className="list-unstyled">
                  <li>
                    <i className="las la-map-marker"></i>A-15, Sector 3, Noida, Uttar Pradesh, India
                  </li>
                  <li>
                    <i className="las la-envelope"></i>
                    <a href="mailto:info@ealyss.com">info@ealyss.com</a>
                  </li>
                  <li>
                    <i className="las la-phone"></i>
                    <a href="tel:+91-989-1629-026">+91-989-1629-026</a>
                  </li>
                </ul>
              </div>
            </Col>
            {/* <Col md="3">
              <div className="f-links">
                <h5 style={{ color: "#eeeeee" }}>Useful Links</h5>
                <ul className="list-unstyled">
                  <li>
                    <Link to={webUrl.about}>
                      <i className="las la-angle-right"></i>About Us
                    </Link>
                  </li>
                  <li>
                    <Link to="#" underline="none">
                      <i className="las la-angle-right"></i>Offerings
                    </Link>
                  </li>
                  <li>
                    <Link to="#" underline="none">
                      <i className="las la-angle-right"></i>Events
                    </Link>
                  </li>
                  <li>
                    <Link to={webUrl.blog} underline="none">
                      <i className="las la-angle-right"></i>Blog
                    </Link>
                  </li>
                </ul>
              </div>
            </Col> */}
            <Col md="6">
              <div className="f-links">
                <h5 style={{ color: "#eeeeee" }}>Other Links</h5>
                <ul className="list-unstyled">
                  <li>
                    <Link to={webUrl.about}>
                      <i className="las la-angle-right"></i>About Us
                    </Link>
                  </li>
                  <li>
                    <Link to={webUrl.contact} underline="none">
                      <i className="las la-angle-right"></i>Contact Us
                    </Link>
                  </li>
                  <li>
                    <Link to={webUrl.terms} underline="none">
                      <i className="las la-angle-right"></i>Terms of Use
                    </Link>
                  </li>
                  <li>
                    <Link to={webUrl.privacyPolicy} underline="none">
                      <i className="las la-angle-right"></i>Privacy Policy
                    </Link>
                  </li>
                  <li className="partner-link">
                    <Partner customText="Partner Login" />
                  </li>
                  <li>
                    <Link to={webUrl.internship} underline="none">
                      <i className="las la-angle-right"></i>Robotics Certification Courses
                    </Link>
                  </li>
                </ul>
              </div>
            </Col>
            <Col md="3">
              <div className="f-newsletter">
                <h5>Newsletter</h5>
                <p>Subscribe newsletter to get the latest updates in your inbox</p>
                <Alert severity="success" style={{ display: !successMessage ? "none" : "block" }}>
                  Thanks, you are successfully subscribed
                </Alert>
                <form id="form4" className="form" style={{ display: successMessage ? "none" : "block" }}>
                  <div className="form-control">
                    <input type="email" placeholder="Enter email here" id="email4" />
                    <span className="input-msg4"></span>
                  </div>
                  <button type="submit" disabled={props.showLoader.length > 0}>
                    Submit
                  </button>
                </form>
              </div>
            </Col>
            <Col md="12">
              <div className="copytext-area text-center">
                <p>Copyright &copy; 2022 | Alyss Edusolutions Pvt. Ltd. All Rights Reserved</p>
                <ul className="social list-unstyled list-inline">
                  <li className="list-inline-item">
                    <Link2 href="https://www.facebook.com/alyssedusolutions/" target="_blank" underline="none">
                      <i className="fab fa-facebook-f"></i>
                    </Link2>
                  </li>
                  <li className="list-inline-item">
                    <Link2 href="https://twitter.com/alysseducation" target="_blank" underline="none">
                      <i className="fab fa-twitter"></i>
                    </Link2>
                  </li>
                  <li className="list-inline-item">
                    <Link2 href="https://www.linkedin.com/company/alyss--edusolutions" target="_blank" underline="none">
                      <i className="fab fa-linkedin-in"></i>
                    </Link2>
                  </li>
                  <li className="list-inline-item">
                    <Link2 href="https://www.youtube.com/channel/UCTOurhsso5Vrk6s1zdtARUA" target="_blank" underline="none">
                      <i className="fab fa-youtube"></i>
                    </Link2>
                  </li>
                  <li className="list-inline-item">
                    <Link2 href="https://www.instagram.com/alyss_edusolutions/" target="_blank" underline="none">
                      <i className="fab fa-instagram"></i>
                    </Link2>
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
        </Container>

        {/* Back To Top  */}
        <BackToTop />
      </footer>
    </Styles>
  );
}

const mapStateToProps = (state) => ({
  newsletter: state.footer.data.newsletter,
  showLoader: state.user.showLoader,
});

const mapDispatchToProps = (dispatch) => ({
  submitNewsletter: (data) => dispatch(submitNewsletterAction(data)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(FooterTwo));
