import { actionTypes } from "./action";

/** Initial state */
const initialState = {
  data: {
    notifications: []
  },
  error: null
};

/** Reducers */
export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {

    case actionTypes.NOTIFICATIONS_DATA_REQUEST_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          notifications: payload.data.notifications
        }
      };
    default:
      return state;
  }
};
