import { showLoaderAction, stopLoaderAction } from "../user/action";
import { startModuleQuiz, submitModuleQuiz } from "./service";

/**
 * Action types
 */
export const actionTypes = {
  START_MODULE_QUIZ_REQUEST_SUCCESS: "START_MODULE_QUIZ_REQUEST_SUCCESS",
  SUBMIT_MODULE_QUIZ_REQUEST_SUCCESS: "SUBMIT_MODULE_QUIZ_REQUEST_SUCCESS"
};

export const receivedStartModuleQuizAction = (payload) => ({
  type: actionTypes.START_MODULE_QUIZ_REQUEST_SUCCESS,
  payload,
});

export const startModuleQuizAction = (data) => {
  return (dispatch) => {
    dispatch(showLoaderAction("startModuleQuiz"));

    return startModuleQuiz(data)
      .then((response) => {

        dispatch(stopLoaderAction("startModuleQuiz"));
        dispatch(receivedStartModuleQuizAction(response));
      })
      .catch((error) => {
        dispatch(stopLoaderAction("startModuleQuiz"));
      });
  };
};

export const receivedSubmitModuleQuizAction = (payload) => ({
  type: actionTypes.SUBMIT_MODULE_QUIZ_REQUEST_SUCCESS,
  payload,
});

export const submitModuleQuizAction = (data) => {
  return (dispatch) => {
    dispatch(showLoaderAction("submitModuleQuiz"));

    return submitModuleQuiz(data)
      .then((response) => {

        dispatch(stopLoaderAction("submitModuleQuiz"));
        dispatch(receivedSubmitModuleQuizAction(response));
      })
      .catch((error) => {
        dispatch(stopLoaderAction("submitModuleQuiz"));
      });
  };
};