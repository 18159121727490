import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import FooterTwo from "./components/FooterTwo";
import VideoSlider from "./components/VideoSlider";
import ButtonArea from "./components/ButtonArea";
import common from "./shared/common";
import { webUrl } from "./shared/constants";
import { Styles } from "./components/styles/homeUS";
import { Button, Container, Grid, Link, Paper, TextField } from "@mui/material";
import { Dropdown } from "react-bootstrap";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import FreeTrial from "./components/FreeTrial";
import User from "./pages/user";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import DashboardIcon from "@mui/icons-material/Dashboard";
import PrecisionManufacturingIcon from "@mui/icons-material/PrecisionManufacturing";
import SupervisedUserCircleIcon from "@mui/icons-material/SupervisedUserCircle";
import AccountCircleRoundedIcon from "@mui/icons-material/AccountCircleRounded";
import ModalVideo from "react-modal-video";
import { sendAuditEventAction } from "./components/actions/common";
import AboutRobookido from "./pages/ushomepage/index";
const HomeUS = (props) => {
  const [anchorState, setAnchorState] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const openVideoModal = (data) => {
    setIsOpen(true);
  };
  const onClickJoinNow = () => {
    if (common.getFromStorage("userName"))
      props.history.push(webUrl.userDashboard);
  };
  const onOpenDrawer = (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setAnchorState(true);
  };
  const onCloseDrawer = (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setAnchorState(false);
  };
  const list = () => (
    <Box role="presentation" onClick={onCloseDrawer} onKeyDown={onCloseDrawer}>
      <List>
        <ListItem>
          <ListItemButton
            onClick={() => {
              props.history.push(process.env.PUBLIC_URL + webUrl.studyMaterial);
            }}
          >
            <ListItemIcon>
              <MenuBookIcon />
            </ListItemIcon>
            <ListItemText primary="Study Material" />
          </ListItemButton>
        </ListItem>
        <ListItem>
          <ListItemButton
            onClick={() => {
              props.history.push(process.env.PUBLIC_URL + webUrl.robotic);
            }}
          >
            <ListItemIcon>
              <PrecisionManufacturingIcon />
            </ListItemIcon>
            <ListItemText primary="Robotics" />
          </ListItemButton>
        </ListItem>
        <ListItem>
          <ListItemButton
            onClick={() => {
              props.history.push(process.env.PUBLIC_URL + webUrl.teacher);
            }}
          >
            <ListItemIcon>
              <SupervisedUserCircleIcon />
            </ListItemIcon>
            <ListItemText primary="Teacher Solutions" />
          </ListItemButton>
        </ListItem>
      </List>
    </Box>
  );
  useEffect(() => {
    if (common.getFromStorage("userName"))
      props.sendAuditEvent({ event_id: "home", source: "web" });
  }, []);

  const profile = common.getFromStorage("profile");
  let dashboardUrl = webUrl.userDashboard;
  if (profile) {
    const profileData = JSON.parse(profile);
    if (profileData.member_type === 4) {
      dashboardUrl = webUrl.partnerDashboard;
    }
  }
  return (
    <Styles>
      <div className="header">
        <img
          src={process.env.PUBLIC_URL + `/assets/images/us/plus.webp`}
          alt="Plus Signs"
          className="fixed-plus"
        />
        <Container maxWidth="100%">
          <div className="logo">
            <Link href={webUrl.homeUS}>
              <img
                src={process.env.PUBLIC_URL + `/assets/images/us/logo.webp`}
                alt="ALYSS Logo"
              />
            </Link>
          </div>
          <div className="right-side">
            <ul className="list-unstyled list-inline bar-login">
              {common.getFromStorage("userName") ? (
                <>
                  <li className="list-inline-item">
                    <Link href={dashboardUrl}>
                      <DashboardIcon />
                      Dashboard
                    </Link>
                  </li>
                  <li className="list-inline-item">
                    <Dropdown
                      className="info-filter"
                      name="country_code"
                      id="country_code"
                      onSelect={(e) => {
                        if (e === webUrl.home) localStorage.clear();
                        this.props.history.push(e);
                      }}
                    >
                      <Dropdown.Toggle as="a" className="filter-item">
                        <Link href={"#"}>
                          <AccountCircleRoundedIcon />
                          {common.getFromStorage("userName")}
                        </Link>
                      </Dropdown.Toggle>
                      <Dropdown.Menu as="ul">
                        <Dropdown.Item eventKey={webUrl.home} as="li">
                          Logout
                        </Dropdown.Item>
                      </Dropdown.Menu>
                      <span className="login_input-msg"></span>
                    </Dropdown>
                  </li>
                </>
              ) : (
                <>
                  <li className="list-inline-item">
                    <Button
                      className="orange-bg"
                      variant="contained"
                      size="large"
                    >
                      <User customText="Login / Signup" />
                    </Button>
                  </li>
                </>
              )}
            </ul>

            <Link className="menu" onClick={onOpenDrawer}>
              <img
                src={process.env.PUBLIC_URL + `/assets/images/us/menu.webp`}
                alt="Menu"
              />
            </Link>
          </div>
          <Grid container spacing={2} className="header-content">
            <Grid xs={12} sm={5} className="p-3">
              <h2 className="tp-9">Fun With STEM</h2>
              <h2>ALYSS Robotics Program</h2>
              <ul>
                <li>3D HD Animations for Robotics Concepts</li>
                <li>Learn from Expert Coaches</li>
                <li>Experiential Learning</li>
                <li>Cognitive Development</li>
                <li>Earn Certificates as you climb the course ladder</li>
              </ul>
              <div className="header-bottom-first">
                <h3>
                  Your Child's Partner in{" "}
                  <span className="orange-text">STEM Learning</span>
                </h3>
                <h5>Register now for a free demo</h5>
              </div>
            </Grid>
            <Grid xs={12} md={3} sm={3} className="p-3">
              <div>
                <div className="robo-with_students"></div>
              </div>
              <Link
                className="why-robots"
                onClick={() => {
                  openVideoModal();
                }}
              >
                <img
                  src={
                    process.env.PUBLIC_URL + `/assets/images/us/video-icon.webp`
                  }
                  alt="Menu"
                />
                <span>Why learn Robotics?</span>
              </Link>
            </Grid>
            <Grid xs={12} md={4} sm={4} className="p-3">
              <div>
                <div className="robo-only">
                  <div id="robo-overlay"></div>
                </div>
              </div>
            </Grid>
          </Grid>
        </Container>
      </div>
      <div className="main-wrapper">
        <Container>
          {/* <Grid container spacing={2} className="experiential-learning">
            <Grid xs={12} sm={7} className="p-3">
              <img src={process.env.PUBLIC_URL + `/assets/images/us/robo.webp`} alt="Robo Vector" />
            </Grid>
            <Grid xs={12} md={5} sm={5} className="p-3">
              <div className="learning-text">
                <h2>Experiential Learning</h2>
                <p>
                  Alyss Robotics program gives students hands-on experience in applying essential Science, Technology, Engineering, and Maths (STEM)
                  concepts to real-world problems.
                </p>
                <p>
                  The curriculum is specially designed to improve creativity, critical thinking, problem solving skills, team spirits and leadership
                  skills in your kids.
                </p>
                <Button onClick="" className="violet-bg" variant="contained" size="large">
                  Learn More
                </Button>
              </div>
              <div className="check-in-block">
                <div>
                  <p>
                    Our team of education experts is here to help.
                    <br />
                    Contact us today and get a demo.
                  </p>
                </div>
                <div className="checkin-form">
                  <TextField label="Enter your e-mail" variant="outlined" size="small" className="email-input" type="email" name="email" />
                  <Button onClick="" className="orange-bg" variant="contained" size="large">
                    Check-in
                  </Button>
                </div>
              </div>
            </Grid>
          </Grid>

          <Grid container spacing={2} className="robotics-expert">
            <Grid xs={12} sm={12} className="p-3 text-center">
              <h3>Climb the course become a robotics expert and ace robotics competitions!</h3>
            </Grid>
            <Grid xs={12} sm={6} className="p-3">
              <img src={process.env.PUBLIC_URL + `/assets/images/us/robo1.webp`} alt="Lego Mindstorm Course" />
              <div className="img-caption">Lego Mindstorm Course</div>
            </Grid>
            <Grid xs={12} sm={6} className="p-3">
              <img src={process.env.PUBLIC_URL + `/assets/images/us/robo-parts.webp`} alt="Robot Inventor Course" />
              <div className="img-caption">Robot Inventor Course</div>
            </Grid>
          </Grid>

          <Grid container spacing={2} className="have_fun">
            <Grid xs={12} className="p-3 main_container">
              <div className="bg_card">
                <div className="head">
                  <h2 className="title">Have Fun</h2>
                  <p>
                    While you learn
                    <div className="line">
                      <span className="start"></span>
                      <span className="middle"></span>
                      <span className="end"></span>
                    </div>
                  </p>
                </div>
                <div className="d_flex">
                  <div className="col_three">
                    <h4 className="circle_title">Absorb</h4>
                    <img src={process.env.PUBLIC_URL + `/assets/images/robotics/circle_1.webp`} alt="" />
                    <h5>Absorb Robotic Concepts</h5>
                    <p>(3D HD Animation Concept Videos)</p>
                  </div>
                  <div className="col_three">
                    <h4 className="circle_title">Program</h4>
                    <img src={process.env.PUBLIC_URL + `/assets/images/robotics/circle_2.webp`} alt="" />
                    <h5>Program Your Robot</h5>
                    <p>(Programming videos)</p>
                  </div>
                  <div className="col_three">
                    <h4 className="circle_title">Challenge</h4>
                    <img src={process.env.PUBLIC_URL + `/assets/images/robotics/circle_3.webp`} alt="" />
                    <h5>Send your Robot on Challenges/Missions</h5>
                    <p>(Tips and Tricks)</p>
                  </div>
                </div>
              </div>
            </Grid>
          </Grid>
          <Grid container spacing={2} className="robotics-coach">
            <Grid xs={12} sm={6} className="p-3">
              <img src={process.env.PUBLIC_URL + `/assets/images/us/robo-expert.webp`} alt="Robotics Coach" />
            </Grid>
            <Grid xs={12} sm={6} className="p-3">
              <h4>Reboot Back to School</h4>
              <p>
                When it comes to lesson planning, we've got you covered! Within the <Link> ALYSS App</Link> and <Link>littleBits Classroom</Link> you
                and your students gain access to thousands of free, standards-aligned STEAM and computer science activities for grades PK-12.
              </p>
              <p>
                Not sure where to start? Our <Link>Sphere Heroes</Link> recommend these activities to help get the ball rolling. See what they share
                below!
              </p>
              <Button onClick="" className="pink-bg" variant="contained" size="large">
                Learn More
              </Button>
            </Grid>
          </Grid> */}

          {/* <FreeTrial></FreeTrial> */}
        </Container>
        <AboutRobookido {...props} />
        {/* <VideoSlider />
        <ButtonArea buttonText="Join Now" onHeroButtonClick={onClickJoinNow} showPopup={!!!common.getFromStorage("userName")} />
        <FooterTwo /> */}
        <FooterTwo />
      </div>
      <Drawer anchor="right" open={anchorState} onClose={onCloseDrawer}>
        {list()}
      </Drawer>
      <ModalVideo
        channel="vimeo"
        isOpen={isOpen}
        videoId="709618465"
        onClose={() => setIsOpen(false)}
      />
    </Styles>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  sendAuditEvent: (data) => dispatch(sendAuditEventAction(data)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(HomeUS));
