import { doGetRequest } from "../../shared/api/base-api";
import { apiUrl } from "../../shared/constants";
import common from "../../shared/common";

//Live Classes data api
export function getRecordedSessionsData(data) {
  return doGetRequest(common.stringformat(apiUrl.recordedSessions, data));
}

//Live Classes validation data api
export function getValidatedRecordedSessionData(data) {
  return doGetRequest(common.stringformat(apiUrl.recordedSessionCheck, data));
}
