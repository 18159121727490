import { showLoaderAction, stopLoaderAction } from "../user/action";
import { getSubscriptionsListData, generateTest } from "./service";

/**
 * Action types
 */
export const actionTypes = {
  SUBSCRIPTIONS_DATA_REQUEST_SUCCESS: "SUBSCRIPTIONS_DATA_REQUEST_SUCCESS",
  GENERATE_TEST_REQUEST_SUCCESS: "GENERATE_TEST_REQUEST_SUCCESS",
};

export const receivedSubscriptionsListDataAction = (payload) => ({
  type: actionTypes.SUBSCRIPTIONS_DATA_REQUEST_SUCCESS,
  payload,
});

export const receivedGenerateTestAction = (payload) => ({
  type: actionTypes.GENERATE_TEST_REQUEST_SUCCESS,
  payload,
});

export const subscriptionsListDataAction = (data) => {

  return (dispatch) => {

    dispatch(showLoaderAction("getSubscriptionsListData"));

    return getSubscriptionsListData(data)
      .then((response) => {
        dispatch(stopLoaderAction("getSubscriptionsListData"));

        if (response) dispatch(receivedSubscriptionsListDataAction(response));
      })
      .catch((error) => {
        dispatch(stopLoaderAction("getSubscriptionsListData"));
      });
  };
};

export const generateTestAction = (data) => {

  return (dispatch) => {

    dispatch(showLoaderAction("generateTest"));

    return generateTest(data)
      .then((response) => {
        dispatch(stopLoaderAction("generateTest"));

        if (response) dispatch(receivedGenerateTestAction(response));
      })
      .catch((error) => {
        dispatch(stopLoaderAction("generateTest"));
        throw new Error(error);
      });
  };
};