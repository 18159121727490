import React, { useEffect } from "react";
import UserLogin from "./components/UserLogin";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import {
  forgotPasswordAction,
  profileMasterDataAction,
  updateProfileAction,
  userLoginAction,
  userLoginWithEmailAction,
  verifyEmailOTPAction,
  verifyOTPAction,
  verifyPasswordAction,
  verifyResetPasswordOTPAction,
  updatePasswordAction,
} from "./action";

const User = (props) => {
  return <UserLogin {...props} redirectTo={props.history.push} />;
};

const mapStateToProps = (state) => ({
  user: state.user,
  masterData: state.user.data.masterData.data || {},
});

const mapDispatchToProps = (dispatch) => ({
  userLogin: (userData) => dispatch(userLoginAction(userData)),
  verifyOTP: (otpData) => dispatch(verifyOTPAction(otpData)),
  userLoginWithEmail: (userData) => dispatch(userLoginWithEmailAction(userData)),
  forgotPassword: (data) => dispatch(forgotPasswordAction(data)),
  verifyEmailOTP: (otpData) => dispatch(verifyEmailOTPAction(otpData)),
  verifyResetPasswordOTP: (otpData) => dispatch(verifyResetPasswordOTPAction(otpData)),
  verifyPassword: (data) => dispatch(verifyPasswordAction(data)),
  updatePassword: (data) => dispatch(updatePasswordAction(data)),
  updateProfile: (profileData) => dispatch(updateProfileAction(profileData)),
  profileMasterData: (data) => dispatch(profileMasterDataAction(data)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(User));
