import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import FooterTwo from "./FooterTwo";
import { Styles } from "./styles/terms.js";
import HeaderTwo from "./HeaderTwo";

const Privacy = (props) => {
  return (
    <>
      <Styles>
        {/* Main Wrapper */}
        <div className="main-wrapper contact-page">
          {/* Header */}
          <HeaderTwo />

          {/* Breadcroumb */}
          {/* <BreadcrumbBox title="Contact Us" /> */}

          {/* Contact Area */}
          <section className="contact-area">
            <Container>
              <Row>
                <Col md="12">
                <h3>TERMS OF SERVICE</h3>

<h6>User Policy</h6>
<p>
  Welcome to Alyss Edusolutions, an online learning platform providing learning content operated by Alyss Edusolutions Pvt Ltd (also referred to as Alyss). By using Alyss, you accept and agree to be bound by the following terms and conditions. Nothing contained in these Terms of Service (ToS) should be construed to confer any rights to a third party beneficiary.
</p>
<h6>Changes To Alyss And Terms And Conditions</h6>
<p>
Alyss reserves its right to modify or terminate the Alyss service for any reason, without prior notice, without liability to the user or any other members or third party. Alyss also reserves the right to modify the ToS from time to time without notice. The user agrees to regularly review the ToS to keep himself apprised of any such changes.
</p>
<h6>Eligibility, Registration And Access</h6>
<p>The decision to grant access to the services of Alyss is the prerogative of the site and its decision in this respect shall be final and binding on the proposed user. The user consents that Alyss reserves its right to take all necessary measures to deny access to its services or the termination of services, if the terms and conditions of the present agreement are not complied with or are contravened or there is any violation of copyright, trademark or other valuable proprietary right as described in this agreement. In order to use the service, the user must obtain access to the World Wide Web, either directly or through devices that access web-based content, and pay for the service fees associated with such access. In addition, the user must have all equipment necessary to make such connection to the World Wide Web, including a computer and modem or other access devices; that do not form a part of the service.</p>
<h6>Terms Of Service</h6>
<ul>
  <li>The contents of the Application/products are developed on the concepts covered in the structured curriculum syllabus prescribed for students of various courses. The usage of the Application/products is not endorsed as a substitution to the curriculum-based education provided by the educational institutions but is intended to supplement the same by explaining and presenting the concepts in a manner enabling easy understanding. The basic definitions and formulae of the subject matter would remain the same.</li>
  <li>The Company's products and / or Services, including the Application and content, are compatible only with certain devices/tablets/hardware. The Company shall not be obligated to provide products and / or services for any instruments that are not recognized by the Company or those instruments that may be purchased from any third party which are not compatible with the Company's products and Services. The company reserves the right to upgrade the hardware/ type of compatible devices as required from time to time.</li>
  <li>The use of the site Alyss, or any of its services or features and user to user interface provided by the site, the information and material contained therein implies and constitutes the user's consent to this agreement, the user unequivocally agrees to abide by these terms and conditions.</li>
  <li>In case of multiple year packs, access to all classes is given immediately on making the payment and the student can access content/classes as per his study requirements without any limitation of time or period.</li>
  <li>The user agrees that Alyss has opinions regarding the subject matter contained on this site and these opinions regarding subject matters contained on the site may be different from those prevalent at that point of time. The user is aware and agrees that these opinions are subject to change from time to time without prior intimation to the user.</li>
  <li>The answers, information, material and content have been posted on Alyss by exercising due diligence and care and are correct and true to the best of the knowledge and intent of the site. However the user agrees to independently verify the authenticity and veracity of the answers, information, material and content posted on the site.</li>
  <li>The user agrees and understands that the information available on the site is not meant for supplementing the knowledge of the user for competitive exams and promises to hold free the site of all liabilities that may arise from the direct or indirect use of the information available on the site.</li>
  <li>The user is aware and understands that Alyss does not provide any judgment or warranty regarding the authenticity or correctness of the content of other services or sites that the screen links refer to. The user is aware and understands that a link to another service or site is not an endorsement of the service or site and Alyss shall not be responsible in any manner whatsoever for the consequences of any act or omission on the part of the user on the basis of the information contained in such other services or sites.</li>
  <li>Alyss reserves its right to monitor the use of all or any part of its service without any prior intimation or any obligation to the user.</li>
  <li>The user agrees that he/she shall not alter the information, content or material or use of such information, content or material contained on the site for any purpose that constitutes a violation of the copyright of Alyss or of a third-party information provider. The user agrees not to copy, reproduce, republish, upload, post, transmit or distribute the material in any way for any use without obtaining the prior written consent from Alyss.</li>
  <li>If the user does not agree to any of the terms mentioned in this agreement, then the user should exit the site through his browser.</li>
</ul>
<h6>Access Security And Password</h6>
<p>
The user might receive a password and account designation upon completing the service's registration process. The user undertakes to maintain the confidentiality of the password and account, and shall be held solely responsible for all acts and omissions that occur under his password or account. Alyss cannot and will not be liable for any loss or damage arising from the user's failure to comply with the terms and conditions of this agreement. The user should always remember to LOG OFF COMPLETELY from Alyss, if he is accessing it from a public place and also change his password often, and be sure to change it to something inconspicuous.
</p>
<h6>User Conduct And Prohibited Acts</h6>
<p>
The Alyss service is made available for personal, non-commercial use only. Broadcasting the same through any means, including but not limited to through the internet, television, handheld devices and other devices, is not permitted and will be considered as a breach of Intellectual Property Rights of the Company. The Company reserves the right to terminate the subscription without any prior notice and also to initiate any legal proceedings in case of such breach. Businesses, organizations or other legal entities are strictly debarred from using the Alyss services for any purpose.
</p>
<p>
The user is prohibited from using the Alyss service for any illegal or unauthorized purpose. International users agree to comply with all local rules regarding online conduct and acceptable content, including laws regulating the export of data from India. The user is solely responsible for his conduct and any data, text, information, graphics, photos, profiles, audio and video clips, links and other material that he submits, posts, and displays on the Alyss service. The site may at its sole discretion remove information, content or material that is incorrect, inaccurate, unlawful, fraudulent, threatening, libelous, defamatory, obscene or otherwise objectionable, or infringes or violates any party's intellectual property or other proprietary rights or these Terms of Service.
</p>
<ul>
  <li>Modifying, adapting, translating, or reverse engineering any portion of the Alyss service;</li>
  <li>Removing any copyright, trademark or other proprietary rights notices contained in or on the Alyss service;</li>
  <li>Using any robot, spider, site search/retrieval application, or other device to retrieve or index any portion or the Alyss service;</li>
  <li>Collecting any information about other users (including usernames and/or email addresses) for unauthorized purposes;</li>
  <li>Reformatting or framing any portion of the web pages that are part of the Alyss service; creating user accounts by automated means or under false or fraudulent pretences;</li>
  <li>Creating or transmitting unwanted electronic communications such as spam or chain letters to other user or otherwise interfering with other user's enjoyment of the service;</li>
  <li>Submitting information or material of any third party without such third party's prior written consent;</li>
  <li>Directing any user (for example, by linking) to any information or material of any third party without such third party's prior written consent or the users consent;</li>
  <li>Submitting information or material that infringe, misappropriate or violate the intellectual property, publicity, privacy or other proprietary rights of any party.</li>
  <li>Transmitting any viruses, worms, defects, Trojan horses or other items of a destructive nature.</li>
  <li>Submitting information or material that are unlawful or promote or encourage illegal activity; or submitting false or misleading information.</li>
  <li>Harm minors, children and women in any way or stalk or otherwise harass others</li>
  <li>Submitting information that is obscene, voyeuristic or contains pornography or contain an indecent representation of women within the meaning of the Indecent Representation of Women (Prohibition) Act, 1986; or cause to be displayed or transmitted or circulated through the site in any electronic form, any material which is lascivious or appeal to the prurient interest or if its effect is such as to tend to deprave and corrupt persons who are likely, having regard to the fact that the site is meant for educational and academic pursuit by students who are yet to reach an age capable of discerning the propriety of the material contained or embodied in such transmission.</li>
  <li>Upload, post, email or otherwise transmit any Content that is unlawful, harmful, threatening, abusive, harassing, tortuous, defamatory, vulgar, obscene, libelous, invasive of another's privacy, hateful, or racially, ethnically or otherwise objectionable; or a threat to the nation's communal harmony, peace and security.</li>
  <li>Upload, post, email or otherwise transmit any Content that the user doesn't have a right to transmit under any law or under contractual or fiduciary relationships (such as inside information, proprietary and confidential information learned or disclosed as part of employment relationships or under non-disclosure agreements).</li>
  <li>Interfere with or disrupt the Service or servers or networks connected to the Service, or disobey any requirements, procedures, policies or regulations of networks connected to the Service.</li>
  <li>Intentionally or unintentionally violate any applicable local, state, national or international law</li>
  <li>Any other conduct, act or behavior that is illegal or unlawful</li>
</ul>
<p>While Alyss prohibits such conduct and content on its site, the user understands and agrees that he nonetheless may be exposed to such information materials and that he shall immediately inform Alyss of such violation. If the user continues to use the Alyss service he shall do so at his own risk and peril.</p>

<h6>User Content</h6>
<p>
Certain elements of the site will contain material submitted by users. Alyss accepts no responsibility for the material and contents, accuracy, conformity to applicable laws of such material.
</p>
<h6>Advertisements</h6>
<p>
The site may at its discretion display advertisements. The users' correspondence or business dealings with, or participation in promotions of, advertisers found on or through the Alyss service, including payment and delivery of related goods or services, and any other terms, conditions, warranties or representations associated with such dealings, are solely between the user and such advertiser. The user agrees that Alyss shall not be responsible or liable for any loss or damage of any sort incurred as the result of any such dealings or as the result of the presence of such advertisers on the Alyss service.
</p>
<h6>Proprietary Rights</h6>
<p>
User acknowledges that all Intellectual Property Rights including but not limited to Copyright, Patent, Trademarks, trade secrets in the products, modules, contents and programs shall continue to vest with the Company at all times. The student/parent shall not have the right to claim any rights over the Intellectual Property in the programs by virtue of them purchasing such programs. Further, under no circumstances, shall the student/parent acquire the right to make copies. The user acknowledges that in the event of breach or threatened breach of this Clause, monetary damages may not be an adequate remedy, therefore, the Company in addition to other rights and remedies shall be entitled to injunctive relief to restrain the student/parent from any such breach, threatened or actual. User further acknowledges and agrees that material and content contained in sponsor advertisements or information presented to the user through the Alyss services or advertisers is protected by copyrights, trademarks, service marks, patents or other proprietary rights and laws. Except as expressly authorized by Alyss or advertisers, user agrees not to modify, rent, lease, loan, sell, distribute or create derivative works based on the Alyss service, in whole or in part.
</p>
<h6>Disclaimer Of Warranties</h6>
<p>
User expressly understands and agrees that:
</p>
<ul>
  <li>We request You not to accept any product package that seems to be tampered with, opened or damaged at the time of delivery. The products must be returned in the same condition as delivered by the Company. Any products returned showing signs of any use or damage in any manner shall not be accepted for return.</li>
  <li>You acknowledge that the Company is not the manufacturer of the instrument/medium/hardware and hence, any defect relating to the same (beyond the specified period of 48 hours) shall be reported to the manufacturer whose details shall be specified on the packaging and the Company shall not be in any manner responsible for the same. The Company does not provide any guarantee or warranty relating to the instrument/medium/hardware.</li>
  <li>Use of the service is at the user's sole risk. The service is provided on an as is and as available basis. Alyss expressly disclaims all warranties of any kind, whether express or implied, including, but not limited to the implied warranties of merchantability, fitness for a particular purpose and non-infringement.</li>
  <li>Alyss makes no warranty that (i) the service will meet the user's requirements, (ii) the service will be uninterrupted, timely, secure, or error-free, (iii) the results that may be obtained from the use of the service will be accurate or reliable, (iv) the quality of any products, services, information, or other material purchased or obtained by the user through the service will meet user's expectations, and (v) any errors in the software will be corrected.</li>
  <li>Any material downloaded or otherwise obtained through the use of the service is done at the user's own discretion and risk and that user will be solely responsible for any damage to the user's computer system or loss of data that results from the download of any such material.</li>
  <li>No advice or information, whether oral or written, obtained by the user from Alyss or through or from the service shall create any warranty not expressly stated in the Tos.</li>
</ul>
<h6>Limitation Of Liability</h6>
<p>
Under no circumstances will Alyss be liable to the user for any indirect, incidental, consequential, special or exemplary damage arising out of or in connection with use of the Alyss service, whether or not Alyss has been advised of the possibility of such damages. Such limitation of liability shall apply (i) whether the damages arise from use or misuse of and reliance on the Alyss service, from inability to use the Alyss service, or from the interruption, suspension, or termination of the Alyss service (including such damages incurred by third parties), and (ii) notwithstanding any failure of essential purpose of any limited remedy and to the fullest extent permitted by law. Under no circumstances shall Alyss be liable to the user for any amount exceeding one thousand rupees.
</p>
<h6>Indemnity</h6>
<p>
The user agrees to indemnify and hold Alyss, and its subsidiaries, affiliates, officers, agents, co-branders or other partners, and employees, harmless from any claim or demand, including reasonable attorney's fees, made by any third party due to or arising out of content the user submits, posts or transmits through the service, the user's use of the Service, the user's connection to the Service, the user's violation of the ToS, or the user's violation of any rights of another.
</p>
<h6>No Resale Of Service</h6>
<p>
The user agrees not to reproduce, duplicate, copy, sell, resell or exploit for any commercial purposes, any portion of the Service, use of the Service, or access to the Service.
</p>

<h6>
Modifications To Service
</h6>
<p>
Alyss reserves the right at any time and from time to time to modify or discontinue, temporarily or permanently, the service (or any part thereof) with or without notice. The user agrees that Alyss shall not be liable to the user or to any third party for any modification, suspension or discontinuance of the service.
</p>
<h6>Notice</h6>
<p>
Notices to the user may be made via email or regular mail. The service of the site may also provide notices of changes to the TOS or other matters by displaying notices or links to notices to the user generally on the service.
</p>

<h6>Trademark Information</h6>
<p>
The site logos, products and service names are trademarks of Alyss Edusolutions Pvt. Ltd. The user agrees not to display or use in any manner, the trademarks of Alyss.
</p>
<h6>Copyright</h6>
<p>
Alyss Edusolutions Pvt. Ltd. is a registered entity under the laws of India with all rights reserved here-in. The copyright of all materials provided on this website are held by Alyss Edusolutions Pvt. Ltd. None of the materials displayed on or within any Alyss products including but not limited to website, SD Cards, Tablets etc, may be copied, reproduced, distributed, republished, downloaded, displayed, posted, modified, altered, reverse engineered, or transmitted in any form or by any means, including, but not limited to, electronic, mechanical, photocopying, recording, screen snapshot/ screen captured, or otherwise, without prior written permission of Alyss Edusolutions Pvt. Ltd, the copyright owner.
</p>
<h6>Violations</h6>
<p>
Please report any violation of the terms and conditions of this agreement to <a href="mailto:info@ealyss.com">info@ealyss.com</a>. 
</p>

<h6>Entire Agreement</h6>
<p>
These Terms of Service constitute the entire agreement between the parties with respect to the subject matter thereof and supersedes and replaces all prior or contemporaneous understandings or agreements, written or oral, regarding such subject.
</p>
<h6>Jurisdiction</h6>
<p>
These Terms of Service will be governed by and construed in accordance with the laws of India and any dispute arising out of the use of the site shall be subject to the exclusive jurisdiction of courts at New Delhi, India. If for any reason a court of competent jurisdiction finds any provision or portion of the Terms of Service to be unenforceable, the remainder of the Terms of Service will continue in full force and effect. The user shall not assign these Terms of Service or assign any rights or delegate any obligations hereunder, in whole or in part, whether voluntarily or by operation of law, without our prior written consent, any such purported assignment or delegation will be null and void and of no force or effect.
</p>
<h6>User Privacy Policy</h6>
<p>
The user consents that by subscribing/using the Company's products, programs or any other services, it expressly allows the Company to improve pedagogy, learning outcomes, predictive learning and to store, use, analyze, distribute, or deal with in any other manner, as the Company deems appropriate, from the information/data captured by the Company through its unique pedagogy of learn, practice and test and from the Customer's usage of Company's products, programs or any other services as the Company, its associates or any third party as the Company may authorize.
</p>
<p>
The user's privacy is critically important to Alyss. Alyss has few fundamental principles:
</p>
<ul>
  <li>Alyss asks its users to supply various personal data when placing an order, such as name, address, credit-card information, and valid E-mail id.</li>
  <li>Alyss uses this data only for the purpose of filling an order, or communicating with the user about the status of an order.</li>
  <li>Alyss treats this customer information as private and confidential, and it shall not disclose this information to other individuals or organizations unless required by law.</li>
</ul>
<p>If the user feels that Alyss has violated this Statement on Privacy in any way, please contact Alyss at so that we may address this issue <a href="mailto:info@ealyss.com">info@ealyss.com</a></p>

<h6>
Refund, Cancellation & Pack Expiration Policy
</h6>
<p>
No refund or cancellation is permitted once the user has completed a transaction at ealyss.com for various study packs etc (Valid only for purchases made through ealyss.com).
</p>
<ul>
  <li>User needs to contact customer support through the registered mobile number or e-mail to enable effective provision of Services. Request from alternate details will not be entertained.</li>
  <li>It is Your sole responsibility to ensure that the account information provided by You is accurate, complete and latest.</li>
  <li>You acknowledge that Your ability to use Your account is dependent upon external factors such as internet service providers and internet network availability and the Company cannot guarantee accessibility to the Application at all times. In addition to the disclaimers set forth in the Terms, the Company shall not be liable to You for any damages arising from Your inability to log into Your account and access the services of the Application at any time.</li>
  <li>Free look up period before applying for cancellation upgraded orders will be solely on primary order and not on upgraded order.</li>
  <li>Your Subscribed package will continue until terminated. Unless you cancel your subscription before your payment, you authorize us to charge the package amount for the next billing cycle to your Payment Method.</li>
  <li>Once you cancel your subscription, package amount will not be refunded.</li>
  <li>In case of subscription of packages of duration less than one year, the fees paid shall be non-refundable in all circumstances including but not limited to cancellation of subscription by the user, etc.</li>
</ul>
<p>
At Alyss, the Pack expires as per the validity suggested at the time they are subscribed/purchased. The subscription price will be made clear to you on our sign up pages or otherwise during the sign up process and may vary from time to time. You agree to pay at the rates notified to you at the time of your sign up. Sign up to premium content on our app is generally for a year. We may however choose to offer monthly payment options. If you choose to pay monthly, your subscription will continue until you tell us that you no longer wish to receive it, in which case you will stop paying the monthly amounts.
</p>
                </Col>
              </Row>
            </Container>

            {/* Google Map */}
            {/* <GoogleMap /> */}
          </section>

          {/* Footer 2 */}
          <FooterTwo />
        </div>
      </Styles>
    </>
  );
};

export default Privacy;
