import React, { useEffect, useState } from "react";
import { Container, Row, Col, Alert } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import FooterTwo from "../../components/FooterTwo";
import { Styles } from "./styles/contact.js";
import HeaderTwo from "../../components/HeaderTwo";
import { submitContactFormAction } from "./action";

function Contact(props) {
  const [successMessage, setSuccessMessage] = useState("");
  useEffect(() => {
    const form = document.getElementById("form_contact");
    const name = document.getElementById("contact_name");
    const email = document.getElementById("contact_email");
    const subject = document.getElementById("contact_subject");
    const message = document.getElementById("contact_message");

    form.addEventListener("submit", formSubmit);

    function formSubmit(e) {
      e.preventDefault();

      const nameValue = name.value.trim();
      const emailValue = email.value.trim();
      const subjectValue = subject.value.trim();
      const messageValue = message.value.trim();
      let error = false;

      if (nameValue === "") {
        setError(name, "Name can't be blank");
        error = true;
      } else {
        setSuccess(name);
      }

      if (emailValue === "") {
        setError(email, "Email can't be blank");
        error = true;
      } else if (!isEmail(emailValue)) {
        setError(email, "Not a valid email");
        error = true;
      } else {
        setSuccess(email);
      }

      if (subjectValue === "") {
        setError(subject, "Subject can't be blank");
        error = true;
      } else {
        setSuccess(subject);
      }

      if (messageValue === "") {
        setError(message, "Message can't be blank");
        error = true;
      } else {
        setSuccess(message);
      }

      if (!error) {
        props
          .submitContactForm({ name: nameValue, email: emailValue, subject: subjectValue, message: messageValue })
          .then((response) => {
            if (response) {
              resetForm(name);
              resetForm(email);
              resetForm(subject);
              resetForm(message);
              setSuccessMessage("Thanks! Your query has been submitted successfully. We will connect you soon.");
              setTimeout(() => {
                setSuccessMessage("");
              }, 10000);
            }
          })
          .catch((error) => {});
      }
    }

    function resetForm(input) {
      const formControl = input.parentElement;
      input.value = "";
      const errorMsg = formControl.querySelector(".contact_input-msg");
      formControl.className = "form-control";
      errorMsg.innerText = "";
    }

    function setError(input, message) {
      const formControl = input.parentElement;
      const errorMsg = formControl.querySelector(".contact_input-msg");
      formControl.className = "form-control text-left error";
      errorMsg.innerText = message;
    }

    function setSuccess(input) {
      const formControl = input.parentElement;
      formControl.className = "form-control success";
    }

    function isEmail(email) {
      return /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(email);
    }
  });

  return (
    <Styles>
      {/* Main Wrapper */}
      <div className="main-wrapper contact-page">
        {/* Header */}
        <HeaderTwo />

        {/* Breadcroumb */}
        {/* <BreadcrumbBox title="Contact Us" /> */}

        {/* Contact Area */}
        <section className="contact-area">
          <Container>
            <Row>
              <Col md="4">
                <div className="contact-box-title">
                  <h4>Contact Info</h4>
                </div>
                <div className="contact-icon-box d-flex">
                  <div className="icon">
                    <i className="las la-map-marker"></i>
                  </div>
                  <div className="box-content">
                    <h5>Our Location</h5>
                    <p>A-15, Sector 3, Noida, Uttar Pradesh, India.</p>
                  </div>
                </div>
                <div className="contact-icon-box d-flex">
                  <div className="icon">
                    <i className="las la-envelope-open"></i>
                  </div>
                  <div className="box-content">
                    <h5>Email Address</h5>
                    <p>info@ealyss.com</p>
                  </div>
                </div>
                <div className="contact-icon-box d-flex">
                  <div className="icon">
                    <i className="las la-phone"></i>
                  </div>
                  <div className="box-content">
                    <h5>Phone Number</h5>
                    <p>+91-989-1629-026</p>
                  </div>
                </div>
                <div className="contact-social">
                  <ul className="social list-unstyled list-inline">
                    <li className="list-inline-item">
                      <a href={process.env.PUBLIC_URL + "/"}>
                        <i className="fab fa-facebook-f"></i>
                      </a>
                    </li>
                    <li className="list-inline-item">
                      <a href={process.env.PUBLIC_URL + "/"}>
                        <i className="fab fa-twitter"></i>
                      </a>
                    </li>
                    <li className="list-inline-item">
                      <a href={process.env.PUBLIC_URL + "/"}>
                        <i className="fab fa-linkedin-in"></i>
                      </a>
                    </li>
                    <li className="list-inline-item">
                      <a href={process.env.PUBLIC_URL + "/"}>
                        <i className="fab fa-youtube"></i>
                      </a>
                    </li>
                    <li className="list-inline-item">
                      <a href={process.env.PUBLIC_URL + "/"}>
                        <i className="fab fa-dribbble"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </Col>
              <Col md="8">
                <div className="contact-form">
                  <div className="form-title">
                    <h4>Get In Touch</h4>
                  </div>
                  <div className="form-box">
                    <form id="form_contact" className="form">
                      <Row>
                        <Col md="6">
                          <div className="form-control">
                            <input type="text" placeholder="Full Name" id="contact_name" />
                            <span className="contact_input-msg"></span>
                          </div>
                        </Col>
                        <Col md="6">
                          <div className="form-control">
                            <input type="email" placeholder="Email Address" id="contact_email" />
                            <span className="contact_input-msg"></span>
                          </div>
                        </Col>
                        <Col md="12">
                          <div className="form-control">
                            <input type="text" placeholder="Subject" id="contact_subject" />
                            <span className="contact_input-msg"></span>
                          </div>
                        </Col>
                        <Col md="12">
                          <div className="form-control">
                            <textarea name="message" id="contact_message" placeholder="Enter Message"></textarea>
                            <span className="contact_input-msg"></span>
                          </div>
                        </Col>
                        <Col md="12">
                          <button>Send Message</button>
                        </Col>
                        {successMessage !== "" && (
                          <Col md="12" className="mt-3">
                            <Alert variant="success">{successMessage}</Alert>
                          </Col>
                        )}
                      </Row>
                    </form>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>

          {/* Google Map */}
          {/* <GoogleMap /> */}
        </section>

        {/* Footer 2 */}
        <FooterTwo />
      </div>
    </Styles>
  );
}

const mapStateToProps = (state) => ({
  contact: state.contact.data.contact,
  showLoader: state.user.showLoader,
});

const mapDispatchToProps = (dispatch) => ({
  submitContactForm: (data) => dispatch(submitContactFormAction(data)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Contact));
