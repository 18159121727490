import React from "react";
import { Card, CardContent, Typography, Button } from "@mui/material";
import { Styles } from "./styles/styles";

function OlympiadThankyou(props) {
  function gotoHome(e) {
    e.preventDefault();
    window.open("https://ealyss.com", "_parent");
  }
  return (
    <Styles>
      <div className="makeStyles-root-2 makeStyles-root-3 makeStyles-root-1 container w-full flex overflow-hidden">
        <div className="flex flex-auto min-w-0">
          <div className="flex flex-auto min-h-screen min-w-0 relative z-10">
            <div className="flex flex-auto min-h-0 relative z-10">
              <div className={"makeStyles-root-19 w-full flex flex-auto items-center justify-center flex-shrink-0 p-16 p-24"}>
                <div className="flex w-full max-w-400 max-w-3xl rounded-20 shadow-2xl overflow-hidden">
                  <Card className={"flex  w-full items-center justify-center shadow-0"} square>
                    <CardContent className="flex justify-center w-full py-96" style={{ padding: "3.6rem" }}>
                      <div>
                        <div style={{ textAlign: "center" }}>
                          <img className="logo-icon w-120" src="/assets/images/logo2.webp" alt="logo" style={{ maxWidth: "250px" }} />
                        </div>
                        <div
                          className="mb-48"
                          style={{
                            padding: "32px",
                            textAlign: "center",
                          }}
                        >
                          <div>
                            <Typography
                              variant="h3"
                              color="inherit"
                              className="leading-tight"
                              style={{
                                fontWeight: "300",
                                fontSize: "30px",
                              }}
                            >
                              Thank You for your participation in Alyss Olympiad Program. <br />
                              Your response has been submitted successfully.
                            </Typography>
                          </div>
                          <div>
                            <Typography
                              variant="subtitle1"
                              color="inherit"
                              className="mt-32"
                              sx={{
                                fontWeight: "400",
                                fontSize: "20px",
                                color: "",
                              }}
                            >
                              On <strong>February 18, 2022</strong> Alyss shall announce the results and the winners.
                            </Typography>
                          </div>
                          <Button
                            type="button"
                            variant="contained"
                            color="primary"
                            className="MuiButton-root-197 MuiButton-containedPrimary-206 w-full mx-auto mt-16"
                            aria-label="LOG IN"
                            value="legacy"
                            style={{ maxWidth: "375px", marginTop: "57px" }}
                            onClick={gotoHome}
                          >
                            Go to Home
                          </Button>
                        </div>
                      </div>
                    </CardContent>
                  </Card>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Styles>
  );
}

export default OlympiadThankyou;
