import { doGetRequest } from "../../shared/api/base-api";
import common from "../../shared/common";
import { apiUrl } from "../../shared/constants";

//chapter theory data api
export function getChapterTheory(data) {
  return doGetRequest(common.stringformat(apiUrl.chapterTheory, data));
}

//chapter exercises data api
export function getChapterExercises(data) {
  return doGetRequest(common.stringformat(apiUrl.chapterExercises, data));
}

//chapter video data api
export function getChapterVideo(data) {
  return doGetRequest(common.stringformat(apiUrl.chapterVideo, data));
}

//module theory data api
export function getModuleTheory(data) {
  return doGetRequest(common.stringformat(apiUrl.moduleTheory, data));
}

//module concept data api
export function getModuleConcept(data) {
  return doGetRequest(common.stringformat(apiUrl.moduleConcept, data));
}

//module programming data api
export function getModuleProgramming(data) {
  return doGetRequest(common.stringformat(apiUrl.moduleProgramming, data));
}

//module hardware building data api
export function getModuleHardwareBuilding(data) {
  return doGetRequest(common.stringformat(apiUrl.moduleHardwareBuilding, data));
}

//module software building data api
export function getModuleSoftwareBuilding(data) {
  return doGetRequest(common.stringformat(apiUrl.moduleSoftwareBuilding, data));
}

//module core fundamentals data api
export function getModuleCoreFundamentals(data) {
  return doGetRequest(common.stringformat(apiUrl.moduleCoreFundamentals, data));
}

//module software setup data api
export function getModuleSoftwareSetup(data) {
  return doGetRequest(common.stringformat(apiUrl.moduleSoftwareSetup, data));
}
