import React from "react";
import Carousel from "react-material-ui-carousel";
import { Container } from "react-bootstrap";
import { Button, Grid, List, ListItem, ListItemIcon, ListItemText, Typography, Box } from "@mui/material";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import { Styles } from "./styles/homeCarousel";
import { webUrl } from "../shared/constants";

function srcset(image, width, height, rows = 1, cols = 1) {
  return {
    src: `${image}?w=${width * cols}&h=${height * rows}&fit=crop&auto=format`,
    srcSet: `${image}?w=${width * cols}&h=${height * rows}&fit=crop&auto=format&dpr=2 2x`,
  };
}

function Item(props) {
  return (
    <Box className={`${props.item.titleClass} home-carousel-item`}>
      <div className={`${props.item.backgroundClass} bg-overlay`} style={{ backgroundSize: "cover", backgroundPosition: "center center" }}></div>
      <Container>
        <Grid container spacing={0} py={3} direction="row" justifyContent="flex-end" alignItems="center">
          <Grid item xs={12} md={7} lg={6}>
            <h2 style={{ fontSize: "30px", fontWeight: "800" }} dangerouslySetInnerHTML={{ __html: props.item.name }}></h2>
            <p style={{ fontSize: "23px", fontWeight: "400", padding: "5px 0" }}>{props.item.description}</p>
            <h4 dangerouslySetInnerHTML={{ __html: props.item.header3 }}></h4>
            <h3>{props.item.list.title}</h3>
            <List dense>
              {props.item.list.items.map((item, i) => (
                <ListItem key={i}>
                  <ListItemIcon sx={{ justifyContent: "center" }}>
                    <CheckCircleOutlineOutlinedIcon className={props.item.titleClass} sx={{ height: "20px", width: "20px" }} />
                  </ListItemIcon>
                  <ListItemText
                    disableTypography
                    primary={
                      <Typography type="body2" className={props.item.titleClass} style={{ fontSize: "16px", fontWeight: "400" }}>
                        {item}
                      </Typography>
                    }
                  />
                </ListItem>
              ))}
            </List>
            <Button
              variant="contained"
              style={{
                background: props.item.button.color,
                margin: "10px 0 20px 0",
                color: "#fff",
                width: "200px",
                fontSize: "18px",
              }}
              className={"transform-button"}
              onClick={(e) => {
                e.preventDefault();
                props.redirectTo(props.item.button.link);
              }}
            >
              {props.item.button.title}
            </Button>
          </Grid>
          <Grid
            item
            xs={12}
            md={5}
            lg={6}
            sx={
              {
                // whiteSpace: "nowrap", textAlign: "center", justifyContent: "center", position: "relative"
              }
            }
          >
            <div className="home-img">
              {props.item.imagePath !== "" && (
                <img {...srcset(props.item.imagePath, 250, 200)} alt={props.item.name} className="mover-image img-fluid" />
              )}
            </div>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default function HomeCarousel(props) {
  var items = [
    {
      name: "Alyss - Igniting Curiosity, Fostering Future Scientists",
      description: "Fall in love with Science and Maths",
      imagePath: "",
      backgroundClass: "study-material",
      bgOverlayColor: "radial-gradient(circle at 51% 93%,#264146,#030d16 69%)",
      header3: "",
      list: {
        title: "",
        items: [
          "Holistic learning with high quality animation videos, theory and sample papers, live sessions",
          "Ignites curiosity",
          "Enhances scientific temperament",
          "Strengthens fundamentals",
        ],
      },
      titleClass: "white",
      button: {
        display: true,
        title: "Explore",
        link: webUrl.studyMaterial,
        color: "linear-gradient(70deg, rgb(234 135 51), rgb(254 113 96))",
        hoverColor: "linear-gradient(315deg, #ffffff 0%, #d7e1ec 74%)",
      },
    },
    {
      name: "Alyss - Your Child's Partner in STEM Learning",
      description: "FUN with STEM - Alyss Robotics program",
      imagePath: "",
      backgroundClass: "stem-robotics",
      header3: "",
      list: {
        title: "",
        items: ["Problem solving skills", "Logical thinking", "Creativity", "Scientific temperament"],
      },
      titleClass: "white",
      button: {
        display: true,
        title: "Explore",
        link: webUrl.robotic,
        color: "linear-gradient(70deg, rgb(234 135 51), rgb(254 113 96))",
        hoverColor: "linear-gradient(315deg, #ffffff 0%, #d7e1ec 74%)",
      },
    },
    // {
    //   name: "Alyss - Partner Program",
    //   description: "Are you a tuition centre, a teacher or an individual looking to be an edupreneur?",
    //   imagePath: "",
    //   backgroundClass: "partner-program-banner",
    //   header3: "",
    //   list: {
    //     title: "",
    //     items: [
    //       "Digital marketing / advertising support",
    //       "Product training",
    //       "Customer support",
    //       "Personal dashboard for licences",
    //       "Revenue sharing for authentic free registrations",
    //     ],
    //   },
    //   titleClass: "white",
    //   button: {
    //     display: true,
    //     title: "Explore",
    //     link: webUrl.teacher,
    //     color: "linear-gradient(315deg, #ffffff 0%, #d7e1ec 74%)",
    //     hoverColor: "linear-gradient(315deg, #ffffff 0%, #d7e1ec 74%)",
    //   },
    // },
  ];

  setTimeout(() => {
    window.dispatchEvent(new Event("resize"));
  }, 2500);

  return (
    <Styles>
      <Carousel
        indicators={false}
        duration={300}
        interval={5000}
        fullHeightHover={true}
        changeOnFirstRender={false}
        className={"home-carousel carousel-content"}
      >
        {items.map((item, i) => (
          <Item key={i} item={item} {...props} />
        ))}
      </Carousel>
    </Styles>
  );
}
