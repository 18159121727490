import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Styles } from "./styles/internshipForm.js";
import common from '../shared/common.js';
import { webUrl } from '../shared/constants.js';
import User from '../pages/user/index.js';

const InternshipForm = (props) => {

    const idPref = Math.random();
    const onClickJoinNow = () => {
        if (!!common.getFromStorage("userName")) {
        props.history.push(webUrl.userDashboard);
        }
    };

    return (
            <Styles>
                <section className="internship-form-area py-5">
                    <Container>
                        <Row>
                            <Col md="12" className='intership-form-bg'>
                                <div className="sec-title text-center">
                                    <h4 className='top-title'>Get hands-on experience and transform your passion for Robotics into a career</h4>
                                    <h3 className='expert-talk'>TALK TO OUR EXPERT</h3>
                                    <button className="btn btn-primary connect-now" id={"hero-btn" + idPref} onClick={onClickJoinNow}>Connect Now</button>
                                    <User openCommand={true} commandCtrlId={"hero-btn" + idPref}  />
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </Styles>
    );
        
}

export default InternshipForm
