import React from 'react';

function SuccessMessage(props) {

    return (
        <>
            <div className="side-content">
                <h5>Thanks! </h5>
                <p>Thanks for your interest. We will connect you shortly</p>
            </div>
        </>
    )
}

export default SuccessMessage
