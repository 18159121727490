import React from "react";
import { Row, Col } from "react-bootstrap";
import ReactHtmlParser from "react-html-parser";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  color: theme.palette.text.secondary,
}));

function NcertExercise(props) {
  return (
    <>
      <Row className="margin-bottom-15">
        <Col md="12">
          <h5 className="grid-header ml-0">NCERT Exercises</h5>
        </Col>
      </Row>
      <Row>
        <Col md="12">
          <div className="course-desc">
            {props.chapter.ncert_exercises.map((data, i) => (
              <div className="event-box" key={i}>
                <Row>
                  <Col xl="12" lg="12" md="12">
                    <div className="event-content">
                      <Row>
                        <Box sx={{ flexGrow: 1 }}>
                          <Grid container spacing={0}>
                            <Grid item xs={12}>
                              <Item className="ncert-exercises">
                                <CardContent>
                                  <Typography sx={{ fontSize: 14 }} gutterBottom>
                                    {ReactHtmlParser(data.question_text)}
                                  </Typography>
                                  <Typography sx={{ fontSize: 14 }} gutterBottom>
                                    {ReactHtmlParser(data.solution_text)}
                                  </Typography>
                                </CardContent>
                              </Item>
                            </Grid>
                          </Grid>
                        </Box>
                      </Row>
                    </div>
                  </Col>
                </Row>
              </div>
            ))}
          </div>
        </Col>
      </Row>
    </>
  );
}

export default NcertExercise;
