import React, { Component, Fragment } from "react";
import Datas from "../../../data/course/item.json";
import { Styles } from "../styles/tests_list.js";
import { Col, Row } from "react-bootstrap";
import { apiUrl, webUrl } from "../../../shared/constants";
import common from "../../../shared/common";
import { Button, Link } from "@mui/material";

const TestItemGrid = (props) => {
  return (
    <Styles>
      <Fragment>
        {/* Course Item */}

        <Row>
          <Col lg="9" md="9" sm="9">
            <h5 className="grid-header ml-0">{props.headerText}</h5>
          </Col>
          <Col lg="3" md="3" sm="3" className="teacher-kit m-0">
            <div className="course-items">
              <Button
                size="large"
                variant="contained"
                onClick={(e) => {
                  e.preventDefault();
                  props.history.push(webUrl.generateNewTest);
                }}
                className="orange-bg mb-4"
              >
                Generate New Test
              </Button>
            </div>
          </Col>
        </Row>

        <Row>
          {(props.data &&
            props.data.length > 0 &&
            props.data.map((data, i) => (
              <Col lg="12" md="12" key={i}>
                <div className="blog-content">
                  <div className="content-box">
                    <div className="top-content d-flex">
                      <div className="blog-title">
                        <h6>{data.test_name}</h6>
                      </div>
                    </div>
                    <div className="blog-desk">
                      <p
                        style={{
                          fontWeight: "bold",
                          fontSize: "19px",
                          margin: "0",
                          padding: "0",
                        }}
                      >
                        {data.subsubcategory.subcategory.category.name} / {data.subsubcategory.subcategory.name} / {data.subsubcategory.name}
                      </p>
                      <p
                        style={{
                          fontSize: "16px",
                          margin: "0",
                          padding: "0",
                        }}
                      >
                        Generated On : {common.getISTFormattedDateTime(data.created_at)}
                      </p>
                      <p
                        style={{
                          fontSize: "16px",
                          margin: "0",
                          padding: "0",
                        }}
                      >
                        Number of Sets : {data.sets_count}
                      </p>
                      <p
                        style={{
                          fontSize: "16px",
                          margin: "0",
                          padding: "0",
                        }}
                      >
                        Maximum Marks : {data.total_marks}
                      </p>
                      <p
                        style={{
                          fontSize: "16px",
                          margin: "0",
                          padding: "0",
                        }}
                      >
                        {data.one_mark_count > 0 && `${data.one_mark_count} one mark`}
                        {data.two_marks_count > 0 && `, ${data.two_marks_count} two marks`}
                        {data.three_marks_count > 0 && `, ${data.three_marks_count} three marks`}
                        {data.five_marks_count > 0 && `, ${data.five_marks_count} five marks`} questions
                      </p>
                      {data.generated_test_sets.map((set) => (
                        <Row className="border-top">
                          <Col md="4">
                            <h6 className="text-center">Set {set.set_number}</h6>
                          </Col>
                          <Col md="4">
                            <Button
                              variant="contained"
                              className="orange-bg"
                              size="large"
                              onClick={(e) => {
                                e.preventDefault();
                                window.open(`${apiUrl.baseApiUrl}${apiUrl.downloadQuestionPaper}/${set.id}`);
                              }}
                            >
                              Download Test Paper
                            </Button>
                          </Col>
                          <Col md="4">
                            <Button
                              variant="contained"
                              className="orange-bg"
                              size="large"
                              onClick={(e) => {
                                e.preventDefault();
                                window.open(`${apiUrl.baseApiUrl}${apiUrl.downloadSolution}/${set.id}`);
                              }}
                            >
                              Download Solution
                            </Button>
                          </Col>
                        </Row>
                      ))}
                    </div>
                  </div>
                </div>
              </Col>
            ))) || (
            <Col lg="4" md="6">
              <p className="not-found">{props.notFoundText}</p>
            </Col>
          )}
        </Row>
      </Fragment>
    </Styles>
  );
};

export default TestItemGrid;
