import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Styles } from "../styles/userConnect.js";
import PhoneFactor from "./PhoneFactor.js";
import SuccessMessage from "./SuccessMessage.js";

function UserConnect(props) {
  const [step, setStep] = useState(0);

  useEffect(() => {
    const sidebarBtn = props.openCommand ? document.getElementById(props.commandCtrlId) : document.getElementById("sidebar-btn");

    if (sidebarBtn) {
      const sidebarOverlay = document.getElementById("sidebar-connect-overlay");
      const sidebarBody = document.getElementById("sidebar-connect-body");
      const sidebarExit = document.getElementById("close-sidebar-connect");

      sidebarBtn.addEventListener("click", function (e) {
        e.preventDefault();
        sidebarOverlay.classList.add("visible");
        sidebarBody.classList.add("opened");
        resetForm();
      });

      sidebarOverlay.addEventListener("click", function (e) {
        e.preventDefault();
        sidebarOverlay.classList.remove("visible");
        sidebarBody.classList.remove("opened");
        resetForm();
      });

      sidebarExit.addEventListener("click", function (e) {
        e.preventDefault();
        sidebarOverlay.classList.remove("visible");
        sidebarBody.classList.remove("opened");
        resetForm();
      });
    }
  });

  const resetForm = () => {
    setStep(0);
    resetError("mobileConnect");
    resetError("name");
    document.getElementById("name").value = "";
  };

  const resetError = (input) => {
    let formControl;
    if (input === "mobileConnect") {
      formControl = document.getElementsByName(input)[0].parentElement.parentElement;
    } else {
      formControl = document.getElementsByName(input)[0].parentElement;
    }
    const errorMsg = formControl.querySelector(".login_input-msg");
    formControl.classList.remove("error");
    formControl.classList.remove("text-left");
    errorMsg.innerText = "";
  };

  return (
    <Styles>
      {/* Sidebar */}
      {props.openCommand ? (
        ""
      ) : (
        <a href={"#!"} id="sidebar-btn">
          <i className="las la-user"></i>
          {props && props.customText}
        </a>
      )}

      <div className="sidebar-connect" id="sidebar-connect-body">
        <div className="side-logo d-flex justify-content-between">
          <div>
            <Link to={process.env.PUBLIC_URL + "/"}>
              <img src={process.env.PUBLIC_URL + "/assets/images/logo2.webp"} alt="" />
            </Link>
          </div>
          <div>
            <a href={process.env.PUBLIC_URL + "/"} id="close-sidebar-connect">
              <i className="las la-times"></i>
            </a>
          </div>
        </div>

        {step === 0 && <PhoneFactor {...props} setStep={(key) => setStep(step + key)} />}
        {step === 1 && <SuccessMessage />}
      </div>

      <div className="sidebar-connect-overlay" id="sidebar-connect-overlay"></div>
    </Styles>
  );
}

export default UserConnect;
