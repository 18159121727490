import { actionTypes } from "./action";

/** Initial state */
const initialState = {
  data: {
    masterData: {},
  },
  error: null,
  showLoader: [],
};

/** Reducers */
export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case actionTypes.PARTNER_LOGIN_REQUEST_SUCCESS:
      return {
        ...state,
        data: { ...state.data, login: payload },
        error: null,
      };

    case actionTypes.VERIFY_OTP_REQUEST_SUCCESS:
      return {
        ...state,
        data: { ...state.data, profile: payload.data.user },
        error: null,
      };

    case actionTypes.UPDATE_PROFILE_REQUEST_SUCCESS:
      return {
        ...state,
        data: { ...state.data, profile: payload.data.user },
        error: null,
      };

    case actionTypes.PROFILE_MASTER_DATA_REQUEST_SUCCESS:
      return {
        ...state,
        data: { ...state.data, masterData: payload },
        error: null,
      };

    case actionTypes.SHOW_LOADER:
      return {
        ...state,
        showLoader: [...state.showLoader, payload],
      };

    case actionTypes.STOP_LOADER:
      return {
        ...state,
        showLoader: state.showLoader.filter((value) => value !== payload),
      };

    case actionTypes.UNMOUNT_USER:
      return {
        ...initialState,
      };

    default:
      return state;
  }
};
