import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import { Styles } from "./styles/stickyMenu.js";
import { webUrl } from "../../shared/constants.js";

function StickyMenu() {
  useEffect(() => {
    window.addEventListener("scroll", () => {
      const stickyMenu = document.querySelector(".sticky-menu");
      if (stickyMenu) {
        if (window.scrollY > 160) {
          stickyMenu.classList.add("sticky");
        } else {
          stickyMenu.classList.remove("sticky");
        }
      }
    });
  });

  return (
    <Styles>
      {/* Sticky Menu */}
      <section className="sticky-menu">
        <Container>
          <Row>
            <Col md="2">
              <div className="logo">
                <Link to={process.env.PUBLIC_URL + "/"}>
                  <img src={process.env.PUBLIC_URL + "/assets/images/logo2.webp"} alt="" />
                </Link>
              </div>
            </Col>
            <Col md="10">
              <div className="menu-box d-flex justify-content-center">
                <ul className="nav menu-nav">
                  <li className="nav-item dropdown active">
                    <Link className="nav-link dropdown-toggle" to={process.env.PUBLIC_URL + webUrl.studyMaterial} data-toggle="dropdown">
                      Study Material
                    </Link>
                  </li>
                  <li className="nav-item dropdown">
                    <Link className="nav-link dropdown-toggle" to={process.env.PUBLIC_URL + webUrl.robotic} data-toggle="dropdown">
                      Robotics
                    </Link>
                  </li>
                  <li className="nav-item dropdown">
                    <Link className="nav-link dropdown-toggle" to={process.env.PUBLIC_URL + webUrl.teacher} data-toggle="dropdown">
                      Teacher Solutions
                    </Link>
                  </li>
                  <li className="nav-item dropdown">
                    <Link className="nav-link dropdown-toggle" to={process.env.PUBLIC_URL + webUrl.examPreparatory} data-toggle="dropdown">
                    Exam Preparatory
                    </Link>
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </Styles>
  );
}

export default StickyMenu;
