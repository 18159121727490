import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Styles } from "../styles/userLogin.js";
import PhoneFactor from "./PhoneFactor.js";
import UserProfile from "./UserProfile.js";
import VerifyOTP from "./VerifyOTP.js";
import CloseIcon from "@mui/icons-material/Close";
import EmailFactor from "./EmailFactor.js";
import VerifyPassword from "./VerifyPassword.js";
import VerifyEmailOTP from "./VerifyEmailOTP.js";
import ForgotPassword from "./ForgotPassword.js";
import VerifyResetPasswordOTP from "./VerifyResetPasswordOTP.js";
import ChangePassword from "./ChangePassword.js";

function UserLogin(props) {
  const currentStep = typeof props.step !== "undefined" ? props.step : 3;
  const [step, setStep] = useState(currentStep);
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [country, setCountry] = useState("+91");
  const [registeredBy, setRegisteredBy] = useState(1);
  const [user, setUser] = useState({});

  useEffect(() => {
    const sidebarBtn = props.openCommand ? document.getElementById(props.commandCtrlId) : document.getElementById("sidebar-btn");

    if (sidebarBtn) {
      const sidebarOverlay = document.getElementById("sidebar-overlay");
      const sidebarBody = document.getElementById("sidebar-body");
      const sidebarExit = document.getElementById("close-sidebar");

      sidebarBtn.addEventListener("click", function (e) {
        e.preventDefault();
        sidebarOverlay.classList.add("visible");
        sidebarBody.classList.add("opened");
        resetForm();
      });

      sidebarOverlay.addEventListener("click", function (e) {
        e.preventDefault();
        sidebarOverlay.classList.remove("visible");
        sidebarBody.classList.remove("opened");
        resetForm();
      });

      sidebarExit.addEventListener("click", function (e) {
        e.preventDefault();
        sidebarOverlay.classList.remove("visible");
        sidebarBody.classList.remove("opened");
        // resetForm();
      });
    }
  });

  const resetForm = () => {
    setStep(currentStep);
    // resetError("mobile");
    // resetError("country_code");
    // document.getElementById("mobile").value = "";
  };

  return (
    <Styles>
      {/* Sidebar */}
      {props.openCommand ? (
        ""
      ) : (
        <a href={"#!"} id="sidebar-btn" className="login-button">
          {props && props.customText}
        </a>
      )}

      <div className="sidebar" id="sidebar-body">
        <div className="side-logo d-flex justify-content-between">
          <div>
            <Link to={process.env.PUBLIC_URL + "/"}>
              <img src={process.env.PUBLIC_URL + "/assets/images/logo2.webp"} alt="" />
            </Link>
          </div>
          <div>
            <a href={process.env.PUBLIC_URL + "/"} id="close-sidebar">
              <CloseIcon />
            </a>
          </div>
        </div>

        {step === 0 && (
          <PhoneFactor
            {...props}
            setStep={(key) => setStep(step + key)}
            setMobile={(number) => setMobile(number)}
            setCountry={(country) => setCountry(country)}
          />
        )}
        {step === 1 && <VerifyOTP {...props} setStep={(key) => setStep(step + key)} mobile={mobile} country={country} />}
        {step === 2 && (
          <UserProfile {...props} setStep={setStep} email={email} mobile={mobile} country={country} registeredBy={registeredBy} user={user} />
        )}
        {step === 3 && <EmailFactor {...props} setStep={setStep} email={email} setEmail={(emailText) => setEmail(emailText)} />}
        {step === 4 && <VerifyPassword {...props} setStep={setStep} email={email} />}
        {step === 5 && <VerifyEmailOTP {...props} setStep={setStep} email={email} setRegisteredBy={setRegisteredBy} setUser={setUser} />}
        {step === 6 && <ForgotPassword {...props} setStep={setStep} email={email} />}
        {step === 7 && <VerifyResetPasswordOTP {...props} setStep={setStep} email={email} />}
        {step === 8 && <ChangePassword {...props} setStep={setStep} email={email} />}
      </div>

      <div className="sidebar-overlay" id="sidebar-overlay"></div>
    </Styles>
  );
}

export default UserLogin;
