import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import LinearProgress from "@mui/material/LinearProgress";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import SquareIcon from "@mui/icons-material/Square";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { Timer } from "@mui/icons-material";
import Alert from "@mui/material/Alert";

import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import FooterTwo from "../../components/FooterTwo";
import UserHeader from "../../components/UserHeader";
import { Container, Row, Col, Form } from "react-bootstrap";
import { Styles } from "../dashboard/styles/dashboard.js";
import { startOlympiadQuizAction, submitOlympiadQuizAction } from "./action";
import { AlertTitle, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid } from "@mui/material";
import { messages, webUrl } from "../../shared/constants";
import MathJax from "mathjax3-react";
import LoaderNew from "../../components/LoaderNew";
// import OlympiadDetails from "./components/OlympiadDetails";

function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ width: "100%", mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  );
}

LinearProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate and buffer variants.
   * Value between 0 and 100.
   */
  value: PropTypes.number.isRequired,
};

const calculateTimeLeft = (startTime, quizTime) => {
  let timeLeft = {};
  if (quizTime) {
    const difference = +new Date(startTime.getTime() + quizTime * 60000) - +new Date();
    

    if (difference >= 0) {
      timeLeft = {
        // days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: String(Math.floor((difference / (1000 * 60 * 60)) % 24)).padStart(2, "0"),
        minutes: String(Math.floor((difference / 1000 / 60) % 60)).padStart(2, "0"),
        seconds: String(Math.floor((difference / 1000) % 60)).padStart(2, "0"),
      };
    }
    
  } else {
    timeLeft = {
      hours: "00",
      minutes: "00",
      seconds: "00",
    };
  }
  return timeLeft;
};

function Olympiad(props) {
  const {
    match: { params },
  } = props;
  // const [progress, setProgress] = useState(0);
  const [open, setOpen] = useState(false);
  const [selectedQuestion, setSelectedQuestion] = useState(1);
  const [skipQuestionsIndex, setSkipQuestionIndex] = useState([]);
  const [attemptQuestionsIndex, setAttemptQuestionIndex] = useState([]);
  const [userResponses, setUserResponses] = useState([]);

  const [startTime, setStartTime] = useState(new Date());
  const [quizTime, setQuizTime] = useState(null);
  const [disableOptions, setDisableOptions] = useState(false);
  const [quizDataLoaded, setQuizDataLoaded] = useState(false);
  const [disablePrevious, setDisablePrevious] = useState(false);
  const [disableNext, setDisableNext] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft(startTime, quizTime));

  const [olympiad_quiz_temp, setOlympiadQuizTemp] = useState({
    olympiadData: {
      id: 1,
      title: "Alyss Science Olympiad",
      fees: 150,
      category_id: 1,
      subcategory_id: 2,
      subsubcategory_id: 1,
      total_marks: 80,
      total_time: 60,
      start_time: "2022-02-06T09:30:00.000Z",
      description: "<p>Test <strong>Description</strong></p>",
      status: 1,
      categoryId: 1,
      subcategoryId: 2,
      subsubcategoryId: 1,
      sections: [
        {
          id: 1,
          olympiad_id: 1,
          title: "Section A - 2 marks questions",
          section_total: 0,
          sort_order: 0,
          status: 1,
          olympiadId: 1,
          questions: [
            {
              id: 1,
              olympiad_section_id: 1,
              question_text: "<p>Test Question 1</p>",
              option1: "<p>Option 1</p>",
              option2: "<p>Option 2</p>",
              option3: "<p>option 3</p>",
              option4: "<p>Option 4</p>",
              correct_option: 3,
              status: 1,
              sort_order: 1,
              marks: 2,
              hardness_level: 2,
              olympiadSectionId: 1,
            },
          ],
        },
      ],
      questions: [
        {
          id: 1,
          olympiad_section_id: 1,
          question_text: "<p>Test Question 1</p>",
          option1: "<p>Option 1</p>",
          option2: "<p>Option 2</p>",
          option3: "<p>option 3</p>",
          option4: "<p>Option 4</p>",
          correct_option: 3,
          status: 1,
          sort_order: 1,
          marks: 2,
          hardness_level: 2,
          olympiadSectionId: 1,
        },
      ],
    },
    olympiadParticipantTest: {
      id: 3,
      olympiad_participant_id: 1,
      test_code: "T8CPK1EETXBN8IR",
      total_questions: 1,
      start_at: "2022-02-04T15:57:06.000Z",
      submit_at: null,
      total_seconds: null,
      total_attempted: null,
      total_unattempted: null,
      total_correct: null,
      total_incorrect: null,
      total_score: null,
      percentile: null,
      status: 1,
      participantId: null,
      responses: [
        {
          id: 1,
          olympiad_participant_test_id: 3,
          olympiad_section_question_id: 1,
          participant_response: null,
          correct_response: 3,
          status: 1,
          testId: null,
          questionId: null,
        },
      ],
      can_user_access: 1,
      is_package_expired: 0,
    },
  });

  useEffect(() => {
    props.startOlympiadQuiz({ quiz_id: params.type }); // To do: params.type should be default one
    setQuizDataLoaded(false);
  }, []);

  const selectedQuestionActions = (selectedId) => {
    setDisablePrevious(false);
    setDisableNext(false);
    switch (+selectedId) {
      case 0:
        setDisablePrevious(true);
        break;
      case olympiad_quiz_temp.olympiadData.questions.length - 1:
        setDisableNext(true);
        break;
      default:
        break;
    }
  };

  if (!quizDataLoaded) {
    if (props.olympiad_quiz) {
      setOlympiadQuizTemp(props.olympiad_quiz);
      setQuizTime(props.olympiad_quiz.olympiadData.total_time);
      setStartTime(new Date(props.olympiad_quiz.olympiadData.start_time));
      setQuizDataLoaded(true);
      selectedQuestionActions(0);
    }
  }

  const handleSelectedQuestion = (e) => {
    setSelectedQuestion(+e.target.value + 1);
    selectedQuestionActions(e.target.value);
  };

  const handleSkipQuestion = () => {
    if (skipQuestionsIndex.indexOf(selectedQuestion) === -1) {
      setSkipQuestionIndex([...skipQuestionsIndex, selectedQuestion]);
      if (attemptQuestionsIndex.indexOf(selectedQuestion) !== -1) {
        let newAttemptedQuestionIndex = [...attemptQuestionsIndex];
        newAttemptedQuestionIndex.splice(attemptQuestionsIndex.indexOf(selectedQuestion), 1);
        setAttemptQuestionIndex(newAttemptedQuestionIndex);
        const question_id = olympiad_quiz_temp.olympiadData.questions[selectedQuestion - 1].id;
        const checkResponses = userResponses.filter((questionObj) => {
          if (+questionObj.question_id !== +question_id) {
            return questionObj;
          }
        });
        setUserResponses(checkResponses);
      }
    }
    if (selectedQuestion === olympiad_quiz_temp.olympiadData.questions.length) {
      setSelectedQuestion(1);
      selectedQuestionActions(0);
    } else {
      setSelectedQuestion(selectedQuestion + 1);
      selectedQuestionActions(selectedQuestion);
    }
  };

  const handlePreviousQuestion = () => {
    if (selectedQuestion === 2) {
      setSelectedQuestion(1);
      selectedQuestionActions(0);
    } else {
      selectedQuestionActions(selectedQuestion - 2);
      setSelectedQuestion(selectedQuestion - 1);
    }
  };

  const handleNextQuestion = () => {
    if (selectedQuestion === olympiad_quiz_temp.olympiadData.questions.length) {
      setSelectedQuestion(1);
      selectedQuestionActions(0);
    } else {
      setSelectedQuestion(selectedQuestion + 1);
      selectedQuestionActions(selectedQuestion);
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const submitOlympiadQuiz = () => {
    const difference = +new Date() - +new Date(startTime.getTime());
    let response_time = Math.round(difference / 1000);
    if (difference > +quizTime * 60000) {
      response_time = +quizTime * 60;
    }
    const formSubmitData = {
      olympiad_participant_test_id: olympiad_quiz_temp.olympiadParticipantTest.id,
      member_responses: [...userResponses],
      response_time,
    };
    if (navigator.onLine) {
      setIsSubmitted(true);
      props.submitOlympiadQuiz(formSubmitData).then((response) => {
        handleClose();
        props.history.replace(webUrl.olympiadThankyou);
      });  
    } else {
      alert(messages.noInternetConnection);
    };
    
  };

  const timerTimeout = () => {
    setIsSubmitted(true);
    setDisableOptions(true);
    submitOlympiadQuiz();
  }

  const handleSubmitQuiz = (e) => {
    e.preventDefault();
    submitOlympiadQuiz();
  };

  const handleQuizOptionChange = (e) => {
    let question_id = e.target.name.replace("radio-buttons-group-", "");
    let member_response = e.target.value;
    let checkResponses = userResponses.filter((questionObj) => {
      if (+questionObj.question_id === +question_id) {
        return questionObj;
      }
    });
    if (attemptQuestionsIndex.indexOf(selectedQuestion) === -1) {
      setAttemptQuestionIndex([...attemptQuestionsIndex, selectedQuestion]);
      if (skipQuestionsIndex.indexOf(selectedQuestion) !== -1) {
        let newSkipQuestionIndex = [...skipQuestionsIndex];
        newSkipQuestionIndex.splice(skipQuestionsIndex.indexOf(selectedQuestion), 1);
        setSkipQuestionIndex(newSkipQuestionIndex);
      }
    }
    if (checkResponses.length === 0) {
      setUserResponses([...userResponses, { question_id: +question_id, member_response: +member_response }]);
    } else {
      let newResponse = userResponses.map((questionObj) => {
        if (+questionObj.question_id === +question_id) {
          return { ...questionObj, member_response: +member_response };
        }
        return questionObj;
      });
      setUserResponses(newResponse);
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft(startTime, quizTime));
    }, 1000);

    return () => clearTimeout(timer);
  });

  const timerComponents = [];
  if (timeLeft && Object.keys(timeLeft).length > 0) {
    timerComponents.push(timeLeft['hours'] + ':' + timeLeft['minutes'] + ':' + timeLeft['seconds']);
  }
  let firstSection = null;

  if (props.showLoader && (props.showLoader.length > 0)) {
    return (<LoaderNew />)
  }

  return (
    <div className="main-wrapper course-details-page">
      {/* Header */}
      <Styles>
        {/* Course Grid */}
        <section className="dashboard-grid-area olympiad-quiz">
          <Container>
            <Row>
              <Col lg="12">
                <h3 style={{
                  padding: "10px", textAlign: "center"
                }}>{olympiad_quiz_temp.olympiadData.title}</h3>
              </Col>
            </Row>
            <Row>
              <Col lg="12">
                <div
                  style={{
                    padding: "10px",
                    marginTop: "10px",
                    textAlign: "center",
                    border: "1px solid #ccc",
                    borderRadius: "5px",
                  }}
                >
                  <Typography variant="h3" gutterBottom component="div" style={{ marginBottom: "0" }}>
                    <Timer
                      style={{
                        fontSize: "34px",
                        marginRight: "7px",
                        marginTop: "-7px",
                      }}
                    />
                    {timerComponents.length ? timerComponents : (!isSubmitted && timerTimeout())}
                  </Typography>
                </div>
              </Col>
            </Row>
            <Row>

              <Col>
                <Row>
                  <Col lg="12" md="12" sm="12">
                    <Form onSubmit={handleClickOpen}>
                      {olympiad_quiz_temp.olympiadData.questions &&
                        olympiad_quiz_temp.olympiadData.questions.map((question, i) => {
                          let selectedVariant = selectedQuestion === i + 1 ? "block" : "none";
                          const checkResponses = userResponses.filter((questionObj) => {
                            if (+questionObj.question_id === +question.id) {
                              return questionObj;
                            }
                          });
                          let selectedOptionValue = checkResponses.length > 0 ? checkResponses[0].member_response : "";
                          return (
                            <div
                              style={{
                                display: `${selectedVariant}`,
                                padding: "20px 20px 5px",
                                border: "1px solid #ccc",
                                borderRadius: "5px",
                                marginTop: "10px",
                              }}
                              key={question.id}
                            >
                              <Row>
                                <Col lg="12" md="12" sm="12">
                                  <Typography variant="h5" gutterBottom className="quiz" component="div">
                                    Question {i + 1}
                                    <span style={{ float: "right" }}>{question.marks} {(question.marks > 1) ? ' Marks' : 'Mark'}</span>
                                  </Typography>
                                </Col>
                              </Row>
                              <Row>
                                <Col lg="12" md="12" sm="12">
                                  <FormControl component="fieldset" key={question.id}>
                                    <FormLabel component="legend">
                                      <Typography variant="h2" className="quiz" gutterBottom component="div">
                                        <MathJax.Provider>
                                          <MathJax.Html html={question.question_text} />
                                        </MathJax.Provider>
                                      </Typography>
                                    </FormLabel>
                                    <RadioGroup
                                      name={`radio-buttons-group-${question.id}`}
                                      key={question.id}
                                      value={selectedOptionValue}
                                      onChange={handleQuizOptionChange}
                                    >
                                      <FormControlLabel
                                        value={1}
                                        disabled={disableOptions}
                                        className="quiz"
                                        control={<Radio />}
                                        label={
                                          <Typography variant="h2" gutterBottom component="div">
                                            <MathJax.Provider>
                                              <MathJax.Html html={question.option1} />
                                            </MathJax.Provider>
                                          </Typography>
                                        }
                                      />
                                      <FormControlLabel
                                        value={2}
                                        disabled={disableOptions}
                                        className="quiz"
                                        control={<Radio />}
                                        label={
                                          <Typography variant="h2" gutterBottom component="div">
                                            <MathJax.Provider>
                                              <MathJax.Html html={question.option2} />
                                            </MathJax.Provider>
                                          </Typography>
                                        }
                                      />
                                      <FormControlLabel
                                        value={3}
                                        disabled={disableOptions}
                                        className="quiz"
                                        control={<Radio />}
                                        label={
                                          <Typography variant="h2" gutterBottom component="div">
                                            <MathJax.Provider>
                                              <MathJax.Html html={question.option3} />
                                            </MathJax.Provider>
                                          </Typography>
                                        }
                                      />
                                      <FormControlLabel
                                        value={4}
                                        disabled={disableOptions}
                                        className="quiz"
                                        control={<Radio />}
                                        label={
                                          <Typography variant="h2" gutterBottom component="div">
                                            <MathJax.Provider>
                                              <MathJax.Html html={question.option4} />
                                            </MathJax.Provider>
                                          </Typography>
                                        }
                                      />
                                    </RadioGroup>
                                  </FormControl>
                                </Col>
                              </Row>
                            </div>
                          );
                        })}
                    </Form>
                    <Box
                      sx={{ flexGrow: 1 }}
                      style={{
                        padding: "10px",
                        border: "1px solid #ccc",
                        borderRadius: "5px",
                        marginTop: "10px",
                      }}
                    >
                      <Grid container>
                        <Grid item xs={3}>
                          <Button
                            variant={"contained"}
                            disabled={disableOptions}
                            onClick={handleSkipQuestion}
                            style={{
                              margin: "10px",
                              backgroundColor: "yellow",
                              color: "black",
                            }}
                          >
                            Skip
                          </Button>
                        </Grid>
                        <Grid item xs={9} style={{
                          flexDirection: "row-reverse",
                          justifyContent: "space-evenly",
                          alignItems: "baseline",
                          display: "flex"
                        }}>
                          <Button variant={"contained"} onClick={handleClickOpen} style={{ margin: "10px" }}>
                            Submit
                          </Button>
                          <Button
                            variant={"contained"}
                            disabled={disableNext}
                            onClick={handleNextQuestion}
                            style={{ margin: "10px" }}
                          >
                            Next
                          </Button>
                          <Button
                            variant={"contained"}
                            disabled={disablePrevious}
                            onClick={handlePreviousQuestion}
                            style={{ margin: "10px" }}
                          >
                            Previous
                          </Button>
                        </Grid>
                      </Grid>
                    </Box>
                  </Col>
                </Row>
              </Col>
              <Col
                lg="3"
                md="3"
                sm="12"
                style={{
                  padding: "15px",
                  paddingTop: "10px",
                }}
              >
                <div style={{
                  border: "1px solid #ccc",
                  borderRadius: "5px",
                }}>

                  <Typography variant="h5" gutterBottom component="div" style={{ textAlign: "center", marginTop: "10px" }}>
                    Total Questions: {olympiad_quiz_temp.olympiadData.questions.length}
                  </Typography>
                  <Grid
                    container
                    sx={{
                      color: "text.primary",
                      padding: "0 20px",
                      marginBottom: "10px",
                    }}
                  >
                    <Grid item xs={1} direction="row">
                      <SquareIcon sx={{ color: "#1565c0" }} />
                    </Grid>
                    <Grid item xs={5} direction="row">
                      <Typography sx={{ padding: "1px 5px" }}> Attempted</Typography>
                    </Grid>
                    <Grid item xs={1} direction="row">
                      <SquareIcon sx={{ color: "yellow" }} />
                    </Grid>
                    <Grid item xs={5} direction="row">
                      <Typography sx={{ padding: "1px 5px" }}> Skipped</Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    spacing={1}
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    style={{
                      marginTop: "10px",
                      padding: "0px 20px 16px",
                    }}
                  >
                    {olympiad_quiz_temp.olympiadData.questions &&
                      olympiad_quiz_temp.olympiadData.questions.map((question, i) => {
                        let selectedVariant = selectedQuestion === i + 1 ? "contained" : "outlined";
                        let selectedColor = "white";
                        let fontColor = "#1565c0";
                        if (skipQuestionsIndex.indexOf(i + 1) !== -1) {
                          selectedColor = "yellow";
                          fontColor = "black";
                        } else if (attemptQuestionsIndex.indexOf(i + 1) !== -1) {
                          selectedColor = "#1565c0";
                          fontColor = "white";
                        } else if (selectedVariant === "contained") {
                          selectedColor = "#eeeeee";
                          fontColor = "#1565c0";
                        }
                        let sectionName = ''
                        if (firstSection !== question.olympiad_section_id) {
                          firstSection = question.olympiad_section_id;
                          sectionName = olympiad_quiz_temp.olympiadData.sections.filter((section) => {
                            if (firstSection === section.id) {
                              return section
                            }
                          });
                        } else {
                          sectionName = '';
                        }
                        return (
                          <>
                            {sectionName && sectionName !== '' && (
                              <Grid item xs={12} alignItems={"center"} justifyContent="center" key={sectionName[0].id}>
                                <Typography variant="h5" gutterBottom component="div" style={{ textAlign: "center", marginTop: "10px" }}>
                                  {sectionName[0].title}
                                </Typography>
                              </Grid>
                            )}
                            <Grid item xs={2.4} alignItems={"center"} justifyContent="center" sx={{ display: "flex", flexDirection: "column" }}>
                              <Button
                                variant={selectedVariant}
                                key={question.id}
                                onClick={(e) => handleSelectedQuestion(e)}
                                value={i}
                                style={{
                                  backgroundColor: selectedColor,
                                  color: fontColor,
                                  minWidth: "35px",
                                  padding: "5px"
                                }}
                              >
                                {i + 1}
                              </Button>
                            </Grid>
                          </>
                        );
                      })}
                  </Grid>
                  <Alert
                    severity="warning"
                    style={{
                      marginTop: "10px",
                    }}
                  >
                    <AlertTitle>Disclaimer</AlertTitle>
                    Please do not <strong>Close</strong> or <strong>Refresh</strong> this page, otherwise you cannot attempt this quiz again with this{" "}
                    <strong>ID</strong>
                  </Alert>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </Styles>

      {/* Footer 2 */}
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Submit Quiz</DialogTitle>
        <DialogContent>
          <DialogContentText>Are you sure you want to submit the quiz?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button variant="contained" onClick={handleSubmitQuiz}>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

const mapStateToProps = (state) => ({
  olympiad_quiz: state.olympiad.data.olympiad_quiz,
  submit_quiz: state.olympiad.data.submit_quiz,
  showLoader: state.user.showLoader,
});

const mapDispatchToProps = (dispatch) => ({
  startOlympiadQuiz: (data) => dispatch(startOlympiadQuizAction(data)),
  submitOlympiadQuiz: (data) => dispatch(submitOlympiadQuizAction(data)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Olympiad));
