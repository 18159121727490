import common from "../../shared/common";
import { setDashboardFormDataAction } from "../dashboard/action";
import { partnerLogin, verifyOTP, updateProfile, getProfileData } from "./service";

/**
 * Action types
 */
export const actionTypes = {
  VERIFY_OTP_REQUEST_SUCCESS: "VERIFY_OTP_REQUEST_SUCCESS",
  PARTNER_LOGIN_REQUEST_SUCCESS: "PARTNER_LOGIN_REQUEST_SUCCESS",
  UPDATE_PROFILE_REQUEST_SUCCESS: "UPDATE_PROFILE_REQUEST_SUCCESS",
  PROFILE_MASTER_DATA_REQUEST_SUCCESS: "PROFILE_MASTER_DATA_REQUEST_SUCCESS",
  SHOW_LOADER: "SHOW_LOADER",
  STOP_LOADER: "STOP_LOADER",
  UNMOUNT_USER: "UNMOUNT_USER",
};

export const showLoaderAction = (payload) => ({
  type: actionTypes.SHOW_LOADER,
  payload,
});

export const stopLoaderAction = (payload) => ({
  type: actionTypes.STOP_LOADER,
  payload,
});

export const unmountUserAction = () => ({
  type: actionTypes.UNMOUNT_USER,
});

export const receivedPartnerLoginAction = (payload) => ({
  type: actionTypes.PARTNER_LOGIN_REQUEST_SUCCESS,
  payload,
});

export const partnerLoginAction = (data) => {
  return (dispatch) => {
    dispatch(showLoaderAction("partnerLogin"));

    return partnerLogin(data)
      .then((response) => {
        dispatch(stopLoaderAction("partnerLogin"));

        if (response && response.access_token) {
          dispatch(receivedPartnerLoginAction(response));
          localStorage.setItem("token", response.access_token);
          localStorage.setItem("user", JSON.stringify(data));
          return true;
        } else {
          return false;
        }
      })
      .catch((error) => {
        dispatch(stopLoaderAction("partnerLogin"));
        return false;
      });
  };
};

export const receivedVerifyOTPAction = (payload) => ({
  type: actionTypes.VERIFY_OTP_REQUEST_SUCCESS,
  payload,
});

export const verifyOTPAction = (data) => {
  return (dispatch) => {
    dispatch(showLoaderAction("verifyOTP"));

    return verifyOTP(data)
      .then((response) => {
        dispatch(stopLoaderAction("verifyOTP"));

        if (response) {
          dispatch(receivedVerifyOTPAction(response));
          common.setToStorage("isAuthenticated", true);
          common.setToStorage("partner", JSON.stringify(response.data.user));
          if (response.data.user.is_profile_completed === 1) {
            common.setToStorage("userName", response.data.user && response.data.user.name);
            common.setToStorage("profile", response.data.user && JSON.stringify(response.data.user));
            common.setToStorage("features", response.data.master_features && JSON.stringify(response.data.master_features));
          }
          dispatch(setDashboardFormDataAction({ category_id: response.data.user.category_id, subcategory_id: response.data.user.subcategory_id }))
          return response.data.user;
        } else {
          return false;
        }
      })
      .catch((error) => {
        dispatch(stopLoaderAction("verifyOTP"));
        return false;
      });
  };
};

export const receivedUpdateProfileAction = (payload) => ({
  type: actionTypes.UPDATE_PROFILE_REQUEST_SUCCESS,
  payload,
});

export const updateProfileAction = (data) => {
  return (dispatch) => {
    const updatedData = {...data, name: data.full_name};
    dispatch(showLoaderAction("updateProfile"));

    return updateProfile(updatedData)
      .then((response) => {
        dispatch(stopLoaderAction("updateProfile"));

        if (response) {
          dispatch(receivedUpdateProfileAction(response));
          if (response.data.user.is_profile_completed === 1) {
            common.setToStorage("userName", response.data.user && response.data.user.name);
            common.setToStorage("profile", response.data.user && JSON.stringify(response.data.user));
            common.setToStorage("features", response.data.master_features && JSON.stringify(response.data.master_features));
          }
          dispatch(setDashboardFormDataAction({ category_id: data.category_id, subcategory_id: data.subcategory_id }))
          return true;
        } else {
          return false;
        }
      })
      .catch((error) => {
        dispatch(stopLoaderAction("updateProfile"));
        return false;
      });
  };
};

export const receivedProfileMasterDataAction = (payload) => ({
  type: actionTypes.PROFILE_MASTER_DATA_REQUEST_SUCCESS,
  payload,
});

export const profileMasterDataAction = (data) => {
  return (dispatch) => {
    dispatch(showLoaderAction("getProfileData"));

    return getProfileData(data)
      .then((response) => {

        dispatch(stopLoaderAction("getProfileData"));
        if (response) {
          dispatch(receivedProfileMasterDataAction(response));
          dispatch(setDashboardFormDataAction({ category_id: response.data.user.category_id, subcategory_id: response.data.user.subcategory_id }))
        };
      })
      .catch((error) => {
        dispatch(stopLoaderAction("getProfileData"));
      });
  };
};

export const handleReloadAppAction = () => {
  return (dispatch) => {

    let _user = localStorage.getItem("user");

    if (_user)
      dispatch(profileMasterDataAction({
        country_code: JSON.parse(_user).country_code
      }));

  };
}