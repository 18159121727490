import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Container, Row, Col } from "react-bootstrap";
import FooterTwo from "../../../../components/FooterTwo";
import { Styles } from "../../styles/dashboard.js";
import DashboardMenu from "../../components/DashboardMenu";
import { addNewStudentAction, getMasterDataAction, getStudentsDataAction } from "../../action";
import LoaderNew from "../../../../components/LoaderNew";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import IntlTelInput from "react-intl-tel-input";
import ListIcon from "@mui/icons-material/List";
import Autocomplete from "@mui/material/Autocomplete";
import { CustomStyle } from "../../styles/style.js";
import PartnerHeader from "../../../../components/PartnerHeader";
import common from "../../../../shared/common";
import MemberProfile from "../list/MemberProfile";
import VisibilityIcon from "@mui/icons-material/Visibility";

const StudentList = (props) => {
  const [open, setOpen] = useState(false);
  const [memberId, setMemberId] = useState(null);
  // Form Fields
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedCountryInput, setSelectedCountryInput] = useState("");
  const [countryError, setCountryError] = useState("");
  const [selectedState, setSelectedState] = useState(null);
  const [selectedStateInput, setSelectedStateInput] = useState("");
  const [stateError, setStateError] = useState("");
  const [selectedCity, setSelectedCity] = useState(null);
  const [selectedCityInput, setSelectedCityInput] = useState("");
  const [cityError, setCityError] = useState("");
  const [selectedSchool, setSelectedSchool] = useState(null);
  const [selectedSchoolInput, setSelectedSchoolInput] = useState("");
  const [schoolError, setSchoolError] = useState("");
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedCategoryInput, setSelectedCategoryInput] = useState("");
  const [categoryError, setCategoryError] = useState("");
  const [selectedSubcategory, setSelectedSubcategory] = useState(null);
  const [selectedSubcategoryInput, setSelectedSubcategoryInput] = useState("");
  const [subcategoryError, setSubcategoryError] = useState("");
  const [name, setName] = useState("");
  const [nameError, setNameError] = useState("");
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [countryCode, setCountryCode] = useState("+91");
  const [mobileError, setMobileError] = useState("");
  const [errorClass, setErrorClass] = useState("");

  useEffect(() => {
    props.getStudentsData();
  }, []);

  const handlePhoneChange = (status, number, countryObj, finalValue) => {
    setCountryCode("+" + countryObj.dialCode);
    setPhoneNumber(number);
    handleForm();
  };

  const handleCountryChange = (number, obj, finalValue, status) => {
    setCountryCode("+" + obj.dialCode);
    setPhoneNumber(number);
    handleForm();
  };

  const resetForm = () => {
    setCountryCode("+91");
    setPhoneNumber("");
    setName("");
    setNameError("");
    setEmail("");
    setEmailError("");
    setMobileError("");
    setErrorClass("");
    setSelectedCountry(null);
    setSelectedCountryInput("");
    setCountryError("");
    setSelectedState(null);
    setSelectedStateInput("");
    setStateError("");
    setSelectedCity(null);
    setSelectedCityInput("");
    setCityError("");
    setSelectedSchool(null);
    setSelectedSchoolInput("");
    setSchoolError("");
    setSelectedCategory(null);
    setSelectedCategoryInput("");
    setCategoryError("");
    setSelectedSubcategory(null);
    setSelectedSubcategoryInput("");
    setSubcategoryError("");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!handleForm()) {
      const studentData = {
        name: name,
        email,
        mobile: phoneNumber,
        country_code: countryCode,
        category_id: selectedCategory,
        subcategory_id: selectedSubcategory,
        country_id: selectedCountry,
        state_id: selectedState,
        city_id: selectedCity,
        school_id: selectedSchool,
      };
      props
        .addNewStudent(studentData)
        .then((response) => {
          resetForm();
          setOpen(false);
          props.getStudentsData();
        })
        .catch((error) => {});
    }
  };

  const handleForm = () => {
    let error = false;
    if (name === "") {
      setNameError("please enter student's name");
      error = true;
    } else {
      setNameError("");
    }
    if (email === "") {
      setEmailError("please enter student's email");
      error = true;
    } else {
      setEmailError("");
    }
    if (countryCode === "" || phoneNumber === "") {
      setMobileError("please enter student's mobile number");
      setErrorClass("error");
      error = true;
    } else {
      setMobileError("");
      setErrorClass("");
    }
    if (selectedCountry === null) {
      setCountryError("please select country");
      error = true;
    } else {
      setCountryError("");
    }
    if (selectedState === null) {
      setStateError("please select state");
      error = true;
    } else {
      setStateError("");
    }
    if (selectedCity === null) {
      setCityError("please select city");
      error = true;
    } else {
      setCityError("");
    }
    if (selectedSchool === null) {
      setSchoolError("please select school");
      error = true;
    } else {
      setSchoolError("");
    }
    if (selectedCategory === null) {
      setCategoryError("please select category");
      error = true;
    } else {
      setCategoryError("");
    }
    if (selectedSubcategory === null) {
      setSubcategoryError("please select sub category");
      error = true;
    } else {
      setSubcategoryError("");
    }
    return error;
  };

  const handleNameChange = (e) => {
    setName(e.target.value);
    handleForm();
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    handleForm();
  };

  const handleClickOpen = () => {
    const dataPayload = { modules: ["categories", "countries"] };
    props.getMasterData(dataPayload).then(setOpen(true));
  };

  const handleClose = () => {
    resetForm();
    setOpen(false);
  };

  const listStudentPackages = React.useCallback(
    (id) => () => {
      setTimeout(() => {
        props.history.push(process.env.PUBLIC_URL + "/student-packages/" + id);
      });
    },
    []
  );
  const showMemberProfile = React.useCallback(
    (id) => () => {
      setTimeout(() => {
        setMemberId(id);
      });
    },
    []
  );

  const columns = React.useMemo(
    () => [
      {
        field: "name",
        headerName: "Name",
        flex: 1,
        renderCell: (params) => (
          <div>
            <Typography sx={{ whiteSpace: "break-spaces" }}>{`${params.row.name || ""}`}</Typography>
          </div>
        ),
      },
      {
        field: "email",
        headerName: "Email",
        flex: 1,
        renderCell: (params) => (
          <div>
            <Typography sx={{ whiteSpace: "break-spaces", wordBreak: "break-word" }}>{`${params.row.email || ""}`}</Typography>
          </div>
        ),
      },
      {
        field: "mobileNumber",
        headerName: "Mobile",
        flex: 1,
        renderCell: (params) => (
          <div>
            <Typography sx={{ whiteSpace: "break-spaces", wordBreak: "break-word" }}>{`${params.row.country_code || ""}${
              params.row.mobile || ""
            }`}</Typography>
          </div>
        ),
      },
      {
        field: "schoolName",
        headerName: "School",
        flex: 1,
        renderCell: (params) => (
          <div>
            <Typography sx={{ whiteSpace: "break-spaces" }}>{params.row.member_school ? `${params.row.member_school.name}` : ""}</Typography>
          </div>
        ),
      },
      {
        field: "city",
        headerName: "City",
        flex: 1,
        renderCell: (params) => (
          <div>
            <Typography sx={{ whiteSpace: "break-spaces" }}>{`${(params.row.city && params.row.city.name) || ""}`}</Typography>
          </div>
        ),
      },
      {
        field: "class",
        headerName: "Class",
        flex: 1,
        renderCell: (params) => (
          <div>
            <Typography sx={{ whiteSpace: "break-spaces" }}>{`${(params.row.subcategory && params.row.subcategory.name) || ""}`}</Typography>
          </div>
        ),
      },
      {
        field: "created_at",
        headerName: "Registered On",
        flex: 1,
        renderCell: (params) => (
          <div>
            <Typography sx={{ whiteSpace: "break-spaces" }}>{`${common.getISTFormattedDate(params.row.created_at) || ""}`}</Typography>
          </div>
        ),
      },
      {
        field: "actions",
        type: "actions",
        headerName: "Actions",
        flex: 1,
        getActions: (params) => [
          <GridActionsCellItem icon={<ListIcon />} label="Packages" onClick={listStudentPackages(params.row.id)} />,
          <GridActionsCellItem icon={<VisibilityIcon />} label="View Profile" onClick={showMemberProfile(params.row.id)} />,
        ],
      },
    ],
    [listStudentPackages, showMemberProfile]
  );

  return (
    <div className="main-wrapper course-page">
      {/* User Header */}
      <PartnerHeader />

      <Styles>
        {/* Course Grid */}
        <section className="dashboard-grid-area">
          <Container>
            <Row>
              <Col lg="3" md="3" sm="12">
                <DashboardMenu {...props} index={props.index || 1} />
              </Col>
              <Col>
                {props.showLoader && props.showLoader.length > 0 && <LoaderNew />}
                {props.showLoader.length === 0 && (
                  <>
                    <Typography variant="h3" component="div" gutterBottom>
                      Manage Students
                      <Button variant="contained" size="medium" onClick={handleClickOpen} className={"mt-3 mb-3 pull-right orange-bg"}>
                        Add New Student
                      </Button>
                    </Typography>
                    <div style={{ height: 400, width: "100%" }}>
                      <DataGrid
                        rows={props.students}
                        columns={columns}
                        pageSize={10}
                        rowsPerPageOptions={[10]}
                        sx={{ fontSize: "15px" }}
                        getRowHeight={({ id, densityFactor }) => {
                          return 100;
                        }}
                      />
                    </div>
                    <div>
                      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="lg">
                        <DialogTitle>Add New Student</DialogTitle>
                        <DialogContent>
                          <DialogContentText>Create a new student profile</DialogContentText>
                          <Box
                            component="form"
                            sx={{
                              "& .MuiTextField-root": { m: 1 },
                              minHeight: "320px",
                            }}
                            noValidate
                            autoComplete="off"
                          >
                            <Grid container spacing={2}>
                              <Grid item xs={12} sm={6} md={4}>
                                <TextField
                                  label="Name"
                                  fullWidth
                                  placeholder="Enter student's full name"
                                  variant="filled"
                                  required
                                  onChange={handleNameChange}
                                  value={name}
                                  error={nameError !== ""}
                                  helperText={nameError}
                                />
                              </Grid>
                              <Grid item xs={12} sm={6} md={4}>
                                <TextField
                                  type="email"
                                  label="Email"
                                  fullWidth
                                  placeholder="Enter student's email address"
                                  variant="filled"
                                  required
                                  onChange={handleEmailChange}
                                  value={email}
                                  error={emailError !== ""}
                                  helperText={emailError}
                                />
                              </Grid>
                              <Grid item xs={12} sm={6} md={4}>
                                <CustomStyle>
                                  <div className={`mobile-field ${errorClass}`}>
                                    <span className="mobile-title">Mobile Number*</span>
                                    <IntlTelInput
                                      fieldName="mobile"
                                      containerClassName="intl-tel-input"
                                      inputClassName="phone-number"
                                      preferredCountries={["in", "us", "ae"]}
                                      onPhoneNumberBlur={handlePhoneChange}
                                      onPhoneNumberChange={handlePhoneChange}
                                      onSelectFlag={handleCountryChange}
                                      separateDialCode
                                    />
                                  </div>
                                  <span className="mobile-error">{mobileError}</span>
                                </CustomStyle>
                              </Grid>
                              <Grid item xs={12} sm={6} md={4}>
                                <Autocomplete
                                  id="category"
                                  freeSolo
                                  options={props.categories}
                                  value={selectedCategory}
                                  inputValue={selectedCategoryInput}
                                  onChange={(event, newValue) => {
                                    setSelectedCategory(newValue ? newValue.id : null);
                                    setSelectedCategoryInput(newValue ? newValue.name : "");
                                    setSelectedSubcategory(null);
                                    setSelectedSubcategoryInput("");
                                    if (newValue && newValue.id) {
                                      props.getMasterData({ modules: ["category_subcategories"], category_id: newValue.id });
                                    }
                                  }}
                                  onInputChange={(event, newInputValue) => {
                                    setSelectedCategoryInput(newInputValue);
                                  }}
                                  getOptionLabel={(option) => {
                                    if (typeof option === "string") {
                                      return "";
                                    } else if (!isNaN(option)) {
                                      return props.categories.filter((category) => category.id === option)[0].name;
                                    } else {
                                      return option.name;
                                    }
                                  }}
                                  clearOnBlur
                                  renderOption={(props, option) => (
                                    <Box component="li" {...props} key={"" + option.id}>
                                      {option.name}
                                    </Box>
                                  )}
                                  onKeyDown={(event) => {
                                    if (event.key === "Enter") {
                                      event.defaultMuiPrevented = true;
                                    }
                                  }}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      variant="filled"
                                      label="Select Category"
                                      required
                                      error={categoryError !== ""}
                                      helperText={categoryError}
                                    />
                                  )}
                                />
                              </Grid>
                              <Grid item xs={12} sm={6} md={4}>
                                <Autocomplete
                                  id="subcategory"
                                  freeSolo
                                  options={props.subcategories}
                                  value={selectedSubcategory}
                                  inputValue={selectedSubcategoryInput}
                                  onChange={(event, newValue) => {
                                    setSelectedSubcategory(newValue ? newValue.id : null);
                                    setSelectedSubcategoryInput(newValue ? newValue.name : "");

                                    // props.getMasterData({ modules: ["category_subcategories"], category_id: newValue });
                                  }}
                                  onInputChange={(event, newInputValue) => {
                                    setSelectedSubcategoryInput(newInputValue);
                                  }}
                                  getOptionLabel={(option) => {
                                    if (typeof option === "string") {
                                      return "";
                                    } else if (!isNaN(option)) {
                                      return props.subcategories.filter((subcategory) => subcategory.id === option)[0].name;
                                    } else {
                                      return option.name;
                                    }
                                  }}
                                  clearOnBlur
                                  renderOption={(props, option) => (
                                    <Box component="li" {...props} key={"" + option.id}>
                                      {option.name}
                                    </Box>
                                  )}
                                  onKeyDown={(event) => {
                                    if (event.key === "Enter") {
                                      event.defaultMuiPrevented = true;
                                    }
                                  }}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      variant="filled"
                                      label="Select Subcategory"
                                      required
                                      error={subcategoryError !== ""}
                                      helperText={subcategoryError}
                                    />
                                  )}
                                />
                              </Grid>
                              <Grid item xs={12} sm={6} md={4}>
                                <Autocomplete
                                  id="country"
                                  freeSolo
                                  options={props.countries}
                                  value={selectedCountry}
                                  inputValue={selectedCountryInput}
                                  onChange={(event, newValue) => {
                                    setSelectedCountry(newValue ? newValue.id : null);
                                    setSelectedCountryInput(newValue ? newValue.name : "");
                                    setSelectedState(null);
                                    setSelectedStateInput("");
                                    setSelectedCity(null);
                                    setSelectedCityInput("");
                                    setSelectedSchool(null);
                                    setSelectedSchoolInput("");
                                    if (newValue && newValue.id) {
                                      props.getMasterData({ modules: ["country_states"], country_id: newValue.id });
                                    }
                                  }}
                                  onInputChange={(event, newInputValue) => {
                                    setSelectedCountryInput(newInputValue);
                                  }}
                                  getOptionLabel={(option) => {
                                    if (typeof option === "string") {
                                      return "";
                                    } else if (!isNaN(option)) {
                                      return props.countries.filter((country) => country.id === option)[0].name;
                                    } else {
                                      return option.name;
                                    }
                                  }}
                                  clearOnBlur
                                  renderOption={(props, option) => (
                                    <Box component="li" {...props} key={"" + option.id}>
                                      {option.name}
                                    </Box>
                                  )}
                                  onKeyDown={(event) => {
                                    if (event.key === "Enter") {
                                      event.defaultMuiPrevented = true;
                                    }
                                  }}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      variant="filled"
                                      label="Select Country"
                                      required
                                      error={countryError !== ""}
                                      helperText={countryError}
                                    />
                                  )}
                                />
                              </Grid>
                              <Grid item xs={12} sm={6} md={4}>
                                <Autocomplete
                                  id="state"
                                  freeSolo
                                  options={props.states}
                                  value={selectedState}
                                  inputValue={selectedStateInput}
                                  onChange={(event, newValue) => {
                                    setSelectedState(newValue ? newValue.id : null);
                                    setSelectedStateInput(newValue ? newValue.name : "");
                                    setSelectedCity(null);
                                    setSelectedCityInput("");
                                    setSelectedSchool(null);
                                    setSelectedSchoolInput("");
                                    if (newValue && newValue.id) {
                                      props.getMasterData({ modules: ["state_cities"], state_id: newValue.id });
                                    }
                                  }}
                                  onInputChange={(event, newInputValue) => {
                                    setSelectedStateInput(newInputValue);
                                  }}
                                  getOptionLabel={(option) => {
                                    if (typeof option === "string") {
                                      return "";
                                    } else if (!isNaN(option)) {
                                      return props.states.filter((state) => state.id === option)[0].name;
                                    } else {
                                      return option.name;
                                    }
                                  }}
                                  clearOnBlur
                                  renderOption={(props, option) => (
                                    <Box component="li" {...props} key={"" + option.id}>
                                      {option.name}
                                    </Box>
                                  )}
                                  onKeyDown={(event) => {
                                    if (event.key === "Enter") {
                                      event.defaultMuiPrevented = true;
                                    }
                                  }}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      variant="filled"
                                      label="Select State"
                                      required
                                      error={stateError !== ""}
                                      helperText={stateError}
                                    />
                                  )}
                                />
                              </Grid>
                              <Grid item xs={12} sm={6} md={4}>
                                <Autocomplete
                                  id="city"
                                  freeSolo
                                  options={props.cities}
                                  value={selectedCity}
                                  inputValue={selectedCityInput}
                                  onChange={(event, newValue) => {
                                    setSelectedCity(newValue ? newValue.id : null);
                                    setSelectedCityInput(newValue ? newValue.name : "");
                                    setSelectedSchool(null);
                                    setSelectedSchoolInput("");
                                    if (newValue && newValue.id) {
                                      props.getMasterData({ modules: ["city_schools"], city_id: newValue.id });
                                    }
                                  }}
                                  onInputChange={(event, newInputValue) => {
                                    setSelectedCityInput(newInputValue);
                                  }}
                                  getOptionLabel={(option) => {
                                    if (typeof option === "string") {
                                      return "";
                                    } else if (!isNaN(option)) {
                                      return props.cities.filter((city) => city.id === option)[0].name;
                                    } else {
                                      return option.name;
                                    }
                                  }}
                                  clearOnBlur
                                  renderOption={(props, option) => (
                                    <Box component="li" {...props} key={"" + option.id}>
                                      {option.name}
                                    </Box>
                                  )}
                                  onKeyDown={(event) => {
                                    if (event.key === "Enter") {
                                      event.defaultMuiPrevented = true;
                                    }
                                  }}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      variant="filled"
                                      label="Select City"
                                      required
                                      error={cityError !== ""}
                                      helperText={cityError}
                                    />
                                  )}
                                />
                              </Grid>
                              <Grid item xs={12} sm={6} md={4}>
                                <Autocomplete
                                  id="school"
                                  freeSolo
                                  options={props.schools}
                                  value={selectedSchool}
                                  inputValue={selectedSchoolInput}
                                  onChange={(event, newValue) => {
                                    setSelectedSchool(newValue ? newValue.id : null);
                                    setSelectedSchoolInput(newValue ? newValue.name : "");
                                  }}
                                  onInputChange={(event, newInputValue) => {
                                    setSelectedSchoolInput(newInputValue);
                                  }}
                                  getOptionLabel={(option) => {
                                    if (typeof option === "string") {
                                      return "";
                                    } else if (!isNaN(option)) {
                                      return props.schools.filter((school) => school.id === option)[0].name;
                                    } else {
                                      return option.name;
                                    }
                                  }}
                                  clearOnBlur
                                  renderOption={(props, option) => (
                                    <Box component="li" {...props} key={"" + option.id}>
                                      {option.name}
                                    </Box>
                                  )}
                                  onKeyDown={(event) => {
                                    if (event.key === "Enter") {
                                      event.defaultMuiPrevented = true;
                                    }
                                  }}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      variant="filled"
                                      label="Select School"
                                      required
                                      error={schoolError !== ""}
                                      helperText={schoolError}
                                    />
                                  )}
                                />
                              </Grid>
                            </Grid>
                          </Box>
                        </DialogContent>
                        <DialogActions>
                          <Button onClick={handleClose} variant="outlined" size="large">
                            Cancel
                          </Button>
                          <Button
                            onClick={handleSubmit}
                            className="orange-bg"
                            variant="contained"
                            disabled={props.showSubLoader && props.showSubLoader.length > 0}
                            size="large"
                          >
                            Submit
                          </Button>
                        </DialogActions>
                      </Dialog>
                    </div>
                    <MemberProfile memberId={memberId} setMemberId={setMemberId} />
                  </>
                )}
              </Col>
            </Row>
          </Container>
        </section>
      </Styles>

      {/* Footer 2 */}
      <FooterTwo />
    </div>
  );
};

const mapStateToProps = (state) => ({
  countries: state.partnerDashboard.data.countries,
  states: state.partnerDashboard.data.country_states,
  cities: state.partnerDashboard.data.state_cities,
  schools: state.partnerDashboard.data.city_schools,
  categories: state.partnerDashboard.data.categories,
  subcategories: state.partnerDashboard.data.category_subcategories,
  students: state.partnerDashboard.data.students,
  error: state.partnerDashboard.error,
  showLoader: state.user.showLoader,
  showSubLoader: state.partnerDashboard.showSubLoader,
});

const mapDispatchToProps = (dispatch) => ({
  getMasterData: (data) => dispatch(getMasterDataAction(data)),
  addNewStudent: (data) => dispatch(addNewStudentAction(data)),
  getStudentsData: () => dispatch(getStudentsDataAction()),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(StudentList));
