import React from "react";
import { Row, Col } from "react-bootstrap";
import common from "../../../shared/common";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import { Button, Fab } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import DownloadIcon from "@mui/icons-material/Download";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const Examination = (props) => {
  const {
    match: { params },
  } = props;
  const downloadResource = (data, url) => {
    // if (props.can_user_access || data.is_solutions_free) {
    window.open(url);
    // common.downloadResource(url)
    // } else {
    //     alert('You need to subscribe the course first')
    // }
  };

  return (
    <div className="instructor-item">
      {props.can_user_access === 0 && (
        <Row className="mb-3">
          <Col lg="12" md="12">
            <Fab
              variant="extended"
              className="pull-right fab-top-right orange-bg"
              onClick={() => props.history.push(process.env.PUBLIC_URL + "/package/" + params.type)}
            >
              <CheckIcon sx={{ mr: 1 }} />
              Buy Now
            </Fab>
          </Col>
        </Row>
      )}
      <Row>
        <Col lg="12" md="12">
          {props.data &&
            props.data.length > 0 &&
            props.data.map((data, i) => (
              <div className="event-box" key={i}>
                <Row>
                  <Col xl="12" lg="12" md="12">
                    <div className="event-content">
                      <div
                        className="content-box"
                        style={{
                          width: "100%",
                          position: "relative",
                          left: "0",
                          top: 0,
                        }}
                      >
                        <Box sx={{ flexGrow: 1 }}>
                          <Grid container spacing={2}>
                            <Grid item xs={12}>
                              <Item>
                                <CardContent>
                                  <Typography
                                    sx={{ fontSize: 14, textTransform: "uppercase", borderBottom: "solid 1px #ccc", paddingBottom: "10px" }}
                                    color="text.secondary"
                                    gutterBottom
                                  >
                                    {data.name}
                                  </Typography>
                                </CardContent>
                                <CardActions sx={{ textAlign: "center", justifyContent: "center" }}>
                                  {(data.is_questions_free == 1 || props.can_user_access === 1) && (
                                    <Button variant="outlined" onClick={() => downloadResource(data, data.questions_attachment_url)} size="large">
                                      Questions <DownloadIcon />
                                    </Button>
                                  )}
                                  {(data.is_solutions_free == 1 || props.can_user_access === 1) && (
                                    <Button variant="outlined" onClick={() => downloadResource(data, data.solutions_attachment_url)} size="large">
                                      Solution <DownloadIcon />
                                    </Button>
                                  )}
                                  {props.can_user_access == 0 && (
                                    <Fab
                                      variant="extended"
                                      className="orange-bg"
                                      onClick={() => props.history.push(process.env.PUBLIC_URL + "/package/" + params.type)}
                                    >
                                      <CheckIcon sx={{ mr: 1 }} />
                                      Buy Now
                                    </Fab>
                                  )}
                                  
                                  
                                </CardActions>
                              </Item>
                            </Grid>
                          </Grid>
                        </Box>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            ))}
        </Col>
      </Row>
    </div>
  );
};

export default Examination;
