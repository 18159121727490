import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Container, Row, Col, Table } from "react-bootstrap";
import { BreadcrumbBox } from "../../components/common/Breadcrumb";
import FooterTwo from "../../components/FooterTwo";
import { Styles } from "./styles/cart.js";
import UserHeader from "../../components/UserHeader";
import PaymentInfo from "./components/PaymentInfo";
import {
  addressListAction,
  cartListAction,
  checkoutAction,
  razorpaycheckoutAction,
  paypalCancelAction,
  paypalReturnAction,
  removeFromCartAction,
  savePaymentDetailsAction,
  applyCouponAction,
  removeCouponAction,
} from "./action";
import { messages, webUrl } from "../../shared/constants";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import Chip from "@mui/material/Chip";
import { sendAuditEventAction } from "../../components/actions/common";

const Cart = (props) => {
  const [showPayment, setShowPayment] = useState(false);
  const [couponFormData, setCouponFormData] = useState({
    coupon_code: "",
  });

  const handleRemoveCoupon = (e) => {
    e.preventDefault();
    props.removeCoupon();
  };

  const setError = (input, message) => {
    const formControl = document.getElementsByName(input)[0];
    const errorMsg = formControl.parentElement.querySelector(".coupon_input-msg");
    formControl.classList.add("error");
    errorMsg.innerText = message;
    formControl.focus();
  };

  const resetError = (input) => {
    const formControl = document.getElementsByName(input)[0];
    const errorMsg = formControl.parentElement.querySelector(".coupon_input-msg");
    formControl.classList.remove("error");
    errorMsg.innerText = "";
  };

  const onFieldChange = (name, value) => {
    setCouponFormData({
      ...couponFormData,
      [name]: value,
    });

    resetError(name);
  };

  const isValidCouponFormData = () => {
    let isValid = true;
    if (!couponFormData || !couponFormData.coupon_code) {
      setError("coupon_code", messages.requiredField);
      isValid = false;
    } else {
      resetError("coupon_code");
    }
    return isValid;
  };

  const onApplyCouponClick = (e) => {
    e.preventDefault();
    if (isValidCouponFormData()) {
      props
        .applyCoupon(couponFormData)
        .then((response) => {
          if (response.data.success === 0) {
            setError("coupon_code", response.message);
          }
        })
        .catch((error) => {
          console.log(error.message);
        });
    }
  };

  const onClickRemoveFromCart = (cartItemId, e) => {
    e.preventDefault();
    if (window.confirm("Are you sure to remove the item from cart?")) {
      props.removeFromCart({
        cart_item_id: +cartItemId,
      });
    }
  };

  const onClickCheckout = (e) => {
    props.addressList().then(() => {
      setShowPayment(true);
      const name = document.getElementById("name");
      name && name.focus();
    });
  };

  const dashboardClick = (e) => {
    e.preventDefault();
    props.sendAuditEvent({ event_id: "dashboard_from_cart", source: "web" });
    props.history.push(webUrl.userDashboard);
  };

  useEffect(() => {
    props.cartList(); // To do: params.type should be default one
  }, []);

  return (
    <Styles>
      {/* Main Wrapper */}
      <div className="main-wrapper cart-page">
        {/* Header 2 */}
        <UserHeader />

        {/* Breadcroumb */}
        {/* <BreadcrumbBox title="Cart" /> */}

        {/* Product Details */}
        <section className="cart-area">
          <Container>
            <Row>
              <Col lg="8" md="12">
                <div className="product-list table-responsive">
                  <Table className="table-bordered">
                    <thead>
                      <tr>
                        <th className="product-thumbnail"></th>
                        <th className="product-name">Course</th>
                        <th className="product-price">Price</th>
                        <th className="product-subtotal">Package Subscription</th>
                        <th className="product-remove"></th>
                      </tr>
                    </thead>
                    <tbody>
                      {props.cart &&
                        props.cart.items &&
                        props.cart.items.map((cartItem) => {
                          return (
                            <tr key={cartItem.id}>
                              <td className="product-thumbnail">
                                <a
                                  href="#!"
                                  onClick={(e) => {
                                    props.history.push(webUrl.subjectDetails + "/" + cartItem.subsubcategory.id);
                                  }}
                                >
                                  <img
                                    src={
                                      cartItem.subsubcategory.subsubcategory_image_url
                                        ? cartItem.subsubcategory.subsubcategory_image_url
                                        : process.env.PUBLIC_URL + `/assets/images/product-01.jpg`
                                    }
                                    alt=""
                                  />
                                </a>
                              </td>
                              <td className="product-title">
                                <a
                                  href="#!"
                                  onClick={(e) => {
                                    props.history.push(webUrl.subjectDetails + "/" + cartItem.subsubcategory.id);
                                  }}
                                >
                                  {cartItem.subsubcategory.name}
                                </a>
                              </td>
                              <td className="product-price">
                                <span className="amount">
                                  {props.cart.currency === "INR" ? <CurrencyRupeeIcon /> : <AttachMoneyIcon />}
                                  {props.cart.currency === "INR"
                                    ? cartItem.subscription.discounted_price_in_inr
                                    : cartItem.subscription.discounted_price_in_usd}
                                </span>
                              </td>
                              <td className="product-subtotal">
                                <span className="subtotal">
                                  {cartItem.subscription.name} - {cartItem.subscription.duration_text}
                                </span>
                              </td>
                              <td className="product-edit">
                                <a
                                  href="#!"
                                  onClick={(e) => {
                                    props.history.push(webUrl.packageList + "/" + cartItem.subsubcategory.id);
                                  }}
                                >
                                  <EditIcon />
                                </a>
                              </td>
                              <td className="product-remove">
                                <a
                                  href="#!"
                                  onClick={(e) => {
                                    onClickRemoveFromCart(cartItem.id, e);
                                  }}
                                >
                                  <DeleteIcon />
                                </a>
                              </td>
                            </tr>
                          );
                        })}
                      {props.cart && props.cart.items?.length === 0 && (
                        <tr>
                          <td colSpan={5}>
                            {" "}
                            <h5
                              style={{
                                textAlign: "center",
                              }}
                            >
                              No items in your cart
                            </h5>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                  <div className="actions d-flex justify-content-between">
                    <div className="coupon">
                      {props.cart && props.cart.coupon && (
                        <div style={{ color: "green" }}>
                          <Chip label={props.cart.coupon.coupon_code} variant="outlined" onDelete={handleRemoveCoupon} className="mr-3" />
                          Coupon Applied successfully!
                        </div>
                      )}
                      {props.cart && props.cart.coupon === null && (
                        <form action="#" className="form-inline">
                          <div>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Coupon code ..."
                              name="coupon_code"
                              onChange={(e) => {
                                onFieldChange(e.target.name, e.target.value);
                              }}
                            />
                            <span className="coupon_input-msg"></span>
                            <button
                              type="button"
                              className="apply-btn"
                              onClick={(e) => {
                                onApplyCouponClick(e);
                              }}
                            >
                              Apply Coupon
                            </button>
                          </div>
                        </form>
                      )}
                    </div>
                    <div className="update-cart">
                      <button className="update-btn" onClick={ dashboardClick }>
                        Dashboard
                      </button>
                    </div>
                  </div>
                  {showPayment && (
                    <div>
                      <PaymentInfo {...props} redirectTo={props.history.push} />
                    </div>
                  )}
                </div>
              </Col>

              <Col lg="4" md="6">
                <div className="cart-summary">
                  <div className="cs-title text-center">
                    <h5>Cart Summary</h5>
                  </div>
                  <div className="cs-content">
                    <ul className="list-unstyled">
                      <li>
                        Sub Total{" "}
                        <span>
                          {props.cart.currency === "INR" ? <CurrencyRupeeIcon /> : <AttachMoneyIcon />}
                          {props.cart.total_amount}
                        </span>
                      </li>
                      {props.cart && props.cart.coupon && (
                        <li>
                          Coupon Discount{" "}
                          <span>
                            {props.cart.currency === "INR" ? <CurrencyRupeeIcon /> : <AttachMoneyIcon />}
                            {props.cart.discount_amount}
                          </span>
                        </li>
                      )}
                      {/* <li>
                                                Discount (10%){" "}
                                                <span>$14.70</span>
                                            </li> */}
                    </ul>
                    <p className="cart-total">
                      Grand Total{" "}
                      <span>
                        {props.cart.currency === "INR" ? <CurrencyRupeeIcon /> : <AttachMoneyIcon />}
                        {props.cart.payable_amount}
                      </span>
                    </p>
                    {props.cart && props.cart.items?.length !== 0 && (
                      <a
                        href="#!"
                        className="checkout-btn"
                        onClick={(e) => {
                          onClickCheckout(e);
                        }}
                      >
                        Proceed to checkout
                      </a>
                    )}
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </section>

        {/* Footer 2 */}
        <FooterTwo />
      </div>
    </Styles>
  );
};

const mapStateToProps = (state) => ({
  cart: state.cart.data.cart,
  user: state.dashboard.data.user,
  checkoutData: state.cart.data.checkout,
  addresses: state.cart.data.addresses || [],
  showLoader: state.user.showLoader,
});

const mapDispatchToProps = (dispatch) => ({
  cartList: (data) => dispatch(cartListAction(data)),
  applyCoupon: (data) => dispatch(applyCouponAction(data)),
  removeCoupon: () => dispatch(removeCouponAction()),
  removeFromCart: (data) => dispatch(removeFromCartAction(data)),
  addressList: (data) => dispatch(addressListAction(data)),
  checkout: (data) => dispatch(checkoutAction(data)),
  razorpaycheckout: (data) => dispatch(razorpaycheckoutAction(data)),
  paypalReturn: (data) => dispatch(paypalReturnAction(data)),
  paypalCancel: (data) => dispatch(paypalCancelAction(data)),
  savePaymentDetails: (data) => dispatch(savePaymentDetailsAction(data)),
  sendAuditEvent: (data) => dispatch(sendAuditEventAction(data)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Cart));
