import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Styles } from "../styles/concept.js";
import VideoTextAttachment from './VideoTextAttachment';

function Programming(props) {

    return (
        <Styles>
            {/* Faq & Event */}
            <section className="event-faq-area">
                <Row>
                    <Col md="12" className="course-desc">
                        <div className="faq-area">
                            <div className="faq-box">
                                <div className="faq-item">
                                    <div className="accordion-content show">
                                        <div className="course-items">
                                            <VideoTextAttachment data={props.module.programmings} headerText="Programming" canUserAccess={props.module.can_user_access} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </section>
        </Styles>
    )
}

export default Programming
