import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Container, Row, Col } from "react-bootstrap";
import FooterTwo from "../../../../components/FooterTwo";
import { Styles } from "../../styles/dashboard.js";
import DashboardMenu from "../DashboardMenu";
import { addNewTeacherAction, getPartnerProfileDataAction, getTeachersDataAction } from "../../action";
import LoaderNew from "../../../../components/LoaderNew";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import ListIcon from "@mui/icons-material/List";
// import { CustomStyle } from "./style.js";
import PartnerHeader from "../../../../components/PartnerHeader";
import { Link, Typography } from "@mui/material";
import QRCode from "react-qr-code";
import { webUrl } from "../../../../shared/constants";

const ReferCode = (props) => {
  useEffect(() => {
    props.getPartnerProfile();
  }, []);

  const listTeacherPackages = React.useCallback(
    (id) => () => {
      setTimeout(() => {
        // setRows((prevRows) => prevRows.filter((row) => row.id !== id));
      });
    },
    []
  );

  const columns = React.useMemo(
    () => [
      {
        field: "name",
        headerName: "Name",
        width: 150,
        editable: false,
      },
      {
        field: "email",
        headerName: "Email",
        width: 150,
        editable: false,
      },
      {
        field: "mobileNumber",
        headerName: "Mobile Number",
        description: "This column has a value getter and is not sortable.",
        sortable: false,
        width: 160,
        valueGetter: (params) => `${params.row.country_code || ""}${params.row.mobile || ""}`,
      },
      {
        field: "actions",
        type: "actions",
        headerName: "Actions",
        width: 80,
        getActions: (params) => [<GridActionsCellItem icon={<ListIcon />} label="Packages" onClick={listTeacherPackages(params.row.id)} />],
      },
    ],
    [listTeacherPackages]
  );
  const referCodeURL = `https://${window.location.hostname}?refer-code=${props.profile.refer_code}`;
  let examReferCodeURL = '';
  if(props.profile.refer_code === '9UIDFY9'){
    examReferCodeURL = `https://${window.location.hostname}${webUrl.examPreparatory}?refer-code=${props.profile.refer_code}`;
  }

  return (
    <div className="main-wrapper course-page">
      {/* User Header */}
      <PartnerHeader />

      <Styles>
        {/* Course Grid */}
        <section className="dashboard-grid-area">
          <Container>
            <Row>
              <Col lg="3" md="3" sm="12">
                <DashboardMenu {...props} index={props.index || 8} />
              </Col>
              <Col className="refer-code">
                {props.showLoader && props.showLoader.length > 0 && <LoaderNew />}
                {props.showLoader.length === 0 && (
                  <>
                    <Typography variant="h2" component="div" gutterBottom>
                      Share your Refer Code
                    </Typography>
                    <div id="share-qr-code">
                      <QRCode value={referCodeURL} />
                    </div>
                    <Typography variant="h6" component="div" gutterBottom>
                      {props.profile.refer_code}
                    </Typography>
                    <Typography variant="h7" component="div" gutterBottom>
                      or
                    </Typography>
                    <Typography variant="h4" component="div" gutterBottom>
                      <Link href={referCodeURL} target="_blank">{referCodeURL}</Link>
                    </Typography>
                    {examReferCodeURL !== '' && (
                        <>
                          <hr />
                          <Typography variant="h2" component="div" gutterBottom>
                            QR Code for Exam Preparatory
                          </Typography>
                          <div id="share-qr-code">
                            <QRCode value={examReferCodeURL} />
                          </div>
                          <Typography variant="h6" component="div" gutterBottom>
                            {props.profile.refer_code}
                          </Typography>
                          <Typography variant="h7" component="div" gutterBottom>
                            or
                          </Typography>
                          <Typography variant="h4" component="div" gutterBottom>
                            <Link href={examReferCodeURL} target="_blank">{examReferCodeURL}</Link>
                          </Typography>
                        </>
                    )}
                    
                    {/* <div style={{ height: 400, width: "100%" }}>
                      <DataGrid rows={props.teachers} columns={columns} pageSize={10} rowsPerPageOptions={[10]} />
                    </div> */}
                  </>
                )}
              </Col>
            </Row>
          </Container>
        </section>
      </Styles>

      {/* Footer 2 */}
      <FooterTwo />
    </div>
  );
};

const mapStateToProps = (state) => ({
  profile: state.partnerDashboard.data.profile,
  showLoader: state.user.showLoader,
});

const mapDispatchToProps = (dispatch) => ({
  getPartnerProfile: () => dispatch(getPartnerProfileDataAction()),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ReferCode));
