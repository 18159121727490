import common from "../../shared/common";
import { doGetRequest } from "../../shared/api/base-api";
import { apiUrl } from "../../shared/constants";

// Module details list api
export function moduleDetails(data) {
   return doGetRequest(common.stringformat(apiUrl.moduleDetails, data));
}

//SAMPLE TOKEN API
export function getSampleToken() {
   return doGetRequest(apiUrl.sampleToken);
}