import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Styles } from './styles/loaderNew.js';

const LoaderNew = (props) => {

    return (
        <Styles>
            <div className='loading-spinner'></div>
        </Styles>
    )
}

const mapStateToProps = state => ({
    showLoader: state.user.showLoader
});

export default withRouter(connect(mapStateToProps, {})(LoaderNew));