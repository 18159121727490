import { showLoaderAction, stopLoaderAction } from "../user/action";
import { chapterDetails } from "./service";

/**
 * Action types
 */
export const actionTypes = {
  CHAPTER_DETAILS_REQUEST_SUCCESS: "CHAPTER_DETAILS_REQUEST_SUCCESS"
};

export const receivedChapterDetailsAction = (payload) => ({
  type: actionTypes.CHAPTER_DETAILS_REQUEST_SUCCESS,
  payload,
});

export const chapterDetailsAction = (data) => {
  return (dispatch) => {
    dispatch(showLoaderAction("chapterDetails"));

    return chapterDetails(data)
      .then((response) => {

        dispatch(stopLoaderAction("chapterDetails"));
        dispatch(receivedChapterDetailsAction(response));
      })
      .catch((error) => {
        dispatch(stopLoaderAction("chapterDetails"));
      });
  };
};