import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import Snackbar from "@mui/material/Snackbar";
import Link from "@mui/material/Link";
import IconButton from "@mui/material/IconButton";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import LockOpenOutlinedIcon from "@mui/icons-material/LockOpenOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import CloseIcon from "@mui/icons-material/Close";
import { Button, Fab } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import { Styles } from "../styles/modules";

const Modules = (props) => {
  const {
    match: { params },
  } = props;
  const [snackPack, setSnackPack] = useState([]);
  const [open, setOpen] = useState(false);
  const [messageInfo, setMessageInfo] = useState(undefined);

  useEffect(() => {
    if (snackPack.length && !messageInfo) {
      // Set a new snack when we don't have an active one
      setMessageInfo({ ...snackPack[0] });
      setSnackPack((prev) => prev.slice(1));
      setOpen(true);
    } else if (snackPack.length && messageInfo && open) {
      // Close an active snack when a new one is added
      setOpen(false);
    }
  }, [snackPack, messageInfo, open]);

  const handleSnackbar = (message) => {
    return setSnackPack((prev) => [...prev, { message, key: new Date().getTime() }]);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const handleExited = () => {
    setMessageInfo(undefined);
  };

  return (
    <Styles>
      <div className="course-element">
        {props.can_user_access === 0 && (
          <Row className="mb-3">
            <Col lg="12" md="12">
              <Button
                variant="contained"
                style={{ minWidth: "100px" }}
                className="pull-right fab-top-right orange-bg"
                onClick={() => props.history.push(process.env.PUBLIC_URL + "/package/" + params.type)}
              >
                <CheckIcon sx={{ mr: 1 }} />
                Buy Now
              </Button>
            </Col>
          </Row>
        )}

        <Row>
          <Col lg="12">
            <div className="mindmap-bg">
              <div className="module-title">Beginner Level</div>
              <div className="module-hardware">
                <img src={process.env.PUBLIC_URL + (props.subsubcategory.subcategory.name === 'Spike Prime') ? `/assets/images/module/spike_prime_hardware.webp` : `/assets/images/module/ev3_hardware.webp`} alt="" className="img-fluid" />
              </div>
            </div>
          </Col>

          {props.subsubcategory.modules &&
            props.subsubcategory.modules.length > 0 &&
            props.subsubcategory.modules.map((data, i) => (
              <Col lg="12" className="step-wrapper" key={i}>
                <div className={`step-content module-${i + 1}`}>
                  <Link
                    underline="none"
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      if (props.can_user_access === 0 && data.allow_free_access === 0) {
                        handleSnackbar("Please subscribe first to get access to this module");
                      } else {
                        props.history.push(process.env.PUBLIC_URL + "/module-details/" + data.id);
                      }
                    }}
                  >
                    <div className="step-counter">
                      <div className="step_text">step</div>
                      <div className="step_number">{i + 1}</div>
                    </div>
                  </Link>
                  <Link
                    href="#"
                    underline="none"
                    onClick={(e) => {
                      e.preventDefault();
                      if (props.can_user_access === 0 && data.allow_free_access === 0) {
                        handleSnackbar("Please subscribe first to get access to this module");
                      } else {
                        props.history.push(process.env.PUBLIC_URL + "/module-details/" + data.id);
                      }
                    }}
                  >
                    <div className="step-box">
                      <div className="module_title">{data.name}</div>
                      <div className="module_separator"></div>
                      <div className="module_image">
                        <img src={(data.module_image && data.module_image_url) || `/assets/images/module/module8.png`} alt="" className="img-fluid" />
                      </div>
                      <div className="module_action">
                        {props.can_user_access === 0 ? (
                          data.allow_free_access === 0 ? (
                            <LockOutlinedIcon />
                          ) : (
                            <a
                              onClick={(e) => {
                                e.preventDefault();
                                props.history.push(process.env.PUBLIC_URL + "/module-details/" + data.id);
                              }}
                            >
                              <VisibilityOutlinedIcon />
                            </a>
                          )
                        ) : (
                          <a
                            onClick={(e) => {
                              e.preventDefault();
                              props.history.push(process.env.PUBLIC_URL + "/module-details/" + data.id);
                            }}
                          >
                            <LockOpenOutlinedIcon />
                          </a>
                        )}
                      </div>
                    </div>
                  </Link>
                </div>
              </Col>
            ))}
          <Col lg="12">
            <div className="mindmap-certificate"></div>
          </Col>
        </Row>
        {/* <Row>
          <Col lg="12" md="12">
            {props.subsubcategory.modules &&
              props.subsubcategory.modules.length > 0 &&
              props.subsubcategory.modules.map((data, i) => (
                <div className="event-box" key={i}>
                  <Link
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      if (props.can_user_access === 0 && data.allow_free_access === 0) {
                        handleSnackbar("Please subscribe first to get access to this module");
                      } else {
                        props.history.push(process.env.PUBLIC_URL + "/module-details/" + data.id);
                      }
                    }}
                  >
                    <Row>
                      <Col xl="2" lg="2" md="2">
                        <div className="event-img">
                          <img
                            src={(data.module_image && data.module_image_url) || process.env.PUBLIC_URL + `/assets/images/event-01.jpg`}
                            alt=""
                            className="img-fluid"
                          />
                        </div>
                      </Col>
                      <Col xl="10" lg="10" md="10" className="subject-list-item">
                        <div className="event-content">
                          <div className="content-box" style={{ width: "100%" }}>
                            <Row>
                              <Col md="12">
                                <div className="event-title">
                                  <h6 style={{ margin: 0 }}>
                                    {data.name}
                                    <span className="orange-font">
                                      {props.can_user_access === 0 ? (
                                        data.allow_free_access === 0 ? (
                                          <LockOutlinedIcon />
                                        ) : (
                                          <Button
                                            variant="extended"
                                            className="pull-right orange-bg"
                                            onClick={(e) => {
                                              e.preventDefault();
                                              props.history.push(process.env.PUBLIC_URL + "/module-details/" + data.id);
                                            }}
                                          >
                                            Free
                                          </Button>
                                        )
                                      ) : (
                                        <Button
                                          variant="extended"
                                          className="pull-right orange-bg"
                                          onClick={(e) => {
                                            e.preventDefault();
                                            props.history.push(process.env.PUBLIC_URL + "/module-details/" + data.id);
                                          }}
                                        >
                                          View
                                        </Button>
                                      )}
                                    </span>
                                  </h6>
                                </div>
                              </Col>
                            </Row>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </Link>
                </div>
              ))}
          </Col>
        </Row> */}
        <Snackbar
          key={messageInfo ? messageInfo.key : undefined}
          open={open}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          autoHideDuration={6000}
          onClose={handleClose}
          TransitionProps={{ onExited: handleExited }}
          message={messageInfo ? messageInfo.message : undefined}
          action={
            <React.Fragment>
              <IconButton aria-label="close" color="inherit" sx={{ p: 0.5 }} onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </React.Fragment>
          }
        />
      </div>
    </Styles>
  );
};

export default Modules;
