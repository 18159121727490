import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import FooterTwo from "../../components/FooterTwo";
import HeaderTwo from "../../components/HeaderTwo";
import TestimonialSlider from "../../components/TestimonialSlider";
import AppSection from "../../components/AppSection";
import FeatureHeroImage from "../../components/FeatureHeroImage";
import Offerings from "../../components/Offerings";
import ButtonArea from "../../components/ButtonArea";
import ImageContent from "../../components/ImageContent";

const Partner = (props) => {

    return (
        <div className="main-wrapper" >

            {/* Header */}
            <HeaderTwo {...props} />

            {/* Hero Image */}
            <FeatureHeroImage 
                buttonText='CONNECT'
                showPopup={true}
                connect={true}  />

            {/* Offerings */}
            <Offerings />

            {/* Button Area */}
            <ButtonArea 
                buttonText='CONNECT'
                showPopup={true}
                connect={true} />

            {/* Image content */}
            < ImageContent />

            {/* Button Area */}
            <ButtonArea 
                buttonText='CONNECT'
                showPopup={true}
                connect={true} />

            {/* Testimonial Slider */}
            < TestimonialSlider />

            {/* App Section */}
            < AppSection />

            {/* Footer */}
            < FooterTwo />

        </div >
    );
};

const mapStateToProps = (state) => ({

});

const mapDispatchToProps = (dispatch) => ({

});

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(Partner)
);
