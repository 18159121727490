import styled from "styled-components";
import { colors, fonts } from "../../components/common/element/elements.js";

export const Styles = styled.div`
  @font-face {
    font-family: "orbitron-medium";
    src: url("/assets/fonts/orbitron-medium.ttf") format("truetype"); /* Safari, Android, iOS */
  }
  @font-face {
    font-family: "myriadpro";
    font-weight: bold;
    src: url("/assets/fonts/MYRIADPRO-BOLD.OTF") format("opentype"); /* Safari, Android, iOS */
  }
  @font-face {
    font-family: "myriadpro";
    font-weight: regular;
    src: url("/assets/fonts/MYRIADPRO-REGULAR.OTF") format("opentype"); /* Safari, Android, iOS */
  }

  .bg-banner {
    background-color: #eff9fe;
    background-image: url("/assets/images/banners/internship_banner.webp");
    background-repeat: no-repeat;
    background-position: center;
    background-size: auto 100%;
    position: relative;

    @media (max-width: 575px) {
      height: 580px;
      background-position: center;
      display: flex;
      flex-direction: column-reverse;
      justify-content: center;
    }
    .overlay-bg{
      background-position: center;
      background-size: 100% 100%;
      height: 100vh;
    }

    .left-text{
      position: absolute;
      color: white;
      font-family: 'orbitron-medium';
      text-transform: uppercase;
      top: 27vh;
      font-size: 7vh;
      width: 46%;
      line-height: 8vh;
      padding: 4vh 2vh;
      background-color: #1a2a2e;
      opacity: .8;
      @media (max-width: 575px) {
        width: 100%;
        top: 0;
        font-size: 2.6vh;
        line-height: 3.5vh;
      } 
    }
    .right-text{
      position: absolute;
      color: white;
      top: 27vh;
      width: 46%;
      right: 0;
      padding: 4vh 2vh;
      background-color: #1a2a2e;
      opacity: .8;
      @media (max-width: 575px) {
        width: 100%;
      } 
      .para-left {
        font-size: 6vh;
        line-height: 7vh;
        font-family: myriadpro;
        font-weight: bold;
        @media (max-width: 575px) {
          font-size: 2.5vh;
          line-height: 3vh;
        } 
      }
      .para-right {
        text-align: right;
        font-family: 'myriadpro';
        font-size: 3vh;
        width: 90%;
        float: right;
        @media (max-width: 575px) {
          font-size: 2vh;
          line-height: 2.5vh;
          margin-top: 3vh;
        }
      }
    }
    
    .join-now-banner {
      position: absolute;
      bottom: 10vh;
      right: 10vh;
      background-color: #d8b812;
      font-size: 2vh;
      color: #345a6c;
      font-weight: bold;
      border: 1px solid #d8b812;
      @media (max-width: 575px) {
        bottom: 3vh;
        left: 10vh;
      }
    }
  }
  .counters-block {
    background-color: #41b6e7;
    color: white;
    padding: 15px;
    ul {
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      list-style: none;
      padding: 10px;
      @media (max-width: 575px) {
        display: block;
      }
    }
    li {
      flex: 1 1 auto;
      position: relative;
      line-height: 40px;
    }
    li + li {
      border-left: solid 2px white;
      @media (max-width: 575px) {
        border: none;
        margin-top: 20px;
      }
    }
  }
  .student {
    position: absolute;
    top: 2%;
    background-image: url(/assets/images/exam_preparatory/video_thumbnail.webp);
    width: 40%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    right: 5%;
    @media (max-width: 575px) {
      width: 90%;
      left: 5%;
      top: 0%;
      right: 5%;
      height: 300px;
    }
  }
  .left-section {
    position: absolute;
    top: 0;
    width: 59%;
    padding: 2% 5%;
    color: #000;
    display: flex;
    flex-direction: column;
    justify-content: center;
    @media (min-width: 1367px) {
      min-height: 600px;
    }
    @media (min-width: 1100px) and (max-width: 1366px) {
      min-height: 485px;
    }
    @media (max-width: 575px) {
      width: 100%;
      text-align: center;
      position: relative;
    }

    h3 {
      font-size: 3vw;
      margin-bottom: 1vw;
      color: #f369ac;
      @media (max-width: 575px) {
        font-size: 22px;
        padding: 10px 0 0 0;
      }
    }
    h4 {
      font-size: 2.5vw;
      line-height: 3.5vw;
      margin-bottom: 1.5vw;
      @media (max-width: 575px) {
        font-size: 24px;
        padding: 8px 0;
        line-height: 35px;
      }
    }
    h2 {
      font-weight: bold;
      font-size: 4vw;
      margin-bottom: 0.5vw;
      @media (max-width: 575px) {
        font-size: 38px;
        padding: 10px 0 0 0;
      }
    }

    p {
      margin: 0 0 2vw 0;
      font-size: 18px;
      @media (min-width: 1100px) and (max-width: 1366px) {
        font-size: 14px;
      }
      @media (min-width: 576px) and (max-width: 1099px) {
        font-size: 13px;
      }
      @media (max-width: 575px) {
        font-size: 11px;
        padding: 0 0 5px 0;
        margin-top: 0;
      }
    }
  }

  .btn-round {
    font-size: 20px;
    border-radius: 2vw;
    padding-right: 3vw;
    padding-left: 3vw;
    font-weight: bold;
    background-color: #41b6e7;
    border-color: #41b6e7;
    @media (max-width: 575px) {
      font-size: 18px;
    }
    &:hover {
      color: #000;
    }
  }

  .btn-round2 {
    font-size: 20px;
    @media (max-width: 575px) {
      font-size: 18px;
      font-weight: bold;
    }
  }

  .buy-course {
    font-size: 20px;
    @media (max-width: 575px) {
      font-size: 18px;
    }
  }
  .orange-color {
    color: white;
    font-weight: bold;
    background-color: #59cdff;
  }

  .big-title {
    font-size: 32px;
    @media (max-width: 575px) {
      font-size: 22px;
      font-weight: bold;
    }
  }

  .text-large {
    font-size: large;
  }

  .play-button {
    position: relative;
    z-index: 10;
    left: 96%;
    bottom: 0%;
    transform: translateX(-50%) translateY(-50%);
    box-sizing: content-box;
    display: block;
    width: 32px;
    height: 44px;
    border-radius: 50%;

    svg,
    i {
      position: relative;
      font-size: 24px;
      color: #fff;
      z-index: 11;
      margin-left: 5px;
    }

    &::before {
      content: "";
      position: absolute;
      z-index: 0;
      left: 50%;
      top: 50%;
      transform: translateX(-50%) translateY(-50%);
      display: block;
      width: 80px;
      height: 80px;
      background: #fff;
      border-radius: 50%;
      animation: pulse-border 1500ms ease-out infinite;
    }

    &:after {
      content: "";
      position: absolute;
      z-index: 1;
      left: 50%;
      top: 50%;
      transform: translateX(-50%) translateY(-50%);
      display: block;
      width: 80px;
      height: 80px;
      border: 5px solid white;
      background: #41b6e7;
      border-radius: 50%;
      transition: all 200ms;
    }

    &:hover {
      svg,
      i {
        color: #182b49;
      }
    }

    @keyframes pulse-border {
      0% {
        transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
        opacity: 1;
      }

      100% {
        transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
        opacity: 0;
      }
    }
  }

  .MuiTypography-h3{
    font-weight: 800;
    font-size: 2rem;
  }
  .robotics-expert{
    ul {
      padding: 15px;
    }
  
    li {
        font-size: 17px;
    }
    p {
      font-size: 15px;
    }
  }
  

  .play-button2 {
    left: 5%;
    bottom: 0%;
  }

  .banner-play-button {
    position: absolute;
    top: 50%;
    left: 50%;
  }

  .mobile-banner-play-button {
    display: none;
    border: none;
    @media (max-width: 575px) {
      display: inline-block;
      top: 20px;
      left: 40px;
      svg,
      i {
        font-size: 14px;
      }
      &:after,
      &:before {
        width: 40px;
        height: 40px;
      }
    }
  }
  .filter-items {
    .course-item {
      transition: all 0.2s ease;
      margin-bottom: 30px;
      padding-top: 20px;
      border: 1px solid #f6f0f0;
      border-radius: 9px;

      .course-image {
        width: 100%;
        padding-top: 56.25%;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        border-radius: 5px 5px 0 0;
        position: relative;

        .author-img {
          position: absolute;
          left: 20px;
          bottom: 20px;

          img {
            max-width: 40px;
            border-radius: 50%;
            margin-right: 5px;
          }

          .title {
            background: #ffffff;
            padding: 3px 8px;
            border-radius: 5px;

            p {
              font-size: 12px;
              color: ${colors.black1};
              font-weight: 500;
              margin-bottom: -4px;
            }

            span {
              font-size: 11px;
              color: ${colors.text3};
              font-weight: 500;
            }
          }
        }

        .course-price {
          p {
            font-size: 16px;
            color: #ffffff;
            background: ${colors.bg1};
            position: absolute;
            right: 20px;
            bottom: 20px;
            padding: 8px 10px;
            font-weight: 500;
            border-radius: 5px;
          }
        }
      }

      .selected-card {
        border: 2px solid ${colors.green};
        cursor: default !important;
      }

      .course-content {
        background: #fff;
        border-radius: 0 0 7px 7px;
        cursor: pointer;
        h6.heading {
          padding: 22px;
          width: 100px;
          height: 100px;
          margin: 0 auto;
          text-align: center;
          color: #fff;
          font-weight: 800;
          background-color: #41b6e6;
          border-radius: 50%;

          a {
            color: ${colors.black1};
            font-weight: 600;
            display: inline-block;
            margin-bottom: 12px;

            &:hover {
              color: ${colors.green};
            }
          }
        }

        .package-price {
          text-transform: uppercase;
          font-weight: 900;
          text-align: center;
          padding: 14px;
          margin-bottom: 10px;
        }

        .selected {
          background: ${colors.gr_bg};
          color: #fff;
        }

        p.desc {
          font-size: 14px;
          color: ${colors.text3};
          line-height: 25px;
          border-bottom: 1px solid ${colors.border1};
          padding-bottom: 10px;
          margin-bottom: 12px;
        }

        ul.check-list {
          min-height: 330px;
          li {
            font-size: 15px;
            color: ${colors.text3};
            margin-bottom: 5px;
            line-height: 20px;

            i {
              float: left;
              color: #11b67a;
              border: 1px solid #dddddd;
              width: 24px;
              height: 24px;
              border-radius: 50%;
              text-align: center;
              padding-top: 5px;
              margin-right: 15px;
            }

            &:last-child {
              margin-bottom: 0;
            }

            @media (max-width: 575px) {
              font-size: 14px;
            }
          }

          padding: 20px 25px;
        }

        .rating {
          p {
            font-size: 18px;
          }

          ul {
            li {
              margin-right: 0;

              i {
                font-size: 14px;
                color: ${colors.yellow};
              }

              &:last-child {
                font-size: 13px;
                color: ${colors.text3};
              }
            }
          }
        }
      }

      &:hover {
        box-shadow: 0 12px 25px rgba(0, 0, 0, 0.07);
      }
    }
  }
  .swiper-slide-active .course-item {
    box-shadow: 0 12px 25px rgba(0, 0, 0, 0.07);
  }

  .slider-dot {
    margin-top: 18px !important;

    .swiper-pagination-bullet {
      width: 22px;
      height: 9px;
      background: ${colors.text4};
      display: inline-block;
      margin: 3px;
      opacity: 1 !important;
      border-radius: 5px;
    }

    .swiper-pagination-bullet.swiper-pagination-bullet-active {
      background: ${colors.green};
    }

    @media (max-width: 575px) {
      margin-top: 0 !important;
    }
  }

  .display-block {
    display: block;
  }

  .hero-button-area {
    position: relative;

    .button-area {
      button.join-button {
        // transform: translateX(0) translateY(-150%);
        box-sizing: content-box;
        display: block;
        margin: auto;
        min-width: 250px;
        height: 45px;
        background: #41b6e7;
        border-color: #41b6e7;
        border-radius: 2vw;
        font-size: 20px;
        color: #ffffff;
        text-align: center;
        text-transform: capitalize;
        font-weight: 500;
        position: relative;
        border: none;
        padding: 0 30px;
        box-shadow: 0 12px 25px rgb(0 0 0 / 20%);
        &:hover {
          color: black;
        }
        @media (max-width: 575px) {
          font-size: 18px;
          font-weight: bold;
          box-sizing: border-box;
          min-width: auto;
          height: auto;
          padding: 5px 20px;
          margin: 0 auto 3rem auto !important;
          border-radius: 10px;
        }
      }
    }
  }
`;
