import { actionTypes } from "./action";

/** Initial state */
const initialState = {
  data: {
    masterData: {},
    categories: [],
    learnings: [],
    subsubcategories: [],
    user: {}
  },
  formData: {},
  error: null
};

/** Reducers */
export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {

    case actionTypes.SUBSUBCATEGORIES_DATA_REQUEST_SUCCESS:
      let newformData = payload.data.user;
      if ((Object.keys(state.formData).length !== 0)) {
        newformData = state.formData
      }
      return {
        ...state,
        data: {
          ...state.data,
          categories: payload.data.categories,
          learnings: payload.data.learnings,
          subsubcategories: payload.data.subsubcategories,
          user: payload.data.user
        },
        formData: {
          ...newformData
        }
      };

    case actionTypes.SET_FORM_DATA:
      return {
        ...state,
        formData: {
          ...state.formData,
          ...payload
        }
      };

    default:
      return state;
  }
};
