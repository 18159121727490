import { actionTypes } from "./action";

/** Initial state */
const initialState = {
  data: {
    course_plans: [],
  },
  error: null,
};

/** Reducers */
export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case actionTypes.EXAM_COURSE_PLANS_DATA_REQUEST_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          course_plans: payload.data.course_plans,
        },
      };
    default:
      return state;
  }
};
