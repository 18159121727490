import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Styles } from "./styles/homeContent";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Styles as StylesAbout } from "../components/styles/aboutUs.js";
import { Button } from "@mui/material";
import common from "../shared/common";
import ModalVideo from "react-modal-video";
import { webUrl } from "../shared/constants";
import User from "../pages/user";
import ButtonArea from "./ButtonArea";

const RoboticsContent = (props) => {
  const idPref = Math.random();
  const idPref2 = Math.random();
  const idPref3 = Math.random();
  const idPref4 = Math.random();
  const [isOpen, setIsOpen] = useState(false);

  const openModal = () => {
    setIsOpen(true);
  };

  const onClickJoinNow = () => {
    if (common.getFromStorage("userName")) {
      props.history.push(webUrl.userDashboard);
    }
  };
  return (
    <Styles>
      <div className="banner_wrapper">
        <div className="robotics_banner">
          <div className="robotics_board">
            <div className="board_content">
              <h2>Fun with Stem</h2>
              <h3>Alyss Robotics Program</h3>
              <div className="board-points">
                <ul>
                  <li>
                    <ChevronRightIcon />
                    3D HD animations for robotics concepts
                  </li>
                  <li>
                    <ChevronRightIcon />
                    Learn from expert coaches
                  </li>
                  <li>
                    <ChevronRightIcon />
                    Experiential Learning
                  </li>
                  <li>
                    <ChevronRightIcon />
                    Cognitive Development
                  </li>
                  <li>
                    <ChevronRightIcon />
                    Earn <strong style={{ "color":"yellow" }}>U.S. BASED CERTIFICATES</strong>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="board_button">
          <button
            className="btn_connect"
            id={"hero-btn" + idPref}
            onClick={(e) => {
              e.preventDefault();
              onClickJoinNow();
            }}
          >
            Connect
          </button>
        </div>
      </div>
      <StylesAbout>
        {/* About Us */}
        <section className="about-us">
          <Container>
            <Row>
              <Col lg="12">
                <h1 className="title">Your Child’s partner in STEM learning</h1>
              </Col>
              <Col md="6">
                <div className="about-image">
                  <img src={process.env.PUBLIC_URL + `/assets/images/about/robo_video_bg.webp`} className="main-img" alt="" />
                  <img src={process.env.PUBLIC_URL + "/assets/images/about/pattern.webp"} className="pattern-img" alt="" />
                  <div
                    className="video-player"
                    style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/about/robo_video_bg_thumb.webp)` }}
                  >
                    <ModalVideo channel="vimeo" isOpen={isOpen} videoId="700788057" onClose={() => setIsOpen(false)} />
                    <button onClick={openModal} className="play-button">
                      <i className="las la-play"></i>
                    </button>
                  </div>
                </div>
              </Col>
              <Col md="6">
                <div className="about-content">
                  <h4 className="about-title">Experiential Learning</h4>
                  <p className="about-para">
                    Alyss Robotics program gives students hands-on experience in applying essential{" "}
                    <strong>Science, Technology, Engineering, and Maths</strong> (STEM) concepts to real-world problems.
                  </p>
                  <p className="about-para">
                    The curriculum is specially designed to improve creativity, critical thinking, problem solving skills, team spirit and leadership
                    skills in your kids.
                  </p>
                  <Button
                    id={"hero-btn" + idPref2}
                    variant="contained"
                    size="large"
                    style={{ float: "right" }}
                    className={"mt-3 mb-3 orange-bg pull-right connect-button"}
                    onClick={(e) => {
                      e.preventDefault();
                      onClickJoinNow();
                    }}
                  >
                    Connect
                  </Button>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </StylesAbout>
      <section className="climb">
        <div className="main_container">
          <h2>
            Climb the course ladder and become a <span>Robotics Expert</span> and ace <span>Robotics competitions</span>!
          </h2>
          <div className="bg_card orange">
            <div className="bg-climb_img">
              <img src={process.env.PUBLIC_URL + `/assets/images/robotics/climb.webp`} alt="" className="img-fluid desktop_climb" />
              <img src={process.env.PUBLIC_URL + `/assets/images/robotics/mobile_climb.webp`} alt="" className="img-fluid mobile_climb" />
            </div>
            <div className="climb_join">
              <button
                className="join_btn"
                id={"hero-btn" + idPref3}
                onClick={(e) => {
                  e.preventDefault();
                  onClickJoinNow();
                }}
              >
                Join Now
              </button>
            </div>
          </div>
        </div>
      </section>

      <section className="have_fun">
        <div className="main_container">
          <div className="bg_card">
            <div className="head">
              <h2 className="title">Have Fun</h2>
              <p>
                While you learn
                <div className="line">
                  <span className="start"></span>
                  <span className="middle"></span>
                  <span className="end"></span>
                </div>
              </p>
            </div>
            <div className="d_flex">
              <div className="col_three">
                <h4 className="circle_title">Absorb</h4>
                <img src={process.env.PUBLIC_URL + `/assets/images/robotics/circle_1.webp`} alt="" />
                <h5>Absorb Robotic Concepts</h5>
                <p>(3D HD Animation Concept Videos)</p>
              </div>
              <div className="col_three">
                <h4 className="circle_title">Program</h4>
                <img src={process.env.PUBLIC_URL + `/assets/images/robotics/circle_2.webp`} alt="" />
                <h5>Program Your Robot</h5>
                <p>(Programming videos)</p>
              </div>
              <div className="col_three">
                <h4 className="circle_title">Challenge</h4>
                <img src={process.env.PUBLIC_URL + `/assets/images/robotics/circle_3.webp`} alt="" />
                <h5>Send your Robot on Challenges/Missions</h5>
                <p>(Tips and Tricks)</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <ButtonArea buttonText="Register now for a free trial" onHeroButtonClick={onClickJoinNow} showPopup={!!!common.getFromStorage("userName")} />
      <section className="school_solution">
        <div className="main_container">
          <div className="bg_card">
            <div className="img_bg"></div>
          </div>
        </div>
      </section>
      <section className="main_container">
        <div className="robotics_bottom">
          <div className="d_flex">
            <div className="col_two">
              <h2>Climb the Robotics ladder and foster Robot Inventors in your school</h2>
              <h5>Register Now for a free demo</h5>
              <button
                className="join_btn"
                id={"hero-btn" + idPref4}
                onClick={(e) => {
                  e.preventDefault();
                  onClickJoinNow();
                }}
              >
                Join Now
              </button>
            </div>
            <div className="col_two"></div>
          </div>
        </div>
      </section>
      <User openCommand={true} commandCtrlId={"hero-btn" + idPref} />
      <User openCommand={true} commandCtrlId={"hero-btn" + idPref2} />
      <User openCommand={true} commandCtrlId={"hero-btn" + idPref3} />
      <User openCommand={true} commandCtrlId={"hero-btn" + idPref4} />
    </Styles>
  );
};

export default RoboticsContent;
