import React from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { webUrl } from '../../../shared/constants';


function LoginForm(props) {
	// const { control, setValue, formState, handleSubmit, reset, trigger } = useForm({
	// 	mode: 'onChange',
	// 	defaultValues,
	// });

	// const { isValid, dirtyFields, errors } = formState;

	// useEffect(() => {
	// 	setValue('email', '', { shouldDirty: true, shouldValidate: true });
	// 	setValue('password', '', { shouldDirty: true, shouldValidate: true });
	// }, [reset, setValue, trigger]);

	function onSubmit(e) {
		e.preventDefault();
		// props.history.push(webUrl.olympiadWelcome)
		props.olympiadLogin({
			email: e.target.email.value,
			password: e.target.password.value
		}).then(response => {
			if (response) {
				props.history.replace(webUrl.olympiadWelcome)
			}
		});
	}

	return (
		<div className="w-full">
			<form className="flex flex-col justify-center w-full" onSubmit={onSubmit}>

				<TextField
					className="mb-16"
					type="email"
					name="email"
					label="Email"
					variant="outlined"
					required
				/>
				<TextField
					className="mb-16"
					label="Password"
					type="password"
					name="password"
					variant="outlined"
					required
				/>
				<Button
					type="submit"
					variant="contained"
					color="primary"
					className="MuiButton-root-197 MuiButton-containedPrimary-206 w-full mx-auto mt-16"
					aria-label="LOG IN"
					value="legacy"
				>
					Login
				</Button>
			</form>
		</div>
	);
}

export default LoginForm;
