import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import { Styles } from "../styles/packageFilter.js";
import { webUrl } from "../../../shared/constants.js";
import CheckIcon from "@mui/icons-material/Check";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import common from "../../../shared/common.js";
import Loader from "../../../components/Loader.js";
import LoaderImage from "../../../components/LoaderImage.js";

function PackageFilter(props) {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const {
    match: { params },
  } = props;

  const _package =
    parseInt(props.user.member_type) === 2
      ? props.subsubcategory.teacher_package?.subscriptions
      : props.subsubcategory.student_package?.subscriptions;

  const userProfile = JSON.parse(common.getFromStorage("profile"));
  const isInr = userProfile.country_code === "+91";

  const onClickPackage = (selectedIndex, e) => {
    setSelectedIndex(selectedIndex);
  };

  const onClickAddToCart = (e) => {
    e.preventDefault();
    const subscriptionId = _package[selectedIndex].id;
    const subsubcategoryId = params.type;
    props
      .addToCart({
        subscription_id: subscriptionId,
        subsubcategory_id: +subsubcategoryId,
      })
      .then((response) => {
        if (response) {
          props.redirectTo(webUrl.cartList);
        }
      });
  };

  return (
    <Styles>
      {/* Package */}
      <section className="course-filter">
        <Container>
          {props.showLoader && props.showLoader.length > 0 && <LoaderImage />}
          {props.showLoader.length === 0 && (
            <Row>
              <Col md="12">
                <div className="sec-title text-center">
                  <h4>Package which makes your dreams true.</h4>
                </div>
              </Col>
              <Col md="12">
                <Row className="filter-items">
                  {_package &&
                    _package.length > 0 &&
                    _package.map((value, i) => (
                      <Col lg="4" md="6" key={i}>
                        <div className="course-item">
                          <div onClick={(e) => onClickPackage(i, e)} className={"course-content " + (selectedIndex === i ? "selected-card" : "")}>
                            <h6 className="heading">
                              {" "}
                              {value.display_name} {value.display_name && value.duration_text ? " - " : ""} {value.duration_text}{" "}
                            </h6>
                            <ul className="list-unstyled check-list">
                              {value.features &&
                                value.features.length > 0 &&
                                value.features.map((feature, j) => (
                                  <li key={j}>
                                    <CheckIcon />
                                    {feature.feature_text}
                                  </li>
                                ))}
                            </ul>
                            <div className={"package-price " + (selectedIndex === i ? "selected" : "")}>
                              <div className="rating">
                                {isInr ? (
                                  <p>
                                    <span style={{ textDecoration: "line-through", color: "#efefef" }}>
                                      <CurrencyRupeeIcon />
                                      {value.price_in_inr}
                                    </span>
                                    &nbsp;&nbsp;
                                    <CurrencyRupeeIcon />
                                    {value.discounted_price_in_inr}
                                  </p>
                                ) : (
                                  <p>
                                    <span style={{ textDecoration: "line-through", color: "#efefef" }}>
                                      <AttachMoneyIcon />
                                      {value.price_in_usd}
                                    </span>
                                    &nbsp;&nbsp;
                                    <AttachMoneyIcon />
                                    {value.discounted_price_in_usd}
                                  </p>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </Col>
                    ))}
                  {!_package && (
                    <Col lg="12" md="12">
                      <h4 style={{ textAlign: "center" }}>No Package found</h4>
                    </Col>
                  )}
                </Row>
              </Col>
              {_package && _package.length > 0 && (
                <Col md="12" className="text-center">
                  <div className="viewall-btn">
                    <Link onClick={(e) => onClickAddToCart(e)}>Add to cart</Link>
                  </div>
                </Col>
              )}
            </Row>
          )}
        </Container>
      </section>
    </Styles>
  );
}

export default PackageFilter;
