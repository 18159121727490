import { showLoaderAction, stopLoaderAction } from "../user/action";
import { chapterQuizReport } from "./service";

/**
 * Action types
 */
export const actionTypes = {
  CHAPTER_QUIZ_REPORT_REQUEST_SUCCESS: "CHAPTER_QUIZ_REPORT_REQUEST_SUCCESS"
};

export const receivedChapterQuizReportAction = (payload) => ({
  type: actionTypes.CHAPTER_QUIZ_REPORT_REQUEST_SUCCESS,
  payload,
});

export const chapterQuizReportAction = (data) => {
  return (dispatch) => {
    dispatch(showLoaderAction("chapterQuizReport"));

    return chapterQuizReport(data)
      .then((response) => {
        dispatch(stopLoaderAction("chapterQuizReport"));
        dispatch(receivedChapterQuizReportAction(response));
      })
      .catch((error) => {
        dispatch(stopLoaderAction("chapterQuizReport"));
      });
  };
};