import { actionTypes } from "./action";

/** Initial state */
const initialState = {
  data: {
    cities: [],
    token: "",
  },
  error: null,
};

/** Reducers */
export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case actionTypes.MASTER_DATA_REQUEST_SUCCESS:
      let newData = payload.data;
      if (payload.data.cities) {
        newData.cities = payload.data.cities;
      }
      return {
        ...state,
        data: {
          ...state.data,
          ...newData,
        },
      };

    case actionTypes.SEND_CAMPAIGN_REQUEST_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          token: payload.token,
        },
        error: payload.error,
      };

    case actionTypes.VERIFY_CAMPAIGN_REQUEST_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          token: "",
        },
        error: payload.error,
      };

    default:
      return state;
  }
};
