import { actionTypes } from "./action";

/** Initial state */
const initialState = {
  data: {
    students: [],
    teachers: [],
    schools: [],
    profile: {},
    member: {},
    member_profile: {},
    categories: [],
    countries: [],
    country_states: [],
    state_cities: [],
    city_schools: [],
    category_subcategories: [],
    subcategory_subsubcategories: [],
    student_subscriptions: [],
    teacher_subscriptions: [],
    packages: [],
    wallet_transactions: [],
    wallet_balance: 0,
    commission_transactions: [],
    commission_balance: 0,
    promotional_materials: [],
    notifications: [],
    paytmResponse: {},
    dashboard: {},
    coupons: [],
  },
  showSubLoader: [],
  showPaytmLoader: [],
  error: null,
};

/** Reducers */
export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case actionTypes.SHOW_PAYTM_LOADER:
      return {
        ...state,
        showPaytmLoader: [...state.showPaytmLoader, payload],
      };
    case actionTypes.STOP_PAYTM_LOADER:
      return {
        ...state,
        showPaytmLoader: state.showPaytmLoader.filter((value) => value !== payload),
      };

    case actionTypes.SHOW_SUB_LOADER:
      return {
        ...state,
        showSubLoader: [...state.showSubLoader, payload],
      };

    case actionTypes.STOP_SUB_LOADER:
      return {
        ...state,
        showSubLoader: state.showSubLoader.filter((value) => value !== payload),
      };

    case actionTypes.MASTER_DATA_REQUEST_SUCCESS:
      let newData = payload.data;
      if (payload.data.categories) {
        newData.categories = payload.data.categories;
      }
      if (payload.data.countries) {
        newData.countries = payload.data.countries;
      }
      if (payload.data.country_states) {
        newData.country_states = payload.data.country_states;
      }
      if (payload.data.state_cities) {
        newData.state_cities = payload.data.state_cities;
      }
      if (payload.data.city_schools) {
        newData.city_schools = payload.data.city_schools;
      }
      if (payload.data.category_subcategories) {
        newData.category_subcategories = payload.data.category_subcategories;
      }
      if (payload.data.subcategory_subsubcategories) {
        newData.subcategory_subsubcategories = payload.data.subcategory_subsubcategories;
      }
      return {
        ...state,
        data: {
          ...state.data,
          ...newData,
        },
      };

    case actionTypes.AUTH_MASTER_DATA_REQUEST_SUCCESS:
      let newData2 = payload.data;
      if (payload.data.student_subscriptions) {
        newData2.student_subscriptions = payload.data.student_subscriptions;
      }
      if (payload.data.teacher_subscriptions) {
        newData2.teacher_subscriptions = payload.data.teacher_subscriptions;
      }
      return {
        ...state,
        data: {
          ...state.data,
          ...newData2,
        },
      };

    case actionTypes.STUDENTS_DATA_REQUEST_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          students: payload.data.students,
        },
      };

    case actionTypes.COUPONS_DATA_REQUEST_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          coupons: payload.data.coupons,
        },
      };

    case actionTypes.ADD_NEW_COUPON_REQUEST_SUCCESS:
    case actionTypes.ADD_NEW_STUDENT_REQUEST_SUCCESS:
    case actionTypes.ADD_NEW_TEACHER_REQUEST_SUCCESS:
    case actionTypes.ADD_NEW_SCHOOL_REQUEST_SUCCESS:
    case actionTypes.ASSIGN_PACKAGE_TO_MEMBER_REQUEST_SUCCESS:
    case actionTypes.WALLET_WITHDRAWL_REQUEST_SUCCESS:
    case actionTypes.COMMISSION_PAYOUT_REQUEST_SUCCESS:
      return {
        ...state,
        error: payload.error,
      };

    case actionTypes.MEMBER_PACKAGES_REQUEST_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          member: payload.data.member,
          packages: payload.data.packages,
        },
      };

    case actionTypes.MEMBER_PROFILE_DATA_REQUEST_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          member_profile: payload.data.member,
        },
      };

    case actionTypes.TEACHERS_DATA_REQUEST_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          teachers: payload.data.teachers,
        },
      };

    case actionTypes.SCHOOLS_DATA_REQUEST_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          schools: payload.data.schools,
        },
      };

    case actionTypes.PARTNER_PROFILE_DATA_REQUEST_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          profile: payload.data.partner,
        },
      };

    case actionTypes.PARTNER_WALLET_DATA_REQUEST_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          wallet_transactions: payload.data.wallet_transactions,
          wallet_balance: payload.data.wallet_balance,
        },
      };

    case actionTypes.PARTNER_COMMISSION_DATA_REQUEST_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          commission_transactions: payload.data.commission_transactions,
          commission_balance: payload.data.commission_balance,
        },
      };

    case actionTypes.PROMOTIONAL_MATERIAL_DATA_REQUEST_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          promotional_materials: payload.data.promotional_materials,
        },
      };

    case actionTypes.NOTIFICATIONS_DATA_REQUEST_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          notifications: payload.data.notifications,
        },
      };
    case actionTypes.WALLET_PAYTM_RECHARGE_REQUEST_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          paytmResponse: payload,
        },
      };
    case actionTypes.PARTNER_DASHBOARD_DATA_REQUEST_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          dashboard: payload.data.dashboard,
        },
      };

    case actionTypes.PARTNER_ADMIN_LOGIN_REQUEST_SUCCESS:
      return {
        ...state,
        data: { ...state.data, profile: payload.user },
        error: null,
      };

    default:
      return state;
  }
};
