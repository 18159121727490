import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Styles } from "../styles/dashboardMenu.js";
import common from "../../../shared/common";
import { webUrl } from "../../../shared/constants.js";
import { unmountUserAction } from "../../user/action.js";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { useDispatch } from "react-redux";
import { Box, IconButton, List, ListItem, ListItemButton, ListItemText, ListSubheader, Drawer, ListItemIcon } from "@mui/material";
import { ChevronRight } from "@mui/icons-material";
import HomeIcon from "@mui/icons-material/Home";
import MenuIcon from "@mui/icons-material/Menu";
import SubscriptionsIcon from "@mui/icons-material/Subscriptions";
import ArticleIcon from "@mui/icons-material/Article";
import HomeRepairServiceIcon from "@mui/icons-material/HomeRepairService";
import NotificationsIcon from "@mui/icons-material/Notifications";
import PersonIcon from "@mui/icons-material/Person";
import PaidIcon from "@mui/icons-material/Paid";
import LogoutIcon from "@mui/icons-material/Logout";
import LiveTvIcon from "@mui/icons-material/LiveTv";
import VideoLibraryIcon from "@mui/icons-material/VideoLibrary";
import { Col } from "react-bootstrap";

const DashboardMenu = (props) => {
  const dispatch = useDispatch();
  const logoutHandler = (e) => {
    e.preventDefault();
    localStorage.clear();
    dispatch(props.logoutUser());
    return props.history.push(webUrl.home);
  };
  let checkRobotics = 1;
  let checkUSVersion = 0;
  if (props.formData && props.formData.category_id && common.getFromStorage("categories")) {
    const categories = JSON.parse(common.getFromStorage("categories"));
    const category = categories.filter((category) => category.id === +props.formData.category_id);
    if (category.length) {
      checkRobotics = category[0].is_robotics === 1 ? 1 : 0;
      checkUSVersion = category[0].is_us_version === 1 ? 1 : 0;
    }
  }

  const userProfile = JSON.parse(common.getFromStorage("profile"));
  const userFeatures = JSON.parse(common.getFromStorage("features"));
  const [selectedIndex, setSelectedIndex] = useState(props.index | 0);
  const liveClassFeature = userFeatures.filter((feature) => feature.slug === "live_classes");
  const testGenerationFeature = userFeatures.filter((feature) => feature.slug === "test_generation");
  const ttkFeature = userFeatures.filter((feature) => feature.slug === "teacher_technology_kit");

  const handleListItemClick = (e, target, index) => {
    e.preventDefault();
    setSelectedIndex(index);
    props.history.push(target);
  };
  return (
    <Styles>
      {/* Course Tag */}
      <div className="course-category mobile-hidden">
        <h5>Hi {common.getFromStorage("userName")}!</h5>
        <Box sx={{ width: "100%", bgcolor: "background.paper" }}>
          <List
            component="nav"
            aria-label="main mailbox folders"
            sx={{ width: "100%", paddingBottom: "5px", fontSize: "15px", fontFamily: "Manrope" }}
          >
            <ListItem
              secondaryAction={
                <IconButton edge="end" aria-label="comments">
                  <ChevronRight />
                </IconButton>
              }
              disablePadding
            >
              <ListItemButton
                className="menu-padding"
                selected={selectedIndex === 0}
                onClick={(event) => handleListItemClick(event, webUrl.userDashboard, 0)}
              >
                <ListItemIcon>
                  <HomeIcon />
                </ListItemIcon>
                <ListItemText primary="Home" className="menu-text" />
              </ListItemButton>
            </ListItem>
            <ListItem
              secondaryAction={
                <IconButton edge="end" aria-label="comments">
                  <ChevronRight />
                </IconButton>
              }
              disablePadding
            >
              <ListItemButton
                className="menu-padding"
                selected={selectedIndex === 1}
                onClick={(event) => handleListItemClick(event, webUrl.userSubscriptions, 1)}
              >
                <ListItemIcon>
                  <SubscriptionsIcon />
                </ListItemIcon>
                <ListItemText primary="Subscriptions" className="menu-text" />
              </ListItemButton>
            </ListItem>
            {liveClassFeature.length > 0 && checkUSVersion === 0 && (
              <>
                <ListItem
                  secondaryAction={
                    <IconButton edge="end" aria-label="comments">
                      <ChevronRight />
                    </IconButton>
                  }
                  disablePadding
                >
                  <ListItemButton
                    className="menu-padding"
                    selected={selectedIndex === 2}
                    onClick={(event) => handleListItemClick(event, webUrl.liveClasses, 2)}
                  >
                    <ListItemIcon>
                      <LiveTvIcon />
                    </ListItemIcon>
                    <ListItemText primary="Live Classes" className="menu-text" />
                  </ListItemButton>
                </ListItem>
                <ListItem
                  secondaryAction={
                    <IconButton edge="end" aria-label="comments">
                      <ChevronRight />
                    </IconButton>
                  }
                  disablePadding
                >
                  <ListItemButton
                    className="menu-padding"
                    selected={selectedIndex === 8}
                    onClick={(event) => handleListItemClick(event, webUrl.recordedSessions, 8)}
                  >
                    <ListItemIcon>
                      <VideoLibraryIcon />
                    </ListItemIcon>
                    <ListItemText primary="Recorded Sessions" className="menu-text" />
                  </ListItemButton>
                </ListItem>
              </>
            )}
            {userProfile && userProfile.member_type === 1 && checkRobotics === 0 && (
              <>
                {testGenerationFeature.length > 0 && (
                  <ListItem
                    secondaryAction={
                      <IconButton edge="end" aria-label="comments">
                        <ChevronRight />
                      </IconButton>
                    }
                    disablePadding
                  >
                    <ListItemButton
                      className="menu-padding"
                      selected={selectedIndex === 3}
                      onClick={(event) => handleListItemClick(event, webUrl.testGeneration, 3)}
                    >
                      <ListItemIcon>
                        <ArticleIcon />
                      </ListItemIcon>
                      <ListItemText primary="Test Maker Kit" className="menu-text" />
                    </ListItemButton>
                  </ListItem>
                )}
              </>
            )}

            {userProfile && userProfile.member_type === 2 && checkRobotics === 0 && (
              <>
                {testGenerationFeature.length > 0 && (
                  <ListItem
                    secondaryAction={
                      <IconButton edge="end" aria-label="comments">
                        <ChevronRight />
                      </IconButton>
                    }
                    disablePadding
                  >
                    <ListItemButton
                      className="menu-padding"
                      selected={selectedIndex === 3}
                      onClick={(event) => handleListItemClick(event, webUrl.testGeneration, 3)}
                    >
                      <ListItemIcon>
                        <ArticleIcon />
                      </ListItemIcon>
                      <ListItemText primary="Test Generation" className="menu-text" />
                    </ListItemButton>
                  </ListItem>
                )}
                {ttkFeature.length > 0 && (
                  <ListItem
                    secondaryAction={
                      <IconButton edge="end" aria-label="comments">
                        <ChevronRight />
                      </IconButton>
                    }
                    disablePadding
                  >
                    <ListItemButton
                      className="menu-padding"
                      selected={selectedIndex === 4}
                      onClick={(event) => handleListItemClick(event, webUrl.teacherTechnologyKit, 4)}
                    >
                      <ListItemIcon>
                        <HomeRepairServiceIcon />
                      </ListItemIcon>
                      <ListItemText primary="Teacher Technology Kit" className="menu-text" />
                    </ListItemButton>
                  </ListItem>
                )}
              </>
            )}
            <ListItem
              secondaryAction={
                <IconButton edge="end" aria-label="comments">
                  <ChevronRight />
                </IconButton>
              }
              disablePadding
            >
              <ListItemButton
                className="menu-padding"
                selected={selectedIndex === 5}
                onClick={(event) => handleListItemClick(event, webUrl.userNotifications, 5)}
              >
                <ListItemIcon>
                  <NotificationsIcon />
                </ListItemIcon>
                <ListItemText primary="Notifications" className="menu-text" />
              </ListItemButton>
            </ListItem>
            <ListItem
              secondaryAction={
                <IconButton edge="end" aria-label="comments">
                  <ChevronRight />
                </IconButton>
              }
              disablePadding
            >
              <ListItemButton
                className="menu-padding"
                selected={selectedIndex === 6}
                onClick={(event) => handleListItemClick(event, webUrl.userProfile, 6)}
              >
                <ListItemIcon>
                  <PersonIcon />
                </ListItemIcon>
                <ListItemText primary="Profile" className="menu-text" />
              </ListItemButton>
            </ListItem>
            <ListItem
              secondaryAction={
                <IconButton edge="end" aria-label="comments">
                  <ChevronRight />
                </IconButton>
              }
              disablePadding
            >
              <ListItemButton
                className="menu-padding"
                selected={selectedIndex === 7}
                onClick={(event) => handleListItemClick(event, webUrl.userTransactions, 7)}
              >
                <ListItemIcon>
                  <PaidIcon />
                </ListItemIcon>
                <ListItemText primary="Transactions" className="menu-text" />
              </ListItemButton>
            </ListItem>
            <ListItem
              secondaryAction={
                <IconButton edge="end" aria-label="comments">
                  <ChevronRight />
                </IconButton>
              }
              disablePadding
            >
              <ListItemButton className="menu-padding" onClick={(event) => logoutHandler(event)}>
                <ListItemIcon>
                  <LogoutIcon />
                </ListItemIcon>
                <ListItemText primary="Logout" className="menu-text" />
              </ListItemButton>
            </ListItem>
          </List>
        </Box>
      </div>
    </Styles>
  );
};

const drawerWidth = 240;

const DashboardMenuDrawer = (props) => {
  const { window } = props;

  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <div>
      <DashboardMenu {...props} index={props.index || 0} />
    </div>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <Box component="nav" sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }} aria-label="mailbox folders">
      <Drawer
        container={container}
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          display: { xs: "block", sm: "none" },
          "& .MuiDrawer-paper": { boxSizing: "border-box", width: drawerWidth, position: "relative", borderRight: "none", zIndex: "0" },
        }}
      >
        {drawer}
      </Drawer>
      <Drawer
        variant="permanent"
        sx={{
          display: { xs: "none", sm: "block" },
          "& .MuiDrawer-paper": { boxSizing: "border-box", width: drawerWidth, position: "relative", borderRight: "none", zIndex: "0" },
        }}
        open
      >
        {drawer}
      </Drawer>
    </Box>
  );
};

connect(
  () => {},
  (dispatch) => ({
    drawerMenuToggle: () => dispatch(this.handleDrawerToggle()),
  })
)(DashboardMenuDrawer);

DashboardMenuDrawer.drawerMenuToggle = (props) => {
  // console.log(props.user);
};

const DashboardMenuToggleButton = (props) => {
  const dispatch = useDispatch();
  const handleDrawerToggle = (props) => {
    DashboardMenuDrawer.drawerMenuToggle(props);
  };
  return (
    <Col lg="12" md="12" sm="12">
      <IconButton color="inherit" aria-label="open drawer" edge="start" onClick={handleDrawerToggle} sx={{ mr: 2, display: { sm: "none" } }}>
        <MenuIcon />
      </IconButton>
    </Col>
  );
};

const mapStateToProps = (state) => ({
  user: state.dashboard.data.user,
  formData: state.dashboard.formData,
});

const mapDispatchToProps = (dispatch) => ({
  logoutUser: (data) => dispatch(unmountUserAction(data)),
});

export { DashboardMenuDrawer, DashboardMenuToggleButton };

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DashboardMenu));
