import React, { Component } from 'react';
import Datas from '../data/hero/hero-image.json';
import { Container, Row, Col } from 'react-bootstrap';
import { Styles } from "./styles/featureHeroImage.js";
import ButtonArea from './ButtonArea';

class FeatureHeroImage extends Component {
    constructor() {
        super()
        this.state = {
            isOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }

    openModal() {
        this.setState({ isOpen: true })
    }

    render() {
        return (
            <Styles>
                {/* Hero Image */}
                <section className="hero-image-area" style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/${this.props.bgImage || Datas.heroBackground})` }}>
                    <div className="round-shape" style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/${Datas.heroRoundShape})` }}></div>
                    <div className="hero-table">
                        <div className="hero-tablecell">
                            <Container>
                                <Row>
                                    <Col md="12">
                                        <div className="hero-box text-center">
                                            <h1>{this.props.sliderTitle || 'Fall in love with Science and Maths'}</h1>
                                            <p dangerouslySetInnerHTML={{ __html: this.props.sliderSubtitle || 'India’s best learning app for Science and Maths curriculum (Grade 9<sup className="description_subscript">th</sup> - 10<sup className="description_subscript">th</sup>)' }}></p>
                                            <p dangerouslySetInnerHTML={{ __html: this.props.sliderSubtitle2 || '' }}></p>

                                            {/* Button Area */}
                                            <ButtonArea {...this.props} />
                                        </div>
                                    </Col>
                                </Row>
                            </Container>
                        </div>
                    </div>
                </section>
            </Styles>
        )
    }
}

export default FeatureHeroImage
