import { showLoaderAction, stopLoaderAction } from "../../pages/user/action";
import { submitNewsletter } from "../services/footer";

/**
 * Action types
 */
export const actionTypes = {
  SUBMIT_NEWSLETTER_SUCCESS: "SUBMIT_NEWSLETTER_SUCCESS",
};

export const receivedSubmitNewsletterAction = (payload) => {
  return {
    type: actionTypes.SUBMIT_NEWSLETTER_SUCCESS,
    payload,
  };
};

export const submitNewsletterAction = (data) => {
  return (dispatch) => {
    dispatch(showLoaderAction("submitNewsletter"));

    return submitNewsletter(data)
      .then((response) => {
        dispatch(stopLoaderAction("submitNewsletter"));
        return dispatch(receivedSubmitNewsletterAction(response));
      })
      .catch((error) => {
        dispatch(stopLoaderAction("submitNewsletter"));
        // throw new Error(error);
      });
  };
};
