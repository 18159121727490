import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Styles } from "./styles/loader.js";

const LoaderImage = (props) => {
  return (
    <Styles>
      <div className="loading-image">
        <img src="/assets/images/icons/loading-loader.gif" alt="Loading" />
      </div>
    </Styles>
  );
};

const mapStateToProps = (state) => ({
  showLoader: state.user.showLoader,
});

export default withRouter(connect(mapStateToProps, {})(LoaderImage));
