import React, { useEffect, useState } from "react";
import { Container, Col, Tab, Nav } from "react-bootstrap";
// import { Link } from "react-router-dom";
import Link from "@mui/material/Link";
import { Styles } from "../styles/subject.js";
import Chapters from "./Chapters";
import Examination from "./Examination";
import Modules from "./Modules";
import Overview from "./Overview";
import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import common from "../../../shared/common.js";

const SubjectPane = (props) => {
  const {
    match: { params },
  } = props;
  const userFeatures = JSON.parse(common.getFromStorage("features"));
  const tweFeature = userFeatures.filter((feature) => feature.slug === "term_wise_examinations");
  const yeeFeature = userFeatures.filter((feature) => feature.slug === "year_end_examinations");

  let defaultActiveKey = "overview";
  if (props.subsubcategory.description) {
    defaultActiveKey = "overview";
  } else if (yeeFeature.length > 0 && props.subsubcategory.year_end_examinations && props.subsubcategory.year_end_examinations.length > 0) {
    defaultActiveKey = "year-examinations";
  } else if (tweFeature.length > 0 && props.subsubcategory.term_end_examinations && props.subsubcategory.term_end_examinations.length > 0) {
    defaultActiveKey = "term-examinations";
  } else if (props.subsubcategory.chapters.length > 0) {
    defaultActiveKey = "chapters";
  }

  // const [fullAccess, setFullAccess] = useState(false);
  // const [snackPack, setSnackPack] = useState([]);
  // const [open, setOpen] = useState(false);
  // const [messageInfo, setMessageInfo] = useState(undefined);

  // useEffect(() => {
  //   if (snackPack.length && !messageInfo) {
  //     // Set a new snack when we don't have an active one
  //     setMessageInfo({ ...snackPack[0] });
  //     setSnackPack((prev) => prev.slice(1));
  //     setOpen(true);
  //   } else if (snackPack.length && messageInfo && open) {
  //     // Close an active snack when a new one is added
  //     setOpen(false);
  //   }
  // }, [snackPack, messageInfo, open]);

  // const handleSnackbar = (message) => {
  //   return setSnackPack((prev) => [...prev, { message, key: new Date().getTime() }]);
  // };

  // const handleClose = (event, reason) => {
  //   if (reason === "clickaway") {
  //     return;
  //   }
  //   setOpen(false);
  // };

  // const handleExited = () => {
  //   setMessageInfo(undefined);
  // };

  useEffect(() => {
    props.subjectDetails([params.type]); // To do: params.type should be default one
  }, []);

  // const checkModuleAccess = (e, index) => {
  //   e.preventDefault();
  //   if (fullAccess === false && props.subsubcategory.modules[index].allow_free_access === 0) {
  //     handleSnackbar("Please subscribe first to get access to this module");
  //   } else {
  //     props.history.push(process.env.PUBLIC_URL + "/module-details/" + props.subsubcategory.modules[index].id);
  //   }
  // };

  // if (props.subsubcategory && props.subsubcategory.can_user_access === 1) {
  //   setFullAccess(true);
  // }

  return (
    <Styles>
      {/* Course Details */}
      <section className="course-details-area">
        <Container>
          {/* {props.subsubcategory.is_robotics === 1 && (
                        <Col md="12">
                            <div>
                                <div className="event-img" style={{ width: "40%", margin: "0 auto 30px auto" }}>
                                    <img
                                        src={
                                            (props.subsubcategory.subcategory.subcategory_image &&
                                                props.subsubcategory.subcategory.subcategory_image_url.replace(/\\/g, "/")) ||
                                            process.env.PUBLIC_URL + `/assets/images/event-01.jpg`
                                        }
                                        alt=""
                                        style={{ height: "300px" }}
                                        className="img-fluid"
                                    />
                                </div>
                                <div className="left-side">
                                    <div style={{ width: "100%" }}>
                                        <Link underline="none" href="#" onClick={(e) => checkModuleAccess(e, 0)}>
                                            <div className="event-title content-box" style={{ background: "lightgreen" }}>
                                                <h6>Light Sensor</h6>
                                            </div>
                                        </Link>
                                        <Link underline="none" href="#" onClick={(e) => checkModuleAccess(e, 1)}>
                                            <div className="event-title content-box" style={{ background: "yellow" }}>
                                                <h6>Color Sensor</h6>
                                            </div>
                                        </Link>
                                        <Link underline="none" href="#" onClick={(e) => checkModuleAccess(e, 2)}>
                                            <div className="event-title content-box" style={{ background: "lightpink" }}>
                                                <h6>Touch Sensor</h6>
                                            </div>
                                        </Link>
                                        <Link underline="none" href="#" onClick={(e) => checkModuleAccess(e, 3)}>
                                            <div className="event-title content-box" style={{ background: "aqua" }}>
                                                <h6>Untrasonic Sensor</h6>
                                            </div>
                                        </Link>
                                        <Link underline="none" href="#" onClick={(e) => checkModuleAccess(e, 4)} >
                                            <div className="event-title content-box" style={{ background: "lightblue" }}>
                                                <h6>Rotation Sensor</h6>
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                                <div className="right-side">
                                    <div style={{ width: "100%" }}>
                                        <Link underline="none" href="#" onClick={(e) => checkModuleAccess(e, 5)}>
                                            <div className="event-title content-box" style={{ background: "lightgoldenrodyellow" }}>
                                                <h6>Gyro Sensor</h6>
                                            </div>
                                        </Link>
                                        <Link underline="none" href="#" onClick={(e) => checkModuleAccess(e, 6)}>
                                            <div className="event-title content-box" style={{ background: "lightsteelblue" }}>
                                                <h6>Motor</h6>
                                            </div>
                                        </Link>
                                        <Link underline="none" href="#" onClick={(e) => checkModuleAccess(e, 7)}>
                                            <div className="event-title content-box" style={{ background: "lightseagreen" }}>
                                                <h6>Wheels</h6>
                                            </div>
                                        </Link>
                                        <Link underline="none" href="#" onClick={(e) => checkModuleAccess(e, 8)}>
                                            <div className="event-title content-box" style={{ background: "orange" }}>
                                                <h6>Gear</h6>
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    )} */}
          {props.showLoader.length === 0 && (
            <div className="course-details-top">
              <div className="course-tab-list">
                {props.subsubcategory.is_robotics === 1 && <Modules {...props} can_user_access={props.subsubcategory.can_user_access} />}
                {props.subsubcategory.is_robotics === 0 && (
                  <Tab.Container defaultActiveKey={defaultActiveKey}>
                    <Nav className="flex-column">
                      {props.subsubcategory.chapters.length > 0 && (
                        <Nav.Item>
                          <Nav.Link eventKey="chapters">Chapters</Nav.Link>
                        </Nav.Item>
                      )}
                      {tweFeature.length > 0 && props.subsubcategory.term_end_examinations && props.subsubcategory.term_end_examinations.length > 0 && (
                        <Nav.Item>
                          <Nav.Link eventKey="term-examinations">Term Wise Examinations</Nav.Link>
                        </Nav.Item>
                      )}

                      {yeeFeature.length > 0 && props.subsubcategory.year_end_examinations && props.subsubcategory.year_end_examinations.length > 0 && (
                        <Nav.Item>
                          <Nav.Link eventKey="year-examinations">Year End Examinations</Nav.Link>
                        </Nav.Item>
                      )}

                      {props.subsubcategory.description && (
                        <Nav.Item>
                          <Nav.Link eventKey="overview">Overview</Nav.Link>
                        </Nav.Item>
                      )}
                    </Nav>
                    <Tab.Content>
                      {props.subsubcategory.chapters.length > 0 && (
                        <Tab.Pane eventKey="chapters" className="curriculum-tab">
                          <Chapters {...props} can_user_access={props.subsubcategory.can_user_access} />
                        </Tab.Pane>
                      )}                      
                      {tweFeature.length > 0 && (
                        <Tab.Pane eventKey="term-examinations" className="instructor-tab">
                          <Examination {...props} data={props.subsubcategory.term_end_examinations} can_user_access={props.subsubcategory.can_user_access} />
                        </Tab.Pane>
                      )}
                      {yeeFeature.length > 0 && (
                        <Tab.Pane eventKey="year-examinations" className="review-tab">
                          <Examination {...props} data={props.subsubcategory.year_end_examinations} can_user_access={props.subsubcategory.can_user_access} />
                        </Tab.Pane>
                      )}
                      <Tab.Pane eventKey="overview" className="overview-tab">
                        <Overview {...props} />
                      </Tab.Pane>
                    </Tab.Content>
                  </Tab.Container>
                )}
              </div>
            </div>
          )}
        </Container>
        {/* <Snackbar
          key={messageInfo ? messageInfo.key : undefined}
          open={open}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          autoHideDuration={6000}
          onClose={handleClose}
          TransitionProps={{ onExited: handleExited }}
          message={messageInfo ? messageInfo.message : undefined}
          action={
            <React.Fragment>
              <IconButton aria-label="close" color="inherit" sx={{ p: 0.5 }} onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </React.Fragment>
          }
        /> */}
      </section>
    </Styles>
  );
};

export default SubjectPane;
