import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Container, Row, Col } from "react-bootstrap";
import { Styles } from "../styles/dashboard.js";
import { partnerAdminLoginAction } from "../action";
import LoaderNew from "../../../components/LoaderNew";
import { webUrl } from "../../../shared/constants";

const PartnerLoginAdmin = (props) => {
  const {
    match: { params },
  } = props;

  useEffect(() => {
    props
      .partnerAdminLogin({ hash: params.hash })
      .then((data) => {
        if (data) {
          return props.history.replace(webUrl.partnerDashboard);
        } else {
          return props.history.replace(webUrl.home);
        }
      })
      .catch((error) => {
        return props.history.replace(webUrl.home);
      });
  }, [params.hash]);

  return (
    <div style={{ width: "100%", height: "100%" }}>
      {props.showLoader && props.showLoader.length > 0 && <LoaderNew />}
      {props.showLoader.length === 0 && <></>}
    </div>
  );
};

const mapStateToProps = (state) => ({
  showLoader: state.user.showLoader,
});

const mapDispatchToProps = (dispatch) => ({
  partnerAdminLogin: (partnerData) => dispatch(partnerAdminLoginAction(partnerData)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PartnerLoginAdmin));
