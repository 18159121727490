import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import MathJax from "mathjax3-react";
import { getChapterExercisesAction } from "./action";
import { Styles } from "./styles";
import LoaderNew from "../../components/LoaderNew";

const ChapterExercise = (props) => {
  const {
    match: { params },
  } = props;

  useEffect(() => {
    props.getChapterExercisesData([params.id]); // To do: params.type should be default one
  }, []);

  if (props.showLoader && props.showLoader.length > 0) {
    return <LoaderNew />;
  }

  return (
    <Styles>
      <div className="chapter-exercises">
        {props.exercises.length > 0 &&
          props.exercises.map((exercise, index) => (
            <div className="exercise-box" key={exercise.id}>
              <div className="question-block">
                <MathJax.Provider>
                  <MathJax.Html html={exercise.exercise_question} />
                </MathJax.Provider>
              </div>
              <div className="answer-block">
                <MathJax.Provider>
                  <MathJax.Html html={exercise.exercise_answer} />
                </MathJax.Provider>
              </div>
            </div>
          ))}
        {props.showLoader.length === 0 && props.exercises.length === 0 && (
          <div className="exercise-box">
            <h3>No Exercise for this chapter</h3>
          </div>
        )}
      </div>
    </Styles>
  );
};

const mapStateToProps = (state) => ({
  exercises: state.embed.data.exercises || [],
  showLoader: state.user.showLoader,
});

const mapDispatchToProps = (dispatch) => ({
  getChapterExercisesData: (data) => dispatch(getChapterExercisesAction(data)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ChapterExercise));
