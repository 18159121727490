import React, { useEffect, useState } from "react";
import { Col, Dropdown, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { webUrl } from "../../shared/constants.js";
import { Styles } from "./styles/sidebar.js";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

function Sidebar(props) {
  const [step, setStep] = useState(0);

  useEffect(() => {
    const sidebarBtn = document.getElementById("sidebar-btn");

    if (sidebarBtn) {
      const sidebarOverlay = document.getElementById("sidebar-overlay");
      const sidebarBody = document.getElementById("sidebar-body");
      const sidebarExit = document.getElementById("close-sidebar");
      const btnSave = document.getElementById("btn-save-info");

      sidebarBtn.addEventListener("click", function (e) {
        e.preventDefault();
        sidebarOverlay.classList.add("visible");
        sidebarBody.classList.add("opened");
        setStep(0);
      });

      sidebarOverlay.addEventListener("click", function (e) {
        e.preventDefault();
        sidebarOverlay.classList.remove("visible");
        sidebarBody.classList.remove("opened");
        setStep(0);
      });

      sidebarExit.addEventListener("click", function (e) {
        e.preventDefault();
        sidebarOverlay.classList.remove("visible");
        sidebarBody.classList.remove("opened");
        setStep(0);
      });

      btnSave &&
        btnSave.addEventListener("click", function (e) {
          setStep(0);
          sidebarOverlay.classList.remove("visible");
          sidebarBody.classList.remove("opened");
        });
    }
  });

  return (
    <Styles>
      {/* Sidebar */}
      <a href={process.env.PUBLIC_URL + "/"} id="sidebar-btn">
        <i className="las la-user"></i>
        {props && props.customText && props.customText}
      </a>

      <div className="sidebar" id="sidebar-body">
        <div className="side-logo d-flex justify-content-between">
          <div>
            <Link to={process.env.PUBLIC_URL + "/"}>
              <img src={process.env.PUBLIC_URL + "/assets/images/logo2.webp"} alt="" />
            </Link>
          </div>
          <div>
            <a href={process.env.PUBLIC_URL + "/"} id="close-sidebar">
              <i className="las la-times"></i>
            </a>
          </div>
        </div>
        {step === 0 && (
          <>
            <div className="side-content">
              <h5>Let's get started</h5>
              <p>Enter your mobile number to sign up / sign in to your Alyss account</p>
            </div>
            <div className="side-post">
              {/* Login Area */}
              <section className="login-area">
                <Row>
                  <Col md="12">
                    <div className="login-box">
                      <form id="form_login" className="form">
                        <div className="form-control">
                          <Row>
                            <Col md="4">
                              <Dropdown className="info-filter">
                                <Dropdown.Toggle as="a" className="filter-item">
                                  <span> +91</span>
                                  <KeyboardArrowDownIcon />
                                </Dropdown.Toggle>
                                <Dropdown.Menu as="ul">
                                  <Dropdown.Item as="li"> +91</Dropdown.Item>
                                  <Dropdown.Item as="li"> +1</Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown>
                            </Col>
                            <Col md="8" className="pad-0">
                              <input type="text" placeholder="Mobile number" id="login_user" />
                            </Col>
                          </Row>
                          <span className="login_input-msg"></span>
                        </div>
                        <button type="button" onClick={() => setStep(step + 1)}>
                          Next
                        </button>
                      </form>
                    </div>
                  </Col>
                </Row>
              </section>
            </div>
          </>
        )}
        {step === 1 && (
          <>
            <div className="side-content">
              <h5>Hi Justin</h5>
              <p>We have sent an SMS with OTP to +91-xxxxxx9999</p>
            </div>
            <div className="side-post">
              {/* Login Area */}
              <section className="login-area">
                <Row>
                  <Col md="12">
                    <div className="login-box">
                      <form id="form_login" className="form">
                        <div className="form-control">
                          <Row>
                            <Col md="12">
                              <input type="text" placeholder="Password or OTP" id="otp" />
                            </Col>
                          </Row>
                          <span className="otp_input-msg"></span>
                        </div>
                        <div className="forget-password">
                          <button type="button" onClick={() => setStep(step + 1)}>
                            Next
                          </button>
                        </div>
                        <div className="save-forget-password d-flex justify-content-between">
                          <div className="forget-password">
                            <Link onClick={() => setStep(step - 1)}>
                              <i className="las la-edit" />
                              Edit Number
                            </Link>
                          </div>
                          <div className="forget-password">
                            <Link>
                              <i className="las la-redo" />
                              Resend OTP
                            </Link>
                          </div>
                        </div>
                      </form>
                    </div>
                  </Col>
                </Row>
              </section>
            </div>
          </>
        )}
        {step === 2 && (
          <>
            <div className="side-content">
              <h5>Personal info</h5>
              <p>Please fill your information</p>
            </div>
            <div className="side-post">
              {/* Login Area */}
              <section className="login-area">
                <Row>
                  <Col md="12">
                    <div className="login-box">
                      <form id="form_login" className="form">
                        <div className="form-control">
                          <Row>
                            <Col md="12">
                              <label>Name</label>
                              <input type="text" placeholder="Name" />
                            </Col>
                          </Row>
                          <span className="otp_input-msg"></span>
                        </div>

                        <div className="form-control">
                          <Row>
                            <Col md="12">
                              <label>Email</label>
                              <input type="text" placeholder="Email" />
                            </Col>
                          </Row>
                          <span className="otp_input-msg"></span>
                        </div>

                        <div className="form-control">
                          <Row>
                            <Col md="12">
                              <label>Location</label>
                              <Dropdown type="text" className="info-filter">
                                <Dropdown.Toggle as="a" className="filter-item">
                                  <span>Delhi</span>
                                  <KeyboardArrowDownIcon />
                                </Dropdown.Toggle>
                                <Dropdown.Menu as="ul">
                                  <Dropdown.Item as="li"> Delhi</Dropdown.Item>
                                  <Dropdown.Item as="li"> Noida</Dropdown.Item>
                                  <Dropdown.Item as="li"> Lucknow</Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown>
                            </Col>
                          </Row>
                          <span className="login_input-msg"></span>
                        </div>

                        <div className="form-control">
                          <Row>
                            <Col md="12">
                              <label>Board / Level</label>
                              <Dropdown className="info-filter">
                                <Dropdown.Toggle as="a" className="filter-item">
                                  <span>CBSE</span>
                                  <KeyboardArrowDownIcon />
                                </Dropdown.Toggle>
                                <Dropdown.Menu as="ul">
                                  <Dropdown.Item as="li">CBSE</Dropdown.Item>
                                  <Dropdown.Item as="li">IGCSE</Dropdown.Item>
                                  <Dropdown.Item as="li">Basic Robotics</Dropdown.Item>
                                  <Dropdown.Item as="li">Advanced Robotics</Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown>
                            </Col>
                          </Row>
                          <span className="login_input-msg"></span>
                        </div>

                        <div className="form-control">
                          <Row>
                            <Col md="12">
                              <label>Grade / Hardware</label>
                              <Dropdown className="info-filter">
                                <Dropdown.Toggle as="a" className="filter-item">
                                  <span> Class 9</span>
                                  <KeyboardArrowDownIcon />
                                </Dropdown.Toggle>
                                <Dropdown.Menu as="ul">
                                  <Dropdown.Item as="li">Class 9</Dropdown.Item>
                                  <Dropdown.Item as="li">Class 10</Dropdown.Item>
                                  <Dropdown.Item as="li">Mindstorm</Dropdown.Item>
                                  <Dropdown.Item as="li">Rasberry PI</Dropdown.Item>
                                  <Dropdown.Item as="li">Arduino</Dropdown.Item>
                                  <Dropdown.Item as="li">Avishkar</Dropdown.Item>
                                  <Dropdown.Item as="li">Alyss' Own Robot Hardware</Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown>
                            </Col>
                          </Row>
                          <span className="login_input-msg"></span>
                        </div>

                        <div className="form-control">
                          <Row>
                            <Col md="12">
                              <label>Refer Code</label>
                              <input type="text" placeholder="Refer Code" id="otp" />
                            </Col>
                          </Row>
                          <span className="otp_input-msg"></span>
                        </div>

                        <div className="forget-password">
                          <Link id="btn-save-info" className="btn-save-info" to={webUrl.userDashboard}>
                            Save
                          </Link>
                        </div>
                      </form>
                    </div>
                  </Col>
                </Row>
              </section>
            </div>
          </>
        )}
      </div>

      <div className="sidebar-overlay" id="sidebar-overlay"></div>
    </Styles>
  );
}

export default Sidebar;
