import { actionTypes } from "./action";

/** Initial state */
const initialState = {
  data: {
    subsubcategory: {
      chapters: [],
      term_end_examinations: [],
      year_end_examinations: [],
      modules: [],
    },
  },
  error: null,
  showLoader: [],
};

/** Reducers */
export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case actionTypes.SUBJECT_DETAILS_REQUEST_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          subsubcategory: payload.data.subsubcategory,
        },
        error: null,
      };

    default:
      return state;
  }
};
