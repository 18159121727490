import common from "../../shared/common";
import { doGetRequest, doPostRequest } from "./../../shared/api/base-api";
import { apiUrl } from "./../../shared/constants";

//Package list api
export function packageList(data) {
  return doGetRequest(common.stringformat(apiUrl.packageList, data));
}

//add to cart api
export function addToCart(data) {
  return doPostRequest(apiUrl.addToCart, data);
}
