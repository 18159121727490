import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { withRouter, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import FooterTwo from "../../components/FooterTwo";
import { Container, Row, Col } from "react-bootstrap";
import { Styles } from "../dashboard/styles/dashboard.js";
import { Styles2 } from "./styles/module.js";
import { sampleModuleDetailsAction } from "./action";
import ModuleMenu from "./components/ModuleMenu";
import { Box, Drawer, IconButton } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import Concept from "./components/Concept";
import Programming from "./components/Programming";
import Theory from "./components/Theory";
import Tests from "./components/Tests";
import Mission from "./components/Mission";
import Ppt from "./components/Ppt";
import SampleHeader from "../../components/SampleHeader";
import { webUrl } from "../../shared/constants";
import common from "../../shared/common";

const drawerWidth = 240;

function ModuleSample(props) {
  const location = useLocation();
  let moduleId;
  switch (location.pathname) {
    case webUrl.sampleModule:
      moduleId = 1;
      common.setToStorage("sampleModule", webUrl.sampleModule);
      common.setToStorage("sampleModuleTitle", "Light Sensor");
      break;
    case webUrl.sampleModule2:
      moduleId = 13;
      common.setToStorage("sampleModule", webUrl.sampleModule2);
      common.setToStorage("sampleModuleTitle", "Ultrasonic Sensor");
      break;
    default:
      moduleId = 1;
      break;
  }

  const [selectedPage, setSelectedPage] = useState(0);
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const changeMenuHandler = (value) => {
    setSelectedPage(value);
    if (mobileOpen) {
      setMobileOpen(!mobileOpen);
    }
  };

  useEffect(() => {
    props.moduleDetails([moduleId]); // To do: params.type should be default one
  }, [moduleId]);

  const drawer = (
    <div>
      <ModuleMenu {...props} onChange={changeMenuHandler} />
    </div>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <div className="main-wrapper course-details-page">
      {/* Header */}
      <SampleHeader {...props} />
      <Styles2>
        <Styles>
          {/* Course Grid */}
          <section className="dashboard-grid-area">
            <Container>
              <Row>
                <Col lg="3" md="3" sm="12">
                  <Box component="nav" sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }} aria-label="mailbox folders">
                    <Drawer
                      container={container}
                      variant="temporary"
                      open={mobileOpen}
                      onClose={handleDrawerToggle}
                      ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                      }}
                      sx={{
                        display: { xs: "block", sm: "none" },
                        "& .MuiDrawer-paper": { boxSizing: "border-box", width: drawerWidth, position: "relative", borderRight: "none", zIndex: "0" },
                      }}
                    >
                      {drawer}
                    </Drawer>
                    <Drawer
                      variant="permanent"
                      sx={{
                        display: { xs: "none", sm: "block" },
                        "& .MuiDrawer-paper": { boxSizing: "border-box", width: drawerWidth, position: "relative", borderRight: "none", zIndex: "0" },
                      }}
                      open
                    >
                      {drawer}
                    </Drawer>
                  </Box>
                </Col>
                <Col>
                  <Row>
                    <Col lg="12" md="12" sm="12">
                      <Box component="main" sx={{ flexGrow: 1, p: 0 }}>
                        <div className="main-wrapper course-details-page">
                          <Styles>
                            <section>
                              <Row>
                                <Col lg="12" md="12" sm="12">
                                  <IconButton
                                    color="inherit"
                                    aria-label="open drawer"
                                    edge="start"
                                    onClick={handleDrawerToggle}
                                    sx={{ mr: 2, display: { sm: "none" } }}
                                  >
                                    <MenuIcon />
                                  </IconButton>
                                </Col>
                                <Col lg="12" md="12" sm="12">
                                  <div className="course-details-top">
                                    <div className="course-tab-list">
                                      <div className="course-desc">
                                        {selectedPage === 0 && <Concept {...props} />}
                                        {selectedPage === 1 && <Programming {...props} />}
                                        {selectedPage === 2 && <Theory {...props} />}
                                        {selectedPage === 3 && <Ppt {...props} />}
                                        {selectedPage === 4 && <Tests {...props} redirectTo={props.history.push} />}
                                        {selectedPage === 5 && <Mission {...props} />}
                                      </div>
                                    </div>
                                  </div>
                                </Col>
                              </Row>
                            </section>
                          </Styles>
                        </div>
                      </Box>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Container>
          </section>
        </Styles>
      </Styles2>

      {/* Footer 2 */}
      <FooterTwo />
    </div>
  );
}

ModuleSample.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

const mapStateToProps = (state) => ({
  module: state.module.data.module,
  showLoader: state.user.showLoader,
});

const mapDispatchToProps = (dispatch) => ({
  moduleDetails: (data) => dispatch(sampleModuleDetailsAction(data)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ModuleSample));
