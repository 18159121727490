import React from "react";
import { Row, Col } from "react-bootstrap";
import MathJax from "mathjax3-react";

function CoreFundamentals(props) {
  return (
    <>
      <Row className="margin-bottom-15">
        <Col md="12">
          <h5 className="grid-header ml-0" style={{ position: "relative" }}>
            Core Fundamentals
          </h5>
        </Col>
      </Row>
      <Row>
        <Col md="12">
          <div
            className="course-desc theory-content"
            style={{
              maxHeight: "700px",
              overflowY: "auto",
              paddingRight: "15px",
            }}
          >
            <MathJax.Provider>
              <MathJax.Html html={props.module.core_fundamentals} />
            </MathJax.Provider>
          </div>
        </Col>
      </Row>
    </>
  );
}

export default CoreFundamentals;
