import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Styles } from "../styles/dashboardMenu.js";
import common from "../../../shared/common.js";
import { webUrl } from "../../../shared/constants.js";
import { unmountUserAction } from "../../user/action.js";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { useDispatch } from "react-redux";
import {
  Box,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  ListSubheader,
  Drawer,
  ListItemIcon,
} from "@mui/material";
import { ChevronRight } from "@mui/icons-material";
import HomeIcon from "@mui/icons-material/Home";
import MenuIcon from "@mui/icons-material/Menu";
import SubscriptionsIcon from "@mui/icons-material/Subscriptions";
import NotificationsIcon from "@mui/icons-material/Notifications";
import PersonIcon from "@mui/icons-material/Person";
import PaidIcon from "@mui/icons-material/Paid";
import LogoutIcon from "@mui/icons-material/Logout";
import { Col } from "react-bootstrap";

const USCourseMenu = (props) => {
  const dispatch = useDispatch();
  const logoutHandler = (e) => {
    e.preventDefault();
    localStorage.clear();
    dispatch(props.logoutUser());
    return props.history.push(webUrl.home);
  };
  let checkRobotics = 1;
  if (
    props.formData &&
    props.formData.category_id &&
    common.getFromStorage("categories")
  ) {
    const categories = JSON.parse(common.getFromStorage("categories"));
    const category = categories.filter(
      (category) => category.id === +props.formData.category_id
    );
    if (category.length) {
      checkRobotics = category[0].is_robotics === 1 ? 1 : 0;
    }
  }

  const userFeatures = JSON.parse(common.getFromStorage("features"));
  const [selectedIndex, setSelectedIndex] = useState(props.index | 0);

  const handleListItemClick = (e, target, index) => {
    e.preventDefault();
    setSelectedIndex(index);
    props.history.push(target);
  };
  return (
    <Styles>
      {/* Course Tag */}
      <div className="course-category mobile-hidden">
        <h5>Hi {common.getFromStorage("userName")}!</h5>
        <Box sx={{ width: "100%", bgcolor: "background.paper" }}>
          <List
            component="nav"
            aria-label="main mailbox folders"
            sx={{
              width: "100%",
              paddingBottom: "5px",
              fontSize: "15px",
              fontFamily: "Manrope",
            }}
          >
            <ListItem
              secondaryAction={
                <IconButton edge="end" aria-label="comments">
                  <ChevronRight />
                </IconButton>
              }
              disablePadding
            >
              <ListItemButton
                className="menu-padding"
                selected={selectedIndex === 0}
                onClick={(event) =>
                  handleListItemClick(
                    event,
                    webUrl.subjectDetails + "/" + props.subsubcategory.id,
                    0
                  )
                }
              >
                <ListItemIcon>
                  <HomeIcon />
                </ListItemIcon>
                <ListItemText primary="Home" className="menu-text" />
              </ListItemButton>
            </ListItem>
            <ListItem
              secondaryAction={
                <IconButton edge="end" aria-label="comments">
                  <ChevronRight />
                </IconButton>
              }
              disablePadding
            >
              <ListItemButton
                className="menu-padding"
                selected={selectedIndex === 1}
                onClick={(event) =>
                  handleListItemClick(
                    event,
                    webUrl.subjectDetails +
                      "/" +
                      props.subsubcategory.id +
                      webUrl.userUSIntroduction,
                    1
                  )
                }
              >
                <ListItemIcon>
                  <SubscriptionsIcon />
                </ListItemIcon>
                <ListItemText primary="Introduction" className="menu-text" />
              </ListItemButton>
            </ListItem>
            <ListItem
              secondaryAction={
                <IconButton edge="end" aria-label="comments">
                  <ChevronRight />
                </IconButton>
              }
              disablePadding
            >
              <ListItemButton
                className="menu-padding"
                selected={selectedIndex === 2}
                onClick={(event) =>
                  handleListItemClick(
                    event,
                    webUrl.subjectDetails +
                      "/" +
                      props.subsubcategory.id +
                      webUrl.userUSUnits,
                    2
                  )
                }
              >
                <ListItemIcon>
                  <NotificationsIcon />
                </ListItemIcon>
                <ListItemText primary="Units" className="menu-text" />
              </ListItemButton>
            </ListItem>
            <ListItem
              secondaryAction={
                <IconButton edge="end" aria-label="comments">
                  <ChevronRight />
                </IconButton>
              }
              disablePadding
            >
              <ListItemButton
                className="menu-padding"
                selected={selectedIndex === 3}
                onClick={(event) =>
                  handleListItemClick(
                    event,
                    webUrl.subjectDetails +
                      "/" +
                      props.subsubcategory.id +
                      webUrl.userUSAdvanceMission,
                    3
                  )
                }
              >
                <ListItemIcon>
                  <PersonIcon />
                </ListItemIcon>
                <ListItemText primary="Advance Mission" className="menu-text" />
              </ListItemButton>
            </ListItem>
            <ListItem
              secondaryAction={
                <IconButton edge="end" aria-label="comments">
                  <ChevronRight />
                </IconButton>
              }
              disablePadding
            >
              <ListItemButton
                className="menu-padding"
                selected={selectedIndex === 4}
                onClick={(event) =>
                  handleListItemClick(
                    event,
                    webUrl.subjectDetails +
                      "/" +
                      props.subsubcategory.id +
                      webUrl.userUSCeritificate,
                    4
                  )
                }
              >
                <ListItemIcon>
                  <PaidIcon />
                </ListItemIcon>
                <ListItemText primary="Certificate" className="menu-text" />
              </ListItemButton>
            </ListItem>
            <ListItem
              secondaryAction={
                <IconButton edge="end" aria-label="comments">
                  <ChevronRight />
                </IconButton>
              }
              disablePadding
            >
              <ListItemButton
                className="menu-padding"
                onClick={(event) => logoutHandler(event)}
              >
                <ListItemIcon>
                  <LogoutIcon />
                </ListItemIcon>
                <ListItemText primary="Logout" className="menu-text" />
              </ListItemButton>
            </ListItem>
          </List>
        </Box>
      </div>
    </Styles>
  );
};

const drawerWidth = 240;

const USCourseMenuDrawer = (props) => {
  const { window } = props;

  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <div>
      <USCourseMenu {...props} index={props.index || 0} />
    </div>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box
      component="nav"
      sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
      aria-label="mailbox folders"
    >
      <Drawer
        container={container}
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          display: { xs: "block", sm: "none" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: drawerWidth,
            position: "relative",
            borderRight: "none",
            zIndex: "0",
          },
        }}
      >
        {drawer}
      </Drawer>
      <Drawer
        variant="permanent"
        sx={{
          display: { xs: "none", sm: "block" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: drawerWidth,
            position: "relative",
            borderRight: "none",
            zIndex: "0",
          },
        }}
        open
      >
        {drawer}
      </Drawer>
    </Box>
  );
};

connect(
  () => {},
  (dispatch) => ({
    drawerMenuToggle: () => dispatch(this.handleDrawerToggle()),
  })
)(USCourseMenuDrawer);

USCourseMenuDrawer.drawerMenuToggle = (props) => {
  // console.log(props.user);
};

const USCourseMenuToggleButton = (props) => {
  const dispatch = useDispatch();
  const handleDrawerToggle = (props) => {
    USCourseMenuDrawer.drawerMenuToggle(props);
  };
  return (
    <Col lg="12" md="12" sm="12">
      <IconButton
        color="inherit"
        aria-label="open drawer"
        edge="start"
        onClick={handleDrawerToggle}
        sx={{ mr: 2, display: { sm: "none" } }}
      >
        <MenuIcon />
      </IconButton>
    </Col>
  );
};

const mapStateToProps = (state) => ({
  user: state.dashboard.data.user,
  formData: state.dashboard.formData,
});

const mapDispatchToProps = (dispatch) => ({
  logoutUser: (data) => dispatch(unmountUserAction(data)),
});

export { USCourseMenuDrawer, USCourseMenuToggleButton };

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(USCourseMenu)
);
