import { IconButton } from "@mui/material";
import React, { Fragment, useState } from "react";
import { Col, Row } from "react-bootstrap";
import common from "../../../shared/common";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";

const ProfileDetail = (props) => {
  const userProfile = JSON.parse(common.getFromStorage("profile"));
  return (
    <Fragment>
      {/* Course Item */}
      <Row>
        <Col>
          <h5 className="grid-header ml-0">My Profile</h5>
        </Col>
      </Row>

      <Row>
        {userProfile && (
          <>
            <Col lg="12" md="12">
              <div className="course-item">
                <div className="course-content">
                  <div className="course-face d-flex justify-content-between">
                    <div className="profile-card">
                      <p>
                        <strong>Role: </strong>
                        {userProfile && userProfile.member_type === 1 && "Student"}
                        {userProfile && userProfile.member_type === 2 && (userProfile.country_code === "+91" ? "Teacher" : "Coach")}
                        {userProfile && userProfile.member_type === 3 && "School"}
                        {userProfile && userProfile.member_type === 4 && "Partner"}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Col>

            <Col lg="12" md="12">
              <div className="course-item">
                <div className="course-content">
                  <div className="course-face d-flex justify-content-between align-items-center">
                    <div className="profile-card">
                      <p>
                        <strong>Name: </strong>
                        {userProfile.name}
                      </p>
                    </div>
                    <span>
                      <IconButton
                        onClick={() => {
                          props.editField("name");
                        }}
                      >
                        <EditIcon />
                      </IconButton>
                    </span>
                  </div>
                </div>
              </div>
            </Col>
            <Col lg="12" md="12">
              <div className="course-item">
                <div className="course-content">
                  <div className="course-face d-flex justify-content-between">
                    <div className="profile-card">
                      <p>
                        <strong>Email: </strong>
                        {userProfile.email}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <Col lg="12" md="12">
              <div className="course-item">
                <div className="course-content">
                  <div className="course-face d-flex justify-content-between">
                    <div className="profile-card">
                      <p>
                        <strong>Mobile Number: </strong>
                        {userProfile.country_code}-{userProfile.mobile}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <Col lg="12" md="12">
              <div className="course-item">
                <div className="course-content">
                  <div className="course-face d-flex justify-content-between align-items-center">
                    <div className="profile-card">
                      <p>
                        <strong>City: </strong>
                        {userProfile?.city?.name || "Not Set"}
                        {userProfile.member_school && (
                          <>
                            <br />
                            <strong>School: </strong>
                            {userProfile.member_school ? userProfile?.member_school?.name : "Not Set"}
                          </>
                        )}
                      </p>
                    </div>
                    <span>
                      <IconButton
                        onClick={() => {
                          props.editField("city");
                        }}
                      >
                        <EditIcon />
                      </IconButton>
                    </span>
                  </div>
                </div>
              </div>
            </Col>
            <Col lg="12" md="12">
              <div className="course-item">
                <div className="course-content">
                  <div className="course-face d-flex justify-content-between align-items-center">
                    <div className="profile-card">
                      <p
                        dangerouslySetInnerHTML={{
                          __html: !userProfile?.subcategory
                            ? "Interests not set"
                            : `<strong>Interested in: </strong>${userProfile?.subcategory.category?.name} / ${userProfile?.subcategory?.name}`,
                        }}
                      />
                    </div>
                    <span>
                      <IconButton
                        onClick={() => {
                          props.editField("category");
                        }}
                      >
                        <EditIcon />
                      </IconButton>
                    </span>
                  </div>
                </div>
              </div>
            </Col>
          </>
        )}
      </Row>
    </Fragment>
  );
};

export default ProfileDetail;
