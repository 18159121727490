import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { messages, webUrl } from "../../../shared/constants.js";

function ChangePassword(props) {
  const [formData, setFormData] = useState({});
  const [changePasswordText, setChangePasswordText] = useState("");

  const onFieldChange = (name, value) => {
    setFormData({
      ...formData,
      [name]: value,
    });

    resetError(name);
  };

  const onPasswordNextClick = (e) => {
    e.preventDefault();
    if (isValidPasswordFormData()) {
      props.updatePassword({ password: formData.password }).then((response) => {
        if (response) {
          setChangePasswordText("Your Password has been changed successfully, please login again!");
          setTimeout(() => {
            setChangePasswordText("");
            props.setStep(3);
          }, 3000);
        }
      });
    }
  };

  const isValidPasswordFormData = () => {
    let isValid = true;

    if (!formData || !formData.password) {
      setError("password", messages.requiredField);
      isValid = false;
    } else {
      resetError("password");
    }

    if (!formData || !formData.confirm_password) {
      setError("confirm_password", messages.requiredField);
      isValid = false;
    } else if (formData.password !== formData.confirm_password) {
      setError("confirm_password", messages.confirmPasswordField);
      isValid = false;
    } else {
      resetError("confirm_password");
    }

    return isValid;
  };

  const setError = (input, message) => {
    const formControl = document.getElementsByName(input)[0].parentElement;
    const errorMsg = formControl.querySelector(".login_input-msg");
    formControl.classList.add("error");
    formControl.classList.add("text-left");
    errorMsg.innerText = message;
  };

  const resetError = (input) => {
    const formControl = document.getElementsByName(input)[0].parentElement;
    const errorMsg = formControl.querySelector(".login_input-msg");
    formControl.classList.remove("error");
    formControl.classList.remove("text-left");
    errorMsg.innerText = "";
  };

  return (
    <>
      {changePasswordText !== "" ? (
        <h3>{changePasswordText}</h3>
      ) : (
        <>
          <div className="side-content">
            <h5>Change Password</h5>
            <p>Please enter new password</p>
          </div>
          <div className="side-post">
            {/* Login Area */}
            <section className="login-area">
              <Row>
                <Col md="12">
                  <div className="login-box">
                    <form id="form_login" className="form" onSubmit={onPasswordNextClick}>
                      <div className="form-control">
                        <Row>
                          <Col md="12">
                            <label>New Password</label>
                            <input
                              type="password"
                              autoFocus
                              placeholder="Password"
                              id="password"
                              name="password"
                              value={formData.password}
                              onChange={(e) => {
                                onFieldChange(e.target.name, e.target.value);
                              }}
                            />
                            <span className="login_input-msg"></span>
                          </Col>
                        </Row>
                      </div>
                      <div className="form-control">
                        <Row>
                          <Col md="12">
                            <label>Confirm Password</label>
                            <input
                              type="password"
                              placeholder="Confirm Password"
                              id="confirm_password"
                              name="confirm_password"
                              value={formData.confirm_password}
                              onChange={(e) => {
                                onFieldChange(e.target.name, e.target.value);
                              }}
                            />
                            <span className="login_input-msg"></span>
                          </Col>
                        </Row>
                      </div>
                      <div className="forget-password">
                        <button type="submit" onClick={onPasswordNextClick}>
                          Submit
                        </button>
                      </div>
                    </form>
                  </div>
                </Col>
              </Row>
            </section>
          </div>
        </>
      )}
    </>
  );
}

export default ChangePassword;
