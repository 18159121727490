import styled from "styled-components";
import { colors } from "../common/element/elements.js";

export const Styles = styled.div`

    .tab-section {
        background: ${colors.blue_color_bg};
        padding   : 60px 0 60px;

        .nav {
            background-color: #ffffff;
            border-radius : 5px;
            border  : 1px solid ${colors.border1};
            overflow: hidden;

            .nav-item {
                a.nav-link {
                    font-size    : 15px;
                    color        : ${colors.black1};
                    border-bottom: 1px solid ${colors.border1};
                    padding      : 18px 25px;

                    svg {
                        color: ${colors.blue_color};
                    }
                }

                a.nav-link.active {
                    ${colors.gredient_blue_bg};
                    color     : #ffffff;

                    svg {
                        color: #ffffff;
                    }
                }

                &:last-child {
                    a.nav-link {
                        border-bottom: none;
                    }
                }
            }

            @media(max-width: 767px) {
                margin-bottom : 30px;
            }
        }

        .tab-content {
            .tab-pane {
                

                p.tab-desc {
                    font-size    : 15px;
                    color        : ${colors.text2};
                    line-height  : 30px;
                    margin-bottom: 25px;

                    @media(max-width: 575px) {
                        font-size: 14px;
                    }
                }

                ul.check-list {
                    li {
                        font-size    : 15px;
                        color        : ${colors.text3};
                        margin-bottom: 20px;
                        line-height  : 25px;

                        svg {
                            color: ${colors.blue_color};
                            width: 25px;
                            height: 25px;
                            margin-right: 10px
                        }

                        &:last-child {
                            margin-bottom: 0;
                        }

                        @media(max-width: 575px) {
                            font-size: 14px;
                        }
                    }
                }
            }
        }

        @media(max-width: 767px) {
            padding: 35px 0 30px;
        }
    }

    .service-box {
        padding   : 35px 20px 20px;
        box-shadow: 0 12px 25px rgba(0, 0, 0, 0.07);
        border-radius : 5px;
        margin-bottom: 30px;

        .box-icon {
            background  : ${colors.green};
            height      : 36px;
            text-align  : center;
            margin-right: 20px;
            position    : relative;

            svg {
                font-size : 34px;
                color     : #ffffff;
                width     : 60px;
                display   : inline-block;
                position  : relative;
                z-index   : 1;
                margin-top: -8px;

                @media(max-width: 991px) {
                    font-size: 24px;
                    width: 45px;
                }

                @media(max-width: 767px) {
                    font-size: 34px;
                    width: 60px;
                }
            }

            &::before {
                content            : "";
                position           : absolute;
                border-width       : 15px 30px;
                border-style       : solid;
                border-top-color   : transparent;
                border-right-color : transparent;
                border-bottom-color: ${colors.green};
                border-left-color  : transparent;
                top                : -30px;
                left               : 0;
                z-index            : 1;

                @media(max-width: 991px) {
                    border-width: 12px 23px;
                    top: -24px;
                }

                @media(max-width: 767px) {
                    border-width: 15px 30px;
                    top: -30px;
                }
            }

            &:after {
                content            : "";
                position           : absolute;
                border-width       : 15px 30px;
                border-style       : solid;
                border-top-color   : ${colors.green};
                border-right-color : transparent;
                border-bottom-color: transparent;
                border-left-color  : transparent;
                bottom             : -30px;
                left               : 0;
                z-index            : 1;

                @media(max-width: 991px) {
                    border-width: 12px 23px;
                    bottom: -24px;
                }

                @media(max-width: 767px) {
                    border-width: 15px 30px;
                    bottom: -30px;
                }
            }

            @media(max-width: 991px) {
                height: 22px;
                margin-right: 10px;
            }

            @media(max-width: 767px) {
                height: 36px;
                margin-right: 20px;
            }
        }

        .box-title {
            margin-top: -15px;
            padding: 10px;

            h6 {
                color         : ${colors.black1};
                text-transform: uppercase;
                font-weight   : 600;
                margin-bottom : 6px;

                @media(max-width: 991px) {
                    font-size: 13px;
                    letter-spacing: 0;
                }

                @media(max-width: 767px) {
                    font-size: 16px;
                    letter-spacing: 0.3px;
                }

                @media(max-width: 575px) {
                    font-size: 15px;
                }
            }

            p {
                font-size: 14px;
                color    : ${colors.text3};

                @media(max-width: 991px) {
                    font-size: 13px;
                    letter-spacing: 0;
                }

                @media(max-width: 767px) {
                    font-size: 14px;
                    letter-spacing: 0.3px;
                }
            }
        }
    }
    .tab-title {
        ${colors.gredient_blue_text}
        font-weight  : 800;

        @media(max-width: 575px) {
            font-size: 20px;
        }
    }
`;