import React, { Fragment } from "react";
import { Col, Row } from "react-bootstrap";
import common from "../../../shared/common";

const ItemGrid = (props) => {
  return (
    <Fragment>
      {/* Course Item */}
      <Row>
        <Col>
          <h5 className="grid-header ml-0">My Notifications</h5>
        </Col>
      </Row>

      <Row>
        {(props.data &&
          props.data.length > 0 &&
          props.data.map((data, i) => (
            <Col lg="12" md="12" key={i}>
              <div className="course-item">
                <div className="course-content">
                  <p className="desc">{data.notification_text}</p>
                  <div className="course-face d-flex justify-content-between">
                    <div className="duration">
                      <p>{common.getISTFormattedDateTime(data.notified_at)}</p>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          ))) || (
          <Col lg="12" md="12">
            <p className="not-found">No Notification found</p>
          </Col>
        )}
      </Row>
    </Fragment>
  );
};

export default ItemGrid;
