import { actionTypes } from "./action";

/** Initial state */
const initialState = {
  data: {
    transactions: []
  },
  error: null
};

/** Reducers */
export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {

    case actionTypes.TRANSACTIONS_DATA_REQUEST_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          transactions: payload.data.transactions
        }
      };
    default:
      return state;
  }
};
