import { PlayArrow } from "@mui/icons-material";
import React, { Component, Fragment, useState } from "react";
import { Col, Row } from "react-bootstrap";

const SubjectVideos = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [videoURL, setVideoURL] = useState("");

  const closeModal = () => {
    setIsOpen(false);
  };

  const playVideo = (videoURL) => {
    setVideoURL(videoURL);
    setIsOpen(true);
  };

  const resizeIframe = () => {
    let item = document.getElementById("frameVideo");
    if (item) item.style.height = item.contentWindow.document.documentElement.scrollHeight + "px";
  };

  return (
    <Fragment>
      <Row className="margin-bottom-15">
        <Col md="12">
          <h5 className="grid-header ml-0">{props.headerText}</h5>
        </Col>
      </Row>

      <Row>
        {props &&
          props.data.map((data, i) => (
            <Col lg="4" md="6" key={i}>
              <div className="course-item no-transition">
                <div
                  className="course-image"
                  style={{ backgroundImage: `url(${data[props.itemKey][props.itemKey + "_image_url"]})`, backgroundColor: "black" }}
                >
                  <div className="course-price">
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        playVideo(data[props.itemKey]["full_" + props.itemKey + "_url"]);
                      }}
                      className="play-button"
                    >
                      <PlayArrow />
                    </button>
                  </div>
                </div>
                <div className="course-content">
                  <h6 className="heading">{data[props.itemKey].name}</h6>
                </div>
              </div>
            </Col>
          ))}
        {isOpen && (
          <div className="model-message-intro">
            <a className="close-message-intro" onClick={closeModal} href="#">
              <img src={`${process.env.PUBLIC_URL}/assets/images/sample/close-icon.webp`} alt="" />
            </a>
            <div className="pop-vid-container">
              <div className="embed-responsive">
                <iframe
                  title="Video Iframe"
                  id="frameVideo"
                  onload={resizeIframe(this)}
                  className="embed-responsive-item"
                  src={videoURL}
                  allowFullScreen
                ></iframe>
              </div>
            </div>
          </div>
        )}
      </Row>
    </Fragment>
  );
};

export default SubjectVideos;
