import React, { useState, useEffect } from "react";
import { Row, Col, Form, Dropdown } from "react-bootstrap";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

const DashboardFilter = (props) => {
  const [isUSVersion, setIsUSVersion] = useState(0);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const getSubcategoriesByCategory = (value) => {
    let selectedItem = props.categories.filter(
      (item) => parseInt(item.id) === parseInt(value)
    )[0];

    return selectedItem && selectedItem.subcategories;
  };

  useEffect(() => {
    if (selectedCategory == null) {
      let selectedItem = props.categories.filter(
        (item) => parseInt(item.id) === parseInt(props.formData.category_id)
      )[0];
      if (selectedItem) {
        setSelectedCategory(selectedItem);
        setIsUSVersion(selectedItem.is_us_version);
      }
    }
  }, []);

  const pickedItem = (key, data) => {
    if (data) {
      let selectedItem = data.filter(
        (item) => parseInt(item.id) === parseInt(key)
      )[0];
      return (selectedItem && selectedItem.name) || "";
    }
    return "";
  };

  const onSubcategoryChange = (name, value) => {
    props.setFormData({
      [name]: value,
    });
  };

  const onCategoryChange = (name, value) => {
    let _subCategoryId =
      (getSubcategoriesByCategory(value) &&
        getSubcategoriesByCategory(value)[0] &&
        getSubcategoriesByCategory(value)[0].id) ||
      null;

    props.setFormData({
      [name]: value,
      subcategory_id: _subCategoryId,
    });
  };

  return (
    <section className="dashboard-filter">
      <Row>
        <Col
          md={{ span: 6 }}
          style={{ display: isUSVersion == 1 ? "none" : "block" }}
        >
          <Form.Group controlId="formBasicSelect">
            <Dropdown
              className="info-filter"
              name="category_id"
              id="category_id"
              onSelect={(e) => {
                onCategoryChange("category_id", e);
              }}
            >
              <Dropdown.Toggle as="a" className="filter-item">
                <span>
                  {pickedItem(props.formData.category_id, props.categories)}
                </span>
                <KeyboardArrowDownIcon />
              </Dropdown.Toggle>
              <Dropdown.Menu as="ul">
                {props.categories &&
                  props.categories.map((item) => (
                    <Dropdown.Item as="li" key={item.id} eventKey={item.id}>
                      {item.name}
                    </Dropdown.Item>
                  ))}
              </Dropdown.Menu>
              <span className="login_input-msg"></span>
            </Dropdown>
          </Form.Group>
        </Col>
        <Col md={{ span: 6 }}>
          <Form.Group controlId="formBasicSelect">
            {getSubcategoriesByCategory(props.formData.category_id) &&
              getSubcategoriesByCategory(props.formData.category_id).length >
                0 && (
                <Dropdown
                  className="info-filter"
                  name="subcategory_id"
                  id="subcategory_id"
                  onSelect={(e) => {
                    onSubcategoryChange("subcategory_id", e);
                  }}
                >
                  <Dropdown.Toggle as="a" className="filter-item">
                    <span>
                      {pickedItem(
                        props.formData.subcategory_id,
                        getSubcategoriesByCategory(props.formData.category_id)
                      )}
                    </span>
                    <KeyboardArrowDownIcon />
                  </Dropdown.Toggle>
                  <Dropdown.Menu as="ul">
                    {getSubcategoriesByCategory(props.formData.category_id) &&
                      getSubcategoriesByCategory(
                        props.formData.category_id
                      ).map((item) => (
                        <Dropdown.Item as="li" key={item.id} eventKey={item.id}>
                          {item.name}
                        </Dropdown.Item>
                      ))}
                  </Dropdown.Menu>
                  <span className="login_input-msg"></span>
                </Dropdown>
              )}
          </Form.Group>
        </Col>
      </Row>
    </section>
  );
};

export default DashboardFilter;
