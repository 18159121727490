import common from "../../shared/common";
import { showLoaderAction, stopLoaderAction } from "../user/action";
import {
  getStudentsData,
  addNewStudent,
  getTeachersData,
  addNewTeacher,
  getSchoolsData,
  addNewSchool,
  getPartnerProfileData,
  getMasterData,
  getMemberPackagesData,
  assignPackageToMember,
  getPartnerWalletData,
  getPartnerCommissionData,
  getPromotionalMaterialsData,
  submitWalletWithdrawlRequest,
  submitCommissionPayoutRequest,
  getNotificationsListData,
  submitPartnerEnquiry,
  submitWalletRechargeRequest,
  submitWalletPaytmRechargeRequest,
  getCouponsData,
  addNewCoupon,
  getPartnerDashboardData,
  getAuthMasterData,
  getMemberProfileData,
  partnerAdminLogin,
} from "./service";

/**
 * Action types
 */
export const actionTypes = {
  SHOW_SUB_LOADER: "SHOW_SUB_LOADER",
  STOP_SUB_LOADER: "STOP_SUB_LOADER",
  SHOW_PAYTM_LOADER: "SHOW_PAYTM_LOADER",
  STOP_PAYTM_LOADER: "STOP_PAYTM_LOADER",
  MASTER_DATA_REQUEST_SUCCESS: "MASTER_DATA_REQUEST_SUCCESS",
  AUTH_MASTER_DATA_REQUEST_SUCCESS: "AUTH_MASTER_DATA_REQUEST_SUCCESS",
  STUDENTS_DATA_REQUEST_SUCCESS: "STUDENTS_DATA_REQUEST_SUCCESS",
  ADD_NEW_STUDENT_REQUEST_SUCCESS: "ADD_NEW_STUDENT_REQUEST_SUCCESS",
  MEMBER_PACKAGES_REQUEST_SUCCESS: "MEMBER_PACKAGES_REQUEST_SUCCESS",
  TEACHERS_DATA_REQUEST_SUCCESS: "TEACHERS_DATA_REQUEST_SUCCESS",
  ADD_NEW_TEACHER_REQUEST_SUCCESS: "ADD_NEW_TEACHER_REQUEST_SUCCESS",
  SCHOOLS_DATA_REQUEST_SUCCESS: "SCHOOLS_DATA_REQUEST_SUCCESS",
  ADD_NEW_SCHOOL_REQUEST_SUCCESS: "ADD_NEW_SCHOOL_REQUEST_SUCCESS",
  PARTNER_PROFILE_DATA_REQUEST_SUCCESS: "PARTNER_PROFILE_DATA_REQUEST_SUCCESS",
  ASSIGN_PACKAGE_TO_MEMBER_REQUEST_SUCCESS: "ASSIGN_PACKAGE_TO_MEMBER_REQUEST_SUCCESS",
  PARTNER_WALLET_DATA_REQUEST_SUCCESS: "PARTNER_WALLET_DATA_REQUEST_SUCCESS",
  PARTNER_COMMISSION_DATA_REQUEST_SUCCESS: "PARTNER_COMMISSION_DATA_REQUEST_SUCCESS",
  PROMOTIONAL_MATERIAL_DATA_REQUEST_SUCCESS: "PROMOTIONAL_MATERIAL_DATA_REQUEST_SUCCESS",
  WALLET_WITHDRAWL_REQUEST_SUCCESS: "WALLET_WITHDRAWL_REQUEST_SUCCESS",
  WALLET_RECHARGE_REQUEST_SUCCESS: "WALLET_RECHARGE_REQUEST_SUCCESS",
  COMMISSION_PAYOUT_REQUEST_SUCCESS: "COMMISSION_PAYOUT_REQUEST_SUCCESS",
  NOTIFICATIONS_DATA_REQUEST_SUCCESS: "NOTIFICATIONS_DATA_REQUEST_SUCCESS",
  ADD_NEW_ENQUIRY_REQUEST_SUCCESS: "ADD_NEW_ENQUIRY_REQUEST_SUCCESS",
  WALLET_PAYTM_RECHARGE_REQUEST_SUCCESS: "WALLET_PAYTM_RECHARGE_REQUEST_SUCCESS",
  COUPONS_DATA_REQUEST_SUCCESS: "COUPONS_DATA_REQUEST_SUCCESS",
  ADD_NEW_COUPON_REQUEST_SUCCESS: "ADD_NEW_COUPON_REQUEST_SUCCESS",
  PARTNER_DASHBOARD_DATA_REQUEST_SUCCESS: "PARTNER_DASHBOARD_DATA_REQUEST_SUCCESS",
  MEMBER_PROFILE_DATA_REQUEST_SUCCESS: "MEMBER_PROFILE_DATA_REQUEST_SUCCESS",
  PARTNER_ADMIN_LOGIN_REQUEST_SUCCESS: "PARTNER_ADMIN_LOGIN_REQUEST_SUCCESS",
};

export const showPaytmLoaderAction = (payload) => ({
  type: actionTypes.SHOW_PAYTM_LOADER,
  payload,
});

export const stopPaytmLoaderAction = (payload) => ({
  type: actionTypes.STOP_PAYTM_LOADER,
  payload,
});

export const showSubLoaderAction = (payload) => ({
  type: actionTypes.SHOW_SUB_LOADER,
  payload,
});

export const stopSubLoaderAction = (payload) => ({
  type: actionTypes.STOP_SUB_LOADER,
  payload,
});

export const receivedMasterDataAction = (payload) => ({
  type: actionTypes.MASTER_DATA_REQUEST_SUCCESS,
  payload,
});

export const receivedAuthMasterDataAction = (payload) => ({
  type: actionTypes.AUTH_MASTER_DATA_REQUEST_SUCCESS,
  payload,
});

export const receivedStudentDataAction = (payload) => ({
  type: actionTypes.STUDENTS_DATA_REQUEST_SUCCESS,
  payload,
});

export const receivedNewStudentAction = (payload) => ({
  type: actionTypes.ADD_NEW_STUDENT_REQUEST_SUCCESS,
  payload,
});

export const receivedMemberPackagesAction = (payload) => ({
  type: actionTypes.MEMBER_PACKAGES_REQUEST_SUCCESS,
  payload,
});

export const receivedTeacherDataAction = (payload) => ({
  type: actionTypes.TEACHERS_DATA_REQUEST_SUCCESS,
  payload,
});

export const receivedNewTeacherAction = (payload) => ({
  type: actionTypes.ADD_NEW_TEACHER_REQUEST_SUCCESS,
  payload,
});

export const receivedSchoolDataAction = (payload) => ({
  type: actionTypes.SCHOOLS_DATA_REQUEST_SUCCESS,
  payload,
});

export const receivedNewSchoolAction = (payload) => ({
  type: actionTypes.ADD_NEW_SCHOOL_REQUEST_SUCCESS,
  payload,
});

export const receivedPartnerProfileDataAction = (payload) => ({
  type: actionTypes.PARTNER_PROFILE_DATA_REQUEST_SUCCESS,
  payload,
});

export const receivedAssignPackageToMemberAction = (payload) => ({
  type: actionTypes.ASSIGN_PACKAGE_TO_MEMBER_REQUEST_SUCCESS,
  payload,
});

export const receivedPartnerWalletDataAction = (payload) => ({
  type: actionTypes.PARTNER_WALLET_DATA_REQUEST_SUCCESS,
  payload,
});

export const receivedPartnerCommissionDataAction = (payload) => ({
  type: actionTypes.PARTNER_COMMISSION_DATA_REQUEST_SUCCESS,
  payload,
});

export const receivedPromotionalMaterialsDataAction = (payload) => ({
  type: actionTypes.PROMOTIONAL_MATERIAL_DATA_REQUEST_SUCCESS,
  payload,
});

export const receivedWalletWithdrawlRequestAction = (payload) => ({
  type: actionTypes.WALLET_WITHDRAWL_REQUEST_SUCCESS,
  payload,
});

export const receivedWalletRechargeRequestAction = (payload) => ({
  type: actionTypes.WALLET_RECHARGE_REQUEST_SUCCESS,
  payload,
});

export const receivedCommissionPayoutRequestAction = (payload) => ({
  type: actionTypes.COMMISSION_PAYOUT_REQUEST_SUCCESS,
  payload,
});

export const receivedNotificationsListDataAction = (payload) => ({
  type: actionTypes.NOTIFICATIONS_DATA_REQUEST_SUCCESS,
  payload,
});

export const receivedAddNewEnquiryAction = (payload) => ({
  type: actionTypes.ADD_NEW_ENQUIRY_REQUEST_SUCCESS,
  payload,
});

export const receivedWalletPaytmRechargeRequestAction = (payload) => ({
  type: actionTypes.WALLET_PAYTM_RECHARGE_REQUEST_SUCCESS,
  payload,
});

export const receivedCouponDataAction = (payload) => ({
  type: actionTypes.COUPONS_DATA_REQUEST_SUCCESS,
  payload,
});

export const receivedNewCouponAction = (payload) => ({
  type: actionTypes.ADD_NEW_COUPON_REQUEST_SUCCESS,
  payload,
});

export const receivedPartnerDashboardDataAction = (payload) => ({
  type: actionTypes.PARTNER_DASHBOARD_DATA_REQUEST_SUCCESS,
  payload,
});

export const receivedMemberProfileDataAction = (payload) => ({
  type: actionTypes.MEMBER_PROFILE_DATA_REQUEST_SUCCESS,
  payload,
});

export const getMasterDataAction = (data) => {
  return (dispatch) => {
    dispatch(showSubLoaderAction("getMasterData"));

    return getMasterData(data)
      .then((response) => {
        dispatch(stopSubLoaderAction("getMasterData"));

        if (response) dispatch(receivedMasterDataAction(response));
      })
      .catch((error) => {
        dispatch(stopSubLoaderAction("getMasterData"));
      });
  };
};

export const getAuthMasterDataAction = (data) => {
  return (dispatch) => {
    dispatch(showSubLoaderAction("getAuthMasterData"));

    return getAuthMasterData(data)
      .then((response) => {
        dispatch(stopSubLoaderAction("getAuthMasterData"));

        if (response) dispatch(receivedAuthMasterDataAction(response));
      })
      .catch((error) => {
        dispatch(stopSubLoaderAction("getAuthMasterData"));
      });
  };
};

export const getStudentsDataAction = (data) => {
  return (dispatch) => {
    dispatch(showLoaderAction("getStudentsData"));

    return getStudentsData(data)
      .then((response) => {
        dispatch(stopLoaderAction("getStudentsData"));

        if (response) dispatch(receivedStudentDataAction(response));
      })
      .catch((error) => {
        dispatch(stopLoaderAction("getStudentsData"));
      });
  };
};

export const addNewStudentAction = (data) => {
  return (dispatch) => {
    dispatch(showSubLoaderAction("addNewStudent"));
    return addNewStudent(data)
      .then((response) => {
        dispatch(stopSubLoaderAction("addNewStudent"));
        if (response) dispatch(receivedNewStudentAction({ ...response }));
      })
      .catch((error) => {
        // dispatch(receivedNewStudentAction({ error: true }));
        dispatch(stopSubLoaderAction("addNewStudent"));
        throw new Error(error);
      });
  };
};

export const getMemberPackagesDataAction = (data) => {
  return (dispatch) => {
    dispatch(showLoaderAction("getMemberPackagesData"));

    return getMemberPackagesData(data)
      .then((response) => {
        dispatch(stopLoaderAction("getMemberPackagesData"));

        if (response) dispatch(receivedMemberPackagesAction(response));
      })
      .catch((error) => {
        dispatch(stopLoaderAction("getMemberPackagesData"));
      });
  };
};

export const assignPackageToMemberAction = (data) => {
  return (dispatch) => {
    dispatch(showSubLoaderAction("assignPackageToMember"));
    return assignPackageToMember(data)
      .then((response) => {
        dispatch(stopSubLoaderAction("assignPackageToMember"));
        if (response) dispatch(receivedAssignPackageToMemberAction({ ...response }));
      })
      .catch((error) => {
        dispatch(stopSubLoaderAction("assignPackageToMember"));
        throw new Error(error);
      });
  };
};

export const getTeachersDataAction = (data) => {
  return (dispatch) => {
    dispatch(showLoaderAction("getTeachersData"));

    return getTeachersData(data)
      .then((response) => {
        dispatch(stopLoaderAction("getTeachersData"));

        if (response) dispatch(receivedTeacherDataAction(response));
      })
      .catch((error) => {
        dispatch(stopLoaderAction("getTeachersData"));
      });
  };
};

export const addNewTeacherAction = (data) => {
  return (dispatch) => {
    dispatch(showLoaderAction("addNewTeacher"));

    return addNewTeacher(data)
      .then((response) => {
        dispatch(stopLoaderAction("addNewTeacher"));

        if (response) dispatch(receivedNewTeacherAction({ error: null, ...response }));
      })
      .catch((error) => {
        dispatch(receivedNewTeacherAction({ error: true }));
        dispatch(stopLoaderAction("addNewTeacher"));
      });
  };
};

export const getSchoolsDataAction = (data) => {
  return (dispatch) => {
    dispatch(showLoaderAction("getSchoolsData"));

    return getSchoolsData(data)
      .then((response) => {
        dispatch(stopLoaderAction("getSchoolsData"));

        if (response) dispatch(receivedSchoolDataAction(response));
      })
      .catch((error) => {
        dispatch(stopLoaderAction("getSchoolsData"));
      });
  };
};

export const addNewSchoolAction = (data) => {
  return (dispatch) => {
    dispatch(showLoaderAction("addNewSchool"));

    return addNewSchool(data)
      .then((response) => {
        dispatch(stopLoaderAction("addNewSchool"));

        if (response) dispatch(receivedNewSchoolAction({ error: null, ...response }));
      })
      .catch((error) => {
        dispatch(receivedNewSchoolAction({ error: true }));
        dispatch(stopLoaderAction("addNewSchool"));
      });
  };
};

export const getPartnerProfileDataAction = () => {
  return (dispatch) => {
    dispatch(showLoaderAction("getPartnerProfile"));

    return getPartnerProfileData()
      .then((response) => {
        dispatch(stopLoaderAction("getPartnerProfile"));

        if (response) dispatch(receivedPartnerProfileDataAction(response));
      })
      .catch((error) => {
        dispatch(stopLoaderAction("getPartnerProfile"));
      });
  };
};

export const getPartnerWalletDataAction = (data) => {
  return (dispatch) => {
    dispatch(showLoaderAction("getPartnerWalletData"));

    return getPartnerWalletData(data)
      .then((response) => {
        dispatch(stopLoaderAction("getPartnerWalletData"));

        if (response) dispatch(receivedPartnerWalletDataAction(response));
      })
      .catch((error) => {
        dispatch(stopLoaderAction("getPartnerWalletData"));
      });
  };
};

export const getPartnerCommissionDataAction = (data) => {
  return (dispatch) => {
    dispatch(showLoaderAction("getPartnerCommissionData"));

    return getPartnerCommissionData(data)
      .then((response) => {
        dispatch(stopLoaderAction("getPartnerCommissionData"));

        if (response) dispatch(receivedPartnerCommissionDataAction(response));
      })
      .catch((error) => {
        dispatch(stopLoaderAction("getPartnerCommissionData"));
      });
  };
};

export const getPromotionalMaterialsDataAction = (data) => {
  return (dispatch) => {
    dispatch(showLoaderAction("getPromotionalMaterialsData"));

    return getPromotionalMaterialsData(data)
      .then((response) => {
        dispatch(stopLoaderAction("getPromotionalMaterialsData"));

        if (response) dispatch(receivedPromotionalMaterialsDataAction(response));
      })
      .catch((error) => {
        dispatch(stopLoaderAction("getPromotionalMaterialsData"));
      });
  };
};

export const walletWithdrawlRequestAction = (data) => {
  return (dispatch) => {
    dispatch(showSubLoaderAction("walletWithdrawlRequest"));
    return submitWalletWithdrawlRequest(data)
      .then((response) => {
        dispatch(stopSubLoaderAction("walletWithdrawlRequest"));
        if (response) dispatch(receivedWalletWithdrawlRequestAction({ ...response }));
      })
      .catch((error) => {
        dispatch(stopSubLoaderAction("walletWithdrawlRequest"));
        throw new Error(error);
      });
  };
};

export const commissionPayoutRequestAction = (data) => {
  return (dispatch) => {
    dispatch(showSubLoaderAction("commissionPayoutRequest"));
    return submitCommissionPayoutRequest(data)
      .then((response) => {
        dispatch(stopSubLoaderAction("commissionPayoutRequest"));
        if (response) dispatch(receivedCommissionPayoutRequestAction({ ...response }));
      })
      .catch((error) => {
        dispatch(stopSubLoaderAction("commissionPayoutRequest"));
        throw new Error(error);
      });
  };
};

export const notificationsListDataAction = (data) => {
  return (dispatch) => {
    dispatch(showLoaderAction("getNotificationsListData"));

    return getNotificationsListData(data)
      .then((response) => {
        dispatch(stopLoaderAction("getNotificationsListData"));

        if (response) dispatch(receivedNotificationsListDataAction(response));
      })
      .catch((error) => {
        dispatch(stopLoaderAction("getNotificationsListData"));
      });
  };
};

export const addNewEnquiryAction = (data) => {
  return (dispatch) => {
    dispatch(showSubLoaderAction("addNewEnquiry"));
    return submitPartnerEnquiry(data)
      .then((response) => {
        dispatch(stopSubLoaderAction("addNewEnquiry"));
        if (response) dispatch(receivedAddNewEnquiryAction({ ...response }));
      })
      .catch((error) => {
        dispatch(stopSubLoaderAction("addNewEnquiry"));
        throw new Error(error);
      });
  };
};

export const walletRechargeRequestAction = (data, files) => {
  return (dispatch) => {
    dispatch(showSubLoaderAction("walletRechargeRequest"));
    return submitWalletRechargeRequest(data, files)
      .then((response) => {
        dispatch(stopSubLoaderAction("walletRechargeRequest"));
        if (response) dispatch(receivedWalletRechargeRequestAction({ ...response }));
      })
      .catch((error) => {
        dispatch(stopSubLoaderAction("walletRechargeRequest"));
        throw new Error(error);
      });
  };
};

export const walletPaytmRechargeRequestAction = (data) => {
  return (dispatch) => {
    dispatch(showPaytmLoaderAction("walletPaytmRechargeRequest"));
    return submitWalletPaytmRechargeRequest(data)
      .then((response) => {
        dispatch(stopPaytmLoaderAction("walletPaytmRechargeRequest"));
        if (response) return dispatch(receivedWalletPaytmRechargeRequestAction({ ...response }));
      })
      .catch((error) => {
        dispatch(stopPaytmLoaderAction("walletPaytmRechargeRequest"));
        throw new Error(error);
      });
  };
};

export const getCouponsDataAction = (data) => {
  return (dispatch) => {
    dispatch(showLoaderAction("getCouponsData"));

    return getCouponsData(data)
      .then((response) => {
        dispatch(stopLoaderAction("getCouponsData"));

        if (response) dispatch(receivedCouponDataAction(response));
      })
      .catch((error) => {
        dispatch(stopLoaderAction("getCouponsData"));
      });
  };
};

export const submitNewCouponRequestAction = (data) => {
  return (dispatch) => {
    dispatch(showSubLoaderAction("addNewCoupon"));
    return addNewCoupon(data)
      .then((response) => {
        dispatch(stopSubLoaderAction("addNewCoupon"));
        if (response) dispatch(receivedNewCouponAction({ ...response }));
      })
      .catch((error) => {
        // dispatch(receivedNewCouponAction({ error: true }));
        dispatch(stopSubLoaderAction("addNewCoupon"));
        throw new Error(error);
      });
  };
};

export const getPartnerDashboardDataAction = () => {
  return (dispatch) => {
    dispatch(showLoaderAction("getPartnerDashboardData"));

    return getPartnerDashboardData()
      .then((response) => {
        dispatch(stopLoaderAction("getPartnerDashboardData"));

        if (response) dispatch(receivedPartnerDashboardDataAction(response));
      })
      .catch((error) => {
        dispatch(stopLoaderAction("getPartnerDashboardData"));
      });
  };
};

export const getMemberProfileDataAction = (data) => {
  return (dispatch) => {
    dispatch(showSubLoaderAction("getMemberProfile"));
    return getMemberProfileData(data)
      .then((response) => {
        dispatch(stopSubLoaderAction("getMemberProfile"));
        if (response) dispatch(receivedMemberProfileDataAction({ ...response }));
      })
      .catch((error) => {
        // dispatch(receivedNewCouponAction({ error: true }));
        dispatch(stopSubLoaderAction("getMemberProfile"));
        throw new Error(error);
      });
  };
};

export const receivedPartnerAdminLoginAction = (payload) => ({
  type: actionTypes.PARTNER_ADMIN_LOGIN_REQUEST_SUCCESS,
  payload,
});

export const partnerAdminLoginAction = (data) => {
  return (dispatch) => {
    dispatch(showLoaderAction("partnerAdminLogin"));

    return partnerAdminLogin(data)
      .then((response) => {
        dispatch(stopLoaderAction("partnerAdminLogin"));

        if (response && response.access_token) {
          dispatch(receivedPartnerAdminLoginAction(response));
          common.setToStorage("token", response.access_token);
          common.setToStorage("isAuthenticated", "true");
          common.setToStorage("partner", JSON.stringify(response.user));
          if (response.user.is_profile_completed === 1) {
            common.setToStorage("userName", response.user && response.user.name);
            common.setToStorage("user", response.user && JSON.stringify({ mobile: response.user.mobile, country_code: response.user.country_code }));
            common.setToStorage("profile", response.user && JSON.stringify(response.user));
            common.setToStorage("features", "[]");
          }
          return true;
        } else {
          return false;
        }
      })
      .catch((error) => {
        dispatch(stopLoaderAction("partnerAdminLogin"));
        return false;
      });
  };
};
