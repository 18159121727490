import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Container, Row, Col } from "react-bootstrap";
import CourseItemGrid from "./components/CourseItemsGrid";
import FooterTwo from "../../components/FooterTwo";
import { Styles } from "../dashboard/styles/dashboard.js";
import UserHeader from "../../components/UserHeader";
import DashboardMenu from "../dashboard/components/DashboardMenu";
import { subsubcategoriesDataAction, setSubsubcategoriesFormDataAction } from "./action";
import DashboardFilter from "../dashboard/components/DashboardFilter";
import { handleReloadAppAction } from "../user/action";
import LoaderNew from "../../components/LoaderNew";

const TeacherTechnologyKit = (props) => {
  useEffect(() => {
    if (!props.formData.category_id) {
      props.subsubcategoriesData([]);
    } else if (props.formData.subcategory_id) {
      props.subsubcategoriesData([props.formData.subcategory_id]);
    }
  }, [props.formData.subcategory_id]);

  return (
    <div className="main-wrapper course-page">
      {/* User Header */}
      <UserHeader />

      <Styles>
        {/* Course Grid */}
        <section className="dashboard-grid-area">
          <Container>
            <Row>
              <Col lg="3" md="3" sm="12">
                <DashboardMenu {...props} index={4} />
              </Col>
              <Col>
                {props.showLoader && props.showLoader.length > 0 && <LoaderNew />}
                {props.showLoader.length === 0 && (
                  <>
                    <Row>
                      <Col lg="12" md="12" sm="12">
                        <DashboardFilter {...props} categories={props.categories.filter((category) => category.is_robotics === 0)} />
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="12" md="12" sm="12">
                        <div className="course-items">
                          <CourseItemGrid
                            data={props.subsubcategories}
                            canBuy={true}
                            headerText="Teacher Technology Kit"
                            notFoundText="No data found."
                          />
                        </div>
                      </Col>
                    </Row>
                  </>
                )}
              </Col>
            </Row>
          </Container>
        </section>
      </Styles>

      {/* Footer 2 */}
      <FooterTwo />
    </div>
  );
};

const mapStateToProps = (state) => ({
  categories: state.subsubcategories.data.categories,
  learnings: state.subsubcategories.data.learnings,
  subsubcategories: state.subsubcategories.data.subsubcategories,
  formData: state.subsubcategories.formData,
  showLoader: state.user.showLoader,
});

const mapDispatchToProps = (dispatch) => ({
  subsubcategoriesData: (data) => dispatch(subsubcategoriesDataAction(data)),
  setFormData: (data) => dispatch(setSubsubcategoriesFormDataAction(data)),
  handleReloadApp: () => dispatch(handleReloadAppAction()),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TeacherTechnologyKit));
