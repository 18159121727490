import styled from "styled-components";
import { colors } from "../common/element/elements.js";

export const Styles = styled.div`
    .expert-teacher-area {
        background-size    : cover;
        background-position: center;
        background-repeat  : no-repeat;
        padding            : 63px 0;
        position           : relative;

        &:before {
            position  : absolute;
            content   : '';
            background: #2c3440;
            opacity   : 0.98;
            width     : 100%;
            height    : 100%;
            top       : 0;
            left      : 0;
        }

        &:after {
            position  : absolute;
            content   : '';
            background: url("/assets/images/expert_teachers/effect.webp");
            background-repeat  : no-repeat;
            top: 30%;
            right: 0;
            height: 200px;
            width: 57px;
        }

        .sec-title {
            h4 {
                color: yellow;
                font-size: 30px;
                line-height: 35px;
                font-weight: 600;
                margin: auto;
                margin-bottom: 43px;

                @media(max-width: 575px) {
                    margin-bottom: 15px;
                    font-size: 20px;
                }
            }
        }

        .expert-teacher-slider {
            .effect-pattern{
                position: absolute;
                right: 0;
                top: 30%;
            }
            .expert-item{
                display: flex;
                justify-content: center;
                align-items: center;
                @media (max-width: 575px) {
                    display: block;
                }
            }
            .slider-image-block {
                width: 30%;
                padding: 10px;
                @media (max-width: 575px) {
                    width: 100%;
                }
            }
            .slider-content{
                width: 70%;
                @media (max-width: 575px) {
                    width: 100%;
                }
            }
            .slider-item {
                .slider-text{
                    padding         : 30px 38px;
                    color: white;
                    p
                    {
                        font-size: 16px;
                        margin-bottom: 10px;
                    }
                }
                .desc {
                    background-color: rgba(255, 255, 255, 0.08);
                    padding         : 30px 38px;
                    border-radius : 5px;
                    position: relative;

                    &::before {
                        content         : "";
                        position        : absolute;
                        border-width    : 15px 15px;
                        border-style    : solid;
                        border-top-color: rgba(255, 255, 255, 0.08);
                        ;
                        border-right-color : transparent;
                        border-bottom-color: transparent;
                        border-left-color  : transparent;
                        top                : 100%;
                        left               : 47px;
                        z-index            : 1;
                    }

                    h5 {
                        font-size    : 18px;
                        color        : ${colors.border1};
                        margin-bottom: 15px;
                    }

                    p {
                        font-size  : 13px;
                        color      : ${colors.border3};
                        line-height: 25px;
                    }
                }

                .writer {
                    margin-top : 35px;
                    margin-left: 30px;

                    img {
                        max-width: 65px;
                        border-radius : 50%;
                        float       : left;
                        margin-right: 15px;
                    }

                    h6 {
                        color        : ${colors.border1};
                        padding-top  : 10px;
                        margin-bottom: 3px;
                        margin-left : 10px;
                        font-size: 30px;
                    }

                    p {
                        color: ${colors.border3};
                        margin-left: 10px;
                        font-size: 18px;
                    }
                }
            }

            .slider-dot {
                margin-top: 48px !important;

                .swiper-pagination-bullet {
                    width     : 30px;
                    height    : 3px;
                    background: ${colors.text4};
                    display   : inline-block;
                    margin    : 3px;
                    opacity   : 1 !important;
                    border-radius : 5px;
                }

                .swiper-pagination-bullet.swiper-pagination-bullet-active {
                    background: ${colors.green};
                }
            }
        }

        @media(max-width: 767px) {
            padding: 30px 0;
        }
    }
`;