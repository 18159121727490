import React, { useEffect, useState } from "react";
import { Col, Dropdown, Row } from "react-bootstrap";
import IntlTelInput from "react-intl-tel-input";

import { messages } from "../../../shared/constants.js";
import "react-intl-tel-input/dist/main.css";

function PhoneFactor(props) {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [countryCode, setCountryCode] = useState("+91");
  const handlePhoneChange = (status, number, countryObj, finalValue) => {
    setCountryCode("+" + countryObj.dialCode);
    const re = /^[0-9\b]+$/;
    if (number !== "" && re.test(number) && number.length <= 10) {
      setPhoneNumber(number);
      onFieldChange("mobileConnect", number);
    } else if (number === "") {
      setPhoneNumber(number);
      onFieldChange("mobileConnect", number);
    }
  };

  useEffect(() => {
    return () => setFormData({});
  }, []);

  const [formData, setFormData] = useState({
    country_code: countryCode,
  });

  const handleCountryChange = (number, obj, finalValue, status) => {
    setCountryCode("+" + obj.dialCode);
    setPhoneNumber(number);
    onFieldChange("mobileConnect", number);
  };

  const onFieldChange = (name, value) => {
    setFormData({
      ...formData,
      [name]: value,
    });

    resetError(name);
  };

  const onLoginNextClick = (e) => {
    e.preventDefault();
    if (isValidLoginFormData()) {
      const _formData = {
        country_code: formData.country_code,
        mobile: formData.mobileConnect,
        name: formData.name,
        request_type: 1,
      };

      props.newRequest(_formData).then((response) => {
        if (response) props.setStep(1);
      });
    }
  };

  const isValidLoginFormData = () => {
    let isValid = true;

    if (!formData || !formData.name) {
      setError("name", messages.requiredField);
      isValid = false;
    } else if (formData.name.length < 3 || formData.name.length > 20) {
      setError("name", "name should be 3 to 20 characters long");
      isValid = false;
    } else {
      resetError("name");
    }

    if (!formData || !formData.mobileConnect) {
      setError("mobileConnect", messages.requiredField);
      isValid = false;
    } else if (isNaN(formData.mobileConnect)) {
      setError("mobileConnect", messages.numberField);
      isValid = false;
    } else if (formData.mobileConnect.length !== 10 && countryCode === "+91") {
      setError("mobileConnect", messages.tenDigitsField);
      isValid = false;
    } else {
      resetError("mobileConnect");
    }

    return isValid;
  };

  const setError = (input, message) => {
    let formControl;
    if (input === "mobileConnect") {
      formControl = document.getElementsByName(input)[0].parentElement.parentElement;
    } else {
      formControl = document.getElementsByName(input)[0].parentElement;
    }

    const errorMsg = formControl.querySelector(".login_input-msg");
    formControl.classList.add("error");
    formControl.classList.add("text-left");
    errorMsg.innerText = message;
  };

  const resetError = (input) => {
    let formControl;
    if (input === "mobileConnect") {
      formControl = document.getElementsByName(input)[0].parentElement.parentElement;
    } else {
      formControl = document.getElementsByName(input)[0].parentElement;
    }
    const errorMsg = formControl.querySelector(".login_input-msg");
    formControl.classList.remove("error");
    formControl.classList.remove("text-left");
    errorMsg.innerText = "";
  };

  return (
    <>
      <div className="side-content">
        <h5>Let's connect</h5>
        <p>Enter your details to submit your request to us</p>
      </div>
      <div className="side-post">
        {/* Login Area */}
        <section className="login-area">
          <Row>
            <Col md="12">
              <div className="login-box">
                <form id="form_login" className="form" onSubmit={onLoginNextClick}>
                  <div className="form-control">
                    <Row>
                      <Col md="12">
                        <input
                          type="text"
                          placeholder="Name"
                          name="name"
                          id="name"
                          minLength={3}
                          maxLength={20}
                          value={formData.name}
                          onChange={(e) => {
                            onFieldChange(e.target.name, e.target.value);
                          }}
                        />
                        <span className="login_input-msg"></span>
                      </Col>
                    </Row>
                  </div>
                  <div className="form-control">
                    <Row>
                      <Col md="12">
                        <IntlTelInput
                          fieldName="mobileConnect"
                          formatOnInit={false}
                          containerClassName="intl-tel-input"
                          inputClassName="phone-number"
                          preferredCountries={["in", "us", "ae"]}
                          value={phoneNumber}
                          onPhoneNumberBlur={handlePhoneChange}
                          onPhoneNumberChange={handlePhoneChange}
                          onSelectFlag={handleCountryChange}
                          separateDialCode
                        />
                        <span className="login_input-msg"></span>
                      </Col>
                    </Row>
                  </div>
                  <button type="button" onClick={onLoginNextClick}>
                    Submit
                  </button>
                </form>
              </div>
            </Col>
          </Row>
        </section>
      </div>
    </>
  );
}

export default PhoneFactor;
