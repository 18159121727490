import { showLoaderAction, stopLoaderAction } from "../user/action";
import { getMasterData, sendCampaignOTP, verifyCampaignOTP } from "./service";
/**
 * Action types
 */
export const actionTypes = {
  MASTER_DATA_REQUEST_SUCCESS: "MASTER_DATA_REQUEST_SUCCESS",
  SEND_CAMPAIGN_REQUEST_SUCCESS: "SEND_CAMPAIGN_REQUEST_SUCCESS",
  VERIFY_CAMPAIGN_REQUEST_SUCCESS: "VERIFY_CAMPAIGN_REQUEST_SUCCESS",
};

export const receivedMasterDataAction = (payload) => ({
  type: actionTypes.MASTER_DATA_REQUEST_SUCCESS,
  payload,
});

export const receivedSendCampaignOTPAction = (payload) => ({
  type: actionTypes.SEND_CAMPAIGN_REQUEST_SUCCESS,
  payload,
});

export const receivedVerifyCampaignOTPAction = (payload) => ({
  type: actionTypes.VERIFY_CAMPAIGN_REQUEST_SUCCESS,
  payload,
});

export const getMasterDataAction = (data) => {
  return (dispatch) => {
    dispatch(showLoaderAction("getMasterData"));

    return getMasterData(data)
      .then((response) => {
        dispatch(stopLoaderAction("getMasterData"));

        if (response) dispatch(receivedMasterDataAction(response));
      })
      .catch((error) => {
        dispatch(stopLoaderAction("getMasterData"));
      });
  };
};

export const sendCampaignOTPAction = (data) => {
  return (dispatch) => {
    dispatch(showLoaderAction("sendCampaignOTP"));
    return sendCampaignOTP(data)
      .then((response) => {
        dispatch(stopLoaderAction("sendCampaignOTP"));
        if (response) dispatch(receivedSendCampaignOTPAction({ ...response }));
      })
      .catch((error) => {
        // dispatch(receivedNewStudentAction({ error: true }));
        dispatch(stopLoaderAction("sendCampaignOTP"));
        throw new Error(error);
      });
  };
};

export const verifyCampaignOTPAction = (data) => {
  return (dispatch) => {
    dispatch(showLoaderAction("verifyCampaignOTP"));
    return verifyCampaignOTP(data)
      .then((response) => {
        dispatch(stopLoaderAction("verifyCampaignOTP"));
        if (response) dispatch(receivedVerifyCampaignOTPAction({ ...response }));
      })
      .catch((error) => {
        // dispatch(receivedNewStudentAction({ error: true }));
        dispatch(stopLoaderAction("verifyCampaignOTP"));
        throw new Error(error);
      });
  };
};
