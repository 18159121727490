import common from "../../shared/common";
import { getMasterData, updateMemberProfile } from "./service";

/**
 * Action types
 */
export const actionTypes = {
  SHOW_SUB_LOADER: "SHOW_SUB_LOADER",
  STOP_SUB_LOADER: "STOP_SUB_LOADER",
  MASTER_DATA_REQUEST_SUCCESS: "MASTER_DATA_REQUEST_SUCCESS",
  UPDATE_MEMBER_PROFILE_REQUEST_SUCCESS: "UPDATE_MEMBER_PROFILE_REQUEST_SUCCESS",
};

export const showSubLoaderAction = (payload) => ({
  type: actionTypes.SHOW_SUB_LOADER,
  payload,
});

export const stopSubLoaderAction = (payload) => ({
  type: actionTypes.STOP_SUB_LOADER,
  payload,
});

export const receivedMasterDataAction = (payload) => ({
  type: actionTypes.MASTER_DATA_REQUEST_SUCCESS,
  payload,
});

export const receivedUpdateMemberProfileAction = (payload) => ({
  type: actionTypes.UPDATE_MEMBER_PROFILE_REQUEST_SUCCESS,
  payload,
});

export const getMasterDataAction = (data) => {
  return (dispatch) => {
    dispatch(showSubLoaderAction("getMasterData"));

    return getMasterData(data)
      .then((response) => {
        dispatch(stopSubLoaderAction("getMasterData"));

        if (response) dispatch(receivedMasterDataAction(response));
      })
      .catch((error) => {
        dispatch(stopSubLoaderAction("getMasterData"));
      });
  };
};

export const updateMemberProfileAction = (data) => {
  return (dispatch) => {
    dispatch(showSubLoaderAction("updateProfile"));

    return updateMemberProfile(data)
      .then((response) => {
        dispatch(stopSubLoaderAction("updateProfile"));

        if (response) {
          dispatch(receivedUpdateMemberProfileAction(response));
          if (response.data.user.is_profile_completed === 1) {
            common.setToStorage("userName", response.data.user && response.data.user.name);
            common.setToStorage("profile", response.data.user && JSON.stringify(response.data.user));
          }
          return true;
        } else {
          return false;
        }
      })
      .catch((error) => {
        dispatch(stopSubLoaderAction("updateProfile"));
        return false;
      });
  };
};
