import styled from "styled-components";
import { colors } from "../../../components/common/element/elements.js";

export const Styles = styled.div`
    .w-full {
        width: 100%;
        max-width: 100%;
        padding: 0;
    }
    .makeStyles-root-1 {
        color: rgb(17, 24, 39);
        background-color: #f6f7f9;
    }
    .makeStyles-root-19 {
        color: #fff;
        background: linear-gradient(to right, #1b2330 0%, rgb(13, 17, 24) 100%);
        @media(max-width: 991px) {
            flex-direction: row !important;
        }
    }
    .flex {
        display: flex;
    }
    .flex-auto {
        flex: 1 1 auto;
    }
    .flex-col {
        flex-direction: column;
    }
    .items-center {
        align-items: center;
    }
    .justify-center {
        justify-content: center;
    }
    .flex-shrink-0 {
        flex-shrink: 0;
    }
    .flex-1 {
        flex: 1 1 0%;
    }
    .p-16 {
        padding: 1.6rem;
    }
    .max-w-400 {
        max-width: 40rem;
    }
    .overflow-hidden {
        overflow: hidden;
    }
    .shadow-2xl {
        --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
        box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
    }
    .rounded-20 {
        border-radius: 2rem;
    }
    .max-w-sm {
        max-width: 48rem;
        @media(max-width: 991px) {
            max-width: 100%;
        }
    }
    .p-64 {
        padding: 6.4rem;
    }
    .min-w-0 {
        min-width: 0;
    }
    .z-10 {
        z-index: 10;
    }
    .relative {
        position: relative;
    }
    .min-h-screen {
        min-height: 100vh;
    }
    .shadow-0 {
        --tw-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
        box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
    }
    MuiCard-root-22 {
        overflow: hidden;
    }
    .py-96 {
        padding-top: 9.6rem;
        padding-bottom: 9.6rem;
    }
    .max-w-320 {
        max-width: 32rem;
    }
    .mb-48 {
        margin-bottom: 4.8rem;
    }
    img, video {
        max-width: 100%;
        height: auto;
    }
    .w-1 {
        width: 0.1rem;
    }
    .mr-4 {
        margin-right: 0.4rem;
    }
    .h-40 {
        height: 4rem;
    }
    .border-l-1 {
        border-left-width: 1px;
    }
    .text-24 {
        font-size: 2.4rem;
    }
    .font-semibold {
        font-weight: 600;
    }
    .tracking-widest {
        letter-spacing: 0.1em;
    }
    .-mt-8 {
        margin-top: -0.8rem;
    }
    .mb-16 {
        margin-bottom: 1.6rem;
    }
    .text-16 {
        font-size: 1.6rem;
    }
    .font-700 {
        font-weight: 700;
    }
    .MuiTypography-colorTextSecondary-78 {
        color: rgb(107, 114, 128);
    }
    [class*="border-"] {
        border-color: rgba(0, 0, 0, 0.12);
    }
    .makeStyles-rightSection-21 {
        color: #fff;
        background: linear-gradient(to right, #1b2330 0%, rgb(13, 17, 24) 100%);
    }
    .leading-tight {
        line-height: 1.25;
    }
    .font-semibold {
        font-weight: 600;
    }
    .min-h-0 {
        min-height: 0;
    }

    .mt-32 {
        margin-top: 3.2rem;
    }
    h3 {
        font-size: 3.457142857142857rem;
    }
    .MuiButton-root-197 {
        color: rgb(17, 24, 39);
        padding: 6px 16px;
        font-size: 1.3rem;
        min-width: 64px;
        box-sizing: border-box;
        transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        font-family: Poppins,Roboto,"Helvetica",Arial,sans-serif;
        font-weight: 500;
        line-height: 1.75;

        border-radius: 18px;
        text-transform: none;
    }
    .background-image {
        background: url(https://via.placeholder.com/500);
        background-repeat: no-repeat;
        background-position: right;
        background-size: cover;
    }

    .MuiButton-containedPrimary-206 {
        color: #fff;
        background-color: #252f3e;
    }
    .MuiButton-containedPrimary-206:hover {
        background-color: #1b2330;
    }
    .max-w-3xl {
        max-width: 100rem;
        @media(max-width: 991px) {
            flex-direction: column !important;
        }
    }
    @media(min-width: 960px) {
        .p-24 {
            padding: 2.4rem;
        }
        
        .flex {
            display: flex;
        }
    } 
`;