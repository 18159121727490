import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import FooterTwo from "../../components/FooterTwo";
import HeaderTwo from "../../components/HeaderTwo";
import TestimonialSlider from "../../components/TestimonialSlider";
import AppSection from "../../components/AppSection";
import FeatureHeroImage from "../../components/FeatureHeroImage";
import ThreeSection from "../../components/ThreeSection";
import VideoSlider from "../../components/VideoSlider";
import ButtonArea from "../../components/ButtonArea";
import { Container, Row, Col } from "react-bootstrap";
import common from "../../shared/common";
import { webUrl } from "../../shared/constants";
import { Styles } from "../../components/styles/videoSlider";
import { Button, List, ListItem, ListItemIcon, ListItemText, Typography } from "@mui/material";
import ContentPasteSearchIcon from "@mui/icons-material/ContentPasteSearch";
import SelfImprovementIcon from "@mui/icons-material/SelfImprovement";
import LocalLibraryIcon from "@mui/icons-material/LocalLibrary";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import InventoryIcon from "@mui/icons-material/Inventory";
import CheckIcon from "@mui/icons-material/Check";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import CountUp from "react-countup";
import Connect from "../connect";
import User from "../user";
import { Styles as StudyStyles } from "./style.js";
import { sendAuditEventAction } from "../../components/actions/common";

const StudyMaterial = (props) => {
  const idPref = Math.random();
  const idPref2 = Math.random();
  const onClickJoinNow = () => {
    if (!!common.getFromStorage("userName")) {
      props.history.push(webUrl.userDashboard);
    }
  };
  useEffect(() => {
    if (common.getFromStorage("userName")) props.sendAuditEvent({ event_id: "study_material", source: "web" });
  }, []);

  return (
    <StudyStyles>
      <div className="main-wrapper">
        {/* Header */}
        <HeaderTwo {...props} />

        <section className="slider-content study_material-bg">
          <Container fluid>
            <Row style={{ position: "relative" }}>
              <img src={process.env.PUBLIC_URL + "/assets/images/banners/study_material_desktop.webp"} alt="" className="p-absolute study-desktop" />
            </Row>
          </Container>
          <Container>
            <Row className="">
              <Col lg="5" md="5" sm="5" xs="5" className="slider-content-wrapper">
                <div className="slider-content-text">
                  <h2 className="slider-title">india's Best Learning App</h2>
                  <h3 className="slider-subtitle">For Science and Maths Curriculum Grade 9th - 10th</h3>
                  <div className="">
                    <Button
                      variant="contained"
                      size="large"
                      className={"mt-3 mb-3 orange-bg top-left-in"}
                      id={"hero-btn" + idPref}
                      onClick={onClickJoinNow}
                    >
                      Enrol Now
                    </Button>
                    <User openCommand={true} commandCtrlId={"hero-btn" + idPref} />
                  </div>
                  <div className="bottom-block">
                    <List>
                      <ListItem sx={{ padding: 0 }}>
                        <ListItemText
                          disableTypography
                          sx={{ margin: 0 }}
                          primary={
                            <Typography type="body2" style={{ fontSize: "1.1vw", fontWeight: "400", color: "white", margin: 0 }}>
                              "The Best Guideline for your Kids"
                            </Typography>
                          }
                        />
                      </ListItem>
                    </List>
                  </div>
                </div>
              </Col>
              <Col lg={{ span: 7 }} md="7" sm="7" xs="7" className="pb-5"></Col>
            </Row>
          </Container>
        </section>
        <section className="py-5 features-section">
          <Container>
            <Row>
              <Col md="12">
                <div className="sec-title text-center">
                  <h4>Learn from Experts, Fall in Love with Science and Maths</h4>
                </div>
              </Col>
            </Row>
            <Row>
              <Col md="4">
                <Row>
                  <Col>
                    <div className="feature-block feature-1">
                      <div className="feature-icon">
                        <ContentPasteSearchIcon />
                      </div>
                      <div className="feature-title">Content Library</div>
                      <div className="feature-excerpt">
                        <ul>
                          <li>
                            <ChevronRightIcon />
                            Curriculum based Concept 3D HD Videos
                          </li>
                          <li>
                            <ChevronRightIcon />
                            Detailed Theory with Short Video Cross References
                          </li>
                          <li>
                            <ChevronRightIcon />
                            NCERT Chapters
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="feature-block feature-2">
                      <div className="feature-icon">
                        <SelfImprovementIcon />
                      </div>
                      <div className="feature-title">Self-Assessment</div>
                      <div className="feature-excerpt">
                        <ul>
                          <li>
                            <ChevronRightIcon />
                            Alyss Online Tests and Year-End Online Exam
                          </li>
                          <li>
                            <ChevronRightIcon />
                            Global Percentile
                          </li>
                          <li>
                            <ChevronRightIcon />
                            Performance Analysis
                          </li>
                        </ul>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col md="4">
                <div className="feature-block mb-2 feature-3">
                  <div className="feature-icon">
                    <LocalLibraryIcon />
                  </div>
                  <div className="feature-title">Affordable Learning</div>
                  <div className="feature-excerpt">
                    <ul>
                      <li>
                        <ChevronRightIcon />
                        Multiple Affordable Packages
                      </li>
                      <li>
                        <ChevronRightIcon />
                        High Quality
                      </li>
                      <li>
                        <ChevronRightIcon />
                        Low Cost
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="mt-0">
                  <img alt="" src={process.env.PUBLIC_URL + "/assets/images/promotions/study_features_image.webp"} className="img-fluid" />
                </div>
              </Col>
              <Col md="4">
                <div className="feature-block feature-4">
                  <div className="feature-icon">
                    <QuestionAnswerIcon />
                  </div>
                  <div className="feature-title">Doubt Solving Sessions</div>
                  <div className="feature-excerpt">
                    <ul>
                      <li>
                        <ChevronRightIcon />
                        Live Sessions for Queries
                      </li>
                      <li>
                        <ChevronRightIcon />
                        Personal Attention
                      </li>
                      <li>
                        <ChevronRightIcon />
                        Revision of Concepts
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="feature-block feature-5">
                  <div className="feature-icon">
                    <InventoryIcon />
                  </div>
                  <div className="feature-title">Resource for Practice</div>
                  <div className="feature-excerpt">
                    <ul>
                      <li>
                        <ChevronRightIcon />
                        Test Series (15000+ questions)
                      </li>
                      <li>
                        <ChevronRightIcon />
                        Descriptive Sample Papers
                      </li>
                      <li>
                        <ChevronRightIcon />
                        Solved Exercise Questions
                      </li>
                    </ul>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </section>

        <section className="about-us pt-5 mb-5">
          <Container>
            <Row>
              <Col md="6">
                <div className="about-image">
                  <img src={process.env.PUBLIC_URL + `/assets/images/about/study_material_about.webp`} className="main-img" alt="" />
                  <img src={process.env.PUBLIC_URL + "/assets/images/about/pattern.webp"} className="pattern-img" alt="" />
                </div>
              </Col>
              <Col md="6">
                <div className="about-content">
                  <h4 className="about-title">From Rote Learning to Experiential Learning</h4>
                  <p className="about-para">
                    "If you can't explain it simply, you don't understand it well enough", we follow this principle in designing the content for
                    classrooms ensuring that explanations are lucid enough for students to grasp and understand. With the fast-diminishing attention
                    span, 3D HD animations recreates excitement and improves students' understanding. Our K12 Science and Maths content for Grades 9
                    to 10 includes 100+ 3D HD Videos, Theory, Test Series (15000+ questions), Sample Papers and Solved Exercise Questions.
                  </p>
                  <p className="about-para">
                    Founded by an IIT alumnus with a strong passion for Science and Maths, Alyss is a team of highly qualified content and animation
                    experts sharing a zeal for science.
                  </p>
                  <Row>
                    <Col sm="6">
                      <div className="counter-box box1 text-center">
                        <h3>
                          <CountUp end={10000} duration={5} delay={1.5} />
                          <i className="las la-plus"></i>
                        </h3>
                        <p>Happy Students</p>
                      </div>
                    </Col>
                    <Col sm="6">
                      <div className="counter-box box2 text-center">
                        <h3>
                          <CountUp end={2000} duration={5} delay={1.5} />
                          <i className="las la-plus"></i>
                        </h3>
                        <p>Teachers</p>
                      </div>
                    </Col>
                    {/* <Col sm="4">
                      <div className="counter-box box3 text-center">
                        <h3>
                          <CountUp end={340} duration={5} delay={1.5} />
                          <i className="las la-plus"></i>
                        </h3>
                        <p>Courses</p>
                      </div>
                    </Col> */}
                  </Row>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        {/* Button Area */}
        <ButtonArea buttonText="Connect" onHeroButtonClick={onClickJoinNow} showPopup={!!!common.getFromStorage("userName")} />
        {/* Three Section */}
        {/* <ThreeSection /> */}
        <section className="pt-5">
          <Container>
            <Row className="explore-section">
              <Col sm="4">
                <div className="section-title">India's Best Learning App</div>
                <div className="section-subtitle">for Science and Maths Curriculum (Grade 9th - 10th)</div>
                <div className="explore-button">
                  <Button variant="contained" size="large" className={"mt-3 mb-3"} id={"hero-btn" + idPref2} onClick={onClickJoinNow}>
                    Register for Free
                  </Button>
                </div>
                <User openCommand={true} commandCtrlId={"hero-btn" + idPref2} />
              </Col>
              <Col sm="4">
                <Row className="explore-box-top">
                  <Col xs="6" className="box-left">
                    <div className="box-title">Absorb</div>
                    <div className="box-points">
                      <ul>
                        <li>
                          <ChevronRightIcon />
                          Concept 3D HD Animation Videos
                        </li>
                        <li>
                          <ChevronRightIcon />
                          Theory
                        </li>
                        <li>
                          <ChevronRightIcon />
                          Doubt Solving Sessions
                        </li>
                      </ul>
                    </div>
                  </Col>
                  <Col xs="6" className="box-right">
                    <img className="img-fluid" src={process.env.PUBLIC_URL + "/assets/images/promotions/3D-Video.webp"} alt="" />
                  </Col>
                </Row>
                <Row className="explore-box-bottom">
                  <Col xs="6" className="box-left">
                    <div className="box-title">Apply</div>
                    <div className="box-points">
                      <ul>
                        <li>
                          <ChevronRightIcon />
                          Observation based MCQ
                        </li>
                        <li>
                          <ChevronRightIcon />
                          Practice Sample Papers
                        </li>
                        <li>
                          <ChevronRightIcon />
                          NCERT Exercises
                        </li>
                      </ul>
                    </div>
                  </Col>
                  <Col xs="6" className="box-right">
                    <img className="img-fluid" src={process.env.PUBLIC_URL + "/assets/images/promotions/MCQ's.webp"} alt="" />
                  </Col>
                </Row>
              </Col>
              <Col sm="4">
                <Row className="explore-box-right">
                  <Col xs="6" className="box-left">
                    <div className="box-title">Assess</div>
                    <div className="box-points">
                      <ul>
                        <li>
                          <ChevronRightIcon />
                          Online Testing with MCQs
                        </li>
                        <li>
                          <ChevronRightIcon />
                          Term Wise / Year End Mock Exams
                        </li>
                        <li>
                          <ChevronRightIcon />
                          Global Percentile
                        </li>
                      </ul>
                    </div>
                  </Col>
                  <Col xs="6" className="box-right">
                    <img className="img-fluid" src={process.env.PUBLIC_URL + "/assets/images/promotions/Online-Exam.webp"} alt="" />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </section>

        {/* <section className="course-slider-area">
          <Container>
            <Row>
              <Col md="12">
                <div className="sec-title text-center">
                  <h4
                    style={{
                      fontSize: "25px",
                      fontWeight: "600",
                      color: "black",
                    }}
                  >
                    Empowering teachers
                  </h4>
                  <p
                    style={{
                      fontSize: "16px",
                      margin: "30px 10px",
                      color: "black",
                    }}
                  >
                    Our teacher technology kits simplify STEM teaching in school, and our well researched Alyss animation content focuses on topics in
                    the curriculum that can't be demonstrated in the science labs in most of the schools. We illustrate and simplify these concepts
                    with real life applications and examples.
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </section> */}
        {/* Testimonial Slider */}
        <TestimonialSlider />

        {/* Video Slider */}
        <VideoSlider />

        {/* Button Area */}
        <ButtonArea buttonText="Join Now" onHeroButtonClick={onClickJoinNow} showPopup={!!!common.getFromStorage("userName")} />

        {/* App Section */}
        {/* <AppSection /> */}

        {/* Footer */}
        <FooterTwo />
      </div>
    </StudyStyles>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  sendAuditEvent: (data) => dispatch(sendAuditEventAction(data)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(StudyMaterial));
