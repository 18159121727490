import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { messages, webUrl } from "../../../shared/constants.js";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";

function VerifyPassword(props) {
  const [formData, setFormData] = useState({});

  const onFieldChange = (name, value) => {
    setFormData({
      ...formData,
      [name]: value,
    });

    resetError(name);
  };

  const onPasswordNextClick = (e) => {
    e.preventDefault();
    if (isValidPasswordFormData()) {
      props.verifyPassword({ password: formData.password }).then((response) => {
        if (response) {
          if (response && response.user.is_profile_completed) props.redirectTo(webUrl.userDashboard);
          else props.setStep(2);
        }
      });
    }
  };

  const isValidPasswordFormData = () => {
    let isValid = true;

    if (!formData || !formData.password) {
      setError("password", messages.requiredField);
      isValid = false;
    } else {
      resetError("password");
    }

    return isValid;
  };

  const setError = (input, message) => {
    const formControl = document.getElementsByName(input)[0].parentElement;
    const errorMsg = formControl.querySelector(".login_input-msg");
    formControl.classList.add("error");
    formControl.classList.add("text-left");
    errorMsg.innerText = message;
  };

  const resetError = (input) => {
    const formControl = document.getElementsByName(input)[0].parentElement;
    const errorMsg = formControl.querySelector(".login_input-msg");
    formControl.classList.remove("error");
    formControl.classList.remove("text-left");
    errorMsg.innerText = "";
  };

  return (
    <>
      <div className="side-content">
        <h5>Enter Password</h5>
        <p>Please enter password for {props.email}</p>
      </div>
      <div className="side-post">
        {/* Login Area */}
        <section className="login-area">
          <Row>
            <Col md="12">
              <div className="login-box">
                <form id="form_login" className="form" onSubmit={onPasswordNextClick}>
                  <div className="form-control">
                    <Row>
                      <Col md="12">
                        <label>Password</label>
                        <input
                          type="password"
                          autoFocus
                          placeholder="Password"
                          id="password"
                          name="password"
                          value={formData.password}
                          onChange={(e) => {
                            onFieldChange(e.target.name, e.target.value);
                          }}
                        />
                        <span className="login_input-msg"></span>
                      </Col>
                    </Row>
                  </div>
                  <div className="forget-password">
                    <button type="submit" onClick={onPasswordNextClick}>
                      Submit
                    </button>
                  </div>
                </form>
                <div className="save-forget-password d-flex justify-content-between">
                  <div className="email-link forget-password">
                    <Link to="#" onClick={() => props.setStep(3)}>
                      <EditOutlinedIcon />
                      Change Email
                    </Link>
                  </div>
                  <div className="email-link forget-password">
                    <Link to="#" onClick={() => props.setStep(6)}>
                      <QuestionMarkIcon />
                      Forgot Password
                    </Link>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </section>
      </div>
    </>
  );
}

export default VerifyPassword;
