import React, { useEffect } from "react";
import { Styles } from "../styles/moduleMenu.js";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Box, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import IntegrationInstructionsIcon from "@mui/icons-material/IntegrationInstructions";
import TextSnippetIcon from "@mui/icons-material/TextSnippet";
import QuizIcon from "@mui/icons-material/Quiz";
import AdjustIcon from "@mui/icons-material/Adjust";
import SlideshowIcon from "@mui/icons-material/Slideshow";
import PsychologyIcon from "@mui/icons-material/Psychology";
import ExtensionIcon from "@mui/icons-material/Extension";
import DesktopWindowsIcon from "@mui/icons-material/DesktopWindows";
import AlignVerticalBottomIcon from "@mui/icons-material/AlignVerticalBottom";
import EngineeringIcon from "@mui/icons-material/Engineering";
import WorkspacePremiumIcon from "@mui/icons-material/WorkspacePremium";
import { ChevronRight } from "@mui/icons-material";
import { Col } from "react-bootstrap";
import { webUrl } from "../../../shared/constants.js";
import common from "../../../shared/common.js";
import { sendAuditEventAction } from "../../../components/actions/common.js";

const ModuleMenu = (props) => {
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const userProfile = JSON.parse(common.getFromStorage("profile"));
  const userFeatures = JSON.parse(common.getFromStorage("features"));
  const moduleConceptsFeature = userFeatures.filter((feature) => feature.slug === "module_concepts");
  const moduleHardwareBuildingFeature = userFeatures.filter((feature) => feature.slug === "module_hardware_building");
  const moduleProgrammingFeature = userFeatures.filter((feature) => feature.slug === "module_programming");
  const moduleSoftwareBuildingFeature = userFeatures.filter((feature) => feature.slug === "module_software_building");
  const moduleTheoryFeature = userFeatures.filter((feature) => feature.slug === "module_theory");
  const moduleCoreFundamentalsFeature = userFeatures.filter((feature) => feature.slug === "module_core_fundamentals");
  const moduleSoftwareSetupFeature = userFeatures.filter((feature) => feature.slug === "module_software_setup");
  const modulePPTFeature = userFeatures.filter((feature) => feature.slug === "module_ppts");
  const moduleMCQQuizFeature = userFeatures.filter((feature) => feature.slug === "module_mcqs_quiz");
  const moduleMissionFeature = userFeatures.filter((feature) => feature.slug === "module_missions");
  const moduleCertificationFeature = userFeatures.filter((feature) => feature.slug === "module_certification");
  const member_type = userProfile ? userProfile.member_type : 1;
  const missionsData = props.module.missions.filter((mission) => mission.member_type === member_type);

  let subscriptionModuleConceptsFeature = [];
  let subscriptionModuleHardwareBuildingFeature = [];
  let subscriptionModuleProgrammingFeature = [];
  let subscriptionModuleSoftwareBuildingFeature = [];
  let subscriptionModuleTheoryFeature = [];
  let subscriptionModuleCoreFundamentalsFeature = [];
  let subscriptionModuleSoftwareSetupFeature = [];
  let subscriptionModulePPTFeature = [];
  let subscriptionModuleMCQQuizFeature = [];
  let subscriptionModuleMissionFeature = [];
  let subscriptionModuleCertificationFeature = [];

  if (props.module && props.module.subscription_features && props.module.subscription_features.length > 0) {
    subscriptionModuleConceptsFeature = props.module.subscription_features.filter((feature) => feature.feature.slug === "module_concepts");
    subscriptionModuleHardwareBuildingFeature = props.module.subscription_features.filter(
      (feature) => feature.feature.slug === "module_hardware_building"
    );
    subscriptionModuleProgrammingFeature = props.module.subscription_features.filter((feature) => feature.feature.slug === "module_programming");
    subscriptionModuleSoftwareBuildingFeature = props.module.subscription_features.filter(
      (feature) => feature.feature.slug === "module_software_building"
    );
    subscriptionModuleTheoryFeature = props.module.subscription_features.filter((feature) => feature.feature.slug === "module_theory");
    subscriptionModuleCoreFundamentalsFeature = props.module.subscription_features.filter(
      (feature) => feature.feature.slug === "module_core_fundamentals"
    );
    subscriptionModuleSoftwareSetupFeature = props.module.subscription_features.filter((feature) => feature.feature.slug === "module_software_setup");
    subscriptionModulePPTFeature = props.module.subscription_features.filter((feature) => feature.feature.slug === "module_ppts");
    subscriptionModuleMCQQuizFeature = props.module.subscription_features.filter((feature) => feature.feature.slug === "module_mcqs_quiz");
    subscriptionModuleMissionFeature = props.module.subscription_features.filter((feature) => feature.feature.slug === "module_missions");
    subscriptionModuleCertificationFeature = props.module.subscription_features.filter((feature) => feature.feature.slug === "module_certification");
  }

  const handleFeatureValidation = (index) => {
    let feature_id = null;
    switch (index) {
      case 0:
        feature_id = moduleConceptsFeature[0].id;
        break;
      case 1:
        feature_id = moduleHardwareBuildingFeature[0].id;
        break;
      case 2:
        feature_id = moduleProgrammingFeature[0].id;
        break;
      case 3:
        feature_id = moduleSoftwareBuildingFeature[0].id;
        break;
      case 4:
        feature_id = moduleTheoryFeature[0].id;
        break;
      case 5:
        feature_id = moduleCoreFundamentalsFeature[0].id;
        break;
      case 6:
        feature_id = moduleSoftwareSetupFeature[0].id;
        break;
      case 7:
        feature_id = modulePPTFeature[0].id;
        break;
      case 8:
        feature_id = moduleMCQQuizFeature[0].id;
        break;
      case 9:
        feature_id = moduleMissionFeature[0].id;
        break;
      case 10:
        feature_id = moduleCertificationFeature[0].id;
        break;
      default:
        break;
    }
    if (feature_id != null) {
      props.sendAuditEvent({
        event_id: "feature",
        source: "web",
        feature_id: feature_id,
        remarks_data:
          props.module.subsubcategory.subcategory.category.name +
          " / " +
          props.module.subsubcategory.subcategory.name +
          " / " +
          props.module.subsubcategory.name +
          " / " +
          props.module.name,
      });
    }
  };

  let firstAvailableIndex = null;
  if (
    moduleConceptsFeature.length > 0 &&
    (subscriptionModuleConceptsFeature.length > 0 || props.module.can_user_access === 0) &&
    props.module &&
    props.module.concepts &&
    props.module.concepts.length > 0 &&
    firstAvailableIndex === null
  ) {
    firstAvailableIndex = 0;
  }

  if (
    moduleHardwareBuildingFeature.length > 0 &&
    (subscriptionModuleHardwareBuildingFeature.length > 0 || props.module.can_user_access === 0) &&
    props.module &&
    props.module.hardware_buildings &&
    props.module.hardware_buildings.length > 0 &&
    firstAvailableIndex === null
  ) {
    firstAvailableIndex = 1;
  }

  if (
    moduleProgrammingFeature.length > 0 &&
    (subscriptionModuleProgrammingFeature.length > 0 || props.module.can_user_access === 0) &&
    props.module &&
    props.module.programmings &&
    props.module.programmings.length > 0 &&
    firstAvailableIndex === null
  ) {
    firstAvailableIndex = 2;
  }

  if (
    moduleSoftwareBuildingFeature.length > 0 &&
    (subscriptionModuleSoftwareBuildingFeature.length > 0 || props.module.can_user_access === 0) &&
    props.module &&
    props.module.software_buildings &&
    props.module.software_buildings.length > 0 &&
    firstAvailableIndex === null
  ) {
    firstAvailableIndex = 3;
  }

  if (
    moduleTheoryFeature.length > 0 &&
    (subscriptionModuleTheoryFeature.length > 0 || props.module.can_user_access === 0) &&
    props.module &&
    props.module.description &&
    props.module.description.length > 0 &&
    firstAvailableIndex === null
  ) {
    firstAvailableIndex = 4;
  }

  if (
    moduleCoreFundamentalsFeature.length > 0 &&
    (subscriptionModuleCoreFundamentalsFeature.length > 0 || props.module.can_user_access === 0) &&
    props.module &&
    props.module.core_fundamentals &&
    props.module.core_fundamentals.length > 0 &&
    firstAvailableIndex === null
  ) {
    firstAvailableIndex = 5;
  }

  if (
    moduleSoftwareSetupFeature.length > 0 &&
    (subscriptionModuleSoftwareSetupFeature.length > 0 || props.module.can_user_access === 0) &&
    props.module &&
    props.module.software_setup &&
    props.module.software_setup.length > 0 &&
    firstAvailableIndex === null
  ) {
    firstAvailableIndex = 6;
  }

  if (
    modulePPTFeature.length > 0 &&
    (subscriptionModulePPTFeature.length > 0 || props.module.can_user_access === 0) &&
    props.module &&
    props.module.ppts &&
    props.module.ppts.length > 0 &&
    firstAvailableIndex === null
  ) {
    firstAvailableIndex = 7;
  }

  if (
    moduleMCQQuizFeature.length > 0 &&
    (subscriptionModuleMCQQuizFeature.length > 0 || props.module.can_user_access === 0) &&
    props.module &&
    props.module.quizzes &&
    props.module.quizzes.length > 0 &&
    firstAvailableIndex === null
  ) {
    firstAvailableIndex = 8;
  }

  if (
    moduleMissionFeature.length > 0 &&
    (subscriptionModuleMissionFeature.length > 0 || props.module.can_user_access === 0) &&
    props.module &&
    missionsData.length > 0 &&
    firstAvailableIndex === null
  ) {
    firstAvailableIndex = 9;
  }

  if (
    moduleCertificationFeature.length > 0 &&
    (subscriptionModuleCertificationFeature.length > 0 || props.module.can_user_access === 0) &&
    props.module &&
    props.module.certification &&
    props.module.certification.length > 0 &&
    firstAvailableIndex === null
  ) {
    firstAvailableIndex = 10;
  }

  if (firstAvailableIndex !== selectedIndex && selectedIndex === 0) {
    props.onChange(firstAvailableIndex);
    handleFeatureValidation(firstAvailableIndex);
  }
  useEffect(() => {
    setSelectedIndex(firstAvailableIndex);
    props.onChange(firstAvailableIndex);
    handleFeatureValidation(firstAvailableIndex);
  }, [firstAvailableIndex]);

  const handleListItemClick = (event, index, featureData) => {
    event.preventDefault();
    setSelectedIndex(index);
    props.onChange(index);
    props.sendAuditEvent({
      event_id: "feature",
      source: "web",
      feature_id: featureData[0].id,
      remarks_data:
        props.module.subsubcategory.subcategory.category.name +
        " / " +
        props.module.subsubcategory.subcategory.name +
        " / " +
        props.module.subsubcategory.name +
        " / " +
        props.module.name,
    });
  };
  return (
    <Styles>
      {/* Course Tag */}
      <div className="course-category">
        <h5>{props.module && props.module.name}</h5>
        <h6>
          {props.module &&
            props.module.subsubcategory &&
            props.module.subsubcategory.subcategory &&
            props.module.subsubcategory.subcategory.category &&
            `${props.module.subsubcategory.subcategory.category.name} / ${props.module.subsubcategory.subcategory.name} / ${props.module.subsubcategory.name}`}
        </h6>
        <Box sx={{ width: "100%", bgcolor: "background.paper" }}>
          <List component="nav" aria-label="main mailbox folders" sx={{ width: "100%", paddingBottom: 0 }}>
            {moduleConceptsFeature.length > 0 &&
              (subscriptionModuleConceptsFeature.length > 0 || props.module.can_user_access === 0) &&
              props.module &&
              props.module.concepts &&
              props.module.concepts.length > 0 && (
                <ListItem
                  secondaryAction={
                    <IconButton edge="end" aria-label="comments">
                      <ChevronRight />
                    </IconButton>
                  }
                  disablePadding
                >
                  <ListItemButton selected={selectedIndex === 0} onClick={(event) => handleListItemClick(event, 0, moduleConceptsFeature)}>
                    <ListItemIcon>
                      <PsychologyIcon />
                    </ListItemIcon>
                    <ListItemText primary="Concepts" className="menu-text" />
                  </ListItemButton>
                </ListItem>
              )}
            {moduleHardwareBuildingFeature.length > 0 &&
              (subscriptionModuleHardwareBuildingFeature.length > 0 || props.module.can_user_access === 0) &&
              props.module &&
              props.module.hardware_buildings &&
              props.module.hardware_buildings.length > 0 && (
                <ListItem
                  secondaryAction={
                    <IconButton edge="end" aria-label="comments">
                      <ChevronRight />
                    </IconButton>
                  }
                  disablePadding
                >
                  <ListItemButton selected={selectedIndex === 1} onClick={(event) => handleListItemClick(event, 1, moduleHardwareBuildingFeature)}>
                    <ListItemIcon>
                      <ExtensionIcon />
                    </ListItemIcon>
                    <ListItemText primary="Hardware Building" className="menu-text" />
                  </ListItemButton>
                </ListItem>
              )}
            {moduleProgrammingFeature.length > 0 &&
              (subscriptionModuleProgrammingFeature.length > 0 || props.module.can_user_access === 0) &&
              props.module &&
              props.module.programmings &&
              props.module.programmings.length > 0 && (
                <ListItem
                  secondaryAction={
                    <IconButton edge="end" aria-label="comments">
                      <ChevronRight />
                    </IconButton>
                  }
                  disablePadding
                >
                  <ListItemButton selected={selectedIndex === 2} onClick={(event) => handleListItemClick(event, 2, moduleProgrammingFeature)}>
                    <ListItemIcon>
                      <IntegrationInstructionsIcon />
                    </ListItemIcon>
                    <ListItemText primary="Programming" className="menu-text" />
                  </ListItemButton>
                </ListItem>
              )}
            {moduleSoftwareBuildingFeature.length > 0 &&
              (subscriptionModuleSoftwareBuildingFeature.length > 0 || props.module.can_user_access === 0) &&
              props.module &&
              props.module.software_buildings &&
              props.module.software_buildings.length > 0 && (
                <ListItem
                  secondaryAction={
                    <IconButton edge="end" aria-label="comments">
                      <ChevronRight />
                    </IconButton>
                  }
                  disablePadding
                >
                  <ListItemButton selected={selectedIndex === 3} onClick={(event) => handleListItemClick(event, 3, moduleSoftwareBuildingFeature)}>
                    <ListItemIcon>
                      <DesktopWindowsIcon />
                    </ListItemIcon>
                    <ListItemText primary="Software Building" className="menu-text" />
                  </ListItemButton>
                </ListItem>
              )}
            {moduleTheoryFeature.length > 0 &&
              (subscriptionModuleTheoryFeature.length > 0 || props.module.can_user_access === 0) &&
              props.module &&
              props.module.description &&
              props.module.description.length > 0 && (
                <ListItem
                  secondaryAction={
                    <IconButton edge="end" aria-label="comments">
                      <ChevronRight />
                    </IconButton>
                  }
                  disablePadding
                >
                  <ListItemButton selected={selectedIndex === 4} onClick={(event) => handleListItemClick(event, 4, moduleTheoryFeature)}>
                    <ListItemIcon>
                      <TextSnippetIcon />
                    </ListItemIcon>
                    <ListItemText primary="Theory" className="menu-text" />
                  </ListItemButton>
                </ListItem>
              )}
            {moduleCoreFundamentalsFeature.length > 0 &&
              (subscriptionModuleCoreFundamentalsFeature.length > 0 || props.module.can_user_access === 0) &&
              props.module &&
              props.module.core_fundamentals &&
              props.module.core_fundamentals.length > 0 && (
                <ListItem
                  secondaryAction={
                    <IconButton edge="end" aria-label="comments">
                      <ChevronRight />
                    </IconButton>
                  }
                  disablePadding
                >
                  <ListItemButton selected={selectedIndex === 5} onClick={(event) => handleListItemClick(event, 5, moduleCoreFundamentalsFeature)}>
                    <ListItemIcon>
                      <AlignVerticalBottomIcon />
                    </ListItemIcon>
                    <ListItemText primary="Core Fundamentals" className="menu-text" />
                  </ListItemButton>
                </ListItem>
              )}
            {moduleSoftwareSetupFeature.length > 0 &&
              (subscriptionModuleSoftwareSetupFeature.length > 0 || props.module.can_user_access === 0) &&
              props.module &&
              props.module.software_setup &&
              props.module.software_setup.length > 0 && (
                <ListItem
                  secondaryAction={
                    <IconButton edge="end" aria-label="comments">
                      <ChevronRight />
                    </IconButton>
                  }
                  disablePadding
                >
                  <ListItemButton selected={selectedIndex === 6} onClick={(event) => handleListItemClick(event, 6, moduleSoftwareSetupFeature)}>
                    <ListItemIcon>
                      <EngineeringIcon />
                    </ListItemIcon>
                    <ListItemText primary="Software Setup" className="menu-text" />
                  </ListItemButton>
                </ListItem>
              )}
            {modulePPTFeature.length > 0 &&
              (subscriptionModulePPTFeature.length > 0 || props.module.can_user_access === 0) &&
              props.module &&
              props.module.ppts &&
              props.module.ppts.length > 0 && (
                <ListItem
                  secondaryAction={
                    <IconButton edge="end" aria-label="comments">
                      <ChevronRight />
                    </IconButton>
                  }
                  disablePadding
                >
                  <ListItemButton selected={selectedIndex === 7} onClick={(event) => handleListItemClick(event, 7, modulePPTFeature)}>
                    <ListItemIcon>
                      <SlideshowIcon />
                    </ListItemIcon>
                    <ListItemText primary="Overview PPT" className="menu-text" />
                  </ListItemButton>
                </ListItem>
              )}
            {moduleMCQQuizFeature.length > 0 &&
              (subscriptionModuleMCQQuizFeature.length > 0 || props.module.can_user_access === 0) &&
              props.module &&
              props.module.quizzes &&
              props.module.quizzes.length > 0 && (
                <ListItem
                  secondaryAction={
                    <IconButton edge="end" aria-label="comments">
                      <ChevronRight />
                    </IconButton>
                  }
                  disablePadding
                >
                  <ListItemButton selected={selectedIndex === 8} onClick={(event) => handleListItemClick(event, 8, moduleMCQQuizFeature)}>
                    <ListItemIcon>
                      <QuizIcon />
                    </ListItemIcon>
                    <ListItemText primary="MCQs Quiz" className="menu-text" />
                  </ListItemButton>
                </ListItem>
              )}
            {moduleMissionFeature.length > 0 &&
              (subscriptionModuleMissionFeature.length > 0 || props.module.can_user_access === 0) &&
              props.module &&
              missionsData.length > 0 && (
                <ListItem
                  secondaryAction={
                    <IconButton edge="end" aria-label="comments">
                      <ChevronRight />
                    </IconButton>
                  }
                  disablePadding
                >
                  <ListItemButton selected={selectedIndex === 9} onClick={(event) => handleListItemClick(event, 9, moduleMissionFeature)}>
                    <ListItemIcon>
                      <AdjustIcon />
                    </ListItemIcon>
                    <ListItemText primary="Missions" className="menu-text" />
                  </ListItemButton>
                </ListItem>
              )}
            {moduleCertificationFeature.length > 0 &&
              (subscriptionModuleCertificationFeature.length > 0 || props.module.can_user_access === 0) &&
              props.module &&
              props.module.certification &&
              props.module.certification.length > 0 && (
                <ListItem
                  secondaryAction={
                    <IconButton edge="end" aria-label="comments">
                      <ChevronRight />
                    </IconButton>
                  }
                  disablePadding
                >
                  <ListItemButton selected={selectedIndex === 10} onClick={(event) => handleListItemClick(event, 10, moduleCertificationFeature)}>
                    <ListItemIcon>
                      <WorkspacePremiumIcon />
                    </ListItemIcon>
                    <ListItemText primary="Certification" className="menu-text" />
                  </ListItemButton>
                </ListItem>
              )}
          </List>
        </Box>
      </div>
      {props.module.can_user_access === 1 || (common.getFromStorage("isAuthenticated") === "true" && common.getFromStorage("userName") === "") ? (
        ""
      ) : (
        <div className="single-details-sidbar">
          <div className="course-details-feature">
            <Col sm="12">
              <button
                type="button"
                onClick={() => props.history.push(webUrl.packageList + "/" + props.module.subsubcategory_id)}
                className="enroll-btn"
                style={{ margin: "20px 0" }}
              >
                Buy Now
              </button>
            </Col>
          </div>
        </div>
      )}
    </Styles>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  sendAuditEvent: (data) => dispatch(sendAuditEventAction(data)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ModuleMenu));
