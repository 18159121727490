import React, { useState } from "react";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Container, Row, Col, Dropdown } from "react-bootstrap";
import FooterTwo from "../../components/FooterTwo";
import ThreeSection from "../../components/ThreeSection";
import { webUrl } from "../../shared/constants";
import { Styles } from "./styles";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import common from "../../shared/common";
import HeaderTwo from "../../components/HeaderTwo";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`full-width-tabpanel-${index}`} aria-labelledby={`full-width-tab-${index}`} {...other}>
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

const Offering = (props) => {
  const theme = useTheme();
  const {
    match: { params },
  } = props;

  const selectedTab = params.selected === "teacher" ? 1 : 0;
  const [value, setValue] = React.useState(selectedTab);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const idPref = Math.random();
  const [isOpen, setIsOpen] = useState(false);

  const openModal = () => {
    setIsOpen(true);
  };

  const onClickJoinNow = () => {
    if (common.getFromStorage("userName")) props.history.push(webUrl.userDashboard);
  };

  return (
    <Styles>
      <div className="main-wrapper">
        {/* Header */}
        <HeaderTwo {...props} />

        <Container fluid className="bg-gray pt-5 pb-3">
          <Row>
            <Col lg="12">
              <h3 className="text-center">You're a</h3>
            </Col>
          </Row>
        </Container>
        <Box sx={{ width: "100%" }} className="bg-gray">
          <Tabs value={value} onChange={handleChange} centered>
            <Tab label="Student" />
            <Tab label="Teacher" />
          </Tabs>
          <div>
            <TabPanel value={value} index={0} dir={theme.direction}>
              <Container className="py-3">
                <Row>
                  <Col lg="12">
                    <h2 className="text-center offering-title">Our Key Offerings</h2>
                    <p className="offering-subtitle">
                      We offer a world-class curriculum that goes beyond skill set enhancement and establishes strong STEM foundation in students
                      early in life.
                    </p>
                  </Col>
                </Row>
              </Container>
              <div className="subject-section">
                <Container className="py-3">
                  <Row>
                    <Col lg="12">
                      <h3 className="text-center subject-title">Unmatched Science &amp; Mathematics Learning</h3>
                      <p className="subject-subtitle">Learn from experts, fall in love with Science and Mathematics.</p>
                    </Col>
                    <Col md="4" lg="4" sm="6" xs="12">
                      <div className="feature-box">
                        <div className="feature-image">
                          <img src={process.env.PUBLIC_URL + `/assets/images/offerings/4.webp`} alt="" className="img-fluid" />
                        </div>
                        <div className="feature-title">Content Library</div>
                        <div className="features-list">
                          <ul>
                            <li>Curriculum based 3D animation based focusing on concepts.</li>
                            <li>Detailed theory with short video cross references and diagrams.</li>
                            <li>NCERT e-books for respective classes.</li>
                          </ul>
                        </div>
                      </div>
                    </Col>
                    <Col md="4" lg="4" sm="6" xs="12">
                      <div className="feature-box">
                        <div className="feature-image">
                          <img src={process.env.PUBLIC_URL + `/assets/images/offerings/5.webp`} alt="" className="img-fluid" />
                        </div>
                        <div className="feature-title">Doubt Solving Sessions</div>
                        <div className="features-list">
                          <ul>
                            <li>Live sessions to solve all your doubts. We won't leave you with unclear concepts.</li>
                            <li>Small groups to pay you personal attention.</li>
                            <li>Dedicated revision sessions whenever required and before exams.</li>
                          </ul>
                        </div>
                      </div>
                    </Col>
                    <Col md="4" lg="4" sm="6" xs="12">
                      <div className="feature-box">
                        <div className="feature-image">
                          <img src={process.env.PUBLIC_URL + `/assets/images/offerings/6.webp`} alt="" className="img-fluid" />
                        </div>
                        <div className="feature-title">Affordable Learning</div>
                        <div className="features-list">
                          <ul>
                            <li>Multiple packages to meet your needs without cutting your pocket.</li>
                            <li>No compromise with the quality of the content we deliver, not matter what!</li>
                            <li>Affordable price so that you can try without hesitation.</li>
                          </ul>
                        </div>
                      </div>
                    </Col>
                    <Col md="6" lg="6" sm="6" xs="12">
                      <div className="feature-box">
                        <div className="feature-image">
                          <img src={process.env.PUBLIC_URL + `/assets/images/offerings/11.webp`} alt="" className="img-fluid" />
                        </div>
                        <div className="feature-title">Self-Assessment</div>
                        <div className="features-list">
                          <ul>
                            <li>ALYSS' online tests, mock tests and year end online exam to help you prepare well.</li>
                            <li>Scorecard and performance report for better evaluation.</li>
                            <li>Percentile to determine how far or close you're to your end goal.</li>
                          </ul>
                        </div>
                      </div>
                    </Col>
                    <Col md="6" lg="6" sm="12" xs="12">
                      <div className="feature-box">
                        <div className="feature-image">
                          <img src={process.env.PUBLIC_URL + `/assets/images/offerings/10.webp`} alt="" className="img-fluid" />
                        </div>
                        <div className="feature-title">Resources for Practice</div>
                        <div className="features-list">
                          <ul>
                            <li>15,000+ hand-curated questions and their solutionsto help you ace your exams.</li>
                            <li>Descriptive sample papers, NCERT exercise solutions, quiz to practice.</li>
                            <li>Mobile app and personal dashboard to manage and access it all at one place.</li>
                          </ul>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Container>
              </div>
            </TabPanel>
            <TabPanel value={value} index={1} dir={theme.direction}>
              <Container className="py-3">
                <Row>
                  <Col lg="12">
                    <h2 className="text-center offering-title">Our Key Offerings</h2>
                    <p className="offering-subtitle">
                      ALYSS introduces high technology offerings that bring back the focus on the real pillars of education system "Teachers and
                      Students".
                    </p>
                  </Col>
                </Row>
              </Container>
            </TabPanel>
          </div>
        </Box>

        {/* Footer */}
        <FooterTwo />
      </div>
    </Styles>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Offering));
