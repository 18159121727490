import { doPostRequest } from "../../shared/api/base-api";
import { apiUrl } from "../../shared/constants";


//Olympiad Login api
export function olympiadLogin(data) {
   return doPostRequest(apiUrl.olympiadLogin, data);
}

// Olympiad Quiz Start Api
export function startOlympiadQuiz(data) {
   return doPostRequest(apiUrl.startOlympiadQuiz, data);
}

// Olympiad Quiz Submit Api
export function submitOlympiadQuiz(data) {
   return doPostRequest(apiUrl.submitOlympiadQuiz, data);
}

//Check Olympiad Login api
export function checkOlympiadQuiz(data) {
   return doPostRequest(apiUrl.checkOlympiadQuiz, data);
}