import styled from "styled-components";
import { fonts } from "../../components/common/element/elements.js";

export const Styles = styled.div`
  .chapter-theory,
  .module-theory,
  .chapter-exercises {
    width: 100%;
    padding: 15px;
  }

  .exercise-box {
    background: #cccccc26;
    border: 1px solid #ccc;
    margin-bottom: 20px;
    h3 {
      text-align: center;
      padding: 20px;
      font-size: 24px;
      color: #5f5757;
    }

    ul {
      list-style: circle;
      li {
        font-family: ${fonts.roboto};
        font-weight: 300;
        font-size: 14px;
      }
    }

    table {
      border: 1px solid #7d7d7d;
      tr {
        border: 1px solid #7d7d7d;
        td {
          border: 1px solid #7d7d7d;
          padding: 5px;
          font-size: 14px;
          font-weight: 300;
          font-family: ${fonts.roboto};
        }
      }
    }

    .question-block,
    .answer-block {
      padding: 10px;
    }
  }

  .embed-responsive {
    position: relative;
    display: block;
    height: 0;
    padding: 0;
    overflow: hidden;

    .embed-responsive-item,
    iframe,
    embed,
    object,
    video {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border: 0;
    }
  }

  .embed-responsive-16by9 {
    padding-bottom: 56.25%;
  }
`;
