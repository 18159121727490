import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { messages, webUrl } from "../../../shared/constants.js";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import ReplayOutlinedIcon from "@mui/icons-material/ReplayOutlined";

function VerifyOTP(props) {
  const [formData, setFormData] = useState({});
  const [resendText, setResendText] = useState("");

  const onFieldChange = (name, value) => {
    setFormData({
      ...formData,
      [name]: value,
    });

    resetError(name);
  };

  const onOTPNextClick = (e) => {
    e.preventDefault();
    if (isValidOTPFormData()) {
      props.verifyOTP({ otp: formData.otp }).then((response) => {
        if (response) {
          if (response) props.redirectTo(webUrl.partnerDashboard);
          else props.setStep(1);
        }
      });
    }
  };

  const isValidOTPFormData = () => {
    let isValid = true;

    if (!formData || !formData.otp) {
      setError("otp", messages.requiredField);
      isValid = false;
    } else if (isNaN(formData.otp)) {
      setError("otp", messages.numberField);
      isValid = false;
    } else {
      resetError("otp");
    }

    return isValid;
  };

  const onResendOTPClick = () => {
    props.partnerLogin({ country_code: props.country, mobile: props.mobile }).then(() => {
      setResendText(messages.resendText);
      setTimeout(() => {
        setResendText("");
      }, 30000);
    });
  };

  const setError = (input, message) => {
    const formControl = document.getElementsByName(input)[0].parentElement;
    const errorMsg = formControl.querySelector(".login_input-msg");
    formControl.classList.add("error");
    formControl.classList.add("text-left");
    errorMsg.innerText = message;
  };

  const resetError = (input) => {
    const formControl = document.getElementsByName(input)[0].parentElement;
    const errorMsg = formControl.querySelector(".login_input-msg");
    formControl.classList.remove("error");
    formControl.classList.remove("text-left");
    errorMsg.innerText = "";
  };

  return (
    <>
      <div className="side-content">
        <h5>Verify OTP</h5>
        <p>
          We have sent an SMS with OTP to {props.country}-{props.mobile}
        </p>
      </div>
      <div className="side-post">
        {/* Login Area */}
        <section className="login-area">
          <Row>
            <Col md="12">
              <div className="login-box">
                <form id="form_login" className="form" onSubmit={onOTPNextClick}>
                  <div className="form-control">
                    <Row>
                      <Col md="12">
                        <input
                          type="text"
                          placeholder="OTP"
                          id="otp"
                          name="otp"
                          value={formData.otp}
                          maxLength={6}
                          onChange={(e) => {
                            onFieldChange(e.target.name, e.target.value);
                          }}
                        />
                        <span className="login_input-msg"></span>
                      </Col>
                    </Row>
                  </div>
                  <div className="forget-password">
                    <button type="submit" onClick={onOTPNextClick}>
                      Next
                    </button>
                  </div>
                </form>
                <div className="save-forget-password d-flex justify-content-between">
                  <div className="forget-password">
                    <Link to="#" onClick={() => props.setStep(-1)}>
                      <EditOutlinedIcon />
                      Edit Number
                    </Link>
                  </div>
                  <div className="forget-password">
                    {resendText !== "" ? (
                      resendText
                    ) : (
                      <Link to="#" onClick={onResendOTPClick}>
                        <ReplayOutlinedIcon />
                        Resend OTP
                      </Link>
                    )}
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </section>
      </div>
    </>
  );
}

export default VerifyOTP;
