import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import IconBox from "./components/IconBox";
import TestimonialSlider from "./components/TestimonialSlider";
import FooterTwo from "./components/FooterTwo";
import HeaderTwo from "./components/HeaderTwo";
import HomeCarousel from "./components/HomeCarousel";
import VideoSlider from "./components/VideoSlider";
import ButtonArea from "./components/ButtonArea";
import common from "./shared/common";
import { webUrl } from "./shared/constants";
import HomeContent from "./components/HomeContent";
import { sendAuditEventAction } from "./components/actions/common";

const HomeIN = (props) => {
  useEffect(() => {
    if (common.getFromStorage("userName")) props.sendAuditEvent({ event_id: "home", source: "web" });
  }, []);
  const onClickJoinNow = () => {
    if (common.getFromStorage("userName")) props.history.push(webUrl.userDashboard);
  };
  return (
    <div className="main-wrapper">
      {/* Header */}
      <HeaderTwo {...props} />

      {/* Banner */}
      <HomeCarousel redirectTo={props.history.push} />
      {/* < Banner /> */}

      {/* Icon Box */}
      <IconBox {...props} />

      <HomeContent {...props} />

      {/* Testimonial Slider */}
      <TestimonialSlider />
      <VideoSlider />

      {/* Button Area */}
      <ButtonArea buttonText="Join Now" onHeroButtonClick={onClickJoinNow} showPopup={!!!common.getFromStorage("userName")} />

      {/* Footer */}
      <FooterTwo />
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  sendAuditEvent: (data) => dispatch(sendAuditEventAction(data)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(HomeIN));
