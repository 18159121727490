import { doPostRequest, doPatchRequest } from "./../../shared/api/base-api";
import { apiUrl } from "./../../shared/constants";

//Login api
export function userLogin(data) {
  return doPostRequest(apiUrl.userLogin, data);
}

//Verify OTP api
export function verifyOTP(data) {
  return doPostRequest(apiUrl.verifyOTP, data);
}

//Update profile api
export function updateProfile(data) {
  return doPatchRequest(apiUrl.updateProfile, data);
}

//Profile data api
export function getProfileData(data) {
  return doPostRequest(apiUrl.profileData, data);
}

//Login wiith Emailapi
export function userLoginWithEmail(data) {
  return doPostRequest(apiUrl.userLoginEmail, data);
}

//Verify Email OTP api
export function verifyEmailOTP(data) {
  return doPostRequest(apiUrl.verifyEmailOTP, data);
}

//Verify Password api
export function verifyPassword(data) {
  return doPostRequest(apiUrl.verifyPassword, data);
}

//Forgot Password api
export function forgotPassword(data) {
  return doPostRequest(apiUrl.forgotPassword, data);
}

//Verify Reset Password OTP api
export function verifyResetPasswordOTP(data) {
  return doPostRequest(apiUrl.verifyEmailOTP, data);
}

//update Password api
export function updatePassword(data) {
  return doPostRequest(apiUrl.updatePassword, data);
}