import styled from "styled-components";
import { colors } from "../../../components/common/element/elements.js";

export const Styles = styled.div`
  .bg-black {
    background: ${colors.gradient_dark_grey_color};
  }
  .mindmap-bg {
    background-image: url("/assets/images/module/mindmap_bg.webp");
    height: 150px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    margin: 30px auto 30px 18%;
    @media (max-width: 767px) {
      margin: 30px auto 30px auto;
    }

    &:after {
      content: "";
      border-left: 1px solid #444444;
      height: 30px;
      position: absolute;
      left: 59%;
      margin-left: -3px;
      bottom: 0;
      @media (max-width: 767px) {
        left: 50%;
      }
    }

    .module-title {
      text-align: center;
      padding-top: 30px;
      font-size: 14px;
      margin-bottom: 33px;
    }

    .module-hardware {
      text-align: center;
      height: 35%;
      position: relative;
      vertical-align: bottom;
      img {
        height: 100%;
      }
    }
  }

  .mindmap-certificate {
    background-image: url("/assets/images/module/module_certificate.webp");
    height: 400px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    margin: 0 auto 30px 18%;
    @media (max-width: 767px) {
      margin: 30px auto 30px auto;
    }
    &:before {
      content: "";
      border-left: 1px solid #444444;
      height: 60px;
      position: absolute;
      left: 59%;
      margin-left: -3px;
      top: 0;
      @media (max-width: 767px) {
        height: 90px;
        left: 50%;
      }
    }
  }
  .step-wrapper {
    padding-bottom: 30px;
    &:after {
      content: "";
      border-left: 1px solid #444444;
      height: 30px;
      position: absolute;
      left: 59%;
      margin-left: -3px;
      bottom: 0;
      @media (max-width: 767px) {
        left: 50%;
      }
    }
    .step-counter {
      position: absolute;
      background: white;
      border-radius: 50%;
      width: 60px;
      height: 60px;
      box-shadow: 0 12px 25px rgb(0 0 0 / 20%);
      text-align: center;
      padding: 6px;
      top: 50%;
      transform: translate(25%, -75%);
      @media (max-width: 1199px) {
        width: 60px;
        height: 60px;
        padding: 5px;
        display: none;
      }
      @media (max-width: 767px) {
        display: none;
      }

      &:after {
        content: "";
        border-bottom: 1px solid #444444;
        width: calc(100%);
        position: absolute;
        top: 50%;
        left: 60px;
        z-index: -1;
        @media (max-width: 1199px) {
        }
      }

      .step_text {
        font-size: 14px;
        font-weight: 600;
        text-transform: uppercase;
        @media (max-width: 1199px) {
          font-size: 14px;
        }
      }

      .step_number {
        font-size: 30px;
        font-weight: 900;
        line-height: 22px;
      }
    }
    .step-box {
      background: purple;
      border-radius: 123px;
      width: 80%;
      margin: 0 auto 0 18%;
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: space-around;
      box-shadow: 0 12px 25px rgb(0 0 0 / 20%);
      z-index: 1;
      @media (max-width: 767px) {
        width: 90%;
        margin: 0 auto 0 5%;
      }

      &:before {
        content: "";
        width: 30px;
        height: 30px;
        position: absolute;
        background: #9c27b0;
        border-radius: 50%;
        left: 17%;
        border: 6px solid white;
        @media (max-width: 767px) {
          left: 4%;
        }
      }

      .module_title {
        color: white;
        font-size: 20px;
        font-weight: 900;
        padding: 10px;
        margin-left: 10px;
        width: 100%;
        word-break: break-word;
        @media (max-width: 1199px) {
          font-size: 16px;
        }
      }

      .module_separator {
        border-right: 1px solid #bbb;
        padding-bottom: 40px;
      }

      .module_image {
        width: 30%;
        text-align: center;
        img {
          max-height: 60px;
          padding: 5px 0;
          @media (max-width: 767px) {
            max-height: 40px;
          }
        }
      }
      .module_action {
        width: 30px;
        height: 30px;
        position: absolute;
        text-align: center;
        right: 2%;
        background: white;
        border-radius: 50%;
        padding: 4px;
        color: black;
        box-shadow: 0 12px 25px rgb(0 0 0 / 20%);
        @media (max-width: 767px) {
          right: 5%;
        }
        img {
          max-height: 60px;
          padding: 5px 0;
        }
      }
    }
    .module-1,
    .module-6,
    .module-11 {
      .step-box {
        background-image: linear-gradient(to right, #7f37ac, #b045c7);
        &:before {
          background-image: linear-gradient(to right, #7f37ac, #b045c7);
        }
      }
      .step_text,
      .step_number,
      svg {
        color: #7f37ac;
      }
    }
    .module-2,
    .module-7,
    .module-12 {
      .step-box {
        background-image: linear-gradient(to right, #7a44c3, #7b40bc);
        &:before {
          background-image: linear-gradient(to right, #7a44c3, #7b40bc);
        }
      }
      .step_text,
      .step_number,
      svg {
        color: #7a44c3;
      }
    }
    .module-3,
    .module-8,
    .module-13 {
      .step-box {
        background-image: linear-gradient(to right, #4496c3, #5fbfc9);
        &:before {
          background-image: linear-gradient(to right, #4496c3, #5fbfc9);
        }
      }
      .step_text,
      .step_number,
      svg {
        color: #4496c3;
      }
    }
    .module-4,
    .module-9,
    .module-14 {
      .step-box {
        background-image: linear-gradient(to right, #d68702, #cb9334);
        &:before {
          background-image: linear-gradient(to right, #d68702, #cb9334);
        }
      }
      .step_text,
      .step_number,
      svg {
        color: #d68702;
      }
    }
    .module-5,
    .module-10,
    .module-15 {
      .step-box {
        background-image: linear-gradient(to right, #98c924, #afc650);
        &:before {
          background-image: linear-gradient(to right, #98c924, #afc650);
        }
      }
      .step_text,
      .step_number,
      svg {
        color: #98c924;
      }
    }
  }
`;
