import { actionTypes } from './action';

/** Initial state */
const initialState = {
    data: {
        module: {
            concepts: [],
            programmings: [],
            missions: [],
            quizzes: []
        }
    }
};

/** Reducers */
export default (state = initialState, action) => {

    const { type, payload } = action;

    switch (type) {
        case actionTypes.MODULE_DETAILS_REQUEST_SUCCESS:
            return {
                ...state,
                data: {
                    ...state.data,
                    module: payload.data.module
                }
            };

        default:
            return state;
    }
};
