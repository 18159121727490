import React, { useEffect, useState } from "react";
import DataList from "./DataList";
import { Styles } from "../../styles/dashboard.js";
import { Container, Row, Col } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import LoaderNew from "../../../../components/LoaderNew";
import Typography from "@mui/material/Typography";
import { notificationsListDataAction } from "../../action";
import PartnerHeader from "../../../../components/PartnerHeader";
import DashboardMenu from "../DashboardMenu";
import FooterTwo from "../../../../components/FooterTwo";
import common from "../../../../shared/common";

const PartnerNotification = (props) => {
  useEffect(() => {
    props.getNotificationsList();
  }, []);
  let notifications = [];
  if (props.notifications.length > 0) {
    notifications = props.notifications.map((notification) => {
      return {
        id: notification.id,
        notification_text: notification.notification_text,
        notified_at: common.getISTFormattedDateTime(notification.notified_at),
      };
    });
  }
  return (
    <div className="main-wrapper course-page">
      {/* User Header */}
      <PartnerHeader />

      <Styles>
        {/* Course Grid */}
        <section className="dashboard-grid-area">
          <Container>
            <Row>
              <Col lg="3" md="3" sm="12">
                <DashboardMenu {...props} index={props.index || 9} />
              </Col>
              <Col>
                {props.showLoader && props.showLoader.length > 0 && <LoaderNew />}
                {props.showLoader.length === 0 && (
                  <>
                    <Typography variant="h3" component="div" gutterBottom>
                      {"Notifications"}
                    </Typography>
                    <Row>
                      {(notifications &&
                        notifications.length > 0 &&
                        notifications.map((data, i) => (
                          <Col lg="12" md="12" key={i}>
                            <div className="course-item">
                              <div className="course-content">
                                <p className="desc">{data.notification_text}</p>
                                <div className="course-face d-flex justify-content-between">
                                  <div className="duration">
                                    <p>{data.notified_at}</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Col>
                        ))) || (
                        <Col lg="12" md="12">
                          <p className="not-found">No Notification found</p>
                        </Col>
                      )}
                    </Row>
                  </>
                )}
              </Col>
            </Row>
          </Container>
        </section>
      </Styles>

      {/* Footer 2 */}
      <FooterTwo />
    </div>
  );
};
const mapStateToProps = (state) => ({
  error: state.partnerDashboard.error,
  notifications: state.partnerDashboard.data.notifications,
  showLoader: state.user.showLoader,
});

const mapDispatchToProps = (dispatch) => ({
  getNotificationsList: () => dispatch(notificationsListDataAction()),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PartnerNotification));
