import { createGlobalStyle } from "styled-components";
import { colors, fonts } from "../element/elements.js";

export const GlobalStyle = createGlobalStyle`
    @import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap');
    
    @import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,300;0,400;0,500;0,600;0,700;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');

    html {
        color      : ${colors.bg1};
        font-size  : 13px;
        font-family: ${fonts.manrope};
        line-height: 1.4;
    }

    ::-moz-selection {
        background : #b3d4fc;
        text-shadow: none;
    }

    ::selection {
        background : #b3d4fc;
        text-shadow: none;
    }

    hr {
        display   : block;
        height    : 1px;
        border    : 0;
        border-top: 1px solid ${colors.border3};
        margin    : 1em 0;
        padding   : 0;
    }

    audio,
    canvas,
    iframe,
    img,
    svg,
    video {
        vertical-align: middle;
    }

    img{
        max-width: 100%;
    }

    fieldset {
        border : 0;
        margin : 0;
        padding: 0;
    }

    textarea {
        resize: vertical;
    }

    body {
        font-size     : 13px;
        background    : #ffffff;
        color         : ${colors.text1};
        letter-spacing: 0.3px;
        font-family   : ${fonts.manrope};
    }

    h1,
    h1 a {
        font-size: 40px;
    }

    h2,
    h2 a {
        font-size: 32px;
    }

    h3,
    h3 a {
        font-size: 28px;
    }

    h4,
    h4 a {
        font-size: 24px;
    }

    h5,
    h5 a {
        font-size: 20px;
    }

    h6,
    h6 a {
        font-size: 16px;
    }

    h1,
    h1 a,
    h2,
    h2 a,
    h3,
    h3 a,
    h4,
    h4 a,
    h5,
    h5 a,
    h6,
    h6 a {
        font-family: ${fonts.manrope};
        margin     : 0;
    }

    a,
    button,
    li,
    p {
        font-size     : 13px;
        font-family   : ${fonts.manrope};
        margin        : 0;
        letter-spacing: 0.3px;
    }

    ul {
        padding: 0;
        margin : 0;
    }

    a,
    a:active,
    a:focus,
    a:hover,
    button:focus,
    button:hover {
        text-decoration: none;
        outline        : none;
    }

    a,
    button {
        transition: all 0.2s ease;
    }

    input:focus,
    textarea:focus {
        outline: none;
    }

    .padding-fix {
        padding-left : 0;
        padding-right: 0;
    }

    .padding-fix-r {
        padding-right: 0;
    }

    .padding-fix-l {
        padding-left: 0;
    }

    .margin-fix {
        margin-left : 0;
        margin-right: 0;
    }
    .btn-green{
        background-image: ${colors.btn_gr1};
    }
    .transform-button{
        &:hover {
            transform: scale(1.06);
            transition: .8s;
        }
    }
    .MuiAutocomplete-popper {
        z-index: 10000 !important;
    }
`;
