import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col, Dropdown } from "react-bootstrap";
import { Styles } from "./styles/mobileDashboardMenu.js";
import { webUrl } from "../../shared/constants.js";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import PhoneOutlinedIcon from "@mui/icons-material/PhoneOutlined";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import PrecisionManufacturingIcon from "@mui/icons-material/PrecisionManufacturing";
import LiveTvIcon from "@mui/icons-material/LiveTv";
import { Box, IconButton, List, ListItem, ListItemButton, ListItemText, ListSubheader, Drawer, ListItemIcon } from "@mui/material";
import SupervisedUserCircleIcon from "@mui/icons-material/SupervisedUserCircle";
import AccountCircleRoundedIcon from "@mui/icons-material/AccountCircleRounded";
import { ChevronRight } from "@mui/icons-material";
import HomeIcon from "@mui/icons-material/Home";
import MenuIcon from "@mui/icons-material/Menu";
import SubscriptionsIcon from "@mui/icons-material/Subscriptions";
import ArticleIcon from "@mui/icons-material/Article";
import HomeRepairServiceIcon from "@mui/icons-material/HomeRepairService";
import NotificationsIcon from "@mui/icons-material/Notifications";
import PersonIcon from "@mui/icons-material/Person";
import PaidIcon from "@mui/icons-material/Paid";
import LogoutIcon from "@mui/icons-material/Logout";
import VideoLibraryIcon from "@mui/icons-material/VideoLibrary";
import User from "../../pages/user";
import common from "../../shared/common.js";
import { useDispatch } from "react-redux";
import { unmountUserAction } from "../../pages/user/action.js";

function MobileDashboardMenu(props) {
  const dispatch = useDispatch();
  const logoutHandler = (e) => {
    e.preventDefault();
    localStorage.clear();
    dispatch(props.logoutUser());
    return props.history.push(webUrl.home);
  };
  let checkRobotics = 1;
  if (props.formData && props.formData.category_id && common.getFromStorage("categories")) {
    const categories = JSON.parse(common.getFromStorage("categories"));
    const category = categories.filter((category) => category.id === +props.formData.category_id);
    if (category.length) {
      checkRobotics = category[0].is_robotics === 1 ? 1 : 0;
    }
  }
  const userProfile = JSON.parse(common.getFromStorage("profile"));
  const userFeatures = JSON.parse(common.getFromStorage("features"));
  const liveClassFeature = userFeatures.filter((feature) => feature.slug === "live_classes");
  const testGenerationFeature = userFeatures.filter((feature) => feature.slug === "test_generation");
  const ttkFeature = userFeatures.filter((feature) => feature.slug === "teacher_technology_kit");
  const [selectedIndex, setSelectedIndex] = useState(props.index | 0);
  const handleListItemClick = (e, target, index) => {
    e.preventDefault();
    setSelectedIndex(index);
    props.history.push(target);
  };
  useEffect(() => {
    // Mobile Menu
    const hmBtn = document.getElementById("mb-sidebar-btn");

    if (hmBtn) {
      const mdSidebarOverlay = document.getElementById("mb-sidebar-overlay");
      const mdSidebarBody = document.getElementById("mb-sidebar-body");
      const mdSidebarExit = document.getElementById("close-mb-sidebar");

      hmBtn.addEventListener("click", function (e) {
        e.preventDefault();
        mdSidebarOverlay.classList.toggle("visible");
        mdSidebarBody.classList.toggle("opened");
      });

      mdSidebarOverlay.addEventListener("click", function (e) {
        e.preventDefault();
        mdSidebarOverlay.classList.remove("visible");
        mdSidebarBody.classList.remove("opened");
      });

      mdSidebarExit.addEventListener("click", function (e) {
        e.preventDefault();
        mdSidebarOverlay.classList.remove("visible");
        mdSidebarBody.classList.remove("opened");
      });
    }

    // Menu Accordion -----------------
    const menuButton = document.querySelectorAll(".mb-menu-button");
    menuButton.forEach((button) => {
      button.addEventListener("click", () => {
        button.classList.toggle("active");
        const content = button.nextElementSibling;

        if (button.classList.contains("active")) {
          content.className = "mb-menu-content show";
          content.style.maxHeight = content.scrollHeight + "px";
        } else {
          content.className = "mb-menu-content";
          content.style.maxHeight = "0";
        }
      });
    });
  });

  return (
    <Styles>
      {/* Mobile Menu */}
      <section className="mobile-menu-area">
        <Container>
          <Row>
            <Col md="0" sm="12">
              <div className="mb-logo-area d-flex justify-content-between">
                <div className="mb-logo-box d-flex">
                  <div className="hm-button">
                    <a href={process.env.PUBLIC_URL + "/"} id="mb-sidebar-btn">
                      <i className="las la-bars"></i>
                    </a>
                  </div>
                  <div className="mb-logo">
                    <Link to={process.env.PUBLIC_URL + "/"}>
                      <img src={process.env.PUBLIC_URL + "/assets/images/logo2.webp"} alt="" />
                    </Link>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      {/* Mobile Menu Sidebar */}
      <section className="mb-sidebar" id="mb-sidebar-body">
        <div className="mb-sidebar-heading text-center">
          <div>
            <img src={process.env.PUBLIC_URL + "/assets/images/new_logo.webp"} alt="" width={100} />
          </div>
          <div>
            <a id="close-mb-sidebar" />
          </div>
        </div>
        <div className="mb-sidebar-menu">
          <div className="mb-menu-item">
            <div className="mb-menu-content show">
              <div className="course-category">
                <h5>Hi {common.getFromStorage("userName")}!</h5>
                <Box sx={{ width: "100%", bgcolor: "background.paper" }}>
                  <List
                    component="nav"
                    aria-label="main mailbox folders"
                    sx={{ width: "100%", paddingBottom: "5px", fontSize: "15px", fontFamily: "Manrope" }}
                  >
                    <ListItem
                      secondaryAction={
                        <IconButton edge="end" aria-label="comments">
                          <ChevronRight />
                        </IconButton>
                      }
                      disablePadding
                    >
                      <ListItemButton
                        className="menu-padding"
                        selected={selectedIndex === 0}
                        onClick={(event) => handleListItemClick(event, webUrl.userDashboard, 0)}
                      >
                        <ListItemIcon>
                          <HomeIcon />
                        </ListItemIcon>
                        <ListItemText primary="Home" className="menu-text" />
                      </ListItemButton>
                    </ListItem>
                    <ListItem
                      secondaryAction={
                        <IconButton edge="end" aria-label="comments">
                          <ChevronRight />
                        </IconButton>
                      }
                      disablePadding
                    >
                      <ListItemButton
                        className="menu-padding"
                        selected={selectedIndex === 1}
                        onClick={(event) => handleListItemClick(event, webUrl.userSubscriptions, 1)}
                      >
                        <ListItemIcon>
                          <SubscriptionsIcon />
                        </ListItemIcon>
                        <ListItemText primary="Subscriptions" className="menu-text" />
                      </ListItemButton>
                    </ListItem>
                    {liveClassFeature.length > 0 && (
                      <>
                        <ListItem
                          secondaryAction={
                            <IconButton edge="end" aria-label="comments">
                              <ChevronRight />
                            </IconButton>
                          }
                          disablePadding
                        >
                          <ListItemButton
                            className="menu-padding"
                            selected={selectedIndex === 2}
                            onClick={(event) => handleListItemClick(event, webUrl.liveClasses, 2)}
                          >
                            <ListItemIcon>
                              <LiveTvIcon />
                            </ListItemIcon>
                            <ListItemText primary="Live Classes" className="menu-text" />
                          </ListItemButton>
                        </ListItem>
                        <ListItem
                          secondaryAction={
                            <IconButton edge="end" aria-label="comments">
                              <ChevronRight />
                            </IconButton>
                          }
                          disablePadding
                        >
                          <ListItemButton
                            className="menu-padding"
                            selected={selectedIndex === 8}
                            onClick={(event) => handleListItemClick(event, webUrl.recordedSessions, 8)}
                          >
                            <ListItemIcon>
                              <VideoLibraryIcon />
                            </ListItemIcon>
                            <ListItemText primary="Recorded Sessions" className="menu-text" />
                          </ListItemButton>
                        </ListItem>
                      </>
                    )}
                    {userProfile && userProfile.member_type === 1 && checkRobotics === 0 && (
                      <>
                        {testGenerationFeature.length > 0 && (
                          <ListItem
                            secondaryAction={
                              <IconButton edge="end" aria-label="comments">
                                <ChevronRight />
                              </IconButton>
                            }
                            disablePadding
                          >
                            <ListItemButton
                              className="menu-padding"
                              selected={selectedIndex === 3}
                              onClick={(event) => handleListItemClick(event, webUrl.testGeneration, 3)}
                            >
                              <ListItemIcon>
                                <ArticleIcon />
                              </ListItemIcon>
                              <ListItemText primary="Test Maker Kit" className="menu-text" />
                            </ListItemButton>
                          </ListItem>
                        )}
                      </>
                    )}

                    {userProfile && userProfile.member_type === 2 && checkRobotics === 0 && (
                      <>
                        {testGenerationFeature.length > 0 && (
                          <ListItem
                            secondaryAction={
                              <IconButton edge="end" aria-label="comments">
                                <ChevronRight />
                              </IconButton>
                            }
                            disablePadding
                          >
                            <ListItemButton
                              className="menu-padding"
                              selected={selectedIndex === 3}
                              onClick={(event) => handleListItemClick(event, webUrl.testGeneration, 3)}
                            >
                              <ListItemIcon>
                                <ArticleIcon />
                              </ListItemIcon>
                              <ListItemText primary="Test Generation" className="menu-text" />
                            </ListItemButton>
                          </ListItem>
                        )}
                        {ttkFeature.length > 0 && (
                          <ListItem
                            secondaryAction={
                              <IconButton edge="end" aria-label="comments">
                                <ChevronRight />
                              </IconButton>
                            }
                            disablePadding
                          >
                            <ListItemButton
                              className="menu-padding"
                              selected={selectedIndex === 4}
                              onClick={(event) => handleListItemClick(event, webUrl.teacherTechnologyKit, 4)}
                            >
                              <ListItemIcon>
                                <HomeRepairServiceIcon />
                              </ListItemIcon>
                              <ListItemText primary="Teacher Technology Kit" className="menu-text" />
                            </ListItemButton>
                          </ListItem>
                        )}
                      </>
                    )}
                    <ListItem
                      secondaryAction={
                        <IconButton edge="end" aria-label="comments">
                          <ChevronRight />
                        </IconButton>
                      }
                      disablePadding
                    >
                      <ListItemButton
                        className="menu-padding"
                        selected={selectedIndex === 5}
                        onClick={(event) => handleListItemClick(event, webUrl.userNotifications, 5)}
                      >
                        <ListItemIcon>
                          <NotificationsIcon />
                        </ListItemIcon>
                        <ListItemText primary="Notifications" className="menu-text" />
                      </ListItemButton>
                    </ListItem>
                    <ListItem
                      secondaryAction={
                        <IconButton edge="end" aria-label="comments">
                          <ChevronRight />
                        </IconButton>
                      }
                      disablePadding
                    >
                      <ListItemButton
                        className="menu-padding"
                        selected={selectedIndex === 6}
                        onClick={(event) => handleListItemClick(event, webUrl.userProfile, 6)}
                      >
                        <ListItemIcon>
                          <PersonIcon />
                        </ListItemIcon>
                        <ListItemText primary="Profile" className="menu-text" />
                      </ListItemButton>
                    </ListItem>
                    <ListItem
                      secondaryAction={
                        <IconButton edge="end" aria-label="comments">
                          <ChevronRight />
                        </IconButton>
                      }
                      disablePadding
                    >
                      <ListItemButton
                        className="menu-padding"
                        selected={selectedIndex === 7}
                        onClick={(event) => handleListItemClick(event, webUrl.userTransactions, 7)}
                      >
                        <ListItemIcon>
                          <PaidIcon />
                        </ListItemIcon>
                        <ListItemText primary="Transactions" className="menu-text" />
                      </ListItemButton>
                    </ListItem>
                    <ListItem
                      secondaryAction={
                        <IconButton edge="end" aria-label="comments">
                          <ChevronRight />
                        </IconButton>
                      }
                      disablePadding
                    >
                      <ListItemButton className="menu-padding" onClick={(event) => logoutHandler(event)}>
                        <ListItemIcon>
                          <LogoutIcon />
                        </ListItemIcon>
                        <ListItemText primary="Logout" className="menu-text" />
                      </ListItemButton>
                    </ListItem>
                  </List>
                </Box>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="mb-sidebar-overlay" id="mb-sidebar-overlay"></div>
    </Styles>
  );
}

const mapStateToProps = (state) => ({
  user: state.dashboard.data.user,
  formData: state.dashboard.formData,
});

const mapDispatchToProps = (dispatch) => ({
  logoutUser: (data) => dispatch(unmountUserAction(data)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MobileDashboardMenu));
