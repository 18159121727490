import { showLoaderAction, stopLoaderAction } from "../user/action";
import { startOlympiadQuiz, submitOlympiadQuiz, olympiadLogin, checkOlympiadQuiz } from "./service";

/**
 * Action types
 */
export const actionTypes = {
  START_OLYMPIAD_QUIZ_REQUEST_SUCCESS: "START_OLYMPIAD_QUIZ_REQUEST_SUCCESS",
  SUBMIT_OLYMPIAD_QUIZ_REQUEST_SUCCESS: "SUBMIT_OLYMPIAD_QUIZ_REQUEST_SUCCESS",
  OLYMPIAD_LOGIN_REQUEST_SUCCESS: "OLYMPIAD_LOGIN_REQUEST_SUCCESS",
  CHECK_OLYMPIAD_REQUEST_SUCCESS: "CHECK_OLYMPIAD_REQUEST_SUCCESS",
};

export const receivedStartOlympiadQuizAction = (payload) => ({
  type: actionTypes.START_OLYMPIAD_QUIZ_REQUEST_SUCCESS,
  payload,
});

export const receivedOlympiadLoginAction = (payload) => ({
  type: actionTypes.OLYMPIAD_LOGIN_REQUEST_SUCCESS,
  payload,
});

export const olympiadLoginAction = (data) => {
  return (dispatch) => {
    dispatch(showLoaderAction("olympiadLogin"));

    return olympiadLogin(data)
      .then((response) => {
        dispatch(stopLoaderAction("olympiadLogin"));

        if (response && response.access_token) {
          dispatch(receivedOlympiadLoginAction(response));
          localStorage.setItem("token", response.access_token);
          return true;
        } else {
          return false;
        }
      })
      .catch((error) => {
        dispatch(stopLoaderAction("olympiadLogin"));
        return false;
      });
  };
};

export const startOlympiadQuizAction = (data) => {
  return (dispatch) => {
    dispatch(showLoaderAction("startOlympiadQuiz"));

    return startOlympiadQuiz(data)
      .then((response) => {

        dispatch(stopLoaderAction("startOlympiadQuiz"));
        dispatch(receivedStartOlympiadQuizAction(response));
      })
      .catch((error) => {
        dispatch(stopLoaderAction("startOlympiadQuiz"));
      });
  };
};

export const receivedSubmitOlympiadQuizAction = (payload) => ({
  type: actionTypes.SUBMIT_OLYMPIAD_QUIZ_REQUEST_SUCCESS,
  payload,
});

export const submitOlympiadQuizAction = (data) => {
  return (dispatch) => {
    dispatch(showLoaderAction("submitOlympiadQuiz"));

    return submitOlympiadQuiz(data)
      .then((response) => {
        dispatch(stopLoaderAction("submitOlympiadQuiz"));
        dispatch(receivedSubmitOlympiadQuizAction(response));
      })
      .catch((error) => {
        dispatch(stopLoaderAction("submitOlympiadQuiz"));
      });
  };
};

export const receivedCheckOlympiadQuizAction = (payload) => ({
  type: actionTypes.CHECK_OLYMPIAD_REQUEST_SUCCESS,
  payload,
});

export const checkOlympiadQuizAction = (data) => {
  return (dispatch) => {
    dispatch(showLoaderAction("checkOlympiadQuiz"));

    return checkOlympiadQuiz(data)
      .then((response) => {
        dispatch(stopLoaderAction("checkOlympiadQuiz"));

        if (response) {
          dispatch(receivedCheckOlympiadQuizAction(response));
          return true;
        } else {
          return false;
        }
      })
      .catch((error) => {
        dispatch(stopLoaderAction("checkOlympiadQuiz"));
        return false;
      });
  };
};