import { doPostRequest, doPatchRequest } from "./../../shared/api/base-api";
import { apiUrl } from "./../../shared/constants";

//Login api
export function partnerLogin(data) {
  return doPostRequest(apiUrl.partnerLogin, data);
}

//Verify OTP api
export function verifyOTP(data) {
  return doPostRequest(apiUrl.verifyOTP, data);
}

//Update profile api
export function updateProfile(data) {
  return doPatchRequest(apiUrl.updateProfile, data);
}

//Profile data api
export function getProfileData(data) {
  return doPostRequest(apiUrl.profileData, data);
}
