import { showLoaderAction, stopLoaderAction } from "../user/action";
import { subjectDetails } from "./service";

/**
 * Action types
 */
export const actionTypes = {
  SUBJECT_DETAILS_REQUEST_SUCCESS: "SUBJECT_DETAILS_REQUEST_SUCCESS",
};

export const receivedSubjectDetailsAction = (payload) => {
  return {
    type: actionTypes.SUBJECT_DETAILS_REQUEST_SUCCESS,
    payload,
  };
};

export const subjectDetailsAction = (data) => {
  return (dispatch) => {
    dispatch(showLoaderAction("subjectDetails"));

    return subjectDetails(data)
      .then((response) => {
        dispatch(stopLoaderAction("subjectDetails"));
        return dispatch(receivedSubjectDetailsAction(response));
      })
      .catch((error) => {
        dispatch(stopLoaderAction("subjectDetails"));
      });
  };
};
