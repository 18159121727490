import React, { useState } from "react";
import { Styles } from "../styles/dashboardMenu.js";
import common from "../../../shared/common";
import { webUrl } from "../../../shared/constants.js";
import { unmountUserAction } from "../../user/action.js";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { useDispatch } from "react-redux";
import { Box, IconButton, List, ListItem, ListItemButton, ListItemText, Drawer, ListItemIcon } from "@mui/material";
import { ChevronRight } from "@mui/icons-material";
import HomeIcon from "@mui/icons-material/Home";
import MenuIcon from "@mui/icons-material/Menu";
import PersonIcon from "@mui/icons-material/Person";
import SupervisedUserCircleIcon from "@mui/icons-material/SupervisedUserCircle";
import SchoolIcon from "@mui/icons-material/School";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import PercentIcon from "@mui/icons-material/Percent";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import ShareIcon from "@mui/icons-material/Share";
import LogoutIcon from "@mui/icons-material/Logout";
import NotificationsIcon from "@mui/icons-material/Notifications";
import HelpIcon from "@mui/icons-material/Help";
import { Col } from "react-bootstrap";

const DashboardMenu = (props) => {
  const dispatch = useDispatch();
  const logoutHandler = (e) => {
    e.preventDefault();
    localStorage.clear();
    dispatch(props.logoutUser());
    return props.history.push(webUrl.home);
  };

  const [selectedIndex, setSelectedIndex] = useState(props.index | 0);

  const handleListItemClick = (e, target, index) => {
    e.preventDefault();
    setSelectedIndex(index);
    props.history.push(target);
  };
  return (
    <Styles>
      {/* Course Tag */}
      <div className="course-category">
        <h5>Hi {common.getFromStorage("userName")}!</h5>
        <Box sx={{ width: "100%", bgcolor: "#12385c", color: "white", borderRadius: "5px", boxShadow: "0 12px 25px rgb(0,0,0,0.20)" }}>
          <List
            component="nav"
            aria-label="main mailbox folders"
            sx={{ width: "100%", paddingBottom: "5px", fontSize: "15px", fontFamily: "Manrope" }}
          >
            <ListItem
              secondaryAction={
                <IconButton edge="end" aria-label="comments">
                  <ChevronRight />
                </IconButton>
              }
              disablePadding
            >
              <ListItemButton
                className="menu-padding"
                selected={selectedIndex === 0}
                onClick={(event) => handleListItemClick(event, webUrl.partnerDashboard, 0)}
              >
                <ListItemIcon>
                  <HomeIcon />
                </ListItemIcon>
                <ListItemText primary="Home" className="menu-text" />
              </ListItemButton>
            </ListItem>
            <ListItem
              secondaryAction={
                <IconButton edge="end" aria-label="comments">
                  <ChevronRight />
                </IconButton>
              }
              disablePadding
            >
              <ListItemButton
                className="menu-padding"
                selected={selectedIndex === 1}
                onClick={(event) => handleListItemClick(event, webUrl.studentsList, 1)}
              >
                <ListItemIcon>
                  <PersonIcon />
                </ListItemIcon>
                <ListItemText primary="Students" className="menu-text" />
              </ListItemButton>
            </ListItem>

            <ListItem
              secondaryAction={
                <IconButton edge="end" aria-label="comments">
                  <ChevronRight />
                </IconButton>
              }
              disablePadding
            >
              <ListItemButton
                className="menu-padding"
                selected={selectedIndex === 2}
                onClick={(event) => handleListItemClick(event, webUrl.teachersList, 2)}
              >
                <ListItemIcon>
                  <SupervisedUserCircleIcon />
                </ListItemIcon>
                <ListItemText primary="Teachers" className="menu-text" />
              </ListItemButton>
            </ListItem>

            <ListItem
              secondaryAction={
                <IconButton edge="end" aria-label="comments">
                  <ChevronRight />
                </IconButton>
              }
              disablePadding
            >
              <ListItemButton
                className="menu-padding"
                selected={selectedIndex === 3}
                onClick={(event) => handleListItemClick(event, webUrl.schoolsList, 3)}
              >
                <ListItemIcon>
                  <SchoolIcon />
                </ListItemIcon>
                <ListItemText primary="Schools" className="menu-text" />
              </ListItemButton>
            </ListItem>

            <ListItem
              secondaryAction={
                <IconButton edge="end" aria-label="comments">
                  <ChevronRight />
                </IconButton>
              }
              disablePadding
            >
              <ListItemButton
                className="menu-padding"
                selected={selectedIndex === 4}
                onClick={(event) => handleListItemClick(event, webUrl.partnerWallet, 4)}
              >
                <ListItemIcon>
                  <AccountBalanceWalletIcon />
                </ListItemIcon>
                <ListItemText primary="Wallet" className="menu-text" />
              </ListItemButton>
            </ListItem>

            {/* <ListItem
              secondaryAction={
                <IconButton edge="end" aria-label="comments">
                  <ChevronRight />
                </IconButton>
              }
              disablePadding
            >
              <ListItemButton
                className="menu-padding"
                selected={selectedIndex === 5}
                onClick={(event) => handleListItemClick(event, webUrl.partnerCommissions, 5)}
              >
                <ListItemIcon>
                  <PercentIcon />
                </ListItemIcon>
                <ListItemText primary="Commission" className="menu-text" />
              </ListItemButton>
            </ListItem> */}

            {/* <ListItem
              secondaryAction={
                <IconButton edge="end" aria-label="comments">
                  <ChevronRight />
                </IconButton>
              }
              disablePadding
            >
              <ListItemButton
                className="menu-padding"
                selected={selectedIndex === 6}
                onClick={(event) => handleListItemClick(event, webUrl.partnerCoupons, 6)}
              >
                <ListItemIcon>
                  <LocalOfferIcon />
                </ListItemIcon>
                <ListItemText primary="Coupons" className="menu-text" />
              </ListItemButton>
            </ListItem> */}

            <ListItem
              secondaryAction={
                <IconButton edge="end" aria-label="comments">
                  <ChevronRight />
                </IconButton>
              }
              disablePadding
            >
              <ListItemButton
                className="menu-padding"
                selected={selectedIndex === 7}
                onClick={(event) => handleListItemClick(event, webUrl.promotionalMaterials, 7)}
              >
                <ListItemIcon>
                  <AttachFileIcon />
                </ListItemIcon>
                <ListItemText primary="Promotional Material" className="menu-text" />
              </ListItemButton>
            </ListItem>
            <ListItem
              secondaryAction={
                <IconButton edge="end" aria-label="comments">
                  <ChevronRight />
                </IconButton>
              }
              disablePadding
            >
              <ListItemButton
                className="menu-padding"
                selected={selectedIndex === 9}
                onClick={(event) => handleListItemClick(event, webUrl.partnerNotifications, 9)}
              >
                <ListItemIcon>
                  <NotificationsIcon />
                </ListItemIcon>
                <ListItemText primary="Notifications" className="menu-text" />
              </ListItemButton>
            </ListItem>
            <ListItem
              secondaryAction={
                <IconButton edge="end" aria-label="comments">
                  <ChevronRight />
                </IconButton>
              }
              disablePadding
            >
              <ListItemButton
                className="menu-padding"
                selected={selectedIndex === 10}
                onClick={(event) => handleListItemClick(event, webUrl.partnerEnquiry, 10)}
              >
                <ListItemIcon>
                  <HelpIcon />
                </ListItemIcon>
                <ListItemText primary="Enquiry" className="menu-text" />
              </ListItemButton>
            </ListItem>

            <ListItem
              secondaryAction={
                <IconButton edge="end" aria-label="comments">
                  <ChevronRight />
                </IconButton>
              }
              disablePadding
            >
              <ListItemButton
                className="menu-padding"
                selected={selectedIndex === 8}
                onClick={(event) => handleListItemClick(event, webUrl.referCode, 8)}
              >
                <ListItemIcon>
                  <ShareIcon />
                </ListItemIcon>
                <ListItemText primary="Share Refer Code" className="menu-text" />
              </ListItemButton>
            </ListItem>

            <ListItem
              secondaryAction={
                <IconButton edge="end" aria-label="comments">
                  <ChevronRight />
                </IconButton>
              }
              disablePadding
            >
              <ListItemButton className="menu-padding" onClick={(event) => logoutHandler(event)}>
                <ListItemIcon>
                  <LogoutIcon />
                </ListItemIcon>
                <ListItemText primary="Logout" className="menu-text" />
              </ListItemButton>
            </ListItem>
          </List>
        </Box>
      </div>
    </Styles>
  );
};

const drawerWidth = 240;

const DashboardMenuDrawer = (props) => {
  const { window } = props;

  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <div>
      <DashboardMenu {...props} index={props.index || 0} />
    </div>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <Box component="nav" sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }} aria-label="mailbox folders">
      <Drawer
        container={container}
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          display: { xs: "block", sm: "none" },
          "& .MuiDrawer-paper": { boxSizing: "border-box", width: drawerWidth, position: "relative", borderRight: "none", zIndex: "0" },
        }}
      >
        {drawer}
      </Drawer>
      <Drawer
        variant="permanent"
        sx={{
          display: { xs: "none", sm: "block" },
          "& .MuiDrawer-paper": { boxSizing: "border-box", width: drawerWidth, position: "relative", borderRight: "none", zIndex: "0" },
        }}
        open
      >
        {drawer}
      </Drawer>
    </Box>
  );
};

connect(
  () => {},
  (dispatch) => ({
    drawerMenuToggle: () => dispatch(this.handleDrawerToggle()),
  })
)(DashboardMenuDrawer);

DashboardMenuDrawer.drawerMenuToggle = (props) => {
  // console.log(props.user);
};

const DashboardMenuToggleButton = (props) => {
  const handleDrawerToggle = (props) => {
    DashboardMenuDrawer.drawerMenuToggle(props);
  };
  return (
    <Col lg="12" md="12" sm="12">
      <IconButton color="inherit" aria-label="open drawer" edge="start" onClick={handleDrawerToggle} sx={{ mr: 2, display: { sm: "none" } }}>
        <MenuIcon />
      </IconButton>
    </Col>
  );
};

const mapStateToProps = (state) => ({
  user: state.dashboard.data.user,
});

const mapDispatchToProps = (dispatch) => ({
  logoutUser: (data) => dispatch(unmountUserAction(data)),
});

export { DashboardMenuDrawer, DashboardMenuToggleButton };

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DashboardMenu));
