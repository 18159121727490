// import { showLoaderAction, stopLoaderAction } from "../../pages/user/action";
import { sendAuditEvent } from "../services/common";

/**
 * Action types
 */
export const actionTypes = {
  SEND_MEMBER_AUDIT_EVENT: "SEND_MEMBER_AUDIT_EVENT",
};

export const receivedSendAuditEventAction = (payload) => {
  return {
    type: actionTypes.SEND_MEMBER_AUDIT_EVENT,
    payload,
  };
};

export const sendAuditEventAction = (data) => {
  return (dispatch) => {
    // dispatch(showLoaderAction("sendAuditEvent"));

    return sendAuditEvent(data)
      .then((response) => {
        // dispatch(stopLoaderAction("sendAuditEvent"));
        return dispatch(receivedSendAuditEventAction(response));
      })
      .catch((error) => {
        // dispatch(stopLoaderAction("sendAuditEvent"));
        // throw new Error(error);
      });
  };
};
