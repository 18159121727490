import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import FooterTwo from "../../components/FooterTwo";
import UserHeader from "../../components/UserHeader";
import { Container, Row, Col } from "react-bootstrap";
import SubjectPane from "./components/SubjectPane";
import DashboardMenu from "../dashboard/components/DashboardMenu";
import { Styles } from "../dashboard/styles/dashboard.js";
import { subjectDetailsAction } from "./action";

function TTKDetails(props) {
  let subjectName = props?.subsubcategory?.name || "";

  return (
    <div className="main-wrapper course-details-page">
      {/* User Header */}
      <UserHeader index={4} />

      <Styles>
        {/* Course Grid */}
        <section className="dashboard-grid-area">
          <Container>
            <Row>
              <Col lg="3" md="3" sm="12">
                <DashboardMenu index={4} />
              </Col>
              <Col>
                <Row>
                  <Col lg="12" md="12" sm="12">
                    {props.showLoader.length === 0 && <h5 style={{ marginTop: "10px" }}>{subjectName}</h5>}
                  </Col>
                </Row>
                <Row>
                  <Col lg="12" md="12" sm="12">
                    <SubjectPane {...props} />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </section>
      </Styles>

      {/* Footer 2 */}
      <FooterTwo />
    </div>
  );
}

const mapStateToProps = (state) => ({
  subsubcategory: state.subject.data.subsubcategory,
  showLoader: state.user.showLoader,
});

const mapDispatchToProps = (dispatch) => ({
  subjectDetails: (data) => dispatch(subjectDetailsAction(data)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TTKDetails));
