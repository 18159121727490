import { showLoaderAction, stopLoaderAction } from "../user/action";
import { confirmParticipationLiveClassData, getLiveClassesData, getValidatedLiveClassData } from "./service";

/**
 * Action types
 */
export const actionTypes = {
  LIVECLASSES_DATA_REQUEST_SUCCESS: "LIVECLASSES_DATA_REQUEST_SUCCESS",
  LIVE_CLASS_CHECK_DATA_REQUEST_SUCCESS: "LIVE_CLASS_CHECK_DATA_REQUEST_SUCCESS",
  LIVE_CLASS_PARTICIPATION_DATA_REQUEST_SUCCESS: "LIVE_CLASS_PARTICIPATION_DATA_REQUEST_SUCCESS",
  SET_FORM_DATA: "SET_FORM_DATA",
};

export const receivedLiveClassesDataAction = (payload) => ({
  type: actionTypes.LIVECLASSES_DATA_REQUEST_SUCCESS,
  payload,
});

export const liveClassesDataAction = (data) => {
  return (dispatch) => {
    dispatch(showLoaderAction("getLiveClassesData"));

    return getLiveClassesData(data)
      .then((response) => {
        dispatch(stopLoaderAction("getLiveClassesData"));

        if (response) dispatch(receivedLiveClassesDataAction(response));
      })
      .catch((error) => {
        dispatch(stopLoaderAction("getLiveClassesData"));
      });
  };
};

export const setLiveClassesFormDataAction = (payload) => ({
  type: actionTypes.SET_FORM_DATA,
  payload,
});

export const receivedLiveClassCheckDataAction = (payload) => ({
  type: actionTypes.LIVE_CLASS_CHECK_DATA_REQUEST_SUCCESS,
  payload,
});

export const liveClassCheckDataAction = (data) => {
  return (dispatch) => {
    // dispatch(showLoaderAction("getLiveClassCheckData"));

    return getValidatedLiveClassData(data)
      .then((response) => {
        
        dispatch(stopLoaderAction("getLiveClassCheckData"));
        
        dispatch(receivedLiveClassCheckDataAction(response));

        return response;
      })
      .catch((error) => {
        dispatch(stopLoaderAction("getLiveClassCheckData"));
      });
  };
};

export const receivedLiveClassParticipationDataAction = (payload) => ({
  type: actionTypes.LIVE_CLASS_PARTICIPATION_DATA_REQUEST_SUCCESS,
  payload,
});

export const liveClassParticipationDataAction = (data) => {
  return (dispatch) => {
    // dispatch(showLoaderAction("getLiveClassParticipationData"));

    return confirmParticipationLiveClassData(data)
      .then((response) => {
        
        dispatch(stopLoaderAction("getLiveClassParticipationData"));
        
        dispatch(receivedLiveClassParticipationDataAction(response));

        return response;
      })
      .catch((error) => {
        dispatch(stopLoaderAction("getLiveClassParticipationData"));
      });
  };
};
