import { showLoaderAction, stopLoaderAction } from "../user/action";
import { getSubsubcategoriesData } from "./service";

/**
 * Action types
 */
export const actionTypes = {
  SUBSUBCATEGORIES_DATA_REQUEST_SUCCESS: "SUBSUBCATEGORIES_DATA_REQUEST_SUCCESS",
  SET_FORM_DATA: "SET_FORM_DATA"
};

export const receivedSubsubcategoriesDataAction = (payload) => ({
  type: actionTypes.SUBSUBCATEGORIES_DATA_REQUEST_SUCCESS,
  payload,
});

export const subsubcategoriesDataAction = (data) => {

  return (dispatch) => {

    dispatch(showLoaderAction("getSubsubcategoriesData"));

    return getSubsubcategoriesData(data)
      .then((response) => {
        dispatch(stopLoaderAction("getSubsubcategoriesData"));

        if (response) dispatch(receivedSubsubcategoriesDataAction(response));
      })
      .catch((error) => {
        dispatch(stopLoaderAction("getSubsubcategoriesData"));
      });
  };
};

export const setSubsubcategoriesFormDataAction = (payload) => ({
  type: actionTypes.SET_FORM_DATA,
  payload,
});