import { doGetRequest, doPostRequest } from "../../shared/api/base-api";
import { apiUrl } from "../../shared/constants";

//Subscription List data api
export function getSubscriptionsListData() {
  return doGetRequest(apiUrl.subscriptionsList);
}

//Generate New Test api
export function generateTest(data) {
  return doPostRequest(apiUrl.generateNewTest, data);
}