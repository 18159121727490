import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import MathJax from "mathjax3-react";
import { getChapterTheoryAction } from "./action";
import { Styles } from "./styles";
import LoaderNew from "../../components/LoaderNew";

const ChapterTheory = (props) => {
  const {
    match: { params },
  } = props;

  useEffect(() => {
    props.getChapterTheoryData([params.id]); // To do: params.type should be default one
  }, []);

  if (props.showLoader && props.showLoader.length > 0) {
    return <LoaderNew />;
  }

  return (
    props.showLoader.length === 0 && (
      <Styles>
        <div className="chapter-theory">
          <MathJax.Provider>
            <MathJax.Html html={props.theory} />
          </MathJax.Provider>
        </div>
      </Styles>
    )
  );
};

const mapStateToProps = (state) => ({
  theory: state.embed.data.theory || "",
  showLoader: state.user.showLoader,
});

const mapDispatchToProps = (dispatch) => ({
  getChapterTheoryData: (data) => dispatch(getChapterTheoryAction(data)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ChapterTheory));
