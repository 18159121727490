import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Container, Row, Col } from "react-bootstrap";
import ReactPixel from "react-facebook-pixel";
import TestimonialSlider from "../../components/TestimonialSlider";
import ThreeSection from "../../components/ThreeSection";
import VideoSlider from "../../components/VideoSlider";
import ButtonArea from "../../components/ButtonArea";
import { config, webUrl } from "../../shared/constants";
import { Styles as StylesAbout } from "../../components/styles/aboutUs.js";
import common from "../../shared/common";
import HeaderCampaign from "../../components/HeaderCampaign";
import { Button, Typography } from "@mui/material";
import { getMasterDataAction, sendCampaignOTPAction, verifyCampaignOTPAction } from "./action";
import { Styles } from "./styles";

const TeacherCampaign = (props) => {
  //Facebook Pixel
  const advancedMatching = {};
  const options = {
    autoConfig: true,
    debug: false,
  };
  ReactPixel.init(config.FACEBOOK_PIXEL_ID, advancedMatching, options);
  ReactPixel.pageView();

  const onClickJoinNow = () => {
    if (common.getFromStorage("userName")) props.history.push(webUrl.userDashboard);
  };

  const focusToForm = () => {
    const name = document.getElementById("name");
    name && name.focus();
  };

  return (
    <div className="main-wrapper">
      {/*  Top Bar */}
      <Styles>
        <section className="top-bar">
          <Container>
            <Row>
              <Col sm="12">
                <Typography variant="h4" gutterBottom component="div" className="text-center top-bar-content">
                  First 100 users will get <span>25% OFF</span> and early access to the new content
                </Typography>
              </Col>
            </Row>
          </Container>
        </section>
        {/* Header */}
        <HeaderCampaign {...props} onButtonClick={onClickJoinNow} showPopup={!!!common.getFromStorage("userName")} />
        {/* Three Section */}
        <ThreeSection />
        {/* Button Area */}
        <ButtonArea buttonText="JOIN NOW" onHeroButtonClick={focusToForm} showPopup={false} bgColor="rgb(246,249,255)" />
        <StylesAbout>
          {/* About Us */}
          <section className="about-us">
            <Container>
              <Row>
                <Col md="12">
                  <div className="sec-title text-center">
                    <h4 className="about-title" style={{ fontWeight: "600" }}>
                      ALYSS Content Edge
                    </h4>
                  </div>
                </Col>
                <Col md="6">
                  <div className="about-image">
                    <img
                      src={process.env.PUBLIC_URL + `/assets/images/about/teacher_fr_about.webp`}
                      className="main-img d-block"
                      alt=""
                      style={{
                        width: "97%",
                        backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/about/Teacher_Bg_about.webp)`,
                        backgroundSize: "100% 100%",
                        padding: "10px",
                      }}
                    />
                  </div>
                </Col>
                <Col md="6">
                  <div className="about-content" style={{ marginTop: "42px" }}>
                    <div className="about-para">
                      We believe that the purpose of education is not about flooding the student's mind with information from the syllabus but about
                      awakening a genuine interest and questioning spirit in the minds of the students that forms the basis of scientific learning.
                      This is what our content and animation experts work ceaselessly on, in an effort to nurture the spirit of scientific curiosity
                      in school students... to enable them to become future scientists!
                    </div>
                    <div className="about-para">
                      <ul>
                        <li>To empower teachers</li>
                        <li>To make science and maths learning a simple and pleasant exercise</li>
                        <li>To enhance scientific curiosity among students</li>
                        <li>To inspire future scientists and mathematicians</li>
                      </ul>
                    </div>
                    <Button variant="contained" size="large" className={"mt-3 mb-3 orange-bg pull-right"} onClick={focusToForm}>
                      Connect
                    </Button>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
        </StylesAbout>
        {/* Testimonial Slider */}
        <TestimonialSlider />
        {/* Video Slider */}
        <VideoSlider />
      </Styles>
    </div>
  );
};

const mapStateToProps = (state) => ({
  masterData: state.user.data.masterData.data || {},
  cities: state.teacherCampaign.data.cities,
  token: state.teacherCampaign.data.token,
});

const mapDispatchToProps = (dispatch) => ({
  getMasterData: (data) => dispatch(getMasterDataAction(data)),
  sendCampaignOTP: (data) => dispatch(sendCampaignOTPAction(data)),
  verifyCampaignOTP: (data) => dispatch(verifyCampaignOTPAction(data)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TeacherCampaign));
