import styled from "styled-components";
import { colors } from "../../../components/common/element/elements";
// import { colors } from "../../../components/common/element/elements.js";

export const CustomStyle = styled.div`
  .mobile-field {
    margin: 10px;
    padding: 0 10px;
    width: 100%;
    background: #f0f0f0;
    border-bottom: 1px solid #8b8b8b;
    border-radius: 5px 5px 0 0;
    display: flex;
    flex-direction: column;
  }
  .mobile-field.error {
    border-bottom: 2px solid #d32f2f;
  }
  .phone-number {
    border: none;
    width: 100%;
    height: 35px;
  }
  .selected-flag .iti-flag {
    margin-top: 2px;
    top: 8px;
    position: relative;
    left: 8px;
  }

  .selected-dial-code {
    padding-left: 6px !important;
  }

  .mobile-error {
    color: #d43232;
    font-size: 9px;
    position: relative;
    bottom: 10px;
    margin-left: 20px;
    font-weight: 600;
  }

  .error .mobile-title {
    color: #d53e3e;
    font-weight: 600;
  }

  .mobile-title {
    font-size: 10px;
    float: left;
    @media (max-width: 767px) {
    }
  }

  .intl-tel-input .country-list {
    overflow-x: hidden;
    max-width: 100%;
  }

  .orange-bg {
    ${colors.gredient_orange_bg}
  }
`;
