import React, { useState,useEffect } from "react";
import { Container, Row, Col, Dropdown } from "react-bootstrap";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";

import { config, messages,apiUrl } from "../../../shared/constants.js";
import { Styles } from "../styles/paymentInfo.js";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import LoaderImage from "../../../components/LoaderImage.js";

function PaymentInfo(props) {
  const [showPaypal, setShowPaypal] = useState(false);
  // const [paypalSuccess, setPaypalSuccess] = useState(false);
  // const [paypalErrorMessage, setPaypalErrorMessage] = useState("");
  // const [paypalOrderID, setPaypalOrderID] = useState(false);
  const [orderCode, setOrderCode] = useState("");
  const [formData, setFormData] = useState({
    user_addresses: 0,
    name: "",
    email: "",
    address: "",
    city: "",
    state: "",
    postal_code: "",
  });
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://checkout.razorpay.com/v1/checkout.js";
    script.crossorigin = "anonymous";
    script.type = "text/javascript";
    document.head.appendChild(script);
  }, []);
  const pickedItem = (key, data) => {
    if (data) {
      let selectedItem = data.filter((item) => item.id === parseInt(key))[0];

      if (selectedItem) {
        if (selectedItem.id === 0) {
          let newAddresses = document.getElementsByClassName("new_address");

          for (let i = 0; i < newAddresses.length; i++) {
            newAddresses[i].style.display = "block";
          }
          return selectedItem.name;
        } else {
          let newAddresses = document.getElementsByClassName("new_address");
          for (let i = 0; i < newAddresses.length; i++) {
            newAddresses[i].style.display = "none";
          }
          return (
            selectedItem.name + ", " + selectedItem.address + ", " + selectedItem.city + ", " + selectedItem.state + ", " + selectedItem.postal_code
          );
        }
      }
      return "";
    }
    return "";
  };

  const onFieldChange = (name, value) => {
    setFormData({
      ...formData,
      [name]: value,
    });

    resetError(name);
  };

  const isValidFormData = () => {
    let isValid = true;

    if (+formData.user_addresses === 0) {
      if (!formData || !formData.name) {
        setError("name", messages.requiredField);
        isValid = false;
      } else {
        resetError("name");
      }

      if (!formData || !formData.email) {
        setError("email", messages.requiredField);
        isValid = false;
      } else if (!isEmail(formData.email)) {
        setError("email", messages.invalidEmailField);
        isValid = false;
      } else {
        resetError("email");
      }

      if (!formData || !formData.address) {
        setError("address", messages.requiredField);
        isValid = false;
      } else {
        resetError("address");
      }

      if (!formData || !formData.city) {
        setError("city", messages.requiredField);
        isValid = false;
      } else {
        resetError("city");
      }

      if (!formData || !formData.state) {
        setError("state", messages.requiredField);
        isValid = false;
      } else {
        resetError("state");
      }

      if (!formData || !formData.postal_code) {
        setError("postal_code", messages.requiredField);
        isValid = false;
      } else {
        resetError("postal_code");
      }
    }

    return isValid;
  };

  const loadPaytmScript = (orderId, transactionToken, amount) => {
    const script = document.createElement("script");
    script.src = "https://" + config.PAYTM_HOST + "/merchantpgpui/checkoutjs/merchants/" + config.PAYTM_MERCHANT_ID + ".js";
    script.crossorigin = "anonymous";
    script.type = "application/javascript";
    document.head.appendChild(script);
    script.onload = () => {
      var config = {
        root: "",
        flow: "DEFAULT",
        data: {
          orderId: orderId,
          token: transactionToken,
          tokenType: "TXN_TOKEN",
          amount: amount,
        },
        merchant: {
          name: "ALYSS Edusolutions Pvt Ltd",
        },
        handler: {
          // transactionStatus: function transactionStatus(paymentStatus) {
          //     console.log("paymentStatus => ", paymentStatus);
          // },
          notifyMerchant: function (eventName, data) {
            console.log("notifyMerchant handler function called");
            console.log("eventName => ", eventName);
            console.log("data => ", data);
          },
        },
      };

      if (window.Paytm && window.Paytm.CheckoutJS) {
        window.Paytm.CheckoutJS.onLoad(function excecuteAfterCompleteLoad() {
          // initialze configuration using init method
          window.Paytm.CheckoutJS.init(config)
            .then(function onSuccess() {
              // after successfully update configuration invoke checkoutjs
              window.Paytm.CheckoutJS.invoke();
            })
            .catch(function onError(error) {
              console.log("error => ", error);
            });
        });
      }
    };
  };

  const onPayNowClick = (e) => {
    e.preventDefault();
    if (isValidFormData()) {
      let checkoutData;
      if (+formData.user_addresses === 0) {
        checkoutData = {
          ...formData,
          address_id: +formData.user_addresses !== 0 ? +formData.user_addresses : null,
        };
        delete checkoutData.user_addresses;
      } else {
        checkoutData = {
          address_id: +formData.user_addresses !== 0 ? +formData.user_addresses : null,
        };
      }
      props.razorpaycheckout(checkoutData).then((response) => {
        const order_code = response.orderCode;
        const transactionToken = response.gatewayType.transactionToken;
        setOrderCode(order_code);
        if (props.cart.currency === "USD") {
          setShowPaypal(true);
        } else {
         const profile = localStorage.getItem("profile");
         const profileData = profile ? JSON.parse(profile) :"";
        const {orderCode }  = response;
        //console.log("order nnnn",response.orderCode)
          const options = {
            key : config.RAZOR_PAY_MERCHANT_KEY,
            amount: props.cart.payable_amount,
            currency: "INR",
            name: "Alyss Education",
            description: "Alyss Education Payment Service",
            image: "https://example.com/your_logo",
            order_id: orderCode.id,
            redirect: true,
            callback_url: `${apiUrl.baseApiUrl}/razor-pay-verification`,
            prefill: {
              name: profileData.name || '',
              email: profileData.email || '',
              contact: profileData.mobile || ''
            },
            notes: {
              address: "Razorpay Corporate Office"
            },
            theme: {
              color: "#121212"
            }
          };
          const rozer = new window.Razorpay(options);
          rozer.open();
          //loadPaytmScript(order_code, transactionToken, props.cart.payable_amount);
        }
      });
    }
  };

  const createPaypalOrder = (data, actions) => {
    const items = props.cart.items.map((item) => {
      return {
        name: item.subsubcategory.name,
        tax: { currency_code: "USD", value: "0.00" },
        quantity: "1",
        unit_amount: { currency_code: "USD", value: item.subscription.discounted_price_in_usd + ".00" },
      };
    });
    return actions.order
      .create({
        purchase_units: [
          {
            // description: "Alyss Edusolutions Course Subscription",
            amount: {
              currency_code: "USD",
              value: props.cart.payable_amount,
              breakdown: {
                item_total: { currency_code: "USD", value: props.cart.payable_amount },
                tax_total: { currency_code: "USD", value: "0.00" },
              },
            },
            items,
            custom_id: orderCode,
          },
        ],
        // not needed if a shipping address is actually needed
        application_context: {
          shipping_preference: "NO_SHIPPING",
        },
      })
      .then((orderID) => {
        // setPaypalOrderID(orderID);
        return orderID;
      });
  };

  // check Approval
  const onPaypalApprove = (data, actions) => {
    return actions.order.capture().then(function (details) {
      props.paypalReturn(details);
    });
  };
  //capture likely error
  const onPaypalError = (data, actions) => {
    data.reason = "An Error occured with your payment";
    data.order_code = orderCode;
    props.paypalCancel(data);
  };
  //capture likely cancel
  const onPaypalCancel = (data, actions) => {
    data.reason = "User cancelled the transaction";
    data.order_code = orderCode;
    props.paypalCancel(data);
  };

  function isEmail(email) {
    return /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(email);
  }
  const setError = (input, message) => {
    const formControl = document.getElementsByName(input)[0].parentElement;
    const errorMsg = formControl.querySelector(".payment_input-msg");
    formControl.classList.add("error");
    formControl.classList.add("text-left");
    errorMsg.innerText = message;
  };

  const resetError = (input) => {
    const formControl = document.getElementsByName(input)[0].parentElement;
    // const errorMsg = formControl.querySelector(".payment_input-msg");
    formControl.classList.remove("error");
    formControl.classList.remove("text-left");
    // errorMsg.innerText = "";
  };

  if (props.showLoader && props.showLoader.length > 0) {
    return <LoaderImage />;
  }

  return (
    <PayPalScriptProvider options={{ "client-id": config.PAYPAL_CLIENT_ID, components: "buttons,funding-eligibility" }}>
      <Styles>
        {/* Main Wrapper */}
        <div className="main-wrapper payment-page">
          {/* Payment Area */}
          <section className="payment-area">
            <Container>
              <Row>
                <Col md="12" className="pad-0">
                  <div className="payment-box">
                    <div className="payment-title text-center">
                      <h3>Billing Information</h3>
                    </div>

                    <form id="form_payment" className="form">
                      <div className="form-control">
                        <label>Select Your Billing Address</label>
                        <Dropdown
                          className="pick-list"
                          name="user_addresses"
                          id="user_addresses"
                          onSelect={(e) => {
                            onFieldChange("user_addresses", e);
                          }}
                        >
                          <Dropdown.Toggle as="a" className="pick-item">
                            <span>{pickedItem(formData.user_addresses, props.addresses)}</span>
                            <KeyboardArrowDownIcon />
                          </Dropdown.Toggle>
                          <Dropdown.Menu as="ul">
                            {props.addresses &&
                              props.addresses.map((item) => (
                                <Dropdown.Item as="li" key={item.id} eventKey={item.id}>
                                  {item.id === 0 && item.name}
                                  {item.id !== 0 && `${item.name}, ${item.address}, ${item.city}, ${item.state}, ${item.postal_code}`}
                                </Dropdown.Item>
                              ))}
                          </Dropdown.Menu>
                        </Dropdown>
                        <span className="payment_input-msg"></span>
                      </div>
                      <div className="form-control new_address">
                        <label> Customer Name</label>
                        <input
                          type="text"
                          autoFocus
                          placeholder="Customer Name"
                          id="name"
                          name="name"
                          onChange={(e) => {
                            onFieldChange(e.target.name, e.target.value);
                          }}
                        />
                        <span className="payment_input-msg"></span>
                      </div>
                      <div className="form-control new_address">
                        <label>Email</label>
                        <input
                          type="text"
                          placeholder="Email"
                          id="email"
                          name="email"
                          onChange={(e) => {
                            onFieldChange(e.target.name, e.target.value);
                          }}
                        />
                        <span className="payment_input-msg"></span>
                      </div>
                      <div className="form-control new_address">
                        <label>Address</label>
                        <input
                          placeholder="Address"
                          id="address"
                          name="address"
                          onChange={(e) => {
                            onFieldChange(e.target.name, e.target.value);
                          }}
                        />
                        <span className="payment_input-msg"></span>
                      </div>
                      <div className="form-control new_address">
                        <label>City</label>
                        <input
                          placeholder="City"
                          id="city"
                          name="city"
                          onChange={(e) => {
                            onFieldChange(e.target.name, e.target.value);
                          }}
                        />
                        <span className="payment_input-msg"></span>
                      </div>
                      <div className="form-control new_address">
                        <label>State</label>
                        <input
                          placeholder="State"
                          id="state"
                          name="state"
                          onChange={(e) => {
                            onFieldChange(e.target.name, e.target.value);
                          }}
                        />
                        <span className="payment_input-msg"></span>
                      </div>
                      <div className="form-control new_address">
                        <label>Postal Code</label>
                        <input
                          type="text"
                          placeholder="Postal Code"
                          id="postal_code"
                          name="postal_code"
                          onChange={(e) => {
                            onFieldChange(e.target.name, e.target.value);
                          }}
                        />
                        <span className="payment_input-msg"></span>
                      </div>

                      <div className="form-control">
                        <p>
                          <img
                            src={process.env.PUBLIC_URL + "/assets/images/secure-payment.png"}
                            alt=""
                            className="img-fluid"
                            style={{ height: "60px" }}
                          />
                        </p>
                      </div>
                      <div className="form-control">
                        By clicking on <i> Confirm and Pay Now</i> button, you have agreed to <a href="#">Terms of use</a> and confirm that this
                        purchase is for personal use only and not for any commercial use.
                      </div>
                      {!showPaypal ? (
                        <button
                          onClick={(e) => {
                            onPayNowClick(e);
                          }}
                        >
                          Confirm and Pay Now
                        </button>
                      ) : null}
                      {showPaypal ? (
                        <PayPalButtons
                          fundingSource="paypal"
                          style={{ layout: "vertical" }}
                          createOrder={createPaypalOrder}
                          onApprove={onPaypalApprove}
                          onError={onPaypalError}
                          onCancel={onPaypalCancel}
                        />
                      ) : null}
                    </form>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
        </div>
      </Styles>
    </PayPalScriptProvider>
  );
}

export default PaymentInfo;
