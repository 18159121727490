import React, { useEffect } from "react";
// import Datas from '../data/faq-event/faq-event.json';
// import { Link } from 'react-router-dom';
import { Container, Row, Col } from "react-bootstrap";
import { Styles } from "./styles/appSection";

function AppSection() {
  useEffect(() => {
    const accordionButton = document.querySelectorAll(".accordion-button");
    accordionButton.forEach((button) => {
      button.addEventListener("click", () => {
        button.classList.toggle("active");
        const content = button.nextElementSibling;

        if (button.classList.contains("active")) {
          content.className = "accordion-content show";
          content.style.maxHeight = content.scrollHeight + "px";
        } else {
          content.className = "accordion-content";
          content.style.maxHeight = "0";
        }
      });
    });
  });

  return (
    <Styles>
      <section className="event-faq-area">
        <Container>
          <Row>
            <Col md="6">
              <div className="event-area">
                <Row>
                  <Col md="12">
                    <div className="sec-title">
                      <h4>
                        Learn free on the <span>App</span>
                      </h4>
                    </div>
                    <div>
                      <p>Download the lessons and learn anytime, anywhere from the free courses available on our app</p>
                      <div className="free-app-img">
                        <img className="blend-mode" src="https://www.simplilearn.com/ice9/assets/home/free-app.png" alt="Free Learning App" />
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col md="6">
              <div className="faq-area">
                <div className="qr-code">
                  <p>Scan this QR code on your camera app to download the app</p>
                  <img className="blend-mode" src={process.env.PUBLIC_URL + "/assets/images/icons/QR.webp"} alt="QR Code" />
                </div>
                <div className="google-play-app">
                  <div id="playStoreButtonId">
                    <div>
                      <a target="_blank" rel="noopener noreferrer" href="https://play.google.com/store/apps/details?id=com.onlinestudy.alyssmission">
                        <img className="blend-mode" src={process.env.PUBLIC_URL + "/assets/images/icons/android.svgz"} alt="Get the Android App" />
                      </a>
                      {/* <a target="_blank" rel="noopener noreferrer" href="https://apps.apple.com/app/simplilearn/id963042747?ls=1">
                                            <img className="blend-mode" src={process.env.PUBLIC_URL + "/assets/images/icons/IOS.svgz"} alt="Get the iOS App" />
                                        </a> */}
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </Styles>
  );
}

export default AppSection;
