import styled from "styled-components";
import { colors, fonts } from "./../../../components/common/element/elements.js";

export const Styles = styled.div`
  /* Course Category */
  .course-category {
    border: 1px solid ${colors.border1};
    border-radius: 5px;
    box-shadow: rgb(0 0 0 / 8%) 0px 0px 20px;
    h5 {
      color: ${colors.black1};
      font-weight: 600;
      padding: 15px 15px 8px 0px;
      margin-left: 15px;
      position: relative;
      font-size: 18px;
      &:before {
        position: absolute;
        content: "";
        background: ${colors.green};
        width: 50px;
        height: 2px;
        bottom: 0;
        left: 0;
      }

      @media (max-width: 575px) {
        font-size: 17px;
      }
    }
    h6 {
      padding: 5px 0px;
      margin-left: 15px;
      margin-top: 3px;
      font-size: 12px;
    }
    ul.category-item {
      li.check-btn {
        border-top: 1px dashed ${colors.border3};
        padding: 10px 0;

        &:last-child {
          border-bottom: 1px dashed ${colors.border3};
        }

        label {
          font-size: 14px;
          color: ${colors.text3};
          display: block;
          margin-bottom: 4px;
          cursor: pointer;

          input[type="checkbox"] {
            border: 2px solid ${colors.border3};
            appearance: none;
            width: 18px;
            height: 18px;
            cursor: pointer;
            margin-right: 6px;
            position: relative;
            top: 4px;

            &:focus {
              outline: none;
            }

            &:checked {
              background-color: ${colors.green};
              background: ${colors.green}
                url("data:image/gif;base64,R0lGODlhCwAKAIABAP////3cnSH5BAEKAAEALAAAAAALAAoAAAIUjH+AC73WHIsw0UCjglraO20PNhYAOw==") 2px 2px no-repeat;
              border-color: ${colors.green};
            }
          }

          span {
            float: right;
            font-size: 12px;
            color: ${colors.text2};
            line-height: 27px;
          }
        }
      }
    }

    .side-menu {
      font-size: 13px;
      color: ${colors.text2};
      background: transparent;
      border: none;
      padding: 0;
      box-shadow: none;

      img {
        margin-right: 5px;
        max-width: 21px;
      }

      i {
        font-size: 12px;
        margin-left: 3px;
      }

      &::after {
        display: none;
      }

      &:hover {
        cursor: pointer;
        color: ${colors.green};
      }
    }
  }
  .MuiListItem-root {
    .Mui-selected {
      background: linear-gradient(90deg, rgb(65, 182, 230) 0%, rgb(57, 218, 253) 100%);
      &:hover {
        color: ${colors.white};
        .MuiListItemIcon-root {
          color: ${colors.white};
        }
      }
    }
    &:hover {
      color: ${colors.green};
      .MuiListItemIcon-root {
        color: ${colors.green};
      }
      .MuiIconButton-root {
        color: ${colors.black};
      }
    }
  }
  .MuiListItemIcon-root {
    min-width: 35px;
  }
  .menu-text {
    span {
      font-family: ${fonts.manrope};
      font-size: 15px;
    }
  }
  .mobile-hidden {
    @media (max-width: 767px) {
      display: none;
    }
  }
`;
