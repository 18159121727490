import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Container, Row, Col } from "react-bootstrap";
import CourseItemGrid from "./components/CourseItemsGrid";
import FooterTwo from "../../components/FooterTwo";
import { Styles } from "./styles/dashboard.js";
import UserHeader from "../../components/UserHeader";
import DashboardMenu from "./components/DashboardMenu";
import { dashboardDataAction, setDashboardFormDataAction } from "./action";
import DashboardFilter from "./components/DashboardFilter";
import { handleReloadAppAction } from "../user/action";
import LoaderNew from "../../components/LoaderNew";
import common from "../../shared/common";

const Dashboard = (props) => {
  const redirectURL = common.getFromStorage("redirectURL") || "";

  if (redirectURL !== "") {
    common.setToStorage("redirectURL", "");
    props.history.replace(redirectURL);
  }
  useEffect(() => {
    if (!props.formData.category_id) {
      props.dashboardData([]);
    } else if (props.formData.subcategory_id) {
      props.dashboardData([props.formData.subcategory_id]);
    }
  }, [props.formData.subcategory_id]);

  return (
    <div className="main-wrapper course-page">
      {/* User Header */}
      <UserHeader />

      <Styles>
        {/* Course Grid */}
        <section className="dashboard-grid-area">
          <Container>
            <Row>
              <Col lg="3" md="3" sm="12">
                <DashboardMenu {...props} />
              </Col>
              <Col>
                {props.showLoader && props.showLoader.length > 0 && <LoaderNew />}
                {props.showLoader.length === 0 && (
                  <>
                    <Row>
                      {/* <DashboardMenuToggleButton  {...props} /> */}
                      <Col lg="12" md="12" sm="12">
                        <DashboardFilter {...props} />
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="12" md="12" sm="12">
                        <div className="course-items">
                          <CourseItemGrid
                            {...props}
                            data={props.learnings}
                            loader={props.showLoader}
                            headerText="My Learnings"
                            notFoundText="Buy now from recommended section."
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="12" md="12" sm="12">
                        <div className="course-items">
                          <CourseItemGrid
                            {...props}
                            data={props.recommended}
                            loader={props.showLoader}
                            canBuy={true}
                            headerText="Recommended"
                            notFoundText="No data found."
                          />
                        </div>
                      </Col>
                    </Row>
                  </>
                )}
              </Col>
            </Row>
          </Container>
        </section>
      </Styles>

      {/* Footer 2 */}
      <FooterTwo />
    </div>
  );
};

const mapStateToProps = (state) => ({
  categories: state.dashboard.data.categories,
  learnings: state.dashboard.data.learnings,
  recommended: state.dashboard.data.recommended,
  formData: state.dashboard.formData,
  showLoader: state.user.showLoader,
});

const mapDispatchToProps = (dispatch) => ({
  dashboardData: (data) => dispatch(dashboardDataAction(data)),
  setFormData: (data) => dispatch(setDashboardFormDataAction(data)),
  handleReloadApp: () => dispatch(handleReloadAppAction()),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Dashboard));
