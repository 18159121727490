import React from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import { Container, Row, Col } from "react-bootstrap";
import { Styles } from "../styles/concept.js";
import { webUrl } from "../../../shared/constants.js";
import { ButtonBase, Grid, List, ListItem, ListItemIcon, ListItemText, Paper } from "@mui/material";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const Img = styled("img")({
  margin: "auto",
  display: "block",
  maxWidth: "100%",
  maxHeight: "100%",
});

function Tests(props) {
  const [open, setOpen] = React.useState(false);
  const [quizData, setQuizData] = React.useState({});

  const handleClickOpen = (data) => {
    setQuizData(data);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const startModuleQuiz = (e, quiz_id) => {
    e.preventDefault();
    props.redirectTo(webUrl.startModuleQuiz + "/" + quiz_id);
  };
  return (
    <Styles>
      {/* Faq & Event */}
      <section className="event-faq-area">
        <Row className="margin-bottom-15">
          <Col md="12">
            <h5 className="grid-header  ml-0">MCQs Quiz</h5>
          </Col>
        </Row>
        <Row>
          <Col md="12">
            <div className="faq-area">
              <div className="faq-box">
                <div className="faq-item">
                  <div className="accordion-content show">
                    {props.module.quizzes.map((data, i) => (
                      <div className="event-box" key={i}>
                        <Row>
                          <Col xl="12" lg="12" md="12">
                            <div className="event-content">
                              <div
                                className="content-box"
                                style={{
                                  width: "100%",
                                  position: "relative",
                                  left: "0",
                                  top: 0,
                                }}
                              >
                                <Row>
                                  <Col md="9">
                                    <div className="event-title">
                                      <h6 style={{ marginTop: 10 }}>{data.name}</h6>
                                    </div>
                                  </Col>
                                  <Col md="3" className="text-center">
                                    <div className="join-btn" style={{ display: "flex", justifyContent: "right" }}>
                                      <div>
                                        <Button
                                          onClick={(e) => {
                                            e.preventDefault();
                                            handleClickOpen(data);
                                          }}
                                        >
                                          Start Quiz
                                        </Button>
                                      </div>
                                    </div>
                                  </Col>
                                </Row>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    ))}
                    <BootstrapDialog fullWidth={"xl"} maxWidth="xl" onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
                      <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
                        {quizData.name}
                      </BootstrapDialogTitle>
                      <DialogContent dividers>
                        <Paper sx={{ p: 2, margin: "auto", maxWidth: "xl", flexGrow: 1 }}>
                          <Grid container spacing={2}>
                            <Grid item xs={12} md={8}>
                              <ButtonBase sx={{ width: "100%", height: "auto" }}>
                                <Img alt="complex" src="/assets/images/quiz/test_quiz.webp" />
                              </ButtonBase>
                            </Grid>
                            <Grid item xs={12} md={4} sm container>
                              <Grid item xs container direction="column" spacing={2}>
                                <Grid item xs>
                                  <Typography gutterBottom variant="h4" component="div">
                                    Quiz Details: {quizData.name}
                                  </Typography>
                                  <Typography variant="h5" gutterBottom>
                                    Total Questions: {quizData.random_questions}
                                  </Typography>
                                  <Typography variant="h5">Quiz Time: {quizData.quiz_time} minutes</Typography>
                                  <Typography variant="h6" sx={{ background: "#ccc", color: "white", textAlign: "center", marginTop: "10px" }}>
                                    READ THE FOLLOWING INSTRUCTIONS CAREFULLY
                                  </Typography>
                                  <Typography>
                                    <ul>
                                      <li
                                        style={{
                                          fontSize: "16px",
                                          margin: "10px 15px",
                                        }}
                                      >{`This is a timed quiz with ${quizData.random_questions} questions to be answered in ${quizData.quiz_time} minutes`}</li>
                                      <li
                                        style={{
                                          fontSize: "16px",
                                          margin: "10px 15px",
                                        }}
                                      >
                                        These questions will be randomly picked from the question bank
                                      </li>
                                      <li
                                        style={{
                                          fontSize: "16px",
                                          margin: "10px 15px",
                                        }}
                                      >
                                        You can skip a tough question and come back to it later.
                                      </li>
                                      <li
                                        style={{
                                          fontSize: "16px",
                                          margin: "10px 15px",
                                        }}
                                      >
                                        The quiz will start as soon as you click the <strong>Start Quiz</strong> button
                                      </li>
                                    </ul>
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Paper>
                      </DialogContent>
                      <DialogActions sx={{ margin: "0 auto" }}>
                        <Button
                          variant="contained"
                          sx={{
                            width: "275px",
                            fontSize: "20px",
                          }}
                          className="btn-green"
                          autoFocus
                          onClick={(e) => startModuleQuiz(e, quizData.id)}
                        >
                          Start Quiz
                        </Button>
                      </DialogActions>
                    </BootstrapDialog>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </section>
    </Styles>
  );
}

export default Tests;
