import React, { useEffect, useState } from "react";
import DataList from "./DataList";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import {
  getPartnerWalletDataAction,
  walletWithdrawlRequestAction,
  walletRechargeRequestAction,
  walletPaytmRechargeRequestAction,
} from "../../action";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, MenuItem, TextField, Typography } from "@mui/material";
import { config } from "../../../../shared/constants";
import common from "../../../../shared/common";

const Wallet = (props) => {
  useEffect(() => {
    props.getPartnerWallet();
  }, []);

  //Dialog
  const [open, setOpen] = useState(false);
  const [remarks, setRemarks] = useState("");

  const handleOpen = () => {
    setRemarks("");
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleRemarksChange = (e) => {
    setRemarks(e.target.value);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = {
      remarks,
    };
    props
      .submitWalletRequest(formData)
      .then((response) => {
        setOpen(false);
      })
      .catch((error) => {});
  };

  const [rechargeWalletOpen, setRechargeWalletOpen] = useState(false);
  const rechargeSource = [
    {
      id: 1,
      title: "Paytm",
    },
    {
      id: 2,
      title: "NEFT",
    },
    {
      id: 3,
      title: "RTGS",
    },
    {
      id: 4,
      title: "IMPS",
    },
    {
      id: 5,
      title: "Cheque",
    },
  ];
  const [selectedSource, setSelectedSource] = useState(null);
  const [selectedSourceError, setSelectedSourceError] = useState("");
  const [amount, setAmount] = useState(null);
  const [amountError, setAmountError] = useState("");
  const [referenceNo, setReferenceNo] = useState(null);
  const [referenceNoError, setReferenceNoError] = useState("");
  const [transactionDate, setTransactionDate] = useState(new Date().toDateString());
  const [transactionDateError, setTransactionDateError] = useState("");
  const [attachedFile, setAttachedFile] = useState(null);
  const [attachedFileError, setAttachedFileError] = useState("");
  const [hideOptions, setHideOptions] = useState(true);

  const handleRechargeWalletOpen = () => {
    setRechargeWalletOpen(true);
  };

  const handleRechargeWalletClose = () => {
    setRechargeWalletOpen(false);
  };

  const resetRechargeWallet = () => {
    setSelectedSource(null);
    setSelectedSourceError("");
    setAmount(null);
    setAmountError("");
    setReferenceNo(null);
    setReferenceNoError("");
    setTransactionDate(new Date().toDateString());
    setTransactionDateError("");
    setAttachedFile(null);
    setAttachedFileError("");
    setHideOptions(true);
  };

  const handleSourceChange = (e) => {
    setSelectedSource(e.target.value);
    handleRechargeForm("selectedSource", e.target.value);
    if (e.target.value === 1) {
      setHideOptions(true);
    } else {
      setHideOptions(false);
    }
  };

  const handleAmountChange = (e) => {
    setAmount(e.target.value);
    handleRechargeForm("amount", e.target.value);
  };

  const handleReferenceNoChange = (e) => {
    setReferenceNo(e.target.value);
    handleRechargeForm("referenceNo", e.target.value);
  };

  const handleTransactionDate = (e) => {
    setTransactionDate(e.target.value);
    handleRechargeForm("transactionDate", e.target.value);
  };

  const handleFileChange = (e) => {
    setAttachedFile(e.target.files);
  };

  const handleRechargeSubmit = (e) => {
    e.preventDefault();

    if (selectedSource === 1) {
      const formData = {
        source: selectedSource,
        amount: amount,
      };

      props
        .submitPaytmRechargeRequest(formData)
        .then((response) => {
          // console.log(response.payload.orderCode);
          const order_code = response.payload.orderCode;
          const transactionToken = response.payload.transactionToken;
          // setOpen(false);
          loadPaytmScript(order_code, transactionToken, amount);
        })
        .catch((error) => {});
    } else {
      const formData = {
        source: selectedSource,
        amount: amount,
        transaction_date: transactionDate,
        reference_no: referenceNo,
      };

      props
        .submitRechargeRequest(formData, attachedFile)
        .then((response) => {
          setRechargeWalletOpen(false);
          resetRechargeWallet();
        })
        .catch((error) => {});
    }
  };

  const handleRechargeForm = (key = "", value = "") => {
    const selectedSourceValue = key === "selectedSource" ? value : selectedSource;
    const amountValue = key === "amount" ? value : amount;
    const referenceNoValue = key === "referenceNo" ? value : referenceNo;
    const transactionDateValue = key === "transactionDate" ? value : transactionDate;
    let error = false;
    if (selectedSourceValue === "") {
      error = true;
      setSelectedSourceError("Please select Source of Payment");
    } else {
      setSelectedSourceError("");
    }
    if (amountValue === "") {
      error = true;
      setAmountError("Please enter recharge amount");
    } else if (+amountValue <= 9999) {
      error = true;
      setAmountError("Recharge amount should be minimum of 10000");
    } else if (+amountValue > 1000000) {
      error = true;
      setAmountError("Recharge amount can be maximum of 1000000");
    } else {
      setAmountError("");
    }

    if (selectedSourceValue === 1) {
      if (referenceNoValue === "") {
        error = true;
        setReferenceNoError("Please enter transaction reference number / cheque number");
      } else {
        setReferenceNoError("");
      }
      if (transactionDateValue === "") {
        error = true;
        setTransactionDateError("Please select transaction date");
      } else {
        setTransactionDateError("");
      }
    } else {
      setReferenceNoError("");
      setTransactionDateError("");
    }

    return error;
  };

  const loadPaytmScript = (orderId, transactionToken, amount) => {
    const script = document.createElement("script");
    script.src = "https://" + config.PAYTM_HOST + "/merchantpgpui/checkoutjs/merchants/" + config.PAYTM_MERCHANT_ID + ".js";
    script.crossorigin = "anonymous";
    script.type = "application/javascript";
    document.head.appendChild(script);
    script.onload = () => {
      var config = {
        root: "",
        flow: "DEFAULT",
        data: {
          orderId: orderId,
          token: transactionToken,
          tokenType: "TXN_TOKEN",
          amount: amount,
        },
        merchant: {
          name: "ALYSS Edusolutions Pvt Ltd",
        },
        handler: {
          // transactionStatus: function transactionStatus(paymentStatus) {
          //     console.log("paymentStatus => ", paymentStatus);
          // },
          notifyMerchant: function (eventName, data) {
            console.log("notifyMerchant handler function called");
            console.log("eventName => ", eventName);
            console.log("data => ", data);
          },
        },
      };

      if (window.Paytm && window.Paytm.CheckoutJS) {
        window.Paytm.CheckoutJS.onLoad(function excecuteAfterCompleteLoad() {
          // initialze configuration using init method
          window.Paytm.CheckoutJS.init(config)
            .then(function onSuccess() {
              // after successfully update configuration invoke checkoutjs
              window.Paytm.CheckoutJS.invoke();
            })
            .catch(function onError(error) {
              console.log("error => ", error);
            });
        });
      }
    };
  };
  //Data Grid
  const columns = React.useMemo(
    () => [
      {
        field: "transaction_datetime",
        headerName: "Transaction Date & Time",
        flex: 1,
        renderCell: (params) => (
          <div>
            <Typography sx={{ whiteSpace: "break-spaces" }}>{`${common.getISTFormattedDateTime(params.row.transaction_datetime) || ""}`}</Typography>
          </div>
        ),
      },
      {
        field: "particulars",
        headerName: "Particulars",
        flex: 1,
        renderCell: (params) => (
          <div>
            <Typography sx={{ whiteSpace: "break-spaces", wordBreak: "break-word" }}>{`${params.row.particulars || ""}`}</Typography>
          </div>
        ),
      },
      {
        field: "debit",
        headerName: "Debit",
        flex: 1,
        renderCell: (params) => (
          <div>
            <Typography sx={{ whiteSpace: "break-spaces" }}>{`${params.row.debit || ""}`}</Typography>
          </div>
        ),
      },
      {
        field: "credit",
        headerName: "Credit",
        flex: 1,
        renderCell: (params) => (
          <div>
            <Typography sx={{ whiteSpace: "break-spaces" }}>{`${params.row.credit || ""}`}</Typography>
          </div>
        ),
      },
      {
        field: "balance",
        headerName: "Balance",
        flex: 1,
        renderCell: (params) => (
          <div>
            <Typography sx={{ whiteSpace: "break-spaces" }}>{`${params.row.balance || ""}`}</Typography>
          </div>
        ),
      },
    ],
    []
  );
  let walletTransactions = [];
  if (props.wallet_transactions.length > 0) {
    walletTransactions = props.wallet_transactions.map((transaction) => {
      return {
        id: transaction.id,
        transaction_datetime: transaction.transaction_at,
        debit: transaction.transaction_type === 2 ? `${transaction.currency} ${transaction.transaction_amount}` : "",
        credit: transaction.transaction_type === 1 ? `${transaction.currency} ${transaction.transaction_amount}` : "",
        balance: `${transaction.currency} ${transaction.wallet_balance}`,
        particulars: transaction.particulars,
      };
    });
  }
  return (
    <DataList
      headerTitle="Wallet Transactions"
      dataCols={columns}
      {...props}
      dataRows={walletTransactions}
      subheader_title={`Wallet Balance: ${props.wallet_balance}`}
      button_component={
        <>
          <Button variant="outlined" onClick={handleRechargeWalletOpen} className={"mt-3 mb-3 pull-right"}>
            Recharge Wallet
          </Button>
          {/* <Button variant="outlined" onClick={handleOpen} className={"mt-3 mb-3 mr-3 pull-right"}>
            Request Withdrawl
          </Button> */}
        </>
      }
      dialog_component={
        <>
          <Dialog open={open} onClose={handleClose} fullWidth maxWidth="lg">
            <DialogTitle>Request Wallet Balance Withdrawl</DialogTitle>
            <DialogContent>
              <Typography variant="h4" gutterBottom component="div" className="mt-3">
                {`Wallet Balance: ${props.wallet_balance}`}
              </Typography>
              <Box component="form" noValidate autoComplete="off">
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={6}>
                    <TextField
                      label="Remarks"
                      multiline
                      rows={4}
                      fullWidth
                      placeholder="Enter remarks (if any)"
                      variant="outlined"
                      onChange={handleRemarksChange}
                      value={remarks}
                    />
                  </Grid>
                </Grid>
              </Box>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} size="large">
                Cancel
              </Button>
              <Button onClick={handleSubmit} disabled={props.showSubLoader && props.showSubLoader.length > 0} size="large">
                Confirm
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog open={rechargeWalletOpen} onClose={handleRechargeWalletClose} fullWidth maxWidth="lg">
            <DialogTitle>Recharge Wallet</DialogTitle>
            <DialogContent>
              <Typography variant="h4" gutterBottom component="div" className="mt-3">
                {`Current Wallet Balance: ${props.wallet_balance}`}
              </Typography>
              <Box component="form" noValidate autoComplete="off">
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={6}>
                    <TextField
                      select
                      fullWidth
                      required
                      label="Select Source"
                      value={selectedSource}
                      onChange={handleSourceChange}
                      helperText={selectedSourceError}
                      error={selectedSourceError !== ""}
                    >
                      {rechargeSource &&
                        rechargeSource.map((item) => (
                          <MenuItem value={item.id} key={item.id}>
                            {item.title}
                          </MenuItem>
                        ))}
                    </TextField>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <TextField
                      label="Amount"
                      fullWidth
                      placeholder="Enter Amount"
                      required
                      inputProps={{
                        max: 50,
                        min: 0,
                      }}
                      type="number"
                      onChange={handleAmountChange}
                      value={amount}
                      error={amountError !== ""}
                      helperText={amountError}
                    />
                  </Grid>
                  {!hideOptions && (
                    <>
                      <Grid item xs={12} sm={6} md={6}>
                        <TextField
                          label="Reference Number"
                          fullWidth
                          required
                          placeholder="Enter Transaction Reference Number/ Cheque Number"
                          onChange={handleReferenceNoChange}
                          value={referenceNo}
                          error={referenceNoError !== ""}
                          helperText={referenceNoError}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={6}>
                        <TextField
                          id="transaction_date"
                          fullWidth
                          label="Transaction Date"
                          type="date"
                          placeholder=""
                          defaultValue={transactionDate}
                          value={transactionDate}
                          onChange={handleTransactionDate}
                          error={transactionDateError !== ""}
                          helperText={transactionDateError}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={6}>
                        <TextField
                          id="selected_file"
                          fullWidth
                          required
                          label="Attach Proof"
                          type="file"
                          placeholder=""
                          onChange={handleFileChange}
                          error={transactionDateError !== ""}
                          helperText={transactionDateError}
                          inputProps={{
                            autoComplete: "selected_file",
                            form: {
                              autoComplete: "off",
                            },
                            accept: "image/png, image/jpeg",
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Grid>
                    </>
                  )}
                </Grid>
              </Box>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleRechargeWalletClose} size="large">
                Cancel
              </Button>
              <Button onClick={handleRechargeSubmit} disabled={props.showSubLoader && props.showSubLoader.length > 0} size="large">
                Confirm
              </Button>
            </DialogActions>
          </Dialog>
        </>
      }
      index={4}
    />
  );
};
const mapStateToProps = (state) => ({
  wallet_balance: state.partnerDashboard.data.wallet_balance,
  wallet_transactions: state.partnerDashboard.data.wallet_transactions,
  error: state.partnerDashboard.error,
  showLoader: state.user.showLoader,
  showSubLoader: state.partnerDashboard.showSubLoader,
  showPaytmLoader: state.partnerDashboard.showPaytmLoader,
});

const mapDispatchToProps = (dispatch) => ({
  getPartnerWallet: () => dispatch(getPartnerWalletDataAction()),
  submitWalletRequest: (data) => dispatch(walletWithdrawlRequestAction(data)),
  submitPaytmRechargeRequest: (data) => dispatch(walletPaytmRechargeRequestAction(data)),
  submitRechargeRequest: (data, files) => dispatch(walletRechargeRequestAction(data, files)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Wallet));
