import React, { Component } from "react";
import { Container, Row, Col } from "react-bootstrap";
import User from "../pages/user/index.js";
import { Styles } from "./styles/buttonArea.js";
import Connect from "../pages/connect/index.js";

class 
ButtonArea extends Component {
  render() {
    const idPref = Math.random();
    const bgColor = this.props.bgColor ? this.props.bgColor : "#fff";
    return (
      <Styles>
        {/* Hero Image */}
        <section className="hero-button-area" style={{ backgroundColor: bgColor }}>
          <Container>
            <Row>
              <Col md="12">
                <div className="button-area">
                  <button id={"hero-btn" + idPref} className="join-button my-5" onClick={this.props.onHeroButtonClick}>
                    <span> {this.props.buttonText}</span>
                  </button>
                  {this.props.showPopup ? (
                    this.props.connect ? (
                      <Connect openCommand={true} commandCtrlId={"hero-btn" + idPref} />
                    ) : (
                      <User openCommand={true} commandCtrlId={"hero-btn" + idPref} />
                    )
                  ) : (
                    ""
                  )}
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </Styles>
    );
  }
}

export default ButtonArea;
