import { doPostRequest } from "../../shared/api/base-api";
import { apiUrl } from "../../shared/constants";

// Module Quiz Start Api
export function startModuleQuiz(data) {
   return doPostRequest(apiUrl.startModuleQuiz, data);
}

// Module Quiz Submit Api
export function submitModuleQuiz(data) {
   return doPostRequest(apiUrl.submitModuleQuiz, data);
}