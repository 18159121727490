import React, { Component, Fragment } from "react";
import Datas from "../../../data/course/item.json";
import { Link } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import { webUrl } from "../../../shared/constants";

const CourseItemGrid = (props) => {
  return (
    <Fragment>
      {/* Course Item */}
      <Row>
        <Col>
          <h5 className="grid-header ml-0">{props.headerText}</h5>
        </Col>
      </Row>

      <Row>
        {(props.data &&
          props.data.length > 0 &&
          props.data.map((data, i) => (
            <Col lg="4" md="6" key={i}>
              <div className="course-item">
                <Link to={process.env.PUBLIC_URL + webUrl.teacherTechnologyKitDetails + "/" + data.id}>
                  <div
                    className="course-image"
                    style={{
                      backgroundImage: `url(${
                        (data.subsubcategory_image_url && data.subsubcategory_image_url.replace(/\\/g, "/")) ||
                        process.env.PUBLIC_URL + "/assets/images/" + Datas[0].imgUrl
                      })`,
                    }}
                  >
                    {data.can_user_access === 0 && (
                      <div className="course-price">
                        <Link to={process.env.PUBLIC_URL + "package/" + data.id}>
                          <p>Buy Now</p>
                        </Link>
                      </div>
                    )}
                  </div>
                </Link>
                <div className="course-content">
                  <h6 className="heading">
                    <Link to={webUrl.teacherTechnologyKitDetails + "/" + data.id}>{data.name}</Link>
                  </h6>
                </div>
              </div>
            </Col>
          ))) || (
          <Col lg="4" md="6">
            <p className="not-found">{props.notFoundText}</p>
          </Col>
        )}
      </Row>
    </Fragment>
  );
};

export default CourseItemGrid;
