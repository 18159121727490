import React, { Fragment } from "react";
import common from "../../../shared/common";
import { Col, Row } from "react-bootstrap";
import MathJax from "mathjax3-react";

const CourseItemGrid = (props) => {
  return (
    <Fragment>
      {/* Course Item */}

      <div className="blog-post">
        <Row>
          {(props.data &&
            props.data.length > 0 &&
            props.data.map((data, i) => (
              <Col lg="12" md="12" key={i}>
                <div className="blog-content">
                  <div className="content-box">
                    <div className="top-content d-flex">
                      <div className="blog-date text-center">
                        <p>{common.getFormattedDate(new Date(data.class_timings))}</p>
                      </div>
                      <div className="blog-title">
                        <h6>{data.topic}</h6>
                      </div>
                    </div>
                    <div className="blog-desk">
                      <p
                        style={{
                          fontWeight: "bold",
                          fontSize: "19px",
                          margin: "0",
                          padding: "0",
                        }}
                      >
                        {data.subsubcategory.name}
                      </p>
                      {data.description && (
                        <p style={{ paddingTop: "10px", borderBottom: "1px solid #eeeeee" }}>
                          <MathJax.Provider>
                            <MathJax.Html html={data.description} />
                          </MathJax.Provider>
                        </p>
                      )}
                      <Row>
                        <Col md="8">
                          <ul className="list-unstyled list-inline">
                            {data.tutor && (
                              <li className="list-inline-item">
                                <i className="las la-user"></i> {data.tutor.name}
                              </li>
                            )}
                            <li className="list-inline-item">
                              <i className="las la-clock"></i> {common.getFormattedTime(new Date(data.class_timings))}
                            </li>
                          </ul>
                        </Col>
                        {data.participants.length === 0 ? (
                          <Col md="4">
                            <button
                              onClick={(e) => {
                                e.preventDefault();
                                props.liveClassCheckData([data.id]).then((response) => {
                                  if (response.data.live_class.is_subscribed === 0) {
                                    props.handleSnackbar(response.data.live_class.subscribedMessage);
                                  } else {
                                    props.confirmLiveClassData([data.id]).then((response) => {
                                      alert("Your participation has been confirmed");
                                      props.liveClassesData([props.formData.subcategory_id, props.formData.subsubcategory_id]);
                                    });
                                  }
                                });
                              }}
                            >
                              Confirm your Participation
                            </button>
                          </Col>
                        ) : (
                          <Col md="4">
                            <button
                              onClick={(e) => {
                                e.preventDefault();
                                props.liveClassCheckData([data.id]).then((response) => {
                                  if (response.data.live_class.is_subscribed === 0) {
                                    props.handleSnackbar(response.data.live_class.subscribedMessage);
                                  } else {
                                    window.open(response.data.live_class.live_class_url);
                                  }
                                });
                              }}
                            >
                              Join Now
                            </button>
                          </Col>
                        )}
                      </Row>
                    </div>
                  </div>
                </div>
              </Col>
            ))) || (
            <Col lg="4" md="6">
              <p className="not-found">{props.notFoundText}</p>
            </Col>
          )}
        </Row>
      </div>
    </Fragment>
  );
};

export default CourseItemGrid;
