import { actionTypes } from "../actions/footer";

/** Initial state */
const initialState = {
  data: {
    newsletter: null,
  },
  error: null,
  showLoader: [],
};

/** Reducers */
export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case actionTypes.SUBMIT_NEWSLETTER_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          newsletter: payload.data.newsletter,
        },
        error: null,
      };

    default:
      return state;
  }
};
