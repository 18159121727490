import { doGetRequest, doPostRequest } from "../../shared/api/base-api";
import { apiUrl } from "../../shared/constants";

//Cart list api
export function cartList() {
  return doGetRequest(apiUrl.cartList);
}

//remove from cart api
export function removeFromCart(data) {
  return doPostRequest(apiUrl.removeFromCart, data);
}

//get addresses api
export function getAddressesList() {
  return doGetRequest(apiUrl.addressesList);
}

//checkout api
export function checkout(data) {
  return doPostRequest(apiUrl.checkout, data);
}
export function razorpaycheckout(data) {
  return doPostRequest(apiUrl.razorpaycheckout, data);
}
//savePaymentDetails api
export function savePaymentDetails(data) {
  return doPostRequest(apiUrl.savePaymentDetails, data);
}

//paypalReturn api
export function paypalReturn(data) {
  return doPostRequest(apiUrl.paypalReturnURL, data);
}

//paypalCancel api
export function paypalCancel(data) {
  return doPostRequest(apiUrl.paypalCancelURL, data);
}

//apply Coupon api
export function applyCoupon(data) {
  return doPostRequest(apiUrl.applyCoupon, data);
}

//remove Coupon api
export function removeCoupon() {
  return doPostRequest(apiUrl.removeCoupon, {});
}
