import React, { useEffect, useState } from "react";
import DataList from "./DataList";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { getPromotionalMaterialsDataAction } from "../../action";
import { Button, Link } from "@mui/material";

const PromotionalMaterial = (props) => {
  useEffect(() => {
    props.getPromotionalMaterials();
  }, []);

  const renderPromoMaterialValue = React.useCallback((params) => {
    return params.row.type === 1 ? (
      <Link target="_blank" href={params.row.link}>
        {params.row.link}
      </Link>
    ) : (
      <Button
        variant="outlined"
        onClick={(e) => {
          e.preventDefault();
          return handleFileButtonClick(params.row.promo_file_url);
        }}
      >
        Download
      </Button>
    );
  }, []);

  const columns = React.useMemo(
    () => [
      {
        field: "title",
        headerName: "Title",
        width: 150,
        editable: false,
      },
      {
        field: "field_type",
        headerName: "Type",
        width: 150,
        editable: false,
      },
      {
        field: "action",
        headerName: "File/Link",
        width: 200,
        editable: false,
        renderCell: renderPromoMaterialValue,
      },
    ],
    [renderPromoMaterialValue]
  );

  const handleFileButtonClick = (url) => {
    window.open(url);
  };
  let promotionalMaterials = [];
  if (props.promotional_materials.length > 0) {
    promotionalMaterials = props.promotional_materials.map((promotional_material) => {
      return {
        id: promotional_material.id,
        title: promotional_material.title,
        type: promotional_material.type,
        field_type: promotional_material.type === 1 ? `Link` : `File`,
        link: promotional_material.link,
        promo_file_url: promotional_material.promo_file_url,
      };
    });
  }
  return <DataList headerTitle="Promotional Materials" dataCols={columns} {...props} dataRows={promotionalMaterials} index={7} />;
};
const mapStateToProps = (state) => ({
  promotional_materials: state.partnerDashboard.data.promotional_materials,
  error: state.partnerDashboard.error,
  showLoader: state.user.showLoader,
});

const mapDispatchToProps = (dispatch) => ({
  getPromotionalMaterials: () => dispatch(getPromotionalMaterialsDataAction()),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PromotionalMaterial));
