import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Container, Row, Col } from "react-bootstrap";
import FooterTwo from "../../components/FooterTwo";
import { withRouter } from "react-router-dom";
import TestItemGrid from "./components/TestItemGrid";
import { Styles } from "../dashboard/styles/dashboard.js";
import UserHeader from "../../components/UserHeader";
import DashboardMenu from "../dashboard/components/DashboardMenu";
import { generatedTestsListDataAction } from "./action";
import LoaderNew from "../../components/LoaderNew";

const NewTest = (props) => {

    useEffect(() => {
        props.generatedTestsListData();
    }, []);


    return (
      <div className="main-wrapper course-page">
        {/* User Header */}
        <UserHeader index={3} />

        <Styles>
          {/* Course Grid */}
          <section className="dashboard-grid-area">
            <Container>
              <Row>
                <Col lg="3" md="3" sm="12">
                  <DashboardMenu index={3} />
                </Col>
                <Col>
                  {props.showLoader && props.showLoader.length > 0 && <LoaderNew />}
                  {props.showLoader.length === 0 && (
                    <Row>
                      <Col lg="12" md="12" sm="12">
                        <div className="course-items">
                          <TestItemGrid {...props} data={props.generatedTests} notFoundText="No Test Generated Yet" headerText="Generated Tests" />
                        </div>
                      </Col>
                    </Row>
                  )}
                </Col>
              </Row>
            </Container>
          </section>
        </Styles>

        {/* Footer 2 */}
        <FooterTwo />
      </div>
    );
};

const mapStateToProps = (state) => ({
    generatedTests: state.generatedTest.data.generatedTests,
    showLoader: state.user.showLoader
});

const mapDispatchToProps = (dispatch) => ({
    generatedTestsListData: (data) => dispatch(generatedTestsListDataAction(data))
});

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(NewTest)
);
