import React, { useState } from "react";
import { Styles } from "./styles/freeTrial";
import { webUrl } from "../shared/constants";
import common from "../shared/common";
import User from "../pages/user";

const FreeTrial  = (props) => {
  const idPref = Math.random();

  const onClickJoinNow = () => {
    if (common.getFromStorage("userName")) {
      props.history.push(webUrl.userDashboard);
    }
  };
  return (
    <Styles>
        <section className="trial">
          <div className="bg_card sky_blue">
            <div className="d_flex h-100">
              <div className="col_three left_col">
                <div className="content_box">
                  <h5>Start Your</h5>
                  <h2>Free Trial</h2>
                  <p>and Experience it yourself</p>
                  <button
                    className="btn_white"
                    id={"hero-btn" + idPref}
                    onClick={(e) => {
                      e.preventDefault();
                      onClickJoinNow();
                    }}
                  >
                    Lets Start
                  </button>
                </div>
              </div>
              <div className="col_three img_col"></div>
              <div className="col_three right_col">
                <div className="r_content_box">
                  <h5>
                    A Subscription that’s <br /> worth your money{" "}
                  </h5>
                  <p>India’s Best Learning App and Portal for Science and Maths CBSE Curriculum</p>
                </div>
              </div>
            </div>
          </div>
        </section>
      <User openCommand={true} commandCtrlId={"hero-btn" + idPref} />
    </Styles>
  );
};

export default FreeTrial;
