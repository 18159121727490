import React from "react";
import { Card, CardContent, Typography } from "@mui/material";
import { Col } from "react-bootstrap";

import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Styles } from "./styles/styles";
import LoginForm from "./form/LoginForm";
import { olympiadLoginAction } from "./action";

function OlympiadLogin(props) {
  return (
    <Styles>
      <div className="makeStyles-root-2 makeStyles-root-3 makeStyles-root-1 container w-full flex">
        <div className="flex flex-auto min-w-0">
          <div className="flex flex-col flex-auto min-h-screen min-w-0 relative z-10">
            <div className="flex flex-col flex-auto min-h-0 relative z-10">
              <div className={"makeStyles-root-19 flex flex-auto items-center justify-center flex-shrink-0 p-16 p-24"}>
                <Col xs="12" className="flex w-full max-w-400 max-w-3xl rounded-20 shadow-2xl overflow-hidden">
                  <Card className={"flex w-full max-w-sm items-center justify-center shadow-0 col-12"} square>
                    <CardContent className="flex flex-col items-center justify-center w-full py-96 max-w-320" style={{ padding: "7.6rem 15px" }}>
                      <div>
                        <div className="flex items-center mb-48">
                          <img className="logo-icon w-120" src="/assets/images/logo2.webp" alt="logo" style={{ maxWidth: "120px" }} />
                          <div className="border-l-1 mr-4 w-1 h-40" />
                          <div>
                            <Typography className="text-24 font-semibold logo-text" color="inherit">
                              Login
                            </Typography>
                            <Typography className="text-16 tracking-widest -mt-8 font-700" color="textSecondary">
                              Console
                            </Typography>
                          </div>
                        </div>
                      </div>

                      <LoginForm {...props} />
                    </CardContent>
                  </Card>

                  <div className={"hidden flex flex-1 items-center justify-center p-64 makeStyles-rightSection-21 col-12"}>
                    <div className="max-w-320">
                      <div>
                        <Typography variant="h3" color="inherit" className="font-semibold leading-tight">
                          Welcome to <br />
                          Alyss National Science Olympiad!
                        </Typography>
                      </div>

                      <div>
                        <Typography variant="subtitle1" color="inherit" className="mt-32">
                          Starts login to check the details!
                        </Typography>
                      </div>
                    </div>
                  </div>
                </Col>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Styles>
  );
}

const mapStateToProps = (state) => ({
  login: state.olympiad.data.login,
});

const mapDispatchToProps = (dispatch) => ({
  olympiadLogin: (userData) => dispatch(olympiadLoginAction(userData)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(OlympiadLogin));
