import styled from "styled-components";
import { colors, fonts } from "../../../components/common/element/elements.js";

export const Styles = styled.div`
  /* Course Category */
  .course-category {
    border: 1px solid ${colors.border1};
    border-radius: 5px;
    box-shadow: rgb(0 0 0 / 8%) 0px 0px 20px;
    h5 {
      color: ${colors.black1};
      font-weight: 600;
      padding: 15px 15px 8px 0px;
      margin-left: 15px;
      position: relative;
      font-size: 18px;
      &:before {
        position: absolute;
        content: "";
        background: ${colors.green};
        width: 50px;
        height: 2px;
        bottom: 0;
        left: 0;
      }

      @media (max-width: 575px) {
        font-size: 17px;
      }
    }
    h6 {
      padding: 5px 0px;
      margin-left: 15px;
      margin-top: 3px;
      font-size: 12px;
    }
    ul.category-item {
      li.check-btn {
        border-top: 1px dashed ${colors.border3};
        padding: 10px 0;

        &:last-child {
          border-bottom: 1px dashed ${colors.border3};
        }

        label {
          font-size: 14px;
          color: ${colors.text3};
          display: block;
          margin-bottom: 4px;
          cursor: pointer;

          input[type="checkbox"] {
            border: 2px solid ${colors.border3};
            appearance: none;
            width: 18px;
            height: 18px;
            cursor: pointer;
            margin-right: 6px;
            position: relative;
            top: 4px;

            &:focus {
              outline: none;
            }

            &:checked {
              background-color: ${colors.green};
              background: ${colors.green}
                url("data:image/gif;base64,R0lGODlhCwAKAIABAP////3cnSH5BAEKAAEALAAAAAALAAoAAAIUjH+AC73WHIsw0UCjglraO20PNhYAOw==") 2px 2px no-repeat;
              border-color: ${colors.green};
            }
          }

          span {
            float: right;
            font-size: 12px;
            color: ${colors.text2};
            line-height: 27px;
          }
        }
      }
    }

    .side-menu {
      font-size: 13px;
      color: ${colors.text2};
      background: transparent;
      border: none;
      padding: 0;
      box-shadow: none;

      img {
        margin-right: 5px;
        max-width: 21px;
      }

      i {
        font-size: 12px;
        margin-left: 3px;
      }

      &::after {
        display: none;
      }

      &:hover {
        cursor: pointer;
        color: ${colors.green};
      }
    }
  }

  .single-details-sidbar {
    .course-details-feature {
      box-shadow: 0 8px 25px rgba(0, 0, 0, 0.1);
      border-radius: 5px;
      h5.title {
        color: ${colors.black1};
        font-weight: 600;
        padding-bottom: 10px;
        margin-bottom: 20px;
        position: relative;
        &:before {
          position: absolute;
          content: "";
          background: ${colors.green};
          width: 50px;
          height: 2px;
          bottom: 0;
          left: 0;
        }

        @media (max-width: 575px) {
          font-size: 17px;
        }
      }

      ul.feature-list {
        margin-bottom: 20px;
        li {
          border-top: 1px dashed ${colors.border3};
          padding: 12px 0;
          font-size: 14px;
          color: ${colors.black2};
          font-weight: 500;
          i {
            font-size: 20px;
            color: ${colors.green};
            vertical-align: top;
            margin-right: 2px;
          }
          span {
            float: right;
            font-size: 13px;
            color: ${colors.text3};
            font-weight: 400;
            line-height: 20px;
          }
          &:first-child {
            border-top: none;
            padding-top: 0;
          }
          &:last-child {
            padding-bottom: 0;
          }
        }
      }

      button.enroll-btn {
        font-size: 16px;
        color: #fff;
        ${colors.gredient_orange_bg}
        display: inline-block;
        width: 100%;
        height: 40px;
        font-weight: 500;
        border: none;
        padding: 9px;
        border-radius: 5px;
        &:hover {
          transition: all 0.5s ease;
          transform: scale(1.05);
        }

        @media (max-width: 575px) {
          font-size: 14px;
        }
      }

      a.enroll-btn {
        font-size: 15px;
        color: #fff;
        background: ${colors.gr_bg};
        display: inline-block;
        width: 200px;
        height: 48px;
        text-transform: uppercase;
        font-weight: 500;
        text-align: center;
        padding: 14px;
        border-radius: 5px;
        margin-top: 20px;

        @media (max-width: 575px) {
          font-size: 14px;
        }
      }

      .course-share {
        margin-top: 30px;
        text-align: center;

        ul.social {
          margin-top: 30px;
          li {
            a {
              text-align: center;
              position: relative;
              height: 18px;
              display: inline-block;

              &:before {
                content: "";
                position: absolute;
                border-width: 9px 17px;
                border-style: solid;
                border-top-color: transparent;
                border-right-color: transparent;
                border-left-color: transparent;
                top: -18px;
                left: 0;
                z-index: 1;
                transition: all 0.2s ease;
              }

              &:after {
                content: "";
                position: absolute;
                border-width: 9px 17px;
                border-style: solid;
                border-right-color: transparent;
                border-bottom-color: transparent;
                border-left-color: transparent;
                bottom: -18px;
                left: 0;
                z-index: 1;
                transition: all 0.2s ease;
              }

              i {
                font-size: 14px;
                color: #ffffff;
                width: 34px;
              }

              &:hover {
                background-color: ${colors.green} !important;

                &:before {
                  border-bottom-color: ${colors.green} !important;
                }

                &:after {
                  border-top-color: ${colors.green} !important;
                }
              }
            }

            &:nth-child(1) {
              a {
                background-color: #4267b2;

                &:before {
                  border-bottom-color: #4267b2;
                }

                &:after {
                  border-top-color: #4267b2;
                }
              }
            }

            &:nth-child(2) {
              a {
                background-color: #1da1f2;

                &:before {
                  border-bottom-color: #1da1f2;
                }

                &:after {
                  border-top-color: #1da1f2;
                }
              }
            }

            &:nth-child(3) {
              a {
                background-color: #2867b2;

                &:before {
                  border-bottom-color: #2867b2;
                }

                &:after {
                  border-top-color: #2867b2;
                }
              }
            }

            &:nth-child(4) {
              a {
                background-color: #dd1343;

                &:before {
                  border-bottom-color: #dd1343;
                }

                &:after {
                  border-top-color: #dd1343;
                }
              }
            }

            &:nth-child(5) {
              a {
                background-color: #ea4c89;

                &:before {
                  border-bottom-color: #ea4c89;
                }

                &:after {
                  border-top-color: #ea4c89;
                }
              }
            }
          }
        }
      }

      @media (max-width: 1199px) {
        padding: 12px 15px 15px;
      }
    }
  }
  .menu-padding {
    padding-top: 3px;
    padding-bottom: 3px;
  }
  .MuiListItem-root {
    .Mui-selected {
      background: linear-gradient(90deg, rgb(65, 182, 230) 0%, rgb(57, 218, 253) 100%);
      &:hover {
        color: ${colors.white};
        .MuiListItemIcon-root {
          color: ${colors.white};
        }
      }
    }
    &:hover {
      color: ${colors.green};
      .MuiListItemIcon-root {
        color: ${colors.green};
      }
      .MuiIconButton-root {
        color: ${colors.black};
      }
    }
  }
  .MuiListItemIcon-root {
    min-width: 28px;
  }
  .menu-text {
    span {
      font-family: ${fonts.manrope};
      font-size: 15px;
    }
  }
`;
