import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import FooterTwo from "../../components/FooterTwo";
import VideoSlider from "../../components/VideoSlider";
import ButtonEnRoll from "../../components/ButtonEnRoll";
import common from "../../shared/common";
import { webUrl } from "../../shared/constants";
import { Styles } from "../../components/styles/homeUS";
import { Button, Container, Grid, Link, Paper, TextField } from "@mui/material";
import { Dropdown } from "react-bootstrap";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import FreeTrial from "../../components/FreeTrial";
import User from "../../pages/user";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import DashboardIcon from "@mui/icons-material/Dashboard";
import PrecisionManufacturingIcon from "@mui/icons-material/PrecisionManufacturing";
import SupervisedUserCircleIcon from "@mui/icons-material/SupervisedUserCircle";
import { UsCoursePlansDataAction } from "./action";

const AboutRobookido = (props) => {

	const [anchorState, setAnchorState] = useState(false);
	const [isOpen, setIsOpen] = useState(false);
	const openVideoModal = (data) => {
	  setIsOpen(true);
	};
	const onClickJoinNow = () => {
	  if (common.getFromStorage("userName")) props.history.push(webUrl.userDashboard);
	};
	const onOpenDrawer = (event) => {
	  if (event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
		return;
	  }
	  setAnchorState(true);
	};
	const onCloseDrawer = (event) => {
	  if (event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
		return;
	  }
	  setAnchorState(false);
	};
	const list = () => (
	  <Box role="presentation" onClick={onCloseDrawer} onKeyDown={onCloseDrawer}>
		<List>
		  <ListItem>
			<ListItemButton
			  onClick={() => {
				props.history.push(process.env.PUBLIC_URL + webUrl.studyMaterial);
			  }}
			>
			  <ListItemIcon>
				<MenuBookIcon />
			  </ListItemIcon>
			  <ListItemText primary="Study Material" />
			</ListItemButton>
		  </ListItem>
		  <ListItem>
			<ListItemButton
			  onClick={() => {
				props.history.push(process.env.PUBLIC_URL + webUrl.robotic);
			  }}
			>
			  <ListItemIcon>
				<PrecisionManufacturingIcon />
			  </ListItemIcon>
			  <ListItemText primary="Robotics" />
			</ListItemButton>
		  </ListItem>
		  <ListItem>
			<ListItemButton
			  onClick={() => {
				props.history.push(process.env.PUBLIC_URL + webUrl.teacher);
			  }}
			>
			  <ListItemIcon>
				<SupervisedUserCircleIcon />
			  </ListItemIcon>
			  <ListItemText primary="Teacher Solutions" />
			</ListItemButton>
		  </ListItem>
		</List>
	  </Box>
	);
	useEffect(() => {
	  if (common.getFromStorage("userName")) props.sendAuditEvent({ event_id: "home", source: "web" });
	}, []);
  
	const profile = common.getFromStorage("profile");
	let dashboardUrl = webUrl.userDashboard;
	if (profile) {
	  const profileData = JSON.parse(profile);
	  if (profileData.member_type === 4) {
		dashboardUrl = webUrl.partnerDashboard;
	  }
	}

 // coursePlansData
 useEffect(() => {
  props.coursePlansData([]);

}, []);
  return (
    <>
		  <section className="aboutRobooookido">
			  <div className="container">
				  {/* <object>ABOUT ROBOKIDO</object> */}
				  <h1>Robotic Education Shaping Young Minds for a Tech-Driven World</h1>
				  <ul>
					  <li>
						  <i className="fa fa-star" aria-hidden="true"></i>
						  <h2>Hands-On Learning</h2>
						  <p>Transform your curiosity into creations with our Hands-on robotics program.</p>
					  </li>
					  <li>
						  <i className="fa fa-cube" aria-hidden="true"></i>
						  <h2>STEM Focus</h2>
						  <p>Our STEM-centric approach combines science, technology, engineering, and mathematics with the captivating world of robotics, guiding young minds towards a future where they design, build, and shape the digital age.</p>
					  </li>
					  <li>
						  <i className="fa fa-arrows-alt" aria-hidden="true"></i>
						  <h2>3D HD Animated Videos</h2>
						  <p>Experience robotics like never before through our captivating 3D animations. From the tiniest components to complex machines, our animations bring the fascinating world of robots to life with stunning visual storytelling.</p>
					  </li>
				  </ul>

				  <div className="textBox">
					  <div className="aTag"><a href="#">More About Us</a></div>
					  <div className="aTx">At ALYSS EduSolutions, we envision a future where every student is empowered with the skills and knowledge to excel in a technology-driven world. We are dedicated to redefining education through comprehensive Robotics curriculum, immersive 3D videos, and inspiring hands-on projects. Our vision is to nurture young minds, fostering creativity, critical thinking, and a passion for innovation. We aspire to be the catalyst that transforms students into the next generation of leaders, problem solvers, and visionaries in the field of Robotics and beyond.</div>
				  </div>


				  <div className="iBx"><img src={`${process.env.PUBLIC_URL}/assets/images/usImages/group.png`} />
				  </div>
			  </div>
		  </section>
    
    <section className="whyChoose">
    <div className="container">
      <div className="wcLeft">
        <img src={`${process.env.PUBLIC_URL}/assets/images/usImages/g1.png`} />
      </div>
      <div className="wcRight">
        <strong>WHY CHOOSE US</strong>
        <h2>Why Choose ALYSS for Your<br /> Child's Educational Journey?</h2>
        <p>We're the perfect choice to ignite your child's passion for robotics and guide them towards a future of innovation and success.</p>
        <ul>
          <li>Child – Centric approach</li>
          <li>Experiential Learning</li>
          <li>Cognitive Learning</li>
          <li>Next Generation Science Standards</li>
		  <li>Practical Training</li>
		  <li>Foundation of Programming</li>
        </ul>
        <b>Enroll Your Child in an Exciting Robotic Journey Today!</b>
		<ButtonEnRoll buttonText="Enroll Now!" onHeroButtonClick={onClickJoinNow} showPopup={!!!common.getFromStorage("userName")} />
      </div>
    </div>
  </section>
  <section className="popularCourses">
	<div className="container">
		<h2>Trending Courses</h2>
		<div className="textBox">
            <div className="aTx">Empower your child's future with our cutting-edge robotics courses, designed to spark their curiosity while gaining essential skills for tomorrow.</div>
            <div className="aTag"><a href="#"  onClick={() => {
            props.history.push(process.env.PUBLIC_URL + webUrl.courseListUs);
          }}> Explore All Courses</a></div>        
        </div>

         
		
		<div className="coursesBlk">
            {props && props.courses && props.courses.length > 0 && props.courses.slice(0, 3).map((course,index) => {
              return (
                <div className="cBox" key={index}>
                  <img src={course.image_url} />
                  <span>${course.amount}</span>
                  <h2>{course.title}</h2>
                  <ul>
                    <li>{course.age_group}</li>
                    <li>{course.module}</li>
                    <li>{course.video_time}</li>
                  </ul>
                  <p><i className="fa fa-star" aria-hidden="true"></i> {course.reviews}</p>
                </div>
              )
            })}		
		</div>
	</div>
</section>

<section className="ourPrograms">
    <div className="container">
    <h2>Our Programs</h2>
    <h3>Explore Our Captivating Robotic<br/>Programs for Kids</h3>
    <h4>Pulvinar nunc eget tincidunt id urna diam scelerisque cursus. Quis nunc pharetra arcu lectus nisl.<br/>Tortor vulputate sed maecenas rhoncusvivera purus.</h4>
    
    <div className="opSection">    
        <div className="cBox">
            <i className="fa fa-download" aria-hidden="true"></i>
            <object>After School Learning</object>
            <p>Extend the school day into the world of robotics, where hands-on learning transforms after-school hours into creative adventures.</p>
            <a href="#">Learn More</a>
        </div>
        
        <div className="cBox">
            <i className="fa fa-video-camera" aria-hidden="true"></i>
            <object>Online Learning</object>
            <p>Join our virtual classroom and explore the limitless possibilities of robotics education from the comfort of your home.</p>
            <a href="#">Learn More</a>
        </div>
        
        <div className="cBox">
            <i className="fa fa-home" aria-hidden="true"></i>
            <object>Summer Camps</object>
            <p>Summer days are for discovery! Dive into the world of robotics at our camps, where young minds innovate and have fun with other kids.</p>
            <a href="#">Learn More</a>
        </div>
        
        <div className="cBox">
            <i className="fa fa-sun-o" aria-hidden="true"></i>
            <object>Competitions</object>
            <p>Turn learning into thrilling challenges as students compete, showcasing their robotic skills and celebrating achievements."</p>
            <a href="#">Learn More</a>
        </div>
        
        <div className="cBox">
            <i className="fa fa-clone" aria-hidden="true"></i>
            <object>Competitions</object>
            <p>Empower students to be the architects of their learning journey, with self-assessment tools that foster independence and growth.</p>
            <a href="#">Learn More</a>
        </div>
        
        <div className="cBox">
            <i className="fa fa-television" aria-hidden="true"></i>
            <object>Online Course</object>
            <p>Tellus tristique habitant non orci ante ipsum quis. Donec etiam hac in ultrices sed dictumst</p>
            <a href="#">Learn More</a>
        </div>    
    
        </div>
        <h4>Pulvinar nunc eget tincidunt id urna diam scelerisque cursus. Quis nunc pharetra arcu lectus nisl.<br/>Tortor vulputate sed maecenas rhoncusvivera purus.</h4>
    </div>
</section>



<section className="learningMethods">
	<div className="container">
		<div className="lmLeft">
			<h2>Learning Methods</h2>
			<h3>Access to Learn Anytime, Anywhere</h3>
			<p>Tellus tristique habitant non orci ante ipsum quis.<br />Donec etiam hac in ultrices sed dictumst</p>
			<ul>
				<li>World className</li>
				<li>Easy Learning</li>
				<li>Flexible</li>
				<li>Affordable</li>
			</ul>
			<ButtonEnRoll buttonText="Enroll Now!" onHeroButtonClick={onClickJoinNow} showPopup={!!!common.getFromStorage("userName")} />
			{/* <button>Enroll Now!</button> */}
		</div>	
		<div className="lmRgt"><img src={`${process.env.PUBLIC_URL}/assets/images/usImages/12.png`} /></div>	
		</div>
</section>


<section className="testimonialsBox">
	<h3>Testimonials</h3>
	<h4>What Parents and Kids Say About<br />Our Robotic Courses</h4>
	<p>Pulvinar nunc eget tincidunt id urna diam scelerisque cursus.<br/>Quis nunc pharetra arcu lectus nisl. Tortor vulputate sed maecenas rhoncusvivera purus.</p>

		<div className="container">
			<div className="cBox">
				<span><i className="fa fa-star" aria-hidden="true"></i> 4.8</span>
				<i className="fa fa-quote-left" aria-hidden="true"></i>
				<h2>Hands-On Learning</h2>
				<p>Sed ut elit mattis turpis sit tincidunt amet diam ultrices. venenatis tincidunt augue pellentesque ultrices ut id. in</p>
				<div className="lmgT"><img src={`${process.env.PUBLIC_URL}/assets/images/usImages/tm.png`} /></div><p>Emily  Johnson <b>Parent</b></p>
			</div>
		
		<div className="cBox">
			<span><i className="fa fa-star" aria-hidden="true"></i> 4.8</span>
			<i className="fa fa-quote-left" aria-hidden="true"></i>

			<h2>Creative Exploration</h2>
			<p>Sed ut elit mattis turpis sit tincidunt amet diam ultrices. venenatis tincidunt augue pellentesque ultrices ut id. in</p>
			<div className="lmgT"><img src={`${process.env.PUBLIC_URL}/assets/images/usImages/tm.png`} /></div><p>Emily  Johnson <b>Parent</b></p>
		</div>
		
		<div className="cBox">
			<span><i className="fa fa-star" aria-hidden="true"></i> 4.8</span>
			<i className="fa fa-quote-left" aria-hidden="true"></i>

			<h2>Expert Instructors</h2>
			<p>Sed ut elit mattis turpis sit tincidunt amet diam ultrices. venenatis tincidunt augue pellentesque ultrices ut id. in</p>
			<div className="lmgT"><img src={`${process.env.PUBLIC_URL}/assets/images/usImages/tm.png`} /></div><p>Emily  Johnson <b>Parent</b></p>
		</div>
		
		<div className="cBox">
			<span><i className="fa fa-star" aria-hidden="true"></i> 4.8</span>
			<i className="fa fa-quote-left" aria-hidden="true"></i>

			<h2>Confidence Boost</h2>
			<p>Sed ut elit mattis turpis sit tincidunt amet diam ultrices. venenatis tincidunt augue pellentesque ultrices ut id. in</p>
			<div className="lmgT"><img src={`${process.env.PUBLIC_URL}/assets/images/usImages/tm.png`} /></div><p>Emily  Johnson <b>Parent</b></p>
		</div>
		
		<div className="cBox">
			<span><i className="fa fa-star" aria-hidden="true"></i> 4.8</span>
			<i className="fa fa-quote-left" aria-hidden="true"></i>

			<h2>Liflong Learning</h2>
			<p>Sed ut elit mattis turpis sit tincidunt amet diam ultrices. venenatis tincidunt augue pellentesque ultrices ut id. in</p>
			<div className="lmgT"><img src={`${process.env.PUBLIC_URL}/assets/images/usImages/tm.png`} /></div><p>Emily  Johnson <b>Parent</b></p>
		</div>
		
		<div className="cBox">
			<span><i className="fa fa-star" aria-hidden="true"></i> 4.8</span>
			<i className="fa fa-quote-left" aria-hidden="true"></i>

			<h2>Collaboration</h2>
			<p>Sed ut elit mattis turpis sit tincidunt amet diam ultrices. venenatis tincidunt augue pellentesque ultrices ut id. in</p>
			<div className="lmgT"><img src={`${process.env.PUBLIC_URL}/assets/images/usImages/tm.png`} /></div><p>Emily  Johnson <b>Parent</b></p>
		</div>
	</div>
</section>




<section className="contactUs">
	<div className="container">
	<div className="lmLeft"><img src={`${process.env.PUBLIC_URL}/assets/images/usImages/cnt.png`} /></div>
	<div className="csRgt">
		<h2>CONTACT US</h2>
		<h3>Get in Touch with Us<br />for Registration</h3>
		<p>Tellus tristique habitant non orci ante ipsum quis. Donec<br/>etiam hac in ultrices sed dictumst</p>
		<button>Contact Us</button> <span><b>126+</b> Customer Services Ready to Help You!</span>		
	</div>
</div>	
</section>
  </>
  );
};


const mapStateToProps = (state) => ({
  courses: state.usCoursesList.data.courses || [],
  showLoader: state.user.showLoader,
});

const mapDispatchToProps = (dispatch) => ({
  coursePlansData: (data) => dispatch(UsCoursePlansDataAction(data)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AboutRobookido));
