import { actionTypes } from "./action";

const newAddress = {
    id: 0,
    name: "New Address",
    email: "",
    address: "",
    city: "",
    state: "",
    postal_code: "",
}

/** Initial state */
const initialState = {
    data: {
        cart: {},
        addresses: [newAddress],
        checkout: {
            orderCode: '',
            gatewayType: {}
        }

    },
    error: null,
    showLoader: [],
};

/** Reducers */
export default (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case actionTypes.CART_LIST_REQUEST_SUCCESS:
        case actionTypes.REMOVE_FROM_REQUEST_SUCCESS:
        case actionTypes.APPLY_COUPON_REQUEST_SUCCESS:
        case actionTypes.REMOVE_COUPON_REQUEST_SUCCESS:
            return {
                ...state,
                data: {
                    ...state.data,
                    cart: payload.data.cart,
                },
                error: null,
            };
        case actionTypes.ADDRESSES_LIST_REQUEST_SUCCESS:
            return {
                ...state,
                data: {
                    ...state.data,
                    addresses: [...payload.data.addresses, newAddress],
                },
                error: null,
            };
        case actionTypes.CHECKOUT_REQUEST_SUCCESS:
            return {
                ...state,
                data: {
                    ...state.data,
                    checkout: { orderCode: payload.orderCode, gatewayType: { ...payload.gatewayType } },
                },
                error: null,
            };
        case actionTypes.SAVE_PAYMENT_DETAILS_REQUEST_SUCCESS:
            return {
                ...state,
                data: {
                    ...state.data,
                    cart: {},
                    checkout: { orderCode: '', gatewayType: {} },
                },
                error: null,
            };
        default:
            return state;
    }
};
