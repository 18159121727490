import React, { Component, useState } from "react";
import Datas from "../data/course/slider.json";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import Swiper from "react-id-swiper";
import { Styles } from "./styles/videoSlider.js";
import ModalVideo from "react-modal-video";

const VideoSlider = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedVideoId, setSelectedVideoId] = useState("394367036");
  const settings = {
    slidesPerView: 3,
    loop: false,
    speed: 1000,
    spaceBetween: 30,
    watchSlidesVisibility: false,
    pagination: {
      el: ".slider-dot.text-center",
      clickable: true,
    },
    breakpoints: {
      0: {
        slidesPerView: 1,
      },
      576: {
        slidesPerView: 1,
      },
      768: {
        slidesPerView: 2,
      },
      992: {
        slidesPerView: 3,
      },
    },
  };
  const openModal = (data) => {
    setSelectedVideoId(data.videoId);
    setIsOpen(true);
  };
  return (
    <Styles>
      {/* Course Slider */}
      <section className="course-slider-area mt-5 mb-1">
        <Container>
          <Row>
            <Col md="12">
              <div className="sec-title text-center">
                <h4>{Datas.secTitle}</h4>
              </div>
            </Col>
            <Col md="12" className="course-slider">
              <Swiper {...settings}>
                {Datas.dataList.map((data, i) => (
                  <div className="course-item" key={i}>
                    <div className="course-image" style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/sample/${data.imgUrl})` }}>
                      <div className="video-player" style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/sample/${data.imgUrl})` }}>
                        <button
                          onClick={(e) => {
                            e.preventDefault();
                            openModal(data);
                          }}
                          className="play-button"
                        >
                          <i className="las la-play"></i>
                        </button>
                      </div>
                    </div>
                    <div className="course-content">
                      <h6 className="heading">
                        <a
                          href="#"
                          onClick={(e) => {
                            e.preventDefault();
                            openModal(data);
                          }}
                        >
                          {data.courseTitle}
                        </a>
                      </h6>
                    </div>
                  </div>
                ))}
              </Swiper>
              <ModalVideo channel="vimeo" isOpen={isOpen} videoId={selectedVideoId} onClose={() => setIsOpen(false)} />
            </Col>
          </Row>
        </Container>
      </section>
    </Styles>
  );
};

export default VideoSlider;
