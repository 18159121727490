import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Box, Button, Grid, Typography } from "@mui/material";
import { getMemberProfileDataAction } from "../../action";
import common from "../../../../shared/common";

const MemberProfile = (props) => {
  const [open, setOpen] = useState(false);
  let memberId = props.memberId;
  useEffect(() => {
    if (memberId) {
      props.getMemberProfile({ member_id: memberId }).then(() => {
        setOpen(true);
      });
    }
  }, [memberId]);

  const handleClose = () => {
    setOpen(false);
    props.setMemberId(null);
  };

  return (
    <div>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="lg">
        <DialogTitle sx={{ fontSize: "30px" }}>
          {props.member &&
            ((props.member.member_type === 1 && "Student") ||
              (props.member.member_type === 2 && "Teacher") ||
              (props.member.member_type === 3 && "School"))}{" "}
          Profile
        </DialogTitle>
        <DialogContent>
          <Box
            component="form"
            sx={{
              "& .MuiTextField-root": { m: 1 },
              minHeight: "320px",
            }}
            noValidate
            autoComplete="off"
          >
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Typography variant="h6">
                  <strong>Name: </strong>
                  {props.member && props.member.name}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="h6">
                  <strong>Email: </strong>
                  {props.member && props.member.email}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="h6">
                  <strong>Mobile: </strong>
                  {props.member && `${props.member.country_code}-${props.member.mobile}`}
                </Typography>
              </Grid>
              {props.member && (props.member.member_type === 1 || props.member.member_type === 2) && (
                <Grid item xs={12} sm={6}>
                  <Typography variant="h6">
                    <strong>School: </strong>
                    {props.member && props.member.member_school && props.member.member_school.name}
                  </Typography>
                </Grid>
              )}
              <Grid item xs={12} sm={6}>
                <Typography variant="h6">
                  <strong>City: </strong>
                  {props.member && props.member.city && props.member.city.name}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="h6">
                  <strong>Category: </strong>
                  {props.member && props.member.subcategory && `${props.member.subcategory.category.name} / ${props.member.subcategory.name}`}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="h6">
                  <strong>Registered On: </strong>
                  {props.member && common.getISTFormattedDateTime(props.member.created_at)}
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="outlined" size="large">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const mapStateToProps = (state) => ({
  member: state.partnerDashboard.data.member_profile,
  error: state.partnerDashboard.error,
  showLoader: state.user.showLoader,
  showSubLoader: state.partnerDashboard.showSubLoader,
});

const mapDispatchToProps = (dispatch) => ({
  getMemberProfile: (data) => dispatch(getMemberProfileDataAction(data)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MemberProfile));
