import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col, Dropdown } from "react-bootstrap";
import { Styles } from "./styles/mobileMenu.js";
import { webUrl } from "../../shared/constants.js";
import PhoneOutlinedIcon from "@mui/icons-material/PhoneOutlined";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import PrecisionManufacturingIcon from "@mui/icons-material/PrecisionManufacturing";
import LiveTvIcon from "@mui/icons-material/LiveTv";
import QuizIcon from "@mui/icons-material/Quiz";
import SupervisedUserCircleIcon from "@mui/icons-material/SupervisedUserCircle";
import AccountCircleRoundedIcon from "@mui/icons-material/AccountCircleRounded";
import User from "../../pages/user";
import common from "../../shared/common.js";

function MobileMenu() {
  useEffect(() => {
    // Mobile Menu
    const hmBtn = document.getElementById("mb-sidebar-btn");

    if (hmBtn) {
      const mdSidebarOverlay = document.getElementById("mb-sidebar-overlay");
      const mdSidebarBody = document.getElementById("mb-sidebar-body");
      const mdSidebarExit = document.getElementById("close-mb-sidebar");

      hmBtn.addEventListener("click", function (e) {
        e.preventDefault();
        mdSidebarOverlay.classList.toggle("visible");
        mdSidebarBody.classList.toggle("opened");
      });

      mdSidebarOverlay.addEventListener("click", function (e) {
        e.preventDefault();
        mdSidebarOverlay.classList.remove("visible");
        mdSidebarBody.classList.remove("opened");
      });

      mdSidebarExit.addEventListener("click", function (e) {
        e.preventDefault();
        mdSidebarOverlay.classList.remove("visible");
        mdSidebarBody.classList.remove("opened");
      });
    }

    // Menu Accordion -----------------
    const menuButton = document.querySelectorAll(".mb-menu-button");
    menuButton.forEach((button) => {
      button.addEventListener("click", () => {
        button.classList.toggle("active");
        const content = button.nextElementSibling;

        if (button.classList.contains("active")) {
          content.className = "mb-menu-content show";
          content.style.maxHeight = content.scrollHeight + "px";
        } else {
          content.className = "mb-menu-content";
          content.style.maxHeight = "0";
        }
      });
    });
  });

  return (
    <Styles>
      {/* Mobile Menu */}
      <section className="mobile-menu-area">
        <Container>
          <Row>
            <Col md="0" sm="12">
              <div className="mb-logo-area d-flex justify-content-between">
                <div className="mb-logo-box d-flex">
                  <div className="hm-button">
                    <a href={process.env.PUBLIC_URL + "/"} id="mb-sidebar-btn">
                      <i className="las la-bars"></i>
                    </a>
                  </div>
                  <div className="mb-logo">
                    <Link to={process.env.PUBLIC_URL + "/"}>
                      <img src={process.env.PUBLIC_URL + "/assets/images/logo2.webp"} alt="" />
                    </Link>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      {/* Mobile Menu Sidebar */}
      <section className="mb-sidebar" id="mb-sidebar-body">
        <div className="mb-sidebar-heading text-center">
          <div>
            <img src={process.env.PUBLIC_URL + "/assets/images/new_logo.webp"} alt="" width={100} />
          </div>
          <div>
            <a id="close-mb-sidebar" />
          </div>
        </div>
        <div className="mb-sidebar-menu">
          <div className="mb-menu-item">
            <div className="mb-menu-content show">
              <ul className="list-unstyled">
                <li>
                  <Link to={process.env.PUBLIC_URL + webUrl.studyMaterial}>
                    <MenuBookIcon />
                    Study Material
                  </Link>
                </li>
                <li>
                  <Link to={process.env.PUBLIC_URL + webUrl.robotic}>
                    <PrecisionManufacturingIcon />
                    Robotics
                  </Link>
                </li>
                <li>
                  <Link to={process.env.PUBLIC_URL + webUrl.teacher}>
                    <SupervisedUserCircleIcon />
                    Teacher Solutions
                  </Link>
                </li>
                <li>
                  <Link to={process.env.PUBLIC_URL + webUrl.examPreparatory}>
                    <QuizIcon />
                    Exam Preparatory
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <div className="mb-sidebar-overlay" id="mb-sidebar-overlay"></div>
    </Styles>
  );
}

export default MobileMenu;
