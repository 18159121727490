import React, { useEffect } from "react";
import { Styles } from "../styles/chapterMenu.js";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Box, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, ListSubheader } from "@mui/material";
import { ChevronRight } from "@mui/icons-material";
import VideoLibraryIcon from "@mui/icons-material/VideoLibrary";
import TextSnippetIcon from "@mui/icons-material/TextSnippet";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import ScienceIcon from "@mui/icons-material/Science";
import AbcIcon from "@mui/icons-material/Abc";
import QuizIcon from "@mui/icons-material/Quiz";
import { Col, Row } from "react-bootstrap";
import { webUrl } from "../../../shared/constants.js";
import { colors } from "../../../components/common/element/elements.js";
import common from "../../../shared/common.js";
import { sendAuditEventAction } from "../../../components/actions/common.js";

const ChapterMenu = (props) => {
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  let firstAvailableIndex = null;
  const userFeatures = JSON.parse(common.getFromStorage("features"));
  const chapterVideosFeature = userFeatures.filter((feature) => feature.slug === "chapter_videos");
  const chapterTheoryFeature = userFeatures.filter((feature) => feature.slug === "chapter_theory");
  const chapterNCERTBooksFeature = userFeatures.filter((feature) => feature.slug === "chapter_ncert_books");
  const chapterPracticeTestFeature = userFeatures.filter((feature) => feature.slug === "chapter_practice_test");
  const chapterVirtualLabFeature = userFeatures.filter((feature) => feature.slug === "chapter_virtual_lab");
  const chapterNCERTExercisesFeature = userFeatures.filter((feature) => feature.slug === "chapter_ncert_exercises");
  const chapterMCQsQuizFeature = userFeatures.filter((feature) => feature.slug === "chapter_mcqs_quiz");

  let subscriptionChapterVideosFeature = [];
  let subscriptionChapterTheoryFeature = [];
  let subscriptionChapterNcertBooksFeature = [];
  let subscriptionChapterPracticeTestFeature = [];
  let subscriptionChapterVirtualLabFeature = [];
  let subscriptionChapterNcertExercisesFeature = [];
  let subscriptionChapterMCQsQuizFeature = [];

  if (props.chapter && props.chapter.subscription_features && props.chapter.subscription_features.length > 0) {
    subscriptionChapterVideosFeature = props.chapter.subscription_features.filter((feature) => feature.feature.slug === "chapter_videos");
    subscriptionChapterTheoryFeature = props.chapter.subscription_features.filter((feature) => feature.feature.slug === "chapter_theory");
    subscriptionChapterNcertBooksFeature = props.chapter.subscription_features.filter((feature) => feature.feature.slug === "chapter_ncert_books");
    subscriptionChapterPracticeTestFeature = props.chapter.subscription_features.filter(
      (feature) => feature.feature.slug === "chapter_practice_test"
    );
    subscriptionChapterVirtualLabFeature = props.chapter.subscription_features.filter((feature) => feature.feature.slug === "chapter_virtual_lab");
    subscriptionChapterNcertExercisesFeature = props.chapter.subscription_features.filter(
      (feature) => feature.feature.slug === "chapter_ncert_exercises"
    );
    subscriptionChapterMCQsQuizFeature = props.chapter.subscription_features.filter((feature) => feature.feature.slug === "chapter_mcqs_quiz");
  }

  const handleListItemClick = (event, index, featureData) => {
    event.preventDefault();
    setSelectedIndex(index);
    props.onChange(index);
    props.sendAuditEvent({
      event_id: "feature",
      source: "web",
      feature_id: featureData[0].id,
      remarks_data:
        props.chapter.subsubcategory.subcategory.category.name +
        " / " +
        props.chapter.subsubcategory.subcategory.name +
        " / " +
        props.chapter.subsubcategory.name +
        " / " +
        props.chapter.name,
    });
  };

  const handleFeatureValidation = (index) => {
    let feature_id = null;
    switch (index) {
      case 0:
        feature_id = chapterVideosFeature[0].id;
        break;
      case 1:
        feature_id = chapterTheoryFeature[0].id;
        break;
      case 2:
        feature_id = chapterNCERTBooksFeature[0].id;
        break;
      case 3:
        feature_id = chapterPracticeTestFeature[0].id;
        break;
      case 4:
        feature_id = chapterVirtualLabFeature[0].id;
        break;
      case 5:
        feature_id = chapterNCERTExercisesFeature[0].id;
        break;
      case 6:
        feature_id = chapterMCQsQuizFeature[0].id;
        break;
      default:
        break;
    }
    if (feature_id != null) {
      props.sendAuditEvent({
        event_id: "feature",
        source: "web",
        feature_id: feature_id,
        remarks_data:
          props.chapter.subsubcategory.subcategory.category.name +
          " / " +
          props.chapter.subsubcategory.subcategory.name +
          " / " +
          props.chapter.subsubcategory.name +
          " / " +
          props.chapter.name,
      });
    }
  };

  if (
    chapterVideosFeature.length > 0 &&
    (subscriptionChapterVideosFeature.length > 0 || props.chapter.can_user_access === 0) &&
    props.chapter &&
    props.chapter.videos &&
    props.chapter.videos.length > 0 &&
    firstAvailableIndex === null
  ) {
    firstAvailableIndex = 0;
  }
  if (
    chapterTheoryFeature.length > 0 &&
    (subscriptionChapterTheoryFeature.length > 0 || props.chapter.can_user_access === 0) &&
    props.chapter &&
    props.chapter.description &&
    props.chapter.description.length > 0 &&
    firstAvailableIndex === null
  ) {
    firstAvailableIndex = 1;
  }
  if (
    chapterNCERTBooksFeature.length > 0 &&
    (subscriptionChapterNcertBooksFeature.length > 0 || props.chapter.can_user_access === 0) &&
    props.chapter &&
    props.chapter.ncert_books &&
    props.chapter.ncert_books.length > 0 &&
    firstAvailableIndex === null
  ) {
    firstAvailableIndex = 2;
  }
  if (
    chapterPracticeTestFeature.length > 0 &&
    (subscriptionChapterPracticeTestFeature.length > 0 || props.chapter.can_user_access === 0) &&
    props.chapter &&
    props.chapter.descriptive_practice_tests &&
    props.chapter.descriptive_practice_tests.length > 0 &&
    firstAvailableIndex === null
  ) {
    firstAvailableIndex = 3;
  }
  if (
    chapterVirtualLabFeature.length > 0 &&
    (subscriptionChapterVirtualLabFeature.length > 0 || props.chapter.can_user_access === 0) &&
    props.chapter &&
    props.chapter.interactivities &&
    props.chapter.interactivities.length > 0 &&
    firstAvailableIndex === null
  ) {
    firstAvailableIndex = 4;
  }
  if (
    chapterNCERTExercisesFeature.length > 0 &&
    (subscriptionChapterNcertExercisesFeature.length > 0 || props.chapter.can_user_access === 0) &&
    props.chapter &&
    props.chapter.ncert_exercises &&
    props.chapter.ncert_exercises.length > 0 &&
    firstAvailableIndex === null
  ) {
    firstAvailableIndex = 5;
  }
  if (
    chapterMCQsQuizFeature.length > 0 &&
    (subscriptionChapterMCQsQuizFeature.length > 0 || props.chapter.can_user_access === 0) &&
    props.chapter &&
    props.chapter.quizzes &&
    props.chapter.quizzes.length > 0 &&
    firstAvailableIndex === null
  ) {
    firstAvailableIndex = 6;
  }

  if (firstAvailableIndex !== selectedIndex && selectedIndex === 0) {
    props.onChange(firstAvailableIndex);
    handleFeatureValidation(firstAvailableIndex);
  }
  useEffect(() => {
    setSelectedIndex(firstAvailableIndex);
    props.onChange(firstAvailableIndex);
    handleFeatureValidation(firstAvailableIndex);
  }, [firstAvailableIndex]);

  return (
    <Styles>
      {/* Course Tag */}
      <div className="course-category">
        <h5>{props.chapter && props.chapter.name}</h5>
        <h6>
          {props.chapter &&
            props.chapter.subsubcategory &&
            props.chapter.subsubcategory.subcategory &&
            props.chapter.subsubcategory.subcategory.category &&
            `${props.chapter.subsubcategory.subcategory.category.name} / ${props.chapter.subsubcategory.subcategory.name} / ${props.chapter.subsubcategory.name}`}
        </h6>
        <Box sx={{ width: "100%", bgcolor: "background.paper" }}>
          {((chapterVideosFeature.length > 0 && (subscriptionChapterVideosFeature.length > 0 || props.chapter.can_user_access === 0)) ||
            (chapterTheoryFeature.length > 0 && (subscriptionChapterTheoryFeature.length > 0 || props.chapter.can_user_access === 0)) ||
            (chapterNCERTBooksFeature.length > 0 && (subscriptionChapterNcertBooksFeature.length > 0 || props.chapter.can_user_access === 0))) &&
            props.chapter && (
              <List
                component="nav"
                aria-label="main mailbox folders"
                sx={{ width: "100%", paddingBottom: 0 }}
                aria-labelledby="learn-subheader"
                subheader={
                  <ListSubheader
                    component="div"
                    id="learn-subheader"
                    sx={{
                      fontSize: "15px",
                      color: `${colors.white}`,
                      background: `${colors.gr_orange}`,
                      marginTop: "15px",
                      lineHeight: "45px",
                      fontFamily: "Manrope",
                    }}
                  >
                    Learn
                  </ListSubheader>
                }
              >
                {chapterVideosFeature.length > 0 &&
                  (subscriptionChapterVideosFeature.length > 0 || props.chapter.can_user_access === 0) &&
                  props.chapter &&
                  props.chapter.videos &&
                  props.chapter.videos.length > 0 && (
                    <ListItem
                      secondaryAction={
                        <IconButton edge="end" aria-label="comments">
                          <ChevronRight />
                        </IconButton>
                      }
                      disablePadding
                    >
                      <ListItemButton selected={selectedIndex === 0} onClick={(event) => handleListItemClick(event, 0, chapterVideosFeature)}>
                        <ListItemIcon>
                          <VideoLibraryIcon />
                        </ListItemIcon>
                        <ListItemText primary="Videos" className="menu-text" />
                      </ListItemButton>
                    </ListItem>
                  )}
                {chapterTheoryFeature.length > 0 &&
                  (subscriptionChapterTheoryFeature.length > 0 || props.chapter.can_user_access === 0) &&
                  props.chapter &&
                  props.chapter.description &&
                  props.chapter.description.length > 0 && (
                    <ListItem
                      secondaryAction={
                        <IconButton edge="end" aria-label="comments">
                          <ChevronRight />
                        </IconButton>
                      }
                      disablePadding
                    >
                      <ListItemButton selected={selectedIndex === 1} onClick={(event) => handleListItemClick(event, 1, chapterTheoryFeature)}>
                        <ListItemIcon>
                          <TextSnippetIcon />
                        </ListItemIcon>
                        <ListItemText primary="Theory" className="menu-text" />
                      </ListItemButton>
                    </ListItem>
                  )}
                {chapterNCERTBooksFeature.length > 0 &&
                  (subscriptionChapterNcertBooksFeature.length > 0 || props.chapter.can_user_access === 0) &&
                  props.chapter &&
                  props.chapter.ncert_books &&
                  props.chapter.ncert_books.length > 0 && (
                    <ListItem
                      secondaryAction={
                        <IconButton edge="end" aria-label="comments">
                          <ChevronRight />
                        </IconButton>
                      }
                      disablePadding
                    >
                      <ListItemButton selected={selectedIndex === 2} onClick={(event) => handleListItemClick(event, 2, chapterNCERTBooksFeature)}>
                        <ListItemIcon>
                          <MenuBookIcon />
                        </ListItemIcon>
                        <ListItemText primary="NCERT Books" className="menu-text" />
                      </ListItemButton>
                    </ListItem>
                  )}
              </List>
            )}
          {((chapterPracticeTestFeature.length > 0 && (subscriptionChapterPracticeTestFeature.length > 0 || props.chapter.can_user_access === 0)) ||
            (chapterVirtualLabFeature.length > 0 && (subscriptionChapterVirtualLabFeature.length > 0 || props.chapter.can_user_access === 0)) ||
            (chapterNCERTExercisesFeature.length > 0 &&
              (subscriptionChapterNcertExercisesFeature.length > 0 || props.chapter.can_user_access === 0))) &&
            props.chapter &&
            (props.chapter.descriptive_practice_tests || props.chapter.interactivities || props.chapter.ncert_exercises) &&
            (props.chapter.descriptive_practice_tests.length > 0 ||
              props.chapter.interactivities.length > 0 ||
              props.chapter.ncert_exercises.length > 0) && (
              <List
                component="nav"
                aria-label="main mailbox folders"
                sx={{ width: "100%", paddingBottom: 0 }}
                aria-labelledby="learn-subheader"
                subheader={
                  <ListSubheader
                    component="div"
                    id="learn-subheader"
                    sx={{
                      fontSize: "15px",
                      color: `${colors.white}`,
                      background: `${colors.gr_orange}`,
                      lineHeight: "45px",
                      fontFamily: "Manrope",
                    }}
                  >
                    Practice
                  </ListSubheader>
                }
              >
                {chapterPracticeTestFeature.length > 0 &&
                  (subscriptionChapterPracticeTestFeature.length > 0 || props.chapter.can_user_access === 0) &&
                  props.chapter &&
                  props.chapter.descriptive_practice_tests &&
                  props.chapter.descriptive_practice_tests.length > 0 && (
                    <ListItem
                      secondaryAction={
                        <IconButton edge="end" aria-label="comments">
                          <ChevronRight />
                        </IconButton>
                      }
                      disablePadding
                    >
                      <ListItemButton selected={selectedIndex === 3} onClick={(event) => handleListItemClick(event, 3, chapterPracticeTestFeature)}>
                        <ListItemIcon>
                          <QuestionMarkIcon />
                        </ListItemIcon>
                        <ListItemText primary="Practice Test" className="menu-text" />
                      </ListItemButton>
                    </ListItem>
                  )}

                {chapterVirtualLabFeature.length > 0 &&
                  (subscriptionChapterVirtualLabFeature.length > 0 || props.chapter.can_user_access === 0) &&
                  props.chapter &&
                  props.chapter.interactivities &&
                  props.chapter.interactivities.length > 0 && (
                    <ListItem
                      secondaryAction={
                        <IconButton edge="end" aria-label="comments">
                          <ChevronRight />
                        </IconButton>
                      }
                      disablePadding
                    >
                      <ListItemButton selected={selectedIndex === 4} onClick={(event) => handleListItemClick(event, 4, chapterVirtualLabFeature)}>
                        <ListItemIcon>
                          <ScienceIcon />
                        </ListItemIcon>
                        <ListItemText primary="Virtual Lab" className="menu-text" />
                      </ListItemButton>
                    </ListItem>
                  )}

                {chapterNCERTExercisesFeature.length > 0 &&
                  (subscriptionChapterNcertExercisesFeature.length > 0 || props.chapter.can_user_access === 0) &&
                  props.chapter &&
                  props.chapter.ncert_exercises &&
                  props.chapter.ncert_exercises.length > 0 && (
                    <ListItem
                      secondaryAction={
                        <IconButton edge="end" aria-label="comments">
                          <ChevronRight />
                        </IconButton>
                      }
                      disablePadding
                    >
                      <ListItemButton selected={selectedIndex === 5} onClick={(event) => handleListItemClick(event, 5, chapterNCERTExercisesFeature)}>
                        <ListItemIcon>
                          <AbcIcon />
                        </ListItemIcon>
                        <ListItemText primary="NCERT Exercises" className="menu-text" />
                      </ListItemButton>
                    </ListItem>
                  )}
              </List>
            )}
          {chapterMCQsQuizFeature.length > 0 &&
            (subscriptionChapterMCQsQuizFeature.length > 0 || props.chapter.can_user_access === 0) &&
            props.chapter &&
            props.chapter.quizzes &&
            props.chapter.quizzes.length > 0 && (
              <List
                component="nav"
                aria-label="main mailbox folders"
                sx={{ width: "100%", paddingBottom: 0 }}
                aria-labelledby="test-subheader"
                subheader={
                  <ListSubheader
                    component="div"
                    id="test-subheader"
                    sx={{ fontSize: "15px", color: `${colors.white}`, background: `${colors.gr_orange}`, lineHeight: "45px", fontFamily: "Manrope" }}
                  >
                    Test
                  </ListSubheader>
                }
              >
                {chapterMCQsQuizFeature.length > 0 &&
                  (subscriptionChapterMCQsQuizFeature.length > 0 || props.chapter.can_user_access === 0) &&
                  props.chapter &&
                  props.chapter.quizzes &&
                  props.chapter.quizzes.length > 0 && (
                    <ListItem
                      secondaryAction={
                        <IconButton edge="end" aria-label="comments">
                          <ChevronRight />
                        </IconButton>
                      }
                      disablePadding
                    >
                      <ListItemButton selected={selectedIndex === 6} onClick={(event) => handleListItemClick(event, 6, chapterMCQsQuizFeature)}>
                        <ListItemIcon>
                          <QuizIcon />
                        </ListItemIcon>
                        <ListItemText primary="MCQs Quiz" className="menu-text" />
                      </ListItemButton>
                    </ListItem>
                  )}
              </List>
            )}
        </Box>
      </div>
      {props.chapter.can_user_access === 1 ? (
        ""
      ) : (
        <div className="single-details-sidbar">
          <div className="course-details-feature">
            <Col sm="12">
              <button
                type="button"
                onClick={() => props.history.push(webUrl.packageList + "/" + props.chapter.subsubcategory_id)}
                className="enroll-btn"
                style={{ margin: "20px 0" }}
              >
                Buy Now
              </button>
            </Col>
          </div>
        </div>
      )}
    </Styles>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({ sendAuditEvent: (data) => dispatch(sendAuditEventAction(data)) });

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ChapterMenu));
