/**
 * Web/page urls used in application
 */
const webUrl = {
  // open routes
  home: "/",
  homeUS: "/us",
  homeIN: "/in",
  about: "/about",
  terms: "/terms-of-use",
  privacyPolicy: "/privacy-policy",
  contact: "/contact",
  faq: "/faq",
  blog: "/blog",
  blogDetails: "/blog-details",
  studyMaterial: "/study-material",
  teacher: "/teacher-solutions",
  teacherCampaign: "/teacher-campaign",
  teacherCampaignSubmit: "/campaign-success",
  class: "/class",
  partner: "/partner",
  school: "/school",
  robotic: "/robotics",
  internship: "/internship",
  sampleModule: "/light-sensor-module",
  sampleModule2: "/ultrasonic-sensor-module",
  offerings: "/offerings",
  examPreparatory: "/exam-preparatory",
  embedChapterTheory: "/embed/chapter/theory",
  embedChapterExercise: "/embed/chapter/exercise",
  embedChapterVideo: "/embed/chapter/video",
  embedModuleTheory: "/embed/module/theory",
  embedModuleConcept: "/embed/module/concept",
  embedModuleProgramming: "/embed/module/programming",
  embedModuleHardwareBuilding: "/embed/module/hardware-building",
  embedModuleSoftwareBuilding: "/embed/module/software-building",
  embedModuleCoreFundamentals: "/embed/module/core-fundamentals",
  embedModuleSoftwareSetup: "/embed/module/software-setup",
  embedVideo: "/embed/video",
  //student / teacher routes
  userDashboard: "/dashboard",
  subjectDetails: "/subject-details",
  chapterDetails: "/chapter-details",
  startChapterQuiz: "/chapter-quiz",
  chapterQuizReport: "/chapter-quiz-report",
  moduleDetails: "/module-details",
  startModuleQuiz: "/module-quiz",
  moduleQuizReport: "/module-quiz-report",
  packageList: "/package",
  cartList: "/cart",
  orderDetails: "/order-details",
  userSubscriptions: "/subscriptions",
  userTransactions: "/transactions",
  userNotifications: "/notifications",
  userProfile: "/profile",
  liveClasses: "/live-classes",
  recordedSessions: "/recorded-sessions",
  teacherTechnologyKit: "/teacher-technology-kit",
  teacherTechnologyKitDetails: "/teacher-technology-kit-details",
  testGeneration: "/test-generation",
  generateNewTest: "/generate-new-test",
  userUSIntroduction: "/introduction",
  userUSUnits: "/units",
  userUSAdvanceMission: "/advance-mission",
  userUSCeritificate: "/certificate",

  //olumpiad routes
  olympiadLogin: "/olympiad/login",
  olympiadWelcome: "/olympiad/welcome",
  olympiadThankyou: "/olympiad/thank-you",
  startOlympiadQuiz: "/olympiad/quiz",
  paymentSuccess: "/payment/paymentSuccess",
  paymentFailure: "/payment/paymentFailure",
  //partner routes
  partnerLoginAdmin: "/partner-login-admin",
  partnerDashboard: "/partner-dashboard",
  studentsList: "/students-list",
  studentPackages: "/student-packages",
  teachersList: "/teachers-list",
  teacherPackages: "/teacher-packages",
  schoolsList: "/schools-list",
  partnerWallet: "/wallet-transactions",
  partnerCoupons: "/partner-coupons",
  partnerCommissions: "/partner-commissions",
  promotionalMaterials: "/promotional-materials",
  referCode: "/share-refer-code",
  partnerNotifications: "/partner-notifications",
  partnerEnquiry: "/partner-enquiry",

  userLogout: "/logout",
  courseListUs: "/us/courses-list",
  courseDetailsUs: "/us/courses-details",
};

/**
 * Api urls used in application
 */
const apiUrl = {
  //baseApiUrl: "http://localhost:5000/api/v1",
  baseApiUrl: "https://api.ealyss.com/api/v1", //production
  // baseApiUrl: "https://api-stage.e-alyss.com/api/v1", //staging
  detectCountry: "/detect-country",
  userLogin: "/login-signup",
  userLoginEmail: "/login-signup-email",
  forgotPassword: "/forgot-password",
  partnerLogin: "/partner-login",
  partnerAdminLogin: "/partner-login-admin",
  masterData: "/masters",
  authMasterData: "/auth-masters",
  partnerAddStudent: "/partner-add-student",
  partnerStudents: "/partner-students",
  memberPackages: "/member-packages?member_id={0}",
  partnerAddTeacher: "/partner-add-teacher",
  partnerTeachers: "/partner-teachers",
  partnerAddSchool: "/partner-add-school",
  partnerSchools: "/partner-schools",
  partnerProfile: "/partner-profile",
  partnerAssignMemberPackage: "/assign-package-to-member",
  partnerWallet: "/partner-wallet",
  partnerCommissions: "/partner-commission",
  promotionalMaterials: "/promotional-materials",
  walletWithdrawlRequest: "/wallet-withdrawl-request",
  walletRechargeRequest: "/wallet-recharge-request",
  walletPaytmrechargeRequest: "/paytm-recharge-request",
  commissionPayoutRequest: "/commission-payout-request",
  addEnquiryRequest: "/partner-add-enquiry",
  partnerAddCoupon: "/partner-add-coupon",
  partnerCoupons: "/partner-coupons",
  partnerDashboard: "/partner-dashboard",
  verifyOTP: "/verify-otp",
  verifyPassword: "/verify-password",
  updatePassword: "/update-password",
  verifyEmailOTP: "/verify-email-otp",
  updateProfile: "/update-profile",
  profileData: "/profile",
  examCoursePlans: "/exam-course-plans",
  dashboard: "/dashboard?subcategory_id={0}",
  packageList: "/package-list?subsubcategory_id={0}",
  subjectDetails: "/subsubcategory-details?subsubcategory_id={0}",
  chapterDetails: "/chapter-details?chapter_id={0}",
  chapterQuizReport: "/chapter-quiz-report?member_chapter_quiz_id={0}",
  moduleQuizReport: "/module-quiz-report?member_module_quiz_id={0}",
  moduleDetails: "/module-details?module_id={0}",
  addToCart: "/add-to-cart",
  cartList: "/cart",
  removeFromCart: "/remove-from-cart",
  applyCoupon: "/apply-coupon",
  removeCoupon: "/remove-coupon",
  addressesList: "/addresses",
  checkout: "/checkout",
  razorpaycheckout: "/razorpaycheckout",
  savePaymentDetails: "/save-payment-details",
  subscriptionsList: "/subscriptions",
  notificationsList: "/notifications",
  transactionsList: "/transactions",
  newRequest: "/new-request",
  verifyRequestOtp: "/verify-request-otp",
  liveClasses: "/live-classes?subcategory_id={0}&subsubcategory_id={1}",
  liveClassCheck: "/live-class-check?live_class_id={0}",
  liveClassParticipation: "/live-class-participation?live_class_id={0}",
  recordedSessions: "/recorded-sessions?subcategory_id={0}&subsubcategory_id={1}",
  recordedSessionCheck: "/recorded-session-check?live_class_id={0}",
  generateNewTest: "/generate-test",
  generatedTestsList: "/teacher-tests-list",
  downloadQuestionPaper: "/download-test-question",
  downloadSolution: "/download-test-solution",
  subsubcategories: "/subsubcategories?subcategory_id={0}",
  orderDetails: "/order-details?order_code={0}",
  startChapterQuiz: "/start-chapter-quiz",
  submitChapterQuiz: "/submit-chapter-quiz",
  startModuleQuiz: "/start-module-quiz",
  submitModuleQuiz: "/submit-module-quiz",
  sampleToken: "/get-sample-token",
  startOlympiadQuiz: "/start-olympiad-quiz",
  submitOlympiadQuiz: "/submit-olympiad-quiz",
  checkOlympiadQuiz: "/check-olympiad-quiz",
  olympiadLogin: "/olympiad/login",
  paypalReturnURL: "/paypalReturnURL",
  paypalCancelURL: "/paypalCancelURL",
  subscribeUser: "/subscribe-user",
  sendCampaignOTP: "/send-campaign-otp",
  verifyCampaignOTP: "/verify-campaign-otp",
  submitContactForm: "/submit-contact",
  chapterTheory: "/chapter-theory?chapter_id={0}",
  chapterExercises: "/chapter-exercises?chapter_id={0}",
  chapterVideo: "/chapter-video?chapter_id={0}&video_id={1}",
  moduleTheory: "/module-theory?module_id={0}",
  moduleConcept: "/module-concept?module_id={0}&video_id={1}",
  moduleProgramming: "/module-programming?module_id={0}&video_id={1}",
  moduleHardwareBuilding: "/module-hardware-building?module_id={0}&video_id={1}",
  moduleSoftwareBuilding: "/module-software-building?module_id={0}&video_id={1}",
  moduleCoreFundamentals: "/module-core-fundamentals?module_id={0}",
  moduleSoftwareSetup: "/module-software-setup?module_id={0}",
  memberProfile: "/member-profile",
  updateMemberProfile: "/update-member-profile",
  memberAuditEvent: "/member-audit-log",
  usCourseList: "/us-courses",
  usCourseDetail: "/us-course/{0}",
};

/**
 * Configuration used in application
 */
const config = {
  PAYTM_HOST: "securegw.paytm.in", // production
  // PAYTM_HOST: "securegw-stage.paytm.in",
  PAYTM_MERCHANT_ID: "XSFPnX77124740110024", // production
  // PAYTM_MERCHANT_ID: "MNepyK66855273340853",
  PAYPAL_CLIENT_ID: "ASs3yp0Q3EzfGcGcYEFkBmBqvpx82PpIHRd4Tc1339SxjUXHrQCAhOYqeGjNHgxgkSxznbmAdTNgiTUN",
  GTM_ID: "GTM-M8FC93S",
  GA_ID: "UA-37089236-1",
  GA_MEASUREMENT_ID: "G-2J8L3M3FRK",
  FACEBOOK_PIXEL_ID: "731704491422563",
  RAZOR_PAY_MERCHANT_KEY: "rzp_live_jtchz0lPZfXLCZ",
};

/**
 * Messages used in application
 */
const messages = {
  requiredField: `Required`,
  requiredMobileField: `Please enter mobile number`,
  confirmPasswordField: `Confirm password should be same as password`,
  invalidEmailField: `Invalid email`,
  numberField: `Only number allowed`,
  resendText: `SMS resend successfully`,
  resendTextEmail: `Email resend successfully`,
  tenDigitsField: `Mobile number should be of 10 digits`,
  noInternetConnection: "There seems to be issue with your internet connection, please try again",
};

export { messages, apiUrl, webUrl, config };
