import React, { useState } from "react";
import { Styles } from "../styles/dashboard.js";
import { Container, Row, Col } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import LoaderNew from "../../../components/LoaderNew";
import Typography from "@mui/material/Typography";
import { addNewEnquiryAction } from "../action";
import PartnerHeader from "../../../components/PartnerHeader";
import DashboardMenu from "./DashboardMenu";
import FooterTwo from "../../../components/FooterTwo";
import { Alert, AlertTitle, Box, Button, Grid, MenuItem, TextField } from "@mui/material";

const PartnerEnquiry = (props) => {
  const subject = [
    {
      id: 1,
      title: "Wallet",
    },
    {
      id: 2,
      title: "Commission",
    },
    {
      id: 3,
      title: "Other",
    },
  ];
  const [selectedSubject, setSelectedSubject] = useState("");
  const [selectedSubjectError, setSelectedSubjectError] = useState("");
  const [remarks, setRemarks] = useState("");
  const [remarksError, setRemarksError] = useState("");
  const [enquiryMessage, setEnquiryMessage] = useState("");

  const handleSubjectChange = (e) => {
    setSelectedSubject(e.target.value);
    handleFormSubmit();
  };

  const handleRemarksChange = (e) => {
    setRemarks(e.target.value);
    handleFormSubmit();
  };

  const handleFormSubmit = () => {
    let error = false;

    if (selectedSubject === "") {
      error = true;
      setSelectedSubjectError("Please select topic");
    } else {
      setSelectedSubjectError("");
    }
    if (remarks === "") {
      error = true;
      setRemarksError("Please enter remarks");
    } else {
      setRemarksError("");
    }
    return error;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!handleFormSubmit()) {
      const formData = {
        subject: selectedSubject,
        remarks: remarks,
      };
      props
        .addNewEnquiry(formData)
        .then((response) => {
          setSelectedSubject("");
          setSelectedSubjectError("");
          setRemarks("");
          setRemarksError("");
          setEnquiryMessage("Your enquiry has been subnmitted successfully, we will contact you soon.");
          setTimeout(() => {
            setEnquiryMessage("");
          }, 10000);
        })
        .catch((error) => {});
    }
  };
  return (
    <div className="main-wrapper course-page">
      {/* User Header */}
      <PartnerHeader />

      <Styles>
        {/* Course Grid */}
        <section className="dashboard-grid-area">
          <Container>
            <Row>
              <Col lg="3" md="3" sm="12">
                <DashboardMenu {...props} index={props.index || 10} />
              </Col>
              <Col>
                {props.showLoader && props.showLoader.length > 0 && <LoaderNew />}
                {props.showLoader.length === 0 && (
                  <>
                    <Typography variant="h3" component="div" gutterBottom>
                      {"Enquiry"}
                    </Typography>
                    <Box
                      component="form"
                      sx={{
                        "& .MuiTextField-root": { m: 1 },
                        minHeight: "320px",
                      }}
                      noValidate
                      autoComplete="off"
                    >
                      {enquiryMessage === "" && (
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <TextField
                              select
                              fullWidth
                              required
                              label="Select Topic"
                              value={selectedSubject}
                              onChange={handleSubjectChange}
                              helperText={selectedSubjectError}
                              error={selectedSubjectError !== ""}
                            >
                              {subject &&
                                subject.map((item) => (
                                  <MenuItem value={item.id} key={item.id}>
                                    {item.title}
                                  </MenuItem>
                                ))}
                            </TextField>
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                              label="Remarks"
                              multiline
                              rows={4}
                              fullWidth
                              required
                              placeholder="Enter remarks (if any)"
                              variant="outlined"
                              onChange={handleRemarksChange}
                              value={remarks}
                              helperText={remarksError}
                              error={remarksError !== ""}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <Button
                              onClick={handleSubmit}
                              disabled={props.showSubLoader && props.showSubLoader.length > 0}
                              variant="outlined"
                              size="large"
                            >
                              Submit Query
                            </Button>
                          </Grid>
                        </Grid>
                      )}
                      {enquiryMessage !== "" && (
                        <Alert severity="success">
                          <AlertTitle>Success</AlertTitle>
                          {enquiryMessage}
                        </Alert>
                      )}
                    </Box>
                  </>
                )}
              </Col>
            </Row>
          </Container>
        </section>
      </Styles>

      {/* Footer 2 */}
      <FooterTwo />
    </div>
  );
};
const mapStateToProps = (state) => ({
  error: state.partnerDashboard.error,
  showLoader: state.user.showLoader,
});

const mapDispatchToProps = (dispatch) => ({
  addNewEnquiry: (data) => dispatch(addNewEnquiryAction(data)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PartnerEnquiry));
